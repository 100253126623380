import { Browser, EventHandler } from '@syncfusion/ej2-base';
import { addClass, removeClass } from '@syncfusion/ej2-base';
import { formatUnit, isNullOrUndefined } from '@syncfusion/ej2-base';
import { getScrollBarWidth, getUpdateUsingRaf } from '../base/util';
import { scroll, contentReady, uiUpdate, onEmpty, headerRefreshed, textWrapRefresh, virtualScrollEdit, infiniteScrollHandler, closeFilterDialog } from '../base/constant';
import { lazyLoadScrollHandler, checkScrollReset } from '../base/constant';
import { ColumnWidthService } from '../services/width-controller';
import * as literals from '../base/string-literals';
import * as events from '../base/constant';
/**
 * The `Scroll` module is used to handle scrolling behaviour.
 */
export class Scroll {
    /**
     * Constructor for the Grid scrolling.
     *
     * @param {IGrid} parent - specifies the IGrid
     * @hidden
     */
    constructor(parent) {
        //To maintain scroll state on grid actions.
        this.previousValues = { top: 0, left: 0 };
        this.oneTimeReady = true;
        this.parent = parent;
        this.widthService = new ColumnWidthService(parent);
        this.addEventListener();
    }
    /**
     * For internal use only - Get the module name.
     *
     * @returns {string} returns the module name
     * @private
     */
    getModuleName() {
        return 'scroll';
    }
    /**
     * @param {boolean} uiupdate - specifies the uiupdate
     * @returns {void}
     * @hidden
     */
    setWidth(uiupdate) {
        this.parent.element.style.width = formatUnit(this.parent.width);
        if (uiupdate) {
            this.widthService.setWidthToColumns();
        }
        if (this.parent.toolbarModule && this.parent.toolbarModule.toolbar &&
            this.parent.toolbarModule.toolbar.element) {
            this.parent.toolbarModule.toolbar.refreshOverflow();
        }
    }
    /**
     * @returns {void}
     * @hidden
     */
    setHeight() {
        let mHdrHeight = 0;
        const content = this.parent.getContent().querySelector('.' + literals.content);
        let height = this.parent.height;
        if (this.parent.isFrozenGrid() && this.parent.height !== 'auto' && this.parent.height.toString().indexOf('%') < 0) {
            height = parseInt(height, 10) - Scroll.getScrollBarWidth();
        }
        if (!this.parent.enableVirtualization && this.parent.frozenRows && this.parent.height !== 'auto') {
            const tbody = this.parent.getHeaderContent().querySelector(literals.tbody);
            mHdrHeight = tbody ? tbody.offsetHeight : 0;
            if (tbody && mHdrHeight) {
                const add = tbody.getElementsByClassName(literals.addedRow).length;
                const height = add * this.parent.getRowHeight();
                mHdrHeight -= height;
            }
            content.style.height = formatUnit(height - mHdrHeight);
        }
        else {
            content.style.height = formatUnit(height);
        }
        this.ensureOverflow(content);
        if (this.parent.isFrozenGrid()) {
            this.refresh();
        }
    }
    /**
     * @returns {void}
     * @hidden
     */
    setPadding() {
        const content = this.parent.getHeaderContent();
        const scrollWidth = Scroll.getScrollBarWidth() - this.getThreshold();
        const cssProps = this.getCssProperties();
        const padding = this.parent.getFrozenMode() === 'Right' || this.parent.getFrozenMode() === literals.leftRight ? '0.5px' : '1px';
        content.querySelector('.' + literals.headerContent).style[cssProps.border] = scrollWidth > 0 ? padding : '0px';
        content.style[cssProps.padding] = scrollWidth > 0 ? scrollWidth + 'px' : '0px';
    }
    /**
     * @param {boolean} rtl - specifies the rtl
     * @returns {void}
     * @hidden
     */
    removePadding(rtl) {
        const cssProps = this.getCssProperties(rtl);
        const hDiv = this.parent.getHeaderContent().querySelector('.' + literals.headerContent);
        hDiv.style[cssProps.border] = '';
        hDiv.parentElement.style[cssProps.padding] = '';
        const footerDiv = this.parent.getFooterContent();
        if (footerDiv && footerDiv.classList.contains('e-footerpadding')) {
            footerDiv.classList.remove('e-footerpadding');
        }
    }
    /**
     * Refresh makes the Grid adoptable with the height of parent container.
     *
     * > The [`height`](grid/#height/) must be set to 100%.
     *
     * @returns {void}
     */
    refresh() {
        if (this.parent.height !== '100%') {
            return;
        }
        const content = this.parent.getContent();
        this.parent.element.style.height = '100%';
        const height = this.widthService.getSiblingsHeight(content);
        content.style.height = 'calc(100% - ' + height + 'px)'; //Set the height to the  '.' + literals.gridContent;
        if (this.parent.isFrozenGrid()) {
            content.firstElementChild.style.height = 'calc(100% - ' + getScrollBarWidth() + 'px)';
        }
    }
    getThreshold() {
        /* Some browsers places the scroller outside the content,
         * hence the padding should be adjusted.*/
        const appName = Browser.info.name;
        if (appName === 'mozilla') {
            return 0.5;
        }
        return 1;
    }
    /**
     * @returns {void}
     * @hidden
     */
    addEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.on(onEmpty, this.wireEvents, this);
        this.parent.on(contentReady, this.wireEvents, this);
        this.parent.on(uiUpdate, this.onPropertyChanged, this);
        this.parent.on(textWrapRefresh, this.wireEvents, this);
        this.parent.on(headerRefreshed, this.setScrollLeft, this);
    }
    /**
     * @returns {void}
     * @hidden
     */
    removeEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.off(onEmpty, this.wireEvents);
        this.parent.off(contentReady, this.wireEvents);
        this.parent.off(uiUpdate, this.onPropertyChanged);
        this.parent.off(textWrapRefresh, this.wireEvents);
        this.parent.off(headerRefreshed, this.setScrollLeft);
    }
    setScrollLeft() {
        if (this.parent.isFrozenGrid()) {
            this.parent.headerModule.getMovableHeader().scrollLeft = this.previousValues.left;
        }
        else {
            this.parent.getHeaderContent().querySelector('.' + literals.headerContent).scrollLeft = this.previousValues.left;
        }
    }
    onFrozenContentScroll() {
        return (e) => {
            if (this.content.querySelector(literals.tbody) === null || this.parent.isPreventScrollEvent) {
                return;
            }
            if (!isNullOrUndefined(this.parent.infiniteScrollModule) && this.parent.enableInfiniteScrolling) {
                this.parent.notify(infiniteScrollHandler, e);
            }
            this.previousValues.top = e.target.scrollTop;
        };
    }
    onContentScroll(scrollTarget) {
        const element = scrollTarget;
        const isHeader = element.classList.contains(literals.headerContent);
        return (e) => {
            if (this.content.querySelector(literals.tbody) === null || this.parent.isPreventScrollEvent) {
                return;
            }
            const target = e.target;
            const left = target.scrollLeft;
            if (!isNullOrUndefined(this.parent.infiniteScrollModule) && this.parent.enableInfiniteScrolling) {
                this.parent.notify(infiniteScrollHandler, { target: e.target, isLeft: this.previousValues.left !== left });
            }
            if (this.parent.groupSettings.columns.length && this.parent.groupSettings.enableLazyLoading) {
                const isDown = this.previousValues.top < this.parent.getContent().firstElementChild.scrollTop;
                this.parent.notify(lazyLoadScrollHandler, { scrollDown: isDown });
            }
            this.parent.notify(virtualScrollEdit, {});
            const isFooter = target.classList.contains('e-summarycontent');
            if (this.previousValues.left === left) {
                this.previousValues.top = !isHeader ? this.previousValues.top : target.scrollTop;
                return;
            }
            this.parent.notify(closeFilterDialog, e);
            element.scrollLeft = left;
            if (isFooter) {
                this.header.scrollLeft = left;
            }
            this.previousValues.left = left;
            this.parent.notify(scroll, { left: left });
        };
    }
    onCustomScrollbarScroll(mCont, mHdr) {
        const content = mCont;
        const header = mHdr;
        return (e) => {
            if (this.content.querySelector(literals.tbody) === null) {
                return;
            }
            const target = e.target;
            const left = target.scrollLeft;
            if (this.previousValues.left === left) {
                return;
            }
            content.scrollLeft = left;
            header.scrollLeft = left;
            this.previousValues.left = left;
            this.parent.notify(scroll, { left: left });
            if (this.parent.isDestroyed) {
                return;
            }
        };
    }
    onTouchScroll(scrollTarget) {
        const element = scrollTarget;
        return (e) => {
            if (e.pointerType === 'mouse') {
                return;
            }
            const isFrozen = this.parent.isFrozenGrid();
            const pageXY = this.getPointXY(e);
            const left = element.scrollLeft + (this.pageXY.x - pageXY.x);
            const mHdr = isFrozen ?
                this.parent.getHeaderContent().querySelector('.' + literals.movableHeader) :
                this.parent.getHeaderContent().querySelector('.' + literals.headerContent);
            const mCont = isFrozen ?
                this.parent.getContent().querySelector('.' + literals.movableContent) :
                this.parent.getContent().querySelector('.' + literals.content);
            if (this.previousValues.left === left || (left < 0 || (mHdr.scrollWidth - mHdr.clientWidth) < left)) {
                return;
            }
            e.preventDefault();
            mHdr.scrollLeft = left;
            mCont.scrollLeft = left;
            if (isFrozen) {
                const scrollBar = this.parent.getContent().querySelector('.e-movablescrollbar');
                scrollBar.scrollLeft = left;
            }
            this.pageXY.x = pageXY.x;
            this.previousValues.left = left;
        };
    }
    setPageXY() {
        return (e) => {
            if (e.pointerType === 'mouse') {
                return;
            }
            this.pageXY = this.getPointXY(e);
        };
    }
    getPointXY(e) {
        const pageXY = { x: 0, y: 0 };
        if (e.touches && e.touches.length) {
            pageXY.x = e.touches[0].pageX;
            pageXY.y = e.touches[0].pageY;
        }
        else {
            pageXY.x = e.pageX;
            pageXY.y = e.pageY;
        }
        return pageXY;
    }
    getScrollbleParent(node) {
        if (node === null) {
            return null;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parent = isNullOrUndefined(node.tagName) ? node.scrollingElement : node;
        const overflowY = document.defaultView.getComputedStyle(parent, null).overflowY;
        if (parent.scrollHeight > parent.clientHeight && overflowY !== 'hidden'
            && overflowY !== 'visible' || node.tagName === 'HTML' || node.tagName === 'BODY') {
            return node;
        }
        else {
            return this.getScrollbleParent(node.parentNode);
        }
    }
    /**
     * @param {boolean} isAdd - specifies whether adding/removing the event
     * @returns {void}
     * @hidden
     */
    addStickyListener(isAdd) {
        this.parentElement = this.getScrollbleParent(this.parent.element.parentElement);
        if (isAdd) {
            if (this.parentElement) {
                EventHandler.add(this.parentElement.tagName === 'HTML' || this.parentElement.tagName === 'BODY' ? document :
                    this.parentElement, 'scroll', this.makeStickyHeader, this);
            }
        }
        else {
            EventHandler.remove(this.parentElement, 'scroll', this.makeStickyHeader);
        }
    }
    wireEvents() {
        if (this.oneTimeReady) {
            const frzCols = this.parent.isFrozenGrid();
            this.content = this.parent.getContent().querySelector('.' + literals.content);
            this.header = this.parent.getHeaderContent().querySelector('.' + literals.headerContent);
            const mCont = this.content.querySelector('.' + literals.movableContent);
            const mHdr = this.header.querySelector('.' + literals.movableHeader);
            const mScrollBar = this.parent.getContent().querySelector('.e-movablescrollbar');
            if (this.parent.frozenRows) {
                EventHandler.add(frzCols ? mHdr : this.header, 'touchstart pointerdown', this.setPageXY(), this);
                EventHandler.add(frzCols ? mHdr : this.header, 'touchmove pointermove', this.onTouchScroll(frzCols ? mCont : this.content), this);
            }
            if (this.parent.isFrozenGrid()) {
                EventHandler.add(mScrollBar, 'scroll', this.onCustomScrollbarScroll(mCont, mHdr), this);
                EventHandler.add(mCont, 'scroll', this.onCustomScrollbarScroll(mScrollBar, mHdr), this);
                EventHandler.add(mHdr, 'scroll', this.onCustomScrollbarScroll(mScrollBar, mCont), this);
                EventHandler.add(this.content, 'scroll', this.onFrozenContentScroll(), this);
                EventHandler.add(mHdr, 'touchstart pointerdown', this.setPageXY(), this);
                EventHandler.add(mHdr, 'touchmove pointermove', this.onTouchScroll(mCont), this);
                EventHandler.add(mCont, 'touchstart pointerdown', this.setPageXY(), this);
                if (!(/macintosh|ipad/.test(Browser.userAgent.toLowerCase()) && Browser.isDevice)) {
                    EventHandler.add(mCont, 'touchmove pointermove', this.onTouchScroll(mHdr), this);
                }
            }
            else {
                EventHandler.add(this.content, 'scroll', this.onContentScroll(this.header), this);
                EventHandler.add(this.header, 'scroll', this.onContentScroll(this.content), this);
            }
            if (this.parent.aggregates.length) {
                EventHandler.add(this.parent.getFooterContent().firstChild, 'scroll', this.onContentScroll(this.content), this);
            }
            if (this.parent.enableStickyHeader) {
                this.addStickyListener(true);
            }
            this.refresh();
            this.oneTimeReady = false;
        }
        const table = this.parent.getContentTable();
        let sLeft;
        let sHeight;
        let clientHeight;
        getUpdateUsingRaf(() => {
            sLeft = this.header.scrollLeft;
            sHeight = table.scrollHeight;
            clientHeight = this.parent.getContent().clientHeight;
        }, () => {
            const args = { cancel: false };
            this.parent.notify(checkScrollReset, args);
            if (!this.parent.enableVirtualization && !this.parent.enableInfiniteScrolling) {
                if (sHeight < clientHeight) {
                    addClass(table.querySelectorAll('tr:last-child td'), 'e-lastrowcell');
                    if (this.parent.isFrozenGrid()) {
                        addClass(this.parent.getContent().querySelector('.' + literals.movableContent).querySelectorAll('tr:last-child td'), 'e-lastrowcell');
                        if (this.parent.getFrozenRightColumnsCount()) {
                            addClass(this.parent.getContent().querySelector('.e-frozen-right-content').querySelectorAll('tr:last-child td'), 'e-lastrowcell');
                        }
                    }
                }
                if (!args.cancel) {
                    if ((this.parent.frozenRows > 0 || this.parent.isFrozenGrid()) && this.header.querySelector('.' + literals.movableHeader)) {
                        this.header.querySelector('.' + literals.movableHeader).scrollLeft = this.previousValues.left;
                    }
                    else {
                        this.header.scrollLeft = this.previousValues.left;
                    }
                    this.content.scrollLeft = this.previousValues.left;
                    this.content.scrollTop = this.previousValues.top;
                }
            }
            if (!this.parent.enableColumnVirtualization) {
                this.content.scrollLeft = sLeft;
            }
            if (this.parent.isFrozenGrid() && this.header.querySelector('.' + literals.movableHeader)) {
                this.header.querySelector('.' + literals.movableHeader).scrollLeft =
                    this.content.querySelector('.' + literals.movableContent).scrollLeft;
            }
        });
        this.parent.isPreventScrollEvent = false;
    }
    /**
     * @param {boolean} rtl - specifies the rtl
     * @returns {ScrollCss} returns the ScrollCss
     * @hidden
     */
    getCssProperties(rtl) {
        const css = {};
        const enableRtl = isNullOrUndefined(rtl) ? this.parent.enableRtl : rtl;
        css.border = enableRtl ? 'borderLeftWidth' : 'borderRightWidth';
        css.padding = enableRtl ? 'paddingLeft' : 'paddingRight';
        return css;
    }
    ensureOverflow(content) {
        content.style.overflowY = this.parent.height === 'auto' ? 'auto' : 'scroll';
    }
    onPropertyChanged(e) {
        if (e.module !== this.getModuleName()) {
            return;
        }
        this.setPadding();
        this.oneTimeReady = true;
        if (this.parent.height === 'auto') {
            this.removePadding();
        }
        this.wireEvents();
        this.setHeight();
        const width = 'width';
        this.setWidth(!isNullOrUndefined(e.properties[width]));
    }
    makeStickyHeader() {
        if (this.parent.enableStickyHeader && this.parent.element && this.parent.getContent()) {
            const contentRect = this.parent.getContent().getClientRects()[0];
            if (contentRect) {
                const headerEle = this.parent.getHeaderContent();
                const toolbarEle = this.parent.element.querySelector('.e-toolbar');
                const groupHeaderEle = this.parent.element.querySelector('.e-groupdroparea');
                const height = headerEle.offsetHeight + (toolbarEle ? toolbarEle.offsetHeight : 0) +
                    (groupHeaderEle ? groupHeaderEle.offsetHeight : 0);
                const parentTop = this.parentElement.getClientRects()[0].top;
                const top = contentRect.top - (parentTop < 0 ? 0 : parentTop);
                const left = contentRect.left;
                if (top < height && contentRect.bottom > 0) {
                    headerEle.classList.add('e-sticky');
                    let elemTop = 0;
                    if (groupHeaderEle && this.parent.groupSettings.showDropArea) {
                        this.setSticky(groupHeaderEle, elemTop, contentRect.width, left, true);
                        elemTop += groupHeaderEle.getClientRects()[0].height;
                    }
                    if (toolbarEle) {
                        this.setSticky(toolbarEle, elemTop, contentRect.width, left, true);
                        elemTop += toolbarEle.getClientRects()[0].height;
                    }
                    this.setSticky(headerEle, elemTop, contentRect.width, left, true);
                }
                else {
                    if (headerEle.classList.contains('e-sticky')) {
                        this.setSticky(headerEle, null, null, null, false);
                        if (toolbarEle) {
                            this.setSticky(toolbarEle, null, null, null, false);
                        }
                        if (groupHeaderEle) {
                            this.setSticky(groupHeaderEle, null, null, null, false);
                        }
                        const ccDlg = this.parent.element.querySelector('.e-ccdlg');
                        if (ccDlg) {
                            ccDlg.classList.remove('e-sticky');
                        }
                    }
                }
                this.parent.notify(events.stickyScrollComplete, {});
            }
        }
    }
    setSticky(ele, top, width, left, isAdd) {
        if (isAdd) {
            ele.style.width = width + 'px';
            ele.classList.add('e-sticky');
        }
        else {
            ele.classList.remove('e-sticky');
        }
        ele.style.top = top != null ? top + 'px' : '';
        ele.style.left = left !== null ? parseInt(ele.style.left, 10) !== left ? left + 'px' : ele.style.left : '';
    }
    /**
     * @returns {void}
     * @hidden
     */
    destroy() {
        const gridElement = this.parent.element;
        if (!gridElement || (!gridElement.querySelector('.' + literals.gridHeader) && !gridElement.querySelector('.' + literals.gridContent))) {
            return;
        }
        this.removeEventListener();
        //Remove Dom event
        const cont = this.parent.getContent().querySelector('.' + literals.content);
        EventHandler.remove(cont, 'scroll', this.onContentScroll);
        if (this.parent.enableStickyHeader) {
            this.addStickyListener(false);
        }
        //Remove padding
        this.removePadding();
        removeClass([this.parent.getHeaderContent().querySelector('.' + literals.headerContent)], literals.headerContent);
        removeClass([cont], literals.content);
        //Remove height
        cont.style.height = '';
        //Remove width
        this.parent.element.style.width = '';
    }
    /**
     * Function to get the scrollbar width of the browser.
     *
     * @returns {number} return the width
     * @hidden
     */
    static getScrollBarWidth() {
        return getScrollBarWidth();
    }
}
