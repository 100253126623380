import { classList } from '@syncfusion/ej2-base';
import * as literals from '../base/string-literals';
/**
 * Edit render module is used to render grid edit row.
 *
 * @hidden
 */
export class BatchEditRender {
    /**
     * Constructor for render module
     *
     * @param {IGrid} parent - specifies the IGrid
     */
    constructor(parent) {
        this.parent = parent;
    }
    update(elements, args) {
        if (this.parent.isReact && args.columnObject && args.columnObject.template) {
            const parentRow = args.cell.parentElement;
            const newTd = args.cell.cloneNode(true);
            parentRow.insertBefore(newTd, args.cell);
            newTd.focus();
            args.cell.remove();
            args.cell = newTd;
        }
        args.cell.innerHTML = '';
        args.cell.appendChild(this.getEditElement(elements, args));
        args.cell.classList.remove('e-ellipsistooltip');
        args.cell.classList.add('e-editedbatchcell');
        classList(args.row, [literals.editedRow, 'e-batchrow'], []);
    }
    getEditElement(elements, args) {
        const gObj = this.parent;
        const form = this.parent
            .createElement('form', { id: gObj.element.id + 'EditForm', className: 'e-gridform' });
        form.appendChild(elements[args.columnObject.uid]);
        if (args.columnObject.editType === 'booleanedit') {
            args.cell.classList.add('e-boolcell');
        }
        if (!args.columnObject.editType) {
            args.cell.classList.add('e-inputbox');
        }
        return form;
    }
    removeEventListener() {
        //To destroy the renderer
    }
}
