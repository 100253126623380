/**
 * AriaService
 *
 * @hidden
 */
export class AriaService {
    setOptions(target, options) {
        const props = Object.keys(options);
        for (let i = 0; i < props.length; i++) {
            setStateAndProperties(target, config[props[i]], options[props[i]]);
        }
    }
    setExpand(target, expand) {
        setStateAndProperties(target, config.expand, expand);
    }
    setSort(target, direction) {
        setStateAndProperties(target, config.sort, direction, typeof direction === 'boolean');
    }
    setBusy(target, isBusy) {
        setStateAndProperties(target, config.busy, isBusy);
        setStateAndProperties(target, config.invalid, null, true);
    }
    setGrabbed(target, isGrabbed, remove) {
        setStateAndProperties(target, config.grabbed, isGrabbed, remove);
    }
    setDropTarget(target, isTarget) {
        setStateAndProperties(target, config.dropeffect, 'copy', !isTarget);
    }
}
/**
 * @param {HTMLElement} target - specifies the target
 * @param {string} attribute - specifies the attribute
 * @param {ValueType} value - specifies the value
 * @param {boolean} remove - specifies the boolean for remove
 * @returns {void}
 * @hidden
 */
function setStateAndProperties(target, attribute, value, remove) {
    if (remove && target) {
        target.removeAttribute(attribute);
        return;
    }
    if (target) {
        target.setAttribute(attribute, value);
    }
}
const config = {
    expand: 'aria-expanded',
    role: 'role',
    datarole: 'data-role',
    selected: 'aria-selected',
    multiselectable: 'aria-multiselectable',
    sort: 'aria-sort',
    busy: 'aria-busy',
    invalid: 'aria-invalid',
    grabbed: 'aria-grabbed',
    dropeffect: 'aria-dropeffect',
    haspopup: 'aria-haspopup',
    level: 'aria-level',
    colcount: 'aria-colcount',
    rowcount: 'aria-rowcount'
};
