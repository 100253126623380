import { extend, isNullOrUndefined, select } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { Query, DataManager, DataUtil, Predicate } from '@syncfusion/ej2-data';
import { isEditable, getComplexFieldID, getObject } from '../base/util';
import { parentsUntil } from '../base/util';
import { EditCellBase } from './edit-cell-base';
import * as literals from '../base/string-literals';
import * as events from '../base/constant';
/**
 * `DropDownEditCell` is used to handle dropdown cell type editing.
 *
 * @hidden
 */
export class DropDownEditCell extends EditCellBase {
    constructor(parent) {
        //constructor
        super();
        this.parent = parent;
        this.flag = false;
        this.removeEventHandler = this.removeEventListener;
    }
    write(args) {
        const isInline = this.parent.editSettings.mode !== 'Dialog';
        this.column = args.column;
        const pred = new Predicate(args.column.field, 'notequal', null, true, false);
        const params = {};
        if (args.column.edit.params) {
            const keys = Object.keys(args.column.edit.params);
            for (let i = 0; i < keys.length; i++) {
                params[keys[i]] = keys[i] === 'query' ? args.column.edit.params[keys[i]].clone() : args.column.edit.params[keys[i]];
            }
        }
        this.obj = new DropDownList(extend({
            dataSource: this.parent.dataSource instanceof DataManager ?
                this.parent.dataSource : new DataManager(this.parent.dataSource),
            query: new Query().where(pred).select(args.column.field), enabled: isEditable(args.column, args.requestType, args.element),
            fields: { value: args.column.field },
            value: getObject(args.column.field, args.rowData),
            enableRtl: this.parent.enableRtl,
            placeholder: isInline ? '' : args.column.headerText, popupHeight: '200px',
            floatLabelType: isInline ? 'Never' : 'Always',
            sortOrder: 'Ascending',
            cssClass: this.parent.cssClass ? this.parent.cssClass : null,
            close: this.dropDownClose.bind(this)
        }, params));
        if (this.parent.enableVirtualization) {
            this.obj.dataSource = args.column.isForeignColumn() ? [args.foreignKeyData[0]] : [args.rowData];
        }
        this.addEventListener();
        this.obj.query.params = this.parent.query.params;
        this.obj.appendTo(args.element);
        /* tslint:disable-next-line:no-any */
        args.element.setAttribute('name', getComplexFieldID(args.column.field));
    }
    dropDownClose(args) {
        // if (args.event && (args.event as KeyboardEventArgs).action === 'escape') {
        //     (this as DropDownEditCell).parent.editModule.editCellDialogClose = true;
        // }
    }
    addEventListener() {
        this.ddCreated = this.dropdownCreated.bind(this);
        this.ddOpen = this.dropDownOpen.bind(this);
        this.ddBeforeOpen = this.dropdownBeforeOpen.bind(this);
        this.ddComplete = this.ddActionComplete.bind(this);
        this.obj.addEventListener(literals.create, this.ddCreated);
        this.obj.addEventListener(literals.open, this.ddOpen);
        this.obj.addEventListener(literals.beforeOpen, this.ddBeforeOpen);
        this.obj.addEventListener(events.actionComplete, this.ddComplete);
    }
    removeEventListener() {
        if (this.obj.isDestroyed) {
            return;
        }
        this.obj.removeEventListener(literals.create, this.ddCreated);
        this.obj.removeEventListener(literals.open, this.ddOpen);
        this.obj.removeEventListener(literals.beforeOpen, this.ddBeforeOpen);
        this.obj.removeEventListener(events.actionComplete, this.ddComplete);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dropdownCreated(e) {
        this.flag = true;
    }
    dropdownBeforeOpen() {
        if (this.parent.enableVirtualization) {
            this.obj.dataSource = !this.column.isForeignColumn() ? (this.parent.dataSource instanceof DataManager ?
                this.parent.dataSource : new DataManager(this.parent.dataSource))
                : this.column.dataSource instanceof DataManager ?
                    this.column.dataSource : new DataManager(this.column.dataSource);
        }
    }
    ddActionComplete(e) {
        e.result = DataUtil.distinct(e.result, this.obj.fields.value, true);
        if (this.flag && this.column.dataSource && !(this.column.edit.params &&
            this.column.edit.params.ddEditedData)) {
            if ('result' in this.column.dataSource) {
                this.column.dataSource.result = e.result;
            }
            else if (this.column.dataSource instanceof DataManager) {
                this.column.dataSource.dataSource.json = e.result;
            }
        }
        this.flag = false;
    }
    dropDownOpen(args) {
        const dlgElement = parentsUntil(this.obj.element, 'e-dialog');
        if (this.parent.editSettings.mode === 'Dialog' && !isNullOrUndefined(dlgElement)) {
            const dlgObj = select('#' + dlgElement.id, document).ej2_instances[0];
            args.popup.element.style.zIndex = (dlgObj.zIndex + 1).toString();
        }
    }
}
