/**
 * Specifies class names
 */
/** @hidden */
export const rowCell = 'e-rowcell';
/** @hidden */
export const gridHeader = 'e-gridheader';
/** @hidden */
export const gridContent = 'e-gridcontent';
/** @hidden */
export const gridFooter = 'e-gridfooter';
/** @hidden */
export const headerContent = 'e-headercontent';
/** @hidden */
export const movableContent = 'e-movablecontent';
/** @hidden */
export const movableHeader = 'e-movableheader';
/** @hidden */
export const frozenContent = 'e-frozencontent';
/** @hidden */
export const frozenHeader = 'e-frozenheader';
/** @hidden */
export const content = 'e-content';
/** @hidden */
export const table = 'e-table';
/** @hidden */
export const row = 'e-row';
/** @hidden */
export const gridChkBox = 'e-gridchkbox';
/** @hidden */
export const editedRow = 'e-editedrow';
/** @hidden */
export const addedRow = 'e-addedrow';
/**
 * Specifies repeated strings
 */
/** @hidden */
export const changedRecords = 'changedRecords';
/** @hidden */
export const addedRecords = 'addedRecords';
/** @hidden */
export const deletedRecords = 'deletedRecords';
/** @hidden */
export const leftRight = 'Left-Right';
/** @hidden */
export const frozenRight = 'frozen-right';
/** @hidden */
export const frozenLeft = 'frozen-left';
/** @hidden */
export const dataColIndex = 'data-colindex';
/** @hidden */
export const ariaColIndex = 'aria-colindex';
/** @hidden */
export const dataRowIndex = 'data-rowindex';
/** @hidden */
export const ariaRowIndex = 'aria-rowindex';
/** @hidden */
export const tbody = 'tbody';
/** @hidden */
export const colGroup = 'colgroup';
/** @hidden */
export const open = 'open';
/** @hidden */
export const change = 'change';
/** @hidden */
export const focus = 'focus';
/** @hidden */
export const create = 'created';
/** @hidden */
export const beforeOpen = 'beforeOpen';
/** @hidden */
export const downArrow = 'downArrow';
/** @hidden */
export const upArrow = 'upArrow';
/** @hidden */
export const pageUp = 'PageUp';
/** @hidden */
export const pageDown = 'PageDown';
/** @hidden */
export const enter = 'enter';
/** @hidden */
export const shiftEnter = 'shiftEnter';
/** @hidden */
export const tab = 'tab';
/** @hidden */
export const shiftTab = 'shiftTab';
