import { initialLoad } from '../base/constant';
import { RenderType } from '../base/enum';
import { VirtualContentRenderer, VirtualHeaderRenderer } from '../renderer/virtual-content-renderer';
import * as events from '../base/constant';
import { RowRenderer } from '../renderer/row-renderer';
import { extend, getValue, isNullOrUndefined, remove } from '@syncfusion/ej2-base';
import { setComplexFieldID, setValidationRuels, getColumnModelByUid } from '../base/util';
/**
 * Virtual Scrolling class
 */
export class VirtualScroll {
    constructor(parent, locator) {
        this.parent = parent;
        this.locator = locator;
        this.addEventListener();
    }
    getModuleName() {
        return 'virtualscroll';
    }
    instantiateRenderer() {
        this.parent.log(['limitation', 'virtual_height'], 'virtualization');
        const renderer = this.locator.getService('rendererFactory');
        if (!this.parent.isFrozenGrid()) {
            if (this.parent.enableColumnVirtualization) {
                renderer.addRenderer(RenderType.Header, new VirtualHeaderRenderer(this.parent, this.locator));
            }
            renderer.addRenderer(RenderType.Content, new VirtualContentRenderer(this.parent, this.locator));
        }
        this.ensurePageSize();
    }
    ensurePageSize() {
        const rowHeight = this.parent.getRowHeight();
        const vHeight = this.parent.height.toString().indexOf('%') < 0 ? this.parent.height :
            this.parent.element.getBoundingClientRect().height;
        this.blockSize = ~~(vHeight / rowHeight);
        const height = this.blockSize * 2;
        const size = this.parent.pageSettings.pageSize;
        this.parent.setProperties({ pageSettings: { pageSize: size < height ? height : size } }, true);
    }
    addEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.on(initialLoad, this.instantiateRenderer, this);
        this.parent.on(events.columnWidthChanged, this.refreshVirtualElement, this);
        this.parent.on(events.createVirtualValidationForm, this.createVirtualValidationForm, this);
        this.parent.on(events.validateVirtualForm, this.virtualEditFormValidation, this);
        this.parent.on(events.destroy, this.destroy, this);
    }
    removeEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.off(initialLoad, this.instantiateRenderer);
        this.parent.off(events.columnWidthChanged, this.refreshVirtualElement);
        this.parent.off(events.createVirtualValidationForm, this.createVirtualValidationForm);
        this.parent.off(events.validateVirtualForm, this.virtualEditFormValidation);
        this.parent.off(events.destroy, this.destroy);
    }
    getCurrentEditedData(prevData) {
        const data = {
            virtualData: extend({}, {}, prevData, true), isAdd: false, isScroll: false, endEdit: true
        };
        this.parent.notify(events.getVirtualData, data);
        return data.virtualData;
    }
    createVirtualValidationForm(e) {
        const gObj = this.parent;
        if (gObj.enableVirtualization && gObj.editSettings.mode === 'Normal') {
            const cols = gObj.columns;
            const rowRenderer = new RowRenderer(this.locator, null, this.parent);
            const rowObj = extend({}, {}, gObj.getRowObjectFromUID(e.uid), true);
            gObj.notify(events.refreshVirtualEditFormCells, rowObj);
            const args = e.argsCreator(this.getCurrentEditedData(e.prevData), {}, false);
            args.isCustomFormValidation = true;
            args.row = rowRenderer.render(rowObj, cols);
            e.renderer.update(args);
            const rules = {};
            for (let i = 0; i < cols.length; i++) {
                if (!cols[i].visible) {
                    continue;
                }
                if (cols[i].validationRules) {
                    setValidationRuels(cols[i], 0, rules, {}, {}, cols.length, true);
                }
            }
            args.form.classList.add('e-virtual-validation');
            gObj.editModule.virtualFormObj = gObj.editModule.createFormObj(args.form, rules);
        }
    }
    virtualEditFormValidation(args) {
        const gObj = this.parent;
        const error = gObj.element.querySelector('.e-griderror:not([style*="display: none"])');
        if (gObj.editModule.virtualFormObj) {
            if (error && error.style.display !== 'none') {
                const errorDomRect = error.getBoundingClientRect();
                const forms = gObj.element.querySelectorAll('.e-gridform');
                let form = forms[0];
                let contentLeft = gObj.getContent().getBoundingClientRect().left;
                if (forms.length > 1) {
                    form = gObj.getFrozenMode() !== 'Right' ? forms[1] : forms[0];
                    contentLeft = gObj.getMovableVirtualContent().getBoundingClientRect().left;
                }
                if (errorDomRect.left < contentLeft || errorDomRect.right > gObj.element.offsetWidth) {
                    const tooltip = form.querySelector('.e-tooltip-wrap:not([style*="display: none"])');
                    this.scrollToEdit(tooltip, { editIdx: args.editIdx, addIdx: args.addIdx }, true);
                }
            }
            else if (gObj.editModule.virtualFormObj && (!error || error.style.display === 'none')) {
                const existingErrors = gObj.editModule.virtualFormObj.element.querySelectorAll('.e-tooltip-wrap:not([style*="display: none"])');
                for (let i = 0; i < existingErrors.length; i++) {
                    remove(existingErrors[i]);
                }
                this.setEditedDataToValidationForm(gObj.editModule.virtualFormObj.element, this.getCurrentEditedData(args.prevData));
                args.isValid = gObj.editModule.virtualFormObj.validate();
                if (!args.isValid) {
                    const tooltip = gObj.editModule.virtualFormObj.element.querySelector('.e-tooltip-wrap:not([style*="display: none"])');
                    this.scrollToEdit(tooltip, { editIdx: args.editIdx, addIdx: args.addIdx });
                }
            }
        }
    }
    scrollToEdit(tooltip, args, isRenderer) {
        const gObj = this.parent;
        if (tooltip) {
            const cols = gObj.columnModel;
            const field = setComplexFieldID(tooltip.id).split('_')[0];
            const col = gObj.getColumnByField(field);
            const scrollTop = this.parent.getContent().firstElementChild.scrollTop;
            const row = gObj.getRowByIndex(args.editIdx);
            if (isRenderer || !col || (!isNullOrUndefined(args.addIdx) && scrollTop > 0) || (!isNullOrUndefined(args.editIdx) && !row)) {
                let validationCol;
                for (let i = 0; i < cols.length && !col; i++) {
                    if (cols[i].field === field) {
                        validationCol = cols[i];
                        break;
                    }
                }
                if (isRenderer) {
                    validationCol = col;
                }
                this.parent.notify(events.scrollToEdit, validationCol);
            }
        }
    }
    setEditedDataToValidationForm(form, editedData) {
        const inputs = [].slice.call(form.getElementsByClassName('e-field'));
        for (let i = 0, len = inputs.length; i < len; i++) {
            const col = getColumnModelByUid(this.parent, inputs[i].getAttribute('e-mappinguid'));
            let value = getValue(col.field, editedData);
            value = isNullOrUndefined(value) ? '' : value;
            inputs[i].value = value;
        }
    }
    refreshVirtualElement(args) {
        if (this.parent.enableColumnVirtualization && args.module === 'resize') {
            const renderer = this.locator.getService('rendererFactory');
            renderer.getRenderer(RenderType.Content).refreshVirtualElement();
        }
    }
    destroy() {
        this.removeEventListener();
    }
}
