import { setStyleAndAttributes } from '../base/util';
import { CellRenderer } from './cell-renderer';
/**
 * IndentCellRenderer class which responsible for building group indent cell.
 *
 * @hidden
 */
export class IndentCellRenderer extends CellRenderer {
    constructor() {
        super(...arguments);
        this.element = this.parent.createElement('TD', { className: 'e-indentcell' });
    }
    /**
     * Function to render the indent cell
     *
     * @param  {Cell} cell - specifies the cell
     * @param  {Object} data - specifies the data
     * @returns {Element} returns the element
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render(cell, data) {
        const node = this.element.cloneNode();
        setStyleAndAttributes(node, cell.attributes);
        return node;
    }
}
