import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { Internationalization, setCulture } from '@syncfusion/ej2-base';
/**
 * ValueFormatter class to globalize the value.
 *
 * @hidden
 */
export class ValueFormatter {
    constructor(cultureName) {
        this.intl = new Internationalization();
        if (!isNullOrUndefined(cultureName)) {
            this.intl.culture = cultureName;
        }
    }
    getFormatFunction(format) {
        if (format.type) {
            return this.intl.getDateFormat(format);
        }
        else {
            return this.intl.getNumberFormat(format);
        }
    }
    getParserFunction(format) {
        if (format.type) {
            return this.intl.getDateParser(format);
        }
        else {
            return this.intl.getNumberParser(format);
        }
    }
    fromView(value, format, type) {
        if ((type === 'date' || type === 'datetime' || type === 'number') && (!isNullOrUndefined(format))) {
            return format(value);
        }
        else {
            return value;
        }
    }
    toView(value, format) {
        let result = value;
        if (!isNullOrUndefined(format) && !isNullOrUndefined(value)) {
            result = format(value);
        }
        return result;
    }
    setCulture(cultureName) {
        if (!isNullOrUndefined(cultureName)) {
            setCulture(cultureName);
        }
    }
}
