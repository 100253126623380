import { Row } from './../models/row';
import { CellType } from '../base/enum';
import { isNullOrUndefined, Internationalization, getValue, createElement } from '@syncfusion/ej2-base';
import { Cell } from '../models/cell';
import { ValueFormatter } from './../services/value-formatter';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { getForeignData, measureColumnDepth, getUid } from '../base/util';
import { Grid } from '../base/grid';
/**
 * @hidden
 * `ExportHelper` for `PdfExport` & `ExcelExport`
 */
export class ExportHelper {
    constructor(parent, foreignKeyData) {
        this.hideColumnInclude = false;
        this.foreignKeyData = {};
        this.parent = parent;
        if (!parent.parentDetails && foreignKeyData) {
            this.foreignKeyData = foreignKeyData;
        }
    }
    static getQuery(parent, data) {
        const query = data.generateQuery(true).requiresCount();
        if (data.isRemote()) {
            if (parent.groupSettings.enableLazyLoading && parent.groupSettings.columns.length) {
                query.lazyLoad = [];
            }
            else {
                query.take(parent.pageSettings.totalRecordsCount);
            }
        }
        return query;
    }
    getFData(value, column) {
        const foreignKeyData = getForeignData(column, {}, value, this.foreignKeyData[column.field])[0];
        return foreignKeyData;
    }
    getGridRowModel(columns, dataSource, gObj, startIndex = 0) {
        const rows = [];
        const length = dataSource.length;
        if (length) {
            for (let i = 0; i < length; i++, startIndex++) {
                const options = { isExpand: false };
                options.data = dataSource[i];
                options.index = startIndex;
                if (gObj.childGrid) {
                    if (gObj.hierarchyPrintMode === 'All') {
                        options.isExpand = true;
                    }
                    else if (gObj.hierarchyPrintMode === 'Expanded' &&
                        this.parent.expandedRows && this.parent.expandedRows[startIndex]) {
                        options.isExpand = gObj.expandedRows[startIndex].isExpand;
                    }
                }
                const row = new Row(options);
                row.cells = this.generateCells(columns, gObj);
                rows.push(row);
            }
            this.processColumns(rows);
        }
        return rows;
    }
    generateCells(columns, gObj) {
        const cells = [];
        if (gObj.childGridLevel) {
            const len = gObj.childGridLevel;
            for (let i = 0; len > i; i++) {
                cells.push(this.generateCell({}, CellType.Indent));
            }
        }
        for (const col of columns) {
            cells.push(this.generateCell(col, CellType.Data));
        }
        return cells;
    }
    getColumnData(gridObj) {
        const columnPromise = [];
        let promise;
        const fColumns = gridObj.getForeignKeyColumns();
        if (fColumns.length) {
            for (let i = 0; i < fColumns.length; i++) {
                const colData = ('result' in fColumns[i].dataSource) ?
                    new DataManager(fColumns[i].dataSource.result) :
                    fColumns[i].dataSource;
                columnPromise.push(colData.executeQuery(new Query()));
            }
            promise = Promise.all(columnPromise).then((e) => {
                for (let j = 0; j < fColumns.length; j++) {
                    this.foreignKeyData[fColumns[j].field] = e[j].result;
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            });
        }
        return promise;
    }
    getHeaders(columns, isHideColumnInclude) {
        if (isHideColumnInclude) {
            this.hideColumnInclude = true;
        }
        else {
            this.hideColumnInclude = false;
        }
        this.colDepth = measureColumnDepth(columns);
        let rows = [];
        for (let i = 0; i < this.colDepth; i++) {
            rows[i] = new Row({});
            rows[i].cells = [];
        }
        rows = this.processColumns(rows);
        rows = this.processHeaderCells(rows, columns);
        return { rows, columns: this.generateActualColumns(columns) };
    }
    getConvertedWidth(input) {
        const value = parseFloat(input);
        return (input.indexOf('%') !== -1) ? (this.parent.element.getBoundingClientRect().width * value / 100) : value;
    }
    generateActualColumns(columns, actualColumns = []) {
        for (const column of columns) {
            if (column.commands) {
                continue;
            }
            if (!column.columns) {
                if (column.visible || this.hideColumnInclude) {
                    actualColumns.push(column);
                }
            }
            else {
                if (column.visible || this.hideColumnInclude) {
                    const colSpan = this.getCellCount(column, 0);
                    if (colSpan !== 0) {
                        this.generateActualColumns(column.columns, actualColumns);
                    }
                }
            }
        }
        return actualColumns;
    }
    processHeaderCells(rows, cols) {
        const columns = cols;
        for (let i = 0; i < columns.length; i++) {
            if (!columns[i].commands) {
                rows = this.appendGridCells(columns[i], rows, 0);
            }
        }
        return rows;
    }
    appendGridCells(cols, gridRows, index) {
        if (!cols.columns && (cols.visible !== false || this.hideColumnInclude) && !cols.commands) {
            gridRows[index].cells.push(this.generateCell(cols, CellType.Header, this.colDepth - index, index));
        }
        else if (cols.columns) {
            const colSpan = this.getCellCount(cols, 0);
            if (colSpan) {
                gridRows[index].cells.push(new Cell({
                    cellType: CellType.StackedHeader, column: cols, colSpan: colSpan
                }));
            }
            let isIgnoreFirstCell;
            for (let i = 0, len = cols.columns.length; i < len; i++) {
                if (cols.columns[i].visible && !isIgnoreFirstCell) {
                    isIgnoreFirstCell = true;
                }
                gridRows = this.appendGridCells(cols.columns[i], gridRows, index + 1);
            }
        }
        return gridRows;
    }
    generateCell(gridColumn, cellType, rowSpan, rowIndex) {
        const option = {
            'visible': gridColumn.visible,
            'isDataCell': cellType === CellType.Data,
            'column': gridColumn,
            'cellType': cellType,
            'rowSpan': rowSpan,
            'index': rowIndex
        };
        if (!option.rowSpan || option.rowSpan < 2) {
            delete option.rowSpan;
        }
        return new Cell(option);
    }
    processColumns(rows) {
        //TODO: generate dummy column for group, detail, stacked row here; ensureColumns here
        const gridObj = this.parent;
        let columnIndexes = [];
        if (gridObj.enableColumnVirtualization) {
            columnIndexes = gridObj.getColumnIndexesInView();
        }
        for (let i = 0, len = rows.length; i < len; i++) {
            if (gridObj.allowGrouping) {
                for (let j = 0, len = gridObj.groupSettings.columns.length - 1; j < len; j++) {
                    if (gridObj.enableColumnVirtualization && columnIndexes.indexOf(j) === -1) {
                        continue;
                    }
                    rows[i].cells.splice(0, 0, this.generateCell({}, CellType.HeaderIndent));
                }
            }
        }
        return rows;
    }
    getCellCount(column, count) {
        if (column.columns) {
            for (let i = 0; i < column.columns.length; i++) {
                count = this.getCellCount(column.columns[i], count);
            }
        }
        else {
            if (column.visible || this.hideColumnInclude) {
                count++;
            }
        }
        return count;
    }
    checkAndExport(gridPool, globalResolve) {
        const bool = Object.keys(gridPool).some((key) => {
            return !gridPool[key];
        });
        if (!bool) {
            globalResolve();
        }
    }
    failureHandler(gridPool, childGridObj, resolve) {
        return () => {
            gridPool[childGridObj.id] = true;
            this.checkAndExport(gridPool, resolve);
        };
    }
    createChildGrid(gObj, row, exportType, gridPool) {
        const childGridObj = new Grid(this.parent.detailRowModule.getGridModel(gObj, row, exportType));
        gObj.isPrinting = false;
        const parent = 'parentDetails';
        childGridObj[parent] = {
            parentID: gObj.element.id,
            parentPrimaryKeys: gObj.getPrimaryKeyFieldNames(),
            parentKeyField: gObj.childGrid.queryString,
            parentKeyFieldValue: getValue(childGridObj.queryString, row.data),
            parentRowData: row.data
        };
        const exportId = getUid('child-grid');
        const element = createElement('div', {
            id: exportId, styles: 'display: none'
        });
        document.body.appendChild(element);
        childGridObj.id = exportId;
        gridPool[exportId] = false;
        childGridObj.isExportGrid = true;
        return { childGrid: childGridObj, element };
    }
    getGridExportColumns(columns) {
        const actualGridColumns = [];
        for (let i = 0, gridColumns = columns; i < gridColumns.length; i++) {
            if (gridColumns[i].type !== 'checkbox') {
                actualGridColumns.push(gridColumns[i]);
            }
        }
        return actualGridColumns;
    }
    /**
     * Gets the foreignkey data.
     *
     * @returns {ForeignKeyFormat} returns the foreignkey data
     * @hidden
     */
    getForeignKeyData() {
        return this.foreignKeyData;
    }
}
/**
 * @hidden
 * `ExportValueFormatter` for `PdfExport` & `ExcelExport`
 */
export class ExportValueFormatter {
    constructor(culture) {
        this.valueFormatter = new ValueFormatter(culture);
        this.internationalization = new Internationalization(culture);
    }
    returnFormattedValue(args, customFormat) {
        if (!isNullOrUndefined(args.value) && args.value) {
            return this.valueFormatter.getFormatFunction(customFormat)(args.value);
        }
        else {
            return '';
        }
    }
    /**
     * Used to format the exporting cell value
     *
     * @param  {ExportHelperArgs} args - Specifies cell details.
     * @returns {string} returns formated value
     * @hidden
     */
    formatCellValue(args) {
        if (args.isForeignKey) {
            args.value = getValue(args.column.foreignKeyValue, getForeignData(args.column, {}, args.value)[0]);
        }
        if (args.column.type === 'number' && args.column.format !== undefined && args.column.format !== '') {
            if (typeof args.column.format === 'string') {
                args.column.format = { format: args.column.format };
            }
            return args.value || args.value === 0 ?
                this.internationalization.getNumberFormat(args.column.format)(args.value) : '';
        }
        else if (args.column.type === 'boolean' && args.value !== '') {
            return args.value ? 'true' : 'false';
            /* tslint:disable-next-line:max-line-length */
        }
        else if ((args.column.type === 'date' || args.column.type === 'datetime' || args.column.type === 'time') && args.column.format !== undefined) {
            if (typeof args.value === 'string') {
                args.value = new Date(args.value);
            }
            if (typeof args.column.format === 'string') {
                let format;
                const cFormat = args.column.format;
                if (args.column.type === 'date') {
                    format = { type: 'date', skeleton: cFormat };
                }
                else if (args.column.type === 'time') {
                    format = { type: 'time', skeleton: cFormat };
                }
                else {
                    format = { type: 'dateTime', skeleton: cFormat };
                }
                return this.returnFormattedValue(args, format);
            }
            else {
                if (args.column.format instanceof Object && args.column.format.type === undefined) {
                    return (args.value.toString());
                }
                else {
                    let customFormat;
                    if (args.column.type === 'date') {
                        customFormat = {
                            type: args.column.format.type,
                            format: args.column.format.format, skeleton: args.column.format.skeleton
                        };
                    }
                    else if (args.column.type === 'time') {
                        customFormat = { type: 'time', format: args.column.format.format, skeleton: args.column.format.skeleton };
                    }
                    else {
                        customFormat = { type: 'dateTime', format: args.column.format.format, skeleton: args.column.format.skeleton };
                    }
                    return this.returnFormattedValue(args, customFormat);
                }
            }
        }
        else {
            if ((!isNullOrUndefined(args.column.type) && !isNullOrUndefined(args.value)) || !isNullOrUndefined(args.value)) {
                return (args.value).toString();
            }
            else {
                return '';
            }
        }
    }
}
