import { isNullOrUndefined, attributes } from '@syncfusion/ej2-base';
import { iterateArrayOrObject } from '../base/util';
import { CellType } from '../base/enum';
/**
 * `CellMergeRender` module.
 *
 * @hidden
 */
export class CellMergeRender {
    constructor(serviceLocator, parent) {
        this.serviceLocator = serviceLocator;
        this.parent = parent;
    }
    render(cellArgs, row, i, td) {
        const cellRendererFact = this.serviceLocator.getService('cellRendererFactory');
        const cellRenderer = cellRendererFact.getCellRenderer(row.cells[i].cellType || CellType.Data);
        let colSpan = row.cells[i].cellSpan ? row.cells[i].cellSpan :
            (cellArgs.colSpan + i) <= row.cells.length ? cellArgs.colSpan : row.cells.length - i;
        const rowSpan = cellArgs.rowSpan;
        let visible = 0;
        let spannedCell;
        if (row.index > 0) {
            const cells = this.parent.groupSettings.columns.length > 0 &&
                !this.parent.getRowsObject()[row.index - 1].isDataRow ? this.parent.getRowsObject()[row.index].cells :
                this.parent.getRowsObject()[row.index - 1].cells;
            const targetCell = row.cells[i];
            const uid = 'uid';
            spannedCell = cells.filter((cell) => cell.column.uid === targetCell.column[uid])[0];
        }
        const colSpanLen = spannedCell && spannedCell.colSpanRange > 1 && spannedCell.rowSpanRange > 1 ?
            spannedCell.colSpanRange : colSpan;
        for (let j = i + 1; j < i + colSpanLen && j < row.cells.length; j++) {
            if (row.cells[j].visible === false) {
                visible++;
            }
            else {
                row.cells[j].isSpanned = true;
            }
        }
        if (visible > 0) {
            for (let j = i + colSpan; j < i + colSpan + visible && j < row.cells.length; j++) {
                row.cells[j].isSpanned = true;
            }
            if (i + colSpan + visible >= row.cells.length) {
                colSpan -= (i + colSpan + visible) - row.cells.length;
            }
        }
        if (row.cells[i].cellSpan) {
            row.data[cellArgs.column.field] = row.cells[i].spanText;
            td = cellRenderer.render(row.cells[i], row.data, { 'index': !isNullOrUndefined(row.index) ? row.index.toString() : '' });
        }
        if (colSpan > 1) {
            attributes(td, { 'colSpan': colSpan.toString(), 'aria-colSpan': colSpan.toString() });
        }
        if (rowSpan > 1) {
            attributes(td, { 'rowspan': rowSpan.toString(), 'aria-rowspan': rowSpan.toString() });
            row.cells[i].isRowSpanned = true;
            row.cells[i].rowSpanRange = Number(rowSpan);
            if (colSpan > 1) {
                row.cells[i].colSpanRange = Number(colSpan);
            }
        }
        if (row.index > 0 && (spannedCell.rowSpanRange > 1)) {
            row.cells[i].isSpanned = true;
            row.cells[i].rowSpanRange = Number(spannedCell.rowSpanRange - 1);
            row.cells[i].colSpanRange = spannedCell.rowSpanRange > 0 ? spannedCell.colSpanRange : 1;
        }
        if (this.parent.enableColumnVirtualization && !row.cells[i].cellSpan &&
            !this.containsKey(cellArgs.column.field, cellArgs.data[cellArgs.column.field])) {
            this.backupMergeCells(cellArgs.column.field, cellArgs.data[cellArgs.column.field], cellArgs.colSpan);
        }
        return td;
    }
    backupMergeCells(fName, data, span) {
        this.setMergeCells(this.generteKey(fName, data), span);
    }
    generteKey(fname, data) {
        return fname + '__' + data.toString();
    }
    splitKey(key) {
        return key.split('__');
    }
    containsKey(fname, data) {
        // eslint-disable-next-line no-prototype-builtins
        return this.getMergeCells().hasOwnProperty(this.generteKey(fname, data));
    }
    getMergeCells() {
        return this.parent.mergeCells;
    }
    setMergeCells(key, span) {
        this.parent.mergeCells[key] = span;
    }
    updateVirtualCells(rows) {
        const mCells = this.getMergeCells();
        for (const key of Object.keys(mCells)) {
            const value = mCells[key];
            const merge = this.splitKey(key);
            const columnIndex = this.getIndexFromAllColumns(merge[0]);
            const vColumnIndices = this.parent.getColumnIndexesInView();
            const span = value - (vColumnIndices[0] - columnIndex);
            if (columnIndex < vColumnIndices[0] && span > 1) {
                for (const row of rows) {
                    if (row.data[merge[0]].toString() === merge[1].toString()) {
                        row.cells[0].cellSpan = span;
                        row.cells[0].spanText = merge[1];
                        break;
                    }
                }
            }
        }
        return rows;
    }
    getIndexFromAllColumns(field) {
        const index = iterateArrayOrObject(this.parent.getVisibleColumns(), (item, index) => {
            if (item.field === field) {
                return index;
            }
            return undefined;
        })[0];
        return index;
    }
}
