import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { CheckBox } from '@syncfusion/ej2-buttons';
import { extend } from '@syncfusion/ej2-base';
import { isEditable, addRemoveActiveClasses, createEditElement, getObject } from '../base/util';
import * as literals from '../base/string-literals';
import { EditCellBase } from './edit-cell-base';
/**
 * `BooleanEditCell` is used to handle boolean cell type editing.
 *
 * @hidden
 */
export class BooleanEditCell extends EditCellBase {
    constructor() {
        super(...arguments);
        this.activeClasses = ['e-selectionbackground', 'e-active'];
    }
    create(args) {
        const col = args.column;
        let classNames = 'e-field e-boolcell';
        if (col.type === 'checkbox') {
            classNames = 'e-field e-boolcell e-edit-checkselect';
        }
        this.removeEventHandler = this.removeEventListener;
        return createEditElement(this.parent, args.column, classNames, { type: 'checkbox', value: args.value });
    }
    read(element) {
        return element.checked;
    }
    write(args) {
        let selectChkBox;
        let chkState;
        if (!isNullOrUndefined(args.row)) {
            selectChkBox = args.row.querySelector('.e-edit-checkselect');
        }
        if (getObject(args.column.field, args.rowData)) {
            chkState = JSON.parse(getObject(args.column.field, args.rowData).toString().toLowerCase());
        }
        if (!isNullOrUndefined(selectChkBox) && args.column.type === 'checkbox') {
            this.editType = this.parent.editSettings.mode;
            this.editRow = args.row;
            if (args.requestType !== 'add') {
                const row = this.parent.getRowObjectFromUID(args.row.getAttribute('data-uid'));
                chkState = row ? row.isSelected : false;
            }
            addRemoveActiveClasses([].slice.call(args.row.getElementsByClassName(literals.rowCell)), chkState, ...this.activeClasses);
        }
        this.obj = new CheckBox(extend({
            label: this.parent.editSettings.mode !== 'Dialog' ? ' ' : args.column.headerText,
            checked: chkState,
            disabled: !isEditable(args.column, args.requestType, args.element), enableRtl: this.parent.enableRtl,
            cssClass: this.parent.cssClass ? this.parent.cssClass : ''
        }, args.column.edit.params));
        this.addEventListener();
        this.obj.appendTo(args.element);
    }
    addEventListener() {
        this.cbChange = this.checkBoxChange.bind(this);
        this.obj.addEventListener(literals.change, this.cbChange);
    }
    removeEventListener() {
        if (this.obj.isDestroyed) {
            return;
        }
        this.obj.removeEventListener(literals.change, this.cbChange);
    }
    checkBoxChange(args) {
        if (this.editRow && this.editType !== 'Dialog') {
            let add = false;
            if (!args.checked) {
                this.editRow.removeAttribute('aria-selected');
            }
            else {
                add = true;
                this.editRow.setAttribute('aria-selected', add.toString());
            }
            addRemoveActiveClasses([].slice.call(this.editRow.getElementsByClassName(literals.rowCell)), add, ...this.activeClasses);
        }
    }
}
