import { extend } from '@syncfusion/ej2-base';
import { ComboBox } from '@syncfusion/ej2-dropdowns';
import { Query, DataManager, DataUtil } from '@syncfusion/ej2-data';
import { isEditable, getObject } from '../base/util';
import { EditCellBase } from './edit-cell-base';
/**
 * `ComboBoxEditCell` is used to handle ComboBoxEdit cell type editing.
 *
 * @hidden
 */
export class ComboboxEditCell extends EditCellBase {
    write(args) {
        this.column = args.column;
        const isInlineMode = this.parent.editSettings.mode !== 'Dialog';
        this.obj = new ComboBox(extend({
            dataSource: this.parent.dataSource instanceof DataManager ?
                this.parent.dataSource : new DataManager(this.parent.dataSource),
            query: new Query().select(args.column.field),
            fields: { value: args.column.field },
            value: getObject(args.column.field, args.rowData),
            enableRtl: this.parent.enableRtl, actionComplete: this.finalValue.bind(this),
            placeholder: isInlineMode ? '' : args.column.headerText,
            floatLabelType: isInlineMode ? 'Never' : 'Always',
            enabled: isEditable(args.column, args.requestType, args.element),
            cssClass: this.parent.cssClass ? this.parent.cssClass : null
        }, args.column.edit.params));
        this.obj.appendTo(args.element);
    }
    finalValue(val) {
        val.result = DataUtil.distinct(val.result, this.obj.fields.value, true);
        if (this.column.dataSource) {
            this.column.dataSource.dataSource.json = val.result;
        }
    }
}
