import { NormalEdit } from './normal-edit';
/**
 * `DialogEdit` module is used to handle dialog editing actions.
 *
 * @hidden
 */
export class DialogEdit extends NormalEdit {
    constructor(parent, serviceLocator, renderer) {
        //constructor
        super(parent, serviceLocator);
        this.parent = parent;
        this.serviceLocator = serviceLocator;
        this.renderer = renderer;
    }
    closeEdit() {
        //closeEdit
        super.closeEdit();
    }
    addRecord(data, index) {
        //addRecord
        super.addRecord(data, index);
    }
    endEdit() {
        //endEdit
        super.endEdit();
    }
    updateRow(index, data) {
        super.updateRow(index, data);
    }
    deleteRecord(fieldname, data) {
        //deleteRecord
        super.deleteRecord(fieldname, data);
    }
    startEdit(tr) {
        super.startEdit(tr);
    }
}
