import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { CellRenderer } from './cell-renderer';
import { appendChildren, templateCompiler } from '../base/util';
/**
 * GroupCaptionCellRenderer class which responsible for building group caption cell.
 *
 * @hidden
 */
export class GroupCaptionCellRenderer extends CellRenderer {
    constructor() {
        super(...arguments);
        this.cellUid = 0;
        this.element = this.parent
            .createElement('TD', { className: 'e-groupcaption',
            attrs: { id: this.parent.element.id + 'captioncell', role: 'gridcell', tabindex: '-1' } });
    }
    /**
     * Function to render the cell content based on Column object.
     *
     * @param  {Cell} cell - specifies the cell
     * @param  {Object} data - specifies the GroupedData
     * @returns {Element} returns the element
     */
    render(cell, data) {
        this.element.id = this.parent.element.id + 'captioncell' + this.cellUid++;
        const node = this.element.cloneNode();
        const gObj = this.parent;
        let result;
        let fKeyValue;
        let gTemplateValue;
        data.headerText = cell.column.headerText;
        if (cell.isForeignKey) {
            fKeyValue = this.format(cell.column, cell.column.valueAccessor('foreignKey', data, cell.column));
        }
        const value = cell.isForeignKey ? fKeyValue : cell.column.enableGroupByFormat ? data.key :
            this.format(cell.column, cell.column.valueAccessor('key', data, cell.column));
        for (let j = 0; j < gObj.aggregates.length; j++) {
            for (let i = 0; i < gObj.aggregates[j].columns.length; i++) {
                if (gObj.getVisibleColumns()[0].field === gObj.aggregates[j].columns[i].field &&
                    gObj.aggregates[j].columns[i].groupCaptionTemplate) {
                    if (gObj.aggregates[j].columns[i].groupCaptionTemplate.includes('$')) {
                        gTemplateValue = gObj.aggregates[j].columns[i].groupCaptionTemplate.split('$')[0] + data[gObj.getVisibleColumns()[0].field][gObj.aggregates[j].columns[i].type] +
                            gObj.aggregates[j].columns[i].groupCaptionTemplate.split('}')[1];
                    }
                    else {
                        gTemplateValue = gObj.aggregates[j].columns[i].groupCaptionTemplate;
                    }
                    break;
                }
            }
        }
        if (!isNullOrUndefined(gObj.groupSettings.captionTemplate)) {
            const isReactCompiler = this.parent.isReact && typeof (gObj.groupSettings.captionTemplate) !== 'string';
            if (isReactCompiler) {
                const tempID = gObj.element.id + 'captionTemplate';
                templateCompiler(gObj.groupSettings.captionTemplate)(data, this.parent, 'captionTemplate', tempID, null, null, node);
                this.parent.renderTemplates();
            }
            else if (this.parent.isVue) {
                result = templateCompiler(gObj.groupSettings.captionTemplate)(data, this.parent);
            }
            else {
                result = templateCompiler(gObj.groupSettings.captionTemplate)(data);
            }
            if (!isReactCompiler) {
                appendChildren(node, result);
            }
        }
        else {
            if (gObj.groupSettings.enableLazyLoading) {
                node.innerHTML = cell.column.headerText + ': ' + value + (gTemplateValue ? '   ' + gTemplateValue : '');
            }
            else {
                node.innerHTML = cell.column.headerText + ': ' + value + ' - ' + data.count + ' ' +
                    (data.count < 2 ? this.localizer.getConstant('Item') : this.localizer.getConstant('Items'))
                    + (gTemplateValue ? '   ' + gTemplateValue : '');
            }
        }
        node.setAttribute('colspan', cell.colSpan.toString());
        node.setAttribute('aria-label', node.innerHTML + ' is groupcaption cell');
        node.setAttribute('title', node.innerHTML);
        return node;
    }
}
/**
 * GroupCaptionEmptyCellRenderer class which responsible for building group caption empty cell.
 *
 * @hidden
 */
export class GroupCaptionEmptyCellRenderer extends CellRenderer {
    constructor() {
        super(...arguments);
        this.element = this.parent.createElement('TD', { className: 'e-groupcaption' });
    }
    /**
     * Function to render the cell content based on Column object.
     *
     * @param {Cell} cell - specifies the cell
     * @param {Object} data - specifies the Object
     * @param {string} data.field - Defines the field
     * @param {string} data.key - Defines the key
     * @param {number} data.count - Defines the count
     * @returns {Element} returns the element
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render(cell, data) {
        const node = this.element.cloneNode();
        node.innerHTML = '&nbsp;';
        node.setAttribute('colspan', cell.colSpan.toString());
        return node;
    }
}
