import { extend, getValue } from '@syncfusion/ej2-base';
import { MultiSelect } from '@syncfusion/ej2-dropdowns';
import { getComplexFieldID } from '../base/util';
import { EditCellBase } from './edit-cell-base';
/**
 * `MultiSelectEditCell` is used to handle multiselect dropdown cell type editing.
 *
 * @hidden
 */
export class MultiSelectEditCell extends EditCellBase {
    write(args) {
        this.column = args.column;
        const isInline = this.parent.editSettings.mode !== 'Dialog';
        this.obj = new MultiSelect(extend({
            fields: { text: args.column.field, value: args.column.field },
            value: getValue(args.column.field, args.rowData),
            enableRtl: this.parent.enableRtl,
            placeholder: isInline ? '' : args.column.headerText, popupHeight: '200px',
            floatLabelType: isInline ? 'Never' : 'Always',
            cssClass: this.parent.cssClass ? this.parent.cssClass : null
        }, args.column.edit.params));
        this.obj.appendTo(args.element);
        args.element.setAttribute('name', getComplexFieldID(args.column.field));
    }
}
