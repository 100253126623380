import { extend, isNullOrUndefined } from '@syncfusion/ej2-base';
import { closest as closestElement, removeClass, classList, remove } from '@syncfusion/ej2-base';
import { getElementIndex, inArray, parentsUntil, getPosition, isActionPrevent } from '../base/util';
import * as events from '../base/constant';
import * as literals from '../base/string-literals';
/**
 *
 * The `Reorder` module is used for reordering columns.
 */
export class Reorder {
    /**
     * Constructor for the Grid reorder module
     *
     * @param {IGrid} parent - specifies the IGrid
     * @hidden
     */
    constructor(parent) {
        this.parent = parent;
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.on(events.headerDrop, this.headerDrop, this);
        this.parent.on(events.uiUpdate, this.enableAfterRender, this);
        this.parent.on(events.reorderComplete, this.onActionComplete, this);
        this.parent.on(events.columnDrag, this.drag, this);
        this.parent.on(events.columnDragStart, this.dragStart, this);
        this.parent.on(events.columnDragStop, this.dragStop, this);
        this.parent.on(events.headerDrop, this.headerDrop, this);
        this.parent.on(events.headerRefreshed, this.createReorderElement, this);
        this.parent.on(events.keyPressed, this.keyPressHandler, this);
        this.parent.on(events.destroy, this.destroy, this);
    }
    chkDropPosition(srcElem, destElem) {
        const col = this.parent.getColumnByUid(destElem.firstElementChild.getAttribute('e-mappinguid'));
        const bool = col ? !col.lockColumn : true;
        return ((srcElem.parentElement.isEqualNode(destElem.parentElement) || this.parent.enableColumnVirtualization)
            || (this.parent.isFrozenGrid()
                && Array.prototype.indexOf.call(closestElement(srcElem, 'thead').children, srcElem.parentElement)
                    === Array.prototype.indexOf.call(closestElement(destElem, 'thead').children, destElem.parentElement)))
            && this.targetParentContainerIndex(srcElem, destElem) > -1 && bool;
    }
    chkDropAllCols(srcElem, destElem) {
        let isFound;
        const headers = this.getHeaderCells();
        let header;
        while (!isFound && headers.length > 0) {
            header = headers.pop();
            isFound = srcElem !== header && this.targetParentContainerIndex(srcElem, destElem) > -1;
        }
        return isFound;
    }
    findColParent(col, cols, parent) {
        // eslint-disable-next-line no-self-assign
        parent = parent;
        for (let i = 0, len = cols.length; i < len; i++) {
            if (col === cols[i]) {
                return true;
            }
            else if (cols[i].columns) {
                const cnt = parent.length;
                parent.push(cols[i]);
                if (!this.findColParent(col, cols[i].columns, parent)) {
                    parent.splice(cnt, parent.length - cnt);
                }
                else {
                    return true;
                }
            }
        }
        return false;
    }
    getColumnsModel(cols) {
        let columnModel = [];
        let subCols = [];
        if (!this.parent.getFrozenColumns() && this.parent.isFrozenGrid()) {
            return this.parent.getColumns();
        }
        else {
            for (let i = 0, len = cols.length; i < len; i++) {
                columnModel.push(cols[i]);
                if (cols[i].columns) {
                    subCols = subCols.concat(cols[i].columns);
                }
            }
            if (subCols.length) {
                columnModel = columnModel.concat(this.getColumnsModel(subCols));
            }
        }
        return columnModel;
    }
    headerDrop(e) {
        const gObj = this.parent;
        const dropElement = this.element.querySelector('.e-headercelldiv') || this.element.querySelector('.e-stackedheadercelldiv');
        const uId = dropElement.getAttribute('e-mappinguid');
        const column = gObj.getColumnByUid(uId);
        if (!closestElement(e.target, 'th') || (!isNullOrUndefined(column) && (!column.allowReordering || column.lockColumn))) {
            this.parent.log('action_disabled_column', { moduleName: this.getModuleName(), column });
            return;
        }
        const destElem = closestElement(e.target, '.e-headercell');
        const destElemDiv = destElem.querySelector('.e-headercelldiv') || destElem.querySelector('.e-stackedheadercelldiv');
        const destElemUid = destElemDiv.getAttribute('e-mappinguid');
        if (!isNullOrUndefined(destElemUid)) {
            const destColumn = gObj.getColumnByUid(destElemUid);
            if (isNullOrUndefined(destColumn) || !destColumn.allowReordering || destColumn.lockColumn) {
                this.parent.log('action_disabled_column', { moduleName: this.getModuleName(), column, destColumn });
                return;
            }
        }
        if (destElem && !(!this.chkDropPosition(this.element, destElem) || !this.chkDropAllCols(this.element, destElem))) {
            if (this.parent.enableColumnVirtualization) {
                const columns = this.parent.columns;
                const sourceUid = this.element.querySelector('.e-headercelldiv').getAttribute('e-mappinguid');
                const col = this.parent.columns.filter((col) => col.uid === sourceUid);
                let colMatchIndex = null;
                const column = col[0];
                const destUid = destElem.querySelector('.e-headercelldiv').getAttribute('e-mappinguid');
                columns.some((col, index) => {
                    if (col.uid === destUid) {
                        colMatchIndex = index;
                        return col.uid === destUid;
                    }
                    return false;
                });
                if (!isNullOrUndefined(colMatchIndex)) {
                    this.moveColumns(colMatchIndex, column);
                }
            }
            else {
                const newIndex = this.targetParentContainerIndex(this.element, destElem);
                const uid = this.element.firstElementChild.getAttribute('e-mappinguid');
                this.destElement = destElem;
                this.parent.notify(events.setReorderDestinationElement, { ele: destElem });
                if (uid) {
                    this.moveColumns(newIndex, this.parent.getColumnByUid(uid));
                }
                else {
                    const headers = this.getHeaderCells();
                    const oldIdx = getElementIndex(this.element, headers);
                    const columns = this.getColumnsModel(this.parent.columns);
                    this.moveColumns(newIndex, columns[oldIdx]);
                }
            }
        }
    }
    isActionPrevent(gObj) {
        return isActionPrevent(gObj);
    }
    moveColumns(destIndex, column, reorderByColumn, preventRefresh) {
        const gObj = this.parent;
        if (this.isActionPrevent(gObj)) {
            gObj.notify(events.preventBatch, { instance: this, handler: this.moveColumns, arg1: destIndex, arg2: column });
            return;
        }
        const isFrozen = !gObj.getFrozenColumns() && gObj.isFrozenGrid();
        const parent = this.getColParent(column, this.parent.columns);
        const cols = parent ? parent.columns : isFrozen ? this.parent.getColumns() : this.parent.columns;
        let srcIdx = inArray(column, cols);
        if (((this.parent.isFrozenGrid() && parent) || this.parent.lockcolPositionCount) && !reorderByColumn &&
            !this.parent.enableColumnVirtualization) {
            for (let i = 0; i < cols.length; i++) {
                if (cols[i].field === column.field) {
                    srcIdx = i;
                    break;
                }
            }
            const col = this.parent.getColumnByUid(this.destElement.firstElementChild.getAttribute('e-mappinguid'));
            if (col) {
                for (let i = 0; i < cols.length; i++) {
                    if (cols[i].field === col.field) {
                        destIndex = i;
                        break;
                    }
                }
            }
            else {
                for (let i = 0; i < cols.length; i++) {
                    if (cols[i].headerText === this.destElement.innerText.trim()) {
                        destIndex = i;
                    }
                }
            }
        }
        if (!gObj.allowReordering || srcIdx === destIndex || srcIdx === -1 || destIndex === -1) {
            return;
        }
        cols.splice(destIndex, 0, cols.splice(srcIdx, 1)[0]);
        const args = { column: column, destIndex: destIndex, columns: cols, parent: parent, cancel: false };
        gObj.notify(events.refreshFrozenColumns, args);
        if (args.cancel) {
            return;
        }
        gObj.getColumns(true);
        gObj.notify(events.columnPositionChanged, { fromIndex: destIndex, toIndex: srcIdx });
        if (preventRefresh !== false) {
            gObj.notify(events.modelChanged, {
                type: events.actionBegin, requestType: 'reorder', fromIndex: destIndex, toIndex: srcIdx, toColumnUid: column.uid
            });
        }
    }
    targetParentContainerIndex(srcElem, destElem) {
        let cols = !this.parent.getFrozenColumns() && this.parent.isFrozenGrid() ? this.parent.getColumns() :
            this.parent.columns;
        const headers = this.getHeaderCells();
        const flatColumns = this.getColumnsModel(cols);
        const parent = this.getColParent(flatColumns[getElementIndex(srcElem, headers)], cols);
        cols = parent ? parent.columns : cols;
        return inArray(flatColumns[getElementIndex(destElem, headers)], cols);
    }
    getHeaderCells() {
        const frozenColumns = this.parent.getFrozenColumns();
        if (frozenColumns || this.parent.isFrozenGrid() || this.parent.lockcolPositionCount) {
            let fTh;
            let mTh;
            let fHeaders = [];
            const fRows = [].slice.call(this.parent.getHeaderTable().getElementsByClassName('e-columnheader'));
            if (frozenColumns || this.parent.isFrozenGrid()) {
                const mRows = [].slice.call(this.parent.getHeaderContent()
                    .querySelector('.' + literals.movableHeader).getElementsByClassName('e-columnheader'));
                for (let i = 0; i < fRows.length; i++) {
                    fTh = [].slice.call(fRows[i].getElementsByClassName('e-headercell'));
                    mTh = [].slice.call(mRows[i].getElementsByClassName('e-headercell'));
                    let isAvail;
                    for (let k = 0; k < fTh.length; k++) {
                        for (let j = 0; j < mTh.length; j++) {
                            if (mTh[j].innerText === fTh[k].innerText && parseInt(mTh[j].getAttribute('data-colindex'), 10) ===
                                parseInt(fTh[k].getAttribute('data-colindex'), 10)) {
                                isAvail = true;
                                break;
                            }
                        }
                        if (!isAvail) {
                            fHeaders = fHeaders.concat([fTh[k]]);
                        }
                    }
                    for (let j = 0; j < mTh.length; j++) {
                        fHeaders.push(mTh[j]);
                    }
                }
                if (this.parent.getFrozenRightColumnsCount()) {
                    const frRows = [].slice.call(this.parent.getHeaderContent().querySelector('.e-frozen-right-header')
                        .getElementsByClassName('e-columnheader'));
                    const frTh = [].slice.call(frRows[0].getElementsByClassName('e-headercell'));
                    for (let i = 0; i < frTh.length; i++) {
                        fHeaders.push(frTh[i]);
                    }
                }
            }
            else {
                for (let i = 0; i < fRows.length; i++) {
                    mTh = [].slice.call(fRows[i].getElementsByClassName('e-headercell'));
                    for (let k = 0; k < mTh.length; k++) {
                        let isAvail;
                        for (let j = k + 1; j < mTh.length; j++) {
                            if (mTh[j].innerText === mTh[k].innerText && parseInt(mTh[j].getAttribute('data-colindex'), 10) ===
                                parseInt(mTh[k].getAttribute('data-colindex'), 10)) {
                                isAvail = true;
                                break;
                            }
                        }
                        if (!isAvail) {
                            fHeaders = fHeaders.concat([mTh[k]]);
                        }
                    }
                }
            }
            return fHeaders;
        }
        else {
            return [].slice.call(this.parent.element.getElementsByClassName('e-headercell'));
        }
    }
    getColParent(column, columns) {
        const parents = [];
        this.findColParent(column, columns, parents);
        return parents[parents.length - 1];
    }
    reorderSingleColumn(fromFName, toFName) {
        const fColumn = this.parent.enableColumnVirtualization ?
            this.parent.columns.filter((col) => col.field === fromFName)[0]
            : this.parent.getColumnByField(fromFName);
        const toColumn = this.parent.enableColumnVirtualization ?
            this.parent.columns.filter((col) => col.field === toFName)[0]
            : this.parent.getColumnByField(toFName);
        if ((!isNullOrUndefined(fColumn) && (!fColumn.allowReordering || fColumn.lockColumn)) ||
            (!isNullOrUndefined(toColumn) && (!toColumn.allowReordering || fColumn.lockColumn))) {
            this.parent.log('action_disabled_column', { moduleName: this.getModuleName(), column: fColumn, destColumn: toColumn });
            return;
        }
        const column = toColumn;
        const parent = this.getColParent(column, this.parent.columns);
        const columns = parent ? parent.columns : this.parent.columns;
        const destIndex = inArray(column, columns);
        if (destIndex > -1) {
            this.moveColumns(destIndex, fColumn, true);
        }
    }
    reorderMultipleColumns(fromFNames, toFName) {
        let toIndex = this.parent.getColumnIndexByField(toFName);
        const toColumn = this.parent.getColumnByField(toFName);
        if (toIndex < 0 || (!isNullOrUndefined(toColumn) && (!toColumn.allowReordering || toColumn.lockColumn))) {
            return;
        }
        for (let i = 0; i < fromFNames.length; i++) {
            const column = this.parent.getColumnByField(fromFNames[i]);
            if (!isNullOrUndefined(column) && (!column.allowReordering || column.lockColumn)) {
                return;
            }
        }
        for (let i = 0; i < fromFNames.length; i++) {
            const column = this.parent.getColumnByIndex(toIndex);
            const parent = this.getColParent(column, this.parent.columns);
            const columns = parent ? parent.columns : this.parent.columns;
            const destIndex = inArray(column, columns);
            if (destIndex > -1) {
                this.moveColumns(destIndex, this.parent.getColumnByField(fromFNames[i]), true, true);
            }
            if (this.parent.getColumnIndexByField(fromFNames[i + 1]) >= destIndex) {
                toIndex++; //R to L
            }
        }
    }
    moveTargetColumn(column, toIndex) {
        if (toIndex > -1) {
            this.moveColumns(toIndex, column, true);
        }
    }
    reorderSingleColumnByTarget(fieldName, toIndex) {
        this.moveTargetColumn(this.parent.getColumnByField(fieldName), toIndex);
    }
    reorderMultipleColumnByTarget(fieldName, toIndex) {
        for (let i = 0; i < fieldName.length; i++) {
            this.reorderSingleColumnByTarget(fieldName[i], toIndex);
        }
    }
    /**
     * Changes the position of the Grid columns by field names.
     *
     * @param  {string | string[]} fromFName - Defines the origin field names.
     * @param  {string} toFName - Defines the destination field name.
     * @returns {void}
     */
    reorderColumns(fromFName, toFName) {
        if (typeof fromFName === 'string') {
            this.reorderSingleColumn(fromFName, toFName);
            this.fromCol = fromFName;
        }
        else {
            this.reorderMultipleColumns(fromFName, toFName);
            this.fromCol = fromFName[0];
        }
    }
    /**
     * Changes the position of the Grid columns by field index.
     *
     * @param  {number} fromIndex - Defines the origin field index.
     * @param  {number} toIndex - Defines the destination field index.
     * @returns {void}
     */
    reorderColumnByIndex(fromIndex, toIndex) {
        this.moveTargetColumn(this.parent.getColumnByIndex(fromIndex), toIndex);
    }
    /**
     * Changes the position of the Grid columns by field index.
     *
     * @param  {string | string[]} fieldName - Defines the field name.
     * @param  {number} toIndex - Defines the destination field index.
     * @returns {void}
     */
    reorderColumnByTargetIndex(fieldName, toIndex) {
        if (typeof fieldName === 'string') {
            this.reorderSingleColumnByTarget(fieldName, toIndex);
        }
        else {
            this.reorderMultipleColumnByTarget(fieldName, toIndex);
        }
    }
    enableAfterRender(e) {
        if (e.module === this.getModuleName() && e.enable) {
            this.createReorderElement();
        }
    }
    createReorderElement(e) {
        if (e && e.args.isXaxis) {
            this.setDisplay('none');
        }
        const header = this.parent.element.querySelector('.' + literals.headerContent);
        this.upArrow = header.appendChild(this.parent
            .createElement('div', { className: 'e-icons e-icon-reorderuparrow e-reorderuparrow', attrs: { style: 'display:none' } }));
        this.downArrow = header.appendChild(this.parent
            .createElement('div', { className: 'e-icons e-icon-reorderdownarrow e-reorderdownarrow', attrs: { style: 'display:none' } }));
    }
    /**
     * The function used to trigger onActionComplete
     *
     * @param {NotifyArgs} e - specified the NotifyArgs
     * @returns {void}
     * @hidden
     */
    onActionComplete(e) {
        this.parent.trigger(events.actionComplete, extend(e, { type: events.actionComplete }));
        const target = this.fromCol && this.parent.getColumnHeaderByField(this.fromCol);
        if (target) {
            this.parent.focusModule.onClick({ target }, true);
        }
    }
    /**
     * To destroy the reorder
     *
     * @returns {void}
     * @hidden
     */
    destroy() {
        const gridElement = this.parent.element;
        if (this.parent.isDestroyed || !gridElement || (!gridElement.querySelector('.' + literals.gridHeader) &&
            !gridElement.querySelector('.' + literals.gridContent))) {
            return;
        }
        if (this.upArrow.parentNode) {
            remove(this.upArrow);
        }
        if (this.downArrow.parentNode) {
            remove(this.downArrow);
        }
        this.parent.off(events.headerDrop, this.headerDrop);
        this.parent.off(events.uiUpdate, this.enableAfterRender);
        this.parent.off(events.reorderComplete, this.onActionComplete);
        this.parent.off(events.columnDrag, this.drag);
        this.parent.off(events.columnDragStart, this.dragStart);
        this.parent.off(events.columnDragStop, this.dragStop);
        this.parent.off(events.headerRefreshed, this.createReorderElement);
        this.parent.off(events.keyPressed, this.keyPressHandler);
        this.parent.off(events.destroy, this.destroy);
        //call ejdrag and drop destroy
    }
    keyPressHandler(e) {
        const gObj = this.parent;
        switch (e.action) {
            case 'ctrlLeftArrow':
            case 'ctrlRightArrow':
                // eslint-disable-next-line no-case-declarations
                const element = gObj.focusModule.currentInfo.element;
                if (element && element.classList.contains('e-headercell')) {
                    const column = gObj.getColumnByUid(element.firstElementChild.getAttribute('e-mappinguid'));
                    const visibleCols = gObj.getVisibleColumns();
                    const index = visibleCols.indexOf(column);
                    const toCol = e.action === 'ctrlLeftArrow' ? visibleCols[index - 1] : visibleCols[index + 1];
                    if (toCol && toCol.field && column.field) {
                        this.reorderColumns(column.field, toCol.field);
                    }
                }
                break;
        }
    }
    drag(e) {
        const gObj = this.parent;
        let target = e.target;
        if (!e.column.allowReordering || e.column.lockColumn) {
            return;
        }
        const closest = closestElement(target, '.e-headercell:not(.e-stackedHeaderCell)');
        const cloneElement = gObj.element.querySelector('.e-cloneproperties');
        const content = gObj.isFrozenGrid() ? gObj.getMovableVirtualContent() : gObj.getContent().firstElementChild;
        const isLeft = this.x > getPosition(e.event).x + content.scrollLeft;
        removeClass([].slice.call(gObj.getHeaderTable().getElementsByClassName('e-reorderindicate')), ['e-reorderindicate']);
        this.setDisplay('none');
        this.stopTimer();
        classList(cloneElement, ['e-defaultcur'], ['e-notallowedcur']);
        this.updateScrollPostion(e.event);
        if (closest && !closest.isEqualNode(this.element)) {
            target = closest;
            //consider stacked, detail header cell
            if (!(!this.chkDropPosition(this.element, target) || !this.chkDropAllCols(this.element, target))) {
                this.updateArrowPosition(target, isLeft);
                classList(target, ['e-allowDrop', 'e-reorderindicate'], []);
            }
            else if (!(gObj.allowGrouping && parentsUntil(e.target, 'e-groupdroparea'))) {
                classList(cloneElement, ['e-notallowedcur'], ['e-defaultcur']);
            }
        }
        gObj.trigger(events.columnDrag, { target: target, draggableType: 'headercell', column: e.column });
    }
    updateScrollPostion(e) {
        const x = getPosition(e).x;
        const cliRect = this.parent.element.getBoundingClientRect();
        const cliRectBaseRight = cliRect.right;
        if (this.parent.isFrozenGrid()) {
            this.updateFrozenScrollPosition(x, cliRect);
        }
        else {
            const cliRectBaseLeft = cliRect.left;
            const scrollElem = this.parent.getContent().firstElementChild;
            if (x > cliRectBaseLeft && x < cliRectBaseLeft + 35) {
                this.timer = window.setInterval(() => { this.setScrollLeft(scrollElem, true); }, 50);
            }
            else if (x < cliRectBaseRight && x > cliRectBaseRight - 35) {
                this.timer = window.setInterval(() => { this.setScrollLeft(scrollElem, false); }, 50);
            }
        }
    }
    updateFrozenScrollPosition(x, cliRect) {
        const scrollElem = this.parent.getContent().querySelector('.' + literals.movableContent);
        const mhdrCliRect = this.parent.element.querySelector('.' + literals.movableHeader).getBoundingClientRect();
        const left = this.parent.getFrozenLeftCount();
        const right = this.parent.getFrozenRightColumnsCount();
        const cliRectBaseRight = right ? mhdrCliRect.right : cliRect.right;
        const cliRectBaseLeft = left ? mhdrCliRect.left : cliRect.left;
        if (x > cliRectBaseLeft && x < cliRectBaseLeft + 35) {
            this.timer = window.setInterval(() => { this.setScrollLeft(scrollElem, true); }, 50);
        }
        else if (x < cliRectBaseRight && x > cliRectBaseRight - 35) {
            this.timer = window.setInterval(() => { this.setScrollLeft(scrollElem, false); }, 50);
        }
    }
    setScrollLeft(scrollElem, isLeft) {
        const scrollLeft = scrollElem.scrollLeft;
        scrollElem.scrollLeft = scrollElem.scrollLeft + (isLeft ? -5 : 5);
        if (scrollLeft !== scrollElem.scrollLeft) {
            this.setDisplay('none');
        }
    }
    stopTimer() {
        window.clearInterval(this.timer);
    }
    updateArrowPosition(target, isLeft) {
        const cliRect = target.getBoundingClientRect();
        const cliRectBase = this.parent.element.getBoundingClientRect();
        if ((isLeft && cliRect.left < cliRectBase.left) || (!isLeft && cliRect.right > cliRectBase.right)) {
            return;
        }
        if (this.parent.isFrozenGrid() && target.classList.contains('e-headercell')) {
            const left = this.parent.getFrozenLeftCount();
            const right = this.parent.getFrozenRightColumnsCount();
            const dropEle = this.element.querySelector('.e-headercelldiv');
            const dropCol = dropEle ? this.parent.getColumnByUid(dropEle.getAttribute('data-uid')) : null;
            const col = this.parent.getColumnByUid(target.firstElementChild.getAttribute('e-mappinguid'));
            const fhdrWidth = Math.round(this.parent.getFrozenVirtualHeader().getBoundingClientRect().right);
            const mhdrRight = Math.round(this.parent.getMovableVirtualHeader().getBoundingClientRect().right);
            if (col) {
                if (left && !right && this.parent.getNormalizedColumnIndex(col.uid) >= left
                    && ((isLeft && Math.round(cliRect.left) < fhdrWidth) || (!isLeft && mhdrRight < cliRect.right))) {
                    return;
                }
                if (!left && right && dropCol && dropCol.getFreezeTableName() !== col.getFreezeTableName()
                    && (!isLeft && Math.round(cliRect.right) < fhdrWidth)) {
                    return;
                }
            }
        }
        const isSticky = this.parent.getHeaderContent().classList.contains('e-sticky');
        this.upArrow.style.top = isSticky ? cliRect.top + cliRect.height + 'px' : cliRect.top + cliRect.height - cliRectBase.top + 'px';
        this.downArrow.style.top = isSticky ? cliRect.top - 7 + 'px' : cliRect.top - cliRectBase.top - 7 + 'px';
        this.upArrow.style.left = this.downArrow.style.left = isSticky ? (isLeft ? cliRect.left : cliRect.right) - 4 + 'px' :
            (isLeft ? cliRect.left : cliRect.right) - cliRectBase.left - 4 + 'px';
        this.setDisplay('');
    }
    dragStart(e) {
        const gObj = this.parent;
        const target = e.target;
        this.element = target.classList.contains('e-headercell') ? target :
            parentsUntil(target, 'e-headercell');
        if (!e.column.allowReordering || e.column.lockColumn) {
            return;
        }
        const content = gObj.isFrozenGrid() ? gObj.getMovableVirtualContent() : gObj.getContent().firstElementChild;
        this.x = getPosition(e.event).x + content.scrollLeft;
        gObj.trigger(events.columnDragStart, {
            target: target, draggableType: 'headercell', column: e.column
        });
    }
    dragStop(e) {
        const gObj = this.parent;
        this.setDisplay('none');
        this.stopTimer();
        if (!e.cancel) {
            gObj.trigger(events.columnDrop, { target: e.target, draggableType: 'headercell', column: e.column });
        }
        removeClass([].slice.call(gObj.getHeaderTable().getElementsByClassName('e-reorderindicate')), ['e-reorderindicate']);
    }
    setDisplay(display) {
        this.upArrow.style.display = display;
        this.downArrow.style.display = display;
    }
    /**
     * For internal use only - Get the module name.
     *
     * @returns {string} return the module name
     * @private
     */
    getModuleName() {
        return 'reorder';
    }
}
