import * as events from '../base/constant';
import { PdfDocument, PdfGrid, PdfBorders, PdfPen, PdfPaddings } from '@syncfusion/ej2-pdf-export';
import { PdfStandardFont, PdfFontFamily, PdfFontStyle, PdfBitmap } from '@syncfusion/ej2-pdf-export';
import { PdfStringFormat, PdfTextAlignment, PdfColor, PdfSolidBrush, PdfTextWebLink } from '@syncfusion/ej2-pdf-export';
import { PdfVerticalAlignment, RectangleF, PdfPageTemplateElement } from '@syncfusion/ej2-pdf-export';
import { PointF, PdfPageNumberField, PdfCompositeField } from '@syncfusion/ej2-pdf-export';
import { PdfPageCountField, SizeF, PdfPageSettings, PdfPageOrientation } from '@syncfusion/ej2-pdf-export';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { ExportHelper, ExportValueFormatter } from './export-helper';
import { Data } from '../actions/data';
import { SummaryModelGenerator, GroupSummaryModelGenerator, CaptionSummaryModelGenerator } from '../services/summary-model-generator';
import { compile, getEnumValue, isNullOrUndefined, detach } from '@syncfusion/ej2-base';
import { CellType } from '../base/enum';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { getValue } from '@syncfusion/ej2-base';
import { getUid, getPrintGridModel, measureColumnDepth, isExportColumns, updateColumnTypeForExportColumns, prepareColumns } from '../base/util';
/**
 * `PDF Export` module is used to handle the exportToPDF action.
 *
 * @hidden
 */
export class PdfExport {
    /**
     * Constructor for the Grid PDF Export module
     *
     * @param {IGrid} parent - specifies the IGrid
     * @hidden
     */
    constructor(parent) {
        this.hideColumnInclude = false;
        this.currentViewData = false;
        this.customDataSource = false;
        this.isGrouping = false;
        this.headerOnPages = [];
        this.drawPosition = { xPosition: 0, yPosition: 0 };
        this.parent = parent;
        this.helper = new ExportHelper(parent);
        this.gridPool = {};
    }
    /**
     * For internal use only - Get the module name.
     *
     * @returns {string} returns the module name
     */
    getModuleName() {
        return 'PdfExport';
    }
    init(parent) {
        this.exportValueFormatter = new ExportValueFormatter(parent.locale);
        this.pdfDocument = undefined;
        this.hideColumnInclude = false;
        this.currentViewData = false;
        this.parent = parent;
        this.isGrouping = false;
        this.isExporting = true;
        parent.id = getUid('main-grid');
        this.gridPool[parent.id] = false;
        this.pdfPageSettings = new PdfPageSettings();
    }
    exportWithData(parent, pdfDoc, resolve, returnType, pdfExportProperties, isMultipleExport, reject) {
        this.init(parent);
        if (!isNullOrUndefined(pdfDoc)) {
            this.pdfDocument = pdfDoc;
        }
        else {
            this.pdfDocument = new PdfDocument();
        }
        this.processExport(parent, returnType, pdfExportProperties, isMultipleExport).then(() => {
            this.isExporting = false;
            parent.trigger(events.pdfExportComplete, this.isBlob ? { promise: this.blobPromise } : {});
            this.parent.log('exporting_complete', this.getModuleName());
            resolve(this.pdfDocument);
        }).catch((e) => {
            reject(this.pdfDocument);
            this.parent.trigger(events.actionFailure, e);
        });
    }
    /**
     * Used to map the input data
     *
     * @param {IGrid} parent - specifies the IGrid
     * @param {PdfExportProperties} pdfExportProperties - specifies the PdfExportProperties
     * @param {boolean} isMultipleExport - specifies the isMultipleExport
     * @param {Object} pdfDoc - specifies the pdfDoc
     * @param {boolean} isBlob - speciies whether it is Blob or not
     * @returns {void}
     */
    Map(parent, pdfExportProperties, isMultipleExport, pdfDoc, isBlob) {
        this.data = new Data(this.parent);
        this.isBlob = isBlob;
        this.gridPool = {};
        let query = new Query();
        if (parent.childGrid && !(!isNullOrUndefined(pdfExportProperties) && pdfExportProperties.hierarchyExportMode === 'None')) {
            parent.expandedRows = getPrintGridModel(parent).expandedRows;
        }
        const args = {
            requestType: 'beforePdfExport', cancel: false,
            headerPageNumbers: [], gridDrawPosition: { xPosition: 0, yPosition: 0 }, generateQuery: false
        };
        const gridObject = 'gridObject';
        args[gridObject] = parent;
        const can = 'cancel';
        const generateQuery = 'generateQuery';
        const header = 'headerPageNumbers';
        const drawPos = 'gridDrawPosition';
        parent.trigger(events.beforePdfExport, args);
        if (args[can] === true) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            return new Promise((resolve, reject) => {
                return resolve();
            });
        }
        if (isExportColumns(pdfExportProperties)) {
            updateColumnTypeForExportColumns(pdfExportProperties, parent);
        }
        if (args[generateQuery]) {
            query = ExportHelper.getQuery(parent, this.data);
        }
        this.headerOnPages = args[header];
        this.drawPosition = args[drawPos];
        this.parent.log('exporting_begin', this.getModuleName());
        if (!isNullOrUndefined(pdfExportProperties) && !isNullOrUndefined(pdfExportProperties.dataSource)
            && pdfExportProperties.dataSource instanceof DataManager) {
            return new Promise((resolve, reject) => {
                pdfExportProperties.dataSource.executeQuery(query).then((returnType) => {
                    this.exportWithData(parent, pdfDoc, resolve, returnType, pdfExportProperties, isMultipleExport, reject);
                });
            });
        }
        else if (!isNullOrUndefined(pdfExportProperties) && pdfExportProperties.exportType === 'CurrentPage') {
            return new Promise((resolve, reject) => {
                this.exportWithData(parent, pdfDoc, resolve, this.parent.getCurrentViewRecords(), pdfExportProperties, isMultipleExport, reject);
            });
        }
        else {
            const allPromise = [];
            allPromise.push(this.data.getData({}, ExportHelper.getQuery(parent, this.data)));
            allPromise.push(this.helper.getColumnData(parent));
            return new Promise((resolve, reject) => {
                Promise.all(allPromise).then((e) => {
                    this.init(parent);
                    if (!isNullOrUndefined(pdfDoc)) {
                        this.pdfDocument = pdfDoc;
                    }
                    else {
                        this.pdfDocument = new PdfDocument();
                    }
                    this.processExport(parent, e[0], pdfExportProperties, isMultipleExport).then(() => {
                        this.isExporting = false;
                        parent.trigger(events.pdfExportComplete, this.isBlob ? { promise: this.blobPromise } : {});
                        this.parent.log('exporting_complete', this.getModuleName());
                        resolve(this.pdfDocument);
                    }).catch((e) => {
                        reject(this.pdfDocument);
                        this.parent.trigger(events.actionFailure, e);
                    });
                });
            });
        }
    }
    processExport(gObj, returnType, pdfExportProperties, isMultipleExport) {
        const section = this.pdfDocument.sections.add();
        let pdfGrid;
        this.processSectionExportProperties(section, pdfExportProperties);
        const pdfPage = section.pages.add();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            pdfGrid = this.processGridExport(gObj, returnType, pdfExportProperties);
            this.globalResolve = resolve;
            this.gridPool[gObj.id] = true;
            this.helper.checkAndExport(this.gridPool, this.globalResolve);
        }).then(() => {
            // draw the grid
            const xPosition = 'xPosition';
            const yPosition = 'yPosition';
            pdfGrid.draw(pdfPage, this.drawPosition[xPosition], this.drawPosition[yPosition]);
            this.drawHeader(pdfExportProperties);
            if (!isMultipleExport) {
                // save the PDF
                if (!this.isBlob) {
                    if (!isNullOrUndefined(pdfExportProperties) && pdfExportProperties.fileName) {
                        this.pdfDocument.save(pdfExportProperties.fileName);
                    }
                    else {
                        this.pdfDocument.save('Export.pdf');
                    }
                }
                else {
                    this.blobPromise = this.pdfDocument.save();
                }
                this.pdfDocument.destroy();
                delete gObj.expandedRows;
            }
            return this.pdfDocument;
        });
    }
    processSectionExportProperties(section, pdfExportProperties) {
        if (!isNullOrUndefined(pdfExportProperties) && (!isNullOrUndefined(pdfExportProperties.pageOrientation)
            || !isNullOrUndefined(pdfExportProperties.pageSize))) {
            this.pdfPageSettings.orientation = (pdfExportProperties.pageOrientation === 'Landscape') ?
                PdfPageOrientation.Landscape : PdfPageOrientation.Portrait;
            this.pdfPageSettings.size = this.getPageSize(pdfExportProperties.pageSize);
            section.setPageSettings(this.pdfPageSettings);
        }
        return section;
    }
    processGridExport(gObj, returnType, pdfExportProperties) {
        let allowHorizontalOverflow = true;
        const isFrozen = this.parent.isFrozenGrid() && !this.parent.getFrozenColumns();
        if (!isNullOrUndefined(pdfExportProperties)) {
            this.gridTheme = pdfExportProperties.theme;
            allowHorizontalOverflow = isNullOrUndefined(pdfExportProperties.allowHorizontalOverflow) ?
                true : pdfExportProperties.allowHorizontalOverflow;
        }
        const helper = new ExportHelper(gObj, this.helper.getForeignKeyData());
        const dataSource = this.processExportProperties(pdfExportProperties, returnType.result);
        let columns = isExportColumns(pdfExportProperties) ?
            prepareColumns(pdfExportProperties.columns, gObj.enableColumnVirtualization) :
            helper.getGridExportColumns(isFrozen ? gObj.getColumns() : gObj.columns);
        columns = columns.filter((columns) => { return isNullOrUndefined(columns.commands); });
        let isGrouping = false;
        if (gObj.groupSettings.columns.length) {
            isGrouping = true;
        }
        if (gObj.childGrid && !isNullOrUndefined(pdfExportProperties)) {
            gObj.hierarchyPrintMode = pdfExportProperties.hierarchyExportMode || 'Expanded';
        }
        // create a grid
        let pdfGrid = new PdfGrid();
        // get header theme style
        const headerThemeStyle = this.getHeaderThemeStyle();
        let border = headerThemeStyle.border;
        const headerFont = headerThemeStyle.font;
        const headerBrush = headerThemeStyle.brush;
        const returnValue = helper.getHeaders(columns, this.hideColumnInclude);
        // Column collection with respect to the records in the grid
        const gridColumns = returnValue.columns;
        // process grid header content
        pdfGrid = this.processGridHeaders(gObj.groupSettings.columns.length, pdfGrid, returnValue.rows, gridColumns, border, headerFont, headerBrush, gObj, allowHorizontalOverflow, columns);
        // set alignment, width and type of the values of the column
        this.setColumnProperties(gridColumns, pdfGrid, helper, gObj, allowHorizontalOverflow);
        const captionThemeStyle = this.getSummaryCaptionThemeStyle();
        if (!isNullOrUndefined(dataSource) && dataSource.length) {
            if (isGrouping) {
                if (!isNullOrUndefined(captionThemeStyle.border)) {
                    border = captionThemeStyle.border;
                }
                this.processGroupedRecords(pdfGrid, dataSource, gridColumns, gObj, border, 0, captionThemeStyle.font, captionThemeStyle.
                    brush, captionThemeStyle.backgroundBrush, returnType, pdfExportProperties, helper, 0);
            }
            else {
                this.processRecord(border, gridColumns, gObj, dataSource, pdfGrid, 0, pdfExportProperties, helper, 0);
            }
            if (!isNullOrUndefined(returnType.aggregates)) {
                const summaryModel = new SummaryModelGenerator(gObj);
                let sRows;
                let column = summaryModel.getColumns();
                column = column.filter((col) => { return isNullOrUndefined(col.commands) && col.type !== 'checkbox'; });
                if (gObj.aggregates.length && this.parent !== gObj) {
                    gObj.aggregateModule.prepareSummaryInfo();
                }
                if (this.customDataSource) {
                    sRows = summaryModel.generateRows(dataSource, returnType.aggregates);
                }
                else if (this.currentViewData) {
                    sRows = summaryModel.generateRows(this.parent.getCurrentViewRecords(), returnType.aggregates);
                }
                else if (isGrouping) {
                    sRows = summaryModel.generateRows(dataSource.records, returnType.aggregates);
                }
                else {
                    sRows = summaryModel.generateRows(returnType.result, returnType.aggregates, null, null, column);
                }
                this.processAggregates(sRows, pdfGrid, border, captionThemeStyle.font, captionThemeStyle.brush, captionThemeStyle.backgroundBrush, false, null, null, null, isGrouping ? false : true);
            }
        }
        else {
            const row = pdfGrid.rows.addRow();
            row.style.setBorder(border);
        }
        return pdfGrid;
    }
    getSummaryCaptionThemeStyle() {
        if (!isNullOrUndefined(this.gridTheme) && !isNullOrUndefined(this.gridTheme.caption)) {
            const fontSize = !isNullOrUndefined(this.gridTheme.caption.fontSize) ? this.gridTheme.caption.fontSize : 9.75;
            const fontFamily = !isNullOrUndefined(this.gridTheme.caption.fontName) ?
                this.getFontFamily(this.gridTheme.caption.fontName) : PdfFontFamily.Helvetica;
            const fontStyle = this.getFontStyle(this.gridTheme.caption);
            let pdfColor = new PdfColor(0, 0, 0);
            if (!isNullOrUndefined(this.gridTheme.caption.fontColor)) {
                const penBrushColor = this.hexToRgb(this.gridTheme.caption.fontColor);
                pdfColor = new PdfColor(penBrushColor.r, penBrushColor.g, penBrushColor.b);
            }
            const borderCaption = this.gridTheme.caption.border ? this.getBorderStyle(this.gridTheme.caption.border) : null;
            let font = new PdfStandardFont(fontFamily, fontSize, fontStyle);
            if (!isNullOrUndefined(this.gridTheme.caption.font)) {
                font = this.gridTheme.caption.font;
            }
            return { font: font, brush: new PdfSolidBrush(pdfColor), backgroundBrush: new PdfSolidBrush(new PdfColor(246, 246, 246)),
                border: borderCaption };
        }
        else {
            //Material theme
            return { font: new PdfStandardFont(PdfFontFamily.Helvetica, 9.75), brush: new PdfSolidBrush(new PdfColor(0, 0, 0)),
                backgroundBrush: new PdfSolidBrush(new PdfColor(246, 246, 246)) };
        }
    }
    getGridPdfFont(args) {
        const fontFamily = 'fontFamily';
        const fontSize = 'fontSize';
        const fontStyle = 'fontStyle';
        const isTrueType = 'isTrueType';
        let style = 0;
        if (args.header && args.header.font) {
            const headerFont = args.header.font[fontFamily];
            const headerSize = args.header.font[fontSize];
            const headerStyle = args.header.font[fontStyle];
            style = (isNullOrUndefined(PdfFontStyle[headerStyle]) ? 0 : PdfFontStyle[headerStyle]);
            if (args.header.font[isTrueType]) {
                args.header.font = new PdfTrueTypeFont(headerFont, headerSize, style);
            }
            else {
                const fontFamily = !isNullOrUndefined(headerFont) ?
                    this.getFontFamily(headerFont) : PdfFontFamily.Helvetica;
                args.header.font = new PdfStandardFont(fontFamily, headerSize, style);
            }
        }
        if (args.caption && args.caption.font) {
            const captionFont = args.caption.font[fontFamily];
            const captionSize = args.caption.font[fontSize];
            const captionStyle = args.caption.font[fontStyle];
            style = (isNullOrUndefined(PdfFontStyle[captionStyle]) ? 0 : PdfFontStyle[captionStyle]);
            if (args.caption.font[isTrueType]) {
                args.caption.font = new PdfTrueTypeFont(captionFont, captionSize, style);
            }
            else {
                const fontFamily = !isNullOrUndefined(captionFont) ?
                    this.getFontFamily(captionFont) : PdfFontFamily.Helvetica;
                args.caption.font = new PdfStandardFont(fontFamily, captionSize, style);
            }
        }
        if (args.record && args.record.font) {
            const recordFont = args.record.font[fontFamily];
            const recordSize = args.record.font[fontSize];
            const recordStyle = args.record.font[fontStyle];
            style = (isNullOrUndefined(PdfFontStyle[recordStyle]) ? 0 : PdfFontStyle[recordStyle]);
            if (args.record.font[isTrueType]) {
                args.record.font = new PdfTrueTypeFont(recordFont, recordSize, style);
            }
            else {
                const fontFamily = !isNullOrUndefined(recordFont) ?
                    this.getFontFamily(recordFont) : PdfFontFamily.Helvetica;
                args.record.font = new PdfStandardFont(fontFamily, recordSize, style);
            }
        }
    }
    getHeaderThemeStyle() {
        const border = new PdfBorders();
        if (!isNullOrUndefined(this.gridTheme) && !isNullOrUndefined(this.gridTheme.header)) {
            const fontFamily = !isNullOrUndefined(this.gridTheme.header.fontName) ?
                this.getFontFamily(this.gridTheme.header.fontName) : PdfFontFamily.Helvetica;
            const fontStyle = this.getFontStyle(this.gridTheme.header);
            const fontSize = !isNullOrUndefined(this.gridTheme.header.fontSize) ? this.gridTheme.header.fontSize : 10.5;
            let pdfColor = new PdfColor();
            if (!isNullOrUndefined(this.gridTheme.header.fontColor)) {
                const penBrushColor = this.hexToRgb(this.gridTheme.header.fontColor);
                pdfColor = new PdfColor(penBrushColor.r, penBrushColor.g, penBrushColor.b);
            }
            let font = new PdfStandardFont(fontFamily, fontSize, fontStyle);
            if (!isNullOrUndefined(this.gridTheme.header.font)) {
                font = this.gridTheme.header.font;
            }
            return { border: this.getBorderStyle(this.gridTheme.header.border), font: font, brush: new PdfSolidBrush(pdfColor) };
        }
        else {
            //Material theme
            border.all = new PdfPen(new PdfColor(234, 234, 234));
            return { border: border, font: new PdfStandardFont(PdfFontFamily.Helvetica, 10.5),
                brush: new PdfSolidBrush(new PdfColor(102, 102, 102)) };
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    processGroupedRecords(pdfGrid, dataSource, gridColumns, gObj, border, level, font, brush, backgroundBrush, returnType, pdfExportProperties, helper, index) {
        const groupIndex = level;
        for (const dataSourceItems of dataSource) {
            const row = pdfGrid.rows.addRow();
            const col = gObj.getColumnByField(dataSourceItems.field);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const args = {
                value: dataSourceItems.key,
                column: col,
                style: undefined,
                isForeignKey: col.isForeignColumn()
            };
            const value = this.parent.getColumnByField(dataSourceItems.field).headerText + ': ' + (!col.enableGroupByFormat ? this.exportValueFormatter.formatCellValue(args) : dataSourceItems.key) + ' - ' + dataSourceItems.count + (dataSource.count > 1 ? ' items' : ' item');
            const cArgs = { captionText: value, type: 'PDF', data: dataSourceItems, style: undefined };
            this.parent.trigger(events.exportGroupCaption, cArgs, (cArgs) => {
                row.cells.getCell(groupIndex).value = cArgs.captionText;
                row.cells.getCell(groupIndex).style.stringFormat = new PdfStringFormat(PdfTextAlignment.Left);
                row.style.setBorder(border);
                row.style.setFont(font);
                row.style.setTextBrush(brush);
                row.style.setBackgroundBrush(backgroundBrush);
                if (!isNullOrUndefined(cArgs.style)) {
                    this.processCellStyle(row.cells.getCell(groupIndex), cArgs);
                }
                let sRows;
                const captionSummaryModel = new CaptionSummaryModelGenerator(gObj);
                if (!isNullOrUndefined(dataSourceItems.items.records)) {
                    sRows = captionSummaryModel.generateRows(dataSourceItems.items.records, dataSourceItems);
                }
                else {
                    sRows = captionSummaryModel.generateRows(dataSourceItems.items, dataSourceItems);
                }
                if (!isNullOrUndefined(sRows) && sRows.length === 0) {
                    row.cells.getCell(groupIndex + 1).columnSpan = pdfGrid.columns.count - (groupIndex + 1);
                }
                if (!isNullOrUndefined(dataSource.childLevels) && dataSource.childLevels > 0) {
                    this.processAggregates(sRows, pdfGrid, border, font, brush, backgroundBrush, true, row, groupIndex);
                    this.processGroupedRecords(pdfGrid, dataSourceItems.items, gridColumns, gObj, border, (groupIndex + 1), font, brush, backgroundBrush, returnType, pdfExportProperties, helper, index);
                    const groupSummaryModel = new GroupSummaryModelGenerator(gObj);
                    sRows = groupSummaryModel.generateRows(dataSourceItems.items.records, dataSourceItems);
                    this.processAggregates(sRows, pdfGrid, border, font, brush, backgroundBrush, false);
                }
                else {
                    this.processAggregates(sRows, pdfGrid, border, font, brush, backgroundBrush, true, row, groupIndex);
                    index = this.processRecord(border, gridColumns, gObj, dataSourceItems.items, pdfGrid, (groupIndex + 1), pdfExportProperties, helper, index);
                    const groupSummaryModel = new GroupSummaryModelGenerator(gObj);
                    sRows = groupSummaryModel.generateRows(dataSourceItems.items, dataSourceItems);
                    const isGroupedFooter = true;
                    this.processAggregates(sRows, pdfGrid, border, font, brush, backgroundBrush, false, null, null, isGroupedFooter);
                }
            });
        }
    }
    processGridHeaders(childLevels, pdfGrid, rows, gridColumn, border, headerFont, headerBrush, grid, allowHorizontalOverflow, eCols) {
        let columnCount = gridColumn.length + childLevels;
        const depth = measureColumnDepth(eCols);
        const cols = eCols;
        let index = 0;
        const rowNumber = [];
        for (let i = 0; i < rows.length; i++) {
            rowNumber[i] = 0;
        }
        if (this.parent.groupSettings.columns.length) {
            index = this.parent.groupSettings.columns.length - 1;
            columnCount = columnCount - 1;
        }
        pdfGrid.columns.add(columnCount);
        pdfGrid.headers.add(rows.length);
        const applyTextAndSpan = (rowIdx, colIdx, col, rowSpan, colSpan) => {
            const gridHeader = pdfGrid.headers.getHeader(rowIdx);
            const pdfCell = gridHeader.cells.getCell(colIdx);
            const cell = rows[rowIdx].cells[this.parent.groupSettings.columns.length ? colIdx : rowNumber[rowIdx]];
            rowNumber[rowIdx] = rowNumber[rowIdx] + 1;
            if (!isNullOrUndefined(col.headerTextAlign)) {
                pdfCell.style.stringFormat = this.getHorizontalAlignment(col.headerTextAlign);
            }
            if (rowSpan > 0) {
                pdfCell.rowSpan = rowSpan;
                pdfCell.style.stringFormat = this.getVerticalAlignment('Bottom', pdfCell.style.stringFormat, col.textAlign);
            }
            if (colSpan > 0) {
                pdfCell.columnSpan = colSpan;
            }
            gridHeader.style.setBorder(border);
            gridHeader.style.setFont(headerFont);
            gridHeader.style.setTextBrush(headerBrush);
            pdfCell.value = col.headerText;
            if (!isNullOrUndefined(cell) && (cell.cellType === CellType.HeaderIndent || cell.cellType === CellType.DetailHeader)) {
                pdfCell.value = '';
                pdfCell.width = 20;
            }
            const args = {
                cell: pdfCell,
                gridCell: cell,
                style: pdfCell.style
            };
            this.parent.trigger(events.pdfHeaderQueryCellInfo, args);
            const evtArgs = args;
            const setCellBorder = args.style.borders;
            const setCellFont = args.style.font;
            const setHeaderBrush = args.style.textBrush;
            if (!isNullOrUndefined(setCellBorder)) {
                gridHeader.style.setBorder(setCellBorder);
            }
            if (!isNullOrUndefined(setCellFont)) {
                gridHeader.style.setFont(setCellFont);
            }
            if (!isNullOrUndefined(setHeaderBrush)) {
                gridHeader.style.setTextBrush(setHeaderBrush);
            }
            if (!isNullOrUndefined(evtArgs.style.verticalAlignment)) {
                pdfCell.style.stringFormat = this.getVerticalAlignment(evtArgs.style.verticalAlignment, pdfCell.style.stringFormat);
            }
            if (!isNullOrUndefined(evtArgs.image)) {
                pdfCell.value = new PdfBitmap(evtArgs.image.base64);
            }
            if (!isNullOrUndefined(evtArgs.hyperLink)) {
                pdfCell.value = this.setHyperLink(evtArgs);
            }
        };
        const recuHeader = (cols, depth, spanCnt, colIndex, rowIndex, isRoot) => {
            let cidx = 0;
            for (let i = 0; i < cols.length; i++) {
                if (isRoot) {
                    cidx = cidx + spanCnt + (i === 0 ? 0 : -1);
                    colIndex = cidx;
                    spanCnt = 0;
                }
                if (!isRoot && !cols[i].visible) {
                    colIndex = colIndex - 1;
                }
                if (cols[i].columns && cols[i].columns.length) {
                    const newSpanCnt = recuHeader(cols[i].columns, depth - 1, 0, i + colIndex, rowIndex + 1, false);
                    applyTextAndSpan(rowIndex, i + colIndex + index, cols[i], 0, newSpanCnt);
                    spanCnt = spanCnt + newSpanCnt;
                    colIndex = colIndex + newSpanCnt - 1;
                }
                else if (cols[i].visible || this.hideColumnInclude) {
                    spanCnt++;
                    applyTextAndSpan(rowIndex, i + colIndex + index, cols[i], depth, 0);
                }
            }
            return spanCnt;
        };
        recuHeader(cols, depth, 0, 0, 0, true);
        if (pdfGrid.columns.count >= 6 && allowHorizontalOverflow) {
            pdfGrid.style.allowHorizontalOverflow = true;
        }
        return pdfGrid;
    }
    processExportProperties(pdfExportProperties, dataSource) {
        if (!isNullOrUndefined(pdfExportProperties)) {
            if (!isNullOrUndefined(pdfExportProperties.theme)) {
                this.gridTheme = pdfExportProperties.theme;
            }
            const clientSize = this.pdfPageSettings.size;
            this.drawHeader(pdfExportProperties);
            if (!isNullOrUndefined(pdfExportProperties.footer)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const footer = pdfExportProperties.footer;
                const position = new PointF(0, ((clientSize.width - 80) - (footer.fromBottom * 0.75)));
                const size = new SizeF((clientSize.width - 80), (footer.height * 0.75));
                const bounds = new RectangleF(position, size);
                this.pdfDocument.template.bottom = this.drawPageTemplate(new PdfPageTemplateElement(bounds), footer);
            }
            if (!isNullOrUndefined(pdfExportProperties.includeHiddenColumn) && !this.isGrouping) {
                this.hideColumnInclude = pdfExportProperties.includeHiddenColumn;
            }
            if (!isNullOrUndefined(pdfExportProperties.dataSource)) {
                if (!(pdfExportProperties.dataSource instanceof DataManager)) {
                    dataSource = pdfExportProperties.dataSource;
                }
                this.customDataSource = true;
                this.currentViewData = false;
            }
            else if (!isNullOrUndefined(pdfExportProperties.exportType)) {
                if (pdfExportProperties.exportType === 'CurrentPage') {
                    dataSource = this.parent.currentViewData;
                    this.currentViewData = true;
                    this.customDataSource = false;
                }
                else {
                    this.currentViewData = false;
                    this.customDataSource = false;
                }
            }
            else {
                this.currentViewData = false;
                this.customDataSource = false;
            }
        }
        else {
            this.currentViewData = false;
            this.customDataSource = false;
        }
        return dataSource;
    }
    drawHeader(pdfExportProperties) {
        const clientSize = this.pdfPageSettings.size;
        if (!isNullOrUndefined(pdfExportProperties) && !isNullOrUndefined(pdfExportProperties.header)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const header = pdfExportProperties.header;
            const position = new PointF(0, header.fromTop);
            const size = new SizeF((clientSize.width - 80), (header.height * 0.75));
            const bounds = new RectangleF(position, size);
            if (!this.headerOnPages.length) {
                this.pdfDocument.template.top = this.drawPageTemplate(new PdfPageTemplateElement(bounds), header);
            }
            else {
                const headerTemplate = this.drawPageTemplate(new PdfPageTemplateElement(bounds), header);
                this.headerOnPages.filter((index) => {
                    if (index - 1 >= 0 && index - 1 < this.pdfDocument.pages.count - 1) {
                        this.pdfDocument.pages.getPageByIndex(index - 1).graphics
                            .drawPdfTemplate(headerTemplate.template, new PointF(0, 0));
                    }
                });
            }
        }
    }
    drawPageTemplate(template, element) {
        for (const content of element.contents) {
            this.processContentValidation(content);
            switch (content.type) {
                case 'Text':
                    if (content.value === '' || content.value === undefined || content.value === null || typeof content.value !== 'string') {
                        throw new Error('please enter the valid input value in text content...');
                    }
                    this.drawText(template, content);
                    break;
                case 'PageNumber':
                    this.drawPageNumber(template, content);
                    break;
                case 'Image':
                    if (content.src === undefined || content.src === null || content.src === '') {
                        throw new Error('please enter the valid base64 string in image content...');
                    }
                    this.drawImage(template, content);
                    break;
                case 'Line':
                    this.drawLine(template, content);
                    break;
                default:
                    throw new Error('Please set valid content type...');
            }
        }
        return template;
    }
    processContentValidation(content) {
        if (content.type === undefined || content.type === null) {
            throw new Error('please set valid content type...');
        }
        else {
            if (content.type === 'Line') {
                if (content.points === undefined || content.points === null) {
                    throw new Error('please enter valid points in ' + content.type + ' content...');
                }
                else {
                    if (content.points.x1 === undefined || content.points.x1 === null || typeof content.points.x1 !== 'number') {
                        throw new Error('please enter valid x1 co-ordinate in ' + content.type + ' points...');
                    }
                    if (content.points.y1 === undefined || content.points.y1 === null || typeof content.points.y1 !== 'number') {
                        throw new Error('please enter valid y1 co-ordinate in ' + content.type + ' points...');
                    }
                    if (content.points.x2 === undefined || content.points.x2 === null || typeof content.points.x2 !== 'number') {
                        throw new Error('please enter valid x2 co-ordinate in ' + content.type + ' points...');
                    }
                    if (content.points.y2 === undefined || content.points.y2 === null || typeof content.points.y2 !== 'number') {
                        throw new Error('please enter valid y2 co-ordinate in ' + content.type + ' points...');
                    }
                }
            }
            else {
                if (content.position === undefined || content.position === null) {
                    throw new Error('please enter valid position in ' + content.type + ' content...');
                }
                else {
                    if (content.position.x === undefined || content.position.x === null || typeof content.position.x !== 'number') {
                        throw new Error('please enter valid x co-ordinate in ' + content.type + ' position...');
                    }
                    if (content.position.y === undefined || content.position.y === null || typeof content.position.y !== 'number') {
                        throw new Error('please enter valid y co-ordinate in ' + content.type + ' position...');
                    }
                }
            }
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    drawText(pageTemplate, content) {
        const font = this.getFont(content);
        let brush = this.getBrushFromContent(content);
        let pen = null;
        if (!isNullOrUndefined(content.style.textPenColor)) {
            const penColor = this.hexToRgb(content.style.textPenColor);
            pen = new PdfPen(new PdfColor(penColor.r, penColor.g, penColor.b));
        }
        if (brush == null && pen == null) {
            brush = new PdfSolidBrush(new PdfColor(0, 0, 0));
        }
        const value = content.value.toString();
        const x = content.position.x * 0.75;
        const y = content.position.y * 0.75;
        const format = new PdfStringFormat();
        const result = this.setContentFormat(content, format);
        if (result !== null && !isNullOrUndefined(result.format) && !isNullOrUndefined(result.size)) {
            pageTemplate.graphics.drawString(value, font, pen, brush, x, y, result.size.width, result.size.height, result.format);
        }
        else {
            pageTemplate.graphics.drawString(value, font, pen, brush, x, y, format);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    drawPageNumber(documentHeader, content) {
        const font = this.getFont(content);
        let brush = null;
        if (!isNullOrUndefined(content.style.textBrushColor)) {
            const brushColor = this.hexToRgb(content.style.textBrushColor);
            brush = new PdfSolidBrush(new PdfColor(brushColor.r, brushColor.g, brushColor.b));
        }
        else {
            brush = new PdfSolidBrush(new PdfColor(0, 0, 0));
        }
        const pageNumber = new PdfPageNumberField(font, brush);
        pageNumber.numberStyle = this.getPageNumberStyle(content.pageNumberType);
        let compositeField;
        let format;
        if (!isNullOrUndefined(content.format)) {
            const total = '$total';
            const current = '$current';
            if (content.format.indexOf(total) !== -1 && content.format.indexOf(current) !== -1) {
                const pageCount = new PdfPageCountField(font);
                pageCount.numberStyle = this.getPageNumberStyle(content.pageNumberType);
                if (content.format.indexOf(total) > content.format.indexOf(current)) {
                    format = content.format.replace(current, '0');
                    format = format.replace(total, '1');
                }
                else {
                    format = content.format.replace(current, '1');
                    format = format.replace(total, '0');
                }
                compositeField = new PdfCompositeField(font, brush, format, pageNumber, pageCount);
            }
            else if (content.format.indexOf(current) !== -1 && content.format.indexOf(total) === -1) {
                format = content.format.replace(current, '0');
                compositeField = new PdfCompositeField(font, brush, format, pageNumber);
            }
            else {
                const pageCount = new PdfPageCountField(font);
                format = content.format.replace(total, '0');
                compositeField = new PdfCompositeField(font, brush, format, pageCount);
            }
        }
        else {
            format = '{0}';
            compositeField = new PdfCompositeField(font, brush, format, pageNumber);
        }
        const x = content.position.x * 0.75;
        const y = content.position.y * 0.75;
        const result = this.setContentFormat(content, compositeField.stringFormat);
        if (result !== null && !isNullOrUndefined(result.format) && !isNullOrUndefined(result.size)) {
            compositeField.stringFormat = result.format;
            compositeField.bounds = new RectangleF(x, y, result.size.width, result.size.height);
        }
        compositeField.draw(documentHeader.graphics, x, y);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    drawImage(documentHeader, content) {
        const x = content.position.x * 0.75;
        const y = content.position.y * 0.75;
        const width = (!isNullOrUndefined(content.size)) ? (content.size.width * 0.75) : undefined;
        const height = (!isNullOrUndefined(content.size)) ? (content.size.height * 0.75) : undefined;
        const image = new PdfBitmap(content.src);
        if (!isNullOrUndefined(width)) {
            documentHeader.graphics.drawImage(image, x, y, width, height);
        }
        else {
            documentHeader.graphics.drawImage(image, x, y);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    drawLine(documentHeader, content) {
        const x1 = content.points.x1 * 0.75;
        const y1 = content.points.y1 * 0.75;
        const x2 = content.points.x2 * 0.75;
        const y2 = content.points.y2 * 0.75;
        const pen = this.getPenFromContent(content);
        if (!isNullOrUndefined(content.style) && content.style !== null) {
            if (!isNullOrUndefined(content.style.penSize) && content.style.penSize !== null && typeof content.style.penSize === 'number') {
                pen.width = content.style.penSize * 0.75;
            }
            pen.dashStyle = this.getDashStyle(content.style.dashStyle);
        }
        documentHeader.graphics.drawLine(pen, x1, y1, x2, y2);
    }
    processAggregates(sRows, pdfGrid, border, font, brush, backgroundBrush, isCaption, captionRow, groupIndex, isGroupedFooter, isAggregate) {
        for (const row of sRows) {
            let leastCaptionSummaryIndex = -1;
            let index = 0;
            let isEmpty = true;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const value = [];
            const aggIdx = isAggregate ? 0 : 1;
            for (let i = 0; i < pdfGrid.columns.count + aggIdx; i++) {
                let cell = row.cells[index];
                if (cell.cellType === CellType.DetailFooterIntent) {
                    i--;
                    index++;
                    continue;
                }
                if (!this.hideColumnInclude) {
                    while (cell.visible === undefined) {
                        if (cell.cellType === CellType.DetailFooterIntent) {
                            continue;
                        }
                        if (!isNullOrUndefined(captionRow)) {
                            if (!isNullOrUndefined(captionRow.cells.getCell(i).value)) {
                                const args = { row: row, type: 'GroupCaption', style: captionRow.cells };
                                this.parent.trigger(events.pdfAggregateQueryCellInfo, args);
                                value.push(captionRow.cells.getCell(i).value);
                                isEmpty = false;
                                if (!isCaption) {
                                    i += 1;
                                }
                            }
                            else {
                                value.push('');
                            }
                        }
                        else {
                            value.push('');
                        }
                        i += 1;
                        index = index + 1;
                        cell = row.cells[index];
                    }
                    while (!isNullOrUndefined(cell.visible) && !cell.visible) {
                        index = index + 1;
                        cell = row.cells[index];
                    }
                }
                if (cell.isDataCell) {
                    let templateFn = {};
                    if (!isNullOrUndefined(cell.column.footerTemplate) || !isNullOrUndefined(cell.column.groupCaptionTemplate)
                        || !isNullOrUndefined(cell.column.groupFooterTemplate)) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const result = this.getTemplateFunction(templateFn, i, leastCaptionSummaryIndex, cell);
                        templateFn = result.templateFunction;
                        leastCaptionSummaryIndex = result.leastCaptionSummaryIndex;
                        let txt;
                        const data = row.data[cell.column.field ? cell.column.field : cell.column.columnName];
                        if (this.parent.isReact || this.parent.isVue) {
                            txt = (templateFn[getEnumValue(CellType, cell.cellType)](data, this.parent));
                            if (this.parent.isReact) {
                                this.parent.renderTemplates();
                            }
                        }
                        else {
                            txt = (templateFn[getEnumValue(CellType, cell.cellType)](data));
                        }
                        value.push(txt[0].textContent);
                        isEmpty = false;
                    }
                    else {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const result = this.getSummaryWithoutTemplate(row.data[cell.column.field]);
                        if (!isNullOrUndefined(result)) {
                            value.push(result);
                        }
                    }
                }
                else {
                    value.push('');
                }
                if (isEmpty && value[i] !== '' && !isNullOrUndefined(value[i]) && value[i] !== null) {
                    isEmpty = false;
                }
                index += 1;
            }
            if (!isAggregate) {
                if (!isCaption) {
                    value.splice(0, 1);
                }
                else {
                    for (let i = this.parent.groupSettings.columns.length; i < value.length - 1; i++) {
                        value[i] = value[i + 1];
                        value[i + 1] = value[i + 2] ? value[i + 2] : '';
                    }
                }
            }
            if (!isEmpty) {
                if (!isCaption) {
                    const gridRow = pdfGrid.rows.addRow();
                    gridRow.style.setBorder(border);
                    gridRow.style.setFont(font);
                    gridRow.style.setTextBrush(brush);
                    gridRow.style.setBackgroundBrush(backgroundBrush);
                    const args = {
                        row: row, type: isGroupedFooter ? 'GroupFooter' : 'Footer', style: gridRow.cells
                    };
                    this.parent.trigger(events.pdfAggregateQueryCellInfo, args);
                    for (let i = 0; i < pdfGrid.columns.count; i++) {
                        gridRow.cells.getCell(i).value = value[i].toString();
                    }
                }
                else {
                    for (let i = 0; i < pdfGrid.columns.count; i++) {
                        captionRow.cells.getCell(i).value = value[i].toString();
                        if (i === groupIndex && leastCaptionSummaryIndex !== -1 && leastCaptionSummaryIndex !== 1) {
                            captionRow.cells.getCell(i).columnSpan = (leastCaptionSummaryIndex - 1) - groupIndex;
                        }
                        else if (i === groupIndex && leastCaptionSummaryIndex === -1) {
                            captionRow.cells.getCell(i).columnSpan = pdfGrid.columns.count - groupIndex;
                        }
                    }
                }
            }
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getTemplateFunction(templateFn, index, leastCaptionSummaryIndex, cell) {
        if (!isNullOrUndefined(cell.column.footerTemplate) && cell.cellType === CellType.Summary) {
            templateFn[getEnumValue(CellType, CellType.Summary)] = compile(cell.column.footerTemplate);
        }
        else if (!isNullOrUndefined(cell.column.groupCaptionTemplate)) {
            if (leastCaptionSummaryIndex === -1) {
                leastCaptionSummaryIndex = index;
            }
            templateFn[getEnumValue(CellType, CellType.CaptionSummary)] = compile(cell.column.groupCaptionTemplate);
        }
        else {
            templateFn[getEnumValue(CellType, CellType.GroupSummary)] = compile(cell.column.groupFooterTemplate);
        }
        return { templateFunction: templateFn, leastCaptionSummaryIndex: leastCaptionSummaryIndex };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getSummaryWithoutTemplate(data) {
        if (!isNullOrUndefined(data.Sum)) {
            return data.Sum;
        }
        else if (!isNullOrUndefined(data.Average)) {
            return data.Average;
        }
        else if (!isNullOrUndefined(data.Max)) {
            return data.Max;
        }
        else if (!isNullOrUndefined(data.Min)) {
            return data.Min;
        }
        else if (!isNullOrUndefined(data.Count)) {
            return data.Count;
        }
        else if (!isNullOrUndefined(data.TrueCount)) {
            return data.TrueCount;
        }
        else if (!isNullOrUndefined(data.FalseCount)) {
            return data.FalseCount;
        }
        else if (!isNullOrUndefined(data.Custom)) {
            return data.Custom;
        }
    }
    /**
     * Set alignment, width and type of the values of the column
     *
     * @param {Column[]} gridColumns - specifies the grid column
     * @param {PdfGrid} pdfGrid - specifies the pdfGrid
     * @param {ExportHelper} helper - specifies the helper
     * @param {IGrid} gObj - specifies the IGrid
     * @param {boolean} allowHorizontalOverflow - specifies the allowHorizontalOverflow
     * @returns {void}
     */
    setColumnProperties(gridColumns, pdfGrid, helper, gObj, allowHorizontalOverflow) {
        const startIndex = gObj.groupSettings.columns.length ? gObj.groupSettings.columns.length - 1 : 0;
        for (let i = 0; i < startIndex; i++) {
            pdfGrid.columns.getColumn(i).width = 20;
        }
        for (let i = 0; i < gridColumns.length; i++) {
            if (!isNullOrUndefined(gridColumns[i].textAlign)) {
                pdfGrid.columns.getColumn(i + startIndex).format = this.getHorizontalAlignment(gridColumns[i].textAlign);
            }
            // Need to add width consideration with % value
            if (pdfGrid.style.allowHorizontalOverflow && !isNullOrUndefined(gridColumns[i].width) && allowHorizontalOverflow) {
                pdfGrid.columns.getColumn(i + startIndex).width = typeof gridColumns[i].width === 'number' ?
                    gridColumns[i].width * 0.75 : helper.getConvertedWidth(gridColumns[i].width) * 0.75;
            }
        }
    }
    /**
     * set default style properties of each rows in exporting grid
     *
     * @param {PdfGridRow} row - specifies the PdfGridRow
     * @param {PdfBorders} border - specifies the PdfBorders
     * @returns {PdfGrid} returns the pdfgrid
     * @private
     */
    setRecordThemeStyle(row, border) {
        if (!isNullOrUndefined(this.gridTheme) && !isNullOrUndefined(this.gridTheme.record)) {
            const fontFamily = !isNullOrUndefined(this.gridTheme.record.fontName) ?
                this.getFontFamily(this.gridTheme.record.fontName) : PdfFontFamily.Helvetica;
            const fontSize = !isNullOrUndefined(this.gridTheme.record.fontSize) ? this.gridTheme.record.fontSize : 9.75;
            const fontStyle = this.getFontStyle(this.gridTheme.record);
            let font = new PdfStandardFont(fontFamily, fontSize, fontStyle);
            if (!isNullOrUndefined(this.gridTheme.record.font)) {
                font = this.gridTheme.record.font;
            }
            row.style.setFont(font);
            let pdfColor = new PdfColor();
            if (!isNullOrUndefined(this.gridTheme.record.fontColor)) {
                const penBrushColor = this.hexToRgb(this.gridTheme.record.fontColor);
                pdfColor = new PdfColor(penBrushColor.r, penBrushColor.g, penBrushColor.b);
            }
            row.style.setTextBrush(new PdfSolidBrush(pdfColor));
        }
        else {
            row.style.setTextBrush(new PdfSolidBrush(new PdfColor(0, 0, 0)));
        }
        const borderRecord = this.gridTheme && this.gridTheme.record &&
            this.gridTheme.record.border ? this.getBorderStyle(this.gridTheme.record.border) : border;
        row.style.setBorder(borderRecord);
        return row;
    }
    /**
     * generate the formatted cell values
     *
     * @param {PdfBorders} border - specifies the border
     * @param {Column[]} columns - specifies the columns
     * @param {IGrid} gObj - specifies the IGrid
     * @param {Object[]} dataSource - specifies the datasource
     * @param {PdfGrid} pdfGrid - specifies the pdfGrid
     * @param {number} startIndex - specifies the startindex
     * @param {PdfExportProperties} pdfExportProperties - specifies the pdfExportProperties
     * @param {ExportHelper} helper - specifies the helper
     * @param {number} rowIndex - specifies the rowIndex
     * @returns {number} returns the number of records
     * @private
     */
    processRecord(border, columns, gObj, dataSource, pdfGrid, startIndex, pdfExportProperties, helper, rowIndex) {
        const rows = helper.getGridRowModel(columns, dataSource, gObj, rowIndex);
        for (const row of rows) {
            rowIndex++;
            // create a new row and set default style properties
            const gridRow = this.setRecordThemeStyle(pdfGrid.rows.addRow(), border);
            const cellLength = row.cells.length;
            for (let j = 0; j < cellLength; j++) {
                const gridCell = row.cells[j];
                if (gridCell.cellType !== CellType.Data) {
                    continue;
                }
                const column = gridCell.column;
                const field = column.field;
                const cellValue = !isNullOrUndefined(field) ? column.valueAccessor(field, row.data, column) : '';
                let value = !isNullOrUndefined(cellValue) ? cellValue : '';
                let foreignKeyData;
                if (column.isForeignColumn && column.isForeignColumn()) {
                    foreignKeyData = helper.getFData(value, column);
                    value = getValue(column.foreignKeyValue, foreignKeyData);
                }
                const data = row.data;
                const cell = gridRow.cells.getCell(j);
                const args = {
                    data: data,
                    value: value,
                    column: column,
                    style: undefined,
                    colSpan: 1,
                    cell: cell
                };
                args.value = args.column.type === 'boolean' && typeof args.value === 'string' ? args.value :
                    this.exportValueFormatter.formatCellValue(args);
                this.parent.trigger(events.pdfQueryCellInfo, args);
                if (!isNullOrUndefined(args.image)) {
                    args.value = new PdfBitmap(args.image.base64);
                    args.value.height = args.image.height || args.value.height;
                    args.value.width = args.image.width || args.value.width;
                }
                cell.value = args.value;
                if (!isNullOrUndefined(args.hyperLink)) {
                    cell.value = this.setHyperLink(args);
                }
                if (!isNullOrUndefined(args.style)) {
                    this.processCellStyle(cell, args);
                }
                if (args.colSpan > 1) {
                    if ((j + 1 + args.colSpan) > gridRow.cells.count) {
                        args.colSpan = gridRow.cells.count - (j + 1);
                    }
                    cell.columnSpan = args.colSpan;
                    for (let i = 1; i < cell.columnSpan; i++) {
                        const spanCell = gridRow.cells.getCell(j + i);
                        spanCell.value = '';
                    }
                    j += (args.colSpan - 1);
                }
            }
            if (row.isExpand) {
                const gridRow = this.setRecordThemeStyle(pdfGrid.rows.addRow(), border);
                const cell = gridRow.cells.getCell(startIndex);
                cell.columnSpan = gridRow.cells.count - (startIndex);
                cell.style.cellPadding = new PdfPaddings(10, 10, 10, 10);
                gObj.isPrinting = true;
                const exportType = (!isNullOrUndefined(pdfExportProperties) && pdfExportProperties.exportType) ?
                    pdfExportProperties.exportType : 'AllPages';
                const returnValue = this.helper.createChildGrid(gObj, row, exportType, this.gridPool);
                const childGridObj = returnValue.childGrid;
                const element = returnValue.element;
                childGridObj.actionFailure =
                    helper.failureHandler(this.gridPool, childGridObj, this.globalResolve);
                const args = { childGrid: childGridObj, row, cell, exportProperties: pdfExportProperties };
                this.parent.trigger(events.exportDetailDataBound, args);
                childGridObj.beforeDataBound = this.childGridCell(cell, childGridObj, pdfExportProperties);
                childGridObj.appendTo(element);
            }
            this.parent.notify(events.exportRowDataBound, { type: 'pdf', rowObj: row });
        }
        return rowIndex;
    }
    setHyperLink(args) {
        // create the Text Web Link
        const textLink = new PdfTextWebLink();
        // set the hyperlink
        textLink.url = args.hyperLink.target;
        // set the link text
        textLink.text = args.hyperLink.displayText || args.hyperLink.target;
        // set the font
        textLink.font = new PdfStandardFont(PdfFontFamily.Helvetica, 9.75);
        // set the brush and pen for the text color
        textLink.brush = new PdfSolidBrush(new PdfColor(51, 102, 187));
        return textLink;
    }
    childGridCell(cell, childGridObj, pdfExportProperties) {
        return (result) => {
            childGridObj.beforeDataBound = null;
            result.cancel = true;
            cell.value = this.processGridExport(childGridObj, result, pdfExportProperties);
            childGridObj.destroy();
            detach(childGridObj.element);
            this.gridPool[childGridObj.id] = true;
            this.helper.checkAndExport(this.gridPool, this.globalResolve);
            return cell;
        };
    }
    processCellStyle(cell, args) {
        if (!isNullOrUndefined(args.style.backgroundColor)) {
            const backColor = this.hexToRgb(args.style.backgroundColor);
            cell.style.backgroundBrush = new PdfSolidBrush(new PdfColor(backColor.r, backColor.g, backColor.b));
        }
        if (!isNullOrUndefined(args.style.textAlignment)) {
            cell.style.stringFormat = this.getHorizontalAlignment(args.style.textAlignment);
        }
        if (!isNullOrUndefined(args.style.cellPadding)) {
            cell.style.cellPadding = args.style.cellPadding;
        }
        if (!isNullOrUndefined(args.style.verticalAlignment)) {
            cell.style.stringFormat = this.getVerticalAlignment(args.style.verticalAlignment, cell.style.stringFormat);
        }
        if (!isNullOrUndefined(args.style.textBrushColor)) {
            const textBrushColor = this.hexToRgb(args.style.textBrushColor);
            cell.style.textBrush = new PdfSolidBrush(new PdfColor(textBrushColor.r, textBrushColor.g, textBrushColor.b));
        }
        if (!isNullOrUndefined(args.style.textPenColor)) {
            const textPenColor = this.hexToRgb(args.style.textPenColor);
            cell.style.textPen = new PdfPen(new PdfColor(textPenColor.r, textPenColor.g, textPenColor.b));
        }
        if (!isNullOrUndefined(args.style.fontFamily) || !isNullOrUndefined(args.style.fontSize) || !isNullOrUndefined(args.style.bold) ||
            !isNullOrUndefined(args.style.italic) || !isNullOrUndefined(args.style.underline) || !isNullOrUndefined(args.style.strikeout)) {
            cell.style.font = this.getFont(args);
        }
        if (!isNullOrUndefined(args.style.border)) {
            const border = new PdfBorders();
            const borderWidth = args.style.border.width;
            // set border width
            const width = (!isNullOrUndefined(borderWidth) && typeof borderWidth === 'number') ? (borderWidth * 0.75) : (undefined);
            // set border color
            let color = new PdfColor(196, 196, 196);
            if (!isNullOrUndefined(args.style.border.color)) {
                const borderColor = this.hexToRgb(args.style.border.color);
                color = new PdfColor(borderColor.r, borderColor.g, borderColor.b);
            }
            const pen = new PdfPen(color, width);
            // set border dashStyle 'Solid <default>, Dash, Dot, DashDot, DashDotDot'
            if (!isNullOrUndefined(args.style.border.dashStyle)) {
                pen.dashStyle = this.getDashStyle(args.style.border.dashStyle);
            }
            border.all = pen;
            cell.style.borders = border;
        }
        if (!isNullOrUndefined(args.style.paragraphIndent)) {
            cell.style.stringFormat = new PdfStringFormat();
            cell.style.stringFormat.paragraphIndent = args.style.paragraphIndent;
        }
    }
    /**
     * set text alignment of each columns in exporting grid
     *
     * @param {string} textAlign - specifies the textAlign
     * @param {PdfStringFormat} format - specifies the PdfStringFormat
     * @returns {PdfStringFormat} returns the PdfStringFormat
     * @private
     */
    getHorizontalAlignment(textAlign, format) {
        if (format === undefined) {
            format = new PdfStringFormat();
        }
        switch (textAlign) {
            case 'Right':
                format.alignment = PdfTextAlignment.Right;
                break;
            case 'Center':
                format.alignment = PdfTextAlignment.Center;
                break;
            case 'Justify':
                format.alignment = PdfTextAlignment.Justify;
                break;
            case 'Left':
                format.alignment = PdfTextAlignment.Left;
                break;
        }
        return format;
    }
    /**
     * set vertical alignment of each columns in exporting grid
     *
     * @param {string} verticalAlign - specifies the verticalAlign
     * @param {PdfStringFormat} format - specifies the PdfStringFormat
     * @param {string} textAlign - specifies the text align
     * @returns {PdfStringFormat} returns the PdfStringFormat
     * @private
     */
    getVerticalAlignment(verticalAlign, format, textAlign) {
        if (format === undefined) {
            format = new PdfStringFormat();
            format = this.getHorizontalAlignment(textAlign, format);
        }
        switch (verticalAlign) {
            case 'Bottom':
                format.lineAlignment = PdfVerticalAlignment.Bottom;
                break;
            case 'Middle':
                format.lineAlignment = PdfVerticalAlignment.Middle;
                break;
            case 'Top':
                format.lineAlignment = PdfVerticalAlignment.Top;
                break;
        }
        return format;
    }
    getFontFamily(fontFamily) {
        switch (fontFamily) {
            case 'TimesRoman':
                return 2;
            case 'Courier':
                return 1;
            case 'Symbol':
                return 3;
            case 'ZapfDingbats':
                return 4;
            default:
                return 0;
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFont(content) {
        if (content.font) {
            return content.font;
        }
        const fontSize = (!isNullOrUndefined(content.style.fontSize)) ? (content.style.fontSize * 0.75) : 9.75;
        const fontFamily = (!isNullOrUndefined(content.style.fontFamily)) ?
            (this.getFontFamily(content.style.fontFamily)) : PdfFontFamily.TimesRoman;
        let fontStyle = PdfFontStyle.Regular;
        if (!isNullOrUndefined(content.style.bold) && content.style.bold) {
            fontStyle |= PdfFontStyle.Bold;
        }
        if (!isNullOrUndefined(content.style.italic) && content.style.italic) {
            fontStyle |= PdfFontStyle.Italic;
        }
        if (!isNullOrUndefined(content.style.underline) && content.style.underline) {
            fontStyle |= PdfFontStyle.Underline;
        }
        if (!isNullOrUndefined(content.style.strikeout) && content.style.strikeout) {
            fontStyle |= PdfFontStyle.Strikeout;
        }
        return new PdfStandardFont(fontFamily, fontSize, fontStyle);
    }
    getPageNumberStyle(pageNumberType) {
        switch (pageNumberType) {
            case 'LowerLatin':
                return 2;
            case 'LowerRoman':
                return 3;
            case 'UpperLatin':
                return 4;
            case 'UpperRoman':
                return 5;
            default:
                return 1;
        }
    }
    setContentFormat(content, format) {
        if (!isNullOrUndefined(content.size)) {
            const width = content.size.width * 0.75;
            const height = content.size.height * 0.75;
            format = new PdfStringFormat(PdfTextAlignment.Left, PdfVerticalAlignment.Middle);
            if (!isNullOrUndefined(content.style.hAlign)) {
                switch (content.style.hAlign) {
                    case 'Right':
                        format.alignment = PdfTextAlignment.Right;
                        break;
                    case 'Center':
                        format.alignment = PdfTextAlignment.Center;
                        break;
                    case 'Justify':
                        format.alignment = PdfTextAlignment.Justify;
                        break;
                    default:
                        format.alignment = PdfTextAlignment.Left;
                }
            }
            if (!isNullOrUndefined(content.style.vAlign)) {
                format = this.getVerticalAlignment(content.style.vAlign, format);
            }
            return { format: format, size: new SizeF(width, height) };
        }
        return null;
    }
    getPageSize(pageSize) {
        switch (pageSize) {
            case 'Letter':
                return new SizeF(612, 792);
            case 'Note':
                return new SizeF(540, 720);
            case 'Legal':
                return new SizeF(612, 1008);
            case 'A0':
                return new SizeF(2380, 3368);
            case 'A1':
                return new SizeF(1684, 2380);
            case 'A2':
                return new SizeF(1190, 1684);
            case 'A3':
                return new SizeF(842, 1190);
            case 'A5':
                return new SizeF(421, 595);
            case 'A6':
                return new SizeF(297, 421);
            case 'A7':
                return new SizeF(210, 297);
            case 'A8':
                return new SizeF(148, 210);
            case 'A9':
                return new SizeF(105, 148);
            // case 'A10':
            // return new SizeF(74, 105);
            case 'B0':
                return new SizeF(2836, 4008);
            case 'B1':
                return new SizeF(2004, 2836);
            case 'B2':
                return new SizeF(1418, 2004);
            case 'B3':
                return new SizeF(1002, 1418);
            case 'B4':
                return new SizeF(709, 1002);
            case 'B5':
                return new SizeF(501, 709);
            case 'Archa':
                return new SizeF(648, 864);
            case 'Archb':
                return new SizeF(864, 1296);
            case 'Archc':
                return new SizeF(1296, 1728);
            case 'Archd':
                return new SizeF(1728, 2592);
            case 'Arche':
                return new SizeF(2592, 3456);
            case 'Flsa':
                return new SizeF(612, 936);
            case 'HalfLetter':
                return new SizeF(396, 612);
            case 'Letter11x17':
                return new SizeF(792, 1224);
            case 'Ledger':
                return new SizeF(1224, 792);
            default:
                return new SizeF(595, 842);
        }
    }
    getDashStyle(dashStyle) {
        switch (dashStyle) {
            case 'Dash':
                return 1;
            case 'Dot':
                return 2;
            case 'DashDot':
                return 3;
            case 'DashDotDot':
                return 4;
            default:
                return 0;
        }
    }
    getPenFromContent(content) {
        let pen = new PdfPen(new PdfColor(0, 0, 0));
        if (!isNullOrUndefined(content.style) && content.style !== null && !isNullOrUndefined(content.style.penColor)) {
            const penColor = this.hexToRgb(content.style.penColor);
            pen = new PdfPen(new PdfColor(penColor.r, penColor.g, penColor.b));
        }
        return pen;
    }
    getBrushFromContent(content) {
        let brush = null;
        if (!isNullOrUndefined(content.style.textBrushColor)) {
            /* tslint:disable-next-line:max-line-length */
            const brushColor = this.hexToRgb(content.style.textBrushColor);
            brush = new PdfSolidBrush(new PdfColor(brushColor.r, brushColor.g, brushColor.b));
        }
        return brush;
    }
    hexToRgb(hex) {
        if (hex === null || hex === '' || hex.length !== 7) {
            throw new Error('please set valid hex value for color...');
        }
        hex = hex.substring(1);
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return { r: r, g: g, b: b };
    }
    getFontStyle(theme) {
        let fontStyle = PdfFontStyle.Regular;
        if (!isNullOrUndefined(theme) && theme.bold) {
            fontStyle |= PdfFontStyle.Bold;
        }
        if (!isNullOrUndefined(theme) && theme.italic) {
            fontStyle |= PdfFontStyle.Italic;
        }
        if (!isNullOrUndefined(theme) && theme.underline) {
            fontStyle |= PdfFontStyle.Underline;
        }
        if (!isNullOrUndefined(theme) && theme.strikeout) {
            fontStyle |= PdfFontStyle.Strikeout;
        }
        return fontStyle;
    }
    getBorderStyle(border) {
        const borders = new PdfBorders();
        if (!isNullOrUndefined(border)) {
            const borderWidth = border.width;
            // set border width
            const width = (!isNullOrUndefined(borderWidth) && typeof borderWidth === 'number') ? borderWidth * 0.75 : undefined;
            // set border color
            let color = new PdfColor(196, 196, 196);
            if (!isNullOrUndefined(border.color)) {
                const borderColor = this.hexToRgb(border.color);
                color = new PdfColor(borderColor.r, borderColor.g, borderColor.b);
            }
            const pen = new PdfPen(color, width);
            // set border dashStyle 'Solid <default>, Dash, Dot, DashDot, DashDotDot'
            if (!isNullOrUndefined(border.dashStyle)) {
                pen.dashStyle = this.getDashStyle(border.dashStyle);
            }
            borders.all = pen;
        }
        else {
            borders.all = new PdfPen(new PdfColor(234, 234, 234));
        }
        return borders;
    }
    destroy() {
        //destroy for exporting
    }
}
