import { isNullOrUndefined, getValue, setValue } from '@syncfusion/ej2-base';
import { Row } from '../models/row';
import { CellType } from '../base/enum';
import { Cell } from '../models/cell';
import { getUid } from '../base/util';
import { getForeignData } from '../../grid/base/util';
import * as events from '../base/constant';
/**
 * RowModelGenerator is used to generate grid data rows.
 *
 * @hidden
 */
export class RowModelGenerator {
    /**
     * Constructor for header renderer module
     *
     * @param {IGrid} parent - specifies the IGrid
     */
    constructor(parent) {
        this.parent = parent;
    }
    generateRows(data, args) {
        const rows = [];
        let startIndex = this.parent.enableVirtualization && args ? args.startIndex : 0;
        startIndex = this.parent.enableInfiniteScrolling && args ? this.getInfiniteIndex(args) : startIndex;
        for (let i = 0, len = Object.keys(data).length; i < len; i++, startIndex++) {
            rows[i] = this.generateRow(data[i], startIndex);
        }
        return rows;
    }
    ensureColumns() {
        //TODO: generate dummy column for group, detail here;
        const cols = [];
        if (this.parent.detailTemplate || this.parent.childGrid) {
            const args = {};
            this.parent.notify(events.detailIndentCellInfo, args);
            cols.push(this.generateCell(args, null, CellType.DetailExpand));
        }
        if (this.parent.isRowDragable()) {
            cols.push(this.generateCell({}, null, CellType.RowDragIcon));
        }
        return cols;
    }
    generateRow(data, index, cssClass, indent, pid, tIndex, parentUid) {
        const options = {};
        options.foreignKeyData = {};
        options.uid = getUid('grid-row');
        options.data = data;
        options.index = index;
        options.indent = indent;
        options.tIndex = tIndex;
        options.isDataRow = true;
        options.parentGid = pid;
        options.parentUid = parentUid;
        if (this.parent.isPrinting) {
            if (this.parent.hierarchyPrintMode === 'All') {
                options.isExpand = true;
            }
            else if (this.parent.hierarchyPrintMode === 'Expanded' && this.parent.expandedRows && this.parent.expandedRows[index]) {
                options.isExpand = this.parent.expandedRows[index].isExpand;
            }
        }
        options.cssClass = cssClass;
        options.isAltRow = this.parent.enableAltRow ? index % 2 !== 0 : false;
        options.isAltRow = this.parent.enableAltRow ? index % 2 !== 0 : false;
        options.isSelected = this.parent.getSelectedRowIndexes().indexOf(index) > -1;
        this.refreshForeignKeyRow(options);
        const cells = this.ensureColumns();
        const row = new Row(options, this.parent);
        row.cells = this.parent.getFrozenMode() === 'Right' ? this.generateCells(options).concat(cells)
            : cells.concat(this.generateCells(options));
        return row;
    }
    refreshForeignKeyRow(options) {
        const foreignKeyColumns = this.parent.getForeignKeyColumns();
        for (let i = 0; i < foreignKeyColumns.length; i++) {
            setValue(foreignKeyColumns[i].field, getForeignData(foreignKeyColumns[i], options.data), options.foreignKeyData);
        }
    }
    generateCells(options) {
        const dummies = this.parent.getColumns();
        const tmp = [];
        for (let i = 0; i < dummies.length; i++) {
            tmp.push(this.generateCell(dummies[i], options.uid, isNullOrUndefined(dummies[i].commands) ? undefined : CellType.CommandColumn, null, i, options.foreignKeyData));
        }
        return tmp;
    }
    /**
     *
     * @param {Column} column - Defines column details
     * @param {string} rowId - Defines row id
     * @param {CellType} cellType  - Defines cell type
     * @param {number} colSpan - Defines colSpan
     * @param {number} oIndex - Defines index
     * @param {Object} foreignKeyData - Defines foreign key data
     * @returns {Cell<Column>} returns cell model
     * @hidden
     */
    generateCell(column, rowId, cellType, colSpan, oIndex, foreignKeyData) {
        const opt = {
            'visible': column.visible,
            'isDataCell': !isNullOrUndefined(column.field || column.template),
            'isTemplate': !isNullOrUndefined(column.template),
            'rowID': rowId,
            'column': column,
            'cellType': !isNullOrUndefined(cellType) ? cellType : CellType.Data,
            'colSpan': colSpan,
            'commands': column.commands,
            'isForeignKey': column.isForeignColumn && column.isForeignColumn(),
            'foreignKeyData': column.isForeignColumn && column.isForeignColumn() && getValue(column.field, foreignKeyData)
        };
        if (opt.isDataCell || opt.column.type === 'checkbox' || opt.commands) {
            opt.index = oIndex;
        }
        return new Cell(opt);
    }
    refreshRows(input) {
        for (let i = 0; i < input.length; i++) {
            this.refreshForeignKeyRow(input[i]);
            input[i].cells = this.generateCells(input[i]);
        }
        return input;
    }
    getInfiniteIndex(args) {
        return args.requestType === 'infiniteScroll' || args.requestType === 'delete' || args.action === 'add'
            ? args.startIndex : 0;
    }
}
