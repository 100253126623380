import { merge } from '@syncfusion/ej2-base';
/**
 * Row
 *
 * @hidden
 */
export class Row {
    constructor(options, parent) {
        merge(this, options);
        this.parent = parent;
    }
    clone() {
        const row = new Row({});
        merge(row, this);
        row.cells = this.cells.map((cell) => cell.clone());
        return row;
    }
    /**
     * Replaces the row data and grid refresh the particular row element only.
     *
     * @param  {Object} data - To update new data for the particular row.
     * @returns {void}
     */
    setRowValue(data) {
        if (!this.parent) {
            return;
        }
        const key = this.data[this.parent.getPrimaryKeyFieldNames()[0]];
        this.parent.setRowData(key, data);
    }
    /**
     * Replaces the given field value and refresh the particular cell element only.
     *
     * @param {string} field - Specifies the field name which you want to update.
     * @param {string | number | boolean | Date} value - To update new value for the particular cell.
     * @returns {void}
     */
    setCellValue(field, value) {
        if (!this.parent) {
            return;
        }
        const isValDiff = !(this.data[field].toString() === value.toString());
        if (isValDiff) {
            const pKeyField = this.parent.getPrimaryKeyFieldNames()[0];
            const key = this.data[pKeyField];
            this.parent.setCellValue(key, field, value);
            this.makechanges(pKeyField, this.data);
        }
        else {
            return;
        }
    }
    makechanges(key, data) {
        if (!this.parent) {
            return;
        }
        const gObj = this.parent;
        const dataManager = gObj.getDataModule().dataManager;
        dataManager.update(key, data);
    }
}
