import { merge } from '@syncfusion/ej2-base';
/**
 * Cell
 *
 * @hidden
 */
export class Cell {
    constructor(options) {
        this.isSpanned = false;
        this.isRowSpanned = false;
        merge(this, options);
    }
    clone() {
        const cell = new Cell({});
        merge(cell, this);
        return cell;
    }
}
