import { ExcelFilterBase } from '../common/excel-filter-base';
import { CheckBoxFilter } from './checkbox-filter';
import * as events from '../base/constant';
/**
 * @hidden
 * `ExcelFilter` module is used to handle filtering action.
 */
export class ExcelFilter extends CheckBoxFilter {
    /**
     * Constructor for excelbox filtering module
     *
     * @param {IGrid} parent - specifies the IGrid
     * @param {FilterSettings} filterSettings - specifies the Filtersettings
     * @param {ServiceLocator} serviceLocator - specifies the serviceLocator
     * @param {object} customFltrOperators - specifies the customFltrOperators
     * @hidden
     */
    constructor(parent, filterSettings, serviceLocator, customFltrOperators) {
        super(parent, filterSettings, serviceLocator);
        this.parent = parent;
        this.isresetFocus = true;
        this.excelFilterBase = new ExcelFilterBase(parent, customFltrOperators);
    }
    /**
     * To destroy the excel filter.
     *
     * @returns {void}
     * @hidden
     */
    destroy() {
        this.excelFilterBase.closeDialog();
    }
    openDialog(options) {
        this.excelFilterBase.openDialog(options);
    }
    closeDialog() {
        this.excelFilterBase.closeDialog();
        if (this.isresetFocus) {
            this.parent.notify(events.restoreFocus, {});
        }
    }
    clearCustomFilter(col) {
        this.excelFilterBase.clearFilter(col);
    }
    closeResponsiveDialog(isCustomFilter) {
        if (isCustomFilter) {
            this.excelFilterBase.removeDialog();
        }
        else {
            this.closeDialog();
        }
    }
    applyCustomFilter(args) {
        if (!args.isCustomFilter) {
            this.excelFilterBase.fltrBtnHandler();
            this.excelFilterBase.closeDialog();
        }
        else {
            this.excelFilterBase.filterBtnClick(args.col.field);
        }
    }
    filterByColumn(fieldName, firstOperator, firstValue, predicate, matchCase, ignoreAccent, secondOperator, secondValue) {
        this.excelFilterBase.filterByColumn(fieldName, firstOperator, firstValue, predicate, matchCase, ignoreAccent, secondOperator, secondValue);
    }
    /**
     * @returns {FilterUI} returns the filterUI
     * @hidden
     */
    getFilterUIInfo() {
        return this.excelFilterBase.getFilterUIInfo();
    }
    /**
     * For internal use only - Get the module name.
     *
     * @returns {string} returns the module name
     * @private
     */
    getModuleName() {
        return 'excelFilter';
    }
}
