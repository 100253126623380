import { EventHandler, getValue, closest, isNullOrUndefined } from '@syncfusion/ej2-base';
import { addClass, removeClass, extend, Browser } from '@syncfusion/ej2-base';
import { CellType } from '../base/enum';
import * as event from '../base/constant';
import { Row } from '../models/row';
import { RowModelGenerator } from './row-model-generator';
import { parentsUntil, addRemoveEventListener, findCellIndex } from '../base/util';
import * as literals from '../base/string-literals';
/**
 * FocusStrategy class
 *
 * @hidden
 */
export class FocusStrategy {
    constructor(parent) {
        this.currentInfo = {};
        this.oneTime = true;
        this.swap = {};
        /** @hidden */
        this.isInfiniteScroll = false;
        this.forget = false;
        this.skipFocus = true;
        this.focusByClick = false;
        this.prevIndexes = {};
        this.refMatrix = this.refreshMatrix(true);
        this.actions = ['downArrow', 'upArrow'];
        this.isVirtualScroll = false;
        this.parent = parent;
        this.rowModelGen = new RowModelGenerator(this.parent);
        this.addEventListener();
    }
    focusCheck(e) {
        const target = e.target;
        this.focusByClick = true;
        this.skipFocus = target.classList.contains('e-grid');
    }
    onFocus(e) {
        if (this.parent.isDestroyed || Browser.isDevice || this.parent.enableVirtualization) {
            return;
        }
        this.setActive(!this.parent.enableHeaderFocus && this.parent.frozenRows === 0, this.parent.isFrozenGrid());
        if (!this.parent.enableHeaderFocus && !this.parent.getCurrentViewRecords().length && ((this.parent.editSettings.mode !== 'Batch')
            || (this.parent.editSettings.mode === 'Batch' && this.parent.editModule && !this.parent.editModule.getBatchChanges()[literals.addedRecords].length))) {
            this.getContent().matrix.
                generate(this.rowModelGen.generateRows({ rows: [new Row({ isDataRow: true })] }), this.getContent().selector, false);
        }
        const current = this.getContent().matrix.get(0, -1, [0, 1], null, this.getContent().validator());
        this.getContent().matrix.select(current[0], current[1]);
        if (this.skipFocus && !(e && e.target === this.parent.element)) {
            this.focus(e);
            this.skipFocus = false;
        }
    }
    passiveFocus(e) {
        if (this.parent.isDestroyed) {
            return;
        }
        if (e.target && e.target.classList.contains('e-detailcell')) {
            this.currentInfo.skipAction = false;
            addClass([this.currentInfo.element], ['e-focused', 'e-focus']);
        }
    }
    onBlur(e) {
        if ((this.parent.isEdit || e && (!e.relatedTarget || closest(e.relatedTarget, '.e-grid'))
            && !(this.parent.element.classList.contains('e-childgrid') && !this.parent.element.matches(':focus-within')))
            && !(isNullOrUndefined(e.relatedTarget) && parseInt(e.target.getAttribute('data-colindex'), 10) === 0 &&
                parseInt(e.target.getAttribute('index'), 10) === 0)) {
            return;
        }
        this.removeFocus();
        this.skipFocus = true;
        this.currentInfo.skipAction = false;
        this.setLastContentCellTabIndex();
        this.parent.element.tabIndex = 0;
    }
    setLastContentCellTabIndex() {
        const contentTable = this.parent.getContentTable();
        const lastCell = contentTable.rows[contentTable.rows.length - 1].lastElementChild;
        lastCell.tabIndex = 0;
    }
    onClick(e, force) {
        if (parentsUntil(e.target, 'e-filterbarcell') && (parentsUntil(e.target, 'e-multiselect') ||
            e.target.classList.contains('e-input-group-icon'))) {
            return;
        }
        let isContent = !isNullOrUndefined(closest(e.target, '.' + literals.gridContent));
        const isHeader = !isNullOrUndefined(closest(e.target, '.' + literals.gridHeader));
        isContent = isContent && isHeader ? !isContent : isContent;
        let isFrozen = !isNullOrUndefined(closest(e.target, '.' + literals.frozenContent)) ||
            !isNullOrUndefined(closest(e.target, '.' + literals.frozenHeader));
        let isFrozenRight = false;
        if (this.parent.getFrozenMode() === literals.leftRight) {
            isFrozenRight = !isNullOrUndefined(closest(e.target, '.e-frozen-right-content')) ||
                !isNullOrUndefined(closest(e.target, '.e-frozen-right-header'));
            isFrozen = isFrozen && !isFrozenRight;
        }
        if (!isContent && isNullOrUndefined(closest(e.target, '.' + literals.gridHeader)) ||
            e.target.classList.contains(literals.content) ||
            !isNullOrUndefined(closest(e.target, '.e-unboundcell'))) {
            return;
        }
        this.setActive(isContent, isFrozen, isFrozenRight);
        if (!isContent && isNullOrUndefined(closest(e.target, '.' + literals.gridHeader))) {
            this.clearOutline();
            return;
        }
        const beforeArgs = { cancel: false, byKey: false, byClick: !isNullOrUndefined(e.target), clickArgs: e };
        this.parent.notify(event.beforeCellFocused, beforeArgs);
        if (beforeArgs.cancel || closest(e.target, '.e-inline-edit')) {
            return;
        }
        this.setActive(isContent, isFrozen, isFrozenRight);
        if (this.getContent()) {
            const returnVal = this.getContent().onClick(e, force);
            if (returnVal === false) {
                return;
            }
            this.focus();
        }
    }
    onKeyPress(e) {
        if (this.parent.allowPaging) {
            const pagerElement = this.parent.pagerModule.pagerObj.element;
            const focusablePagerElements = this.parent.pagerModule.pagerObj.getFocusablePagerElements(pagerElement, []);
            if (this.parent.childGrid && this.allowToPaging(e) && focusablePagerElements.length) {
                focusablePagerElements[0].tabIndex = 0;
            }
            if (this.parent.pagerModule.pagerObj.checkPagerHasFocus()) {
                if (e.action === 'shiftTab' && focusablePagerElements.length && focusablePagerElements[0] === e.target) {
                    this.setActive(true);
                    let lastHeaderCellIndex = [this.active.matrix.matrix.length - 1,
                        this.active.matrix.matrix[this.active.matrix.matrix.length - 1].length - 1];
                    if (this.active.matrix.matrix[lastHeaderCellIndex[0]][lastHeaderCellIndex[1]] === 0) {
                        lastHeaderCellIndex = findCellIndex(this.active.matrix.matrix, lastHeaderCellIndex, false);
                    }
                    this.active.matrix.current = lastHeaderCellIndex;
                    e.preventDefault();
                    this.focus(e);
                    return;
                }
                if (!(e.action === 'tab' && this.parent.element.classList.contains('e-childgrid')
                    && ((!this.parent.pageSettings.pageSizes && focusablePagerElements.length
                        && focusablePagerElements[focusablePagerElements.length - 1] === e.target)
                        || (this.parent.pagerModule.pagerObj.getDropDownPage() === e.target)))) {
                    this.parent.pagerModule.pagerObj.changePagerFocus(e);
                    return;
                }
                else {
                    const parentCell = parentsUntil(this.parent.element, 'e-detailcell');
                    removeClass([this.parent.element], ['e-focus']);
                    removeClass([parentCell], ['e-focused']);
                    parentCell.tabIndex = -1;
                }
            }
            if (this.parent.pagerModule.pagerObj.element.tabIndex === 0 && (e.keyCode === 38 || (e.shiftKey && e.keyCode === 9))) {
                e.preventDefault();
                this.getFocusedElement().focus();
                return;
            }
            else if (this.parent.pagerModule.pagerObj.element.tabIndex === 0 && e.keyCode === 9) {
                e.preventDefault();
                this.parent.pagerModule.pagerObj.setPagerFocus();
                return;
            }
            if (this.parent.pagerModule.pagerObj.checkFirstPagerFocus()) {
                const lastRow = this.getContent().matrix.rows;
                const lastColumn = this.getContent().matrix.columns;
                this.getContent().matrix.current = [lastRow, lastColumn];
            }
        }
        if (this.skipOn(e)) {
            return;
        }
        if (e.target && parentsUntil(e.target, 'e-gridcontent') && !this.parent.isFrozenGrid()) {
            const rows = [].slice.call(this.parent.getContentTable().rows);
            const lastCell = rows[rows.length - 1].lastElementChild;
            if (e.target === lastCell) {
                this.setActive(true);
                this.setLastContentCellActive();
            }
        }
        if (e.action === 'shiftTab' && e.target && (e.target === this.parent.element || parentsUntil(e.target, 'e-toolbar')
            || parentsUntil(e.target, 'e-groupdroparea'))) {
            if (e.target === this.parent.element) {
                if (this.parent.element.classList.contains('e-childgrid')) {
                    this.focusOutFromChildGrid(e);
                }
                return;
            }
            if (parentsUntil(e.target, 'e-groupdroparea')) {
                e.preventDefault();
                this.parent.element.focus();
                return;
            }
            if (parentsUntil(e.target, 'e-toolbar')) {
                e.preventDefault();
                if (this.parent.allowGrouping) {
                    const groupModule = this.parent.groupModule;
                    const focusableGroupedItems = groupModule.getFocusableGroupedItems();
                    if (focusableGroupedItems.length > 0) {
                        focusableGroupedItems[focusableGroupedItems.length - 1].focus();
                    }
                    else {
                        groupModule.element.focus();
                    }
                }
                else {
                    this.parent.element.focus();
                }
                return;
            }
        }
        let focusFirstHeaderCell = false;
        if (e.action === 'tab' && e.target && (e.target === this.parent.element || parentsUntil(e.target, 'e-toolbar')
            || parentsUntil(e.target, 'e-groupdroparea'))) {
            if (this.parent.allowGrouping && (e.target === this.parent.element
                || e.target.classList.contains('e-groupdroparea'))) {
                const groupModule = this.parent.groupModule;
                const focusableGroupedItems = groupModule.getFocusableGroupedItems();
                if (focusableGroupedItems.length > 0) {
                    e.preventDefault();
                    focusableGroupedItems[0].focus();
                    return;
                }
                if (!e.target.classList.contains('e-groupdroparea')) {
                    e.preventDefault();
                    groupModule.element.focus();
                    return;
                }
            }
            if ((this.parent.toolbar || this.parent.toolbarTemplate) && (e.target === this.parent.element
                || parentsUntil(e.target, 'e-groupdroparea')
                || e.target.classList.contains('e-toolbar'))) {
                const toolbarElement = this.parent.toolbarModule.toolbar.element;
                const focusableToolbarItems = toolbarElement
                    .querySelectorAll('.e-toolbar-item:not(.e-overlay):not(.e-hidden)');
                if (focusableToolbarItems.length > 0) {
                    e.preventDefault();
                    focusableToolbarItems[0].querySelector('.e-btn,.e-input').focus();
                    return;
                }
                if (!e.target.classList.contains('e-toolbar')) {
                    e.preventDefault();
                    toolbarElement.focus();
                    return;
                }
            }
            if (e.target === this.parent.element || parentsUntil(e.target, 'e-toolbar')
                || parentsUntil(e.target, 'e-groupdroparea')) {
                focusFirstHeaderCell = true;
            }
        }
        if (focusFirstHeaderCell) {
            if (this.parent.isFrozenGrid() && (this.parent.getFrozenMode() === 'Left'
                || this.parent.getFrozenMode() === literals.leftRight)) {
                this.setActive(false, true);
            }
            else {
                this.setActive(false);
            }
            this.active.matrix.current = [0, -1];
        }
        this.activeKey = e.action;
        const beforeArgs = { cancel: false, byKey: true, byClick: false, keyArgs: e };
        this.parent.notify(event.beforeCellFocused, beforeArgs);
        if (beforeArgs.cancel) {
            return;
        }
        const bValue = this.getContent().matrix.current;
        this.currentInfo.outline = true;
        const swapInfo = this.getContent().jump(e.action, bValue);
        this.swap = swapInfo;
        if (swapInfo.swap) {
            this.setActive(!swapInfo.toHeader, swapInfo.toFrozen, swapInfo.toFrozenRight);
            this.getContent().matrix.current = this.getContent().getNextCurrent(bValue, swapInfo, this.active, e.action);
            this.prevIndexes = {};
        }
        this.setActiveByKey(e.action, this.getContent());
        let returnVal = this.content.lastIdxCell ? false : this.getContent().onKeyPress(e);
        if (e.target && parentsUntil(e.target, 'e-gridheader')) {
            if (!this.parent.isFrozenGrid()) {
                if (e.action === 'tab' && bValue.toString() === this.active.matrix.current.toString()) {
                    const nextHeaderCellIndex = findCellIndex(this.active.matrix.matrix, this.active.matrix.current, true);
                    let lastHeaderCellIndex = [this.active.matrix.matrix.length - 1,
                        this.active.matrix.matrix[this.active.matrix.matrix.length - 1].length - 1];
                    if (this.active.matrix.matrix[lastHeaderCellIndex[0]][lastHeaderCellIndex[1]] === 0) {
                        lastHeaderCellIndex = findCellIndex(this.active.matrix.matrix, lastHeaderCellIndex, false);
                    }
                    if (this.active.matrix.current.toString() === lastHeaderCellIndex.toString()) {
                        returnVal = true;
                        this.setActive(true);
                        let firstContentCellIndex = [0, 0];
                        if (this.active.matrix.matrix[firstContentCellIndex[0]][firstContentCellIndex[1]] === 0) {
                            firstContentCellIndex = findCellIndex(this.active.matrix.matrix, [0, 0], true);
                        }
                        this.active.matrix.current = firstContentCellIndex;
                    }
                    else if (this.active.matrix.current.toString() !== nextHeaderCellIndex.toString()) {
                        this.active.matrix.current = nextHeaderCellIndex;
                    }
                }
                if (e.action === 'shiftTab' && bValue.toString() === this.active.matrix.current.toString()) {
                    const previousCellIndex = findCellIndex(this.active.matrix.matrix, this.active.matrix.current, false);
                    if (previousCellIndex.toString() === this.active.matrix.current.toString()) {
                        this.focusOutFromHeader(e);
                        return;
                    }
                    if (this.active.matrix.current.toString() !== previousCellIndex.toString() && !returnVal) {
                        returnVal = true;
                        this.active.matrix.current = previousCellIndex;
                    }
                }
            }
            else {
                if (e.action === 'shiftTab' && bValue.toString() === this.active.matrix.current.toString() && !swapInfo.swap) {
                    this.focusOutFromHeader(e);
                    return;
                }
            }
        }
        if (e.target && parentsUntil(e.target, 'e-gridcontent')) {
            if (!this.parent.isFrozenGrid()) {
                if (e.action === 'shiftTab' && bValue.toString() === this.active.matrix.current.toString()) {
                    let firstContentCellIndex = [0, 0];
                    if (this.active.matrix.matrix[firstContentCellIndex[0]][firstContentCellIndex[1]] === 0) {
                        firstContentCellIndex = findCellIndex(this.active.matrix.matrix, [0, 0], true);
                    }
                    if (!returnVal && firstContentCellIndex.toString() === this.active.matrix.current.toString()) {
                        returnVal = true;
                        this.setActive(false);
                        this.setLastContentCellActive();
                    }
                }
            }
        }
        if (returnVal === false) {
            this.clearIndicator();
            if (e.action === 'shiftTab' && bValue.toString() === [0, 0].toString()) {
                this.parent.element.tabIndex = -1;
            }
            if (this.parent.allowPaging && !this.parent.pagerModule.pagerObj.checkPagerHasFocus() && this.allowToPaging(e)
                && bValue.toString() !== [0, 0].toString()) {
                e.preventDefault();
                if (e.keyCode === 40) {
                    this.parent.pagerModule.pagerObj.setPagerContainerFocus();
                    return;
                }
                else if (e.keyCode === 9) {
                    this.parent.pagerModule.pagerObj.setPagerFocus();
                    return;
                }
            }
            if (this.parent.element.classList.contains('e-childgrid')) {
                this.focusOutFromChildGrid(e);
            }
            return;
        }
        e.preventDefault();
        this.focus(e);
    }
    setLastContentCellActive() {
        let lastContentCellIndex = [this.active.matrix.matrix.length - 1,
            this.active.matrix.matrix[this.active.matrix.matrix.length - 1].length - 1];
        if (this.active.matrix.matrix[lastContentCellIndex[0]][lastContentCellIndex[1]] === 0) {
            lastContentCellIndex = findCellIndex(this.active.matrix.matrix, lastContentCellIndex, false);
        }
        this.active.matrix.current = lastContentCellIndex;
    }
    focusOutFromChildGrid(e) {
        const parentTable = parentsUntil(this.parent.element, 'e-table');
        const parentGrid = parentsUntil(parentTable, 'e-grid').ej2_instances[0];
        const parentCell = parentsUntil(this.parent.element, 'e-detailcell');
        const uid = parentsUntil(this.parent.element, 'e-detailrow').getAttribute('data-uid');
        const parentRows = parentGrid.getRows();
        const parentRowIndex = parentRows.map((m) => m.getAttribute('data-uid')).indexOf(uid);
        if (e.action === 'tab' && parentRowIndex >= parentRows.length - 1) {
            return;
        }
        removeClass([this.parent.element], ['e-focus']);
        removeClass([parentCell], ['e-focused']);
        parentCell.tabIndex = -1;
        e.preventDefault();
        let nextFocusCell;
        parentGrid.focusModule.removeFocus();
        if (e.action === 'shiftTab') {
            const previousRow = parentRows[parentRowIndex - 1];
            const rowCells = previousRow.cells;
            for (let i = rowCells.length - 1; i >= 0; i--) {
                nextFocusCell = rowCells[i];
                if (!nextFocusCell.classList.contains('e-hide')) {
                    parentGrid.focusModule.active.matrix.current = [parentRowIndex - 1, i];
                    break;
                }
            }
        }
        else {
            nextFocusCell = parentRows[parentRowIndex + 1].cells[0];
            parentGrid.focusModule.active.matrix.current = [parentRowIndex + 1, 0];
        }
        parentGrid.focusModule.currentInfo.element = nextFocusCell;
        parentGrid.focusModule.currentInfo.elementToFocus = nextFocusCell;
        addClass([nextFocusCell], ['e-focused', 'e-focus']);
        nextFocusCell.tabIndex = 0;
        nextFocusCell.focus();
    }
    focusOutFromHeader(e) {
        e.preventDefault();
        this.removeFocus();
        if (this.parent.toolbar || this.parent.toolbarTemplate) {
            const toolbarElement = this.parent.toolbarModule.toolbar.element;
            const focusableToolbarItems = toolbarElement
                .querySelectorAll('.e-toolbar-item:not(.e-overlay):not(.e-hidden)');
            if (focusableToolbarItems.length > 0) {
                focusableToolbarItems[focusableToolbarItems.length - 1].querySelector('.e-btn,.e-input').focus();
            }
            else {
                toolbarElement.focus();
            }
            return;
        }
        if (this.parent.allowGrouping) {
            const groupModule = this.parent.groupModule;
            const focusableGroupedItems = groupModule.getFocusableGroupedItems();
            if (focusableGroupedItems.length > 0) {
                focusableGroupedItems[focusableGroupedItems.length - 1].focus();
            }
            else {
                groupModule.element.focus();
            }
            return;
        }
        if (this.parent.element.classList.contains('e-childgrid')) {
            parentsUntil(this.parent.element, 'e-detailcell').focus();
        }
        else {
            this.parent.element.focus();
        }
    }
    allowToPaging(e) {
        if (this.parent.editSettings.mode === 'Batch' && this.parent.editSettings.allowAdding && e.keyCode !== 40) {
            return false;
        }
        return true;
    }
    skipOn(e) {
        const target = e.target;
        if (!target) {
            return false;
        }
        if (this.currentInfo.skipAction) {
            this.clearIndicator();
            return true;
        }
        if (['pageUp', 'pageDown', 'altDownArrow'].indexOf(e.action) > -1) {
            this.clearIndicator();
            return true;
        }
        if (this.parent.allowGrouping) {
            const focusableGroupedItems = this.parent.groupModule.getFocusableGroupedItems();
            if (parentsUntil(e.target, 'e-groupheadercell')
                && !((e.target === focusableGroupedItems[0] && e.action === 'shiftTab')
                    || (e.target === focusableGroupedItems[focusableGroupedItems.length - 1] && e.action === 'tab'))) {
                return true;
            }
        }
        if (this.parent.toolbar || this.parent.toolbarTemplate) {
            const toolbarElement = this.parent.toolbarModule.toolbar.element;
            const focusableToolbarItems = toolbarElement
                .querySelectorAll('.e-toolbar-item:not(.e-overlay):not(.e-hidden)');
            if (parentsUntil(e.target, 'e-toolbar-item')
                && !(focusableToolbarItems.length > 0 && ((parentsUntil(e.target, 'e-toolbar-item') === focusableToolbarItems[0] && e.action === 'shiftTab')
                    || (parentsUntil(e.target, 'e-toolbar-item') === focusableToolbarItems[focusableToolbarItems.length - 1] && e.action === 'tab')))) {
                return true;
            }
        }
        const th = closest(target, 'th') && !closest(target, 'th').tabIndex;
        if (e.target.classList.contains('e-filterbaroperator') && (e.keyCode === 13 || e.keyCode === 27)) {
            const inputTarget = closest(e.target, '.e-filterbarcell');
            inputTarget.querySelector('input').focus();
        }
        if (th && closest(document.activeElement, '.e-filterbarcell') !== null) {
            this.removeFocus();
        }
        let filterCell = closest(document.activeElement, '.e-filterbarcell') !== null;
        if (this.parent.enableHeaderFocus && filterCell) {
            const matrix = this.active.matrix;
            const current = matrix.current;
            filterCell = matrix.matrix[current[0]].lastIndexOf(1) !== current[1];
        }
        return (e.action === 'delete'
            || (this.parent.editSettings.mode !== 'Batch' && (this.parent.isEdit || ['insert', 'f2'].indexOf(e.action) > -1))
            || ((filterCell && this.parent.enableHeaderFocus) ||
                closest(document.activeElement, '#' + this.parent.element.id + '_searchbar') !== null
                    && ['enter', 'leftArrow', 'rightArrow',
                        'shiftLeft', 'shiftRight', 'ctrlPlusA'].indexOf(e.action) > -1)
            || (closest(target, '.' + literals.gridContent) === null && closest(target, '.' + literals.gridHeader) === null
                && !(e.target === this.parent.element || parentsUntil(e.target, 'e-toolbar')
                    || parentsUntil(e.target, 'e-groupdroparea')))
            || (e.action === 'space' && (!target.classList.contains(literals.gridChkBox) && closest(target, '.' + literals.gridChkBox) === null
                && closest(target, '.e-headerchkcelldiv') === null))) || closest(target, '.e-filter-popup') !== null;
    }
    focusVirtualElement(e) {
        if (this.parent.enableVirtualization || this.parent.enableInfiniteScrolling) {
            const data = { virtualData: {}, isAdd: false, isCancel: false };
            this.parent.notify(event.getVirtualData, data);
            const isKeyFocus = this.actions.some((value) => value === this.activeKey);
            const isSelected = this.parent.contentModule ?
                this.parent.contentModule.selectedRowIndex > -1 : false;
            if (data.isAdd || Object.keys(data.virtualData).length || isKeyFocus || data.isCancel || isSelected) {
                this.parent.notify(event.resetVirtualFocus, { isCancel: false });
                data.isCancel = false;
                this.parent.contentModule.selectedRowIndex = -1;
                if (isKeyFocus) {
                    this.activeKey = this.empty;
                    this.parent.notify('virtaul-key-handler', e);
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.currentInfo.elementToFocus.focus({ preventScroll: true });
            }
            else {
                if (this.isVirtualScroll || this.isInfiniteScroll) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    this.currentInfo.elementToFocus.focus({ preventScroll: true });
                }
                else {
                    this.currentInfo.elementToFocus.focus();
                }
            }
        }
        this.isVirtualScroll = this.isInfiniteScroll = false;
    }
    getFocusedElement() {
        return this.currentInfo.elementToFocus;
    }
    getContent() {
        return this.active || this.content;
    }
    setActive(content, isFrozen, isFrozenRight) {
        this.active = content ? isFrozen ? this.fContent : isFrozenRight ? this.frContent : this.content :
            isFrozen ? this.fHeader : isFrozenRight ? this.frHeader : this.header;
    }
    setFocusedElement(element, e) {
        this.currentInfo.elementToFocus = element;
        setTimeout(() => {
            if (!isNullOrUndefined(this.currentInfo.elementToFocus)) {
                if (this.parent.enableVirtualization || this.parent.enableInfiniteScrolling) {
                    this.focusVirtualElement(e);
                }
                else {
                    this.currentInfo.elementToFocus.focus();
                }
            }
        }, 0);
    }
    focus(e) {
        this.parent.notify(event.virtaulCellFocus, e);
        this.removeFocus();
        this.addFocus(this.getContent().getFocusInfo(), e);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeFocus(e) {
        if (!this.currentInfo.element) {
            return;
        }
        removeClass([this.currentInfo.element, this.currentInfo.elementToFocus], ['e-focused', 'e-focus']);
        this.currentInfo.element.tabIndex = -1;
    }
    /**
     * @returns {void}
     * @hidden */
    addOutline() {
        const info = this.getContent().getFocusInfo();
        if (info.element) {
            addClass([info.element], ['e-focused']);
            addClass([info.elementToFocus], ['e-focus']);
        }
    }
    /**
     * @returns {void}
     * @hidden */
    focusHeader() {
        this.setActive(false, this.parent.isFrozenGrid());
        this.resetFocus();
    }
    /**
     * @returns {void}
     * @hidden */
    focusContent() {
        this.setActive(true, this.parent.isFrozenGrid());
        this.resetFocus();
    }
    resetFocus() {
        const current = this.getContent().matrix.get(0, -1, [0, 1], null, this.getContent().validator());
        this.getContent().matrix.select(current[0], current[1]);
        this.focus();
    }
    addFocus(info, e) {
        this.currentInfo = info;
        this.currentInfo.outline = info.outline && !isNullOrUndefined(e);
        if (this.isInfiniteScroll) {
            this.currentInfo.outline = true;
        }
        if (!info.element) {
            return;
        }
        const isFocused = info.elementToFocus.classList.contains('e-focus');
        if (isFocused) {
            return;
        }
        if (this.currentInfo.outline) {
            addClass([info.element], ['e-focused']);
        }
        addClass([info.elementToFocus], ['e-focus']);
        info.element.tabIndex = 0;
        if (!isFocused) {
            this.setFocusedElement(info.elementToFocus, e);
        }
        this.parent.notify(event.cellFocused, {
            element: info.elementToFocus,
            parent: info.element,
            indexes: this.getContent().matrix.current,
            byKey: !isNullOrUndefined(e),
            byClick: isNullOrUndefined(e),
            keyArgs: e,
            isJump: this.swap.swap,
            container: this.getContent().getInfo(e),
            outline: !isNullOrUndefined(e),
            swapInfo: this.swap
        });
        const [rowIndex, cellIndex] = this.getContent().matrix.current;
        this.prevIndexes = { rowIndex, cellIndex };
        this.focusedColumnUid = this.parent.getColumnByIndex(cellIndex).uid;
        this.focusByClick = false;
    }
    refreshMatrix(content) {
        return (e) => {
            if (content && (e.args && e.args.isFrozen) && !this.fContent) {
                this.fContent = new FixedContentFocus(this.parent);
            }
            else if (content && !this.frContent && (e.args && e.args.renderFrozenRightContent)) {
                this.frContent = new FixedRightContentFocus(this.parent);
            }
            else if (content && !this.content) {
                this.content = new ContentFocus(this.parent);
            }
            if (!content && (e.args && e.args.isFrozen) && !this.fHeader) {
                this.fHeader = new FixedHeaderFocus(this.parent);
            }
            else if (!content && (e.args && e.args.renderFrozenRightContent) && !this.frHeader) {
                this.frHeader = new FixedRightHeaderFocus(this.parent);
            }
            else if (!content && !this.header) {
                this.header = new HeaderFocus(this.parent);
            }
            const cFocus = content ? (e.args && e.args.isFrozen) ? this.fContent : (e.args && e.args.renderFrozenRightContent)
                ? this.frContent : this.content : (e.args && e.args.isFrozen) ? this.fHeader : (e.args && e.args.renderFrozenRightContent)
                ? this.frHeader : this.header;
            let rows = content ? e.rows.slice(this.parent.frozenRows) : e.rows;
            const updateRow = content ? e.rows.slice(0, this.parent.frozenRows) : e.rows;
            if (this.parent.isCollapseStateEnabled() && content) {
                rows = rows.filter((x) => x.visible !== false);
            }
            const isRowTemplate = !isNullOrUndefined(this.parent.rowTemplate);
            const matrix = cFocus.matrix.generate(updateRow, cFocus.selector, isRowTemplate);
            if (e.name === 'batchAdd' && this.parent.isFrozenGrid()) {
                const mRows = this.parent.getMovableRowsObject();
                const newMovableRows = mRows.map((row) => { return row.clone(); });
                const newFrozenRows = rows.map((row) => { return row.clone(); });
                this.fContent.matrix.generate(newFrozenRows, this.fContent.selector, isRowTemplate);
                this.content.matrix.generate(newMovableRows, this.content.selector, isRowTemplate);
                if (this.parent.getFrozenMode() === literals.leftRight) {
                    const frRows = this.parent.getFrozenRightRowsObject();
                    const newfrRows = frRows.map((row) => { return row.clone(); });
                    this.frContent.matrix.generate(newfrRows, this.frContent.selector, isRowTemplate);
                }
            }
            else {
                cFocus.matrix.generate(rows, cFocus.selector, isRowTemplate);
            }
            if (!(this.parent.isFrozenGrid() && e.args && e.args.requestType === 'sorting')) {
                cFocus.generateRows(updateRow, {
                    matrix, handlerInstance: (e.args && e.args.isFrozen) ? this.fHeader
                        : (e.args && e.args.renderFrozenRightContent) ? this.frHeader : this.header
                });
            }
            if (!Browser.isDevice && e && e.args) {
                if (!this.focusByClick && e.args.requestType === 'paging' && !this.parent.pagerModule.pagerObj.checkPagerHasFocus()) {
                    this.skipFocus = false;
                    this.parent.element.focus();
                }
                if (e.args.requestType === 'grouping') {
                    this.skipFocus = true;
                }
            }
            if (e && e.args && e.args.requestType === 'virtualscroll') {
                if (this.currentInfo.uid) {
                    let index;
                    const bool = e.rows.some((row, i) => {
                        index = i;
                        return row.uid === this.currentInfo.uid;
                    });
                    if (bool) {
                        this.content.matrix.current[0] = index;
                        this.content.matrix.current[1] = this.parent.getColumnIndexByUid(this.focusedColumnUid) || 0;
                        const focusElement = this.getContent().getFocusInfo().elementToFocus;
                        if (focusElement) {
                            const cellPosition = focusElement.getBoundingClientRect();
                            const gridPosition = this.parent.element.getBoundingClientRect();
                            if (cellPosition.top >= 0 && cellPosition.left >= 0 &&
                                cellPosition.right <= Math.min(gridPosition.right, window.innerWidth ||
                                    document.documentElement.clientWidth) &&
                                cellPosition.bottom <= Math.min(gridPosition.bottom, window.innerHeight ||
                                    document.documentElement.clientHeight)) {
                                this.isVirtualScroll = true;
                                this.focus();
                            }
                        }
                    }
                }
                else if (e.args.focusElement && e.args.focusElement.classList.contains('e-filtertext')) {
                    const focusElement = this.parent.element.querySelector('#' + e.args.focusElement.id);
                    if (focusElement) {
                        focusElement.focus();
                    }
                }
            }
        };
    }
    addEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        EventHandler.add(this.parent.element, 'mousedown', this.focusCheck, this);
        EventHandler.add(this.parent.element, 'focus', this.onFocus, this);
        this.parent.element.addEventListener('focus', this.passiveHandler = (e) => this.passiveFocus(e), true);
        EventHandler.add(this.parent.element, 'focusout', this.onBlur, this);
        this.evtHandlers = [{ event: event.keyPressed, handler: this.onKeyPress },
            { event: event.click, handler: this.onClick },
            { event: event.contentReady, handler: this.refMatrix },
            { event: event.partialRefresh, handler: this.refMatrix },
            { event: event.refreshExpandandCollapse, handler: this.refMatrix },
            { event: event.headerRefreshed, handler: this.refreshMatrix() },
            { event: event.closeEdit, handler: this.restoreFocus },
            { event: event.restoreFocus, handler: this.restoreFocus },
            { event: 'start-edit', handler: this.clearIndicator },
            { event: 'start-add', handler: this.clearIndicator },
            { event: 'sorting-complete', handler: this.restoreFocus },
            { event: 'filtering-complete', handler: this.filterfocus },
            { event: 'grouping-complete', handler: this.restoreFocusWithAction },
            { event: 'ungrouping-complete', handler: this.restoreFocusWithAction },
            { event: event.batchAdd, handler: this.refMatrix },
            { event: event.batchCancel, handler: this.refMatrix },
            { event: event.batchDelete, handler: this.refMatrix },
            { event: event.detailDataBound, handler: this.refMatrix },
            { event: event.onEmpty, handler: this.refMatrix },
            { event: event.cellFocused, handler: this.internalCellFocus }];
        addRemoveEventListener(this.parent, this.evtHandlers, true, this);
    }
    filterfocus() {
        if (this.parent.filterSettings.type !== 'FilterBar') {
            this.restoreFocus();
        }
    }
    removeEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        EventHandler.remove(this.parent.element, 'mousedown', this.focusCheck);
        EventHandler.remove(this.parent.element, 'focus', this.onFocus);
        EventHandler.remove(this.parent.element, 'focusout', this.onBlur);
        this.parent.element.removeEventListener('focus', this.passiveHandler, true);
        addRemoveEventListener(this.parent, this.evtHandlers, false);
    }
    destroy() {
        this.removeEventListener();
    }
    restoreFocus() {
        const groupModule = this.parent.groupModule;
        if (this.parent.allowGrouping && groupModule && groupModule.groupSortFocus) {
            groupModule.groupSortFocus = false;
            return;
        }
        this.addFocus(this.getContent().getFocusInfo());
    }
    restoreFocusWithAction(e) {
        if (!this.parent.enableInfiniteScrolling) {
            const matrix = this.getContent().matrix;
            const current = matrix.current;
            switch (e.requestType) {
                case 'grouping':
                case 'ungrouping':
                    current[1] = current.length &&
                        !this.parent.groupSettings.showGroupedColumn && !isNullOrUndefined(matrix.matrix[current[0]]) ?
                        matrix.matrix[current[0]].indexOf(1) : e.requestType === 'grouping' ? current[1] + 1 : current[1] - 1;
                    break;
            }
            this.getContent().matrix.current = current;
            const groupModule = this.parent.groupModule;
            if (this.parent.allowGrouping && groupModule && groupModule.groupCancelFocus) {
                const focusableGroupedItems = groupModule.getFocusableGroupedItems();
                if (focusableGroupedItems.length) {
                    if (focusableGroupedItems[0].parentElement.getAttribute('ej-mappingname') === e.columnName) {
                        focusableGroupedItems[3].focus();
                    }
                    else {
                        focusableGroupedItems[0].focus();
                    }
                }
                else {
                    groupModule.element.focus();
                }
                groupModule.groupCancelFocus = false;
                return;
            }
            this.addFocus(this.getContent().getFocusInfo());
        }
    }
    clearOutline() {
        this.getContent().matrix.current = this.getContent().matrix.get(0, -1, [0, 1], 'downArrow', this.getContent().validator());
        this.clearIndicator();
    }
    clearIndicator() {
        if (!this.currentInfo.element || !this.currentInfo.elementToFocus) {
            return;
        }
        removeClass([this.currentInfo.element, this.currentInfo.elementToFocus], ['e-focus', 'e-focused']);
    }
    getPrevIndexes() {
        const forget = this.forget;
        this.forget = false;
        return forget || !Object.keys(this.prevIndexes).length ? { rowIndex: null, cellIndex: null } : this.prevIndexes;
    }
    forgetPrevious() {
        this.forget = true;
    }
    setActiveByKey(action, active) {
        if (!this.parent.isFrozenGrid() && this.parent.frozenRows === 0) {
            return;
        }
        // eslint-disable-next-line prefer-const
        let info;
        const actions = {
            'home': () => ({ toHeader: !info.isContent, toFrozen: true }),
            'end': () => ({ toHeader: !info.isContent, toFrozen: false }),
            'ctrlHome': () => ({ toHeader: true, toFrozen: this.parent.isFrozenGrid() }),
            'ctrlEnd': () => ({ toHeader: false, toFrozen: false })
        };
        if (!(action in actions)) {
            return;
        }
        info = active.getInfo();
        const swap = actions[action]();
        this.setActive(!swap.toHeader, swap.toFrozen);
        this.getContent().matrix.current = active.matrix.current;
    }
    internalCellFocus(e) {
        if (!(e.byKey && e.container.isContent && e.keyArgs.action === 'enter'
            && (e.parent.classList.contains('e-detailcell') ||
                e.parent.classList.contains('e-unboundcell') || e.parent.classList.contains('e-templatecell')))) {
            return;
        }
        this.clearIndicator();
        const focusEle = this.getContent().getFocusable(this.getFocusedElement());
        this.setFocusedElement(focusEle);
        this.currentInfo.skipAction = true;
    }
}
/**
 * Create matrix from row collection which act as mental model for cell navigation
 *
 * @hidden
 */
export class Matrix {
    constructor() {
        this.matrix = [];
        this.current = [];
    }
    set(rowIndex, columnIndex, allow) {
        rowIndex = Math.max(0, Math.min(rowIndex, this.rows));
        columnIndex = Math.max(0, Math.min(columnIndex, this.columns));
        this.matrix[rowIndex] = this.matrix[rowIndex] || [];
        this.matrix[rowIndex][columnIndex] = allow ? 1 : 0;
    }
    get(rowIndex, columnIndex, navigator, action, validator) {
        const tmp = columnIndex;
        if (rowIndex + navigator[0] < 0) {
            return [rowIndex, columnIndex];
        }
        rowIndex = Math.max(0, Math.min(rowIndex + navigator[0], this.rows));
        let emptyTable = true;
        if (isNullOrUndefined(this.matrix[rowIndex])) {
            return null;
        }
        columnIndex = Math.max(0, Math.min(columnIndex + navigator[1], this.matrix[rowIndex].length - 1));
        if (tmp + navigator[1] > this.matrix[rowIndex].length - 1 && validator(rowIndex, columnIndex, action)) {
            return [rowIndex, tmp];
        }
        const first = this.first(this.matrix[rowIndex], columnIndex, navigator, true, action);
        columnIndex = first === null ? tmp : first;
        const val = getValue(`${rowIndex}.${columnIndex}`, this.matrix);
        if (rowIndex === this.rows && (action === 'downArrow' || action === 'enter')) {
            navigator[0] = -1;
        }
        if (first === null) {
            for (let i = 0; i < this.rows; i++) {
                if (this.matrix[i].some((v) => { return v === 1; })) {
                    emptyTable = false;
                    break;
                }
            }
            if (emptyTable) {
                rowIndex = this.current[0];
                return [rowIndex, columnIndex];
            }
        }
        return this.inValid(val) || !validator(rowIndex, columnIndex, action) ?
            this.get(rowIndex, tmp, navigator, action, validator) : [rowIndex, columnIndex];
    }
    first(vector, index, navigator, moveTo, action) {
        if (((index < 0 || index === vector.length) && this.inValid(vector[index])
            && (action !== 'upArrow' && action !== 'downArrow')) || !vector.some((v) => v === 1)) {
            return null;
        }
        return !this.inValid(vector[index]) ? index :
            this.first(vector, (['upArrow', 'downArrow', 'shiftUp', 'shiftDown'].indexOf(action) !== -1) ? moveTo ? 0 : ++index : index + navigator[1], navigator, false, action);
    }
    select(rowIndex, columnIndex) {
        rowIndex = Math.max(0, Math.min(rowIndex, this.rows));
        columnIndex = Math.max(0, Math.min(columnIndex, this.matrix[rowIndex].length - 1));
        this.current = [rowIndex, columnIndex];
    }
    generate(rows, selector, isRowTemplate) {
        this.rows = rows.length - 1;
        this.matrix = [];
        for (let i = 0; i < rows.length; i++) {
            const cells = rows[i].cells.filter((c) => c.isSpanned !== true);
            this.columns = Math.max(cells.length - 1, this.columns | 0);
            let incrementNumber = 0;
            for (let j = 0; j < cells.length; j++) {
                if (cells[j].column && cells[j].column.columns) {
                    incrementNumber = this.columnsCount(cells[j].column.columns, incrementNumber);
                }
                else {
                    incrementNumber++;
                }
                this.set(i, j, rows[i].visible === false ? false : selector(rows[i], cells[j], isRowTemplate));
            }
            this.columns = Math.max(incrementNumber - 1, this.columns | 0);
        }
        return this.matrix;
    }
    columnsCount(rowColumns, currentColumnCount) {
        const columns = rowColumns;
        let incrementNumber = currentColumnCount;
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].columns) {
                incrementNumber = this.columnsCount(columns[i].columns, incrementNumber);
            }
            else {
                incrementNumber++;
            }
        }
        return incrementNumber;
    }
    inValid(value) {
        return value === 0 || value === undefined;
    }
}
/**
 * @hidden
 */
export class ContentFocus {
    constructor(parent) {
        this.matrix = new Matrix();
        this.lastIdxCell = false;
        this.parent = parent;
        this.keyActions = {
            'rightArrow': [0, 1],
            'tab': [0, 1],
            'leftArrow': [0, -1],
            'shiftTab': [0, -1],
            'upArrow': [-1, 0],
            'downArrow': [1, 0],
            'shiftUp': [-1, 0],
            'shiftDown': [1, 0],
            'shiftRight': [0, 1],
            'shiftLeft': [0, -1],
            'enter': [1, 0],
            'shiftEnter': [-1, 0]
        };
        this.indexesByKey = (action) => {
            const opt = {
                'home': [this.matrix.current[0], -1, 0, 1],
                'end': [this.matrix.current[0], this.matrix.columns + 1, 0, -1],
                'ctrlHome': [0, -1, 0, 1],
                'ctrlEnd': [this.matrix.rows, this.matrix.columns + 1, 0, -1]
            };
            return opt[action] || null;
        };
    }
    getTable() {
        return (this.parent.isFrozenGrid() ?
            this.parent.getContent().querySelector('.e-movablecontent .e-table') :
            this.parent.getContentTable());
    }
    onKeyPress(e) {
        const navigator = this.keyActions[e.action];
        let current = this.getCurrentFromAction(e.action, navigator, e.action in this.keyActions, e);
        if (!current) {
            return;
        }
        if (((['tab', 'shiftTab'].indexOf(e.action) > -1 && this.matrix.current || []).toString() === current.toString())
            || (this.parent.allowPaging && !this.parent.pagerModule.pagerObj.checkPagerHasFocus()
                && this.matrix.current[0] === this.matrix.rows && ((this.parent.editSettings.mode === 'Batch'
                && this.parent.editSettings.allowAdding && e.keyCode === 40) || (e.keyCode === 40)))) {
            if (current.toString() === [this.matrix.rows, this.matrix.columns].toString() ||
                current.toString() === [0, 0].toString() || (this.matrix.current[0] === this.matrix.rows &&
                this.matrix.current.toString() === current.toString())) {
                return false;
            }
            else {
                current = this.editNextRow(current[0], current[1], e.action);
            }
        }
        this.matrix.select(current[0], current[1]);
    }
    editNextRow(rowIndex, cellIndex, action) {
        const gObj = this.parent;
        const editNextRow = gObj.editSettings.allowNextRowEdit && (gObj.isEdit || gObj.isLastCellPrimaryKey);
        const visibleIndex = gObj.getColumnIndexByField(gObj.getVisibleColumns()[0].field);
        const cell = this.getTable().rows[rowIndex].cells[cellIndex];
        if (action === 'tab' && editNextRow) {
            rowIndex++;
            const index = (this.getTable().rows[rowIndex].getElementsByClassName('e-indentcell').length +
                this.getTable().rows[rowIndex].getElementsByClassName('e-detailrowcollapse').length);
            cellIndex = visibleIndex + index;
        }
        if (action === 'shiftTab' && editNextRow) {
            rowIndex--;
            cellIndex = gObj.getColumnIndexByField(gObj.getVisibleColumns()[gObj.getVisibleColumns().length - 1].field);
        }
        return !cell.classList.contains(literals.rowCell) && !cell.classList.contains('e-headercell') &&
            !cell.classList.contains('e-groupcaption') && !cell.classList.contains('e-filterbarcell') ?
            this.editNextRow(rowIndex, cellIndex, action) : [rowIndex, cellIndex];
    }
    getCurrentFromAction(action, navigator = [0, 0], isPresent, e) {
        if (!isPresent && !this.indexesByKey(action) || (this.matrix.current.length === 0)) {
            return null;
        }
        if (!this.shouldFocusChange(e)) {
            return this.matrix.current;
        }
        // eslint-disable-next-line
        let [rowIndex, cellIndex, rN, cN] = this.indexesByKey(action) || [...this.matrix.current, ...navigator];
        if (action === 'ctrlEnd') {
            let lastContentCellIndex = [this.matrix.matrix.length - 1,
                this.matrix.matrix[this.matrix.matrix.length - 1].length - 1];
            if (this.matrix.matrix[lastContentCellIndex[0]][lastContentCellIndex[1]] === 0) {
                lastContentCellIndex = findCellIndex(this.matrix.matrix, lastContentCellIndex, false);
            }
            rowIndex = lastContentCellIndex[0];
            cellIndex = lastContentCellIndex[1] + 1;
        }
        const current = this.matrix.get(rowIndex, cellIndex, [rN, cN], action, this.validator());
        return current;
    }
    onClick(e, force) {
        let target = e.target;
        this.target = target;
        target = (target.classList.contains(literals.rowCell) ? target : closest(target, 'td'));
        target = target ? target : closest(e.target, 'td.e-detailrowcollapse')
            || closest(e.target, 'td.e-detailrowexpand');
        target = closest(e.target, 'td.e-detailcell') ?
            isNullOrUndefined(closest(closest(e.target, '.e-grid'), 'td.e-detailcell')) ? null : target : target;
        target = target && closest(target, 'table').classList.contains(literals.table) ? target : null;
        if (!target) {
            return false;
        }
        const [rowIndex, cellIndex] = [target.parentElement.rowIndex, target.cellIndex];
        const [oRowIndex, oCellIndex] = this.matrix.current;
        const val = getValue(`${rowIndex}.${cellIndex}`, this.matrix.matrix);
        if (this.matrix.inValid(val) || (!force && oRowIndex === rowIndex && oCellIndex === cellIndex) ||
            (!parentsUntil(e.target, literals.rowCell) && !parentsUntil(e.target, 'e-groupcaption'))) {
            return false;
        }
        this.matrix.select(rowIndex, cellIndex);
    }
    getFocusInfo() {
        const info = {};
        const [rowIndex = 0, cellIndex = 0] = this.matrix.current;
        this.matrix.current = [rowIndex, cellIndex];
        info.element = !isNullOrUndefined(this.getTable().rows[rowIndex]) ? this.getTable().rows[rowIndex].cells[cellIndex] : null;
        if (!info.element) {
            return info;
        }
        info.elementToFocus = !info.element.classList.contains('e-unboundcell') && !info.element.classList.contains('e-detailcell')
            ? this.getFocusable(info.element) : info.element;
        info.elementToFocus = info.element.classList.contains('e-detailcell') && info.element.querySelector('.e-childgrid')
            ? info.element.querySelector('.e-childgrid') : info.elementToFocus;
        info.outline = true;
        info.uid = info.element.parentElement.getAttribute('data-uid');
        return info;
    }
    getFocusable(element) {
        let query = 'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])';
        const isTemplate = !isNullOrUndefined(closest(element, '.e-templatecell'));
        if (this.parent.isEdit) {
            query = 'input:not([type="hidden"]), select:not([aria-hidden="true"]), textarea';
        }
        const child = [].slice.call(element.querySelectorAll(query));
        /* Select the first focusable child element
         * if no child found then select the cell itself.
         * if Grid is in editable state, check for editable control inside child.
         */
        return child.length ? isTemplate && child.length > 1 ? this.target : child[0] : element;
    }
    selector(row, cell, isRowTemplate) {
        const types = [CellType.Expand, CellType.GroupCaption, CellType.CaptionSummary, CellType.GroupSummary];
        return ((row.isDataRow && cell.visible && (cell.isDataCell || cell.isTemplate))
            || (row.isDataRow && cell.cellType === CellType.DetailExpand && isNullOrUndefined(cell.visible))
            || (!row.isDataRow && types.indexOf(cell.cellType) > -1)
            || (cell.column && cell.visible && cell.column.type === 'checkbox')
            || (cell.cellType === CellType.CommandColumn)
            || (row.isDataRow && isRowTemplate))
            && !(row.edit === 'delete' && row.isDirty);
    }
    nextRowFocusValidate(index) {
        const lastIndex = index;
        for (let i = index, len = this.matrix.rows; i <= len; i++) {
            if (this.matrix.matrix[index].indexOf(1) === -1) {
                index = index + 1;
            }
            else {
                return index;
            }
        }
        this.lastIdxCell = true;
        return lastIndex;
    }
    previousRowFocusValidate(index) {
        const firstIndex = index;
        for (let i = index, len = 0; i >= len; i--) {
            if (this.matrix.matrix[index].indexOf(1) === -1) {
                index = index - 1;
                if (index < 0) {
                    this.lastIdxCell = true;
                    return firstIndex;
                }
            }
            else {
                return index;
            }
        }
        return firstIndex;
    }
    jump(action, current) {
        let frozenSwap = (this.parent.getFrozenLeftCount() &&
            ((action === 'leftArrow' || action === 'shiftTab') && current[1] === 0))
            || (current[0] < this.matrix.matrix.length - 1 && action === 'tab' && this.parent.getFrozenMode() === 'Left'
                && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1));
        const right = ((action === 'rightArrow' || action === 'tab') && current[1] === this.matrix.columns)
            || (action === 'shiftTab' && this.parent.getFrozenMode() === 'Right'
                && current[1] === this.matrix.matrix[current[0]].indexOf(1));
        const frSwap = this.parent.getFrozenMode() === literals.leftRight && right;
        if (this.parent.getFrozenMode() === 'Right') {
            frozenSwap = right;
        }
        this.lastIdxCell = false;
        const enterFrozen = this.parent.frozenRows !== 0 && action === 'shiftEnter';
        const headerSwap = ((action === 'upArrow' || enterFrozen) && current[0] === 0)
            || (action === 'shiftTab' && this.parent.getFrozenMode() === 'Right' && current[0] === 0
                && current[1] === this.matrix.matrix[current[0]].indexOf(1));
        if (!this.parent.isFrozenGrid() && action === 'tab' &&
            current[1] === this.matrix.matrix[current[0]].lastIndexOf(1) && this.matrix.matrix.length - 1 !== current[0]) {
            this.matrix.current[0] = this.nextRowFocusValidate(this.matrix.current[0] + 1);
            this.matrix.current[1] = -1;
            frozenSwap = this.parent.isFrozenGrid();
        }
        if (!this.parent.isFrozenGrid() && action === 'shiftTab' &&
            current[0] !== 0 && this.matrix.matrix[current[0]].indexOf(1) === current[1]) {
            this.matrix.current[0] = this.previousRowFocusValidate(this.matrix.current[0] - 1);
            this.matrix.current[1] = this.matrix.matrix[current[0]].length;
        }
        let isHeaderFocus = false;
        const row = document.activeElement.parentElement;
        if ((this.parent.enableVirtualization || this.parent.infiniteScrollSettings.enableCache)
            && row.classList.contains(literals.row)) {
            const rowIndex = parseInt(row.getAttribute(literals.dataRowIndex), 10);
            isHeaderFocus = rowIndex > 0;
        }
        if (action === 'shiftTab' && this.parent.getFrozenMode() === 'Right' && current[0] === 0
            && current[1] === this.matrix.matrix[current[0]].indexOf(1)) {
            this.matrix.current[0] = -1;
        }
        const info = {
            swap: !isHeaderFocus ? ((action === 'upArrow' || enterFrozen) && current[0] === 0) || frozenSwap || frSwap : false,
            toHeader: headerSwap,
            toFrozen: frozenSwap,
            toFrozenRight: frSwap
        };
        return info;
    }
    getNextCurrent(previous = [], swap, active, action) {
        const current = [];
        if (this.parent.getFrozenMode() === 'Right' || this.parent.getFrozenMode() === literals.leftRight) {
            if (action === 'leftArrow' || action === 'shiftTab') {
                current[0] = previous[0];
                current[1] = this.matrix.matrix[current[0]].length;
            }
            if (this.parent.getFrozenMode() === literals.leftRight && (action === 'rightArrow' || action === 'tab')) {
                current[0] = previous[0];
                current[1] = -1;
            }
            if (this.parent.getFrozenMode() === 'Right' && action === 'tab') {
                current[0] = previous[0] + 1;
                current[1] = -1;
            }
        }
        else if (action === 'rightArrow' || action === 'tab') {
            current[0] = previous[0];
            current[1] = -1;
        }
        if (action === 'downArrow' || action === 'enter') {
            current[0] = -1;
            current[1] = previous[1];
        }
        if (action === 'shiftTab' && this.parent.getFrozenMode() === 'Left') {
            current[0] = previous[0] - 1;
            current[1] = this.matrix.matrix[current[0]].length;
        }
        return current;
    }
    generateRows(rows, optionals) {
        const { matrix, handlerInstance } = optionals;
        const len = handlerInstance.matrix.matrix.length;
        const defaultLen = this.parent.allowFiltering && this.parent.filterSettings.type === 'FilterBar' ? len + 1 : len;
        handlerInstance.matrix.matrix = handlerInstance.matrix.matrix.slice(0, defaultLen); //Header matrix update.
        handlerInstance.matrix.rows = defaultLen;
        handlerInstance.matrix.matrix.push(...matrix);
        handlerInstance.matrix.rows += matrix.length;
    }
    getInfo(e) {
        const info = this.getFocusInfo();
        const [rIndex, cIndex] = this.matrix.current;
        const isData = info.element.classList.contains(literals.rowCell);
        const isSelectable = isData || (e && e.action !== 'enter' && (info.element.classList.contains('e-detailrowcollapse')
            || info.element.classList.contains('e-detailrowexpand')));
        // eslint-disable-next-line
        let [rowIndex, cellIndex] = [Math.min(parseInt(info.element.parentElement.getAttribute(literals.dataRowIndex), 10), rIndex),
            Math.min(parseInt(info.element.getAttribute(literals.dataColIndex), 10), cIndex)];
        if (this.parent.allowGrouping && this.parent.groupSettings.enableLazyLoading && isData) {
            rowIndex = this.parent.getDataRows().indexOf(info.element.parentElement);
        }
        return { isContent: true, isDataCell: isData, indexes: [rowIndex, cellIndex], isSelectable: isSelectable };
    }
    validator() {
        const table = this.getTable();
        return (rowIndex, cellIndex, action) => {
            if (!isNullOrUndefined(table.rows[rowIndex])) {
                let cell;
                cellIndex = table.querySelector('.e-emptyrow') ? 0 : cellIndex;
                if (table.rows[rowIndex].cells[0].classList.contains('e-editcell')) {
                    cell = table.rows[rowIndex].cells[0].querySelectorAll('td')[cellIndex];
                }
                else {
                    cell = table.rows[rowIndex].cells[cellIndex];
                }
                const isCellWidth = cell.getBoundingClientRect().width !== 0;
                if (action === 'enter' || action === 'shiftEnter') {
                    return isCellWidth && cell.classList.contains(literals.rowCell);
                }
                if ((action === 'shiftUp' || action === 'shiftDown') && cell.classList.contains(literals.rowCell)) {
                    return isCellWidth;
                }
                else if (action !== 'shiftUp' && action !== 'shiftDown') {
                    return isCellWidth;
                }
            }
            return false;
        };
    }
    shouldFocusChange(e) {
        const [rIndex = -1, cIndex = -1] = this.matrix.current;
        if (rIndex < 0 || cIndex < 0) {
            return true;
        }
        const cell = getValue(`${rIndex}.cells.${cIndex}`, this.getTable().rows);
        if (!cell) {
            return true;
        }
        return e.action === 'enter' || e.action === 'shiftEnter' ?
            cell.classList.contains(literals.rowCell) && !cell.classList.contains('e-unboundcell')
                && (!cell.classList.contains('e-templatecell') || cell.classList.contains('e-editedbatchcell'))
                && !cell.classList.contains('e-detailcell') : true;
    }
    getGridSeletion() {
        return this.parent.allowSelection && this.parent.selectionSettings.allowColumnSelection;
    }
}
/**
 * @hidden
 */
export class HeaderFocus extends ContentFocus {
    constructor(parent) {
        super(parent);
    }
    getTable() {
        return (this.parent.isFrozenGrid() ?
            this.parent.getHeaderContent().querySelector('.e-movableheader .e-table') :
            this.parent.getHeaderTable());
    }
    onClick(e) {
        let target = e.target;
        this.target = target;
        target = (target.classList.contains('e-headercell') ? target : closest(target, 'th'));
        if (!target && this.parent.frozenRows !== 0) {
            target = (e.target.classList.contains(literals.rowCell) ? e.target :
                closest(e.target, 'td'));
        }
        if (e.target.classList.contains('e-columnheader') ||
            e.target.querySelector('.e-stackedheadercell')) {
            return false;
        }
        if (!target) {
            return;
        }
        const [rowIndex, cellIndex] = [target.parentElement.rowIndex, target.cellIndex];
        const val = getValue(`${rowIndex}.${cellIndex}`, this.matrix.matrix);
        if (this.matrix.inValid(val)) {
            return false;
        }
        this.matrix.select(target.parentElement.rowIndex, target.cellIndex);
    }
    getFocusInfo() {
        const info = {};
        const [rowIndex = 0, cellIndex = 0] = this.matrix.current;
        info.element = this.getTable().rows[rowIndex].cells[cellIndex];
        if (!isNullOrUndefined(info.element)) {
            info.elementToFocus = this.getFocusable(info.element);
            info.outline = !info.element.classList.contains('e-filterbarcell');
        }
        return info;
    }
    selector(row, cell) {
        return (cell.visible && (cell.column.field !== undefined || cell.isTemplate || !isNullOrUndefined(cell.column.template))) ||
            cell.column.type === 'checkbox' || cell.cellType === CellType.StackedHeader;
    }
    jump(action, current) {
        let frozenSwap = (this.parent.getFrozenLeftCount() &&
            (action === 'leftArrow' || (action === 'shiftLeft' && this.getGridSeletion()) || action === 'shiftTab') && (current[1] === 0
            || current[1] === this.matrix.matrix[current[0]].indexOf(1))) || (this.parent.getFrozenMode() === 'Left' && action === 'tab'
            && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1));
        const right = ((action === 'rightArrow' || (action === 'shiftRight' && this.getGridSeletion())
            || action === 'tab') && (current[1] === this.matrix.columns || current[1] === this.matrix.matrix[current[0]].lastIndexOf(1)))
            || (current[0] > 0 && action === 'shiftTab' && this.parent.getFrozenMode() === 'Right'
                && current[1] === this.matrix.matrix[current[0]].indexOf(1));
        const frSwap = this.parent.getFrozenMode() === literals.leftRight && right;
        if (this.parent.getFrozenMode() === 'Right') {
            frozenSwap = right;
        }
        const enterFrozen = this.parent.frozenRows !== 0 && action === 'enter';
        let isLastCell;
        let lastRow;
        let headerSwap = frozenSwap && !(this.parent.getFrozenMode() === 'Left' && action === 'tab'
            && current[0] === this.matrix.matrix.length - 1) || frSwap;
        const fMatrix = this.parent.focusModule.fHeader && this.parent.focusModule.fHeader.matrix.matrix;
        const isPresent = fMatrix && !isNullOrUndefined(fMatrix[current[0]]);
        if (this.parent.enableHeaderFocus && action === 'tab') {
            lastRow = this.matrix.matrix.length - 1 === current[0];
            isLastCell = current[1] === this.matrix.matrix[current[0]].lastIndexOf(1);
            if (isLastCell) {
                if (!lastRow) {
                    this.matrix.current[0] = this.matrix.current[0] + 1;
                }
                else {
                    this.matrix.current[0] = 0;
                }
                this.matrix.current[1] = -1;
            }
            if (this.parent.isFrozenGrid() && lastRow && isLastCell) {
                frozenSwap = true;
                headerSwap = false;
            }
        }
        if (action === 'tab' && this.parent.getFrozenMode() === 'Left' && current[0] === this.matrix.matrix.length - 1
            && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1)) {
            this.matrix.current[0] = -1;
        }
        return {
            swap: ((action === 'downArrow' || enterFrozen) && current[0] === this.matrix.matrix.length - 1) ||
                (isPresent && (frozenSwap || frSwap)) || (action === 'tab' && lastRow && isLastCell),
            toHeader: headerSwap,
            toFrozen: frozenSwap,
            toFrozenRight: frSwap
        };
    }
    getNextCurrent(previous = [], swap, active, action) {
        const current1 = [];
        if (this.parent.getFrozenMode() === 'Right' || this.parent.getFrozenMode() === literals.leftRight) {
            if (action === 'leftArrow' || (action === 'shiftLeft' && this.getGridSeletion()) || action === 'shiftTab') {
                current1[0] = previous[0];
                current1[1] = this.matrix.matrix[current1[0]].length;
            }
            if (this.parent.getFrozenMode() === literals.leftRight
                && (action === 'rightArrow' || (action === 'shiftRight' && this.getGridSeletion()) || action === 'tab')) {
                current1[0] = previous[0];
                current1[1] = -1;
            }
        }
        else if (action === 'rightArrow' || (action === 'shiftRight' && this.getGridSeletion()) || action === 'tab') {
            current1[0] = previous[0];
            current1[1] = -1;
        }
        if (action === 'upArrow' || action === 'shiftEnter') {
            current1[0] = this.matrix.matrix.length;
            current1[1] = previous[1];
        }
        if (this.parent.getFrozenMode() === 'Left' && action === 'shiftTab') {
            current1[0] = previous[0] === -1 ? this.matrix.matrix.length - 1 : previous[0] - 1;
            current1[1] = this.matrix.matrix[current1[0]].length;
        }
        if (this.parent.getFrozenMode() === 'Right' && action === 'tab') {
            current1[0] = previous[0] + 1;
            current1[1] = -1;
        }
        return current1;
    }
    generateRows(rows) {
        const length = this.matrix.matrix.length;
        if (this.parent.allowFiltering && this.parent.filterSettings.type === 'FilterBar') {
            this.matrix.rows = ++this.matrix.rows;
            const cells = rows[0].cells;
            let incrementNumber = 0;
            const headerTable = this.getHeaderType() === 'FixedHeaderFocus' && this.parent.getFrozenMode() !== 'Right' ? literals.frozenLeft :
                this.getHeaderType() === 'FixedHeaderFocus' || this.getHeaderType() === 'FixedRightHeaderFocus' ? literals.frozenRight : 'movable';
            for (let i = 0; i < cells.length; i++) {
                if (cells[i].column && cells[i].column.columns) {
                    incrementNumber = this.checkFilterColumn(cells[i].column.columns, length, incrementNumber, headerTable);
                }
                else {
                    if (!this.parent.isFrozenGrid() || (this.parent.isFrozenGrid() && cells[i].column.freezeTable === headerTable)) {
                        this.matrix.set(length, incrementNumber, cells[i].visible && cells[i].column.allowFiltering !== false);
                        incrementNumber++;
                    }
                }
            }
        }
    }
    checkFilterColumn(rowColumns, rowIndex, columnIndex, headerTable) {
        const columns = rowColumns;
        let incrementNumber = columnIndex;
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].columns) {
                incrementNumber = this.checkFilterColumn(columns[i].columns, rowIndex, incrementNumber, headerTable);
            }
            else {
                if (!this.parent.isFrozenGrid() || (this.parent.isFrozenGrid() && columns[i].freezeTable === headerTable)) {
                    this.matrix.set(rowIndex, incrementNumber, columns[i].visible && columns[i].allowFiltering !== false);
                    incrementNumber++;
                }
            }
        }
        return incrementNumber;
    }
    getInfo(e) {
        return extend(super.getInfo(e), { isContent: false, isHeader: true });
    }
    validator() {
        return () => true;
    }
    shouldFocusChange(e) {
        const [rowIndex, columnIndex] = this.matrix.current;
        if (rowIndex < 0 || columnIndex < 0) {
            return true;
        }
        const cell = getValue(`${rowIndex}.cells.${columnIndex}`, this.getTable().rows);
        if (!cell) {
            return true;
        }
        return e.action === 'enter' || e.action === 'altDownArrow' ? !cell.classList.contains('e-headercell') : true;
    }
    getHeaderType() {
        return 'HeaderFocus';
    }
}
export class FixedContentFocus extends ContentFocus {
    getTable() {
        return this.parent.getContent().querySelector('.e-frozencontent .e-table');
    }
    jump(action, current) {
        const enterFrozen = this.parent.frozenRows !== 0 && action === 'shiftEnter';
        const toHeader = ((action === 'upArrow' || enterFrozen) && current[0] === 0)
            || (action === 'shiftTab' && this.parent.getFrozenMode() !== 'Right'
                && current[0] === 0 && current[1] === this.matrix.matrix[current[0]].indexOf(1));
        const fSwap = (action === 'upArrow' || enterFrozen) && current[0] === 0;
        const frSwap = action === 'shiftTab' && this.parent.getFrozenMode() === literals.leftRight
            && current[1] === this.matrix.matrix[current[0]].indexOf(1);
        if (this.parent.getFrozenMode() === 'Right') {
            const swap = toHeader || ((action === 'shiftTab' || action === 'leftArrow') && current[1] === 0)
                || (action === 'tab' && current[0] < this.matrix.matrix.length - 1
                    && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1));
            return { swap: swap, toHeader: toHeader, toFrozen: fSwap };
        }
        if (action === 'shiftTab' && current[0] === 0 && current[1] === this.matrix.matrix[current[0]].indexOf(1)) {
            this.matrix.current[0] = -1;
        }
        return {
            swap: toHeader || ((action === 'tab' || action === 'rightArrow') && current[1] === this.matrix.columns)
                || (action === 'shiftTab' && current[1] === this.matrix.matrix[current[0]].indexOf(1)),
            toHeader: toHeader,
            toFrozen: fSwap,
            toFrozenRight: frSwap
        };
    }
    getNextCurrent(previous = [], swap, active, action) {
        const current2 = [];
        if (this.parent.getFrozenMode() === 'Right') {
            if (action === 'rightArrow' || action === 'tab') {
                current2[0] = previous[0];
                current2[1] = -1;
            }
            if (action === 'shiftTab') {
                current2[0] = previous[0] - 1;
                current2[1] = this.matrix.matrix[current2[0]].length;
            }
        }
        else {
            if (action === 'tab' && this.parent.enableHeaderFocus) {
                current2[0] = previous[0];
                current2[1] = -1;
            }
            if (action === 'leftArrow' || action === 'shiftTab') {
                current2[0] = previous[0];
                current2[1] = active.matrix.columns + 1;
            }
        }
        if (action === 'downArrow' || action === 'enter') {
            current2[0] = -1;
            current2[1] = previous[1];
        }
        if (action === 'tab' && this.parent.getFrozenMode() !== 'Right') {
            current2[0] = previous[0] + 1;
            current2[1] = -1;
        }
        return current2;
    }
}
export class FixedHeaderFocus extends HeaderFocus {
    jump(action, current) {
        const enterFrozen = this.parent.frozenRows !== 0 && action === 'enter';
        const hMatrix = this.parent.focusModule.header && this.parent.focusModule.header.matrix.matrix;
        const isPresent = hMatrix && !isNullOrUndefined(hMatrix[current[0]]);
        if (this.parent.getFrozenMode() === 'Right') {
            const frSwap = ((action === 'leftArrow' || (action === 'shiftLeft' && this.getGridSeletion())
                || action === 'shiftTab') && (current[1] === 0 || current[1] === this.matrix.matrix[current[0]].indexOf(1)))
                || (current[0] < this.matrix.matrix.length - 1 && action === 'tab'
                    && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1));
            const swap = ((action === 'downArrow' || enterFrozen || (action === 'tab'
                && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1))) && current[0] === this.matrix.matrix.length - 1) ||
                (isPresent && frSwap);
            const toFrozen = (action === 'downArrow' || enterFrozen) && current[0] === this.matrix.matrix.length - 1;
            if (action === 'tab' && current[0] === this.matrix.matrix.length - 1
                && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1)) {
                this.matrix.current[0] = -1;
            }
            return { swap: swap, toHeader: frSwap, toFrozen: toFrozen };
        }
        const frSwap = current[0] > 0 && action === 'shiftTab' && this.parent.getFrozenMode() === literals.leftRight
            && current[1] === this.matrix.matrix[current[0]].indexOf(1);
        return {
            swap: (action === 'downArrow' || enterFrozen) && current[0] === this.matrix.matrix.length - 1 || ((action === 'rightArrow' ||
                (action === 'shiftRight' && this.getGridSeletion()) || action === 'tab') &&
                (current[1] === this.matrix.columns || current[1] === this.matrix.matrix[current[0]].lastIndexOf(1)) && isPresent)
                || (action === 'tab' && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1))
                || (action === 'shiftTab' && current[1] === this.matrix.matrix[current[0]].indexOf(1) && current[0] > 0),
            toHeader: (action === 'rightArrow' || (action === 'shiftRight' && this.getGridSeletion()) || action === 'tab') &&
                (current[1] === this.matrix.columns || current[1] === this.matrix.matrix[current[0]].lastIndexOf(1))
                || (action === 'tab' && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1))
                || (action === 'shiftTab' && current[1] === this.matrix.matrix[current[0]].indexOf(1) && current[0] > 0),
            toFrozen: (action === 'downArrow' || enterFrozen) && current[0] === this.matrix.matrix.length - 1,
            toFrozenRight: frSwap
        };
    }
    getTable() {
        return (this.parent.getHeaderContent().querySelector('.e-frozenheader .e-table'));
    }
    getNextCurrent(previous = [], swap, active, action) {
        const current3 = [];
        if (this.parent.getFrozenMode() === 'Right') {
            if (action === 'rightArrow' || (action === 'shiftRight' && this.getGridSeletion()) || action === 'tab') {
                current3[0] = previous[0];
                current3[1] = -1;
            }
            if (action === 'shiftTab') {
                current3[0] = previous[0] === -1 ? this.matrix.matrix.length - 1 : previous[0] - 1;
                current3[1] = this.matrix.matrix[current3[0]].length;
            }
        }
        else {
            if (action === 'leftArrow' || (action === 'shiftLeft' && this.getGridSeletion()) || action === 'shiftTab') {
                current3[0] = previous[0];
                current3[1] = this.matrix.matrix[current3[0]].length;
            }
        }
        if (action === 'upArrow' || action === 'shiftEnter') {
            current3[0] = this.matrix.matrix.length;
            current3[1] = previous[1];
        }
        if (action === 'tab' && this.parent.getFrozenMode() !== 'Right') {
            current3[0] = previous[0] + 1;
            current3[1] = -1;
        }
        return current3;
    }
    getHeaderType() {
        return 'FixedHeaderFocus';
    }
}
/** @hidden */
export class SearchBox {
    constructor(searchBox) {
        this.searchBox = searchBox;
    }
    searchFocus(args) {
        args.target.parentElement.classList.add('e-input-focus');
        if (args.target.classList.contains('e-input') && args.target.classList.contains('e-search') && args.target.value) {
            args.target.parentElement.querySelector('.e-clear-icon').classList.remove('e-clear-icon-hide');
        }
    }
    searchBlur(args) {
        args.target.parentElement.classList.remove('e-input-focus');
        if (args.target.classList.contains('e-search') && args.relatedTarget && !(args.relatedTarget.classList.contains('e-sicon e-clear-icon'))
            && !(args.relatedTarget.classList.contains('e-sicon'))) {
            args.target.parentElement.querySelector('.e-clear-icon').classList.add('e-clear-icon-hide');
        }
    }
    wireEvent() {
        if (this.searchBox) {
            EventHandler.add(this.searchBox, 'focus', this.searchFocus, this);
            EventHandler.add(this.searchBox, 'blur', this.searchBlur, this);
        }
    }
    unWireEvent() {
        if (this.searchBox) {
            EventHandler.remove(this.searchBox, 'focus', this.searchFocus);
            EventHandler.remove(this.searchBox, 'blur', this.searchBlur);
        }
    }
}
export class FixedRightContentFocus extends ContentFocus {
    getTable() {
        return this.parent.getContent().querySelector('.e-frozen-right-content .e-table');
    }
    jump(action, current) {
        const enterFrozen = this.parent.frozenRows !== 0 && action === 'shiftEnter';
        const toHeader = (action === 'upArrow' || enterFrozen) && current[0] === 0;
        const toFrozenSwap = this.parent.getFrozenMode() === literals.leftRight && action === 'tab'
            && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1);
        return {
            swap: toHeader || ((action === 'shiftTab' || action === 'leftArrow') && current[1] === 0)
                || (action === 'tab' && current[0] < this.matrix.matrix.length - 1
                    && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1)),
            toHeader: toHeader,
            toFrozenRight: toHeader,
            toFrozen: toFrozenSwap
        };
    }
    getNextCurrent(previous = [], swap, active, action) {
        const current2 = [];
        if (action === 'rightArrow' || action === 'tab') {
            current2[0] = previous[0];
            current2[1] = -1;
        }
        if (action === 'downArrow' || action === 'enter') {
            current2[0] = -1;
            current2[1] = previous[1];
        }
        if (action === 'shiftTab') {
            current2[0] = previous[0] - 1;
            current2[1] = this.matrix.matrix[current2[0]].length;
        }
        return current2;
    }
}
export class FixedRightHeaderFocus extends HeaderFocus {
    jump(action, current) {
        const headerMat = this.parent.focusModule.header && this.parent.focusModule.header.matrix.matrix;
        const isPresent = headerMat && !isNullOrUndefined(headerMat[current[0]]);
        const enterFrozen = this.parent.frozenRows !== 0 && action === 'enter';
        const frozenSwap = (action === 'leftArrow' || (action === 'shiftLeft' && this.getGridSeletion())
            || action === 'shiftTab') && (current[1] === 0 || current[1] === this.matrix.matrix[current[0]].indexOf(1))
            || (current[0] < this.matrix.matrix.length - 1 && action === 'tab'
                && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1));
        const swap = ((action === 'downArrow' || enterFrozen || (action === 'tab'
            && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1))) && current[0] === this.matrix.matrix.length - 1) ||
            (isPresent && frozenSwap);
        const fSwap = action === 'tab' && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1);
        const frSwap = (action === 'downArrow' || enterFrozen) && current[0] === this.matrix.matrix.length - 1;
        if (action === 'tab' && current[0] === this.matrix.matrix.length - 1
            && current[1] === this.matrix.matrix[current[0]].lastIndexOf(1)) {
            this.matrix.current[0] = -1;
        }
        return { swap: swap, toHeader: frozenSwap, toFrozen: fSwap, toFrozenRight: frSwap };
    }
    getTable() {
        return (this.parent.getHeaderContent().querySelector('.e-frozen-right-header .e-table'));
    }
    getNextCurrent(previous = [], swap, active, action) {
        const current3 = [];
        if (action === 'rightArrow' || (action === 'shiftRight' && this.getGridSeletion()) || action === 'tab') {
            current3[0] = previous[0];
            current3[1] = -1;
        }
        if (action === 'upArrow' || action === 'shiftEnter') {
            current3[0] = this.matrix.matrix.length;
            current3[1] = previous[1];
        }
        if (action === 'shiftTab') {
            current3[0] = previous[0] === -1 ? this.matrix.matrix.length - 1 : previous[0] - 1;
            current3[1] = this.matrix.matrix[current3[0]].length;
        }
        return current3;
    }
    getHeaderType() {
        return 'FixedRightHeaderFocus';
    }
}
