import { classList, addClass, removeClass, isNullOrUndefined, Browser } from '@syncfusion/ej2-base';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { EventHandler, closest } from '@syncfusion/ej2-base';
import * as events from '../base/constant';
import { Dialog, calculateRelativeBasedPosition } from '@syncfusion/ej2-popups';
import { createCboxWithWrap, toogleCheckbox, parentsUntil, removeAddCboxClasses } from '../base/util';
import { createCheckBox } from '@syncfusion/ej2-buttons';
import { SearchBox } from '../services/focus-strategy';
import * as literals from '../base/string-literals';
/**
 * The `ColumnChooser` module is used to show or hide columns dynamically.
 */
export class ColumnChooser {
    /**
     * Constructor for the Grid ColumnChooser module
     *
     * @param {IGrid} parent - specifies the IGrid
     * @param {ServiceLocator} serviceLocator - specifies the serviceLocator
     * @hidden
     */
    constructor(parent, serviceLocator) {
        this.showColumn = [];
        this.hideColumn = [];
        this.changedColumns = [];
        this.unchangedColumns = [];
        this.isDlgOpen = false;
        this.initialOpenDlg = true;
        this.stateChangeColumns = [];
        this.changedStateColumns = [];
        this.isInitialOpen = false;
        this.isCustomizeOpenCC = false;
        this.searchOperator = 'startswith';
        this.prevShowedCols = [];
        this.hideDialogFunction = this.hideDialog.bind(this);
        this.parent = parent;
        this.serviceLocator = serviceLocator;
        this.addEventListener();
        this.cBoxTrue = createCheckBox(this.parent.createElement, true, { checked: true, label: ' ' });
        this.cBoxFalse = createCheckBox(this.parent.createElement, true, { checked: false, label: ' ' });
        this.cBoxTrue.insertBefore(this.parent.createElement('input', {
            className: 'e-chk-hidden e-cc e-cc-chbox', attrs: { type: 'checkbox' }
        }), this.cBoxTrue.firstChild);
        this.cBoxFalse.insertBefore(this.parent.createElement('input', {
            className: 'e-chk-hidden e-cc e-cc-chbox', attrs: { 'type': 'checkbox' }
        }), this.cBoxFalse.firstChild);
        this.cBoxFalse.querySelector('.e-frame').classList.add('e-uncheck');
        if (this.parent.enableRtl) {
            addClass([this.cBoxTrue, this.cBoxFalse], ['e-rtl']);
        }
        if (this.parent.cssClass) {
            addClass([this.cBoxTrue, this.cBoxFalse], [this.parent.cssClass]);
        }
    }
    destroy() {
        const gridElement = this.parent.element;
        if (!gridElement || (!gridElement.querySelector('.' + literals.gridHeader) && !gridElement.querySelector('.' + literals.gridContent))) {
            return;
        }
        this.removeEventListener();
        this.unWireEvents();
        if (!isNullOrUndefined(this.dlgObj) && this.dlgObj.element && !this.dlgObj.isDestroyed) {
            this.dlgObj.destroy();
        }
    }
    rtlUpdate() {
        if (this.parent.enableRtl) {
            addClass([].slice.call(this.innerDiv.getElementsByClassName('e-checkbox-wrapper')), ['e-rtl']);
        }
        else {
            removeClass([].slice.call(this.innerDiv.getElementsByClassName('e-checkbox-wrapper')), ['e-rtl']);
        }
    }
    /**
     * @returns {void}
     * @hidden
     */
    addEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        EventHandler.add(document, 'click', this.clickHandler, this);
        this.parent.on(events.uiUpdate, this.enableAfterRenderEle, this);
        this.parent.on(events.initialEnd, this.render, this);
        this.parent.addEventListener(events.dataBound, this.hideDialogFunction);
        this.parent.on(events.destroy, this.destroy, this);
        this.parent.on(events.rtlUpdated, this.rtlUpdate, this);
        this.parent.on(events.keyPressed, this.keyUpHandler, this);
        this.parent.on(events.resetColumns, this.onResetColumns, this);
    }
    /**
     * @returns {void}
     * @hidden
     */
    removeEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        EventHandler.remove(document, 'click', this.clickHandler);
        this.parent.off(events.initialEnd, this.render);
        this.parent.off(events.destroy, this.destroy);
        this.parent.off(events.uiUpdate, this.enableAfterRenderEle);
        this.parent.off(events.rtlUpdated, this.rtlUpdate);
        this.parent.on(events.keyPressed, this.keyUpHandler, this);
        this.parent.off(events.resetColumns, this.onResetColumns);
        this.parent.removeEventListener(events.dataBound, this.hideDialogFunction);
    }
    render() {
        this.l10n = this.serviceLocator.getService('localization');
        this.renderDlgContent();
        this.getShowHideService = this.serviceLocator.getService('showHideService');
    }
    clickHandler(e) {
        const targetElement = e.target;
        if (!this.isCustomizeOpenCC) {
            if (!isNullOrUndefined(closest(targetElement, '.e-cc')) || !isNullOrUndefined(closest(targetElement, '.e-cc-toolbar'))) {
                if (targetElement.classList.contains('e-columnchooser-btn') || targetElement.classList.contains('e-cc-toolbar')) {
                    if ((this.initialOpenDlg && this.dlgObj.visible) || !this.isDlgOpen) {
                        this.isDlgOpen = true;
                        return;
                    }
                }
                else if (targetElement.classList.contains('e-cc-cancel')) {
                    targetElement.parentElement.querySelector('.e-ccsearch').value = '';
                    this.columnChooserSearch('');
                    this.removeCancelIcon();
                    this.refreshCheckboxButton();
                }
            }
            else {
                if (!isNullOrUndefined(this.dlgObj) && this.dlgObj.visible && !targetElement.classList.contains('e-toolbar-items')) {
                    this.dlgObj.hide();
                    this.clearActions();
                    this.refreshCheckboxState();
                    // this.unWireEvents();
                    this.isDlgOpen = false;
                }
            }
            if (this.parent.detailTemplate || this.parent.childGrid) {
                this.targetdlg = e.target;
            }
        }
        if (this.isCustomizeOpenCC && e.target.classList.contains('e-cc-cancel')) {
            this.refreshCheckboxState();
        }
        this.rtlUpdate();
    }
    hideDialog() {
        if (!isNullOrUndefined(this.dlgObj) && this.dlgObj.visible) {
            this.dlgObj.hide();
            // this.unWireEvents();
            this.isDlgOpen = false;
        }
    }
    /**
     * To render columnChooser when showColumnChooser enabled.
     *
     * @param {number} x - specifies the position x
     * @param {number} y - specifies the position y
     * @param {Element} target - specifies the target
     * @returns {void}
     * @hidden
     */
    renderColumnChooser(x, y, target) {
        if (!this.dlgObj.visible && (this.parent.detailTemplate || this.parent.childGrid)) {
            this.hideOpenedDialog();
        }
        if (!this.dlgObj.visible) {
            const args = this.beforeOpenColumnChooserEvent();
            if (args.cancel) {
                return;
            }
            if (target) {
                this.targetdlg = target;
            }
            this.refreshCheckboxState();
            this.dlgObj.dataBind();
            this.dlgObj.element.style.maxHeight = '430px';
            const elementVisible = this.dlgObj.element.style.display;
            this.dlgObj.element.style.display = 'block';
            const isSticky = this.parent.getHeaderContent().classList.contains('e-sticky');
            const toolbarItem = closest(target, '.e-toolbar-item');
            let newpos;
            if (isSticky) {
                newpos = toolbarItem.getBoundingClientRect();
                this.dlgObj.element.classList.add('e-sticky');
            }
            else {
                this.dlgObj.element.classList.remove('e-sticky');
                newpos = calculateRelativeBasedPosition(toolbarItem, this.dlgObj.element);
            }
            this.dlgObj.element.style.display = elementVisible;
            this.dlgObj.element.style.top = newpos.top + closest(target, '.e-cc-toolbar').getBoundingClientRect().height + 'px';
            const dlgWidth = 250;
            if (!isNullOrUndefined(closest(target, '.e-bigger'))) {
                this.dlgObj.width = 258;
            }
            if (Browser.isDevice) {
                this.dlgObj.target = document.body;
                this.dlgObj.position = { X: 'center', Y: 'center' };
                this.dlgObj.refreshPosition();
                this.dlgObj.open = this.mOpenDlg.bind(this);
            }
            else {
                if (this.parent.enableRtl) {
                    this.dlgObj.element.style.left = target.offsetLeft + 'px';
                }
                else {
                    this.dlgObj.element.style.left = ((newpos.left - dlgWidth) + closest(target, '.e-cc-toolbar').clientWidth) + 2 + 'px';
                }
            }
            this.removeCancelIcon();
            this.dlgObj.show();
            this.parent.notify(events.columnChooserOpened, { dialog: this.dlgObj });
        }
        else {
            // this.unWireEvents();
            this.hideDialog();
            this.addcancelIcon();
            this.clearActions();
            this.refreshCheckboxState();
        }
        this.rtlUpdate();
    }
    /**
     * Column chooser can be displayed on screen by given position(X and Y axis).
     *
     * @param  {number} X - Defines the X axis.
     * @param  {number} Y - Defines the Y axis.
     * @return {void}
     */
    openColumnChooser(X, Y) {
        this.isCustomizeOpenCC = true;
        if (this.dlgObj.visible) {
            this.hideDialog();
            return;
        }
        const args = this.beforeOpenColumnChooserEvent();
        if (args.cancel) {
            return;
        }
        if (!this.isInitialOpen) {
            this.dlgObj.content = this.renderChooserList();
            this.updateIntermediateBtn();
        }
        else {
            this.refreshCheckboxState();
        }
        this.dlgObj.dataBind();
        this.dlgObj.position = { X: 'center', Y: 'center' };
        if (isNullOrUndefined(X)) {
            this.dlgObj.position = { X: 'center', Y: 'center' };
            this.dlgObj.refreshPosition();
        }
        else {
            this.dlgObj.element.style.top = '';
            this.dlgObj.element.style.left = '';
            this.dlgObj.element.style.top = Y + 'px';
            this.dlgObj.element.style.left = X + 'px';
        }
        this.dlgObj.beforeOpen = this.customDialogOpen.bind(this);
        this.dlgObj.show();
        this.isInitialOpen = true;
        this.dlgObj.beforeClose = this.customDialogClose.bind(this);
    }
    enableAfterRenderEle(e) {
        if (e.module === this.getModuleName() && e.enable) {
            this.render();
        }
    }
    keyUpHandler(e) {
        if (e.action === 'escape') {
            this.hideDialog();
        }
    }
    customDialogOpen() {
        const searchElement = this.dlgObj.content.querySelector('input.e-ccsearch');
        EventHandler.add(searchElement, 'keyup', this.columnChooserManualSearch, this);
    }
    customDialogClose() {
        const searchElement = this.dlgObj.content.querySelector('input.e-ccsearch');
        EventHandler.remove(searchElement, 'keyup', this.columnChooserManualSearch);
    }
    getColumns() {
        const columns = this.parent.getColumns().filter((column) => (column.type !== 'checkbox'
            && column.showInColumnChooser === true) || (column.type === 'checkbox' && column.field !== undefined));
        return columns;
    }
    renderDlgContent() {
        this.dlgDiv = this.parent.createElement('div', { className: 'e-ccdlg e-cc', id: this.parent.element.id + '_ccdlg' });
        this.dlgDiv.setAttribute('aria-label', this.l10n.getConstant('ColumnChooserDialogARIA'));
        this.parent.element.appendChild(this.dlgDiv);
        this.dlgObj = new Dialog({
            header: this.l10n.getConstant('ChooseColumns'),
            showCloseIcon: false,
            closeOnEscape: false,
            locale: this.parent.locale,
            visible: false,
            enableRtl: this.parent.enableRtl,
            target: document.getElementById(this.parent.element.id),
            buttons: [{
                    click: this.confirmDlgBtnClick.bind(this),
                    buttonModel: {
                        content: this.l10n.getConstant('OKButton'), isPrimary: true,
                        cssClass: this.parent.cssClass ? 'e-cc e-cc_okbtn' + ' ' + this.parent.cssClass : 'e-cc e-cc_okbtn'
                    }
                },
                {
                    click: this.clearBtnClick.bind(this),
                    buttonModel: { cssClass: this.parent.cssClass ?
                            'e-flat e-cc e-cc-cnbtn' + ' ' + this.parent.cssClass : 'e-flat e-cc e-cc-cnbtn',
                        content: this.l10n.getConstant('CancelButton') }
                }],
            content: this.renderChooserList(),
            width: 250,
            cssClass: this.parent.cssClass ? 'e-cc' + ' ' + this.parent.cssClass : 'e-cc',
            animationSettings: { effect: 'None' }
        });
        const isStringTemplate = 'isStringTemplate';
        this.dlgObj[isStringTemplate] = true;
        this.dlgObj.appendTo(this.dlgDiv);
        this.wireEvents();
    }
    renderChooserList() {
        this.mainDiv = this.parent.createElement('div', { className: 'e-main-div e-cc' });
        const searchDiv = this.parent.createElement('div', { className: 'e-cc-searchdiv e-cc e-input-group' });
        const ccsearchele = this.parent.createElement('input', {
            className: 'e-ccsearch e-cc e-input',
            attrs: { placeholder: this.l10n.getConstant('Search'), cssClass: this.parent.cssClass }
        });
        const ccsearchicon = this.parent.createElement('span', {
            className: 'e-ccsearch-icon e-icons e-cc e-input-group-icon',
            attrs: { title: this.l10n.getConstant('Search') }
        });
        const conDiv = this.parent.createElement('div', { className: 'e-cc-contentdiv' });
        this.innerDiv = this.parent.createElement('div', { className: 'e-innerdiv e-cc' });
        searchDiv.appendChild(ccsearchele);
        searchDiv.appendChild(ccsearchicon);
        this.searchBoxObj = new SearchBox(ccsearchele);
        const innerDivContent = this.refreshCheckboxList(this.parent.getColumns());
        this.innerDiv.appendChild(innerDivContent);
        conDiv.appendChild(this.innerDiv);
        this.mainDiv.appendChild(searchDiv);
        this.mainDiv.appendChild(conDiv);
        return this.mainDiv;
    }
    confirmDlgBtnClick(args) {
        this.stateChangeColumns = [];
        this.changedStateColumns = [];
        this.changedColumns = (this.changedColumns.length > 0) ? this.changedColumns : this.unchangedColumns;
        this.changedColumnState(this.changedColumns);
        const uncheckedLength = this.ulElement.querySelector('.e-uncheck') &&
            this.ulElement.querySelectorAll('.e-uncheck:not(.e-selectall)').length;
        if (!isNullOrUndefined(args)) {
            if (uncheckedLength < this.parent.getColumns().length) {
                if (this.hideColumn.length) {
                    this.columnStateChange(this.hideColumn, false);
                }
                if (this.showColumn.length) {
                    this.columnStateChange(this.showColumn, true);
                }
                this.getShowHideService.setVisible(this.stateChangeColumns, this.changedStateColumns);
                this.clearActions();
                this.parent.notify(events.tooltipDestroy, { module: 'edit' });
                if (this.parent.getCurrentViewRecords().length === 0) {
                    const emptyRowCell = this.parent.element.querySelector('.e-emptyrow').querySelector('td');
                    emptyRowCell.setAttribute('colSpan', this.parent.getVisibleColumns().length.toString());
                }
            }
        }
    }
    onResetColumns(e) {
        if (e.requestType === 'columnstate') {
            this.resetColumnState();
            return;
        }
    }
    resetColumnState() {
        this.showColumn = [];
        this.hideColumn = [];
        this.hideDialog();
    }
    changedColumnState(changedColumns) {
        for (let index = 0; index < changedColumns.length; index++) {
            const colUid = changedColumns[index];
            const currentCol = this.parent.getColumnByUid(colUid);
            this.changedStateColumns.push(currentCol);
        }
    }
    columnStateChange(stateColumns, state) {
        for (let index = 0; index < stateColumns.length; index++) {
            const colUid = stateColumns[index];
            const currentCol = this.parent.getColumnByUid(colUid);
            if (currentCol.type !== 'checkbox') {
                currentCol.visible = state;
            }
            this.stateChangeColumns.push(currentCol);
        }
    }
    clearActions() {
        this.resetColumnState();
        this.addcancelIcon();
    }
    clearBtnClick() {
        this.clearActions();
        this.parent.notify(events.columnChooserCancelBtnClick, { dialog: this.dlgObj });
    }
    checkstatecolumn(isChecked, coluid, selectAll = false) {
        if (isChecked) {
            if (this.hideColumn.indexOf(coluid) !== -1) {
                this.hideColumn.splice(this.hideColumn.indexOf(coluid), 1);
            }
            if (this.showColumn.indexOf(coluid) === -1) {
                this.showColumn.push(coluid);
            }
        }
        else {
            if (this.showColumn.indexOf(coluid) !== -1) {
                this.showColumn.splice(this.showColumn.indexOf(coluid), 1);
            }
            if (this.hideColumn.indexOf(coluid) === -1) {
                this.hideColumn.push(coluid);
            }
        }
        if (selectAll) {
            if (!isChecked) {
                this.changedColumns.push(coluid);
            }
            else {
                this.unchangedColumns.push(coluid);
            }
        }
        else if (this.changedColumns.indexOf(coluid) !== -1) {
            this.changedColumns.splice(this.changedColumns.indexOf(coluid), 1);
        }
        else {
            this.changedColumns.push(coluid);
        }
    }
    columnChooserSearch(searchVal) {
        let clearSearch = false;
        let fltrCol;
        let okButton;
        const buttonEle = this.dlgDiv.querySelector('.e-footer-content');
        const selectedCbox = this.ulElement.querySelector('.e-check') &&
            this.ulElement.querySelectorAll('.e-check:not(.e-selectall)').length;
        this.isInitialOpen = true;
        if (buttonEle) {
            okButton = buttonEle.querySelector('.e-btn').ej2_instances[0];
        }
        if (searchVal === '') {
            this.removeCancelIcon();
            fltrCol = this.getColumns();
            clearSearch = true;
        }
        else {
            fltrCol = new DataManager(this.getColumns()).executeLocal(new Query()
                .where('headerText', this.searchOperator, searchVal, true, this.parent.columnChooserSettings.ignoreAccent));
        }
        if (fltrCol.length) {
            this.innerDiv.innerHTML = ' ';
            this.innerDiv.classList.remove('e-ccnmdiv');
            this.innerDiv.appendChild(this.refreshCheckboxList(fltrCol));
            if (!clearSearch) {
                this.addcancelIcon();
                this.refreshCheckboxButton();
            }
            else {
                if (okButton && selectedCbox) {
                    okButton.disabled = false;
                }
            }
        }
        else {
            const nMatchele = this.parent.createElement('span', { className: 'e-cc e-nmatch' });
            nMatchele.innerHTML = this.l10n.getConstant('Matchs');
            this.innerDiv.innerHTML = ' ';
            this.innerDiv.appendChild(nMatchele);
            this.innerDiv.classList.add('e-ccnmdiv');
            if (okButton) {
                okButton.disabled = true;
            }
        }
        this.flag = true;
        this.stopTimer();
    }
    wireEvents() {
        EventHandler.add(this.dlgObj.element, 'click', this.checkBoxClickHandler, this);
        EventHandler.add(this.searchBoxObj.searchBox, 'keyup', this.columnChooserManualSearch, this);
        this.searchBoxObj.wireEvent();
    }
    unWireEvents() {
        if (this.parent.isDestroyed) {
            return;
        }
        if (this.dlgObj.element) {
            EventHandler.remove(this.dlgObj.element, 'click', this.checkBoxClickHandler);
        }
        EventHandler.remove(this.searchBoxObj.searchBox, 'keyup', this.columnChooserManualSearch);
        this.searchBoxObj.unWireEvent();
    }
    checkBoxClickHandler(e) {
        let checkstate;
        const elem = parentsUntil(e.target, 'e-checkbox-wrapper');
        if (elem) {
            const selectAll = elem.querySelector('.e-selectall');
            if (selectAll) {
                this.updateSelectAll(!elem.querySelector('.e-check'));
            }
            else {
                toogleCheckbox(elem.parentElement);
            }
            elem.querySelector('.e-chk-hidden').focus();
            if (elem.querySelector('.e-check')) {
                checkstate = true;
            }
            else if (elem.querySelector('.e-uncheck')) {
                checkstate = false;
            }
            else {
                return;
            }
            this.updateIntermediateBtn();
            const columnUid = parentsUntil(elem, 'e-ccheck').getAttribute('uid');
            const column = this.parent.getColumns();
            if (columnUid === 'grid-selectAll') {
                this.changedColumns = [];
                this.unchangedColumns = [];
                for (let i = 0; i < column.length; i++) {
                    if (column[i].showInColumnChooser) {
                        this.checkstatecolumn(checkstate, column[i].uid, true);
                    }
                }
            }
            else {
                this.checkstatecolumn(checkstate, columnUid);
            }
            this.refreshCheckboxButton();
        }
    }
    updateIntermediateBtn() {
        const cnt = this.ulElement.children.length - 1;
        let className = [];
        const elem = this.ulElement.children[0].querySelector('.e-frame');
        const selected = this.ulElement.querySelectorAll('.e-check:not(.e-selectall)').length;
        const btn = this.dlgObj.btnObj[0];
        btn.disabled = false;
        if (cnt === selected) {
            className = ['e-check'];
        }
        else if (selected) {
            className = ['e-stop'];
        }
        else {
            className = ['e-uncheck'];
            btn.disabled = true;
        }
        btn.dataBind();
        removeClass([elem], ['e-check', 'e-stop', 'e-uncheck']);
        addClass([elem], className);
    }
    updateSelectAll(checked) {
        const cBoxes = [].slice.call(this.ulElement.getElementsByClassName('e-frame'));
        for (const cBox of cBoxes) {
            removeAddCboxClasses(cBox, checked);
        }
    }
    refreshCheckboxButton() {
        const visibleCols = this.parent.getVisibleColumns();
        for (let i = 0; i < visibleCols.length; i++) {
            const columnUID = visibleCols[i].uid;
            if (this.prevShowedCols.indexOf(columnUID) === -1) {
                this.prevShowedCols.push(columnUID);
            }
        }
        for (let i = 0; i < this.hideColumn.length; i++) {
            const index = this.prevShowedCols.indexOf(this.hideColumn[i]);
            if (index !== -1) {
                this.prevShowedCols.splice(index, 1);
            }
        }
        const selected = this.showColumn.length !== 0 ? 1 : this.prevShowedCols.length;
        const btn = this.dlgDiv.querySelector('.e-footer-content').querySelector('.e-btn').ej2_instances[0];
        btn.disabled = false;
        const srchShowCols = [];
        const searchData = [].slice.call(this.parent.element.getElementsByClassName('e-cc-chbox'));
        for (let i = 0, itemsLen = searchData.length; i < itemsLen; i++) {
            const element = searchData[i];
            const columnUID = parentsUntil(element, 'e-ccheck').getAttribute('uid');
            srchShowCols.push(columnUID);
        }
        const hideCols = this.showColumn.filter((column) => srchShowCols.indexOf(column) !== -1);
        if (selected === 0 && hideCols.length === 0) {
            btn.disabled = true;
        }
        btn.dataBind();
    }
    refreshCheckboxList(gdCol) {
        this.ulElement = this.parent.createElement('ul', { className: 'e-ccul-ele e-cc' });
        const selectAllValue = this.l10n.getConstant('SelectAll');
        const cclist = this.parent.createElement('li', { className: 'e-cclist e-cc e-cc-selectall' });
        const selectAll = this.createCheckBox(selectAllValue, false, 'grid-selectAll');
        if (gdCol.length) {
            selectAll.querySelector('.e-checkbox-wrapper').firstElementChild.classList.add('e-selectall');
            selectAll.querySelector('.e-frame').classList.add('e-selectall');
            this.checkState(selectAll.querySelector('.e-icons'), true);
            cclist.appendChild(selectAll);
            this.ulElement.appendChild(cclist);
        }
        if (this.parent.cssClass) {
            addClass([selectAll], [this.parent.cssClass]);
        }
        for (let i = 0; i < gdCol.length; i++) {
            const columns = gdCol[i];
            this.renderCheckbox(columns);
        }
        return this.ulElement;
    }
    refreshCheckboxState() {
        this.dlgObj.element.querySelector('.e-cc.e-input').value = '';
        this.columnChooserSearch('');
        const gridObject = this.parent;
        const currentCheckBoxColls = this.dlgObj.element.querySelectorAll('.e-cc-chbox:not(.e-selectall)');
        for (let i = 0, itemLen = currentCheckBoxColls.length; i < itemLen; i++) {
            const element = currentCheckBoxColls[i];
            let columnUID;
            if (this.parent.childGrid || this.parent.detailTemplate) {
                columnUID = parentsUntil(this.dlgObj.element.querySelectorAll('.e-cc-chbox:not(.e-selectall)')[i], 'e-ccheck').getAttribute('uid');
            }
            else {
                columnUID = parentsUntil(element, 'e-ccheck').getAttribute('uid');
            }
            const column = gridObject.getColumnByUid(columnUID);
            const uncheck = [].slice.call(element.parentElement.getElementsByClassName('e-uncheck'));
            if (column.visible && !uncheck.length) {
                element.checked = true;
                this.checkState(element.parentElement.querySelector('.e-icons'), true);
            }
            else {
                element.checked = false;
                this.checkState(element.parentElement.querySelector('.e-icons'), false);
            }
        }
    }
    checkState(element, state) {
        if (state) {
            classList(element, ['e-check'], ['e-uncheck']);
        }
        else {
            classList(element, ['e-uncheck'], ['e-check']);
        }
    }
    createCheckBox(label, checked, uid) {
        const cbox = checked ? this.cBoxTrue.cloneNode(true) : this.cBoxFalse.cloneNode(true);
        cbox.querySelector('.e-label').innerHTML = label;
        return createCboxWithWrap(uid, cbox, 'e-ccheck');
    }
    renderCheckbox(column) {
        let cclist;
        let hideColState;
        let showColState;
        if (column.showInColumnChooser) {
            cclist = this.parent.createElement('li', { className: 'e-cclist e-cc', styles: 'list-style:None', id: 'e-ccli_' + column.uid });
            hideColState = this.hideColumn.indexOf(column.uid) === -1 ? false : true;
            showColState = this.showColumn.indexOf(column.uid) === -1 ? false : true;
            const cccheckboxlist = this.createCheckBox(column.headerText, (column.visible && !hideColState) || showColState, column.uid);
            cclist.appendChild(cccheckboxlist);
            if (this.parent.cssClass) {
                addClass([cccheckboxlist], [this.parent.cssClass]);
            }
            this.ulElement.appendChild(cclist);
        }
        if (this.isInitialOpen) {
            this.updateIntermediateBtn();
        }
    }
    columnChooserManualSearch(e) {
        this.addcancelIcon();
        this.searchValue = e.target.value;
        this.stopTimer();
        this.startTimer(e);
    }
    startTimer(e) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const proxy = this;
        const interval = !proxy.flag && e.keyCode !== 13 ? 500 : 0;
        this.timer = window.setInterval(() => { proxy.columnChooserSearch(proxy.searchValue); }, interval);
    }
    stopTimer() {
        window.clearInterval(this.timer);
    }
    addcancelIcon() {
        this.dlgDiv.querySelector('.e-cc.e-ccsearch-icon').classList.add('e-cc-cancel');
    }
    removeCancelIcon() {
        this.dlgDiv.querySelector('.e-cc.e-ccsearch-icon').classList.remove('e-cc-cancel');
    }
    mOpenDlg() {
        if (Browser.isDevice) {
            this.dlgObj.element.querySelector('.e-cc-searchdiv').classList.remove('e-input-focus');
            this.dlgObj.element.querySelectorAll('.e-cc-chbox')[0].focus();
        }
    }
    // internally use
    getModuleName() {
        return 'columnChooser';
    }
    hideOpenedDialog() {
        const openCC = [].slice.call(document.getElementsByClassName('e-ccdlg')).filter((dlgEle) => dlgEle.classList.contains('e-popup-open'));
        for (let i = 0, dlgLen = openCC.length; i < dlgLen; i++) {
            if (openCC[i].classList.contains('e-dialog') || this.parent.element.id + '_ccdlg' !== openCC[i].id) {
                openCC[i].ej2_instances[0].hide();
            }
        }
    }
    beforeOpenColumnChooserEvent() {
        const args1 = {
            requestType: 'beforeOpenColumnChooser', element: this.parent.element,
            columns: this.getColumns(), cancel: false,
            searchOperator: this.parent.columnChooserSettings.operator
        };
        this.parent.trigger(events.beforeOpenColumnChooser, args1);
        this.searchOperator = args1.searchOperator;
        return args1;
    }
}
