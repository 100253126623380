import { remove, addClass } from '@syncfusion/ej2-base';
import { isNullOrUndefined, extend } from '@syncfusion/ej2-base';
import { doesImplementInterface, setStyleAndAttributes, appendChildren, extendObjWithFn } from '../base/util';
import { createCheckBox } from '@syncfusion/ej2-buttons';
import { foreignKeyData } from '../base/constant';
import { CellType } from '../base/enum';
import * as literals from '../base/string-literals';
/**
 * CellRenderer class which responsible for building cell content.
 *
 * @hidden
 */
export class CellRenderer {
    constructor(parent, locator) {
        this.localizer = locator.getService('localization');
        this.formatter = locator.getService('valueFormatter');
        this.parent = parent;
        this.element = this.parent.createElement('TD', { className: literals.rowCell, attrs: { role: 'gridcell', tabindex: '-1' } });
        this.rowChkBox = this.parent.createElement('input', { className: 'e-checkselect', attrs: { 'type': 'checkbox', 'aria-label': 'checkbox' } });
    }
    /**
     * Function to return the wrapper for the TD content
     *
     * @returns {string | Element} returns the string
     */
    getGui() {
        return '';
    }
    /**
     * Function to format the cell value.
     *
     * @param  {Column} column - specifies the column
     * @param  {Object} value - specifies the value
     * @param  {Object} data - specifies the data
     * @returns {string} returns the format
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    format(column, value, data) {
        if (!isNullOrUndefined(column.format)) {
            if (column.type === 'number' && isNaN(parseInt(value, 10))) {
                value = null;
            }
            value = this.formatter.toView(value, column.getFormatter());
        }
        return isNullOrUndefined(value) ? '' : value.toString();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    evaluate(node, cell, data, attributes, fData, isEdit) {
        let result;
        if (cell.column.template) {
            const isReactCompiler = this.parent.isReact && typeof (cell.column.template) !== 'string';
            const literals = ['index'];
            const dummyData = extendObjWithFn({}, data, { [foreignKeyData]: fData, column: cell.column });
            const templateID = this.parent.element.id + cell.column.uid;
            const str = 'isStringTemplate';
            if (isReactCompiler) {
                const copied = { 'index': attributes[literals[0]] };
                cell.column.getColumnTemplate()(extend(copied, dummyData), this.parent, 'columnTemplate', templateID, this.parent[str], null, node);
                this.parent.renderTemplates();
            }
            else {
                result = cell.column.getColumnTemplate()(extend({ 'index': attributes[literals[0]] }, dummyData), this.parent, 'template', templateID, this.parent[str], undefined, undefined, this.parent['root']);
            }
            if (!isReactCompiler) {
                appendChildren(node, result);
            }
            this.parent.notify('template-result', { template: result });
            result = null;
            node.setAttribute('aria-label', node.innerText + ' is template cell' + ' column header ' +
                cell.column.headerText);
            return false;
        }
        return true;
    }
    /**
     * Function to invoke the custom formatter available in the column object.
     *
     * @param  {Column} column - specifies the column
     * @param  {Object} value - specifies the value
     * @param  {Object} data - specifies the data
     * @returns {Object} returns the object
     */
    invokeFormatter(column, value, data) {
        if (!isNullOrUndefined(column.formatter)) {
            if (doesImplementInterface(column.formatter, 'getValue')) {
                const formatter = column.formatter;
                value = new formatter().getValue(column, data);
            }
            else if (typeof column.formatter === 'function') {
                value = column.formatter(column, data);
            }
            else {
                value = column.formatter.getValue(column, data);
            }
        }
        return value;
    }
    /**
     * Function to render the cell content based on Column object.
     *
     * @param {Cell<Column>} cell - specifies the cell
     * @param {Object} data - specifies the data
     * @param {Object} attributes - specifies the attributes
     * @param {boolean} isExpand - specifies the boolean for expand
     * @param {boolean} isEdit - specifies the boolean for edit
     * @returns {Element} returns the element
     */
    render(cell, data, attributes, isExpand, isEdit) {
        return this.refreshCell(cell, data, attributes, isEdit);
    }
    /**
     * Function to refresh the cell content based on Column object.
     *
     * @param {Element} td - specifies the element
     * @param {Cell<Column>} cell - specifies the cell
     * @param {Object} data - specifies the data
     * @param {Object} attributes - specifies the attribute
     * @returns {void}
     */
    refreshTD(td, cell, data, attributes) {
        const isEdit = this.parent.editSettings.mode === 'Batch' && td.classList.contains('e-editedbatchcell');
        if (this.parent.isReact) {
            td.innerHTML = '';
            const cellIndex = td.cellIndex;
            const parentRow = td.parentElement;
            remove(td);
            const newTD = this.refreshCell(cell, data, attributes, isEdit);
            this.cloneAttributes(newTD, td);
            if (parentRow.cells.length !== cellIndex - 1) {
                parentRow.insertBefore(newTD, parentRow.cells[cellIndex]);
            }
            else {
                parentRow.appendChild(newTD);
            }
        }
        else {
            const node = this.refreshCell(cell, data, attributes, isEdit);
            td.innerHTML = '';
            td.setAttribute('aria-label', node.getAttribute('aria-label'));
            const elements = [].slice.call(node.childNodes);
            for (const elem of elements) {
                td.appendChild(elem);
            }
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cloneAttributes(target, source) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const attrs = source.attributes;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let i = attrs.length;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let attr;
        while (i--) {
            attr = attrs[i];
            target.setAttribute(attr.name, attr.value);
        }
    }
    refreshCell(cell, data, attributes, isEdit) {
        const node = this.element.cloneNode();
        const column = cell.column;
        let fData;
        if (cell.isForeignKey) {
            fData = cell.foreignKeyData[0] || { [column.foreignKeyValue]: column.format ? null : '' };
        }
        //Prepare innerHtml
        let innerHtml = this.getGui();
        let value = cell.isForeignKey ? this.getValue(column.foreignKeyValue, fData, column) :
            this.getValue(column.field, data, column);
        if ((column.type === 'date' || column.type === 'datetime') && !isNullOrUndefined(value)) {
            value = new Date(value);
        }
        value = this.format(column, value, data);
        innerHtml = value.toString();
        if (column.type === 'boolean' && !column.displayAsCheckBox) {
            const localeStr = (value !== 'true' && value !== 'false') ? null : value === 'true' ? 'True' : 'False';
            innerHtml = localeStr ? this.localizer.getConstant(localeStr) : innerHtml;
        }
        const fromFormatter = this.invokeFormatter(column, value, data);
        innerHtml = !isNullOrUndefined(column.formatter) ? isNullOrUndefined(fromFormatter) ? '' : fromFormatter.toString() : innerHtml;
        node.setAttribute('aria-label', innerHtml + ' column header ' + cell.column.headerText);
        if (this.evaluate(node, cell, data, attributes, fData, isEdit) && column.type !== 'checkbox') {
            this.appendHtml(node, innerHtml, column.getDomSetter ? column.getDomSetter() : 'innerHTML');
        }
        else if (column.type === 'checkbox') {
            node.classList.add(literals.gridChkBox);
            node.setAttribute('aria-label', 'checkbox');
            if (this.parent.selectionSettings.persistSelection) {
                value = value === 'true';
            }
            else {
                value = false;
            }
            const checkWrap = createCheckBox(this.parent.createElement, false, { checked: value, label: ' ' });
            if (this.parent.cssClass) {
                addClass([checkWrap], [this.parent.cssClass]);
            }
            this.rowChkBox.id = 'checkbox-' + cell.rowID;
            checkWrap.insertBefore(this.rowChkBox.cloneNode(), checkWrap.firstChild);
            node.appendChild(checkWrap);
        }
        if (this.parent.checkAllRows === 'Check' && this.parent.enableVirtualization) {
            cell.isSelected = true;
        }
        this.setAttributes(node, cell, attributes);
        if (column.type === 'boolean' && column.displayAsCheckBox) {
            const checked = isNaN(parseInt(value.toString(), 10)) ? value === 'true' : parseInt(value.toString(), 10) > 0;
            const checkWrap = createCheckBox(this.parent.createElement, false, { checked: checked, label: ' ' });
            node.innerHTML = '';
            checkWrap.classList.add('e-checkbox-disabled');
            if (this.parent.cssClass) {
                addClass([checkWrap], [this.parent.cssClass]);
            }
            node.appendChild(checkWrap);
            node.setAttribute('aria-label', checked + ' column header ' + cell.column.headerText);
        }
        return node;
    }
    /**
     * Function to specifies how the result content to be placed in the cell.
     *
     * @param {Element} node - specifies the node
     * @param {string|Element} innerHtml - specifies the innerHTML
     * @param {string} property - specifies the element
     * @returns {Element} returns the element
     */
    appendHtml(node, innerHtml, property = 'innerHTML') {
        node[property] = innerHtml;
        return node;
    }
    /**
     * @param {HTMLElement} node - specifies the node
     * @param {cell<Column>} cell - specifies the cell
     * @param {Object} attributes - specifies the attributes
     * @returns {void}
     * @hidden
     */
    setAttributes(node, cell, attributes) {
        const column = cell.column;
        this.buildAttributeFromCell(node, cell, column.type === 'checkbox');
        setStyleAndAttributes(node, attributes);
        setStyleAndAttributes(node, cell.attributes);
        if (column.customAttributes) {
            setStyleAndAttributes(node, column.customAttributes);
        }
        if (this.parent.rowRenderingMode === 'Vertical') {
            setStyleAndAttributes(node, { 'data-cell': column.headerText });
        }
        if (column.textAlign) {
            node.style.textAlign = column.textAlign;
        }
        if (column.clipMode === 'Clip' || (!column.clipMode && this.parent.clipMode === 'Clip')) {
            node.classList.add('e-gridclip');
        }
        else if (column.clipMode === 'EllipsisWithTooltip' || (!column.clipMode && this.parent.clipMode === 'EllipsisWithTooltip')) {
            if (column.type !== 'checkbox') {
                node.classList.add('e-ellipsistooltip');
            }
        }
    }
    buildAttributeFromCell(node, cell, isCheckBoxType) {
        const attr = {};
        const prop = { 'colindex': literals.dataColIndex };
        const classes = [];
        if (cell.colSpan) {
            attr.colSpan = cell.colSpan;
        }
        if (cell.rowSpan) {
            attr.rowSpan = cell.rowSpan;
        }
        if (cell.isTemplate) {
            classes.push('e-templatecell');
        }
        if (cell.isSelected) {
            classes.push(...['e-selectionbackground', 'e-active']);
            if (isCheckBoxType) {
                node.querySelector('.e-frame').classList.add('e-check');
            }
        }
        if (cell.isColumnSelected) {
            classes.push(...['e-columnselection']);
        }
        if (cell.cellType === CellType.Header) {
            attr[prop.colindex] = cell.colIndex;
            attr[literals.ariaColIndex] = cell.colIndex + 1;
        }
        else if (!isNullOrUndefined(cell.index)) {
            attr[prop.colindex] = cell.index;
            attr[literals.ariaColIndex] = cell.index + 1;
        }
        if (!cell.visible) {
            classes.push('e-hide');
        }
        attr.class = classes;
        setStyleAndAttributes(node, attr);
    }
    getValue(field, data, column) {
        return column.valueAccessor(field, data, column);
    }
}
