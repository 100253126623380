import { Row } from '../models/row';
import { Column } from '../models/column';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { calculateAggregate, getUid } from '../base/util';
import { CellType } from '../base/enum';
import { Cell } from '../models/cell';
/**
 * Summary row model generator
 *
 * @hidden
 */
export class SummaryModelGenerator {
    /**
     * Constructor for Summary row model generator
     *
     * @param {IGrid} parent - specifies the IGrid
     */
    constructor(parent) {
        this.parent = parent;
    }
    getData() {
        const rows = [];
        const row = this.parent.aggregates.slice();
        for (let i = 0; i < row.length; i++) {
            const columns = row[i].columns.filter((column) => {
                return !(column.footerTemplate || column.groupFooterTemplate || column.groupCaptionTemplate)
                    || this.columnSelector(column);
            });
            if (columns.length) {
                rows.push({ columns: columns });
            }
        }
        return rows;
    }
    columnSelector(column) {
        return column.footerTemplate !== undefined;
    }
    getColumns(start, end) {
        const columns = [];
        if (this.parent.allowGrouping) {
            for (let i = 0; i < this.parent.groupSettings.columns.length; i++) {
                columns.push(new Column({}));
            }
        }
        if (this.parent.detailTemplate || !isNullOrUndefined(this.parent.childGrid)) {
            columns.push(new Column({}));
        }
        if (this.parent.isRowDragable() && !start) {
            columns.push(new Column({}));
        }
        columns.push(...this.parent.getColumns());
        end = end && !start ? end + this.parent.getIndentCount() : end;
        return isNullOrUndefined(start) ? columns : columns.slice(start, end);
    }
    generateRows(input, args, start, end, columns) {
        if (input.length === 0) {
            if (args === undefined || !args.count) {
                return [];
            }
        }
        const data = this.buildSummaryData(input, args);
        const rows = [];
        const row = this.getData();
        for (let i = 0; i < row.length; i++) {
            rows.push(this.getGeneratedRow(row[i], data[i], args ? args.level : undefined, start, end, args ? args.parentUid : undefined, columns));
        }
        return rows;
    }
    getGeneratedRow(summaryRow, data, raw, start, end, parentUid, columns) {
        const tmp = [];
        let indents = this.getIndentByLevel();
        const isDetailGridAlone = !isNullOrUndefined(this.parent.childGrid);
        const indentLength = !start ? this.parent.getIndentCount() : 0;
        if (this.parent.isRowDragable() && !start) {
            indents = ['e-indentcelltop'];
        }
        const values = columns ? columns : this.getColumns(start, end);
        for (let i = 0; i < values.length; i++) {
            tmp.push(this.getGeneratedCell(values[i], summaryRow, i >= indentLength ? this.getCellType() :
                i < this.parent.groupSettings.columns.length ? CellType.Indent : CellType.DetailFooterIntent, indents[i], isDetailGridAlone));
        }
        const row = new Row({ data: data, attributes: { class: 'e-summaryrow' } });
        row.cells = tmp;
        row.uid = getUid('grid-row');
        row.parentUid = parentUid;
        row.isAggregateRow = true;
        row.visible = tmp.some((cell) => cell.isDataCell && cell.visible);
        return row;
    }
    getGeneratedCell(column, summaryRow, cellType, indent, isDetailGridAlone) {
        //Get the summary column by display
        const sColumn = summaryRow.columns.filter((scolumn) => scolumn.columnName === column.field)[0];
        const attrs = {
            'style': { 'textAlign': column.textAlign },
            'e-mappinguid': column.uid, index: column.index
        };
        if (indent) {
            attrs.class = indent;
        }
        if (isNullOrUndefined(indent) && isDetailGridAlone) {
            attrs.class = 'e-detailindentcelltop';
        }
        const opt = {
            'visible': column.visible,
            'isDataCell': !isNullOrUndefined(sColumn),
            'isTemplate': sColumn && !isNullOrUndefined(sColumn.footerTemplate
                || sColumn.groupFooterTemplate || sColumn.groupCaptionTemplate),
            'column': sColumn || {},
            'attributes': attrs,
            'cellType': cellType
        };
        opt.column.headerText = column.headerText;
        return new Cell(opt);
    }
    buildSummaryData(data, args) {
        const dummy = [];
        const summaryRows = this.getData();
        let single = {};
        for (let i = 0; i < summaryRows.length; i++) {
            single = {};
            const column = summaryRows[i].columns;
            for (let j = 0; j < column.length; j++) {
                single = this.setTemplate(column[j], (args && args.aggregates) ? args : data, single);
            }
            dummy.push(single);
        }
        return dummy;
    }
    getIndentByLevel() {
        return this.parent.groupSettings.columns.map(() => 'e-indentcelltop');
    }
    setTemplate(column, data, single) {
        let types = column.type;
        const helper = {};
        const formatFn = column.getFormatter() || (() => (a) => a)();
        const group = data;
        if (!(types instanceof Array)) {
            types = [column.type];
        }
        for (let i = 0; i < types.length; i++) {
            const key = column.field + ' - ' + types[i].toLowerCase();
            const disp = column.columnName;
            const val = types[i] !== 'Custom' && group.aggregates && key in group.aggregates ? group.aggregates[key] :
                calculateAggregate(types[i], group.aggregates ? group : data, column, this.parent);
            single[disp] = single[disp] || {};
            single[disp][key] = val;
            single[disp][types[i]] = !isNullOrUndefined(val) ? formatFn(val) : ' ';
            if (group.field) {
                single[disp].field = group.field;
                single[disp].key = group.key;
            }
        }
        helper.format = column.getFormatter();
        column.setTemplate(helper);
        return single;
    }
    getCellType() {
        return CellType.Summary;
    }
}
export class GroupSummaryModelGenerator extends SummaryModelGenerator {
    columnSelector(column) {
        return column.groupFooterTemplate !== undefined;
    }
    getIndentByLevel(level = this.parent.groupSettings.columns.length) {
        return this.parent.groupSettings.columns.map((v, indx) => indx <= level - 1 ? '' : 'e-indentcelltop');
    }
    getCellType() {
        return CellType.GroupSummary;
    }
}
export class CaptionSummaryModelGenerator extends SummaryModelGenerator {
    columnSelector(column) {
        return column.groupCaptionTemplate !== undefined;
    }
    getData() {
        const initVal = { columns: [] };
        return [super.getData().reduce((prev, cur) => {
                prev.columns = [...prev.columns, ...cur.columns];
                return prev;
            }, initVal)];
    }
    isEmpty() {
        return (this.getData()[0].columns || []).length === 0;
    }
    getCellType() {
        return CellType.CaptionSummary;
    }
}
