var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Property, ChildProperty } from '@syncfusion/ej2-base';
/**
 * Configures the column chooser behavior of the Grid.
 */
export class ColumnChooserSettings extends ChildProperty {
}
__decorate([
    Property('startsWith')
], ColumnChooserSettings.prototype, "operator", void 0);
__decorate([
    Property(false)
], ColumnChooserSettings.prototype, "ignoreAccent", void 0);
