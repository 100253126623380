import { isNullOrUndefined, remove } from '@syncfusion/ej2-base';
import { formatUnit } from '@syncfusion/ej2-base';
import { Browser } from '@syncfusion/ej2-base';
import { colGroupRefresh, columnWidthChanged, scroll, columnVisibilityChanged, refreshFooterRenderer } from '../base/constant';
import { ContentRender } from './content-renderer';
import { RowRenderer } from './row-renderer';
import { SummaryModelGenerator } from '../services/summary-model-generator';
import { renderMovable, calculateAggregate, iterateExtend, addRemoveEventListener } from '../base/util';
import { DataUtil } from '@syncfusion/ej2-data';
import * as literals from '../base/string-literals';
/**
 * Footer module is used to render grid content
 *
 * @hidden
 */
export class FooterRenderer extends ContentRender {
    constructor(gridModule, serviceLocator) {
        super(gridModule, serviceLocator);
        this.aggregates = {};
        this.parent = gridModule;
        this.locator = serviceLocator;
        this.modelGenerator = new SummaryModelGenerator(this.parent);
        this.addEventListener();
    }
    /**
     * The function is used to render grid footer div
     *
     * @returns {void}
     */
    renderPanel() {
        const div = this.parent.createElement('div', { className: literals.gridFooter });
        const innerDiv = this.parent.createElement('div', { className: 'e-summarycontent' });
        let movableContent = innerDiv;
        if (this.parent.isFrozenGrid()) {
            const fDiv = this.parent.createElement('div', { className: 'e-frozenfootercontent e-frozen-left-footercontent' });
            const mDiv = this.parent.createElement('div', { className: 'e-movablefootercontent' });
            const frDiv = this.parent.createElement('div', { className: 'e-frozenfootercontent e-frozen-right-footercontent' });
            if (this.parent.getFrozenColumns() || this.parent.getFrozenLeftColumnsCount()) {
                innerDiv.appendChild(fDiv);
                this.frozenContent = fDiv;
            }
            innerDiv.appendChild(mDiv);
            this.movableContent = mDiv;
            movableContent = mDiv;
            if (this.parent.getFrozenRightColumnsCount()) {
                innerDiv.appendChild(frDiv);
                this.frozenRightContent = frDiv;
            }
        }
        if (Browser.isDevice) {
            movableContent.style.overflowX = 'scroll';
        }
        div.appendChild(innerDiv);
        this.setPanel(div);
        if (this.parent.getPager() != null) {
            this.parent.element.insertBefore(div, this.parent.getPager());
        }
        else {
            this.parent.element.appendChild(div);
        }
    }
    /**
     * The function is used to render grid footer table
     *
     * @returns {void}
     */
    renderTable() {
        const frzCols = this.parent.getFrozenColumns() || this.parent.getFrozenLeftColumnsCount();
        const innerDiv = this.createContentTable('_footer_table');
        const table = innerDiv.querySelector('.' + literals.table);
        const tFoot = this.parent.createElement('tfoot');
        table.appendChild(tFoot);
        if (this.parent.isFrozenGrid()) {
            const freezeTable = table.cloneNode(true);
            const frTable = table.cloneNode(true);
            if (frzCols) {
                this.frozenContent.appendChild(freezeTable);
                this.freezeTable = freezeTable;
            }
            if (this.parent.getFrozenRightColumnsCount()) {
                remove(frTable.querySelector(literals.colGroup));
                const hdr = this.parent.getHeaderContent().querySelector('.e-frozen-right-header');
                const frCol = (hdr.querySelector(literals.colGroup).cloneNode(true));
                frTable.insertBefore(frCol, frTable.querySelector(literals.tbody));
                this.frozenRightContent.appendChild(frTable);
                this.frTable = frTable;
            }
            this.movableContent.appendChild(table);
            remove(table.querySelector(literals.colGroup));
            const colGroup = ((this.parent.getHeaderContent().querySelector('.' + literals.movableHeader).querySelector(literals.colGroup)).cloneNode(true));
            table.insertBefore(colGroup, table.querySelector(literals.tbody));
            this.setColGroup(colGroup);
        }
        this.setTable(table);
    }
    renderSummaryContent(e, table, cStart, cEnd) {
        const input = this.parent.dataSource instanceof Array ? !this.parent.getDataModule().isRemote() &&
            this.parent.parentDetails ? this.getData() : this.parent.dataSource : this.parent.currentViewData;
        const summaries = this.modelGenerator.getData();
        const dummies = isNullOrUndefined(cStart) ? this.modelGenerator.getColumns() :
            this.modelGenerator.getColumns(cStart, cEnd);
        // eslint-disable-next-line max-len
        const rows = isNullOrUndefined(cStart) ? this.modelGenerator.generateRows(input, e || this.aggregates) :
            this.modelGenerator.generateRows(input, e || this.aggregates, cStart, cEnd);
        const fragment = document.createDocumentFragment();
        const rowrenderer = new RowRenderer(this.locator, null, this.parent);
        rowrenderer.element = this.parent.createElement('TR', { className: 'e-summaryrow', attrs: { role: 'row' } });
        for (let srow = 0, len = summaries.length; srow < len; srow++) {
            const row = rows[srow];
            if (!row) {
                continue;
            }
            const tr = rowrenderer.render(row, dummies);
            fragment.appendChild(tr);
        }
        table.tFoot.appendChild(fragment);
        this.aggregates = !isNullOrUndefined(e) ? e : this.aggregates;
    }
    refresh(e) {
        const frzCols = this.parent.getFrozenColumns() || this.parent.getFrozenLeftColumnsCount();
        const movable = this.parent.getMovableColumnsCount();
        const right = this.parent.getFrozenRightColumnsCount();
        if (this.parent.isFrozenGrid()) {
            remove(this.getPanel());
            this.renderPanel();
            this.renderTable();
            if (frzCols) {
                this.freezeTable.tFoot.innerHTML = '';
                this.renderSummaryContent(e, this.freezeTable, 0, frzCols);
            }
        }
        this.getTable().tFoot.innerHTML = '';
        this.renderSummaryContent(e, this.getTable(), frzCols, right ? frzCols + movable : undefined);
        if (this.parent.getFrozenRightColumnsCount()) {
            this.frTable.tFoot.innerHTML = '';
            this.renderSummaryContent(e, this.frTable, frzCols + movable, frzCols + movable + right);
            const movableLastCell = [].slice.call(this.getTable().getElementsByClassName('e-lastsummarycell'));
            if (movableLastCell.length) {
                for (let i = 0; i < movableLastCell.length; i++) {
                    movableLastCell[i].style.borderRight = '0px';
                }
            }
        }
        // check freeze content have no row case
        if (this.parent.isFrozenGrid()) {
            const movableCnt = [].slice.call(this.parent.element.querySelector('.e-movablefootercontent')
                .getElementsByClassName('e-summaryrow'));
            let frozenCnt;
            if (frzCols) {
                frozenCnt = [].slice.call(this.parent.element.querySelector('.e-frozen-left-footercontent')
                    .getElementsByClassName('e-summaryrow'));
                this.refreshHeight(frozenCnt, movableCnt);
                const frozenDiv = this.frozenContent;
                if (!frozenDiv.offsetHeight) {
                    frozenDiv.style.height = this.getTable().offsetHeight + 'px';
                }
            }
            if (right) {
                const frCnt = [].slice.call(this.parent.element.querySelector('.e-frozen-right-footercontent')
                    .getElementsByClassName('e-summaryrow'));
                this.refreshHeight(frCnt, movableCnt);
                if (frozenCnt) {
                    this.refreshHeight(frCnt, frozenCnt);
                }
                const frDiv = this.frTable;
                if (!frDiv.offsetHeight) {
                    frDiv.style.height = this.getTable().offsetHeight + 'px';
                }
            }
            if (this.parent.allowResizing) {
                this.updateFooterTableWidth(this.getTable());
            }
        }
        if (isNullOrUndefined(e) && this.parent.isAutoFitColumns) {
            this.parent.autoFitColumns();
        }
        this.onScroll();
    }
    refreshHeight(frozenCnt, movableCnt) {
        for (let i = 0; i < frozenCnt.length; i++) {
            const frozenHeight = frozenCnt[i].getBoundingClientRect().height;
            const movableHeight = movableCnt[i].getBoundingClientRect().height;
            if (frozenHeight < movableHeight) {
                frozenCnt[i].classList.remove('e-hide');
                frozenCnt[i].style.height = movableHeight + 'px';
            }
            else if (frozenHeight > movableHeight) {
                movableCnt[i].classList.remove('e-hide');
                movableCnt[i].style.height = frozenHeight + 'px';
            }
        }
    }
    refreshCol() {
        // frozen table
        let mheaderCol;
        const fheaderCol = mheaderCol = this.parent.element.querySelector('.' + literals.gridHeader).querySelector(literals.colGroup).cloneNode(true);
        if (this.parent.getFrozenColumns()) {
            // eslint-disable-next-line max-len
            const isXaxis = this.parent.enableColumnVirtualization && this.parent.contentModule.isXaxis();
            if (isXaxis) {
                mheaderCol = this.parent.getMovableVirtualHeader().querySelector(literals.colGroup).cloneNode(true);
            }
            else {
                mheaderCol = renderMovable(fheaderCol, this.parent.getFrozenColumns(), this.parent);
                this.freezeTable.replaceChild(fheaderCol, this.freezeTable.querySelector(literals.colGroup));
            }
        }
        this.getTable().replaceChild(mheaderCol, this.getColGroup());
        this.setColGroup(mheaderCol);
    }
    onWidthChange(args) {
        this.getColFromIndex(args.index).style.width = formatUnit(args.width);
        if (this.parent.allowResizing && args.module === 'resize') {
            this.updateFooterTableWidth(this.getTable());
        }
    }
    onScroll(e = {
        left: this.parent.isFrozenGrid() ? this.parent.getContent().querySelector('.' + literals.movableContent).scrollLeft :
            this.parent.getContent().firstChild.scrollLeft
    }) {
        this.getTable().parentElement.scrollLeft = e.left;
    }
    getColFromIndex(index) {
        const left = this.parent.getFrozenLeftColumnsCount() || this.parent.getFrozenColumns();
        const movable = this.parent.getMovableColumnsCount();
        const right = this.parent.getFrozenRightColumnsCount();
        const isDrag = this.parent.isRowDragable() && !(this.parent.getFrozenMode() === 'Right') ? 1 : 0;
        if (left && index < (left + isDrag)) {
            return this.freezeTable.querySelector(literals.colGroup).children[index];
        }
        else if (right && (index >= (left + movable + isDrag))) {
            return this.frTable.querySelector(literals.colGroup).children[index - (left ? (left + movable + isDrag) :
                (left + movable))];
        }
        return this.getColGroup().children[index - (left ? (left + isDrag) : left)];
    }
    columnVisibilityChanged() {
        this.refresh();
    }
    addEventListener() {
        this.evtHandlers = [{ event: colGroupRefresh, handler: this.refreshCol },
            { event: columnWidthChanged, handler: this.onWidthChange },
            { event: scroll, handler: this.onScroll },
            { event: columnVisibilityChanged, handler: this.columnVisibilityChanged },
            { event: refreshFooterRenderer, handler: this.refreshFooterRenderer }];
        addRemoveEventListener(this.parent, this.evtHandlers, true, this);
    }
    removeEventListener() {
        addRemoveEventListener(this.parent, this.evtHandlers, false);
    }
    updateFooterTableWidth(tFoot) {
        const tHead = this.parent.getHeaderTable();
        if (tHead && tFoot) {
            tFoot.style.width = tHead.style.width;
        }
    }
    refreshFooterRenderer(editedData) {
        const aggregates = this.onAggregates(editedData);
        this.refresh(aggregates);
    }
    getIndexByKey(data, ds) {
        const key = this.parent.getPrimaryKeyFieldNames()[0];
        for (let i = 0; i < ds.length; i++) {
            if (ds[i][key] === data[key]) {
                return i;
            }
        }
        return -1;
    }
    getData() {
        return this.parent.getDataModule().dataManager.executeLocal(this.parent.getDataModule().generateQuery(true));
    }
    onAggregates(editedData) {
        editedData = editedData instanceof Array ? editedData : [];
        const field = this.parent.getPrimaryKeyFieldNames()[0];
        let dataSource = [];
        let isModified = false;
        let batchChanges = {};
        const gridData = 'dataSource';
        let isFiltered = false;
        if (!this.parent.renderModule.data.isRemote() && this.parent.allowFiltering && this.parent.filterSettings.columns.length) {
            isFiltered = true;
        }
        let currentViewData = this.parent.dataSource instanceof Array ?
            (isFiltered ? this.parent.getFilteredRecords() : this.parent.dataSource) : (this.parent.dataSource[gridData].json.length ?
            this.parent.dataSource[gridData].json : this.parent.getCurrentViewRecords());
        if (this.parent.parentDetails && !this.parent.getDataModule().isRemote()) {
            currentViewData = this.getData();
        }
        if (this.parent.editModule) {
            batchChanges = this.parent.editModule.getBatchChanges();
        }
        if (Object.keys(batchChanges).length) {
            for (let i = 0; i < currentViewData.length; i++) {
                isModified = false;
                // eslint-disable-next-line max-len
                if (batchChanges[literals.changedRecords].length && this.getIndexByKey(currentViewData[i], batchChanges[literals.changedRecords]) > -1) {
                    isModified = true;
                    // eslint-disable-next-line max-len
                    dataSource.push(batchChanges[literals.changedRecords][this.getIndexByKey(currentViewData[i], batchChanges[literals.changedRecords])]);
                }
                // eslint-disable-next-line max-len
                if (batchChanges[literals.deletedRecords].length && this.getIndexByKey(currentViewData[i], batchChanges[literals.deletedRecords]) > -1) {
                    isModified = true;
                }
                else if (!isModified) {
                    dataSource.push(currentViewData[i]);
                }
            }
            if (batchChanges[literals.addedRecords].length) {
                for (let i = 0; i < batchChanges[literals.addedRecords].length; i++) {
                    dataSource.push(batchChanges[literals.addedRecords][i]);
                }
            }
        }
        else {
            if (editedData.length) {
                const data = iterateExtend(currentViewData);
                dataSource = data.map((item) => {
                    const idVal = DataUtil.getObject(field, item);
                    let value;
                    const hasVal = editedData.some((cItem) => {
                        value = cItem;
                        return idVal === DataUtil.getObject(field, cItem);
                    });
                    return hasVal ? value : item;
                });
            }
            else {
                dataSource = currentViewData;
            }
        }
        const eData = editedData;
        if ((eData.type && eData.type === 'cancel')) {
            dataSource = currentViewData;
        }
        const aggregate = {};
        let agrVal;
        const aggregateRows = this.parent.aggregates;
        for (let i = 0; i < aggregateRows.length; i++) {
            for (let j = 0; j < aggregateRows[i].columns.length; j++) {
                let data = [];
                const type = aggregateRows[i].columns[j].type.toString();
                data = dataSource;
                agrVal = calculateAggregate(type, data, aggregateRows[i].columns[j], this.parent);
                aggregate[aggregateRows[i].columns[j].field + ' - ' + type.toLowerCase()] = agrVal;
            }
        }
        const result = {
            result: dataSource,
            count: dataSource.length,
            aggregates: aggregate
        };
        return result;
    }
}
