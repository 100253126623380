import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { CellRenderer } from './cell-renderer';
/**
 * ExpandCellRenderer class which responsible for building group expand cell.
 *
 * @hidden
 */
export class DetailExpandCellRenderer extends CellRenderer {
    constructor() {
        super(...arguments);
        this.element = this.parent.createElement('TD', {
            className: 'e-detailrowcollapse',
            attrs: { 'aria-expanded': 'false', role: 'gridcell', tabindex: '-1' }
        });
    }
    /**
     * Function to render the detail expand cell
     *
     * @param {Cell<Column>} cell - specifies the cell
     * @param {Object} data - specifies the data
     * @param {Object} attributes - specifies the attributes
     * @returns {Element} returns the element
     */
    render(cell, data, attributes) {
        const node = this.element.cloneNode();
        if (attributes && !isNullOrUndefined(attributes['class'])) {
            node.className = '';
            node.className = attributes['class'];
            node.appendChild(this.parent.createElement('a', { className: 'e-icons e-dtdiagonaldown e-icon-gdownarrow', attrs: {
                    href: 'javascript:void(0);', 'title': 'expanded'
                } }));
        }
        else {
            node.appendChild(this.parent.createElement('a', { className: 'e-icons e-dtdiagonalright e-icon-grightarrow', attrs: {
                    href: 'javascript:void(0);', 'title': 'collapsed'
                } }));
        }
        return node;
    }
}
