var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, L10n, Browser, addClass, removeClass } from '@syncfusion/ej2-base';
import { createElement, compile as templateCompiler, EventHandler, extend } from '@syncfusion/ej2-base';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { Property, Event, NotifyPropertyChanges } from '@syncfusion/ej2-base';
import { NumericContainer } from './numeric-container';
import { PagerMessage } from './pager-message';
import { appendChildren } from '../grid/base/util';
import * as events from '../grid/base/constant';
/**
 * Represents the `Pager` component.
 * ```html
 * <div id="pager"/>
 * ```
 * ```typescript
 * <script>
 *   var pagerObj = new Pager({ totalRecordsCount: 50, pageSize:10 });
 *   pagerObj.appendTo("#pager");
 * </script>
 * ```
 */
let Pager = class Pager extends Component {
    /**
     * Constructor for creating the component.
     *
     * @param {PagerModel} options - specifies the options
     * @param {string} element - specifies the element
     * @param {string} parent - specifies the pager parent
     * @hidden
     */
    constructor(options, element, parent) {
        super(options, element);
        /** @hidden */
        this.hasParent = false;
        this.pageRefresh = 'pager-refresh';
        this.firstPagerFocus = false;
        this.parent = parent;
    }
    /**
     * To provide the array of modules needed for component rendering
     *
     * @returns {ModuleDeclaration[]} returns the modules declaration
     * @hidden
     */
    requiredModules() {
        const modules = [];
        if (this.enableExternalMessage) {
            modules.push({
                member: 'externalMessage',
                args: [this]
            });
        }
        if (this.checkpagesizes()) {
            modules.push({
                member: 'pagerdropdown',
                args: [this]
            });
        }
        return modules;
    }
    /**
     * Initialize the event handler
     *
     * @returns {void}
     * @hidden
     */
    preRender() {
        //preRender
        this.defaultConstants = {
            currentPageInfo: '{0} of {1} pages',
            totalItemsInfo: '({0} items)',
            totalItemInfo: '({0} item)',
            firstPageTooltip: 'Go to first page',
            lastPageTooltip: 'Go to last page',
            nextPageTooltip: 'Go to next page',
            previousPageTooltip: 'Go to previous page',
            nextPagerTooltip: 'Go to next pager items',
            previousPagerTooltip: 'Go to previous pager items',
            pagerDropDown: 'Items per page',
            pagerAllDropDown: 'Items',
            CurrentPageInfo: '{0} of {1} pages',
            TotalItemsInfo: '({0} items)',
            FirstPageTooltip: 'Go to first page',
            LastPageTooltip: 'Go to last page',
            NextPageTooltip: 'Go to next page',
            PreviousPageTooltip: 'Go to previous page',
            NextPagerTooltip: 'Go to next pager items',
            PreviousPagerTooltip: 'Go to previous pager items',
            PagerDropDown: 'Items per page',
            PagerAllDropDown: 'Items',
            All: 'All'
        };
        this.containerModule = new NumericContainer(this);
        this.pagerMessageModule = new PagerMessage(this);
    }
    /**
     * To Initialize the component rendering
     *
     * @returns {void}
     */
    render() {
        this.element.setAttribute('data-role', 'pager');
        this.element.setAttribute('aria-label', 'Pager Container');
        this.element.setAttribute('tabindex', '-1');
        if (this.cssClass) {
            addClass([this.element], [this.cssClass]);
        }
        if (!this.hasParent) {
            this.element.setAttribute('tabindex', '0');
        }
        if (this.template) {
            if (this.isReactTemplate()) {
                this.on(this.pageRefresh, this.pagerTemplate, this);
                this.notify(this.pageRefresh, {});
            }
            else {
                this.pagerTemplate();
            }
        }
        else {
            this.initLocalization();
            this.updateRTL();
            this.totalRecordsCount = this.totalRecordsCount || 0;
            this.renderFirstPrevDivForDevice();
            this.containerModule.render();
            if (this.enablePagerMessage) {
                this.pagerMessageModule.render();
            }
            this.renderNextLastDivForDevice();
            if (this.checkpagesizes() && this.pagerdropdownModule) {
                this.pagerdropdownModule.render();
            }
            this.addAriaLabel();
            if (this.enableExternalMessage && this.externalMessageModule) {
                this.externalMessageModule.render();
            }
            this.refresh();
            this.trigger('created', { 'currentPage': this.currentPage, 'totalRecordsCount': this.totalRecordsCount });
        }
        this.wireEvents();
        this.addListener();
    }
    /**
     * Get the properties to be maintained in the persisted state.
     *
     * @returns {string} returns the persist data
     * @hidden
     */
    getPersistData() {
        const keyEntity = ['currentPage', 'pageSize'];
        return this.addOnPersist(keyEntity);
    }
    /**
     * To destroy the Pager component.
     *
     * @method destroy
     * @returns {void}
     */
    destroy() {
        if (this.isDestroyed) {
            return;
        }
        if (this.isReactTemplate()) {
            this.off(this.pageRefresh, this.pagerTemplate);
            if (!this.hasParent) {
                this.destroyTemplate(['template']);
            }
        }
        this.removeListener();
        this.unwireEvents();
        super.destroy();
        this.containerModule.destroy();
        this.pagerMessageModule.destroy();
        if (!this.isReactTemplate()) {
            this.element.innerHTML = '';
        }
    }
    /**
     * Destroys the given template reference.
     *
     * @param {string[]} propertyNames - Defines the collection of template name.
     * @param {any} index - Defines the index
     */
    // eslint-disable-next-line
    destroyTemplate(propertyNames, index) {
        this.clearTemplate(propertyNames, index);
    }
    /**
     * For internal use only - Get the module name.
     *
     * @returns {string} returns the module name
     * @private
     */
    getModuleName() {
        return 'pager';
    }
    /**
     * Called internally if any of the property value changed.
     *
     * @param {PagerModel} newProp - specifies the new property
     * @param {PagerModel} oldProp - specifies the old propety
     * @returns {void}
     * @hidden
     */
    onPropertyChanged(newProp, oldProp) {
        if (this.isDestroyed) {
            return;
        }
        if (newProp.pageCount !== oldProp.pageCount) {
            this.containerModule.refreshNumericLinks();
            this.containerModule.refresh();
        }
        for (const prop of Object.keys(newProp)) {
            switch (prop) {
                case 'currentPage':
                    if (this.checkGoToPage(newProp.currentPage, oldProp.currentPage)) {
                        this.currentPageChanged(newProp, oldProp);
                    }
                    break;
                case 'pageSize':
                case 'totalRecordsCount':
                case 'customText':
                    if (this.checkpagesizes() && this.pagerdropdownModule) {
                        if (oldProp.pageSize !== newProp.pageSize) {
                            this.currentPage = 1;
                        }
                        this.pagerdropdownModule.setDropDownValue('value', this.pageSize);
                    }
                    if (newProp.pageSize !== oldProp.pageSize) {
                        this.pageSize = newProp.pageSize;
                        this.currentPageChanged(newProp, oldProp);
                    }
                    else {
                        this.refresh();
                    }
                    break;
                case 'pageSizes':
                    if (this.checkpagesizes() && this.pagerdropdownModule) {
                        this.pagerdropdownModule.destroy();
                        this.pagerdropdownModule.render();
                    }
                    this.refresh();
                    break;
                case 'template':
                    this.templateFn = this.compile(this.template);
                    this.refresh();
                    break;
                case 'locale':
                    this.initLocalization();
                    this.refresh();
                    break;
                case 'enableExternalMessage':
                    if (this.enableExternalMessage && this.externalMessageModule) {
                        this.externalMessageModule.render();
                    }
                    break;
                case 'externalMessage':
                    if (this.externalMessageModule) {
                        this.externalMessageModule.refresh();
                    }
                    break;
                case 'enableRtl':
                    this.updateRTL();
                    break;
                case 'enablePagerMessage':
                    if (this.enablePagerMessage) {
                        this.pagerMessageModule.showMessage();
                    }
                    else {
                        this.pagerMessageModule.hideMessage();
                    }
                    break;
            }
        }
    }
    wireEvents() {
        if (!this.hasParent) {
            EventHandler.add(this.element, 'keydown', this.keyPressHandler, this);
            EventHandler.add(document.body, 'keydown', this.keyDownHandler, this);
        }
        EventHandler.add(this.element, 'focusin', this.onFocusIn, this);
        EventHandler.add(this.element, 'focusout', this.onFocusOut, this);
    }
    unwireEvents() {
        if (!this.hasParent) {
            EventHandler.remove(this.element, 'keydown', this.keyPressHandler);
            EventHandler.remove(document.body, 'keydown', this.keyDownHandler);
        }
        EventHandler.remove(this.element, 'focusin', this.onFocusIn);
        EventHandler.remove(this.element, 'focusout', this.onFocusOut);
    }
    onFocusIn(e) {
        const focusedTabIndexElement = this.getFocusedTabindexElement();
        if (isNullOrUndefined(focusedTabIndexElement)) {
            const target = e.target;
            const dropDownPage = this.getDropDownPage();
            if (!this.hasParent) {
                this.element.tabIndex = -1;
            }
            if (target === this.element && !this.hasParent) {
                const focusablePagerElements = this.getFocusablePagerElements(this.element, []);
                this.addFocus(focusablePagerElements[0], true);
                return;
            }
            if (target === this.element) {
                this.element.tabIndex = 0;
                return;
            }
            if (target !== dropDownPage && !target.classList.contains('e-disable')) {
                this.addFocus(target, true);
            }
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFocusOut(e) {
        const focusedElement = this.getFocusedElement();
        const dropDownPage = this.getDropDownPage();
        if (!isNullOrUndefined(focusedElement)) {
            this.removeFocus(focusedElement, true);
        }
        if (this.pageSizes && dropDownPage && dropDownPage.classList.contains('e-input-focus')) {
            this.removeFocus(dropDownPage, true);
        }
        this.setTabIndexForFocusLastElement();
        if (!this.hasParent) {
            this.element.tabIndex = 0;
        }
        if (this.hasParent) {
            this.element.tabIndex = -1;
        }
    }
    keyDownHandler(e) {
        if (e.altKey) {
            if (e.keyCode === 74) {
                const focusablePagerElements = this.getFocusablePagerElements(this.element, []);
                if (focusablePagerElements.length > 0) {
                    focusablePagerElements[0].focus();
                }
            }
        }
    }
    keyPressHandler(e) {
        const presskey = extend(e, { cancel: false });
        this.notify(events.keyPressed, presskey);
        if (presskey.cancel === true) {
            e.stopImmediatePropagation();
        }
    }
    addListener() {
        if (this.isDestroyed) {
            return;
        }
        if (!this.hasParent) {
            this.on(events.keyPressed, this.onKeyPress, this);
        }
    }
    removeListener() {
        if (this.isDestroyed) {
            return;
        }
        if (!this.hasParent) {
            this.off(events.keyPressed, this.onKeyPress);
        }
    }
    onKeyPress(e) {
        if (!this.hasParent) {
            if (this.checkPagerHasFocus()) {
                this.changePagerFocus(e);
            }
            else {
                e.preventDefault();
                this.setPagerFocus();
            }
        }
    }
    /**
     * @returns {boolean} - Return the true value if pager has focus
     * @hidden */
    checkPagerHasFocus() {
        return this.getFocusedTabindexElement() ? true : false;
    }
    /**
     * @returns {void}
     * @hidden */
    setPagerContainerFocus() {
        this.element.focus();
    }
    /**
     * @returns {void}
     * @hidden */
    setPagerFocus() {
        const focusablePagerElements = this.getFocusablePagerElements(this.element, []);
        if (focusablePagerElements.length > 0) {
            focusablePagerElements[0].focus();
        }
    }
    setPagerFocusForActiveElement() {
        const currentActivePage = this.getActiveElement();
        if (currentActivePage) {
            currentActivePage.focus();
        }
    }
    setTabIndexForFocusLastElement() {
        const focusablePagerElements = this.getFocusablePagerElements(this.element, []);
        const dropDownPage = this.getDropDownPage();
        if (this.pageSizes && dropDownPage && !isNullOrUndefined(dropDownPage.offsetParent)) {
            dropDownPage.tabIndex = 0;
        }
        else if (focusablePagerElements.length > 0) {
            focusablePagerElements[focusablePagerElements.length - 1].tabIndex = 0;
        }
    }
    /**
     * @param {KeyboardEventArgs} e - Keyboard Event Args
     * @returns {void}
     * @hidden */
    changePagerFocus(e) {
        if (e.shiftKey && e.keyCode === 9) {
            this.changeFocusByShiftTab(e);
        }
        else if (e.keyCode === 9) {
            this.changeFocusByTab(e);
        }
        else if (e.keyCode === 13 || e.keyCode === 32) {
            this.navigateToPageByEnterOrSpace(e);
        }
        else if (e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 35 || e.keyCode === 36) {
            this.navigateToPageByKey(e);
        }
    }
    getFocusedTabindexElement() {
        let focusedTabIndexElement;
        const tabindexElements = this.element.querySelectorAll('[tabindex]:not([tabindex="-1"])');
        for (let i = 0; i < tabindexElements.length; i++) {
            const element = tabindexElements[i];
            if (element && (element.classList.contains('e-focused') || element.classList.contains('e-input-focus'))) {
                focusedTabIndexElement = element;
                break;
            }
        }
        return focusedTabIndexElement;
    }
    changeFocusByTab(e) {
        const currentItemPagerFocus = this.getFocusedTabindexElement();
        const focusablePagerElements = this.getFocusablePagerElements(this.element, []);
        const dropDownPage = this.getDropDownPage();
        if (focusablePagerElements.length > 0) {
            if (this.pageSizes && dropDownPage && currentItemPagerFocus === focusablePagerElements[focusablePagerElements.length - 1]) {
                dropDownPage.tabIndex = 0;
            }
            else {
                for (let i = 0; i < focusablePagerElements.length; i++) {
                    if (currentItemPagerFocus === focusablePagerElements[i]) {
                        const incrementNumber = i + 1;
                        if (incrementNumber < focusablePagerElements.length) {
                            e.preventDefault();
                            focusablePagerElements[incrementNumber].focus();
                        }
                        break;
                    }
                }
            }
        }
    }
    changeFocusByShiftTab(e) {
        const currentItemPagerFocus = this.getFocusedTabindexElement();
        const focusablePagerElements = this.getFocusablePagerElements(this.element, []);
        const dropDownPage = this.getDropDownPage();
        if (this.pageSizes && dropDownPage && dropDownPage.classList.contains('e-input-focus')) {
            dropDownPage.tabIndex = -1;
            this.addFocus(focusablePagerElements[focusablePagerElements.length - 1], true);
        }
        else if (focusablePagerElements.length > 0) {
            for (let i = 0; i < focusablePagerElements.length; i++) {
                if (currentItemPagerFocus === focusablePagerElements[i]) {
                    const decrementNumber = i - 1;
                    if (decrementNumber >= 0) {
                        e.preventDefault();
                        focusablePagerElements[decrementNumber].focus();
                    }
                    else if (this.hasParent) {
                        const rows = this.parent.getRows();
                        const lastRow = rows[rows.length - 1];
                        const lastCell = lastRow.lastChild;
                        e.preventDefault();
                        lastCell.focus();
                        this.firstPagerFocus = true;
                    }
                    break;
                }
            }
        }
    }
    /**
     * @returns {void}
     * @hidden */
    checkFirstPagerFocus() {
        if (this.firstPagerFocus) {
            this.firstPagerFocus = false;
            return true;
        }
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    navigateToPageByEnterOrSpace(e) {
        const currentItemPagerFocus = this.getFocusedElement();
        if (currentItemPagerFocus) {
            this.goToPage(parseInt(currentItemPagerFocus.getAttribute('index'), 10));
            const currentActivePage = this.getActiveElement();
            const selectedClass = this.getClass(currentItemPagerFocus);
            const classElement = this.getElementByClass(selectedClass);
            if ((selectedClass === 'e-first' || selectedClass === 'e-prev' || selectedClass === 'e-next'
                || selectedClass === 'e-last' || selectedClass === 'e-pp' || selectedClass === 'e-np')
                && classElement && !classElement.classList.contains('e-disable')) {
                classElement.focus();
            }
            else if (this.checkFocusInAdaptiveMode(currentItemPagerFocus)) {
                this.changeFocusInAdaptiveMode(currentItemPagerFocus);
            }
            else {
                if (currentActivePage) {
                    currentActivePage.focus();
                }
            }
        }
    }
    navigateToPageByKey(e) {
        const actionClass = e.keyCode === 37 ? '.e-prev' : e.keyCode === 39 ? '.e-next'
            : e.keyCode === 35 ? '.e-last' : e.keyCode === 36 ? '.e-first' : '';
        const pagingItem = this.element.querySelector(actionClass);
        const currentItemPagerFocus = this.getFocusedElement();
        if (!isNullOrUndefined(pagingItem) && pagingItem.hasAttribute('index')
            && !isNaN(parseInt(pagingItem.getAttribute('index'), 10))) {
            this.goToPage(parseInt(pagingItem.getAttribute('index'), 10));
            const currentActivePage = this.getActiveElement();
            if (this.checkFocusInAdaptiveMode(currentItemPagerFocus)) {
                this.changeFocusInAdaptiveMode(currentItemPagerFocus);
            }
            else {
                if (currentActivePage) {
                    currentActivePage.focus();
                }
            }
        }
    }
    checkFocusInAdaptiveMode(element) {
        const selectedClass = this.getClass(element);
        return selectedClass === 'e-mfirst' || selectedClass === 'e-mprev' || selectedClass === 'e-mnext'
            || selectedClass === 'e-mlast' ? true : false;
    }
    changeFocusInAdaptiveMode(element) {
        const selectedClass = this.getClass(element);
        const classElement = this.getElementByClass(selectedClass);
        if (classElement && classElement.classList.contains('e-disable')) {
            if (selectedClass === 'e-mnext' || selectedClass === 'e-mlast') {
                const mPrev = this.element.querySelector('.e-mprev');
                mPrev.focus();
            }
            else {
                this.setPagerFocus();
            }
        }
    }
    removeTabindexLastElements() {
        const tabIndexElements = this.element.querySelectorAll('[tabindex]:not([tabindex="-1"])');
        if (tabIndexElements.length > 1) {
            for (let i = 1; i < tabIndexElements.length; i++) {
                const element = tabIndexElements[i];
                if (element) {
                    element.tabIndex = -1;
                }
            }
        }
    }
    getActiveElement() {
        return this.element.querySelector('.e-active');
    }
    /**
     * @returns {Element} - Returns DropDown Page
     * @hidden */
    getDropDownPage() {
        const dropDownPageHolder = this.element.querySelector('.e-pagerdropdown');
        let dropDownPage;
        if (dropDownPageHolder) {
            dropDownPage = dropDownPageHolder.children[0];
        }
        return dropDownPage;
    }
    getFocusedElement() {
        return this.element.querySelector('.e-focused');
    }
    getClass(element) {
        let currentClass;
        const classList = ['e-mfirst', 'e-mprev', 'e-first', 'e-prev', 'e-pp',
            'e-np', 'e-next', 'e-last', 'e-mnext', 'e-mlast'];
        for (let i = 0; i < classList.length; i++) {
            if (element && element.classList.contains(classList[i])) {
                currentClass = classList[i];
                return currentClass;
            }
        }
        return currentClass;
    }
    getElementByClass(className) {
        return this.element.querySelector('.' + className);
    }
    /**
     * @param {Element} element - Pager element
     * @param {Element[]} previousElements - Iterating pager element
     * @returns {Element[]} - Returns focusable pager element
     * @hidden */
    getFocusablePagerElements(element, previousElements) {
        const target = element;
        const targetChildrens = target.children;
        let pagerElements = previousElements;
        for (let i = 0; i < targetChildrens.length; i++) {
            const element = targetChildrens[i];
            if (element.children.length > 0 && !element.classList.contains('e-pagesizes')) {
                pagerElements = this.getFocusablePagerElements(element, pagerElements);
            }
            else {
                const tabindexElement = targetChildrens[i];
                if (tabindexElement.hasAttribute('tabindex') && !element.classList.contains('e-disable')
                    && element.style.display !== 'none'
                    && !isNullOrUndefined(element.offsetParent)) {
                    pagerElements.push(tabindexElement);
                }
            }
        }
        return pagerElements;
    }
    addFocus(element, addFocusClass) {
        if (addFocusClass) {
            addClass([element], ['e-focused', 'e-focus']);
        }
        element.tabIndex = 0;
    }
    removeFocus(element, removeFocusClass) {
        if (removeFocusClass) {
            removeClass([element], ['e-focused', 'e-focus']);
        }
        element.tabIndex = -1;
    }
    /**
     * Gets the localized label by locale keyword.
     *
     * @param  {string} key - specifies the key
     * @returns {string} returns the localized label
     */
    getLocalizedLabel(key) {
        return this.localeObj.getConstant(key);
    }
    /**
     * Navigate to target page by given number.
     *
     * @param  {number} pageNo - Defines page number.
     * @returns {void}
     */
    goToPage(pageNo) {
        if (this.checkGoToPage(pageNo)) {
            this.currentPage = pageNo;
            this.dataBind();
        }
    }
    /**
     * @param {number} pageSize - specifies the pagesize
     * @returns {void}
     * @hidden
     */
    setPageSize(pageSize) {
        this.pageSize = pageSize;
        this.dataBind();
    }
    checkpagesizes() {
        if (this.pageSizes === true || this.pageSizes.length) {
            return true;
        }
        return false;
    }
    checkGoToPage(newPageNo, oldPageNo) {
        if (newPageNo !== this.currentPage) {
            this.previousPageNo = this.currentPage;
        }
        if (!isNullOrUndefined(oldPageNo)) {
            this.previousPageNo = oldPageNo;
        }
        if (this.previousPageNo !== newPageNo && (newPageNo >= 1 && newPageNo <= this.totalPages)) {
            return true;
        }
        return false;
    }
    currentPageChanged(newProp, oldProp) {
        if (this.enableQueryString) {
            this.updateQueryString(this.currentPage);
        }
        if (newProp.currentPage !== oldProp.currentPage || newProp.pageSize !== oldProp.pageSize) {
            const args = {
                currentPage: this.currentPage,
                newProp: newProp, oldProp: oldProp, cancel: false
            };
            this.trigger('click', args);
            if (!args.cancel) {
                this.refresh();
            }
        }
    }
    pagerTemplate() {
        if (this.isReactTemplate() && this.hasParent) {
            return;
        }
        let result;
        this.element.classList.add('e-pagertemplate');
        this.compile(this.template);
        const data = {
            currentPage: this.currentPage, pageSize: this.pageSize, pageCount: this.pageCount,
            totalRecordsCount: this.totalRecordsCount, totalPages: this.totalPages
        };
        const tempId = this.element.parentElement.id + '_template';
        if (this.isReactTemplate() && !this.isVue) {
            this.getPagerTemplate()(data, this, 'template', tempId, null, null, this.element);
            this.renderReactTemplates();
        }
        else {
            result = this.isVue ? this.getPagerTemplate()(data, this, 'template') : this.getPagerTemplate()(data);
            appendChildren(this.element, result);
        }
    }
    /**
     * @returns {void}
     * @hidden
     */
    updateTotalPages() {
        this.totalPages = this.isAllPage ? 1 : (this.totalRecordsCount % this.pageSize === 0) ? (this.totalRecordsCount / this.pageSize) :
            (parseInt((this.totalRecordsCount / this.pageSize).toString(), 10) + 1);
    }
    /**
     * @returns {Function} returns the function
     * @hidden
     */
    getPagerTemplate() {
        return this.templateFn;
    }
    /**
     * @param {string} template - specifies the template
     * @returns {Function} returns the function
     * @hidden
     */
    compile(template) {
        if (template) {
            try {
                if (document.querySelectorAll(template).length) {
                    this.templateFn = templateCompiler(document.querySelector(template).innerHTML.trim());
                }
            }
            catch (e) {
                this.templateFn = templateCompiler(template);
            }
        }
        return undefined;
    }
    /**
     * Refreshes page count, pager information and external message.
     *
     * @returns {void}
     */
    refresh() {
        if (this.template) {
            if (this.isReactTemplate()) {
                this.updateTotalPages();
                this.notify(this.pageRefresh, {});
            }
            else {
                this.element.innerHTML = '';
                this.updateTotalPages();
                this.pagerTemplate();
            }
        }
        else {
            this.updateRTL();
            const focusedTabIndexElement = this.getFocusedTabindexElement();
            this.containerModule.refresh();
            this.removeTabindexLastElements();
            if (focusedTabIndexElement && focusedTabIndexElement.classList.contains('e-disable')) {
                if (this.checkFocusInAdaptiveMode(focusedTabIndexElement)) {
                    this.changeFocusInAdaptiveMode(focusedTabIndexElement);
                }
                else {
                    this.setPagerFocusForActiveElement();
                }
            }
            if (this.enablePagerMessage) {
                this.pagerMessageModule.refresh();
            }
            if (this.pagerdropdownModule) {
                this.pagerdropdownModule.refresh();
            }
            if (this.enableExternalMessage && this.externalMessageModule) {
                this.externalMessageModule.refresh();
            }
            this.setTabIndexForFocusLastElement();
        }
    }
    updateRTL() {
        if (this.enableRtl) {
            this.element.classList.add('e-rtl');
        }
        else {
            this.element.classList.remove('e-rtl');
        }
    }
    initLocalization() {
        this.localeObj = new L10n(this.getModuleName(), this.defaultConstants, this.locale);
    }
    updateQueryString(value) {
        const updatedUrl = this.getUpdatedURL(window.location.href, 'page', value.toString());
        window.history.pushState({ path: updatedUrl }, '', updatedUrl);
    }
    getUpdatedURL(uri, key, value) {
        const regx = new RegExp('([?|&])' + key + '=.*?(&|#|$)', 'i');
        if (uri.match(regx)) {
            return uri.replace(regx, '$1' + key + '=' + value + '$2');
        }
        else {
            let hash = '';
            if (uri.indexOf('#') !== -1) {
                hash = uri.replace(/.*#/, '#');
                uri = uri.replace(/#.*/, '');
            }
            return uri + (uri.indexOf('?') !== -1 ? '&' : '?') + key + '=' + value + hash;
        }
    }
    renderFirstPrevDivForDevice() {
        this.element.appendChild(createElement('div', {
            className: 'e-mfirst e-icons e-icon-first',
            attrs: { title: this.getLocalizedLabel('firstPageTooltip'), tabindex: '-1' }
        }));
        this.element.appendChild(createElement('div', {
            className: 'e-mprev e-icons e-icon-prev',
            attrs: { title: this.getLocalizedLabel('previousPageTooltip'), tabindex: '-1' }
        }));
    }
    renderNextLastDivForDevice() {
        this.element.appendChild(createElement('div', {
            className: 'e-mnext e-icons e-icon-next',
            attrs: { title: this.getLocalizedLabel('nextPageTooltip'), tabindex: '-1' }
        }));
        this.element.appendChild(createElement('div', {
            className: 'e-mlast e-icons e-icon-last',
            attrs: { title: this.getLocalizedLabel('lastPageTooltip'), tabindex: '-1' }
        }));
    }
    addAriaLabel() {
        const classList = ['.e-mfirst', '.e-mprev', '.e-mnext', '.e-mlast'];
        if (!Browser.isDevice) {
            for (let i = 0; i < classList.length; i++) {
                const element = this.element.querySelector(classList[i]);
                element.setAttribute('aria-label', element.getAttribute('title'));
            }
        }
    }
    isReactTemplate() {
        return (this.isReact || this.isVue) && this.template && typeof (this.template) !== 'string';
    }
};
__decorate([
    Property(false)
], Pager.prototype, "enableQueryString", void 0);
__decorate([
    Property(false)
], Pager.prototype, "enableExternalMessage", void 0);
__decorate([
    Property(true)
], Pager.prototype, "enablePagerMessage", void 0);
__decorate([
    Property(12)
], Pager.prototype, "pageSize", void 0);
__decorate([
    Property(10)
], Pager.prototype, "pageCount", void 0);
__decorate([
    Property(1)
], Pager.prototype, "currentPage", void 0);
__decorate([
    Property()
], Pager.prototype, "totalRecordsCount", void 0);
__decorate([
    Property()
], Pager.prototype, "externalMessage", void 0);
__decorate([
    Property(false)
], Pager.prototype, "pageSizes", void 0);
__decorate([
    Property()
], Pager.prototype, "template", void 0);
__decorate([
    Property('')
], Pager.prototype, "customText", void 0);
__decorate([
    Event()
], Pager.prototype, "click", void 0);
__decorate([
    Property('')
], Pager.prototype, "cssClass", void 0);
__decorate([
    Event()
], Pager.prototype, "dropDownChanged", void 0);
__decorate([
    Event()
], Pager.prototype, "created", void 0);
Pager = __decorate([
    NotifyPropertyChanges
], Pager);
export { Pager };
