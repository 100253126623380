import { isNullOrUndefined, getEnumValue } from '@syncfusion/ej2-base';
import { CellType } from '../base/enum';
/**
 * CellRendererFactory
 *
 * @hidden
 */
export class CellRendererFactory {
    constructor() {
        this.cellRenderMap = {};
    }
    addCellRenderer(name, type) {
        name = typeof name === 'string' ? name : getEnumValue(CellType, name);
        if (isNullOrUndefined(this.cellRenderMap[name])) {
            this.cellRenderMap[name] = type;
        }
    }
    getCellRenderer(name) {
        name = typeof name === 'string' ? name : getEnumValue(CellType, name);
        if (isNullOrUndefined(this.cellRenderMap[name])) {
            // eslint-disable-next-line no-throw-literal
            throw `The cellRenderer ${name} is not found`;
        }
        else {
            return this.cellRenderMap[name];
        }
    }
}
