import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { appendChildren } from '../base/util';
import { CellRenderer } from './cell-renderer';
import { refreshAggregateCell } from '../base/constant';
/**
 * SummaryCellRenderer class which responsible for building summary cell content.
 *
 * @hidden
 */
export class SummaryCellRenderer extends CellRenderer {
    constructor() {
        super(...arguments);
        this.element = this.parent
            .createElement('TD', { className: 'e-summarycell', attrs: { role: 'gridcell', tabindex: '-1' } });
    }
    getValue(field, data, column) {
        const key = !isNullOrUndefined(column.type) ?
            column.field + ' - ' + (typeof column.type === 'string' ? column.type.toLowerCase() : '') : column.columnName;
        return data[column.columnName] ? data[column.columnName][key] : '';
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    evaluate(node, cell, data, attributes) {
        const column = cell.column;
        this.parent.on(refreshAggregateCell, this.refreshWithAggregate(node, cell), this);
        if (!(column.footerTemplate || column.groupFooterTemplate || column.groupCaptionTemplate)) {
            if (this.parent.rowRenderingMode === 'Vertical') {
                node.style.display = 'none';
            }
            return true;
        }
        else {
            if (this.parent.rowRenderingMode === 'Vertical') {
                node.classList.add('e-lastsummarycell');
            }
        }
        const tempObj = column.getTemplate(cell.cellType);
        const tempID = '';
        const gColumn = this.parent.getColumnByField(data[column.columnName].field);
        if (!isNullOrUndefined(gColumn)) {
            data[column.columnName].headerText = gColumn.headerText;
            if (gColumn.isForeignColumn()) {
                const fData = gColumn.columnData.filter((e) => {
                    return e[gColumn.foreignKeyField] === data[column.columnName].key;
                })[0];
                if (fData) {
                    data[column.columnName].foreignKey = fData[gColumn.foreignKeyValue];
                }
            }
        }
        const isReactCompiler = this.parent.isReact && (column.footerTemplate ?
            typeof (column.footerTemplate) !== 'string' : column.groupFooterTemplate ? typeof (column.groupFooterTemplate) !== 'string'
            : column.groupCaptionTemplate ? typeof (column.groupCaptionTemplate) !== 'string' : false);
        if (isReactCompiler) {
            const prop = data[column.columnName];
            if (tempObj.property === 'groupCaptionTemplate' || tempObj.property === 'groupFooterTemplate') {
                const groupKey = 'groupKey';
                const key = 'key';
                prop[groupKey] = prop[key];
            }
            tempObj.fn(prop, this.parent, tempObj.property, tempID, null, null, node);
            this.parent.renderTemplates();
        }
        else {
            appendChildren(node, tempObj.fn(data[column.columnName], this.parent, tempObj.property, tempID));
        }
        return false;
    }
    refreshWithAggregate(node, cell) {
        const cellNode = cell;
        return (args) => {
            const cell = cellNode;
            const field = cell.column.columnName ? cell.column.columnName : null;
            const curCell = (!isNullOrUndefined(field) ? args.cells.filter((cell) => cell.column.columnName === field)[0] : null);
            if (node.parentElement && node.parentElement.getAttribute('data-uid') === args.dataUid && field &&
                field === curCell.column.columnName) {
                this.refreshTD(node, curCell, args.data);
            }
        };
    }
}
