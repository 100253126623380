/** @hidden */
export const created = 'create';
/** @hidden */
export const destroyed = 'destroy';
/** @hidden */
export const load = 'load';
/** @hidden */
export const rowDataBound = 'rowDataBound';
/** @hidden */
export const queryCellInfo = 'queryCellInfo';
/** @hidden */
export const headerCellInfo = 'headerCellInfo';
/** @hidden */
export const actionBegin = 'actionBegin';
/** @hidden */
export const actionComplete = 'actionComplete';
/** @hidden */
export const actionFailure = 'actionFailure';
/** @hidden */
export const dataBound = 'dataBound';
/** @hidden */
export const rowSelecting = 'rowSelecting';
/** @hidden */
export const rowSelected = 'rowSelected';
/** @hidden */
export const rowDeselecting = 'rowDeselecting';
/** @hidden */
export const rowDeselected = 'rowDeselected';
/** @hidden */
export const cellSelecting = 'cellSelecting';
/** @hidden */
export const cellSelected = 'cellSelected';
/** @hidden */
export const cellDeselecting = 'cellDeselecting';
/** @hidden */
export const cellDeselected = 'cellDeselected';
/** @hidden */
export const columnSelecting = 'columnSelecting';
/** @hidden */
export const columnSelected = 'columnSelected';
/** @hidden */
export const columnDeselecting = 'columnDeselecting';
/** @hidden */
export const columnDeselected = 'columnDeselected';
/** @hidden */
export const columnDragStart = 'columnDragStart';
/** @hidden */
export const columnDrag = 'columnDrag';
/** @hidden */
export const columnDrop = 'columnDrop';
/** @hidden */
export const rowDragStartHelper = 'rowDragStartHelper';
/** @hidden */
export const rowDragStart = 'rowDragStart';
/** @hidden */
export const rowDrag = 'rowDrag';
/** @hidden */
export const rowDrop = 'rowDrop';
/** @hidden */
export const beforePrint = 'beforePrint';
/** @hidden */
export const printComplete = 'printComplete';
/** @hidden */
export const detailDataBound = 'detailDataBound';
/** @hidden */
export const toolbarClick = 'toolbarClick';
/** @hidden */
export const batchAdd = 'batchAdd';
/** @hidden */
export const batchCancel = 'batchCancel';
/** @hidden */
export const batchDelete = 'batchDelete';
/** @hidden */
export const beforeBatchAdd = 'beforeBatchAdd';
/** @hidden */
export const beforeBatchDelete = 'beforeBatchDelete';
/** @hidden */
export const beforeBatchSave = 'beforeBatchSave';
/** @hidden */
export const beginEdit = 'beginEdit';
/** @hidden */
export const cellEdit = 'cellEdit';
/** @hidden */
export const cellSave = 'cellSave';
/** @hidden */
export const cellSaved = 'cellSaved';
/** @hidden */
export const endAdd = 'endAdd';
/** @hidden */
export const endDelete = 'endDelete';
/** @hidden */
export const endEdit = 'endEdit';
/** @hidden */
export const recordDoubleClick = 'recordDoubleClick';
/** @hidden */
export const recordClick = 'recordClick';
/** @hidden */
export const beforeDataBound = 'beforeDataBound';
/** @hidden */
export const beforeOpenColumnChooser = 'beforeOpenColumnChooser';
/** @hidden */
export const beforeOpenAdaptiveDialog = 'beforeOpenAdaptiveDialog';
/** @hidden */
export const resizeStart = 'resizeStart';
/** @hidden */
export const onResize = 'resizing';
/** @hidden */
export const resizeStop = 'resizeStop';
/** @hidden */
export const checkBoxChange = 'checkBoxChange';
/** @hidden */
export const beforeCopy = 'beforeCopy';
/** @hidden */
export const beforePaste = 'beforePaste';
/** @hidden */
export const beforeAutoFill = 'beforeAutoFill';
/** @hidden */
export const filterChoiceRequest = 'filterchoicerequest';
/** @hidden */
export const filterAfterOpen = 'filterafteropen';
/** @hidden */
export const filterBeforeOpen = 'filterbeforeopen';
/** @hidden */
export const filterSearchBegin = 'filtersearchbegin';
/** @hidden */
export const commandClick = 'commandClick';
/** @hidden */
export const exportGroupCaption = 'exportGroupCaption';
/** @hidden */
export const lazyLoadGroupExpand = 'lazyLoadGroupExpand';
/** @hidden */
export const lazyLoadGroupCollapse = 'lazyLoadGroupCollapse';
/**
 * Specifies grid internal events
 */
/** @hidden */
export const initialLoad = 'initial-load';
/** @hidden */
export const initialEnd = 'initial-end';
/** @hidden */
export const dataReady = 'data-ready';
/** @hidden */
export const contentReady = 'content-ready';
/** @hidden */
export const uiUpdate = 'ui-update';
/** @hidden */
export const onEmpty = 'on-empty';
/** @hidden */
export const inBoundModelChanged = 'inbound-model-changed';
/** @hidden */
export const modelChanged = 'model-changed';
/** @hidden */
export const colGroupRefresh = 'colgroup-refresh';
/** @hidden */
export const headerRefreshed = 'header-refreshed';
/** @hidden */
export const pageBegin = 'paging-begin';
/** @hidden */
export const pageComplete = 'paging-complete';
/** @hidden */
export const sortBegin = 'sorting-begin';
/** @hidden */
export const sortComplete = 'sorting-complete';
/** @hidden */
export const filterBegin = 'filtering-begin';
/** @hidden */
export const filterComplete = 'filtering-complete';
/** @hidden */
export const searchBegin = 'searching-begin';
/** @hidden */
export const searchComplete = 'searching-complete';
/** @hidden */
export const reorderBegin = 'reorder-begin';
/** @hidden */
export const reorderComplete = 'reorder-complete';
/** @hidden */
export const rowDragAndDropBegin = 'rowdraganddrop-begin';
/** @hidden */
export const rowDragAndDropComplete = 'rowdraganddrop-complete';
/** @hidden */
export const groupBegin = 'grouping-begin';
/** @hidden */
export const groupComplete = 'grouping-complete';
/** @hidden */
export const ungroupBegin = 'ungrouping-begin';
/** @hidden */
export const ungroupComplete = 'ungrouping-complete';
/** @hidden */
export const groupAggregates = 'group-aggregates';
/** @hidden */
export const refreshFooterRenderer = 'refresh-footer-rendered';
/** @hidden */
export const refreshAggregateCell = 'refresh-aggregate-cell';
/** @hidden */
export const refreshAggregates = 'refresh-aggregates';
/** @hidden */
export const rowSelectionBegin = 'rowselecting';
/** @hidden */
export const rowSelectionComplete = 'rowselected';
/** @hidden */
export const columnSelectionBegin = 'columnselecting';
/** @hidden */
export const columnSelectionComplete = 'columnselected';
/** @hidden */
export const cellSelectionBegin = 'cellselecting';
/** @hidden */
export const cellSelectionComplete = 'cellselected';
/** @hidden */
export const beforeCellFocused = 'beforecellfocused';
/** @hidden */
export const cellFocused = 'cellfocused';
/** @hidden */
export const keyPressed = 'key-pressed';
/** @hidden */
export const click = 'click';
/** @hidden */
export const destroy = 'destroy';
/** @hidden */
export const columnVisibilityChanged = 'column-visible-changed';
/** @hidden */
export const scroll = 'scroll';
/** @hidden */
export const columnWidthChanged = 'column-width-changed';
/** @hidden */
export const columnPositionChanged = 'column-position-changed';
/** @hidden */
export const rowDragAndDrop = 'row-drag-and-drop';
/** @hidden */
export const rowsAdded = 'rows-added';
/** @hidden */
export const rowsRemoved = 'rows-removed';
/** @hidden */
export const columnDragStop = 'column-drag-stop';
/** @hidden */
export const headerDrop = 'header-drop';
/** @hidden */
export const dataSourceModified = 'datasource-modified';
/** @hidden */
export const refreshComplete = 'refresh-complete';
/** @hidden */
export const refreshVirtualBlock = 'refresh-virtual-block';
/** @hidden */
export const dblclick = 'dblclick';
/** @hidden */
export const toolbarRefresh = 'toolbar-refresh';
/** @hidden */
export const bulkSave = 'bulk-save';
/** @hidden */
export const autoCol = 'auto-col';
/** @hidden */
export const tooltipDestroy = 'tooltip-destroy';
/** @hidden */
export const updateData = 'update-data';
/** @hidden */
export const editBegin = 'edit-begin';
/** @hidden */
export const editComplete = 'edit-complete';
/** @hidden */
export const addBegin = 'add-begin';
/** @hidden */
export const addComplete = 'add-complete';
/** @hidden */
export const saveComplete = 'save-complete';
/** @hidden */
export const deleteBegin = 'delete-begin';
/** @hidden */
export const deleteComplete = 'delete-complete';
/** @hidden */
export const preventBatch = 'prevent-batch';
/** @hidden */
export const dialogDestroy = 'dialog-destroy';
/** @hidden */
export const crudAction = 'crud-Action';
/** @hidden */
export const addDeleteAction = 'add-delete-Action';
/** @hidden */
export const destroyForm = 'destroy-form';
/** @hidden */
export const doubleTap = 'double-tap';
/** @hidden */
export const beforeExcelExport = 'beforeExcelExport';
/** @hidden */
export const excelExportComplete = 'excelExportComplete';
/** @hidden */
export const excelQueryCellInfo = 'excelQueryCellInfo';
/** @hidden */
export const excelHeaderQueryCellInfo = 'excelHeaderQueryCellInfo';
/** @hidden */
export const exportDetailDataBound = 'exportDetailDataBound';
/** @hidden */
export const beforePdfExport = 'beforePdfExport';
/** @hidden */
export const pdfExportComplete = 'pdfExportComplete';
/** @hidden */
export const pdfQueryCellInfo = 'pdfQueryCellInfo';
/** @hidden */
export const pdfHeaderQueryCellInfo = 'pdfHeaderQueryCellInfo';
/** @hidden */
export const accessPredicate = 'access-predicate';
/** @hidden */
export const contextMenuClick = 'contextMenuClick';
/** @hidden */
export const freezeRender = 'freezerender';
/** @hidden */
export const freezeRefresh = 'freezerefresh';
/** @hidden */
export const contextMenuOpen = 'contextMenuOpen';
/** @hidden */
export const columnMenuClick = 'columnMenuClick';
/** @hidden */
export const columnMenuOpen = 'columnMenuOpen';
/** @hidden */
export const filterOpen = 'filterOpen';
/** @hidden */
export const filterDialogCreated = 'filterDialogCreated';
/** @hidden */
export const filterMenuClose = 'filter-menu-close';
/** @hidden */
export const initForeignKeyColumn = 'initForeignKeyColumn';
/** @hidden */
export const getForeignKeyData = 'getForeignKeyData';
/** @hidden */
export const generateQuery = 'generateQuery';
/** @hidden */
export const showEmptyGrid = 'showEmptyGrid';
/** @hidden */
export const foreignKeyData = 'foreignKeyData';
/** @hidden */
export const columnDataStateChange = 'columnDataStateChange';
/** @hidden */
export const dataStateChange = 'dataStateChange';
/** @hidden */
export const dataSourceChanged = 'dataSourceChanged';
/** @hidden */
export const rtlUpdated = 'rtl-updated';
/** @hidden */
export const beforeFragAppend = 'beforeFragAppend';
/** @hidden */
export const frozenHeight = 'frozenHeight';
/** @hidden */
export const textWrapRefresh = 'textWrapRefresh';
/** @hidden */
export const recordAdded = 'recordAdded';
/** @hidden */
export const cancelBegin = 'cancel-Begin';
/** @hidden */
export const editNextValCell = 'editNextValCell';
/** @hidden */
export const hierarchyPrint = 'hierarchyprint';
/** @hidden */
export const expandChildGrid = 'expandchildgrid';
/** @hidden */
export const printGridInit = 'printGrid-Init';
/** @hidden */
export const exportRowDataBound = 'export-RowDataBound';
/** @hidden */
export const exportDataBound = 'export-DataBound';
/** @hidden */
export const rowPositionChanged = 'row-position-changed';
/** @hidden */
export const columnChooserOpened = 'columnChooserOpened';
/** @hidden */
export const batchForm = 'batchedit-form';
/** @hidden */
export const beforeStartEdit = 'edit-form';
/** @hidden */
export const beforeBatchCancel = 'before-batch-cancel';
/** @hidden */
export const batchEditFormRendered = 'batcheditform-rendered';
/** @hidden */
export const partialRefresh = 'partial-refresh';
/** @hidden */
export const beforeCustomFilterOpen = 'beforeCustomFilterOpen';
/** @hidden */
export const selectVirtualRow = 'select-virtual-Row';
/** @hidden */
export const columnsPrepared = 'columns-prepared';
/** @hidden */
export const cBoxFltrBegin = 'cbox-filter-begin';
/** @hidden */
export const cBoxFltrComplete = 'cbox-filter-complete';
/** @hidden */
export const fltrPrevent = 'filter-Prevent';
/** @hidden */
export const beforeFltrcMenuOpen = 'before-filter-cmenu-open';
/** @hidden */
export const valCustomPlacement = 'validation-custom-placement';
/** @hidden */
export const filterCboxValue = 'filter-cbox-value';
/** @hidden */
export const componentRendered = 'component-rendered';
/** @hidden */
export const restoreFocus = 'restore-Focus';
/** @hidden */
export const detailStateChange = 'detail-state-change';
/** @hidden */
export const detailIndentCellInfo = 'detail-indentcell-info';
/** @hidden */
export const virtaulKeyHandler = 'virtaul-key-handler';
/** @hidden */
export const virtaulCellFocus = 'virtaul-cell-focus';
/** @hidden */
export const virtualScrollEditActionBegin = 'virtual-scroll-edit-action-begin';
/** @hidden */
export const virtualScrollEditSuccess = 'virtual-scroll-edit-success';
/** @hidden */
export const virtualScrollEditCancel = 'virtual-scroll-edit-cancel';
/** @hidden */
export const virtualScrollEdit = 'virtual-scroll-edit';
/** @hidden */
export const refreshVirtualCache = 'refresh-virtual-cache';
/** @hidden */
export const editReset = 'edit-reset';
/** @hidden */
export const virtualScrollAddActionBegin = 'virtual-scroll-add-action-begin';
/** @hidden */
export const getVirtualData = 'get-virtual-data';
/** @hidden */
export const refreshInfiniteModeBlocks = 'refresh-infinite-mode-blocks';
/** @hidden */
export const resetInfiniteBlocks = 'reset-infinite-blocks';
/** @hidden */
export const infiniteScrollHandler = 'infinite-scroll-handler';
/** @hidden */
export const infinitePageQuery = 'infinite-page-query';
/** @hidden */
export const infiniteShowHide = 'infinite-show-hide';
/** @hidden */
export const appendInfiniteContent = 'append-infinite-content';
/** @hidden */
export const removeInfiniteRows = 'remove-infinite-rows';
/** @hidden */
export const setInfiniteCache = 'set-infinite-cache';
/** @hidden */
export const infiniteEditHandler = 'infinite-edit-handler';
/** @hidden */
export const initialCollapse = 'initial-collapse';
/** @hidden */
export const getAggregateQuery = 'get-aggregate-query';
/** @hidden */
export const closeFilterDialog = 'close-filter-dialog';
/** @hidden */
export const columnChooserCancelBtnClick = 'columnChooserCancelBtnClick';
/** @hidden */
export const getFilterBarOperator = 'get-filterbar-operator';
/** @hidden */
export const resetColumns = 'reset-columns';
/** @hidden */
export const pdfAggregateQueryCellInfo = 'pdfAggregateQueryCellInfo';
/** @hidden */
export const excelAggregateQueryCellInfo = 'excelAggregateQueryCellInfo';
/** @hidden */
export const setGroupCache = 'group-cache';
/** @hidden */
export const lazyLoadScrollHandler = 'lazy-load-scroll-handler';
/** @hidden */
export const groupCollapse = 'group-collapse';
/** @hidden */
export const beforeCheckboxRenderer = 'beforeCheckboxRenderer';
/** @hidden */
export const refreshHandlers = 'refreshResizeHandlers';
/** @hidden */
export const refreshFrozenColumns = 'refresh-frozen-columns';
/** @hidden */
export const setReorderDestinationElement = 'set-reorder-destination-element';
/** @hidden */
export const refreshVirtualFrozenHeight = 'refresh-virtual-frozen-height';
/** @hidden */
export const setFreezeSelection = 'set-freeze-selection';
/** @hidden */
export const setInfiniteFrozenHeight = 'set-infinite-frozen-height';
/** @hidden */
export const setInfiniteColFrozenHeight = 'set-infinite-col-frozen-height';
/** @hidden */
export const beforeRefreshOnDataChange = 'before-refresh-on-data-change';
/** @hidden */
export const immutableBatchCancel = 'immutable-batch-cancel';
/** @hidden */
export const refreshVirtualFrozenRows = 'refresh-virtual-frozenrows';
/** @hidden */
export const checkScrollReset = 'check-scroll-reset';
/** @hidden */
export const refreshFrozenHeight = 'refresh-frozen-height';
/** @hidden */
export const setHeightToFrozenElement = 'set-height-to-frozen-element';
/** @hidden */
export const preventFrozenScrollRefresh = 'prevent-frozen-scroll-refresh';
/** @hidden */
export const nextCellIndex = 'next-cell-index';
/** @hidden */
export const refreshInfiniteCurrentViewData = 'refresh-infinite-current-view-data';
/** @hidden */
export const infiniteCrudCancel = 'infinite-crud-cancel';
/** @hidden */
export const filterDialogClose = 'filter-dialog-close';
/** @hidden */
export const refreshCustomFilterOkBtn = 'refresh-cutsom-filter-ok-button';
/** @hidden */
export const refreshCustomFilterClearBtn = 'refresh-cutsom-filter-clear-button';
/** @hidden */
export const renderResponsiveCmenu = 'render-responsive-cmenu';
/** @hidden */
export const filterCmenuSelect = 'filter-cmenu-select';
/** @hidden */
export const customFilterClose = 'custom-filter-close';
/** @hidden */
export const setFullScreenDialog = 'set-fullscreen-dialog';
/** @hidden */
export const refreshExpandandCollapse = 'refresh-Expand-and-Collapse';
/** @hidden */
export const rowModeChange = 'row-mode-change';
/** @hidden */
export const enterKeyHandler = 'enter-key-handler';
/** @hidden */
export const refreshVirtualMaxPage = 'refresh-virtual-max-page';
/** @hidden */
export const setVirtualPageQuery = 'set-virtual-page-query';
/** @hidden */
export const selectRowOnContextOpen = 'select-row-on-context-open';
/** @hidden */
export const pagerRefresh = 'pager-refresh';
/** @hidden */
export const closeInline = 'closeinline';
/** @hidden */
export const closeBatch = 'closebatch';
/** @hidden */
export const closeEdit = 'close-edit';
/** @hidden */
export const resetVirtualFocus = 'reset-virtual-focus';
/** @hidden */
export const afterContentRender = 'after-content-renderer';
/** @hidden */
export const refreshVirtualEditFormCells = 'refresh-virtual-editform-cells';
/** @hidden */
export const scrollToEdit = 'scroll-to-edit';
/** @hidden */
export const beforeCheckboxRendererQuery = 'before-checkbox-renderer-query';
/** @hidden */
export const createVirtualValidationForm = 'create-virtual-validation-form';
/** @hidden */
export const validateVirtualForm = 'validate-virtual-form';
/** @hidden */
export const destroyChildGrid = 'destroy-child-grid';
/** @hidden */
export const stickyScrollComplete = 'sticky-scroll-complete';
/** @hidden */
export const captionActionComplete = 'infinite-group-collapse';
/** @hidden */
export const refreshInfinitePersistSelection = 'refresh-infinite-persist-selection';
/** @hidden */
export const refreshInfiniteEditrowindex = 'refresh-infinite-editrowindex';
/** @hidden */
export const afterFilterColumnMenuClose = 'after-filter-column-menu-close';
/** @hidden */
export const beforeCheckboxfilterRenderer = 'beforeCheckboxfilterRenderer';
/** @hidden */
export const commandColumnDestroy = 'commandColumnDestroy';
