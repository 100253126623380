import { Draggable, Droppable, EventHandler } from '@syncfusion/ej2-base';
import { createElement, closest, remove, classList, addClass, removeClass } from '@syncfusion/ej2-base';
import { isNullOrUndefined, extend } from '@syncfusion/ej2-base';
import { parentsUntil, isActionPrevent, isGroupAdaptive, updatecloneRow, getComplexFieldID, isComplexField } from '../base/util';
import * as events from '../base/constant';
import { AriaService } from '../services/aria-service';
import { GroupModelGenerator } from '../services/group-model-generator';
import { DataUtil } from '@syncfusion/ej2-data';
import * as literals from '../base/string-literals';
// eslint-disable-next-line valid-jsdoc
/**
 *
 * The `Group` module is used to handle group action.
 */
export class Group {
    /**
     * Constructor for Grid group module
     *
     * @param {IGrid} parent - specifies the IGrid
     * @param {GroupSettingsModel} groupSettings - specifies the GroupSettingsModel
     * @param {string[]} sortedColumns - specifies the sortedColumns
     * @param {ServiceLocator} serviceLocator - specifies the serviceLocator
     * @hidden
     */
    constructor(parent, groupSettings, sortedColumns, serviceLocator) {
        //Internal variables
        this.sortRequired = true;
        /** @hidden */
        this.groupSortFocus = false;
        /** @hidden */
        this.groupCancelFocus = false;
        this.isAppliedGroup = false;
        this.isAppliedUnGroup = false;
        this.reorderingColumns = [];
        this.visualElement = createElement('div', {
            className: 'e-cloneproperties e-dragclone e-gdclone',
            styles: 'line-height:23px', attrs: { action: 'grouping' }
        });
        this.helper = (e) => {
            const gObj = this.parent;
            const target = e.sender.target;
            const element = target.classList.contains('e-groupheadercell') ? target :
                parentsUntil(target, 'e-groupheadercell');
            if (!element || (!target.classList.contains('e-drag') && this.groupSettings.allowReordering)) {
                return false;
            }
            this.column = gObj.getColumnByField(element.firstElementChild.getAttribute('ej-mappingname'));
            this.visualElement.textContent = element.textContent;
            this.visualElement.style.width = element.offsetWidth + 2 + 'px';
            this.visualElement.style.height = element.offsetHeight + 2 + 'px';
            this.visualElement.setAttribute('e-mappinguid', this.column.uid);
            gObj.element.appendChild(this.visualElement);
            return this.visualElement;
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.dragStart = (e) => {
            this.parent.element.classList.add('e-ungroupdrag');
        };
        this.drag = (e) => {
            if (this.groupSettings.allowReordering) {
                this.animateDropper(e);
            }
            const target = e.target;
            const cloneElement = this.parent.element.querySelector('.e-cloneproperties');
            this.parent.trigger(events.columnDrag, { target: target, draggableType: 'headercell', column: this.column });
            if (!this.groupSettings.allowReordering) {
                classList(cloneElement, ['e-defaultcur'], ['e-notallowedcur']);
                if (!(parentsUntil(target, literals.gridContent) || parentsUntil(target, 'e-headercell'))) {
                    classList(cloneElement, ['e-notallowedcur'], ['e-defaultcur']);
                }
            }
        };
        this.dragStop = (e) => {
            this.parent.element.classList.remove('e-ungroupdrag');
            const preventDrop = !(parentsUntil(e.target, literals.gridContent) || parentsUntil(e.target, 'e-gridheader'));
            if (this.groupSettings.allowReordering && preventDrop) {
                remove(e.helper);
                if (parentsUntil(e.target, 'e-groupdroparea')) {
                    this.rearrangeGroup();
                }
                else if (!(parentsUntil(e.target, 'e-grid'))) {
                    const field = this.parent.getColumnByUid(e.helper.getAttribute('e-mappinguid')).field;
                    if (this.groupSettings.columns.indexOf(field) !== -1) {
                        this.ungroupColumn(field);
                    }
                }
                return;
            }
            else if (preventDrop) {
                remove(e.helper);
                return;
            }
        };
        this.animateDropper = (e) => {
            const uid = this.parent.element.querySelector('.e-cloneproperties').getAttribute('e-mappinguid');
            const dragField = this.parent.getColumnByUid(uid).field;
            const parent = parentsUntil(e.target, 'e-groupdroparea');
            const dropTarget = parentsUntil(e.target, 'e-group-animator');
            const grouped = [].slice.call(this.element.getElementsByClassName('e-groupheadercell'))
                .map((e) => e.querySelector('div').getAttribute('ej-mappingname'));
            const cols = JSON.parse(JSON.stringify(grouped));
            if (dropTarget || parent) {
                if (dropTarget) {
                    const dropField = dropTarget.querySelector('div[ej-mappingname]').getAttribute('ej-mappingname');
                    const dropIndex = +(dropTarget.getAttribute('index'));
                    if (dropField !== dragField) {
                        const dragIndex = cols.indexOf(dragField);
                        if (dragIndex !== -1) {
                            cols.splice(dragIndex, 1);
                        }
                        const flag = dropIndex !== -1 && dragIndex === dropIndex;
                        cols.splice(dropIndex + (flag ? 1 : 0), 0, dragField);
                    }
                }
                else if (parent && cols.indexOf(dragField) === -1) {
                    cols.push(dragField);
                }
                this.element.innerHTML = '';
                if (cols.length && !this.element.classList.contains('e-grouped')) {
                    this.element.classList.add('e-grouped');
                }
                this.reorderingColumns = cols;
                for (let c = 0; c < cols.length; c++) {
                    this.addColToGroupDrop(cols[c]);
                }
            }
            else {
                this.addLabel();
                this.removeColFromGroupDrop(dragField);
            }
        };
        this.drop = (e) => {
            const gObj = this.parent;
            const column = gObj.getColumnByUid(e.droppedElement.getAttribute('e-mappinguid'));
            this.element.classList.remove('e-hover');
            remove(e.droppedElement);
            this.aria.setDropTarget(this.parent.element.querySelector('.e-groupdroparea'), false);
            this.aria.setGrabbed(this.parent.getHeaderTable().querySelector('[aria-grabbed=true]'), false);
            if (isNullOrUndefined(column) || column.allowGrouping === false ||
                parentsUntil(gObj.getColumnHeaderByUid(column.uid), 'e-grid').getAttribute('id') !==
                    gObj.element.getAttribute('id')) {
                this.parent.log('action_disabled_column', { moduleName: this.getModuleName(), columnName: column.headerText });
                return;
            }
            this.groupColumn(column.field);
        };
        this.contentRefresh = true;
        this.aria = new AriaService();
        this.parent = parent;
        this.groupSettings = groupSettings;
        this.serviceLocator = serviceLocator;
        this.sortedColumns = sortedColumns;
        this.focus = serviceLocator.getService('focus');
        this.addEventListener();
        this.groupGenerator = new GroupModelGenerator(this.parent);
    }
    addLabel() {
        if (!this.element.getElementsByClassName('e-group-animator').length) {
            const dragLabel = this.l10n.getConstant('GroupDropArea');
            this.element.innerHTML = dragLabel;
            this.element.classList.remove('e-grouped');
        }
    }
    rearrangeGroup() {
        this.sortRequired = false;
        this.updateModel();
    }
    columnDrag(e) {
        if (this.groupSettings.allowReordering && e.column.allowGrouping) {
            this.animateDropper(e);
        }
        const cloneElement = this.parent.element.querySelector('.e-cloneproperties');
        classList(cloneElement, ['e-defaultcur'], ['e-notallowedcur']);
        if (!parentsUntil(e.target, 'e-groupdroparea') &&
            !(this.parent.allowReordering && parentsUntil(e.target, 'e-headercell'))) {
            classList(cloneElement, ['e-notallowedcur'], ['e-defaultcur']);
        }
        if (e.target.classList.contains('e-groupdroparea')) {
            this.element.classList.add('e-hover');
        }
        else {
            this.element.classList.remove('e-hover');
        }
    }
    columnDragStart(e) {
        if (e.target.classList.contains('e-stackedheadercell')) {
            return;
        }
        const dropArea = this.parent.element.querySelector('.e-groupdroparea');
        this.aria.setDropTarget(dropArea, e.column.allowGrouping);
        const element = e.target.classList.contains('e-headercell') ? e.target : parentsUntil(e.target, 'e-headercell');
        this.aria.setGrabbed(element, true, !e.column.allowGrouping);
    }
    columnDrop(e) {
        const gObj = this.parent;
        if (e.droppedElement.getAttribute('action') === 'grouping') {
            const column = gObj.getColumnByUid(e.droppedElement.getAttribute('e-mappinguid'));
            if (isNullOrUndefined(column) || column.allowGrouping === false ||
                parentsUntil(gObj.getColumnHeaderByUid(column.uid), 'e-grid').getAttribute('id') !==
                    gObj.element.getAttribute('id')) {
                return;
            }
            this.ungroupColumn(column.field);
        }
    }
    /**
     * @returns {void}
     * @hidden
     */
    addEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.on(events.uiUpdate, this.enableAfterRender, this);
        this.parent.on(events.groupComplete, this.onActionComplete, this);
        this.parent.on(events.ungroupComplete, this.onActionComplete, this);
        this.parent.on(events.inBoundModelChanged, this.onPropertyChanged, this);
        this.parent.on(events.click, this.clickHandler, this);
        this.parent.on(events.columnDrag, this.columnDrag, this);
        this.parent.on(events.columnDragStart, this.columnDragStart, this);
        this.parent.on(events.headerDrop, this.columnDrop, this);
        this.parent.on(events.columnDrop, this.columnDrop, this);
        this.parent.on(events.headerRefreshed, this.refreshSortIcons, this);
        this.parent.on(events.sortComplete, this.refreshSortIcons, this);
        this.parent.on(events.keyPressed, this.keyPressHandler, this);
        this.parent.on(events.contentReady, this.initialEnd, this);
        this.parent.on(events.onEmpty, this.initialEnd, this);
        this.parent.on(events.initialEnd, this.render, this);
        this.parent.on(events.groupAggregates, this.onGroupAggregates, this);
        this.parent.on(events.destroy, this.destroy, this);
        this.parent.on('group-expand-collapse', this.updateExpand, this);
        this.parent.on('persist-data-changed', this.initialEnd, this);
    }
    /**
     * @returns {void}
     * @hidden
     */
    removeEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.off(events.initialEnd, this.render);
        this.parent.off(events.uiUpdate, this.enableAfterRender);
        this.parent.off(events.groupComplete, this.onActionComplete);
        this.parent.off(events.ungroupComplete, this.onActionComplete);
        this.parent.off(events.inBoundModelChanged, this.onPropertyChanged);
        this.parent.off(events.click, this.clickHandler);
        this.parent.off(events.columnDrag, this.columnDrag);
        this.parent.off(events.columnDragStart, this.columnDragStart);
        this.parent.off(events.columnDrop, this.columnDrop);
        this.parent.off(events.headerDrop, this.columnDrop);
        this.parent.off(events.headerRefreshed, this.refreshSortIcons);
        this.parent.off(events.sortComplete, this.refreshSortIcons);
        this.parent.off(events.keyPressed, this.keyPressHandler);
        this.parent.off(events.groupAggregates, this.onGroupAggregates);
        this.parent.off(events.destroy, this.destroy);
        this.parent.off('group-expand-collapse', this.updateExpand);
    }
    initialEnd() {
        const gObj = this.parent;
        this.parent.off(events.contentReady, this.initialEnd);
        this.parent.off(events.onEmpty, this.initialEnd);
        if (this.parent.getColumns().length && this.groupSettings.columns.length) {
            this.contentRefresh = false;
            for (const col of gObj.groupSettings.columns) {
                this.groupColumn(col);
            }
            this.contentRefresh = true;
        }
    }
    keyPressHandler(e) {
        const gObj = this.parent;
        if (e.target && parentsUntil(e.target, 'e-groupheadercell') && (e.action === 'tab' || e.action === 'shiftTab')) {
            const focusableGroupedItems = this.getFocusableGroupedItems();
            if ((e.action === 'tab' && e.target === focusableGroupedItems[focusableGroupedItems.length - 1])
                || (e.action === 'shiftTab' && e.target === focusableGroupedItems[0])) {
                return;
            }
            for (let i = 0; i < focusableGroupedItems.length; i++) {
                if (e.target === focusableGroupedItems[i]) {
                    e.preventDefault();
                    const index = e.action === 'tab' ? i + 1 : i - 1;
                    focusableGroupedItems[index].focus();
                    return;
                }
            }
        }
        if (e.action !== 'ctrlSpace' && (!this.groupSettings.columns.length ||
            ['altDownArrow', 'altUpArrow', 'ctrlDownArrow', 'ctrlUpArrow', 'enter'].indexOf(e.action) === -1)) {
            return;
        }
        e.preventDefault();
        switch (e.action) {
            case 'altDownArrow':
            case 'altUpArrow':
                // eslint-disable-next-line no-case-declarations
                const selected = gObj.allowSelection ? gObj.getSelectedRowIndexes() : [];
                if (selected.length) {
                    const rows = gObj.getContentTable().querySelector(literals.tbody).children;
                    const dataRow = gObj.getDataRows()[selected[selected.length - 1]];
                    let grpRow;
                    for (let i = dataRow.rowIndex; i >= 0; i--) {
                        if (!rows[i].classList.contains(literals.row) && !rows[i].classList.contains('e-detailrow')) {
                            grpRow = rows[i];
                            break;
                        }
                    }
                    this.expandCollapseRows(grpRow.querySelector(e.action === 'altUpArrow' ?
                        '.e-recordplusexpand' : '.e-recordpluscollapse'));
                }
                break;
            case 'ctrlDownArrow':
                this.expandAll();
                break;
            case 'ctrlUpArrow':
                this.collapseAll();
                break;
            case 'enter':
                if (e.target.classList.contains('e-groupsort')) {
                    this.groupSortFocus = true;
                    this.applySortFromTarget(e.target);
                    break;
                }
                else if (e.target.classList.contains('e-ungroupbutton')) {
                    this.groupCancelFocus = true;
                    this.unGroupFromTarget(e.target);
                    break;
                }
                if (this.parent.isEdit || (closest(e.target, '#' + this.parent.element.id + '_searchbar') !== null) ||
                    parentsUntil(e.target, 'e-pager')) {
                    return;
                }
                // eslint-disable-next-line no-case-declarations
                let element = this.focus.getFocusedElement();
                if (element.classList.contains('e-icon-grightarrow') || element.classList.contains('e-icon-gdownarrow')) {
                    element = element.parentElement;
                }
                // eslint-disable-next-line no-case-declarations
                const row = element ? element.parentElement.querySelector('[class^="e-record"]') : null;
                if (!row) {
                    break;
                }
                this.expandCollapseRows(row);
                break;
            case 'ctrlSpace':
                // eslint-disable-next-line no-case-declarations
                const elem = gObj.focusModule.currentInfo.element;
                if (elem && elem.classList.contains('e-headercell')) {
                    const column = gObj.getColumnByUid(elem.firstElementChild.getAttribute('e-mappinguid'));
                    if (column.field && gObj.groupSettings.columns.indexOf(column.field) < 0) {
                        this.groupColumn(column.field);
                    }
                    else {
                        this.ungroupColumn(column.field);
                    }
                }
                break;
        }
    }
    /**
     * @returns {Element[]} - Return the focusable grouping items
     * @hidden */
    getFocusableGroupedItems() {
        const focusableGroupedItems = [];
        if (this.groupSettings.columns.length) {
            const focusableGroupedHeaderItems = this.element.querySelectorAll('.e-groupheadercell');
            for (let i = 0; i < focusableGroupedHeaderItems.length; i++) {
                focusableGroupedItems.push(focusableGroupedHeaderItems[i].querySelector('.e-grouptext'));
                focusableGroupedItems.push(focusableGroupedHeaderItems[i].querySelector('.e-groupsort'));
                focusableGroupedItems.push(focusableGroupedHeaderItems[i].querySelector('.e-ungroupbutton'));
            }
        }
        return focusableGroupedItems;
    }
    wireEvent() {
        EventHandler.add(this.element, 'focusin', this.onFocusIn, this);
        EventHandler.add(this.element, 'focusout', this.onFocusOut, this);
    }
    unWireEvent() {
        EventHandler.remove(this.element, 'focusin', this.onFocusIn);
        EventHandler.remove(this.element, 'focusout', this.onFocusOut);
    }
    onFocusIn(e) {
        if (this.parent.focusModule.currentInfo && this.parent.focusModule.currentInfo.element) {
            removeClass([this.parent.focusModule.currentInfo.element, this.parent.focusModule.currentInfo.elementToFocus], ['e-focused', 'e-focus']);
            this.parent.focusModule.currentInfo.element.tabIndex = -1;
        }
        this.addOrRemoveFocus(e);
    }
    onFocusOut(e) {
        this.addOrRemoveFocus(e);
    }
    addOrRemoveFocus(e) {
        if (e.target.classList.contains('e-groupdroparea') || e.target.classList.contains('e-grouptext')
            || e.target.classList.contains('e-groupsort')
            || e.target.classList.contains('e-ungroupbutton')) {
            const target = e.target.classList.contains('e-grouptext') ?
                e.target.parentElement.parentElement : e.target;
            if (e.type === 'focusin') {
                addClass([target], ['e-focused', 'e-focus']);
                target.tabIndex = 0;
            }
            else {
                removeClass([target], ['e-focused', 'e-focus']);
                target.tabIndex = -1;
            }
        }
    }
    clickHandler(e) {
        if (e.target.classList.contains('e-groupsort')) {
            this.groupSortFocus = true;
        }
        if (e.target.classList.contains('e-ungroupbutton')) {
            this.groupCancelFocus = true;
        }
        this.expandCollapseRows(e.target);
        this.applySortFromTarget(e.target);
        this.unGroupFromTarget(e.target);
        this.toogleGroupFromHeader(e.target);
    }
    unGroupFromTarget(target) {
        if (target.classList.contains('e-ungroupbutton')) {
            this.ungroupColumn(target.parentElement.getAttribute('ej-mappingname'));
        }
    }
    toogleGroupFromHeader(target) {
        if (this.groupSettings.showToggleButton) {
            if (target.classList.contains('e-grptogglebtn')) {
                if (target.classList.contains('e-toggleungroup')) {
                    this.ungroupColumn(this.parent.getColumnByUid(target.parentElement.getAttribute('e-mappinguid')).field);
                }
                else {
                    this.groupColumn(this.parent.getColumnByUid(target.parentElement.getAttribute('e-mappinguid')).field);
                }
            }
            else {
                if (target.classList.contains('e-toggleungroup')) {
                    this.ungroupColumn(target.parentElement.getAttribute('ej-mappingname'));
                }
            }
        }
    }
    applySortFromTarget(target) {
        const gObj = this.parent;
        const gHeader = closest(target, '.e-groupheadercell');
        if (gObj.allowSorting && gHeader && !target.classList.contains('e-ungroupbutton') &&
            !target.classList.contains('e-toggleungroup')) {
            const field = gHeader.firstElementChild.getAttribute('ej-mappingname');
            if (gObj.getColumnHeaderByField(field).getElementsByClassName('e-ascending').length) {
                gObj.sortColumn(field, 'Descending', true);
            }
            else {
                gObj.sortColumn(field, 'Ascending', true);
            }
        }
    }
    /**
     * Expands or collapses grouped rows by target element.
     *
     * @param  {Element} target - Defines the target element of the grouped row.
     * @returns {void}
     */
    expandCollapseRows(target) {
        const trgt = parentsUntil(target, 'e-recordplusexpand') ||
            parentsUntil(target, 'e-recordpluscollapse');
        if (trgt) {
            const rowNodes = this.parent.getContentTable().querySelector(literals.tbody).children;
            let isHide;
            let dataManager;
            let query;
            const gObj = this.parent;
            const indent = trgt.parentElement.getElementsByClassName('e-indentcell').length;
            const uid = trgt.parentElement.getAttribute('data-uid');
            const captionRow = gObj.getRowObjectFromUID(uid);
            let expand = false;
            if (trgt.classList.contains('e-recordpluscollapse')) {
                addClass([trgt], 'e-recordplusexpand');
                removeClass([trgt], 'e-recordpluscollapse');
                trgt.firstElementChild.className = 'e-icons e-gdiagonaldown e-icon-gdownarrow';
                trgt.firstElementChild.setAttribute('title', 'expanded');
                expand = true;
                captionRow.isExpand = true;
                if (isGroupAdaptive(gObj)) {
                    this.updateVirtualRows(gObj, target, expand, query, dataManager);
                }
                if (this.parent.groupSettings.enableLazyLoading) {
                    this.parent.contentModule.captionExpand(trgt.parentElement);
                }
            }
            else {
                isHide = true;
                captionRow.isExpand = false;
                removeClass([trgt], 'e-recordplusexpand');
                addClass([trgt], 'e-recordpluscollapse');
                trgt.firstElementChild.className = 'e-icons e-gnextforward e-icon-grightarrow';
                trgt.firstElementChild.setAttribute('title', 'collapsed');
                if (isGroupAdaptive(gObj)) {
                    this.updateVirtualRows(gObj, target, !isHide, query, dataManager);
                }
                if (this.parent.groupSettings.enableLazyLoading) {
                    this.parent.contentModule.captionCollapse(trgt.parentElement);
                }
            }
            this.aria.setExpand(trgt, expand);
            if (!isGroupAdaptive(gObj) && !this.parent.groupSettings.enableLazyLoading) {
                const rowObjs = gObj.getRowsObject();
                const startIdx = rowObjs.indexOf(captionRow);
                const rowsState = {};
                let cacheStartIdx = gObj.enableInfiniteScrolling && gObj.infiniteScrollSettings &&
                    gObj.infiniteScrollSettings.enableCache && rowObjs.length !== rowNodes.length ?
                    Array.from(rowNodes).indexOf(trgt.parentElement) : undefined;
                for (let i = startIdx; i < rowObjs.length; i++) {
                    if (i > startIdx && rowObjs[i].indent === indent) {
                        break;
                    }
                    if (rowObjs[i].isDetailRow) {
                        const visible = rowObjs[i - 1].isExpand && rowObjs[i - 1].visible;
                        if (cacheStartIdx && cacheStartIdx > 0 && cacheStartIdx < rowNodes.length) {
                            rowNodes[cacheStartIdx].style.display = visible ? '' : 'none';
                        }
                        else if (isNullOrUndefined(cacheStartIdx)) {
                            rowNodes[i].style.display = visible ? '' : 'none';
                        }
                    }
                    else if (rowsState[rowObjs[i].parentUid] === false) {
                        rowObjs[i].visible = false;
                        if (cacheStartIdx && cacheStartIdx > 0 && cacheStartIdx < rowNodes.length) {
                            rowNodes[cacheStartIdx].style.display = 'none';
                        }
                        else if (isNullOrUndefined(cacheStartIdx)) {
                            rowNodes[i].style.display = 'none';
                        }
                    }
                    else {
                        if (!(rowObjs[i].isDataRow || rowObjs[i].isCaptionRow || rowObjs[i].isDetailRow || rowObjs[i].isAggregateRow)) {
                            const visible = rowObjs[i].cells.some((cell) => cell.isDataCell
                                && cell.visible);
                            if (visible === rowObjs[i].visible) {
                                continue;
                            }
                        }
                        rowObjs[i].visible = true;
                        if (cacheStartIdx && cacheStartIdx > 0 && cacheStartIdx < rowNodes.length) {
                            rowNodes[cacheStartIdx].style.display = '';
                            rowNodes[cacheStartIdx].classList.remove('e-hide');
                        }
                        else if (isNullOrUndefined(cacheStartIdx)) {
                            rowNodes[i].style.display = '';
                            rowNodes[i].classList.remove('e-hide');
                        }
                    }
                    if (rowObjs[i].isCaptionRow) {
                        rowsState[rowObjs[i].uid] = rowObjs[i].isExpand && rowObjs[i].visible;
                    }
                    if (!isNullOrUndefined(cacheStartIdx)) {
                        cacheStartIdx++;
                    }
                }
                this.parent.notify(events.refreshExpandandCollapse, { rows: this.parent.getRowsObject() });
            }
            this.parent.notify(events.captionActionComplete, { isCollapse: isHide, parentUid: uid });
        }
    }
    updateVirtualRows(gObj, target, isExpand, query, dataManager) {
        const rObj = gObj.getRowObjectFromUID(target.closest('tr').getAttribute('data-uid'));
        rObj.isExpand = isExpand;
        updatecloneRow(gObj);
        this.parent.notify(events.refreshVirtualMaxPage, {});
        query = gObj.getDataModule().generateQuery(false);
        query.queries = gObj.getDataModule().aggregateQuery(gObj.getQuery().clone()).queries;
        const args = { requestType: 'virtualscroll', rowObject: rObj };
        if (gObj.contentModule) {
            args.virtualInfo = gObj.contentModule.prevInfo;
        }
        dataManager = gObj.getDataModule().getData(args, query.requiresCount());
        dataManager.then((e) => gObj.renderModule.dataManagerSuccess(e, args));
    }
    expandCollapse(isExpand) {
        if (!this.parent.groupSettings.columns.length) {
            return;
        }
        if (!isExpand) {
            this.parent.notify(events.initialCollapse, isExpand);
        }
        const rowNodes = this.parent.getContentTable().querySelector(literals.tbody).children;
        const rowObjs = this.parent.getRowsObject();
        let row;
        for (let i = 0, len = rowNodes.length; i < len; i++) {
            if (rowNodes[i].querySelectorAll('.e-recordplusexpand, .e-recordpluscollapse').length) {
                row = rowNodes[i].querySelector(isExpand ? '.e-recordpluscollapse' : '.e-recordplusexpand');
                if (row) {
                    row.className = isExpand ? 'e-recordplusexpand' : 'e-recordpluscollapse';
                    row.firstElementChild.className = isExpand ? 'e-icons e-gdiagonaldown e-icon-gdownarrow' :
                        'e-icons e-gnextforward e-icon-grightarrow';
                }
                if (!(rowNodes[i].firstElementChild.classList.contains('e-recordplusexpand') ||
                    rowNodes[i].firstElementChild.classList.contains('e-recordpluscollapse'))) {
                    rowNodes[i].style.display = isExpand ? '' : 'none';
                }
            }
            else {
                rowNodes[i].style.display = isExpand ? '' : 'none';
            }
            if (rowObjs[i].isCaptionRow) {
                rowObjs[i].isExpand = isExpand ? true : false;
            }
        }
        this.parent.updateVisibleExpandCollapseRows();
        this.parent.notify(events.refreshExpandandCollapse, { rows: this.parent.getRowsObject() });
    }
    /**
     * Expands all the grouped rows of the Grid.
     *
     * @returns {void}
     */
    expandAll() {
        this.expandCollapse(true);
    }
    /**
     * Collapses all the grouped rows of the Grid.
     *
     * @returns {void}
     */
    collapseAll() {
        this.expandCollapse(false);
    }
    /**
     * The function is used to render grouping
     *
     * @returns {void}
     * @hidden
     */
    render() {
        this.l10n = this.serviceLocator.getService('localization');
        this.renderGroupDropArea();
        this.initDragAndDrop();
        this.refreshToggleBtn();
        this.wireEvent();
    }
    renderGroupDropArea() {
        const groupElem = this.parent.element.querySelector('.e-groupdroparea');
        if (groupElem) {
            remove(groupElem);
        }
        this.element = this.parent.createElement('div', { className: 'e-groupdroparea', attrs: { 'tabindex': '-1' } });
        if (this.groupSettings.allowReordering) {
            this.element.classList.add('e-group-animate');
        }
        this.updateGroupDropArea();
        this.parent.element.insertBefore(this.element, this.parent.element.firstChild);
        if (!this.groupSettings.showDropArea) {
            this.element.style.display = 'none';
        }
    }
    updateGroupDropArea(clear) {
        if (this.groupSettings.showDropArea && !this.groupSettings.columns.length) {
            const dragLabel = this.l10n.getConstant('GroupDropArea');
            this.element.innerHTML = dragLabel;
            this.element.classList.remove('e-grouped');
        }
        else {
            if ((this.element.innerHTML === this.l10n.getConstant('GroupDropArea') && (this.groupSettings.columns.length === 1
                || !this.isAppliedGroup && !this.isAppliedUnGroup)) || clear) {
                this.element.innerHTML = '';
            }
            this.element.classList.add('e-grouped');
        }
    }
    initDragAndDrop() {
        this.initializeGHeaderDrop();
        this.initializeGHeaderDrag();
    }
    initializeGHeaderDrag() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const drag = new Draggable(this.element, {
            dragTarget: this.groupSettings.allowReordering ? '.e-drag' : '.e-groupheadercell',
            distance: this.groupSettings.allowReordering ? -10 : 5,
            helper: this.helper,
            dragStart: this.dragStart,
            drag: this.drag,
            dragStop: this.dragStop
        });
    }
    initializeGHeaderDrop() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const drop = new Droppable(this.element, {
            accept: '.e-dragclone',
            drop: this.drop
        });
    }
    /**
     * Groups a column by column name.
     *
     * @param  {string} columnName - Defines the column name to group.
     * @returns {void}
     */
    groupColumn(columnName) {
        const gObj = this.parent;
        const column = gObj.getColumnByField(columnName);
        if (isNullOrUndefined(column) || column.allowGrouping === false ||
            (this.contentRefresh && this.groupSettings.columns.indexOf(columnName) > -1)) {
            this.parent.log('action_disabled_column', { moduleName: this.getModuleName(), columnName: column.headerText });
            return;
        }
        if (isActionPrevent(gObj)) {
            gObj.notify(events.preventBatch, { instance: this, handler: this.groupColumn, arg1: columnName });
            return;
        }
        column.visible = gObj.groupSettings.showGroupedColumn;
        this.colName = columnName;
        this.isAppliedGroup = true;
        if (this.contentRefresh) {
            this.updateModel();
        }
        else {
            this.addColToGroupDrop(columnName);
        }
        this.updateGroupDropArea();
        this.isAppliedGroup = false;
    }
    /**
     * Ungroups a column by column name.
     *
     * @param  {string} columnName - Defines the column name to ungroup.
     * @returns {void}
     */
    ungroupColumn(columnName) {
        const gObj = this.parent;
        const column = this.parent.enableColumnVirtualization ?
            this.parent.columns.filter((c) => c.field === columnName)[0] : gObj.getColumnByField(columnName);
        if (isNullOrUndefined(column) || column.allowGrouping === false || this.groupSettings.columns.indexOf(columnName) < 0) {
            return;
        }
        if (isActionPrevent(gObj)) {
            gObj.notify(events.preventBatch, { instance: this, handler: this.ungroupColumn, arg1: columnName });
            return;
        }
        column.visible = true;
        this.colName = column.field;
        const columns = JSON.parse(JSON.stringify(this.groupSettings.columns));
        columns.splice(columns.indexOf(this.colName), 1);
        if (this.sortedColumns.indexOf(columnName) < 0) {
            for (let i = 0, len = gObj.sortSettings.columns.length; i < len; i++) {
                if (columnName === gObj.sortSettings.columns[i].field) {
                    gObj.sortSettings.columns.splice(i, 1);
                    break;
                }
            }
        }
        if (this.groupSettings.allowReordering) {
            this.reorderingColumns = columns;
        }
        this.groupSettings.columns = columns;
        if (gObj.allowGrouping) {
            this.isAppliedUnGroup = true;
            this.parent.dataBind();
        }
    }
    /**
     * The function used to update groupSettings
     *
     * @returns {void}
     * @hidden
     */
    updateModel() {
        let columns = JSON.parse(JSON.stringify(this.groupSettings.columns));
        columns = this.reorderingColumns.length ? JSON.parse(JSON.stringify(this.reorderingColumns)) : columns;
        if (this.sortRequired) {
            if (columns.indexOf(this.colName) === -1) {
                columns.push(this.colName);
            }
            this.groupAddSortingQuery(this.colName);
        }
        this.sortRequired = true;
        this.parent.groupSettings.columns = columns;
        this.parent.dataBind();
    }
    /**
     * The function used to trigger onActionComplete
     *
     * @param {NotifyArgs} e - specifies the NotifyArgs
     * @returns {void}
     * @hidden
     */
    onActionComplete(e) {
        if (e.requestType === 'grouping') {
            this.addColToGroupDrop(this.colName);
        }
        else {
            this.removeColFromGroupDrop(this.colName);
        }
        const args = this.groupSettings.columns.indexOf(this.colName) > -1 ? {
            columnName: this.colName, requestType: 'grouping', type: events.actionComplete
        } : { requestType: 'ungrouping', type: events.actionComplete };
        this.parent.trigger(events.actionComplete, extend(e, args));
        this.colName = null;
    }
    groupAddSortingQuery(colName) {
        let i = 0;
        while (i < this.parent.sortSettings.columns.length) {
            if (this.parent.sortSettings.columns[i].field === colName) {
                break;
            }
            i++;
        }
        if (this.parent.sortSettings.columns.length === i) {
            this.parent.sortSettings.columns.push({ field: colName, direction: 'Ascending', isFromGroup: true });
        }
        else if (!this.parent.allowSorting) {
            this.parent.sortSettings.columns[i].direction = 'Ascending';
        }
    }
    createElement(field) {
        const gObj = this.parent;
        let direction = 'Ascending';
        const animator = this.parent.createElement('div', { className: 'e-grid-icon e-group-animator' });
        let groupedColumn = this.parent.createElement('div', { className: 'e-grid-icon e-groupheadercell' });
        const childDiv = this.parent.createElement('div', { attrs: { 'ej-mappingname': field } });
        if (isComplexField(field)) {
            childDiv.setAttribute('ej-complexname', getComplexFieldID(field));
        }
        const column = this.parent.getColumnByField(field);
        //Todo headerTemplateID for grouped column, disableHtmlEncode
        const headerCell = gObj.getColumnHeaderByUid(column.uid);
        // if (!isNullOrUndefined(column.headerTemplate)) {
        //     if (column.headerTemplate.indexOf('#') !== -1) {
        //         childDiv.innerHTML = document.querySelector(column.headerTemplate).innerHTML.trim();
        //     } else {
        //         childDiv.innerHTML = column.headerTemplate;
        //     }
        //     childDiv.firstElementChild.classList.add('e-grouptext');
        // } else {
        if (this.groupSettings.allowReordering) {
            childDiv.appendChild(this.parent.createElement('span', {
                className: 'e-drag e-icons e-icon-drag', innerHTML: '&nbsp;',
                attrs: { title: 'Drag', tabindex: '-1', 'aria-label': 'Drag the grouped column' }
            }));
        }
        childDiv.appendChild(this.parent.createElement('span', {
            className: 'e-grouptext', innerHTML: column.headerText,
            attrs: { tabindex: '-1', 'aria-label': 'sort the grouped column' }
        }));
        // }
        if (this.groupSettings.showToggleButton) {
            childDiv.appendChild(this.parent.createElement('span', {
                className: 'e-togglegroupbutton e-icons e-icon-ungroup e-toggleungroup', innerHTML: '&nbsp;',
                attrs: { tabindex: '-1', 'aria-label': 'ungroup button' }
            }));
        }
        if (headerCell.querySelectorAll('.e-ascending,.e-descending').length) {
            direction = headerCell.querySelector('.e-ascending') ? 'Ascending' : 'Descending';
        }
        childDiv.appendChild(this.parent.createElement('span', {
            className: 'e-groupsort e-icons ' +
                ('e-' + direction.toLowerCase() + ' e-icon-' + direction.toLowerCase()), innerHTML: '&nbsp;',
            attrs: { tabindex: '-1', 'aria-label': 'sort the grouped column', role: 'button' }
        }));
        childDiv.appendChild(this.parent.createElement('span', {
            className: 'e-ungroupbutton e-icons e-icon-hide', innerHTML: '&nbsp;',
            attrs: { title: this.l10n.getConstant('UnGroup'),
                tabindex: '-1', 'aria-label': 'ungroup the grouped column', role: 'button' },
            styles: this.groupSettings.showUngroupButton ? '' : 'display:none'
        }));
        groupedColumn.appendChild(childDiv);
        if (this.groupSettings.allowReordering) {
            animator.appendChild(groupedColumn);
            animator.appendChild(this.createSeparator());
            groupedColumn = animator;
        }
        return groupedColumn;
    }
    addColToGroupDrop(field) {
        const groupElem = isComplexField(field) ? this.parent.element.querySelector('.e-groupdroparea div[ej-complexname=' +
            getComplexFieldID(field) + ']') : this.parent.element.querySelector('.e-groupdroparea div[ej-mappingname=' + field + ']');
        if (this.groupSettings.allowReordering && groupElem) {
            return;
        }
        const column = this.parent.getColumnByField(field);
        if (isNullOrUndefined(column)) {
            return;
        }
        const groupedColumn = this.createElement(field);
        if (this.groupSettings.allowReordering) {
            const index = this.element.getElementsByClassName('e-group-animator').length;
            groupedColumn.setAttribute('index', index.toString());
        }
        this.element.appendChild(groupedColumn);
        //Todo:  rtl
    }
    createSeparator() {
        return this.parent.createElement('span', {
            className: 'e-nextgroup e-icons e-icon-next', innerHTML: '&nbsp;',
            attrs: { tabindex: '-1', 'aria-label': 'Separator for the grouped columns' },
            styles: this.groupSettings.showUngroupButton ? '' : 'display:none'
        });
    }
    refreshToggleBtn(isRemove) {
        if (this.groupSettings.showToggleButton) {
            const headers = [].slice.call(this.parent.getHeaderTable().getElementsByClassName('e-headercelldiv'));
            for (let i = 0, len = headers.length; i < len; i++) {
                if (!((headers[i].classList.contains('e-emptycell')) || (headers[i].classList.contains('e-headerchkcelldiv')))) {
                    const column = this.parent.getColumnByUid(headers[i].getAttribute('e-mappinguid'));
                    if (!this.parent.showColumnMenu || (this.parent.showColumnMenu && !column.showColumnMenu)) {
                        if (headers[i].getElementsByClassName('e-grptogglebtn').length) {
                            remove(headers[i].querySelectorAll('.e-grptogglebtn')[0]);
                        }
                        if (!isRemove) {
                            headers[i].appendChild(this.parent.createElement('span', {
                                className: 'e-grptogglebtn e-icons ' +
                                    (this.groupSettings.columns.indexOf(column.field) > -1 ? 'e-toggleungroup e-icon-ungroup'
                                        : 'e-togglegroup e-icon-group'), attrs: { tabindex: '-1', 'aria-label': 'Group button' }
                            }));
                        }
                    }
                }
            }
        }
    }
    removeColFromGroupDrop(field) {
        if (!isNullOrUndefined(this.getGHeaderCell(field))) {
            const elem = this.getGHeaderCell(field);
            if (this.groupSettings.allowReordering) {
                const parent = parentsUntil(elem, 'e-group-animator');
                remove(parent);
            }
            else {
                remove(elem);
            }
            this.updateGroupDropArea();
        }
        this.isAppliedUnGroup = false;
    }
    onPropertyChanged(e) {
        if (e.module !== this.getModuleName()) {
            return;
        }
        for (const prop of Object.keys(e.properties)) {
            switch (prop) {
                case 'columns':
                    // eslint-disable-next-line no-case-declarations
                    let args;
                    if (this.contentRefresh) {
                        if (!this.isAppliedUnGroup) {
                            if (!this.isAppliedGroup) {
                                this.updateGroupDropArea(true);
                                for (let j = 0; j < this.parent.sortSettings.columns.length; j++) {
                                    if (this.parent.sortSettings.columns[j].isFromGroup) {
                                        this.parent.sortSettings.columns.splice(j, 1);
                                        j--;
                                    }
                                }
                                for (let i = 0; i < this.groupSettings.columns.length; i++) {
                                    this.colName = this.groupSettings.columns[i];
                                    const col = this.parent.getColumnByField(this.colName);
                                    col.visible = this.parent.groupSettings.showGroupedColumn;
                                    this.groupAddSortingQuery(this.colName);
                                    if (i < this.groupSettings.columns.length - 1) {
                                        this.addColToGroupDrop(this.groupSettings.columns[i]);
                                    }
                                }
                            }
                            args = {
                                columnName: this.colName, requestType: e.properties[prop].length ? 'grouping' : 'ungrouping',
                                type: events.actionBegin
                            };
                        }
                        else {
                            args = { columnName: this.colName, requestType: 'ungrouping', type: events.actionBegin };
                        }
                        if (!this.groupSettings.showGroupedColumn) {
                            const columns = e.oldProperties[prop];
                            for (let i = 0; i < columns.length; i++) {
                                if (e.properties[prop].indexOf(columns[i]) === -1) {
                                    this.parent.getColumnByField(columns[i]).visible = true;
                                }
                            }
                        }
                        this.parent.notify(events.modelChanged, args);
                    }
                    break;
                case 'showDropArea':
                    this.updateGroupDropArea();
                    if (this.groupSettings.showDropArea) {
                        this.element.style.display = '';
                        this.parent.headerModule.refreshUI();
                    }
                    else {
                        this.element.style.display = 'none';
                    }
                    break;
                case 'showGroupedColumn':
                    this.updateGroupedColumn(this.groupSettings.showGroupedColumn);
                    this.parent.notify(events.modelChanged, { requestType: 'refresh' });
                    break;
                case 'showUngroupButton':
                    this.updateButtonVisibility(this.groupSettings.showUngroupButton, 'e-ungroupbutton');
                    break;
                case 'showToggleButton':
                    this.updateButtonVisibility(this.groupSettings.showToggleButton, 'e-togglegroupbutton ');
                    this.parent.refreshHeader();
                    break;
                case 'enableLazyLoading':
                    this.parent.freezeRefresh();
                    break;
            }
        }
    }
    updateGroupedColumn(isVisible) {
        for (let i = 0; i < this.groupSettings.columns.length; i++) {
            this.parent.getColumnByField(this.groupSettings.columns[i]).visible = isVisible;
        }
    }
    updateButtonVisibility(isVisible, className) {
        const gHeader = [].slice.call(this.element.getElementsByClassName(className));
        for (let i = 0; i < gHeader.length; i++) {
            gHeader[i].style.display = isVisible ? '' : 'none';
        }
    }
    enableAfterRender(e) {
        if (e.module === this.getModuleName() && e.enable) {
            this.render();
        }
    }
    /**
     * To destroy the reorder
     *
     * @returns {void}
     * @hidden
     */
    destroy() {
        const gridElement = this.parent.element;
        if (!gridElement || (!gridElement.querySelector('.' + literals.gridHeader) && !gridElement.querySelector('.' + literals.gridContent))) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((this.parent.isDestroyed || !this.parent.allowGrouping) && !this.parent.refreshing) {
            this.clearGrouping();
        }
        this.unWireEvent();
        this.removeEventListener();
        this.refreshToggleBtn(true);
        if (this.element.parentNode) {
            remove(this.element);
        }
        //call ejdrag and drop destroy
    }
    /**
     * Clears all the grouped columns of the Grid.
     *
     * @returns {void}
     */
    clearGrouping() {
        const cols = JSON.parse(JSON.stringify(this.groupSettings.columns));
        this.contentRefresh = false;
        for (let i = 0, len = cols.length; i < len; i++) {
            if (i === (len - 1)) {
                this.contentRefresh = true;
            }
            this.ungroupColumn(cols[i]);
        }
        this.contentRefresh = true;
    }
    /**
     * For internal use only - Get the module name.
     *
     * @returns {string} returns the module name
     * @private
     */
    getModuleName() {
        return 'group';
    }
    refreshSortIcons() {
        const gObj = this.parent;
        let header;
        const cols = gObj.sortSettings.columns;
        const gCols = gObj.groupSettings.columns;
        const fieldNames = this.parent.getColumns().map((c) => c.field);
        this.refreshToggleBtn();
        for (let i = 0, len = cols.length; i < len; i++) {
            if (fieldNames.indexOf(cols[i].field) === -1) {
                continue;
            }
            header = gObj.getColumnHeaderByField(cols[i].field);
            if (!gObj.allowSorting && (this.sortedColumns.indexOf(cols[i].field) > -1 ||
                this.groupSettings.columns.indexOf(cols[i].field) > -1)) {
                classList(header.querySelector('.e-sortfilterdiv'), ['e-ascending', 'e-icon-ascending'], []);
                if (cols.length > 1) {
                    header.querySelector('.e-headercelldiv').appendChild(this.parent.createElement('span', { className: 'e-sortnumber', innerHTML: (i + 1).toString() }));
                }
            }
            else if (this.getGHeaderCell(cols[i].field) && this.getGHeaderCell(cols[i].field).getElementsByClassName('e-groupsort').length) {
                if (cols[i].direction === 'Ascending') {
                    classList(this.getGHeaderCell(cols[i].field).querySelector('.e-groupsort'), ['e-ascending', 'e-icon-ascending'], ['e-descending', 'e-icon-descending']);
                }
                else {
                    classList(this.getGHeaderCell(cols[i].field).querySelector('.e-groupsort'), ['e-descending', 'e-icon-descending'], ['e-ascending', 'e-icon-ascending']);
                }
            }
        }
        for (let i = 0, len = gCols.length; i < len; i++) {
            if (fieldNames.indexOf(gCols[i]) === -1) {
                continue;
            }
            gObj.getColumnHeaderByField(gCols[i]).setAttribute('aria-grouped', 'true');
        }
    }
    getGHeaderCell(field) {
        if (this.element && this.element.querySelector('[ej-mappingname="' + field + '"]')) {
            return this.element.querySelector('[ej-mappingname="' + field + '"]').parentElement;
        }
        return null;
    }
    onGroupAggregates(editedData) {
        const aggregates = this.iterateGroupAggregates(editedData);
        const rowData = this.groupGenerator.generateRows(aggregates, {});
        const summaryRows = this.parent.getRowsObject().filter((row) => !row.isDataRow);
        const updateSummaryRows = rowData.filter((data) => !data.isDataRow);
        if (this.parent.isReact || this.parent.isVue) {
            this.parent.destroyTemplate(['groupFooterTemplate', 'groupCaptionTemplate', 'footerTemplate']);
        }
        for (let i = 0; i < updateSummaryRows.length; i++) {
            const row = updateSummaryRows[i];
            const cells = row.cells.filter((cell) => cell.isDataCell);
            const args = { cells: cells, data: row.data, dataUid: summaryRows[i] ? summaryRows[i].uid : '' };
            this.parent.notify(events.refreshAggregateCell, args);
        }
    }
    iterateGroupAggregates(editedData) {
        const updatedData = editedData instanceof Array ? editedData : [];
        const rows = this.parent.getRowsObject();
        const initData = this.parent.getCurrentViewRecords();
        const deletedCols = [];
        let changeds = rows.map((row) => {
            if (row.edit === 'delete') {
                deletedCols.push(row.data);
            }
            return row.changes instanceof Object ? row.changes : row.data;
        });
        const field = this.parent.getPrimaryKeyFieldNames()[0];
        changeds = updatedData.length === 0 ? changeds : updatedData;
        const mergeData = initData.map((item) => {
            const pKeyVal = DataUtil.getObject(field, item);
            let value;
            const hasVal = changeds.some((cItem) => {
                value = cItem;
                return pKeyVal === DataUtil.getObject(field, cItem);
            });
            return hasVal ? value : item;
        });
        const eData = editedData;
        if (!(eData.type && eData.type === 'cancel') && deletedCols.length > 0) {
            for (let i = 0; i < deletedCols.length; i++) {
                const index = mergeData.indexOf(deletedCols[i]);
                mergeData.splice(index, 1);
            }
        }
        const aggregates = [];
        const aggregateRows = this.parent.aggregates;
        for (let j = 0; j < aggregateRows.length; j++) {
            const row = aggregateRows[j];
            for (let k = 0; k < row.columns.length; k++) {
                let aggr = {};
                const type = row.columns[k].type.toString();
                aggr = { type: type.toLowerCase(), field: row.columns[k].field };
                aggregates.push(aggr);
            }
        }
        let result;
        let aggrds;
        const groupedCols = this.parent.groupSettings.columns;
        for (let l = 0; l < groupedCols.length; l++) {
            aggrds = result ? result : mergeData;
            result = DataUtil.group(aggrds, groupedCols[l], aggregates, null, null);
        }
        return result;
    }
    updateExpand(args) {
        const uid = args.uid;
        const isExpand = args.isExpand;
        const rows = this.parent.getRowsObject();
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            if (row.uid === uid || isNullOrUndefined(uid)) {
                row.isExpand = isExpand;
                for (let j = i + 1; j < rows.length; j++) {
                    const childRow = rows[j];
                    let closestParent;
                    if (childRow.parentUid !== row.uid) {
                        closestParent = rows.filter((x) => x.uid === childRow.parentUid)[0];
                    }
                    if (childRow.parentUid === row.uid) {
                        childRow.visible = row.isExpand;
                    }
                    else if (!isNullOrUndefined(closestParent) && childRow.parentUid === closestParent.uid) {
                        if (closestParent.isExpand && closestParent.visible === true) {
                            childRow.visible = true;
                        }
                        else if (closestParent.isExpand && closestParent.visible === false) {
                            childRow.visible = false;
                        }
                    }
                    if (isNullOrUndefined(uid)) {
                        break;
                    }
                }
            }
        }
        this.parent.notify(events.contentReady, { rows: rows, args: { isFrozen: false, rows: rows } });
    }
}
