var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { compile } from '@syncfusion/ej2-base';
import { getEnumValue } from '@syncfusion/ej2-base';
import { CellType } from '../base/enum';
import { Property, Collection, ChildProperty } from '@syncfusion/ej2-base';
/**
 * Configures the Grid's aggregate column.
 */
export class AggregateColumn extends ChildProperty {
    constructor() {
        super(...arguments);
        this.templateFn = {};
    }
    /**
     * @param {Function} value - specifies the value
     * @returns {void}
     * @hidden
     */
    setFormatter(value) {
        this.formatFn = value;
    }
    /**
     * @returns {Function} returns the Function
     * @hidden
     */
    getFormatter() {
        return this.formatFn;
    }
    /**
     * @param {Object} helper - specifies the helper
     * @returns {void}
     * @hidden
     */
    setTemplate(helper = {}) {
        if (this.footerTemplate !== undefined) {
            this.templateFn[getEnumValue(CellType, CellType.Summary)] = { fn: compile(this.footerTemplate, helper),
                property: 'footerTemplate' };
        }
        if (this.groupFooterTemplate !== undefined) {
            this.templateFn[getEnumValue(CellType, CellType.GroupSummary)] = { fn: compile(this.groupFooterTemplate, helper),
                property: 'groupFooterTemplate' };
        }
        if (this.groupCaptionTemplate !== undefined) {
            this.templateFn[getEnumValue(CellType, CellType.CaptionSummary)] = { fn: compile(this.groupCaptionTemplate, helper),
                property: 'groupCaptionTemplate' };
        }
    }
    /**
     * @param {CellType} type - specifies the cell type
     * @returns {Object} returns the object
     * @hidden
     */
    getTemplate(type) {
        return this.templateFn[getEnumValue(CellType, type)];
    }
    /**
     * @param {Object} prop - returns the Object
     * @returns {void}
     * @hidden
     */
    setPropertiesSilent(prop) {
        this.setProperties(prop, true);
    }
}
__decorate([
    Property()
], AggregateColumn.prototype, "type", void 0);
__decorate([
    Property()
], AggregateColumn.prototype, "field", void 0);
__decorate([
    Property()
], AggregateColumn.prototype, "columnName", void 0);
__decorate([
    Property()
], AggregateColumn.prototype, "format", void 0);
__decorate([
    Property()
], AggregateColumn.prototype, "footerTemplate", void 0);
__decorate([
    Property()
], AggregateColumn.prototype, "groupFooterTemplate", void 0);
__decorate([
    Property()
], AggregateColumn.prototype, "groupCaptionTemplate", void 0);
__decorate([
    Property()
], AggregateColumn.prototype, "customAggregate", void 0);
/**
 * Configures the aggregate rows.
 */
export class AggregateRow extends ChildProperty {
}
__decorate([
    Collection([], AggregateColumn)
], AggregateRow.prototype, "columns", void 0);
