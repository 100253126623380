import { remove, addClass, isNullOrUndefined, extend, isUndefined, Browser } from '@syncfusion/ej2-base';
import { HeaderRender } from './header-renderer';
import { ContentRender } from './content-renderer';
import { FreezeRowModelGenerator } from '../services/freeze-row-model-generator';
import * as events from '../base/constant';
import { renderMovable, getScrollBarWidth, wrap, addRemoveEventListener } from '../base/util';
import { Input } from '@syncfusion/ej2-inputs';
import * as literals from '../base/string-literals';
/**
 * Freeze module is used to render grid content with frozen rows and columns
 *
 * @hidden
 */
export class FreezeContentRender extends ContentRender {
    constructor(parent, locator) {
        super(parent, locator);
        this.isInitialRender = true;
        this.widthService = locator.getService('widthService');
        this.addEventListener();
    }
    addEventListener() {
        this.parent.addEventListener(events.actionComplete, this.actionComplete.bind(this));
        this.parent.addEventListener(events.batchAdd, this.batchAdd.bind(this));
        this.parent.on(events.batchCancel, this.batchAdd.bind(this));
        this.parent.addEventListener(events.batchDelete, this.batchAdd.bind(this));
        this.parent.on(events.setHeightToFrozenElement, this.refreshScrollOffset);
        this.parent.on(events.columnVisibilityChanged, this.widthService.refreshFrozenScrollbar, this);
    }
    batchAdd(args) {
        const isAdd = args.name !== 'batchCancel'
            && !(this.parent.frozenRows && this.parent.editSettings.newRowPosition === 'Top');
        if (this.parent.height !== 'auto' && (isAdd || args.name === 'batchCancel' || args.name === 'batchDelete')) {
            this.refreshScrollOffset();
            const height = this.getTable().offsetHeight;
            if (args.name === 'add' && this.parent.editSettings.newRowPosition === 'Bottom') {
                this.parent.getContent().firstChild.scroll(0, height);
            }
        }
    }
    setHeightToContent(height) {
        this.getFrozenContent().style.height = height.toString() + 'px';
        this.getMovableContent().style.height = height.toString() + 'px';
    }
    actionComplete(args) {
        if (this.parent.editSettings.mode !== 'Dialog' && (args.requestType === 'add' || (args.requestType === 'cancel'
            && args.row.classList.contains(literals.addedRow)))
            && (!this.parent.frozenRows || this.parent.editSettings.newRowPosition === 'Bottom') && this.parent.height !== 'auto') {
            this.refreshScrollOffset();
            const height = this.getTable().offsetHeight;
            if (args.requestType === 'add' && this.parent.editSettings.newRowPosition === 'Bottom') {
                this.parent.getContent().firstChild.scroll(0, height);
            }
        }
    }
    removeEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.removeEventListener(events.actionComplete, this.actionComplete);
        this.parent.removeEventListener(events.batchAdd, this.batchAdd);
        this.parent.off(events.columnVisibilityChanged, this.widthService.refreshFrozenScrollbar);
    }
    renderPanel() {
        super.renderPanel();
        let fDiv = this.parent.element.querySelector('.' + literals.frozenContent);
        let mDiv = this.parent.element.querySelector('.' + literals.movableContent);
        if (isNullOrUndefined(fDiv)) {
            fDiv = this.parent.createElement('div', { className: 'e-frozencontent e-frozen-left-content' });
            mDiv = this.parent.createElement('div', { className: literals.movableContent });
            this.getPanel().querySelector('.' + literals.content).appendChild(fDiv);
            this.getPanel().querySelector('.' + literals.content).appendChild(mDiv);
            mDiv.style.scrollbarWidth = 'none';
        }
        this.setFrozenContent(fDiv);
        this.setMovableContent(mDiv);
        if (Browser.userAgent.indexOf('Mac OS') > -1 && Browser.info.name === 'safari' && !this.parent.enableVirtualization) {
            this.getPanel().firstElementChild.classList.add('e-mac-safari');
        }
    }
    renderFrozenRigthPanel() {
        super.renderPanel();
    }
    renderEmpty(tbody) {
        super.renderEmpty(tbody);
        this.getMovableContent().querySelector(literals.tbody).innerHTML = '<tr><td></td></tr>';
        addClass([this.getMovableContent().querySelector(literals.tbody).querySelector('tr')], ['e-emptyrow']);
        this.getFrozenContent().querySelector('.e-emptyrow').querySelector('td').colSpan = this.parent.getVisibleFrozenColumns();
        this.getFrozenContent().style.borderRightWidth = '0px';
        if (this.parent.frozenRows) {
            this.parent.getHeaderContent().querySelector('.' + literals.frozenHeader).querySelector(literals.tbody).innerHTML = '';
            this.parent.getHeaderContent().querySelector('.' + literals.movableHeader).querySelector(literals.tbody).innerHTML = '';
        }
    }
    renderFrozenRightEmpty(tbody) {
        super.renderEmpty(tbody);
    }
    setFrozenContent(ele) {
        this.frozenContent = ele;
    }
    /**
     * @param {Element} ele - specifies the element
     * @returns {void}
     * @hidden
     */
    setMovableContent(ele) {
        this.movableContent = ele;
    }
    getFrozenContent() {
        return this.frozenContent;
    }
    getMovableContent() {
        return this.movableContent;
    }
    getModelGenerator() {
        return new FreezeRowModelGenerator(this.parent);
    }
    renderFrozenRightTable() {
        super.renderTable();
    }
    renderTable() {
        let mTbl;
        if (this.getFrozenContent().querySelector('.' + literals.table) == null) {
            super.renderTable();
            this.getFrozenContent().appendChild(this.getTable());
            mTbl = this.getTable().cloneNode(true);
            this.getMovableContent().appendChild(mTbl);
        }
        else {
            this.setTable(this.getFrozenContent().querySelector('.' + literals.table));
            this.setColGroup(this.parent.element.querySelector('.' + literals.gridHeader).querySelector(literals.colGroup).cloneNode(true));
            this.getFrozenContent().querySelector('.' + literals.table).appendChild(this.getColGroup());
            mTbl = this.getMovableContent().querySelector('.' + literals.table);
            if (this.parent.frozenRows) {
                this.parent.getHeaderContent().classList.add('e-frozenhdrcont');
            }
        }
        if (this.getMovableContent().querySelector(literals.colGroup)) {
            remove(this.getMovableContent().querySelector(literals.colGroup));
        }
        const colGroup = ((this.parent.getHeaderContent().querySelector('.' + literals.movableHeader).querySelector(literals.colGroup)).cloneNode(true));
        mTbl.insertBefore(colGroup, mTbl.querySelector(literals.tbody));
        const style = this.parent.enableVirtualization ? '' : 'flex';
        this.getPanel().firstChild.style.display = style;
        this.renderHorizontalScrollbar('e-frozenscrollbar e-frozen-left-scrollbar', this.getScrollbarDisplay());
    }
    getScrollbarDisplay() {
        let frozenDisplay = '';
        if ((this.parent.getFrozenColumns() && !this.parent.getVisibleFrozenColumns())
            || (this.parent.getFrozenLeftColumnsCount() && !this.parent.getVisibleFrozenLeftCount())) {
            frozenDisplay = 'none';
        }
        return frozenDisplay;
    }
    renderHorizontalScrollbar(className, display, isRight) {
        const left = this.parent.createElement('div', { className: className, styles: 'display:' + display });
        const movable = this.parent.createElement('div', { className: 'e-movablescrollbar' });
        const child = this.parent.createElement('div', { className: 'e-movablechild' });
        const scrollbarHeight = getScrollBarWidth().toString();
        this.setScrollbarHeight(movable, scrollbarHeight);
        this.setScrollbarHeight(child, scrollbarHeight);
        movable.appendChild(child);
        this.appendScrollbar(left, movable, isRight);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    appendScrollbar(frozen, movable, isRight) {
        const parent = this.parent.createElement('div', { className: 'e-scrollbar', styles: 'display: flex' });
        parent.appendChild(frozen);
        parent.appendChild(movable);
        this.parent.getContent().appendChild(parent);
    }
    setScrollbarHeight(ele, height) {
        ele.style.minHeight = height + 'px';
        ele.style.maxHeight = height + 'px';
    }
    /**
     * @param {NotifyArgs} args - specifies the NotifyArgs
     * @param {freezeTable} tableName - specifies the Freeze Table
     * @returns {void}
     * @hidden
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setIsFrozen(args, tableName) {
        args.isFrozen = !args.isFrozen;
    }
    /**
     * @param {Row<Column>[]} modelData - specifies the modeldata
     * @param {NotifyArgs} args - specifies the args
     * @returns {freezeTable} returns the freeze table
     * @hidden
     */
    setTbody(modelData, args) {
        let tableName;
        if (isNullOrUndefined(modelData[0].cells[0])) {
            this.getMovableContent().querySelector(literals.tbody).innerHTML = '';
        }
        let cell = modelData[0].cells[0];
        let idx = cell.index;
        if (isUndefined(idx) && this.parent.isRowDragable()) {
            cell = modelData[0].cells[1];
            idx = cell.index;
        }
        if (idx === 0) {
            this.getPanel().firstChild.style.overflowX = 'hidden';
            if (this.parent.enableColumnVirtualization) {
                this.getMovableContent().style.overflowX = 'hidden';
            }
        }
        if (this.parent.enableColumnVirtualization && args.renderMovableContent
            && args.requestType === 'virtualscroll' && this.getMovableContent().scrollLeft > 0 && args.virtualInfo.columnIndexes[0] !== 0) {
            idx = this.parent.getFrozenColumns();
        }
        if (cell && cell.column) {
            tableName = cell.column.getFreezeTableName();
        }
        this.setIdx(idx);
        args.tableName = tableName;
        return tableName;
    }
    /**
     * @param {string} tableName - specifies the table name
     * @returns {void}
     * @hidden
     */
    splitRows(tableName) {
        if (tableName === literals.frozenLeft) {
            this.freezeRows = this.rows;
            this.freezeRowElements = this.rowElements;
        }
        else {
            this.movableRows = this.rows;
        }
    }
    /**
     * @param {NotifyArgs} args - specifies the notifyargs
     * @param {string} tableName - specifies the tableName
     * @returns {void}
     * @hidden
     */
    renderNextFrozentPart(args, tableName) {
        const isVFTable = this.parent.enableVirtualization;
        if (tableName === literals.frozenLeft) {
            if (isVFTable) {
                args.renderMovableContent = true;
            }
            this.refreshContentRows(extend({}, args));
        }
    }
    appendContent(tbody, frag, args, tableName) {
        if (this.parent.isReact && !isNullOrUndefined(this.parent.rowTemplate)) {
            tbody = frag;
        }
        else {
            tbody.appendChild(frag);
        }
        if (tableName === literals.frozenLeft) {
            this.isLoaded = false;
            this.getFrozenContent().querySelector('table').appendChild(tbody);
        }
        else {
            this.refreshTbody(tbody);
            this.isLoaded = true;
            this.getMovableContent().querySelector('table').appendChild(tbody);
            this.refreshHeight();
            this.refreshScrollOffset();
            this.widthService.refreshFrozenScrollbar();
        }
        if (this.isInitialRender) {
            this.parent.scrollModule.setHeight();
            this.isInitialRender = false;
        }
    }
    refreshScrollOffset() {
        if (this.parent.height !== 'auto') {
            const height = this.getTable().offsetHeight + 1;
            this.setHeightToContent(height);
        }
        this.parent.notify(events.refreshFrozenHeight, {});
    }
    /**
     * @param {string} tableName - specifies the table name
     * @returns {HTMLElement} returns the Html element
     * @hidden
     */
    getFrozenHeader(tableName) {
        if (tableName === literals.frozenLeft) {
            return this.parent.getHeaderContent().querySelector('.' + literals.frozenHeader).querySelector(literals.tbody);
        }
        else {
            return this.parent.getHeaderContent().querySelector('.' + literals.movableHeader).querySelector(literals.tbody);
        }
    }
    refreshTbody(tbody) {
        if (tbody.childElementCount < 1) {
            tbody.appendChild(this.parent.createElement('tr', { attrs: { role: 'row' } }).appendChild(this.parent.createElement('td')));
        }
    }
    refreshHeight() {
        if (!this.parent.allowTextWrap) {
            this.parent.notify(events.freezeRender, { case: 'refreshHeight' });
        }
        this.getFrozenContent().style.borderRightWidth = '1px';
    }
    setIdx(idx) {
        this.idx = idx;
    }
    getIdx() {
        return this.idx;
    }
    /**
     * @param {freezeTable} tableName - specifies the table name
     * @returns {Element} returns the element
     * @hidden
     */
    getTbody(tableName) {
        if (tableName === literals.frozenLeft) {
            return this.getTable().querySelector(literals.tbody);
        }
        else {
            return this.getMovableContent().querySelector(literals.tbody);
        }
    }
}
export class FreezeRender extends HeaderRender {
    constructor(parent, locator) {
        super(parent, locator);
        this.addEventListener();
    }
    addEventListener() {
        this.eventHandler = [{ event: events.freezeRender, handler: this.refreshFreeze },
            { event: events.frozenHeight, handler: this.setFrozenHeight },
            { event: events.uiUpdate, handler: this.enableAfterRender }];
        addRemoveEventListener(this.parent, this.eventHandler, true, this);
    }
    removeEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        addRemoveEventListener(this.parent, this.eventHandler, false);
    }
    renderTable() {
        super.renderTable();
        this.rfshMovable();
        this.updateColgroup();
        this.initializeHeaderDrag();
        this.initializeHeaderDrop();
        this.parent.notify(events.headerRefreshed, { rows: this.rows, args: { isFrozen: false } });
    }
    renderPanel() {
        let fDiv = this.parent.element.querySelector('.' + literals.frozenHeader);
        let mDiv = this.parent.element.querySelector('.' + literals.movableHeader);
        super.renderPanel();
        if (isNullOrUndefined(fDiv)) {
            fDiv = this.parent.createElement('div', { className: 'e-frozenheader e-frozen-left-header' });
            mDiv = this.parent.createElement('div', { className: literals.movableHeader });
            this.getPanel().querySelector('.' + literals.headerContent).appendChild(fDiv);
            this.getPanel().querySelector('.' + literals.headerContent).appendChild(mDiv);
        }
        this.setFrozenHeader(fDiv);
        this.setMovableHeader(mDiv);
    }
    renderFrozenRightPanel() {
        super.renderPanel();
    }
    renderFrozenRightTable() {
        super.renderTable();
    }
    refreshUI() {
        const tbody = this.getMovableHeader().querySelector(literals.tbody);
        remove(this.getMovableHeader().querySelector('table'));
        super.refreshUI();
        this.rfshMovable();
        this.getMovableHeader().querySelector(literals.tbody).innerHTML = tbody.innerHTML;
        this.updateColgroup();
        this.widthService.setWidthToColumns();
        if (!this.parent.enableVirtualization && !this.parent.isFrozenGrid()) {
            this.widthService.setWidthToTable();
        }
        if (this.parent.allowTextWrap && this.parent.textWrapSettings.wrapMode === 'Header') {
            wrap([].slice.call(this.movableHeader.querySelectorAll('tr.e-columnheader')), true);
        }
        this.parent.updateDefaultCursor();
        renderMovable(this.parent.getContentTable().querySelector(literals.colGroup), this.parent.getFrozenColumns(), this.parent);
        this.widthService.refreshFrozenScrollbar();
        this.initializeHeaderDrag();
        this.parent.notify(events.headerRefreshed, { rows: this.rows, args: { isFrozen: false } });
    }
    refreshFrozenLeftUI() {
        super.refreshUI();
    }
    rfshMovable() {
        this.getFrozenHeader().appendChild(this.getTable());
        this.getMovableHeader().appendChild(this.createHeader(undefined, 'movable'));
        this.refreshStackedHdrHgt();
        this.addMovableFirstCls();
    }
    addMovableFirstCls() {
        if (this.parent.getVisibleFrozenColumns()) {
            const movablefirstcell = [].slice.call(this.parent.element.querySelector('.' + literals.movableHeader).querySelector('thead').getElementsByClassName('e-columnheader'));
            const len = movablefirstcell.length;
            for (let i = 0; i < len; i++) {
                const cells = 'cells';
                const element = movablefirstcell[i][cells][0];
                if (element) {
                    addClass([element], ['e-movablefirst']);
                    if (movablefirstcell[i][cells][0].rowSpan > 1) {
                        i = i + (movablefirstcell[i][cells][0].rowSpan - 1);
                    }
                }
            }
        }
    }
    refreshFreeze(obj) {
        if (obj.case === 'filter') {
            const filterRow = this.getTable().querySelector('.e-filterbar');
            if (this.parent.allowFiltering && filterRow && this.getMovableHeader().querySelector('thead')) {
                this.getMovableHeader().querySelector('thead')
                    .appendChild(this.filterRenderer(filterRow, this.parent.getFrozenColumns()));
                const elements = [].slice.call(this.getMovableHeader().
                    querySelectorAll('thead .e-filterbarcell .e-input'));
                for (const elem of elements) {
                    const args = {
                        element: elem, floatLabelType: 'Never',
                        properties: {
                            enableRtl: this.parent.enableRtl, showClearButton: true
                        }
                    };
                    Input.bindInitialEvent(args);
                }
            }
        }
        else if (obj.case === 'textwrap' || obj.case === 'refreshHeight') {
            this.refreshHeight(obj);
            this.parent.contentModule.refreshScrollOffset();
        }
    }
    refreshHeight(obj) {
        let fRows;
        let mRows;
        const fHdr = this.getFrozenHeader();
        const mHdr = this.getMovableHeader();
        const cont = this.parent.getContent();
        const wrapMode = this.parent.textWrapSettings.wrapMode;
        const hdrClassList = this.parent.getHeaderContent().querySelector('.' + literals.headerContent).classList;
        if (obj.case === 'textwrap') {
            if (wrapMode !== 'Header' || obj.isModeChg) {
                fRows = cont.querySelector('.' + literals.frozenContent).querySelectorAll('tr');
                mRows = cont.querySelector('.' + literals.movableContent).querySelectorAll('tr');
                this.setWrapHeight(fRows, mRows, obj.isModeChg, true);
            }
            if (wrapMode === 'Content' && this.parent.allowTextWrap) {
                hdrClassList.add('e-wrap');
            }
            else {
                hdrClassList.remove('e-wrap');
            }
            if (wrapMode === 'Both' || obj.isModeChg) {
                fRows = fHdr.querySelectorAll('tr');
                mRows = mHdr.querySelectorAll('tr');
            }
            else {
                mRows = mHdr.querySelector(wrapMode === 'Content' ?
                    literals.tbody : 'thead').querySelectorAll('tr');
                fRows = fHdr.querySelector(wrapMode === 'Content' ?
                    literals.tbody : 'thead').querySelectorAll('tr');
            }
            if (!this.parent.getHeaderContent().getElementsByClassName('e-stackedheadercell').length) {
                this.setWrapHeight(fRows, mRows, obj.isModeChg, false, this.colDepth > 1);
            }
            this.refreshStackedHdrHgt();
        }
        else if (obj.case === 'refreshHeight') {
            this.setWrapHeight(cont.querySelector('.' + literals.frozenContent).querySelectorAll('tr'), cont.querySelector('.' + literals.movableContent).querySelectorAll('tr'), obj.isModeChg);
            if (!this.parent.getHeaderContent().getElementsByClassName('e-stackedheadercell').length) {
                this.setWrapHeight(fHdr.querySelectorAll('tr'), mHdr.querySelectorAll('tr'), obj.isModeChg);
            }
        }
    }
    enableAfterRender(e) {
        if (e.module === 'scroll') {
            this.setFrozenHeight();
        }
    }
    updateResizeHandler() {
        const elements = [].slice.call(this.parent.getHeaderContent().getElementsByClassName('e-rhandler'));
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.height = elements[i].parentElement.offsetHeight + 'px';
        }
    }
    setWrapHeight(fRows, mRows, isModeChg, isContReset, isStackedHdr) {
        let fRowHgt;
        let mRowHgt;
        const isWrap = this.parent.allowTextWrap;
        const wrapMode = this.parent.textWrapSettings.wrapMode;
        const tHead = this.parent.getHeaderContent().querySelector('thead');
        const tBody = this.parent.getHeaderContent().querySelector(literals.tbody);
        const height = [];
        const width = [];
        for (let i = 0, len = fRows.length; i < len; i++) {
            if (isModeChg && ((!this.parent.rowHeight && tBody.className === 'e-hide') || (wrapMode === 'Header' && isContReset) ||
                ((wrapMode === 'Content' && tHead.contains(fRows[i])) || (wrapMode === 'Header' && tBody.contains(fRows[i])))) ||
                isStackedHdr) {
                fRows[i].style.height = null;
                mRows[i].style.height = null;
            }
            if (!isNullOrUndefined(fRows[i]) && !isNullOrUndefined(mRows[i])) {
                height[i] = fRows[i].getBoundingClientRect().height; //https://pagebuildersandwich.com/increased-plugins-performance-200/
                width[i] = mRows[i].getBoundingClientRect().height;
            }
            fRowHgt = height[i];
            mRowHgt = width[i];
            if (!isNullOrUndefined(fRows[i]) && fRows[i].childElementCount && ((isWrap && fRowHgt < mRowHgt) ||
                (!isWrap && fRowHgt < mRowHgt) || (this.parent.allowResizing && this.parent.resizeModule &&
                this.parent.resizeModule.isFrozenColResized === false))) {
                fRows[i].style.height = mRowHgt + 'px';
            }
            if (mRows && !isNullOrUndefined(mRows[i]) && mRows[i].childElementCount && ((isWrap && fRowHgt > mRowHgt) ||
                (!isWrap && fRowHgt > mRowHgt) || (this.parent.allowResizing && this.parent.resizeModule &&
                this.parent.resizeModule.isFrozenColResized === true))) {
                mRows[i].style.height = fRowHgt + 'px';
            }
        }
        if (isWrap && this.parent.height !== 'auto') {
            this.setFrozenHeight();
        }
    }
    setFrozenHeight(height = getScrollBarWidth()) {
        const movableContentHeight = this.parent.element.querySelector('.' + literals.movableContent).getBoundingClientRect().height;
        const movableContent = this.parent.element.querySelector('.' + literals.movableContent);
        const frozenContent = this.parent.element.querySelector('.' + literals.frozenContent);
        const contentScrollWidth = this.parent.getContent().scrollWidth;
        const contentTableScrollWidth = this.parent.element.querySelector('.e-movablecontent table').scrollWidth +
            this.parent.getContentTable().scrollWidth;
        if (movableContent.scrollWidth - movableContent.clientWidth) {
            frozenContent.style.height = movableContentHeight -
                height + 'px';
            frozenContent.style.borderBottom = '';
        }
        else {
            frozenContent.style.height = movableContentHeight + 'px';
            if (((frozenContent.scrollHeight <= frozenContent.clientHeight) ||
                (movableContent.scrollHeight <= movableContent.clientHeight))
                && contentScrollWidth === contentTableScrollWidth) {
                this.parent.scrollModule.removePadding();
            }
            frozenContent.style.borderBottom = '0px';
        }
    }
    refreshStackedHdrHgt() {
        let fRowSpan;
        let mRowSpan;
        const fTr = [].slice.call(this.getFrozenHeader().getElementsByClassName('e-columnheader'));
        const mTr = [].slice.call(this.getMovableHeader().getElementsByClassName('e-columnheader'));
        for (let i = 0, len = fTr.length; i < len; i++) {
            fRowSpan = this.getRowSpan(fTr[i]);
            mRowSpan = this.getRowSpan(mTr[i]);
            if (fRowSpan.min > 1) {
                this.updateStackedHdrRowHgt(i, fRowSpan.max, fTr[i], mTr);
            }
            else if (mRowSpan.min > 1) {
                this.updateStackedHdrRowHgt(i, mRowSpan.max, mTr[i], fTr);
            }
        }
        if (this.parent.allowResizing) {
            this.updateResizeHandler();
        }
    }
    getRowSpan(row) {
        let rSpan;
        let minRowSpan;
        let maxRowSpan;
        for (let i = 0, len = row.childElementCount; i < len; i++) {
            if (i === 0) {
                minRowSpan = row.children[0].rowSpan;
            }
            rSpan = row.children[i].rowSpan;
            minRowSpan = Math.min(rSpan, minRowSpan);
            maxRowSpan = Math.max(rSpan, minRowSpan);
        }
        return { min: minRowSpan, max: maxRowSpan };
    }
    updateStackedHdrRowHgt(idx, maxRowSpan, row, rows) {
        let height = 0;
        for (let i = 0; i < maxRowSpan; i++) {
            height += rows[idx + i].style.height ?
                parseInt(rows[idx + i].style.height, 10) : rows[idx + i].offsetHeight;
        }
        row.style.height = height + 'px';
    }
    setFrozenHeader(ele) {
        this.frozenHeader = ele;
    }
    /**
     * @param {Element} ele - specifies the element
     * @returns {void}
     * @hidden
     */
    setMovableHeader(ele) {
        this.movableHeader = ele;
    }
    getFrozenHeader() {
        return this.frozenHeader;
    }
    getMovableHeader() {
        return this.movableHeader;
    }
    /**
     * @returns {void}
     * @hidden
     */
    updateColgroup() {
        const mTable = this.getMovableHeader().querySelector('table');
        remove(this.getMovableHeader().querySelector(literals.colGroup));
        mTable.insertBefore(renderMovable(this.getFrozenHeader().querySelector(literals.colGroup), this.parent.getFrozenColumns(), this.parent), mTable.querySelector('thead'));
    }
    filterRenderer(ele, frozenColumn, total) {
        const clone = ele.cloneNode(true);
        clone.innerHTML = '';
        const end = total ? total : this.parent.getColumns().length;
        for (let i = frozenColumn; i < end; i++) {
            clone.appendChild(ele.removeChild(ele.children[frozenColumn]));
        }
        return clone;
    }
}
