import { extend, select } from '@syncfusion/ej2-base';
import { remove, isNullOrUndefined, updateBlazorTemplate } from '@syncfusion/ej2-base';
import { parentsUntil, isGroupAdaptive, refreshForeignData, getObject, gridActionHandler } from '../base/util';
import * as events from '../base/constant';
import { RowRenderer } from '../renderer/row-renderer';
import { DataUtil } from '@syncfusion/ej2-data';
import { addRemoveEventListener } from '../base/util';
import * as literals from '../base/string-literals';
/**
 * `NormalEdit` module is used to handle normal('inline, dialog, external') editing actions.
 *
 * @hidden
 */
export class NormalEdit {
    constructor(parent, serviceLocator, renderer) {
        this.args = {};
        this.currentVirtualData = {};
        this.parent = parent;
        this.renderer = renderer;
        this.serviceLocator = serviceLocator;
        this.addEventListener();
    }
    clickHandler(e) {
        const target = e.target;
        const gObj = this.parent;
        if ((((parentsUntil(target, literals.gridContent) &&
            parentsUntil(parentsUntil(target, literals.gridContent), 'e-grid').id === gObj.element.id)) || (gObj.frozenRows
            && parentsUntil(target, literals.headerContent))) && !parentsUntil(target, 'e-unboundcelldiv')) {
            this.rowIndex = parentsUntil(target, literals.rowCell)
                ? parseInt(target.parentElement.getAttribute(literals.dataRowIndex), 10) : -1;
            if (gObj.isEdit) {
                gObj.editModule.endEdit();
            }
        }
    }
    dblClickHandler(e) {
        if (parentsUntil(e.target, literals.rowCell) && this.parent.editSettings.allowEditOnDblClick) {
            this.parent.editModule.startEdit(parentsUntil(e.target, literals.row));
        }
    }
    /**
     * The function used to trigger editComplete
     *
     * @param {NotifyArgs} e - specifies the NotifyArgs
     * @returns {void}
     * @hidden
     */
    editComplete(e) {
        this.parent.isEdit = false;
        const action = 'action';
        switch (e.requestType) {
            case 'save':
                if (!(this.parent.isCheckBoxSelection || this.parent.selectionSettings.type === 'Multiple')
                    || (!this.parent.isPersistSelection)) {
                    if (e[action] !== 'edit') {
                        this.parent.selectRow(0);
                    }
                }
                this.parent.trigger(events.actionComplete, extend(e, {
                    requestType: 'save',
                    type: events.actionComplete
                }));
                break;
            case 'delete':
                this.parent.trigger(events.actionComplete, extend(e, {
                    requestType: 'delete',
                    type: events.actionComplete
                }));
                if (!this.parent.isCheckBoxSelection) {
                    this.parent.selectRow(this.editRowIndex);
                }
                break;
        }
    }
    getEditArgs(editedData, rowObj, isScroll) {
        const primaryKeys = this.parent.getPrimaryKeyFieldNames();
        const primaryKeyValues = [];
        for (let i = 0; i < primaryKeys.length; i++) {
            primaryKeyValues.push(getObject(primaryKeys[i], editedData));
        }
        const args = {
            primaryKey: primaryKeys, primaryKeyValue: primaryKeyValues, requestType: 'beginEdit',
            rowData: editedData, rowIndex: this.rowIndex, type: 'edit', cancel: false,
            foreignKeyData: rowObj && rowObj.foreignKeyData, target: undefined, isScroll: isScroll
        };
        return args;
    }
    startEdit(tr) {
        const gObj = this.parent;
        this.rowIndex = this.editRowIndex = parseInt(tr.getAttribute(literals.dataRowIndex), 10);
        if (gObj.enableVirtualization || gObj.enableInfiniteScrolling) {
            const selector = '.e-row[data-rowindex="' + this.rowIndex + '"]';
            const virtualRow = this.parent.element.querySelector(selector);
            if (!virtualRow) {
                return;
            }
        }
        const e = { data: undefined, index: this.rowIndex, isScroll: false };
        this.parent.notify(events.virtualScrollEditActionBegin, e);
        if (isGroupAdaptive(gObj)) {
            const rObj = gObj.getRowObjectFromUID(tr.getAttribute('data-uid'));
            this.previousData = rObj.data;
        }
        else if (!this.previousData && (this.parent.enableVirtualization || this.parent.enableInfiniteScrolling)) {
            this.previousData = e.data;
        }
        else if (!this.parent.enableVirtualization) {
            this.previousData = extend({}, {}, gObj.getCurrentViewRecords()[this.rowIndex], true);
        }
        const editedData = extend({}, {}, e.data || this.previousData, true);
        this.uid = tr.getAttribute('data-uid');
        const rowObj = gObj.getRowObjectFromUID(this.uid);
        const args = this.getEditArgs(editedData, rowObj, e.isScroll);
        args.row = tr;
        if (!args.isScroll) {
            this.parent.notify(events.createVirtualValidationForm, { uid: this.uid, prevData: this.previousData, argsCreator: this.getEditArgs.bind(this), renderer: this.renderer });
            gObj.trigger(events.beginEdit, args, (begineditargs) => {
                begineditargs.type = 'actionBegin';
                gObj.trigger(events.actionBegin, begineditargs, (editargs) => {
                    if (!editargs.cancel) {
                        this.inlineEditHandler(editargs, tr);
                    }
                });
            });
        }
        else {
            this.inlineEditHandler(args, tr);
        }
    }
    inlineEditHandler(editargs, tr) {
        const gObj = this.parent;
        gObj.isEdit = true;
        editargs.row = editargs.row ? editargs.row : tr;
        if (gObj.editSettings.mode !== 'Dialog') {
            gObj.clearSelection();
        }
        if (gObj.editSettings.mode === 'Dialog' && gObj.selectionModule) {
            gObj.selectionModule.preventFocus = true;
            editargs.row.classList.add('e-dlgeditrow');
        }
        this.renderer.update(editargs);
        this.uid = tr.getAttribute('data-uid');
        gObj.editModule.applyFormValidation();
        editargs.type = 'actionComplete';
        gObj.trigger(events.actionComplete, editargs);
        this.args = editargs;
        if (this.parent.allowTextWrap) {
            this.parent.notify(events.freezeRender, { case: 'textwrap' });
        }
    }
    updateRow(index, data) {
        const gObj = this.parent;
        this.editRowIndex = index;
        const args = {
            requestType: 'save', action: 'edit', type: events.actionBegin, data: data, cancel: false,
            previousData: gObj.getCurrentViewRecords()[index],
            row: gObj.getRowByIndex(index)
        };
        gObj.showSpinner();
        if (gObj.enableInfiniteScrolling) {
            this.uid = args.row.getAttribute('data-uid');
            const index = parseInt(args.row.getAttribute('data-rowindex'), 10);
            this.parent.notify(events.refreshInfiniteEditrowindex, { index: index });
        }
        gObj.notify(events.updateData, args);
        if (args.promise) {
            args.promise.then(() => gObj.refresh()).catch((e) => this.edFail(e));
        }
        else {
            if (!gObj.enableInfiniteScrolling) {
                gObj.refresh();
            }
        }
    }
    editFormValidate() {
        const gObj = this.parent;
        const isValid = gObj.editModule.editFormValidate();
        const validationArgs = {
            prevData: this.previousData, isValid: true, editIdx: this.editRowIndex, addIdx: this.addedRowIndex
        };
        gObj.notify(events.validateVirtualForm, validationArgs);
        return (isValid && validationArgs.isValid);
    }
    endEdit() {
        const gObj = this.parent;
        if (!this.parent.isEdit || !this.editFormValidate()) {
            return;
        }
        let editedData = extend({}, {}, this.previousData, true);
        const args = extend(this.args, {
            requestType: 'save', type: events.actionBegin, data: editedData, cancel: false,
            previousData: this.previousData, selectedRow: gObj.selectedRowIndex, foreignKeyData: {}
        });
        const index = gObj.getFrozenMode() === 'Right' ? 1 : 0;
        const isDlg = gObj.editSettings.mode === 'Dialog';
        const dlgWrapper = select('#' + gObj.element.id + '_dialogEdit_wrapper', document);
        const dlgForm = isDlg ? dlgWrapper.querySelector('.e-gridform') : gObj.element.getElementsByClassName('e-gridform')[index];
        const data = {
            virtualData: extend({}, {}, this.previousData, true), isAdd: false, isScroll: false, endEdit: true
        };
        this.parent.notify(events.getVirtualData, data);
        if ((this.parent.enableVirtualization || this.parent.enableInfiniteScrolling)
            && this.parent.editSettings.mode === 'Normal' && Object.keys(data.virtualData).length) {
            if (this.parent.isEdit) {
                this.currentVirtualData = editedData = args.data = data.virtualData;
            }
        }
        else {
            editedData = gObj.editModule.getCurrentEditedData(dlgForm, editedData);
        }
        if (gObj.isFrozenGrid() && gObj.editSettings.mode === 'Normal') {
            const mhdrFrm = gObj.getMovableVirtualHeader().querySelector('.e-gridform');
            const mCntFrm = gObj.getMovableVirtualContent().querySelector('.e-gridform');
            const mvblEle = [mhdrFrm || mCntFrm];
            let frHdrFrm;
            let frCntFrm;
            let frEle = [];
            if (gObj.getFrozenMode() === literals.leftRight) {
                frHdrFrm = gObj.getFrozenRightHeader().querySelector('.e-gridform');
                frCntFrm = gObj.getFrozenRightContent().querySelector('.e-gridform');
                frEle = [frHdrFrm || frCntFrm];
            }
            gridActionHandler(this.parent, (tableName, elements) => {
                for (const ele of elements) {
                    if (ele) {
                        editedData = gObj.editModule.getCurrentEditedData(ele, editedData);
                    }
                }
            }, [[], mvblEle, frEle]);
        }
        let eleLength = [].slice.call(gObj.element.getElementsByClassName(literals.editedRow)).length;
        if (!data.isAdd && Object.keys(this.currentVirtualData).length && !eleLength) {
            eleLength = 1;
        }
        if (isDlg ? dlgWrapper.getElementsByClassName(literals.editedRow).length : eleLength) {
            args.action = 'edit';
            gObj.trigger(events.actionBegin, args, (endEditArgs) => {
                if (endEditArgs.cancel) {
                    return;
                }
                gObj.showSpinner();
                gObj.notify(events.updateData, endEditArgs);
            });
        }
        else {
            args.action = 'add';
            args.selectedRow = 0;
            args.index = this.addedRowIndex;
            gObj.notify(events.virtualScrollEditSuccess, {});
            gObj.notify(events.modelChanged, args);
            this.addedRowIndex = null;
            if (args.cancel) {
                return;
            }
        }
    }
    destroyElements() {
        const gObj = this.parent;
        gObj.editModule.destroyWidgets();
        gObj.editModule.destroyForm();
        this.parent.notify(events.dialogDestroy, {});
    }
    editHandler(args) {
        if (args.promise) {
            args.promise.then((e) => this.edSucc(e, args)).catch((e) => this.edFail(e));
        }
        else {
            this.editSuccess(args.data, args);
        }
    }
    edSucc(e, args) {
        this.editSuccess(e, args);
    }
    edFail(e) {
        this.editFailure(e);
    }
    updateCurrentViewData(data) {
        if (!this.parent.enableVirtualization && !this.parent.enableInfiniteScrolling) {
            this.parent.getCurrentViewRecords()[this.editRowIndex] = data;
        }
    }
    requestSuccess(args) {
        if (this.parent.editModule.formObj && !this.parent.editModule.formObj.isDestroyed) {
            this.destroyElements();
            this.stopEditStatus();
            if (this.parent.editSettings.mode === 'Dialog' && args.action !== 'add' &&
                this.parent.selectionModule) {
                this.parent.element.querySelector('.e-dlgeditrow').classList.remove('e-dlgeditrow');
            }
        }
    }
    editSuccess(e, args) {
        if (!isNullOrUndefined(e) && !(e instanceof Array)) {
            const rowData = 'rowData';
            args.data = extend({}, extend({}, args[rowData], args.data), e);
        }
        this.requestSuccess(args);
        this.parent.trigger(events.beforeDataBound, args);
        args.type = events.actionComplete;
        this.parent.isEdit = false;
        this.refreshRow(args.data);
        this.parent.notify(events.virtualScrollEditSuccess, args);
        this.parent.editModule.checkLastRow(args.row);
        this.parent.editModule.isLastRow = false;
        this.updateCurrentViewData(args.data);
        this.blazorTemplate();
        this.editRowIndex = null;
        this.parent.trigger(events.actionComplete, args);
        if (!(this.parent.isCheckBoxSelection || this.parent.selectionSettings.type === 'Multiple')
            || (!this.parent.isPersistSelection) && !this.parent.selectionSettings.checkboxOnly) {
            if (this.parent.editSettings.mode !== 'Dialog') {
                this.parent.selectRow(this.rowIndex > -1 ? this.rowIndex : this.editRowIndex);
            }
        }
        this.parent.hideSpinner();
    }
    closeForm() {
        if (!this.cloneRow && this.parent.isEdit) {
            this.stopEditStatus();
        }
        if (this.cloneRow) {
            this.cloneRow.remove();
            this.cloneRow = null;
            this.originalRow.classList.remove('e-hiddenrow');
        }
        if (this.parent.isFrozenGrid() && this.cloneFrozen) {
            this.cloneFrozen.remove();
            this.frozen.classList.remove('e-hiddenrow');
        }
    }
    blazorTemplate() {
        const cols = this.parent.getColumns();
        if (this.parent.editSettings.template && this.parent.editSettings.mode === 'Normal') {
            updateBlazorTemplate(this.parent.element.id + 'editSettingsTemplate', 'Template', this.parent.editSettings);
        }
        for (let i = 0; i < cols.length; i++) {
            const col = cols[i];
            if (col.template) {
                updateBlazorTemplate(this.parent.element.id + col.uid, 'Template', col, false);
            }
            if (col.editTemplate) {
                updateBlazorTemplate(this.parent.element.id + col.uid + 'editTemplate', 'EditTemplate', col);
            }
        }
    }
    editFailure(e) {
        this.parent.trigger(events.actionFailure, ({ error: e }));
        this.parent.hideSpinner();
        this.parent.log('actionfailure', { error: e });
    }
    needRefresh() {
        let refresh = true;
        const editedRow = this.parent.element.querySelector('.e-gridform');
        if ((this.parent.enableVirtualization || this.parent.infiniteScrollSettings.enableCache)
            && this.parent.editSettings.mode === 'Normal' && !editedRow) {
            refresh = false;
        }
        return refresh;
    }
    refreshRow(data) {
        const frzCols = this.parent.isFrozenGrid();
        const row = new RowRenderer(this.serviceLocator, null, this.parent);
        let rowObj = this.parent.getRowObjectFromUID(this.uid);
        if (rowObj) {
            rowObj.changes = data;
            this.parent.notify(events.refreshVirtualCache, { data: data });
            refreshForeignData(rowObj, this.parent.getForeignKeyColumns(), rowObj.changes);
            if (this.needRefresh()) {
                row.refresh(rowObj, this.parent.getColumns(), true);
            }
            const tr = [].slice.call(this.parent.element.querySelectorAll('[data-rowindex="' + rowObj.index + '"]'));
            if (frzCols && tr.length) {
                for (let i = 0; i < tr.length; i++) {
                    const rowUid = tr[i].getAttribute('data-uid');
                    if (rowUid !== this.uid) {
                        rowObj = this.parent.getRowObjectFromUID(rowUid);
                        rowObj.changes = data;
                        row.refresh(rowObj, this.parent.getColumns(), true);
                        this.parent.editModule.checkLastRow(tr[i]);
                    }
                }
            }
        }
    }
    closeEdit() {
        if (!this.parent.isEdit) {
            return;
        }
        const gObj = this.parent;
        const args = extend(this.args, {
            requestType: 'cancel', type: events.actionBegin, cancel: false, data: this.previousData, selectedRow: gObj.selectedRowIndex
        });
        gObj.notify(events.virtualScrollEditCancel, args);
        this.blazorTemplate();
        gObj.trigger(events.actionBegin, args, (closeEditArgs) => {
            if (closeEditArgs.cancel) {
                return;
            }
            if (this.parent.editSettings.mode === 'Dialog') {
                this.parent.notify(events.dialogDestroy, {});
            }
            gObj.isEdit = false;
            this.stopEditStatus();
            closeEditArgs.type = events.actionComplete;
            if (gObj.editSettings.mode !== 'Dialog') {
                this.refreshRow(closeEditArgs.data);
            }
            const isLazyLoad = gObj.groupSettings.enableLazyLoading && gObj.groupSettings.columns.length
                && !gObj.getContentTable().querySelector('tr.e-emptyrow');
            if (!gObj.getContentTable().querySelector('tr.e-emptyrow') &&
                !gObj.getContentTable().querySelector('tr.e-row') && !isLazyLoad) {
                gObj.renderModule.emptyRow();
            }
            if (gObj.editSettings.mode !== 'Dialog') {
                gObj.selectRow(this.rowIndex);
            }
            gObj.trigger(events.actionComplete, closeEditArgs);
        });
    }
    addRecord(data, index) {
        const gObj = this.parent;
        this.addedRowIndex = index = !isNullOrUndefined(index) ? index : 0;
        if (data) {
            gObj.notify(events.modelChanged, {
                requestType: 'save', type: events.actionBegin, data: data, selectedRow: 0, action: 'add', index: index
            });
            return;
        }
        if (gObj.isEdit) {
            return;
        }
        this.previousData = {};
        this.uid = '';
        const cols = gObj.getColumns();
        const rowData = { virtualData: {}, isScroll: false };
        this.parent.notify(events.getVirtualData, rowData);
        for (let i = 0; i < cols.length; i++) {
            if (rowData.isScroll && cols[i].getFreezeTableName() !== 'movable') {
                continue;
            }
            if (cols[i].field) {
                DataUtil.setValue(cols[i].field, cols[i].defaultValue, this.previousData);
            }
        }
        const args = {
            cancel: false, foreignKeyData: {},
            requestType: 'add', data: this.previousData, type: events.actionBegin, index: index,
            rowData: this.previousData, target: undefined, isScroll: rowData.isScroll
        };
        if ((this.parent.enableVirtualization || this.parent.infiniteScrollSettings.enableCache)
            && Object.keys(rowData.virtualData).length) {
            args.data = args.rowData = rowData.virtualData;
        }
        if (!args.isScroll) {
            this.parent.notify(events.createVirtualValidationForm, { uid: this.uid, prevData: this.previousData, argsCreator: this.getEditArgs.bind(this), renderer: this.renderer });
            gObj.trigger(events.actionBegin, args, (addArgs) => {
                if (addArgs.cancel) {
                    return;
                }
                this.inlineAddHandler(addArgs);
            });
        }
        else {
            this.inlineAddHandler(args);
        }
    }
    inlineAddHandler(addArgs) {
        const gObj = this.parent;
        gObj.isEdit = true;
        if (gObj.editSettings.mode !== 'Dialog') {
            gObj.clearSelection();
        }
        this.renderer.addNew(addArgs);
        gObj.editModule.applyFormValidation();
        addArgs.type = events.actionComplete;
        addArgs.row = gObj.element.querySelector('.' + literals.addedRow);
        gObj.trigger(events.actionComplete, addArgs);
        this.args = addArgs;
    }
    deleteRecord(fieldname, data) {
        this.editRowIndex = this.parent.selectedRowIndex;
        if (data) {
            data = (data instanceof Array) ? data : [data];
            const gObj = this.parent;
            const dataLen = Object.keys(data).length;
            fieldname = fieldname || this.parent.getPrimaryKeyFieldNames()[0];
            for (let i = 0; i < dataLen; i++) {
                let tmpRecord;
                const contained = gObj.currentViewData.some((record) => {
                    tmpRecord = record;
                    return data[i] === getObject(fieldname, record) || data[i] === record;
                });
                data[i] = contained ? tmpRecord : data[i][fieldname] ? data[i] : { [fieldname]: data[i] };
            }
        }
        const args = {
            requestType: 'delete', type: events.actionBegin, foreignKeyData: {},
            data: data ? data : this.parent.getSelectedRecords(), tr: this.parent.getSelectedRows(), cancel: false
        };
        if (!isNullOrUndefined(this.parent.commandDelIndex)) {
            args.data[0] =
                this.parent.getRowObjectFromUID(this.parent.getRowByIndex(this.parent.commandDelIndex).getAttribute('data-uid')).data;
        }
        if (this.parent.enableVirtualization && args.data.length > 1) {
            const uid = this.parent.getSelectedRows()[0].getAttribute('data-uid');
            args.data = [this.parent.getRowObjectFromUID(uid).data];
        }
        this.parent.notify(events.modelChanged, args);
    }
    stopEditStatus() {
        const gObj = this.parent;
        const addElements = [].slice.call(gObj.element.getElementsByClassName(literals.addedRow));
        const editElements = [].slice.call(gObj.element.getElementsByClassName(literals.editedRow));
        for (let i = 0; i < addElements.length; i++) {
            remove(addElements[i]);
        }
        for (let i = 0; i < editElements.length; i++) {
            editElements[i].classList.remove(literals.editedRow);
        }
    }
    /**
     * @returns {void}
     * @hidden
     */
    addEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.evtHandlers = [{ event: events.crudAction, handler: this.editHandler },
            { event: events.doubleTap, handler: this.dblClickHandler },
            { event: events.click, handler: this.clickHandler },
            { event: events.recordAdded, handler: this.requestSuccess },
            { event: events.dblclick, handler: this.dblClickHandler },
            { event: events.deleteComplete, handler: this.editComplete },
            { event: events.saveComplete, handler: this.editComplete },
            { event: events.rowModeChange, handler: this.closeEdit },
            { event: events.closeInline, handler: this.closeForm }];
        addRemoveEventListener(this.parent, this.evtHandlers, true, this);
    }
    /**
     * @returns {void}
     * @hidden
     */
    removeEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        addRemoveEventListener(this.parent, this.evtHandlers, false);
    }
    /**
     * @returns {void}
     * @hidden
     */
    destroy() {
        this.removeEventListener();
        this.renderer.destroy();
    }
}
