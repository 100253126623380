import { isNullOrUndefined, extend } from '@syncfusion/ej2-base';
import { isEditable, createEditElement } from '../base/util';
import { TextBox } from '@syncfusion/ej2-inputs';
import { EditCellBase } from './edit-cell-base';
/**
 * `DefaultEditCell` is used to handle default cell type editing.
 *
 * @hidden
 */
export class DefaultEditCell extends EditCellBase {
    create(args) {
        const attr = {
            type: 'text', value: !isNullOrUndefined(args.value) ? args.value : '', style: 'text-align:' + args.column.textAlign
        };
        return createEditElement(this.parent, args.column, 'e-field e-input e-defaultcell', attr);
    }
    read(element) {
        return element.value;
    }
    write(args) {
        const col = args.column;
        const isInline = this.parent.editSettings.mode !== 'Dialog';
        this.obj = new TextBox(extend({
            element: args.element, floatLabelType: this.parent.editSettings.mode !== 'Dialog' ? 'Never' : 'Always',
            enableRtl: this.parent.enableRtl, enabled: isEditable(args.column, args.requestType, args.element),
            placeholder: isInline ? '' : args.column.headerText,
            cssClass: this.parent.cssClass ? this.parent.cssClass : ''
        }, col.edit.params));
        this.obj.appendTo(args.element);
    }
}
