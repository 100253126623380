import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { iterateArrayOrObject, isGroupAdaptive } from '../base/util';
import * as events from '../base/constant';
/**
 * The `ShowHide` module is used to control column visibility.
 */
export class ShowHide {
    /**
     * Constructor for the show hide module.
     *
     * @param {IGrid} parent - specifies the IGrid
     * @hidden
     */
    constructor(parent) {
        this.parent = parent;
    }
    /**
     * Shows a column by column name.
     *
     * @param  {string|string[]} columnName - Defines a single or collection of column names to show.
     * @param  {string} showBy - Defines the column key either as field name or header text.
     * @returns {void}
     */
    show(columnName, showBy) {
        const keys = this.getToggleFields(columnName);
        const columns = this.getColumns(keys, showBy);
        this.parent.notify(events.tooltipDestroy, { module: 'edit' });
        for (let i = 0; i < columns.length; i++) {
            columns[i].visible = true;
        }
        this.setVisible(columns);
    }
    /**
     * Hides a column by column name.
     *
     * @param  {string|string[]} columnName - Defines a single or collection of column names to hide.
     * @param  {string} hideBy - Defines the column key either as field name or header text.
     * @returns {void}
     */
    hide(columnName, hideBy) {
        const keys = this.getToggleFields(columnName);
        const columns = this.getColumns(keys, hideBy);
        this.parent.notify(events.tooltipDestroy, { module: 'edit' });
        for (let i = 0; i < columns.length; i++) {
            columns[i].visible = false;
        }
        this.setVisible(columns);
    }
    getToggleFields(key) {
        let finalized = [];
        if (typeof key === 'string') {
            finalized = [key];
        }
        else {
            finalized = key;
        }
        return finalized;
    }
    getColumns(keys, getKeyBy) {
        const columns = iterateArrayOrObject(keys, (key) => {
            return iterateArrayOrObject(this.parent.columnModel, (item) => {
                if (item[getKeyBy] === key) {
                    return item;
                }
                return undefined;
            })[0];
        });
        return columns;
    }
    /**
     * Shows or hides columns by given column collection.
     *
     * @private
     * @param {Column[]} columns - Specifies the columns.
     * @param {Column[]} changedStateColumns - specifies the changedStateColumns
     * @returns {void}
     */
    setVisible(columns, changedStateColumns = []) {
        changedStateColumns = (changedStateColumns.length > 0) ? changedStateColumns : columns;
        const args = {
            requestType: 'columnstate',
            cancel: false,
            columns: changedStateColumns
        };
        const cancel = 'cancel';
        if (this.parent.enableInfiniteScrolling && this.parent.allowGrouping
            && this.parent.groupModule.groupSettings.columns.length > 0) {
            this.parent.contentModule.visibleRows = [];
        }
        this.parent.trigger(events.actionBegin, args, (showHideArgs) => {
            const currentViewCols = this.parent.getColumns();
            columns = isNullOrUndefined(columns) ? currentViewCols : columns;
            if (showHideArgs[cancel]) {
                this.parent.notify(events.resetColumns, { showHideArgs: showHideArgs });
                if (columns.length > 0) {
                    columns[0].visible = true;
                }
                return;
            }
            if (isGroupAdaptive(this.parent)) {
                this.parent.contentModule.emptyVcRows();
            }
            if (this.parent.allowSelection && this.parent.getSelectedRecords().length &&
                !this.parent.selectionSettings.persistSelection) {
                this.parent.clearSelection();
            }
            if (this.parent.enableColumnVirtualization) {
                const colsInCurrentView = columns.filter((col1) => (currentViewCols.some((col2) => col1.field === col2.field)));
                if (colsInCurrentView.length) {
                    this.parent.notify(events.columnVisibilityChanged, columns);
                }
            }
            else {
                this.parent.notify(events.columnVisibilityChanged, columns);
            }
            const params = {
                requestType: 'columnstate',
                columns: changedStateColumns
            };
            this.parent.trigger(events.actionComplete, params);
            if (this.parent.columnQueryMode !== 'All') {
                this.parent.refresh();
            }
        });
    }
}
