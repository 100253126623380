import { extend } from '@syncfusion/ej2-base';
import { TimePicker } from '@syncfusion/ej2-calendars';
import { isEditable, getObject } from '../base/util';
import { EditCellBase } from './edit-cell-base';
/**
 * `TimePickerEditCell` is used to handle Timepicker cell type editing.
 *
 * @hidden
 */
export class TimePickerEditCell extends EditCellBase {
    write(args) {
        const isInlineEdit = this.parent.editSettings.mode !== 'Dialog';
        let rowDataValue = getObject(args.column.field, args.rowData);
        rowDataValue = rowDataValue ? new Date(rowDataValue) : null;
        this.obj = new TimePicker(extend({
            floatLabelType: isInlineEdit ? 'Never' : 'Always',
            value: rowDataValue,
            placeholder: isInlineEdit ?
                '' : args.column.headerText, enableRtl: this.parent.enableRtl,
            enabled: isEditable(args.column, args.requestType, args.element),
            cssClass: this.parent.cssClass ? this.parent.cssClass : null
        }, args.column.edit.params));
        this.obj.appendTo(args.element);
    }
}
