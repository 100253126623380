import { EventHandler, remove, Browser } from '@syncfusion/ej2-base';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { Query, DataManager, Predicate } from '@syncfusion/ej2-data';
import { Dialog } from '@syncfusion/ej2-popups';
import { DropDownList, AutoComplete } from '@syncfusion/ej2-dropdowns';
import { NumericTextBox } from '@syncfusion/ej2-inputs';
import { RadioButton, CheckBox } from '@syncfusion/ej2-buttons';
import { distinctStringValues, isComplexField, getComplexFieldID, getCustomDateFormat, applyBiggerTheme, performComplexDataOperation, registerEventHandlers, removeEventHandlers, clearReactVueTemplates } from '../base/util';
import { DatePicker, DateTimePicker } from '@syncfusion/ej2-calendars';
import { parentsUntil, appendChildren, extend, eventPromise } from '../base/util';
import * as events from '../base/constant';
import { ContextMenu } from '@syncfusion/ej2-navigations';
import { CheckBoxFilterBase } from '../common/checkbox-filter-base';
import * as literals from '../base/string-literals';
/**
 * @hidden
 * `ExcelFilter` module is used to handle filtering action.
 */
export class ExcelFilterBase extends CheckBoxFilterBase {
    /**
     * Constructor for excel filtering module
     *
     * @param {IXLFilter} parent - parent details
     * @param {Object} customFltrOperators - operator details
     * @hidden
     */
    constructor(parent, customFltrOperators) {
        super(parent);
        this.childRefs = [];
        this.eventHandlers = {};
        this.isDevice = false;
        this.customFilterOperators = customFltrOperators;
        this.isExcel = true;
    }
    getCMenuDS(type, operator) {
        const options = {
            number: ['Equal', 'NotEqual', '', 'LessThan', 'LessThanOrEqual', 'GreaterThan',
                'GreaterThanOrEqual', 'Between', '', 'CustomFilter'],
            string: ['Equal', 'NotEqual', '', 'StartsWith', 'EndsWith', '', 'Contains', '', 'CustomFilter']
        };
        options.date = options.number;
        options.datetime = options.number;
        const model = [];
        for (let i = 0; i < options[type].length; i++) {
            if (options[type][i].length) {
                if (operator) {
                    model.push({
                        text: this.getLocalizedLabel(options[type][i]) + '...',
                        iconCss: 'e-icons e-icon-check ' + (operator === options[type][i].toLowerCase() ? '' : 'e-emptyicon')
                    });
                }
                else {
                    model.push({
                        text: this.getLocalizedLabel(options[type][i]) + '...'
                    });
                }
            }
            else {
                model.push({ separator: true });
            }
        }
        return model;
    }
    /**
     * To destroy the filter bar.
     *
     * @returns {void}
     * @hidden
     */
    destroy() {
        if (this.dlg) {
            this.unwireExEvents();
            super.closeDialog();
        }
        if (!this.isDevice && this.menuObj) {
            const li = this.menuObj.element.querySelector('li.e-focused');
            if (!(li && parentsUntil(li, 'e-excel-menu'))) {
                this.destroyCMenu();
            }
        }
        if (this.dlgObj && !this.dlgObj.isDestroyed) {
            this.removeDialog();
        }
    }
    createMenu(type, isFiltered, isCheckIcon, eleOptions) {
        const options = { string: 'TextFilter', date: 'DateFilter', datetime: 'DateTimeFilter', number: 'NumberFilter' };
        this.menu = this.parent.createElement('div', { className: 'e-contextmenu-wrapper' });
        if (this.parent.enableRtl) {
            this.menu.classList.add('e-rtl');
        }
        else {
            this.menu.classList.remove('e-rtl');
        }
        if (this.parent.cssClass) {
            this.menu.classList.add(this.parent.cssClass);
        }
        const ul = this.parent.createElement('ul');
        const icon = isFiltered ? 'e-excl-filter-icon e-filtered' : 'e-excl-filter-icon';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (this.parent.allowSorting && this.parent.getModuleName() === 'grid'
            && !this.options.isResponsiveFilter) {
            const hdrele = this.parent.getColumnHeaderByUid(eleOptions.uid).getAttribute('aria-sort');
            const colIsSort = this.parent.getColumnByField(eleOptions.field).allowSorting;
            const isAsc = (!colIsSort || hdrele === 'ascending') ? 'e-disabled e-excel-ascending' : 'e-excel-ascending';
            const isDesc = (!colIsSort || hdrele === 'descending') ? 'e-disabled e-excel-descending' : 'e-excel-descending';
            const ascName = (type === 'string') ? this.getLocalizedLabel('SortAtoZ') : (type === 'datetime' || type === 'date') ?
                this.getLocalizedLabel('SortByOldest') : this.getLocalizedLabel('SortSmallestToLargest');
            const descName = (type === 'string') ? this.getLocalizedLabel('SortZtoA') : (type === 'datetime' || type === 'date') ?
                this.getLocalizedLabel('SortByNewest') : this.getLocalizedLabel('SortLargestToSmallest');
            ul.appendChild(this.createMenuElem(ascName, isAsc, 'e-sortascending'));
            ul.appendChild(this.createMenuElem(descName, isDesc, 'e-sortdescending'));
            const separator = this.parent.createElement('li', { className: 'e-separator e-menu-item e-excel-separator' });
            ul.appendChild(separator);
        }
        if (!this.options.isResponsiveFilter) {
            ul.appendChild(this.createMenuElem(this.getLocalizedLabel('ClearFilter'), isFiltered ? '' : 'e-disabled', icon));
        }
        if (type !== 'boolean') {
            ul.appendChild(this.createMenuElem(this.getLocalizedLabel(options[type]), 'e-submenu', isCheckIcon && this.ensureTextFilter() ? 'e-icon-check' : icon + ' e-emptyicon', true));
        }
        this.menu.appendChild(ul);
        this.parent.notify(events.beforeFltrcMenuOpen, { element: this.menu });
        this.parent.notify(events.refreshCustomFilterClearBtn, { isFiltered: isFiltered });
    }
    createMenuElem(val, className, iconName, isSubMenu) {
        const li = this.parent.createElement('li', { className: className + ' e-menu-item' });
        li.innerHTML = val;
        li.insertBefore(this.parent.createElement('span', { className: 'e-menu-icon e-icons ' + iconName }), li.firstChild);
        if (isSubMenu) {
            li.appendChild(this.parent.createElement('span', { className: 'e-icons e-caret' }));
        }
        return li;
    }
    wireExEvents() {
        EventHandler.add(this.dlg, 'mouseover', this.hoverHandler, this);
        EventHandler.add(this.dlg, 'click', this.clickExHandler, this);
    }
    unwireExEvents() {
        EventHandler.remove(this.dlg, 'mouseover', this.hoverHandler);
        EventHandler.remove(this.dlg, 'click', this.clickExHandler);
    }
    clickExHandler(e) {
        const options = { string: 'TextFilter', date: 'DateFilter', datetime: 'DateTimeFilter', number: 'NumberFilter' };
        const menuItem = parentsUntil(e.target, 'e-menu-item');
        if (menuItem) {
            if (this.getLocalizedLabel('ClearFilter') === menuItem.innerText.trim()) {
                this.clearFilter();
                this.closeDialog();
            }
            else if (this.options.isResponsiveFilter
                && this.getLocalizedLabel(options[this.options.type]) === menuItem.innerText.trim()) {
                this.hoverHandler(e);
            }
        }
    }
    destroyCMenu() {
        this.isCMenuOpen = false;
        if (this.menuObj && !this.menuObj.isDestroyed) {
            this.menuObj.destroy();
            remove(this.cmenu);
            this.parent.notify(events.renderResponsiveCmenu, { target: null, header: '', isOpen: false, col: this.options.column });
        }
    }
    hoverHandler(e) {
        if (this.options.isResponsiveFilter && e.type === 'mouseover') {
            return;
        }
        const target = e.target.querySelector('.e-contextmenu');
        const li = parentsUntil(e.target, 'e-menu-item');
        const focused = this.menu.querySelector('.e-focused');
        let isSubMenu;
        if (focused) {
            focused.classList.remove('e-focused');
        }
        if (li) {
            li.classList.add('e-focused');
            isSubMenu = li.classList.contains('e-submenu');
        }
        if (target) {
            return;
        }
        if (!isSubMenu) {
            const submenu = this.menu.querySelector('.e-submenu');
            if (!isNullOrUndefined(submenu)) {
                submenu.classList.remove('e-selected');
            }
            this.destroyCMenu();
        }
        const selectedMenu = this.ensureTextFilter();
        if (!this.isCMenuOpen && isSubMenu) {
            li.classList.add('e-selected');
            this.isCMenuOpen = true;
            const menuOptions = {
                items: this.getCMenuDS(this.options.type, selectedMenu ? selectedMenu.replace(/\s/g, '') : undefined),
                select: this.selectHandler.bind(this),
                onClose: this.destroyCMenu.bind(this),
                enableRtl: this.parent.enableRtl,
                beforeClose: this.preventClose.bind(this),
                cssClass: this.options.isResponsiveFilter && this.parent.cssClass ?
                    'e-res-contextmenu-wrapper' + ' ' + this.parent.cssClass : this.options.isResponsiveFilter ?
                    'e-res-contextmenu-wrapper' : this.parent.cssClass ? this.parent.cssClass : ''
            };
            this.parent.element.appendChild(this.cmenu);
            this.menuObj = new ContextMenu(menuOptions, this.cmenu);
            const client = this.menu.querySelector('.e-submenu').getBoundingClientRect();
            const pos = { top: 0, left: 0 };
            if (this.options.isResponsiveFilter) {
                const options = { string: 'TextFilter', date: 'DateFilter', datetime: 'DateTimeFilter', number: 'NumberFilter' };
                const content = document.querySelector('.e-responsive-dialog > .e-dlg-header-content');
                const height = content.offsetHeight + 4;
                this.menuObj.element.style.height = 'calc(100% - ' + height + 'px)';
                this.menuObj.open(height, 0, document.body);
                const header = this.getLocalizedLabel(options[this.options.type]);
                this.parent.notify(events.renderResponsiveCmenu, {
                    target: this.menuObj.element.parentElement, header: header, isOpen: true
                });
            }
            else {
                if (Browser.isDevice) {
                    this.isDevice = true;
                    const contextRect = this.getContextBounds();
                    pos.top = (window.innerHeight - contextRect.height) / 2;
                    pos.left = (window.innerWidth - contextRect.width) / 2;
                    this.closeDialog();
                    this.isDevice = false;
                }
                else {
                    pos.top = Browser.isIE ? window.pageYOffset + client.top : window.scrollY + client.top;
                    pos.left = this.getCMenuYPosition(this.dlg);
                }
                this.menuObj.open(pos.top, pos.left, e.target);
            }
            applyBiggerTheme(this.parent.element, this.menuObj.element.parentElement);
        }
    }
    ensureTextFilter() {
        let selectedMenu;
        const predicates = this.existingPredicate[this.options.field];
        if (predicates && predicates.length === 2) {
            if (predicates[0].operator === 'greaterthanorequal' && predicates[1].operator === 'lessthanorequal') {
                selectedMenu = 'between';
            }
            else {
                selectedMenu = 'customfilter';
            }
        }
        else {
            if (predicates && predicates.length === 1) {
                this.optrData = this.customFilterOperators[this.options.type + 'Operator'];
                selectedMenu = predicates[0].operator;
            }
        }
        return selectedMenu;
    }
    preventClose(args) {
        if (this.options && this.options.isResponsiveFilter && args.event) {
            const target = args.event.target;
            const isFilterBack = target.classList.contains('e-resfilterback')
                || target.classList.contains('e-res-back-btn') || target.classList.contains('e-menu-item');
            args.cancel = !isFilterBack;
        }
        else {
            if (args.event instanceof MouseEvent && args.event.target.classList.contains('e-submenu')) {
                args.cancel = true;
            }
        }
    }
    getContextBounds() {
        this.menuObj.element.style.display = 'block';
        return this.menuObj.element.getBoundingClientRect();
    }
    getCMenuYPosition(target) {
        const contextWidth = this.getContextBounds().width;
        const targetPosition = target.getBoundingClientRect();
        const leftPos = targetPosition.right + contextWidth - this.parent.element.clientWidth;
        let targetBorder = target.offsetWidth - target.clientWidth;
        targetBorder = targetBorder ? targetBorder + 1 : 0;
        return (leftPos < 1) ? (targetPosition.right + 1 - targetBorder) : (targetPosition.left - contextWidth - 1 + targetBorder);
    }
    openDialog(options) {
        this.updateModel(options);
        this.getAndSetChkElem(options);
        this.showDialog(options);
        if (options.cancel) {
            return;
        }
        this.dialogObj.dataBind();
        const filterLength = (this.existingPredicate[options.field] && this.existingPredicate[options.field].length) ||
            this.options.filteredColumns.filter((col) => {
                return this.options.field === col.field;
            }).length;
        this.createMenu(options.type, filterLength > 0, (filterLength === 1 || filterLength === 2), options);
        this.dlg.insertBefore(this.menu, this.dlg.firstChild);
        this.dlg.classList.add('e-excelfilter');
        if (this.parent.enableRtl) {
            this.dlg.classList.add('e-rtl');
        }
        this.dlg.classList.remove('e-checkboxfilter');
        this.cmenu = this.parent.createElement('ul', { className: 'e-excel-menu' });
        if (options.column.showColumnMenu) {
            this.parent.notify(events.filterDialogCreated, {});
        }
        this.wireExEvents();
    }
    closeDialog() {
        this.destroy();
    }
    selectHandler(e) {
        if (e.item) {
            this.parent.notify(events.filterCmenuSelect, {});
            this.menuItem = e.item;
            this.renderDialogue(e);
        }
    }
    /**
     * @hidden
     * @param {MenuEventArgs} e - event args
     * @returns {void}
     */
    renderDialogue(e) {
        const target = e ? e.element : undefined;
        const column = this.options.field;
        const isComplex = !isNullOrUndefined(column) && isComplexField(column);
        const complexFieldName = !isNullOrUndefined(column) && getComplexFieldID(column);
        const mainDiv = this.parent.createElement('div', {
            className: 'e-xlfl-maindiv',
            id: isComplex ? complexFieldName + '-xlflmenu' : column + '-xlflmenu'
        });
        this.dlgDiv = this.parent.createElement('div', {
            className: 'e-xlflmenu',
            id: isComplex ? complexFieldName + '-xlfldlg' : column + '-xlfldlg'
        });
        if (this.options.isResponsiveFilter) {
            const responsiveCnt = document.querySelector('.e-resfilter > .e-dlg-content > .e-xl-customfilterdiv');
            responsiveCnt.appendChild(this.dlgDiv);
        }
        else {
            this.parent.element.appendChild(this.dlgDiv);
        }
        this.dlgObj = new Dialog({
            header: this.getLocalizedLabel('CustomFilter'),
            isModal: true,
            overlayClick: this.removeDialog.bind(this),
            showCloseIcon: true,
            closeOnEscape: false,
            target: document.body,
            // target: this.parent.element,
            visible: false,
            enableRtl: this.parent.enableRtl,
            open: () => {
                const row = this.dlgObj.element.querySelector('table.e-xlfl-table>tr');
                if (this.options.column.filterTemplate) {
                    row.querySelector('#' + this.options.column.field + '-xlfl-frstvalue').focus();
                }
                else {
                    //(row.cells[1].querySelector('input:not([type=hidden])') as HTMLElement).focus();
                }
            },
            close: this.removeDialog.bind(this),
            created: this.createdDialog.bind(this, target, column),
            buttons: [{
                    click: this.filterBtnClick.bind(this, column),
                    buttonModel: {
                        content: this.getLocalizedLabel('OKButton'), isPrimary: true,
                        cssClass: this.parent.cssClass ? 'e-xlfl-okbtn' + ' ' + this.parent.cssClass : 'e-xlfl-okbtn'
                    }
                },
                {
                    click: this.removeDialog.bind(this),
                    buttonModel: { content: this.getLocalizedLabel('CancelButton'),
                        cssClass: this.parent.cssClass ? 'e-xlfl-cancelbtn' + ' ' + this.parent.cssClass : 'e-xlfl-cancelbtn' }
                }],
            content: mainDiv,
            width: 430,
            animationSettings: { effect: 'None' },
            cssClass: this.parent.cssClass ? this.parent.cssClass : ''
        });
        const isStringTemplate = 'isStringTemplate';
        this.dlgObj[isStringTemplate] = true;
        this.renderResponsiveDialog();
        this.dlgDiv.setAttribute('aria-label', this.getLocalizedLabel('CustomFilterDialogARIA'));
        this.childRefs.push(this.dlgObj);
        this.dlgObj.appendTo(this.dlgDiv);
    }
    renderResponsiveDialog() {
        if (this.options.isResponsiveFilter) {
            const rowResponsiveDlg = document.querySelector('.e-row-responsive-filter');
            if (rowResponsiveDlg) {
                rowResponsiveDlg.classList.remove('e-row-responsive-filter');
            }
            this.dlgObj.buttons = [{}];
            this.dlgObj.header = undefined;
            this.dlgObj.position = { X: '', Y: '' };
            this.dlgObj.target = document.querySelector('.e-resfilter > .e-dlg-content > .e-xl-customfilterdiv');
            this.dlgObj.width = '100%';
            this.dlgObj.isModal = false;
            this.dlgObj.showCloseIcon = false;
        }
    }
    /**
     * @hidden
     * @returns {void}
     */
    removeDialog() {
        this.parent.notify(events.customFilterClose, {});
        if ((this.parent.isReact || this.parent.isVue) && this.parent.destroyTemplate !== undefined) {
            clearReactVueTemplates(this.parent, ['filterTemplate']);
        }
        this.removeObjects(this.childRefs);
        remove(this.dlgDiv);
        this.parent.notify(events.filterDialogClose, {});
    }
    createdDialog(target, column) {
        this.renderCustomFilter(target, column);
        this.dlgObj.element.style.left = '0px';
        if (!this.options.isResponsiveFilter) {
            this.dlgObj.element.style.top = '0px';
        }
        else {
            const content = document.querySelector('.e-responsive-dialog > .e-dlg-header-content');
            const height = content.offsetHeight + 4;
            this.dlgObj.element.style.top = height + 'px';
        }
        if (!this.options.isResponsiveFilter && Browser.isDevice && window.innerWidth < 440) {
            this.dlgObj.element.style.width = '90%';
        }
        this.parent.notify(events.beforeCustomFilterOpen, { column: column, dialog: this.dialogObj });
        this.dlgObj.show();
        applyBiggerTheme(this.parent.element, this.dlgObj.element.parentElement);
    }
    renderCustomFilter(target, column) {
        const dlgConetntEle = this.dlgObj.element.querySelector('.e-xlfl-maindiv');
        const dlgFields = this.parent.createElement('div', { innerHTML: this.getLocalizedLabel('ShowRowsWhere'), className: 'e-xlfl-dlgfields' });
        dlgConetntEle.appendChild(dlgFields);
        //column name
        const fieldSet = this.parent.createElement('div', { innerHTML: this.options.displayName, className: 'e-xlfl-fieldset' });
        dlgConetntEle.appendChild(fieldSet);
        this.renderFilterUI(column, dlgConetntEle);
    }
    /**
     * @hidden
     * @param {string} col - Defines column details
     * @returns {void}
     */
    filterBtnClick(col) {
        const isComplex = !isNullOrUndefined(col) && isComplexField(col);
        const complexFieldName = !isNullOrUndefined(col) && getComplexFieldID(col);
        const colValue = isComplex ? complexFieldName : col;
        const fValue = this.dlgDiv.querySelector('#' + colValue + '-xlfl-frstvalue').ej2_instances[0];
        const fOperator = this.dlgDiv.querySelector('#' + colValue + '-xlfl-frstoptr').ej2_instances[0];
        const sValue = this.dlgDiv.querySelector('#' + colValue + '-xlfl-secndvalue').ej2_instances[0];
        const sOperator = this.dlgDiv.querySelector('#' + colValue + '-xlfl-secndoptr').ej2_instances[0];
        let checkBoxValue;
        if (this.options.type === 'string') {
            const checkBox = this.dlgDiv.querySelector('#' + colValue + '-xlflmtcase').ej2_instances[0];
            checkBoxValue = checkBox.checked;
        }
        const andRadio = this.dlgDiv.querySelector('#' + colValue + 'e-xlfl-frstpredicate').ej2_instances[0];
        let predicate = (andRadio.checked ? 'and' : 'or');
        if (sValue.value === null) {
            predicate = 'or';
        }
        this.filterByColumn(this.options.field, fOperator.value, fValue.value, predicate, checkBoxValue, this.options.ignoreAccent, sOperator.value, sValue.value);
        this.removeDialog();
    }
    /**
     * @hidden
     * Filters grid row by column name with given options.
     *
     * @param {string} fieldName - Defines the field name of the filter column.
     * @param {string} firstOperator - Defines the first operator by how to filter records.
     * @param {string | number | Date | boolean} firstValue - Defines the first value which is used to filter records.
     * @param  {string} predicate - Defines the relationship between one filter query with another by using AND or OR predicate.
     * @param {boolean} matchCase - If ignore case set to true, then filter records with exact match or else
     * filter records with case insensitive(uppercase and lowercase letters treated as same).
     * @param {boolean} ignoreAccent - If ignoreAccent set to true, then ignores the diacritic characters or accents when filtering.
     * @param {string} secondOperator - Defines the second operator by how to filter records.
     * @param {string | number | Date | boolean} secondValue - Defines the first value which is used to filter records.
     * @returns {void}
     */
    filterByColumn(fieldName, firstOperator, firstValue, predicate, matchCase, ignoreAccent, secondOperator, secondValue) {
        const col = this.parent.getColumnByField ? this.parent.getColumnByField(fieldName) : this.options.column;
        const field = this.isForeignColumn(col) ? col.foreignKeyValue : fieldName;
        const fColl = [];
        let mPredicate;
        fColl.push({
            field: field,
            predicate: predicate,
            matchCase: matchCase,
            ignoreAccent: ignoreAccent,
            operator: firstOperator,
            value: firstValue,
            type: this.options.type
        });
        const arg = {
            instance: this, handler: this.filterByColumn, arg1: fieldName, arg2: firstOperator, arg3: firstValue, arg4: predicate,
            arg5: matchCase, arg6: ignoreAccent, arg7: secondOperator, arg8: secondValue, cancel: false
        };
        this.parent.notify(events.fltrPrevent, arg);
        if (arg.cancel) {
            return;
        }
        mPredicate = new Predicate(field, firstOperator.toLowerCase(), firstValue, !matchCase, ignoreAccent);
        if (!isNullOrUndefined(secondValue) && !isNullOrUndefined(secondOperator)) {
            fColl.push({
                field: field,
                predicate: predicate,
                matchCase: matchCase,
                ignoreAccent: ignoreAccent,
                operator: secondOperator,
                value: secondValue,
                type: this.options.type
            });
            // eslint-disable-next-line max-len
            mPredicate = mPredicate[predicate](field, secondOperator.toLowerCase(), secondValue, !matchCase, ignoreAccent);
        }
        const args = {
            action: 'filtering', filterCollection: fColl, field: this.options.field,
            ejpredicate: mPredicate, actualPredicate: fColl
        };
        if (this.isForeignColumn(col)) {
            this.foreignKeyFilter(args, fColl, mPredicate);
        }
        else {
            this.options.handler(args);
        }
    }
    // eslint-disable-next-line max-len
    renderOperatorUI(column, table, elementID, predicates, isFirst) {
        const fieldElement = this.parent.createElement('tr', { className: 'e-xlfl-fields', attrs: { role: 'row' } });
        table.appendChild(fieldElement);
        const xlfloptr = this.parent.createElement('td', { className: 'e-xlfl-optr' });
        fieldElement.appendChild(xlfloptr);
        const optrDiv = this.parent.createElement('div', { className: 'e-xlfl-optrdiv' });
        const isComplex = !isNullOrUndefined(column) && isComplexField(column);
        const complexFieldName = !isNullOrUndefined(column) && getComplexFieldID(column);
        const optrInput = this.parent
            .createElement('input', { id: isComplex ? complexFieldName + elementID : column + elementID });
        optrDiv.appendChild(optrInput);
        xlfloptr.appendChild(optrDiv);
        const optr = this.options.type + 'Operator';
        const dropDatasource = this.customFilterOperators[optr];
        this.optrData = dropDatasource;
        let selectedValue = this.dropSelectedVal(this.options.column, predicates, isFirst);
        //Trailing three dots are sliced.
        let menuText = '';
        if (this.menuItem) {
            menuText = this.menuItem.text.slice(0, -3);
            if (menuText !== this.getLocalizedLabel('CustomFilter')) {
                selectedValue = isFirst ? menuText : undefined;
            }
            if (menuText === this.getLocalizedLabel('Between')) {
                selectedValue = this.getLocalizedLabel(isFirst ? 'GreaterThanOrEqual' : 'LessThanOrEqual');
            }
        }
        const col = this.options.column;
        const dropOptr = new DropDownList(extend({
            dataSource: dropDatasource,
            fields: { text: 'text', value: 'value' },
            text: selectedValue,
            enableRtl: this.parent.enableRtl,
            cssClass: this.parent.cssClass ? this.parent.cssClass : null
        }, col.filter.params));
        this.childRefs.push(dropOptr);
        const evt = { 'open': this.dropDownOpen.bind(this), 'change': this.dropDownValueChange.bind(this) };
        registerEventHandlers(optrInput.id, [literals.open, literals.change], evt, this);
        dropOptr.addEventListener(literals.open, this.eventHandlers[optrInput.id][literals.open]);
        dropOptr.addEventListener(literals.change, this.eventHandlers[optrInput.id][literals.change]);
        dropOptr.appendTo(optrInput);
        const operator = this.getSelectedValue(selectedValue);
        return { fieldElement, operator };
    }
    removeHandlersFromComponent(component) {
        if (component.element.classList.contains('e-dropdownlist')) {
            removeEventHandlers(component, [literals.open, literals.change], this);
        }
        else if (component.element.classList.contains('e-autocomplete')) {
            removeEventHandlers(component, [events.actionComplete, literals.focus], this);
        }
    }
    dropDownOpen(args) {
        args.popup.element.style.zIndex = (this.dialogObj.zIndex + 1).toString();
    }
    dropDownValueChange(args) {
        if (args.element.id.includes('-xlfl-frstoptr')) {
            this.firstOperator = args.value.toString();
        }
        else {
            this.secondOperator = args.value.toString();
        }
    }
    /**
     * @hidden
     * @returns {FilterUI} returns filter UI
     */
    getFilterUIInfo() {
        return { firstOperator: this.firstOperator, secondOperator: this.secondOperator, field: this.options.field };
    }
    getSelectedValue(text) {
        const selectedField = new DataManager(this.optrData).executeLocal(new Query().where('text', 'equal', text));
        return !isNullOrUndefined(selectedField[0]) ? selectedField[0].value : '';
    }
    dropSelectedVal(col, predicates, isFirst) {
        let operator;
        if (predicates && predicates.length > 0) {
            operator = predicates.length === 2 ?
                (isFirst ? predicates[0].operator : predicates[1].operator) :
                (isFirst ? predicates[0].operator : undefined);
        }
        else if (isFirst && col.type === 'string' && !col.filter.operator) {
            operator = 'startswith';
        }
        else {
            operator = isFirst ? col.filter.operator || 'equal' : undefined;
        }
        return this.getSelectedText(operator);
    }
    getSelectedText(operator) {
        const selectedField = new DataManager(this.optrData).executeLocal(new Query().where('value', 'equal', operator));
        return !isNullOrUndefined(selectedField[0]) ? selectedField[0].text : '';
    }
    renderFilterUI(column, dlgConetntEle) {
        const predicates = this.existingPredicate[column];
        const table = this.parent.createElement('table', { className: 'e-xlfl-table', attrs: { role: 'grid' } });
        dlgConetntEle.appendChild(table);
        const colGroup = this.parent.createElement(literals.colGroup);
        colGroup.innerHTML = '<col style="width: 50%"></col><col style="width: 50%"></col>';
        table.appendChild(colGroup);
        //Renders first dropdown
        let optr = this.renderOperatorUI(column, table, '-xlfl-frstoptr', predicates, true);
        this.firstOperator = optr.operator;
        //Renders first value
        this.renderFlValueUI(column, optr, '-xlfl-frstvalue', predicates, true);
        const predicate = this.parent.createElement('tr', { className: 'e-xlfl-predicate', attrs: { role: 'row' } });
        table.appendChild(predicate);
        //Renders first radion button
        this.renderRadioButton(column, predicate, predicates);
        //Renders second dropdown
        optr = this.renderOperatorUI(column, table, '-xlfl-secndoptr', predicates, false);
        this.secondOperator = optr.operator;
        //Renders second text box
        this.renderFlValueUI(column, optr, '-xlfl-secndvalue', predicates, false);
    }
    renderRadioButton(column, tr, predicates) {
        const td = this.parent.createElement('td', { className: 'e-xlfl-radio', attrs: { 'colSpan': '2' } });
        tr.appendChild(td);
        const radioDiv = this.parent
            .createElement('div', { className: 'e-xlfl-radiodiv', attrs: { 'style': 'display: inline-block' } });
        const isComplex = !isNullOrUndefined(column) && isComplexField(column);
        const complexFieldName = !isNullOrUndefined(column) && getComplexFieldID(column);
        const frstpredicate = this.parent.createElement('input', { id: isComplex ? complexFieldName + 'e-xlfl-frstpredicate' : column + 'e-xlfl-frstpredicate', attrs: { 'type': 'radio' } });
        const secndpredicate = this.parent.createElement('input', { id: isComplex ? complexFieldName + 'e-xlfl-secndpredicate' : column + 'e-xlfl-secndpredicate', attrs: { 'type': 'radio' } });
        //appends into div
        radioDiv.appendChild(frstpredicate);
        radioDiv.appendChild(secndpredicate);
        td.appendChild(radioDiv);
        if (this.options.type === 'string') {
            this.renderMatchCase(column, tr, td, '-xlflmtcase', predicates);
        }
        // Initialize AND RadioButton component.
        const andRadio = new RadioButton({
            label: this.getLocalizedLabel('AND'),
            name: 'default', checked: true,
            enableRtl: this.parent.enableRtl,
            cssClass: this.parent.cssClass ? this.parent.cssClass : ''
        });
        this.childRefs.push(andRadio);
        // Initialize OR RadioButton component.
        const orRadio = new RadioButton({
            label: this.getLocalizedLabel('OR'),
            name: 'default',
            enableRtl: this.parent.enableRtl,
            cssClass: this.parent.cssClass ? this.parent.cssClass : ''
        });
        this.childRefs.push(orRadio);
        const flValue = predicates && predicates.length === 2 ? predicates[1].predicate : 'and';
        if (flValue === 'and') {
            andRadio.checked = true;
            orRadio.checked = false;
        }
        else {
            orRadio.checked = true;
            andRadio.checked = false;
        }
        // Render initialized RadioButton.
        andRadio.appendTo(frstpredicate);
        orRadio.appendTo(secndpredicate);
        andRadio.element.nextElementSibling.classList.add('e-xlfl-radio-and');
        orRadio.element.nextElementSibling.classList.add('e-xlfl-radio-or');
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    removeObjects(elements) {
        for (const obj of elements) {
            if (obj && !obj.isDestroyed) {
                this.removeHandlersFromComponent(obj);
                obj.destroy();
            }
        }
    }
    // eslint-disable-next-line max-len
    renderFlValueUI(column, optr, elementId, predicates, isFirst) {
        const value = this.parent.createElement('td', { className: 'e-xlfl-value' });
        optr.fieldElement.appendChild(value);
        const isComplex = !isNullOrUndefined(column) && isComplexField(column);
        const complexFieldName = !isNullOrUndefined(column) && getComplexFieldID(column);
        const valueDiv = this.parent.createElement('div', { className: 'e-xlfl-valuediv' });
        const isFilteredCol = this.options.filteredColumns.some((col) => { return column === col.field; });
        const fltrPredicates = this.options.filteredColumns.filter((col) => col.field === column);
        if (this.options.column.filterTemplate) {
            let data = {};
            const columnObj = this.options.column;
            if (isFilteredCol && elementId) {
                data = this.getExcelFilterData(elementId, data, columnObj, predicates, fltrPredicates);
            }
            const isReactCompiler = this.parent.isReact && typeof (this.options.column.filterTemplate) !== 'string';
            const tempID = this.parent.element.id + columnObj.uid + 'filterTemplate';
            if (isReactCompiler) {
                this.options.column.getFilterTemplate()(data, this.parent, 'filterTemplate', tempID, null, null, valueDiv);
                this.parent.renderTemplates();
            }
            else {
                const element = this.options.column.getFilterTemplate()(data, this.parent, 'filterTemplate', tempID);
                appendChildren(valueDiv, element);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.parent.isAngular ? valueDiv.children[0] : valueDiv.querySelector('input')).id = isComplex ?
                complexFieldName + elementId : column + elementId;
            value.appendChild(valueDiv);
        }
        else {
            const valueInput = this.parent
                .createElement('input', { id: isComplex ? complexFieldName + elementId : column + elementId });
            valueDiv.appendChild(valueInput);
            value.appendChild(valueDiv);
            let flValue;
            let predicate;
            if (predicates && predicates.length > 0) {
                predicate = predicates.length === 2 ?
                    (isFirst ? predicates[0] : predicates[1]) :
                    (isFirst ? predicates[0] : undefined);
                flValue = (predicate && predicate.operator === optr.operator) ? predicate.value : undefined;
                if (isNullOrUndefined(flValue)) {
                    flValue = undefined;
                }
            }
            const types = {
                'string': this.renderAutoComplete.bind(this),
                'number': this.renderNumericTextBox.bind(this),
                'date': this.renderDate.bind(this),
                'datetime': this.renderDateTime.bind(this)
            };
            types[this.options.type](this.options, column, valueInput, flValue, this.parent.enableRtl);
        }
    }
    getExcelFilterData(elementId, data, columnObj, predicates, fltrPredicates) {
        const predIndex = elementId === '-xlfl-frstvalue' ? 0 : 1;
        if (elementId === '-xlfl-frstvalue' || fltrPredicates.length > 1) {
            data = { column: predicates instanceof Array ? predicates[predIndex] : predicates };
            const indx = this.options.column.columnData && fltrPredicates.length > 1 ?
                (this.options.column.columnData.length === 1 ? 0 : 1) : predIndex;
            data[this.options.field] = columnObj.foreignKeyValue ? this.options.column.columnData[indx][columnObj.foreignKeyValue] :
                fltrPredicates[indx].value;
            if (this.options.foreignKeyValue) {
                data[this.options.foreignKeyValue] = this.options.column.columnData[indx][columnObj.foreignKeyValue];
            }
        }
        return data;
    }
    // eslint-disable-next-line max-len
    renderMatchCase(column, tr, matchCase, elementId, predicates) {
        const matchCaseDiv = this.parent.createElement('div', { className: 'e-xlfl-matchcasediv', attrs: { 'style': 'display: inline-block' } });
        const isComplex = !isNullOrUndefined(column) && isComplexField(column);
        const complexFieldName = !isNullOrUndefined(column) && getComplexFieldID(column);
        const matchCaseInput = this.parent.createElement('input', { id: isComplex ? complexFieldName + elementId : column + elementId, attrs: { 'type': 'checkbox' } });
        matchCaseDiv.appendChild(matchCaseInput);
        matchCase.appendChild(matchCaseDiv);
        const flValue = predicates && predicates.length > 0 ?
            (predicates && predicates.length === 2 ? predicates[1].matchCase : predicates[0].matchCase) :
            false;
        // Initialize Match Case check box.
        const checkbox = new CheckBox({
            label: this.getLocalizedLabel('MatchCase'),
            enableRtl: this.parent.enableRtl, checked: flValue,
            cssClass: this.parent.cssClass ? this.parent.cssClass : ''
        });
        this.childRefs.push(checkbox);
        // Render initialized CheckBox.
        checkbox.appendTo(matchCaseInput);
    }
    // eslint-disable-next-line max-len
    renderDate(options, column, inputValue, fValue, isRtl) {
        const format = getCustomDateFormat(options.format, options.type) || options.format;
        const datePicker = new DatePicker(extend({
            format: format,
            cssClass: this.parent.cssClass ? 'e-popup-flmenu' + ' ' + this.parent.cssClass : 'e-popup-flmenu',
            placeholder: this.getLocalizedLabel('CustomFilterDatePlaceHolder'),
            width: '100%',
            enableRtl: isRtl,
            value: new Date(fValue),
            locale: this.parent.locale
        }, options.column.filter.params));
        this.childRefs.push(datePicker);
        datePicker.appendTo(inputValue);
    }
    // eslint-disable-next-line max-len
    renderDateTime(options, column, inputValue, fValue, isRtl) {
        const format = getCustomDateFormat(options.format, options.type);
        const dateTimePicker = new DateTimePicker(extend({
            format: format,
            cssClass: this.parent.cssClass ? 'e-popup-flmenu' + ' ' + this.parent.cssClass : 'e-popup-flmenu',
            placeholder: this.getLocalizedLabel('CustomFilterDatePlaceHolder'),
            width: '100%',
            enableRtl: isRtl,
            value: new Date(fValue),
            locale: this.parent.locale
        }, options.column.filter.params));
        this.childRefs.push(dateTimePicker);
        dateTimePicker.appendTo(inputValue);
    }
    completeAction(e) {
        e.result = distinctStringValues(e.result);
    }
    // eslint-disable-next-line max-len
    renderNumericTextBox(options, column, inputValue, fValue, isRtl) {
        const numericTextBox = new NumericTextBox(extend({
            format: options.format,
            placeholder: this.getLocalizedLabel('CustomFilterPlaceHolder'),
            enableRtl: isRtl,
            value: fValue,
            locale: this.parent.locale,
            cssClass: this.parent.cssClass ? this.parent.cssClass : null
        }, options.column.filter.params));
        this.childRefs.push(numericTextBox);
        numericTextBox.appendTo(inputValue);
    }
    // eslint-disable-next-line max-len
    renderAutoComplete(options, column, inputValue, fValue, isRtl) {
        const colObj = this.options.column;
        const isForeignColumn = this.isForeignColumn(colObj);
        const dataSource = isForeignColumn ? colObj.dataSource : options.dataSource;
        const fields = { value: isForeignColumn ? colObj.foreignKeyValue : column };
        const actObj = new AutoComplete(extend({
            dataSource: dataSource instanceof DataManager ? dataSource : new DataManager(dataSource),
            fields: fields,
            query: this.getQuery(),
            sortOrder: 'Ascending',
            locale: this.parent.locale,
            cssClass: this.parent.cssClass ? 'e-popup-flmenu' + ' ' + this.parent.cssClass : 'e-popup-flmenu',
            autofill: true,
            placeholder: this.getLocalizedLabel('CustomFilterPlaceHolder'),
            enableRtl: isRtl,
            text: fValue
        }, colObj.filter.params));
        if (dataSource && 'result' in dataSource) {
            const defObj = eventPromise({ requestType: 'stringfilterrequest' }, this.getQuery());
            this.parent.trigger(events.dataStateChange, defObj.state);
            const def = defObj.deffered;
            def.promise.then((e) => {
                actObj.dataSource = new DataManager(e);
            });
        }
        this.childRefs.push(actObj);
        const evt = { 'actionComplete': this.acActionComplete(actObj, column), 'focus': this.acFocus(actObj, column, options, inputValue) };
        registerEventHandlers(inputValue.id, [events.actionComplete, literals.focus], evt, this);
        actObj.addEventListener(literals.focus, this.eventHandlers[inputValue.id][literals.focus]);
        actObj.addEventListener(events.actionComplete, this.eventHandlers[inputValue.id][events.actionComplete]);
        actObj.appendTo(inputValue);
    }
    acActionComplete(actObj, column) {
        return (e) => {
            const isComplex = !isNullOrUndefined(column) && isComplexField(column);
            e.result = e.result.filter((obj, index, arr) => {
                return arr.map((mapObject) => {
                    return isComplex ? performComplexDataOperation(actObj.fields.value, mapObject)
                        : mapObject[actObj.fields.value];
                }).indexOf(isComplex ? performComplexDataOperation(actObj.fields.value, obj) :
                    obj[actObj.fields.value]) === index;
            });
        };
    }
    acFocus(actObj, column, options, inputValue) {
        return () => {
            const isComplex = !isNullOrUndefined(column) && isComplexField(column);
            const complexFieldName = !isNullOrUndefined(column) && getComplexFieldID(column);
            const columnvalue = isComplex ? complexFieldName : column;
            actObj.filterType = this.dlgDiv.querySelector('#' + columnvalue +
                (inputValue.id === (columnvalue + '-xlfl-frstvalue') ?
                    '-xlfl-frstoptr' :
                    '-xlfl-secndoptr')).ej2_instances[0].value;
            actObj.ignoreCase = options.type === 'string' ?
                !this.dlgDiv.querySelector('#' + columnvalue + '-xlflmtcase').ej2_instances[0].checked :
                true;
            actObj.filterType = !isNullOrUndefined(actObj.filterType) ? actObj.filterType :
                'equal';
        };
    }
}
