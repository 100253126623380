import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { Switch } from '@syncfusion/ej2-buttons';
import { extend } from '@syncfusion/ej2-base';
import { isEditable, addRemoveActiveClasses, createEditElement, getObject } from '../base/util';
import * as literals from '../base/string-literals';
import { EditCellBase } from './edit-cell-base';
/**
 * `ToggleEditCell` is used to handle boolean cell type editing.
 *
 * @hidden
 */
export class ToggleEditCell extends EditCellBase {
    constructor() {
        super(...arguments);
        this.activeClasses = ['e-selectionbackground', 'e-active'];
    }
    create(args) {
        let clsNames = 'e-field e-boolcell';
        if (args.column.type === 'checkbox') {
            clsNames = 'e-field e-boolcell e-edit-checkselect';
        }
        return createEditElement(this.parent, args.column, clsNames, { type: 'checkbox', value: args.value });
    }
    read(element) {
        return element.checked;
    }
    write(args) {
        const chkBoxElement = !isNullOrUndefined(args.row) && args.row.querySelector('.e-edit-checkselect');
        const data = getObject(args.column.field, args.rowData);
        let checkState = data && JSON.parse(data.toString().toLowerCase());
        if (!isNullOrUndefined(chkBoxElement)) {
            this.editType = this.parent.editSettings.mode;
            this.editRow = args.row;
            if (args.requestType !== 'add') {
                const row = this.parent.getRowObjectFromUID(args.row.getAttribute('data-uid'));
                checkState = row ? row.isSelected : false;
            }
            addRemoveActiveClasses([].slice.call(args.row.getElementsByClassName(literals.rowCell)), checkState, ...this.activeClasses);
        }
        this.obj = new Switch(extend({
            label: this.parent.editSettings.mode !== 'Dialog' ? ' ' : args.column.headerText,
            checked: checkState,
            disabled: !isEditable(args.column, args.requestType, args.element), enableRtl: this.parent.enableRtl,
            change: this.switchModeChange.bind(this),
            cssClass: this.parent.cssClass ? this.parent.cssClass : ''
        }, args.column.edit.params));
        this.obj.appendTo(args.element);
    }
    switchModeChange(args) {
        if (this.editRow && this.editType !== 'Dialog') {
            let addClass = false;
            if (!args.checked) {
                this.editRow.removeAttribute('aria-selected');
            }
            else {
                addClass = true;
                this.editRow.setAttribute('aria-selected', addClass.toString());
            }
            addRemoveActiveClasses([].slice.call(this.editRow.getElementsByClassName(literals.rowCell)), addClass, ...this.activeClasses);
        }
    }
}
