import { extend, addClass, removeClass, setValue, closest, select } from '@syncfusion/ej2-base';
import { remove, classList } from '@syncfusion/ej2-base';
import { isNullOrUndefined, isUndefined } from '@syncfusion/ej2-base';
import { CellType } from '../base/enum';
import { parentsUntil, inArray, refreshForeignData, getObject, alignFrozenEditForm, gridActionHandler, addRemoveEventListener } from '../base/util';
import { splitFrozenRowObjectCells, getGridRowElements } from '../base/util';
import { sliceElements, getCellByColAndRowIndex } from '../base/util';
import { getGridRowObjects } from '../base/util';
import * as events from '../base/constant';
import { RowRenderer } from '../renderer/row-renderer';
import { CellRenderer } from '../renderer/cell-renderer';
import { Row } from '../models/row';
import { Cell } from '../models/cell';
import { RowModelGenerator } from '../services/row-model-generator';
import { DataUtil } from '@syncfusion/ej2-data';
import * as literals from '../base/string-literals';
/**
 * `BatchEdit` module is used to handle batch editing actions.
 *
 * @hidden
 */
export class BatchEdit {
    constructor(parent, serviceLocator, renderer) {
        this.cellDetails = {};
        this.originalCell = {};
        this.cloneCell = {};
        this.editNext = false;
        this.preventSaveCell = false;
        this.initialRender = true;
        this.validationColObj = [];
        this.parent = parent;
        this.serviceLocator = serviceLocator;
        this.renderer = renderer;
        this.focus = serviceLocator.getService('focus');
        this.addEventListener();
    }
    /**
     * @returns {void}
     * @hidden
     */
    addEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.evtHandlers = [{ event: events.click, handler: this.clickHandler },
            { event: events.dblclick, handler: this.dblClickHandler },
            { event: events.beforeCellFocused, handler: this.onBeforeCellFocused },
            { event: events.cellFocused, handler: this.onCellFocused },
            { event: events.doubleTap, handler: this.dblClickHandler },
            { event: events.keyPressed, handler: this.keyDownHandler },
            { event: events.editNextValCell, handler: this.editNextValCell },
            { event: events.closeBatch, handler: this.closeForm },
            { event: events.destroy, handler: this.destroy }];
        addRemoveEventListener(this.parent, this.evtHandlers, true, this);
        this.dataBoundFunction = this.dataBound.bind(this);
        this.batchCancelFunction = this.batchCancel.bind(this);
        this.parent.addEventListener(events.dataBound, this.dataBoundFunction);
        this.parent.addEventListener(events.batchCancel, this.batchCancelFunction);
    }
    /**
     * @returns {void}
     * @hidden
     */
    removeEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        addRemoveEventListener(this.parent, this.evtHandlers, false);
        this.parent.removeEventListener(events.dataBound, this.dataBoundFunction);
        this.parent.removeEventListener(events.batchCancel, this.batchCancelFunction);
    }
    batchCancel() {
        this.parent.focusModule.restoreFocus();
    }
    dataBound() {
        this.parent.notify(events.toolbarRefresh, {});
    }
    /**
     * @returns {void}
     * @hidden
     */
    destroy() {
        this.removeEventListener();
    }
    clickHandler(e) {
        if (!parentsUntil(e.target, this.parent.element.id + '_add', true)) {
            if (this.parent.isEdit && closest(this.form, 'td') !== closest(e.target, 'td')) {
                this.saveCell();
                this.editNextValCell();
            }
            if (parentsUntil(e.target, literals.rowCell) && !this.parent.isEdit) {
                this.setCellIdx(e.target);
            }
        }
    }
    dblClickHandler(e) {
        const target = parentsUntil(e.target, literals.rowCell);
        const tr = parentsUntil(e.target, literals.row);
        const rowIndex = tr && parseInt(tr.getAttribute(literals.dataRowIndex), 10);
        const colIndex = target && parseInt(target.getAttribute(literals.dataColIndex), 10);
        if (!isNullOrUndefined(target) && !isNullOrUndefined(rowIndex) && !isNaN(colIndex)
            && !target.parentElement.classList.contains(literals.editedRow)) {
            this.editCell(rowIndex, this.parent.getColumns()[colIndex].field, this.isAddRow(rowIndex));
        }
    }
    onBeforeCellFocused(e) {
        if (this.parent.isEdit && this.validateFormObj() &&
            (e.byClick || (['tab', 'shiftTab', 'enter', 'shiftEnter'].indexOf(e.keyArgs.action) > -1))) {
            e.cancel = true;
            if (e.byClick) {
                e.clickArgs.preventDefault();
            }
            else {
                e.keyArgs.preventDefault();
            }
        }
    }
    onCellFocused(e) {
        const frzCols = this.parent.getFrozenLeftCount();
        const frzRightCols = this.parent.getFrozenRightColumnsCount();
        const mCont = this.parent.getContent().querySelector('.' + literals.movableContent);
        const mHdr = this.parent.getHeaderContent().querySelector('.' + literals.movableHeader);
        const clear = (!e.container.isContent || !e.container.isDataCell) && !(this.parent.frozenRows && e.container.isHeader);
        if (!e.byKey || clear) {
            if (this.parent.isEdit && clear) {
                this.saveCell();
            }
            return;
        }
        let [rowIndex, cellIndex] = e.container.indexes;
        if (frzCols && (mCont.contains(e.element) || (this.parent.frozenRows && mHdr.contains(e.element)))) {
            cellIndex += frzCols;
        }
        if (frzRightCols) {
            const frHdr = this.parent.getHeaderContent().querySelector('.e-frozen-right-header');
            const frCont = this.parent.getContent().querySelector('.e-frozen-right-content');
            if (frCont.contains(e.element) || (this.parent.frozenRows && frHdr.contains(e.element))) {
                cellIndex += (frzCols + this.parent.getMovableColumnsCount());
            }
        }
        if (this.parent.frozenRows && e.container.isContent) {
            rowIndex += this.parent.frozenRows;
        }
        let isEdit = this.parent.isEdit;
        if (!this.parent.element.getElementsByClassName('e-popup-open').length) {
            isEdit = isEdit && !this.validateFormObj();
            switch (e.keyArgs.action) {
                case 'tab':
                case 'shiftTab':
                    let indent = this.parent.isRowDragable() && this.parent.isDetail() ? 2 :
                        this.parent.isRowDragable() || this.parent.isDetail() ? 1 : 0;
                    // eslint-disable-next-line no-case-declarations
                    const col = this.parent.getColumns()[e.indexes[1] - indent];
                    if (col && !this.parent.isEdit) {
                        this.editCell(e.indexes[0], col.field);
                    }
                    if (isEdit || this.parent.isLastCellPrimaryKey) {
                        this.editCellFromIndex(rowIndex, cellIndex);
                    }
                    break;
                case 'enter':
                case 'shiftEnter':
                    e.keyArgs.preventDefault();
                    // eslint-disable-next-line no-case-declarations
                    const args = { cancel: false, keyArgs: e.keyArgs };
                    this.parent.notify('beforeFocusCellEdit', args);
                    if (!args.cancel && isEdit) {
                        this.editCell(rowIndex, this.cellDetails.column.field);
                    }
                    break;
                case 'f2':
                    this.editCellFromIndex(rowIndex, cellIndex);
                    this.focus.focus();
                    break;
            }
        }
    }
    isAddRow(index) {
        return this.parent.getDataRows()[index].classList.contains('e-insertedrow');
    }
    editCellFromIndex(rowIdx, cellIdx) {
        this.cellDetails.rowIndex = rowIdx;
        this.cellDetails.cellIndex = cellIdx;
        this.editCell(rowIdx, this.parent.getColumns()[cellIdx].field, this.isAddRow(rowIdx));
    }
    closeEdit() {
        const gObj = this.parent;
        const rows = this.parent.getRowsObject();
        const argument = { cancel: false, batchChanges: this.getBatchChanges() };
        gObj.notify(events.beforeBatchCancel, argument);
        if (argument.cancel) {
            return;
        }
        if (gObj.isEdit) {
            this.saveCell(true);
        }
        this.isAdded = false;
        gObj.clearSelection();
        const allRows = getGridRowObjects(this.parent);
        for (let i = 0; i < rows.length; i++) {
            let isInsert = false;
            const isDirty = rows[i].isDirty;
            gridActionHandler(this.parent, (tableName, rows) => {
                isInsert = this.removeBatchElementChanges(rows[i], isDirty);
                if (isInsert) {
                    rows.splice(i, 1);
                }
            }, allRows);
            if (isInsert) {
                i--;
            }
        }
        if (!gObj.getContentTable().querySelector('tr.e-row')) {
            gObj.renderModule.renderEmptyRow();
        }
        let args = {
            requestType: 'batchCancel', rows: this.parent.getRowsObject()
        };
        if (!this.parent.isFrozenGrid()) {
            gObj.notify(events.batchCancel, {
                rows: this.parent.getRowsObject().length ? this.parent.getRowsObject() :
                    [new Row({ isDataRow: true, cells: [new Cell({ isDataCell: true, visible: true })] })]
            });
        }
        else {
            if (this.parent.getRowsObject().length) {
                gObj.notify(events.batchCancel, { rows: this.parent.getRowsObject(),
                    args: { isFrozen: true } });
            }
            if (this.parent.getMovableRowsObject().length) {
                gObj.notify(events.batchCancel, { rows: this.parent.getMovableRowsObject() });
            }
            if (this.parent.getFrozenRightRowsObject().length) {
                gObj.notify(events.batchCancel, { rows: this.parent.getFrozenRightRowsObject(),
                    args: { renderFrozenRightContent: true } });
            }
        }
        gObj.selectRow(this.cellDetails.rowIndex);
        this.refreshRowIdx();
        gObj.notify(events.toolbarRefresh, {});
        this.parent.notify(events.tooltipDestroy, {});
        args = { requestType: 'batchCancel', rows: this.parent.getRowsObject() };
        gObj.trigger(events.batchCancel, args);
    }
    removeBatchElementChanges(row, isDirty) {
        const gObj = this.parent;
        const rowRenderer = new RowRenderer(this.serviceLocator, null, this.parent);
        let isInstertedRemoved = false;
        if (isDirty) {
            row.isDirty = isDirty;
            const tr = gObj.getRowElementByUID(row.uid);
            if (tr) {
                if (tr.classList.contains('e-insertedrow')) {
                    remove(tr);
                    isInstertedRemoved = true;
                }
                else {
                    refreshForeignData(row, this.parent.getForeignKeyColumns(), row.data);
                    delete row.changes;
                    delete row.edit;
                    row.isDirty = false;
                    classList(tr, [], ['e-hiddenrow', 'e-updatedtd']);
                    rowRenderer.refresh(row, gObj.getColumns(), false);
                }
                if (this.parent.aggregates.length > 0) {
                    const type = 'type';
                    const editType = [];
                    editType[type] = 'cancel';
                    this.parent.notify(events.refreshFooterRenderer, editType);
                    if (this.parent.groupSettings.columns.length > 0) {
                        this.parent.notify(events.groupAggregates, editType);
                    }
                }
            }
        }
        return isInstertedRemoved;
    }
    removeHideAndSelection(tr) {
        if (tr.classList.contains('e-hiddenrow')) {
            tr.removeAttribute('aria-selected');
            const tdElements = [].slice.call(tr.getElementsByClassName('e-selectionbackground'));
            for (let i = 0; i < tdElements.length; i++) {
                removeClass([tdElements[i]], ['e-selectionbackground', 'e-active']);
            }
        }
        classList(tr, [], ['e-hiddenrow', 'e-updatedtd']);
    }
    deleteRecord(fieldname, data) {
        this.saveCell();
        if (this.validateFormObj()) {
            this.saveCell(true);
        }
        this.isAdded = false;
        this.bulkDelete(fieldname, data);
        if (this.parent.aggregates.length > 0) {
            this.parent.notify(events.refreshFooterRenderer, {});
            if (this.parent.groupSettings.columns.length > 0) {
                this.parent.notify(events.groupAggregates, {});
            }
        }
    }
    addRecord(data) {
        this.bulkAddRow(data);
    }
    endEdit() {
        if (this.parent.isEdit && this.validateFormObj()) {
            return;
        }
        this.batchSave();
    }
    closeForm() {
        for (let i = 0; i < Object.keys(this.originalCell).length; i++) {
            for (let j = 0; j < Object.keys(this.cloneCell).length; j++) {
                if (Object.keys(this.originalCell)[i] === Object.keys(this.cloneCell)[j]) {
                    this.cloneCell[Object.keys(this.cloneCell)[j]].replaceWith(this.originalCell[Object.keys(this.originalCell)[i]]);
                    if (this.originalCell[Object.keys(this.originalCell)[i]].classList.contains('e-selectionbackground')) {
                        this.originalCell[Object.keys(this.originalCell)[i]]
                            .classList.remove('e-selectionbackground', 'e-cellselectionbackground', 'e-active');
                    }
                }
            }
        }
        this.cloneCell = {};
        this.originalCell = {};
    }
    validateFormObj() {
        return this.parent.editModule.formObj && !this.parent.editModule.formObj.validate();
    }
    batchSave() {
        const gObj = this.parent;
        const deletedRecords = 'deletedRecords';
        if (gObj.isCheckBoxSelection) {
            const checkAllBox = gObj.element.querySelector('.e-checkselectall').parentElement;
            if (checkAllBox.classList.contains('e-checkbox-disabled') &&
                gObj.pageSettings.totalRecordsCount > gObj.currentViewData.length) {
                removeClass([checkAllBox], ['e-checkbox-disabled']);
            }
        }
        this.saveCell();
        if (gObj.isEdit || this.editNextValCell() || gObj.isEdit) {
            return;
        }
        const changes = this.getBatchChanges();
        if (this.parent.selectionSettings.type === 'Multiple' && changes[deletedRecords].length &&
            this.parent.selectionSettings.persistSelection) {
            changes[deletedRecords] = this.removeSelectedData;
            this.removeSelectedData = [];
        }
        const original = {
            changedRecords: this.parent.getRowsObject()
                .filter((row) => row.isDirty && ['add', 'delete'].indexOf(row.edit) === -1)
                .map((row) => row.data)
        };
        const args = { batchChanges: changes, cancel: false };
        gObj.trigger(events.beforeBatchSave, args, (beforeBatchSaveArgs) => {
            if (beforeBatchSaveArgs.cancel) {
                return;
            }
            gObj.showSpinner();
            gObj.notify(events.bulkSave, { changes: changes, original: original });
        });
    }
    getBatchChanges() {
        const changes = {
            addedRecords: [],
            deletedRecords: [],
            changedRecords: []
        };
        const rows = this.parent.getRowsObject();
        for (const row of rows) {
            if (row.isDirty) {
                switch (row.edit) {
                    case 'add':
                        changes.addedRecords.push(row.changes);
                        break;
                    case 'delete':
                        changes.deletedRecords.push(row.data);
                        break;
                    default:
                        changes.changedRecords.push(row.changes);
                }
            }
        }
        return changes;
    }
    /**
     * @param {string} uid - specifes the uid
     * @returns {void}
     * @hidden
     */
    removeRowObjectFromUID(uid) {
        const rows = this.parent.getRowsObject();
        let i = 0;
        for (let len = rows.length; i < len; i++) {
            if (rows[i].uid === uid) {
                break;
            }
        }
        gridActionHandler(this.parent, (tableName, rows) => {
            rows.splice(i, 1);
        }, getGridRowObjects(this.parent));
    }
    /**
     * @param {Row<Column>} row - specifies the row object
     * @param {freezeTable} newTableName - specifies the table name
     * @returns {void}
     * @hidden
     */
    addRowObject(row, newTableName) {
        const gObj = this.parent;
        const isTop = gObj.editSettings.newRowPosition === 'Top';
        gridActionHandler(this.parent, (tableName, rows) => {
            const rowClone = row.clone();
            if (gObj.isFrozenGrid()) {
                if (newTableName === tableName) {
                    if (isTop) {
                        rows.unshift(rowClone);
                    }
                    else {
                        rows.push(rowClone);
                    }
                }
            }
            else {
                if (isTop) {
                    rows.unshift(rowClone);
                }
                else {
                    rows.push(rowClone);
                }
            }
        }, getGridRowObjects(this.parent), true);
    }
    // tslint:disable-next-line:max-func-body-length
    bulkDelete(fieldname, data) {
        this.removeSelectedData = [];
        const gObj = this.parent;
        let index = gObj.selectedRowIndex;
        let selectedRows = gObj.getSelectedRows();
        const args = {
            primaryKey: this.parent.getPrimaryKeyFieldNames(),
            rowIndex: index,
            rowData: data ? data : gObj.getSelectedRecords()[0],
            cancel: false
        };
        if (data) {
            args.row = gObj.editModule.deleteRowUid ? gObj.getRowElementByUID(gObj.editModule.deleteRowUid)
                : gObj.getRows()[gObj.getCurrentViewRecords().indexOf(data)];
        }
        else {
            args.row = data ? gObj.getRows()[index] : selectedRows[0];
        }
        if (!args.row) {
            return;
        }
        // tslint:disable-next-line:max-func-body-length
        gObj.trigger(events.beforeBatchDelete, args, (beforeBatchDeleteArgs) => {
            if (beforeBatchDeleteArgs.cancel) {
                return;
            }
            this.removeSelectedData = gObj.getSelectedRecords();
            gObj.clearSelection();
            beforeBatchDeleteArgs.row = beforeBatchDeleteArgs.row ?
                beforeBatchDeleteArgs.row : data ? gObj.getRows()[index] : selectedRows[0];
            if (this.parent.isFrozenGrid()) {
                if (data) {
                    index = parseInt(beforeBatchDeleteArgs.row.getAttribute(literals.dataRowIndex), 10);
                    selectedRows = [];
                    selectedRows.push(gObj.getRowByIndex(index));
                    selectedRows.push(gObj.getMovableRowByIndex(index));
                    if (gObj.getFrozenMode() === literals.leftRight) {
                        selectedRows.push(gObj.getFrozenRightRowByIndex(index));
                    }
                }
                for (let i = 0; i < selectedRows.length; i++) {
                    const uid = selectedRows[i].getAttribute('data-uid');
                    if (selectedRows[i].classList.contains('e-insertedrow')) {
                        this.removeRowObjectFromUID(uid);
                        remove(selectedRows[i]);
                    }
                    else {
                        const rowObj = gObj.getRowObjectFromUID(uid);
                        rowObj.isDirty = true;
                        rowObj.edit = 'delete';
                        classList(selectedRows[i], ['e-hiddenrow', 'e-updatedtd'], []);
                        if (gObj.frozenRows && index < gObj.frozenRows && gObj.getMovableDataRows().length >= gObj.frozenRows) {
                            gObj.getMovableHeaderTbody().appendChild(gObj.getMovableRowByIndex(gObj.frozenRows - 1));
                            gObj.getFrozenHeaderTbody().appendChild(gObj.getRowByIndex(gObj.frozenRows - 1));
                            if (gObj.getFrozenMode() === literals.leftRight) {
                                gObj.getFrozenRightHeaderTbody().appendChild(gObj.getFrozenRightRowByIndex(gObj.frozenRows - 1));
                            }
                        }
                        if (gObj.frozenRows && index < gObj.frozenRows && gObj.getDataRows().length >= gObj.frozenRows) {
                            gObj.getHeaderTable().querySelector(literals.tbody).appendChild(gObj.getRowByIndex(gObj.frozenRows - 1));
                        }
                    }
                    delete selectedRows[i];
                }
            }
            else if (!this.parent.isFrozenGrid() && (selectedRows.length === 1 || data)) {
                let uid = beforeBatchDeleteArgs.row.getAttribute('data-uid');
                uid = data && this.parent.editModule.deleteRowUid ? uid = this.parent.editModule.deleteRowUid : uid;
                if (beforeBatchDeleteArgs.row.classList.contains('e-insertedrow')) {
                    this.removeRowObjectFromUID(uid);
                    remove(beforeBatchDeleteArgs.row);
                }
                else {
                    const rowObj = gObj.getRowObjectFromUID(uid);
                    rowObj.isDirty = true;
                    rowObj.edit = 'delete';
                    classList(beforeBatchDeleteArgs.row, ['e-hiddenrow', 'e-updatedtd'], []);
                }
                delete beforeBatchDeleteArgs.row;
            }
            else {
                for (let i = 0; i < selectedRows.length; i++) {
                    const uniqueid = selectedRows[i].getAttribute('data-uid');
                    if (selectedRows[i].classList.contains('e-insertedrow')) {
                        this.removeRowObjectFromUID(uniqueid);
                        remove(selectedRows[i]);
                    }
                    else {
                        classList(selectedRows[i], ['e-hiddenrow', 'e-updatedtd'], []);
                        const selectedRow = gObj.getRowObjectFromUID(uniqueid);
                        selectedRow.isDirty = true;
                        selectedRow.edit = 'delete';
                        delete selectedRows[i];
                    }
                }
            }
            this.refreshRowIdx();
            if (data) {
                gObj.editModule.deleteRowUid = undefined;
                if (gObj.getSelectedRows().length) {
                    index = parseInt(gObj.getSelectedRows()[0].getAttribute(literals.dataRowIndex), 10);
                }
            }
            if (!gObj.isCheckBoxSelection) {
                gObj.selectRow(index);
            }
            gObj.trigger(events.batchDelete, beforeBatchDeleteArgs);
            gObj.notify(events.batchDelete, { rows: this.parent.getRowsObject() });
            gObj.notify(events.toolbarRefresh, {});
        });
    }
    refreshRowIdx() {
        const gObj = this.parent;
        const rows = gObj.getAllDataRows(true);
        const dataRows = getGridRowElements(this.parent);
        const dataObjects = getGridRowObjects(this.parent);
        for (let i = 0, j = 0, len = rows.length; i < len; i++) {
            if (rows[i].classList.contains(literals.row) && !rows[i].classList.contains('e-hiddenrow')) {
                gridActionHandler(this.parent, (tableName, rowElements, rowObjects) => {
                    rowElements[i].setAttribute(literals.dataRowIndex, j.toString());
                    rowElements[i].setAttribute(literals.ariaRowIndex, (j + 1).toString());
                    rowObjects[i].index = j;
                }, dataRows, null, dataObjects);
                j++;
            }
            else {
                gridActionHandler(this.parent, (tableName, rowElements, rowObjects) => {
                    rowElements[i].removeAttribute(literals.dataRowIndex);
                    rowElements[i].removeAttribute(literals.ariaRowIndex);
                    rowObjects[i].index = -1;
                }, dataRows, null, dataObjects);
            }
        }
    }
    getIndexFromData(data) {
        return inArray(data, this.parent.getCurrentViewRecords());
    }
    bulkAddRow(data) {
        const gObj = this.parent;
        if (!gObj.editSettings.allowAdding) {
            return;
        }
        if (gObj.isEdit) {
            this.saveCell();
            this.parent.notify(events.editNextValCell, {});
        }
        if (gObj.isEdit) {
            return;
        }
        if (this.initialRender) {
            const visibleColumns = gObj.getVisibleColumns();
            for (let i = 0; i < visibleColumns.length; i++) {
                if (visibleColumns[i].validationRules &&
                    visibleColumns[i].validationRules['required']) {
                    const obj = { field: (visibleColumns[i]['field']).slice(), cellIdx: i };
                    this.validationColObj.push(obj);
                }
            }
            this.initialRender = false;
        }
        this.parent.element.classList.add('e-editing');
        const defaultData = data ? data : this.getDefaultData();
        const args = {
            defaultData: defaultData,
            primaryKey: gObj.getPrimaryKeyFieldNames(),
            cancel: false
        };
        gObj.trigger(events.beforeBatchAdd, args, (beforeBatchAddArgs) => {
            if (beforeBatchAddArgs.cancel) {
                return;
            }
            this.isAdded = true;
            gObj.clearSelection();
            if (gObj.isFrozenGrid()) {
                let movableCnt = this.parent.getMovableColumnsCount();
                let leftCnt = this.parent.getFrozenLeftCount();
                let rightCnt = this.parent.getFrozenRightColumnsCount();
                let tbody$$1 = gObj.getContentTable().querySelector(literals.tbody);
                let totCount = movableCnt + leftCnt + rightCnt;
                let tableTanName;
                const selectedRowAdd = [];
                const selectedRowAddCells = [];
                let col;
                let index;
                let tr;
                let mTr;
                let frTr;
                for (let i = 0; i < totCount;) {
                    const row = new RowRenderer(this.serviceLocator, null, this.parent);
                    const model = new RowModelGenerator(this.parent);
                    const modelData = model.generateRows([beforeBatchAddArgs.defaultData]);
                    if (leftCnt > 0) {
                        leftCnt = 0;
                        tableTanName = 'frozen-left';
                        totCount = leftCnt + rightCnt + movableCnt;
                    }
                    else if (movableCnt > 0) {
                        movableCnt = 0;
                        tableTanName = 'movable';
                        totCount = leftCnt + rightCnt + movableCnt;
                    }
                    else {
                        rightCnt = 0;
                        tableTanName = 'frozen-right';
                        totCount = leftCnt + rightCnt + movableCnt;
                    }
                    for (let i = 0; i < modelData.length; i++) {
                        modelData[i].cells = splitFrozenRowObjectCells(this.parent, modelData[i].cells, tableTanName);
                    }
                    if (tableTanName === 'frozen-left') {
                        tr = row.render(modelData[0], gObj.getColumns());
                        tr.classList.add('e-insertedrow');
                    }
                    else if (tableTanName === 'movable') {
                        mTr = row.render(modelData[0], gObj.getColumns());
                        mTr.classList.add('e-insertedrow');
                    }
                    else {
                        frTr = row.render(modelData[0], gObj.getColumns());
                        frTr.classList.add('e-insertedrow');
                    }
                    for (let i = 0; i < this.parent.groupSettings.columns.length; i++) {
                        tr.insertBefore(this.parent.createElement('td', { className: 'e-indentcell' }), tr.firstChild);
                        modelData[0].cells.unshift(new Cell({ cellType: CellType.Indent }));
                    }
                    if (tbody$$1.querySelector('.e-emptyrow')) {
                        const emptyRow = tbody$$1.querySelector('.e-emptyrow');
                        emptyRow.parentNode.removeChild(emptyRow);
                        this.removeFrozenTbody();
                    }
                    if (tableTanName === 'frozen-left') {
                        if (gObj.frozenRows && gObj.editSettings.newRowPosition === 'Top') {
                            tbody$$1 = gObj.getHeaderTable().querySelector(literals.tbody);
                        }
                        else {
                            tbody$$1 = gObj.getContentTable().querySelector(literals.tbody);
                        }
                        if (this.parent.editSettings.newRowPosition === 'Top') {
                            tbody$$1.insertBefore(tr, tbody$$1.firstChild);
                            addClass([].slice.call(tr.getElementsByClassName(literals.rowCell)), ['e-updatedtd']);
                        }
                        else {
                            tbody$$1.appendChild(tr);
                            addClass([].slice.call(tr.getElementsByClassName(literals.rowCell)), ['e-updatedtd']);
                        }
                    }
                    if (tableTanName === 'movable' || tableTanName === 'frozen-right') {
                        this.renderFrozenAddRow(mTr, frTr, tableTanName);
                    }
                    modelData[0].isDirty = true;
                    modelData[0].changes = extend({}, {}, modelData[0].data, true);
                    modelData[0].edit = 'add';
                    this.addRowObject(modelData[0], tableTanName);
                }
                this.refreshRowIdx();
                this.focus.forgetPrevious();
                gObj.notify(events.batchAdd, { rows: this.parent.getRowsObject(), args: { isFrozen: this.parent.isFrozenGrid() } });
                const changes = this.getBatchChanges();
                const btmIdx = this.getBottomIndex();
                if (this.parent.editSettings.newRowPosition === 'Top') {
                    gObj.selectRow(0);
                }
                else {
                    gObj.selectRow(btmIdx);
                }
                if (!data) {
                    index = this.findNextEditableCell(0, true);
                    col = gObj.getColumns()[index];
                    if (this.parent.editSettings.newRowPosition === 'Top') {
                        this.editCell(0, col.field, true);
                    }
                    else {
                        this.editCell(btmIdx, col.field, true);
                    }
                }
                if (this.parent.aggregates.length > 0 && (data || changes[literals.addedRecords].length)) {
                    this.parent.notify(events.refreshFooterRenderer, {});
                }
                if (tr) {
                    alignFrozenEditForm(mTr.querySelector('td:not(.e-hide)'), tr.querySelector('td:not(.e-hide)'));
                    selectedRowAdd.push(tr);
                    selectedRowAddCells.push(tr.cells);
                }
                selectedRowAdd.push(mTr);
                selectedRowAddCells.push(mTr.cells);
                if (frTr) {
                    selectedRowAdd.push(frTr);
                    selectedRowAddCells.push(frTr.cells);
                }
                const args1 = {
                    defaultData: beforeBatchAddArgs.defaultData, row: selectedRowAdd,
                    columnObject: col, columnIndex: index, primaryKey: beforeBatchAddArgs.primaryKey, cell: selectedRowAddCells
                };
                gObj.trigger(events.batchAdd, args1);
            }
            else {
                const row = new RowRenderer(this.serviceLocator, null, this.parent);
                const model = new RowModelGenerator(this.parent);
                const modelData = model.generateRows([beforeBatchAddArgs.defaultData]);
                const tr = row.render(modelData[0], gObj.getColumns());
                let col;
                let index;
                for (let i = 0; i < this.parent.groupSettings.columns.length; i++) {
                    tr.insertBefore(this.parent.createElement('td', { className: 'e-indentcell' }), tr.firstChild);
                    modelData[0].cells.unshift(new Cell({ cellType: CellType.Indent }));
                }
                let tbody = gObj.getContentTable().querySelector(literals.tbody);
                tr.classList.add('e-insertedrow');
                if (tbody.querySelector('.e-emptyrow')) {
                    const emptyRow = tbody.querySelector('.e-emptyrow');
                    emptyRow.parentNode.removeChild(emptyRow);
                    this.removeFrozenTbody();
                }
                if (gObj.frozenRows && gObj.editSettings.newRowPosition === 'Top') {
                    tbody = gObj.getHeaderTable().querySelector(literals.tbody);
                }
                else {
                    tbody = gObj.getContentTable().querySelector(literals.tbody);
                }
                if (this.parent.editSettings.newRowPosition === 'Top') {
                    tbody.insertBefore(tr, tbody.firstChild);
                }
                else {
                    tbody.appendChild(tr);
                }
                addClass([].slice.call(tr.getElementsByClassName(literals.rowCell)), ['e-updatedtd']);
                modelData[0].isDirty = true;
                modelData[0].changes = extend({}, {}, modelData[0].data, true);
                modelData[0].edit = 'add';
                this.addRowObject(modelData[0]);
                this.refreshRowIdx();
                this.focus.forgetPrevious();
                gObj.notify(events.batchAdd, { rows: this.parent.getRowsObject(), args: { isFrozen: this.parent.isFrozenGrid() } });
                const changes = this.getBatchChanges();
                const btmIdx = this.getBottomIndex();
                if (this.parent.editSettings.newRowPosition === 'Top') {
                    gObj.selectRow(0);
                }
                else {
                    gObj.selectRow(btmIdx);
                }
                if (!data) {
                    index = this.findNextEditableCell(0, true);
                    col = gObj.getColumns()[index];
                    if (this.parent.editSettings.newRowPosition === 'Top') {
                        this.editCell(0, col.field, true);
                    }
                    else {
                        this.editCell(btmIdx, col.field, true);
                    }
                }
                if (this.parent.aggregates.length > 0 && (data || changes[literals.addedRecords].length)) {
                    this.parent.notify(events.refreshFooterRenderer, {});
                }
                const args1 = {
                    defaultData: beforeBatchAddArgs.defaultData, row: tr,
                    columnObject: col, columnIndex: index, primaryKey: beforeBatchAddArgs.primaryKey, cell: tr.cells[index]
                };
                gObj.trigger(events.batchAdd, args1);
            }
        });
    }
    renderFrozenAddRow(mTr, frTr, tableName$$1) {
        const gObj = this.parent;
        let mTbody;
        let frTbody;
        if (tableName$$1 === 'movable') {
            if (gObj.frozenRows && gObj.editSettings.newRowPosition === 'Top') {
                mTbody = this.parent.getMovableHeaderTbody();
            }
            else {
                mTbody = this.parent.getContent().querySelector('.e-movablecontent').querySelector(literals.tbody);
            }
            if (gObj.editSettings.newRowPosition === 'Top') {
                mTbody.insertBefore(mTr, mTbody.firstChild);
            }
            else {
                mTbody.appendChild(mTr);
            }
            addClass([].slice.call(mTr.getElementsByClassName(literals.rowCell)), ['e-updatedtd']);
        }
        if (tableName$$1 === 'frozen-right') {
            if (gObj.frozenRows && gObj.editSettings.newRowPosition === 'Top') {
                frTbody = this.parent.getFrozenRightHeaderTbody();
            }
            else {
                frTbody = this.parent.getContent().querySelector('.e-frozen-right-content').querySelector(literals.tbody);
            }
            if (gObj.editSettings.newRowPosition === 'Top') {
                frTbody.insertBefore(frTr, frTbody.firstChild);
            }
            else {
                frTbody.appendChild(frTr);
            }
            addClass([].slice.call(frTr.getElementsByClassName(literals.rowCell)), ['e-updatedtd']);
            alignFrozenEditForm(frTr.querySelector('td:not(.e-hide)'), mTr.querySelector('td:not(.e-hide)'));
        }
        if (gObj.height === 'auto') {
            gObj.notify(events.frozenHeight, {});
        }
    }
    removeFrozenTbody() {
        const gObj = this.parent;
        if (gObj.isFrozenGrid()) {
            const moveTbody = gObj.getContent().querySelector('.' + literals.movableContent).querySelector(literals.tbody);
            (moveTbody.firstElementChild).parentNode.removeChild(moveTbody.firstElementChild);
            if (gObj.getFrozenMode() === literals.leftRight) {
                const frTbody = gObj.getContent().querySelector('.e-frozen-right-content').querySelector(literals.tbody);
                (frTbody.firstElementChild).parentNode.removeChild(frTbody.firstElementChild);
            }
        }
    }
    renderMovable(ele, rightEle) {
        const mEle = ele.cloneNode(true);
        const movable = this.parent.getMovableColumnsCount();
        const left = this.parent.getFrozenLeftCount();
        const right = this.parent.getFrozenRightColumnsCount();
        sliceElements(ele, 0, left);
        sliceElements(mEle, left, right ? mEle.children.length - right : mEle.children.length);
        sliceElements(rightEle, left + movable, rightEle.children.length);
        return mEle;
    }
    findNextEditableCell(columnIndex, isAdd, isValOnly) {
        const cols = this.parent.getColumns();
        const endIndex = cols.length;
        let validation;
        for (let i = columnIndex; i < endIndex; i++) {
            validation = isValOnly ? isNullOrUndefined(cols[i].validationRules) : false;
            if (!isAdd && this.checkNPCell(cols[i])) {
                return i;
            }
            else if (isAdd && !cols[i].template && cols[i].visible && cols[i].allowEditing &&
                !(cols[i].isIdentity && cols[i].isPrimaryKey) && !validation) {
                return i;
            }
        }
        return -1;
    }
    checkNPCell(col) {
        return !col.template && col.visible && !col.isPrimaryKey && !col.isIdentity && col.allowEditing;
    }
    getDefaultData() {
        const gObj = this.parent;
        const data = {};
        const dValues = { 'number': 0, 'string': null, 'boolean': false, 'date': null, 'datetime': null };
        for (const col of (gObj.columnModel)) {
            if (col.field) {
                setValue(col.field, Object.keys(col).indexOf('defaultValue') >= 0 ? col.defaultValue : dValues[col.type], data);
            }
        }
        return data;
    }
    setCellIdx(target) {
        let gLen = 0;
        if (this.parent.allowGrouping) {
            gLen = this.parent.groupSettings.columns.length;
        }
        this.cellDetails.cellIndex = target.cellIndex - gLen;
        this.cellDetails.rowIndex = parseInt(target.getAttribute('index'), 10);
    }
    editCell(index, field, isAdd) {
        const gObj = this.parent;
        const col = gObj.getColumnByField(field);
        this.index = index;
        this.field = field;
        this.isAdd = isAdd;
        const checkEdit = gObj.isEdit && !(this.cellDetails.column.field === field
            && (this.cellDetails.rowIndex === index && this.parent.getDataRows().length - 1 !== index));
        if (gObj.editSettings.allowEditing) {
            if (!checkEdit && col.allowEditing) {
                this.editCellExtend(index, field, isAdd);
            }
            else if (checkEdit) {
                this.editNext = true;
                this.saveCell();
            }
        }
    }
    editCellExtend(index, field, isAdd) {
        const gObj = this.parent;
        const col = gObj.getColumnByField(field);
        const keys = gObj.getPrimaryKeyFieldNames();
        if (gObj.isEdit) {
            return;
        }
        let row;
        let mRowData;
        let rowData = extend({}, {}, this.getDataByIndex(index), true);
        if (col.getFreezeTableName() === 'movable' || col.getFreezeTableName() === literals.frozenRight) {
            row = col.getFreezeTableName() === 'movable' ? gObj.getMovableDataRows()[index] : gObj.getFrozenRightDataRows()[index];
            mRowData = this.parent.getRowObjectFromUID(row.getAttribute('data-uid'));
            rowData = mRowData.changes ? extend({}, {}, mRowData.changes, true) : rowData;
        }
        else {
            row = gObj.getDataRows()[index];
            rowData = extend({}, {}, this.getDataByIndex(index), true);
        }
        if ((keys[0] === col.field && !row.classList.contains('e-insertedrow')) || col.columns ||
            (col.isPrimaryKey && col.isIdentity) || col.commands) {
            this.parent.isLastCellPrimaryKey = true;
            return;
        }
        this.parent.isLastCellPrimaryKey = false;
        this.parent.element.classList.add('e-editing');
        const rowObj = gObj.getRowObjectFromUID(row.getAttribute('data-uid'));
        const cells = [].slice.apply(row.cells);
        const args = {
            columnName: col.field, isForeignKey: !isNullOrUndefined(col.foreignKeyValue),
            primaryKey: keys, rowData: rowData,
            validationRules: extend({}, col.validationRules ? col.validationRules : {}),
            value: getObject(col.field, rowData),
            type: !isAdd ? 'edit' : 'add', cancel: false,
            foreignKeyData: rowObj && rowObj.foreignKeyData
        };
        args.cell = cells[this.getColIndex(cells, this.getCellIdx(col.uid))];
        args.row = row;
        args.columnObject = col;
        if (!args.cell) {
            return;
        }
        gObj.trigger(events.cellEdit, args, (cellEditArgs) => {
            if (cellEditArgs.cancel) {
                return;
            }
            cellEditArgs.cell = cellEditArgs.cell ? cellEditArgs.cell : cells[this.getColIndex(cells, this.getCellIdx(col.uid))];
            cellEditArgs.row = cellEditArgs.row ? cellEditArgs.row : row;
            cellEditArgs.columnObject = cellEditArgs.columnObject ? cellEditArgs.columnObject : col;
            cellEditArgs.columnObject.index = isNullOrUndefined(cellEditArgs.columnObject.index) ? 0 : cellEditArgs.columnObject.index;
            this.cellDetails = {
                rowData: rowData, column: col, value: cellEditArgs.value, isForeignKey: cellEditArgs.isForeignKey, rowIndex: index,
                cellIndex: parseInt(cellEditArgs.cell.getAttribute(literals.dataColIndex), 10),
                foreignKeyData: cellEditArgs.foreignKeyData
            };
            if (cellEditArgs.cell.classList.contains('e-updatedtd')) {
                this.isColored = true;
                cellEditArgs.cell.classList.remove('e-updatedtd');
            }
            gObj.isEdit = true;
            gObj.clearSelection();
            if (!gObj.isCheckBoxSelection || !gObj.isPersistSelection) {
                gObj.selectRow(this.cellDetails.rowIndex, true);
            }
            this.renderer.update(cellEditArgs);
            this.parent.notify(events.batchEditFormRendered, cellEditArgs);
            this.form = select('#' + gObj.element.id + 'EditForm', gObj.element);
            gObj.editModule.applyFormValidation([col]);
            this.parent.element.querySelector('.e-gridpopup').style.display = 'none';
        });
    }
    updateCell(rowIndex, field, value) {
        const gObj = this.parent;
        const col = gObj.getColumnByField(field);
        const index = gObj.getColumnIndexByField(field);
        if (col && !col.isPrimaryKey && col.allowEditing) {
            const td = getCellByColAndRowIndex(this.parent, col, rowIndex, index);
            const rowObj = col.getFreezeTableName() === 'movable' ? this.parent.getMovableRowsObject()[rowIndex] :
                col.getFreezeTableName() === literals.frozenRight ? gObj.getFrozenRightRowsObject()[rowIndex]
                    : gObj.getRowObjectFromUID(td.parentElement.getAttribute('data-uid'));
            this.refreshTD(td, col, rowObj, value);
            this.parent.trigger(events.queryCellInfo, {
                cell: this.newReactTd || td, column: col, data: rowObj.changes
            });
        }
    }
    setChanges(rowObj, field, value) {
        let currentRowObj;
        if (!this.parent.isFrozenGrid()) {
            if (!rowObj.changes) {
                rowObj.changes = extend({}, {}, rowObj.data, true);
            }
            if (!isNullOrUndefined(field)) {
                DataUtil.setValue(field, value, rowObj.changes);
            }
            if (rowObj.data[field] !== value) {
                const type = this.parent.getColumnByField(field).type;
                if ((type === 'date' || type === 'datetime')) {
                    if (new Date(rowObj.data[field]).toString() !== new Date(value).toString()) {
                        rowObj.isDirty = true;
                    }
                }
                else {
                    rowObj.isDirty = true;
                }
            }
        }
        else {
            const rowEle = this.parent.getRowElementByUID(rowObj.uid);
            const rowIndex = parseInt(rowEle.getAttribute(literals.dataRowIndex), 10);
            currentRowObj = this.parent.getRowsObject()[rowIndex];
            if (!currentRowObj.changes) {
                currentRowObj.changes = extend({}, {}, rowObj.data, true);
            }
            if (!isNullOrUndefined(field)) {
                setValue(field, value, currentRowObj.changes);
            }
            const movableRowObject = this.parent.getMovableRowsObject()[rowIndex];
            movableRowObject.changes = extend({}, {}, currentRowObj.changes, true);
            if (rowObj.data[field] !== value) {
                movableRowObject.isDirty = true;
                currentRowObj.isDirty = true;
            }
            if (this.parent.getFrozenMode() === literals.leftRight) {
                const frRowObject = this.parent.getFrozenRightRowsObject()[rowIndex];
                frRowObject.changes = extend({}, {}, currentRowObj.changes, true);
                if (rowObj.data[field] !== value) {
                    frRowObject.isDirty = true;
                }
            }
        }
    }
    updateRow(index, data) {
        const keys = Object.keys(data);
        for (const col of keys) {
            this.updateCell(index, col, data[col]);
        }
    }
    getCellIdx(uid) {
        let cIdx = this.parent.getColumnIndexByUid(uid) + this.parent.groupSettings.columns.length;
        if (!isNullOrUndefined(this.parent.detailTemplate) || !isNullOrUndefined(this.parent.childGrid)) {
            cIdx++;
        }
        if (this.parent.isRowDragable()) {
            cIdx++;
        }
        return cIdx;
    }
    refreshTD(td, column, rowObj, value) {
        const cell = new CellRenderer(this.parent, this.serviceLocator);
        let rowcell;
        value = column.type === 'number' && !isNullOrUndefined(value) ? parseFloat(value) : value;
        this.setChanges(rowObj, column.field, value);
        let frzCols = this.parent.getFrozenColumns() || this.parent.getFrozenLeftColumnsCount()
            || this.parent.getFrozenRightColumnsCount();
        frzCols = frzCols && this.parent.isRowDragable() ? frzCols + 1 : frzCols;
        refreshForeignData(rowObj, this.parent.getForeignKeyColumns(), rowObj.changes);
        if (frzCols && column.getFreezeTableName() === 'movable' && this.parent.getColumns().length === rowObj.cells.length) {
            rowcell = rowObj.cells.slice(frzCols, rowObj.cells.length);
        }
        else {
            rowcell = rowObj.cells;
        }
        let parentElement;
        let cellIndex;
        if (this.parent.isReact) {
            parentElement = td.parentElement;
            cellIndex = td.cellIndex;
        }
        let index = 0;
        if (frzCols) {
            index = column.getFreezeTableName() === 'movable' && this.parent.getFrozenMode() !== 'Right'
                ? frzCols : column.getFreezeTableName() === literals.frozenRight
                ? this.parent.getFrozenLeftColumnsCount() + this.parent.getMovableColumnsCount() : index;
        }
        cell.refreshTD(td, rowcell[this.getCellIdx(column.uid) - index], rowObj.changes, { 'index': this.getCellIdx(column.uid) });
        if (this.parent.isReact) {
            this.newReactTd = parentElement.cells[cellIndex];
            parentElement.cells[cellIndex].classList.add('e-updatedtd');
        }
        else {
            td.classList.add('e-updatedtd');
        }
        td.classList.add('e-updatedtd');
        this.parent.notify(events.toolbarRefresh, {});
    }
    getColIndex(cells, index) {
        let cIdx = 0;
        if (this.parent.allowGrouping && this.parent.groupSettings.columns) {
            cIdx = this.parent.groupSettings.columns.length;
        }
        if (!isNullOrUndefined(this.parent.detailTemplate) || !isNullOrUndefined(this.parent.childGrid)) {
            cIdx++;
        }
        if (this.parent.isRowDragable()) {
            cIdx++;
        }
        for (let m = 0; m < cells.length; m++) {
            const colIndex = parseInt(cells[m].getAttribute(literals.dataColIndex), 10);
            if (colIndex === index - cIdx) {
                return m;
            }
        }
        return -1;
    }
    editNextValCell() {
        const gObj = this.parent;
        const insertedRows = gObj.element.querySelectorAll('.e-insertedrow');
        const isSingleInsert = insertedRows.length === 1 ? true : (gObj.getFrozenColumns() > 0 ||
            gObj.getFrozenRightColumnsCount() > 0 || gObj.getFrozenLeftColumnsCount() > 0) && (insertedRows.length === 2 ||
            insertedRows.length === 3) ? true : false;
        if (isSingleInsert && this.isAdded && !gObj.isEdit) {
            const btmIdx = this.getBottomIndex();
            for (let i = this.cellDetails.cellIndex; i < gObj.getColumns().length; i++) {
                if (gObj.isEdit) {
                    return;
                }
                const index = this.findNextEditableCell(this.cellDetails.cellIndex + 1, true, true);
                const col = gObj.getColumns()[index];
                if (col) {
                    if (this.parent.editSettings.newRowPosition === 'Bottom') {
                        this.editCell(btmIdx, col.field, true);
                    }
                    else {
                        const args = { index: 0, column: col };
                        this.parent.notify(events.nextCellIndex, args);
                        this.editCell(args.index, col.field, true);
                    }
                    this.saveCell();
                }
            }
            if (!gObj.isEdit) {
                this.isAdded = false;
            }
        }
        else if (!isSingleInsert && this.isAdded && !gObj.isEdit && !gObj.isFrozenGrid()) {
            let editRowIdx = 0;
            if (gObj.editSettings.newRowPosition === 'Bottom') {
                const changes = this.getBatchChanges();
                editRowIdx = gObj.getCurrentViewRecords().length - changes[literals.deletedRecords].length;
            }
            for (let i = 0; i < insertedRows.length; i++, editRowIdx++) {
                if (!gObj.isEdit) {
                    for (let j = 0; j < this.validationColObj.length; j++) {
                        if (gObj.isEdit) {
                            break;
                        }
                        else if (insertedRows[i].querySelectorAll('td')[this.validationColObj[j].cellIdx].innerText === '') {
                            this.editCell(editRowIdx, this.validationColObj[j].field);
                            if (this.validateFormObj()) {
                                this.saveCell();
                            }
                        }
                    }
                }
                else {
                    break;
                }
            }
            if (!gObj.isEdit) {
                this.isAdded = false;
            }
        }
        else if (!isSingleInsert && this.isAdded && !gObj.isEdit && gObj.isFrozenGrid()) {
            let fLeftInsertedRow = gObj.getFrozenLeftContentTbody() ? gObj.getFrozenLeftContentTbody()
                .querySelectorAll('.e-insertedrow') : undefined;
            let fRightInsertedRow = gObj.getFrozenRightContentTbody() ? gObj.getFrozenRightContentTbody()
                .querySelectorAll('.e-insertedrow') : undefined;
            let mInsertedRow = gObj.getMovableContentTbody().querySelectorAll('.e-insertedrow');
            let editRowIdx = 0;
            const fLeftCount = gObj.getVisibleFrozenLeftCount() ? gObj.getVisibleFrozenLeftCount() :
                gObj.getFrozenColumns();
            const fRightCount = gObj.getVisibleFrozenRightCount();
            const mColumnCount = gObj.getVisibleMovableCount();
            if (gObj.editSettings.newRowPosition === 'Bottom') {
                const changes = this.getBatchChanges();
                editRowIdx = gObj.getCurrentViewRecords().length - changes[literals.deletedRecords].length;
            }
            else if (gObj.editSettings.newRowPosition === 'Top' && gObj.frozenRows) {
                fLeftInsertedRow = gObj.getFrozenHeaderTbody() ? gObj.getFrozenHeaderTbody()
                    .querySelectorAll('.e-insertedrow') : undefined;
                fRightInsertedRow = gObj.getFrozenRightHeader() ? gObj.getFrozenRightHeader()
                    .querySelectorAll('.e-insertedrow') : undefined;
                mInsertedRow = gObj.getMovableHeaderTbody().querySelectorAll('.e-insertedrow');
            }
            for (let i = 0; i < mInsertedRow.length; i++, editRowIdx++) {
                if (!gObj.isEdit) {
                    for (let j = 0; j < this.validationColObj.length; j++) {
                        if (gObj.isEdit) {
                            break;
                        }
                        else if (fLeftCount && this.validationColObj[j].cellIdx < fLeftCount) {
                            if (fLeftInsertedRow[i].querySelectorAll('td')[this.validationColObj[j].cellIdx].innerText === '') {
                                this.editCell(editRowIdx, this.validationColObj[j].field);
                                if (gObj.editModule.formObj.validate()) {
                                    this.saveCell();
                                }
                            }
                        }
                        else if (fRightCount && mColumnCount <= this.validationColObj[j].cellIdx) {
                            if (fRightInsertedRow[i].querySelectorAll('td')[this.validationColObj[j].cellIdx - (mColumnCount + fLeftCount)].innerText === '') {
                                this.editCell(editRowIdx, this.validationColObj[j].field);
                                if (gObj.editModule.formObj.validate()) {
                                    this.saveCell();
                                }
                            }
                        }
                        else if (mInsertedRow[i].querySelectorAll('td')[this.validationColObj[j].cellIdx - fLeftCount].innerText === '') {
                            this.editCell(editRowIdx, this.validationColObj[j].field);
                            if (gObj.editModule.formObj.validate()) {
                                this.saveCell();
                            }
                        }
                    }
                }
                else {
                    break;
                }
            }
            if (!gObj.isEdit) {
                this.isAdded = false;
            }
        }
    }
    escapeCellEdit() {
        const args = this.generateCellArgs();
        args.value = args.previousValue;
        if (args.value || !this.cellDetails.column.validationRules) {
            this.successCallBack(args, args.cell.parentElement, args.column, true)(args);
        }
    }
    generateCellArgs() {
        const gObj = this.parent;
        this.parent.element.classList.remove('e-editing');
        const column = this.cellDetails.column;
        const obj = {};
        obj[column.field] = getObject(column.field, this.cellDetails.rowData);
        let editedData = gObj.editModule.getCurrentEditedData(this.form, obj);
        const cloneEditedData = extend({}, editedData);
        editedData = extend({}, editedData, this.cellDetails.rowData);
        const value = getObject(column.field, cloneEditedData);
        if (!isNullOrUndefined(column.field) && !isUndefined(value)) {
            setValue(column.field, value, editedData);
        }
        const args = {
            columnName: column.field,
            value: getObject(column.field, editedData),
            rowData: this.cellDetails.rowData,
            column: column,
            previousValue: this.cellDetails.value,
            isForeignKey: this.cellDetails.isForeignKey,
            cancel: false
        };
        args.cell = this.form.parentElement;
        args.columnObject = column;
        return args;
    }
    saveCell(isForceSave) {
        if (this.preventSaveCell || !this.form) {
            return;
        }
        const gObj = this.parent;
        if (!isForceSave && (!gObj.isEdit || this.validateFormObj())) {
            return;
        }
        this.preventSaveCell = true;
        const args = this.generateCellArgs();
        const tr = args.cell.parentElement;
        const col = args.column;
        if (!isForceSave) {
            gObj.trigger(events.cellSave, args, this.successCallBack(args, tr, col));
            gObj.notify(events.batchForm, { formObj: this.form });
        }
        else {
            this.successCallBack(args, tr, col)(args);
        }
    }
    successCallBack(cellSaveArgs, tr, column, isEscapeCellEdit) {
        return (cellSaveArgs) => {
            const gObj = this.parent;
            cellSaveArgs.cell = cellSaveArgs.cell ? cellSaveArgs.cell : this.form.parentElement;
            cellSaveArgs.columnObject = cellSaveArgs.columnObject ? cellSaveArgs.columnObject : column;
            cellSaveArgs.columnObject.index = isNullOrUndefined(cellSaveArgs.columnObject.index) ? 0 : cellSaveArgs.columnObject.index;
            if (cellSaveArgs.cancel) {
                this.preventSaveCell = false;
                if (this.editNext) {
                    this.editNext = false;
                    if (this.cellDetails.rowIndex === this.index && this.cellDetails.column.field === this.field) {
                        return;
                    }
                    this.editCellExtend(this.index, this.field, this.isAdd);
                }
                return;
            }
            gObj.editModule.destroyWidgets([column]);
            gObj.isEdit = false;
            gObj.editModule.destroyForm();
            this.parent.notify(events.tooltipDestroy, {});
            let rowObj = parentsUntil(cellSaveArgs.cell, literals.movableContent)
                || parentsUntil(cellSaveArgs.cell, literals.movableHeader) ? gObj.getRowObjectFromUID(tr.getAttribute('data-uid'), true)
                : gObj.getRowObjectFromUID(tr.getAttribute('data-uid'));
            if (gObj.getFrozenMode() === literals.leftRight && (parentsUntil(cellSaveArgs.cell, 'e-frozen-right-header')
                || parentsUntil(cellSaveArgs.cell, 'e-frozen-right-content'))) {
                rowObj = gObj.getRowObjectFromUID(tr.getAttribute('data-uid'), false, true);
            }
            this.refreshTD(cellSaveArgs.cell, column, rowObj, cellSaveArgs.value);
            if (this.parent.isReact) {
                cellSaveArgs.cell = this.newReactTd;
            }
            removeClass([tr], [literals.editedRow, 'e-batchrow']);
            removeClass([cellSaveArgs.cell], ['e-editedbatchcell', 'e-boolcell']);
            if (!isNullOrUndefined(cellSaveArgs.value) && cellSaveArgs.value.toString() ===
                (!isNullOrUndefined(this.cellDetails.value) ? this.cellDetails.value : '').toString() && !this.isColored
                || (isNullOrUndefined(cellSaveArgs.value) && isNullOrUndefined(rowObj.data[column.field]) &&
                    isNullOrUndefined(this.cellDetails.value) && !cellSaveArgs.cell.parentElement.classList.contains('e-insertedrow'))) {
                cellSaveArgs.cell.classList.remove('e-updatedtd');
            }
            if (isNullOrUndefined(isEscapeCellEdit)) {
                gObj.trigger(events.cellSaved, cellSaveArgs);
            }
            gObj.notify(events.toolbarRefresh, {});
            this.isColored = false;
            if (this.parent.aggregates.length > 0) {
                this.parent.notify(events.refreshFooterRenderer, {});
                if (this.parent.groupSettings.columns.length > 0 && !this.isAddRow(this.cellDetails.rowIndex)) {
                    this.parent.notify(events.groupAggregates, {});
                }
            }
            this.preventSaveCell = false;
            if (this.editNext) {
                this.editNext = false;
                if (this.cellDetails.rowIndex === this.index && this.cellDetails.column.field === this.field) {
                    return;
                }
                const col = gObj.getColumnByField(this.field);
                if (col && col.allowEditing) {
                    this.editCellExtend(this.index, this.field, this.isAdd);
                }
            }
            if (isEscapeCellEdit) {
                gObj.notify(events.restoreFocus, {});
            }
        };
    }
    getDataByIndex(index) {
        const row = this.parent.getRowObjectFromUID(this.parent.getDataRows()[index].getAttribute('data-uid'));
        return row.changes ? row.changes : row.data;
    }
    keyDownHandler(e) {
        if ((e.action === 'tab' || e.action === 'shiftTab') && this.parent.isEdit) {
            const gObj = this.parent;
            const btmIdx = this.getBottomIndex();
            const rowcell = parentsUntil(e.target, literals.rowCell);
            if (rowcell) {
                const cell = rowcell.querySelector('.e-field');
                if (cell) {
                    const visibleColumns = this.parent.getVisibleColumns();
                    const columnIndex = e.action === 'tab' ? visibleColumns.length - 1 : 0;
                    if (visibleColumns[columnIndex].field === cell.getAttribute('id').slice(this.parent.element.id.length)) {
                        if (this.cellDetails.rowIndex === btmIdx && e.action === 'tab') {
                            if (gObj.editSettings.newRowPosition === 'Top') {
                                gObj.editSettings.newRowPosition = 'Bottom';
                                this.addRecord();
                                gObj.editSettings.newRowPosition = 'Top';
                            }
                            else {
                                this.addRecord();
                            }
                        }
                        else {
                            this.saveCell();
                        }
                    }
                }
            }
        }
    }
    /**
     * @returns {void}
     * @hidden
     */
    addCancelWhilePaging() {
        if (this.validateFormObj()) {
            this.parent.notify(events.destroyForm, {});
            this.parent.isEdit = false;
            this.isColored = false;
        }
    }
    getBottomIndex() {
        const changes = this.getBatchChanges();
        return this.parent.getCurrentViewRecords().length + changes[literals.addedRecords].length -
            changes[literals.deletedRecords].length - 1;
    }
}
