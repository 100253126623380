import * as events from '../base/constant';
import { Workbook } from '@syncfusion/ej2-excel-export';
import { isNullOrUndefined, getEnumValue, compile, getValue, detach, extend } from '@syncfusion/ej2-base';
import { Data } from '../actions/data';
import { ExportHelper, ExportValueFormatter } from './export-helper';
import { SummaryModelGenerator, GroupSummaryModelGenerator, CaptionSummaryModelGenerator } from '../services/summary-model-generator';
import { CellType } from '../base/enum';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { getPrintGridModel, getUid, isExportColumns, updateColumnTypeForExportColumns, prepareColumns, measureColumnDepth } from '../base/util';
/**
 * @hidden
 * `ExcelExport` module is used to handle the Excel export action.
 */
export class ExcelExport {
    /**
     * Constructor for the Grid Excel Export module.
     *
     * @param {IGrid} parent - specifies the IGrid
     * @param {ServiceLocator} locator - specifies the ServiceLocator
     * @hidden
     */
    constructor(parent, locator) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.book = {};
        this.workSheet = [];
        this.rows = [];
        this.columns = [];
        this.styles = [];
        this.rowLength = 1;
        this.expType = 'AppendToSheet';
        this.includeHiddenColumn = false;
        this.isCsvExport = false;
        this.isElementIdChanged = false;
        this.gridPool = {};
        this.sheet = {};
        this.parent = parent;
        this.helper = new ExportHelper(parent);
        this.locator = locator;
        this.l10n = this.locator.getService('localization');
    }
    /**
     * For internal use only - Get the module name.
     *
     * @returns {string} returns the module name
     */
    getModuleName() {
        return 'ExcelExport';
    }
    init(gObj) {
        if (gObj.element !== null && gObj.element.id === '') {
            gObj.element.id = new Date().toISOString();
            this.isElementIdChanged = true;
        }
        this.parent = gObj;
        if (this.parent.isDestroyed) {
            return;
        }
        this.isExporting = undefined;
        this.book = {};
        this.workSheet = [];
        this.rows = [];
        this.columns = [];
        this.styles = [];
        this.rowLength = 1;
        this.footer = undefined;
        this.expType = 'AppendToSheet';
        this.includeHiddenColumn = false;
        this.exportValueFormatter = new ExportValueFormatter(gObj.locale);
        gObj.id = getUid('main-grid');
        this.gridPool[gObj.id] = false;
    }
    /**
     * Export Grid to Excel file.
     *
     * @param {IGrid} grid - Defines the grid.
     * @param  {exportProperties} exportProperties - Defines the export properties of the Grid.
     * @param  {isMultipleExport} isMultipleExport - Defines is multiple Grid's are exported.
     * @param  {Workbook} workbook - Defined the Workbook if multiple Grid is exported.
     * @param  {boolean} isCsv - true if export to CSV.
     * @param {boolean} isBlob - true if isBlob is enabled.
     * @returns {Promise<any>} - Returns the map for export.
     */
    // eslint-disable-next-line
    Map(grid, exportProperties, isMultipleExport, workbook, isCsv, isBlob) {
        const gObj = grid;
        const cancel = 'cancel';
        const isBlb = 'isBlob';
        const csv = 'isCsv';
        const workbk = 'workbook';
        const isMultiEx = 'isMultipleExport';
        this.gridPool = {};
        if (grid.childGrid && !(!isNullOrUndefined(exportProperties) && exportProperties.hierarchyExportMode === 'None')) {
            grid.expandedRows = getPrintGridModel(grid).expandedRows;
        }
        const args = {
            requestType: 'beforeExcelExport', gridObject: gObj, cancel: false,
            isMultipleExport: isMultipleExport, workbook: workbook, isCsv: isCsv, isBlob: isBlob
        };
        gObj.trigger(events.beforeExcelExport, args);
        if (args[cancel]) {
            return new Promise((resolve) => {
                return resolve();
            });
        }
        this.parent.log('exporting_begin', this.getModuleName());
        this.data = new Data(gObj);
        this.isExporting = true;
        this.isBlob = args[isBlb];
        if (args[csv]) {
            this.isCsvExport = args[csv];
        }
        else {
            this.isCsvExport = false;
        }
        if (isExportColumns(exportProperties)) {
            updateColumnTypeForExportColumns(exportProperties, gObj);
        }
        return this.processRecords(gObj, exportProperties, args[isMultiEx], args[workbk]);
    }
    exportingSuccess(resolve) {
        this.isExporting = false;
        this.parent.trigger(events.excelExportComplete, this.isBlob ? { promise: this.blobPromise } : {});
        this.parent.log('exporting_complete', this.getModuleName());
        resolve(this.book);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    processRecords(gObj, exportProperties, isMultipleExport, workbook) {
        if (!isNullOrUndefined(exportProperties) && !isNullOrUndefined(exportProperties.dataSource) &&
            exportProperties.dataSource instanceof DataManager) {
            const query = exportProperties.query ? exportProperties.query : new Query();
            if (isNullOrUndefined(query.isCountRequired) || gObj.aggregates) {
                query.isCountRequired = true;
            }
            return new Promise((resolve) => {
                const dataManager = exportProperties.dataSource.executeQuery(query);
                dataManager.then((r) => {
                    this.init(gObj);
                    this.processInnerRecords(gObj, exportProperties, isMultipleExport, workbook, r).then(() => {
                        this.exportingSuccess(resolve);
                    });
                });
            });
        }
        else if (!isNullOrUndefined(exportProperties) && exportProperties.exportType === 'CurrentPage') {
            return new Promise((resolve) => {
                this.init(gObj);
                this.processInnerRecords(gObj, exportProperties, isMultipleExport, workbook, this.parent.getCurrentViewRecords());
                this.exportingSuccess(resolve);
            });
        }
        else {
            const allPromise = [];
            allPromise.push(this.data.getData({}, ExportHelper.getQuery(gObj, this.data)));
            allPromise.push(this.helper.getColumnData(gObj));
            return new Promise((resolve, reject) => {
                Promise.all(allPromise).then((e) => {
                    this.init(gObj);
                    this.processInnerRecords(gObj, exportProperties, isMultipleExport, workbook, e[0]).then(() => {
                        this.exportingSuccess(resolve);
                    });
                }).catch((e) => {
                    reject(this.book);
                    this.parent.trigger(events.actionFailure, e);
                });
            });
        }
    }
    processInnerRecords(gObj, exportProperties, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isMultipleExport, workbook, r) {
        this.groupedColLength = gObj.groupSettings.columns.length;
        let blankRows = 5;
        let separator;
        let rows = [];
        const colDepth = measureColumnDepth(gObj.columns);
        const isExportPropertiesPresent = !isNullOrUndefined(exportProperties);
        if (isExportPropertiesPresent && !isNullOrUndefined(exportProperties.multipleExport)) {
            this.expType = (!isNullOrUndefined(exportProperties.multipleExport.type) ? exportProperties.multipleExport.type : 'AppendToSheet');
            if (!isNullOrUndefined(exportProperties.multipleExport.blankRows)) {
                blankRows = exportProperties.multipleExport.blankRows;
            }
        }
        if (isNullOrUndefined(workbook)) {
            this.workSheet = [];
            this.rows = [];
            this.columns = [];
            this.styles = [];
            this.sheet.images = [];
        }
        else if (this.expType === 'NewSheet') {
            this.workSheet = workbook.worksheets;
            this.rows = [];
            this.columns = [];
            this.sheet.images = [];
            this.styles = workbook.styles;
        }
        else {
            this.workSheet = [];
            this.rows = workbook.worksheets[0].rows;
            this.columns = workbook.worksheets[0].columns;
            this.styles = workbook.styles;
            this.sheet.images = workbook.worksheets[0].images;
            this.rowLength = (this.rows[this.rows.length - 1].index + blankRows);
            this.rowLength++;
        }
        if (isExportPropertiesPresent) {
            if (!isNullOrUndefined(isMultipleExport)) {
                if (!isNullOrUndefined(exportProperties.header) && (isMultipleExport || this.expType === 'NewSheet')) {
                    this.processExcelHeader(JSON.parse(JSON.stringify(exportProperties.header)));
                }
                if (!isNullOrUndefined(exportProperties.footer)) {
                    if (this.expType === 'AppendToSheet') {
                        if (!isMultipleExport) {
                            this.footer = JSON.parse(JSON.stringify(exportProperties.footer));
                        }
                    }
                    else {
                        this.footer = JSON.parse(JSON.stringify(exportProperties.footer));
                    }
                }
            }
            else {
                if (!isNullOrUndefined(exportProperties.header)) {
                    this.processExcelHeader(JSON.parse(JSON.stringify(exportProperties.header)));
                }
                if (!isNullOrUndefined(exportProperties.footer)) {
                    this.footer = JSON.parse(JSON.stringify(exportProperties.footer));
                }
            }
        }
        this.includeHiddenColumn = (isExportPropertiesPresent ? exportProperties.includeHiddenColumn : false);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            gObj.childGridLevel = 0;
            rows = this.processGridExport(gObj, exportProperties, r);
            this.globalResolve = resolve;
            this.gridPool[gObj.id] = true;
            this.helper.checkAndExport(this.gridPool, this.globalResolve);
        }).then(() => {
            const organisedRows = [];
            this.organiseRows(rows, rows[0].index, organisedRows);
            this.rows = this.rows.concat(organisedRows);
            //footer template add
            if (!isNullOrUndefined(this.footer)) {
                if ((this.expType === 'AppendToSheet' && !isMultipleExport) || (this.expType === 'NewSheet')) {
                    this.processExcelFooter(this.footer);
                }
            }
            if (this.columns.length > 0) {
                this.sheet.columns = this.columns;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.sheet.rows = this.rows;
            this.sheet.enableRtl = this.parent.enableRtl;
            if (this.parent.allowFiltering && gObj.getVisibleColumns().length && isExportPropertiesPresent &&
                exportProperties.enableFilter) {
                const headerRowLen = exportProperties.header ? exportProperties.header.headerRows ||
                    exportProperties.header.rows.length : 0;
                const autoFilters = {
                    row: colDepth + headerRowLen, column: this.groupedColLength ? this.groupedColLength + 1 :
                        this.sheet.columns[0].index, lastRow: this.sheet.rows.length, lastColumn: this.sheet.columns.length
                };
                this.sheet.autoFilters = autoFilters;
            }
            this.workSheet.push(this.sheet);
            this.book.worksheets = this.workSheet;
            this.book.styles = this.styles;
            gObj.notify('finalPageSetup', this.book);
            if (!isMultipleExport) {
                if (this.isCsvExport) {
                    if (isExportPropertiesPresent && !isNullOrUndefined(exportProperties.separator)
                        && exportProperties.separator !== ',') {
                        separator = exportProperties.separator;
                    }
                    const book = new Workbook(this.book, 'csv', gObj.locale, gObj.currencyCode, separator);
                    if (!this.isBlob) {
                        if (isExportPropertiesPresent && exportProperties.fileName) {
                            book.save(exportProperties.fileName);
                        }
                        else {
                            book.save('Export.csv');
                        }
                    }
                    else {
                        this.blobPromise = book.saveAsBlob('text/csv');
                    }
                }
                else {
                    const book = new Workbook(this.book, 'xlsx', gObj.locale, gObj.currencyCode);
                    if (!this.isBlob) {
                        if (isExportPropertiesPresent && exportProperties.fileName) {
                            book.save(exportProperties.fileName);
                        }
                        else {
                            book.save('Export.xlsx');
                        }
                    }
                    else {
                        this.blobPromise = book.saveAsBlob('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                    }
                }
                if (this.isElementIdChanged) {
                    gObj.element.id = '';
                }
                delete gObj.expandedRows;
            }
            return workbook;
        });
    }
    organiseRows(rows, initialIndex, organisedRows) {
        if (!rows.length) {
            return initialIndex;
        }
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            const childRows = row.childRows;
            if (childRows) {
                row.index = initialIndex++;
                delete row.childRows;
                organisedRows.push(row);
                initialIndex = this.organiseRows(childRows, initialIndex, organisedRows);
            }
            else {
                row.index = initialIndex++;
                organisedRows.push(row);
            }
        }
        return initialIndex;
    }
    processGridExport(gObj, exportProperties, r) {
        let excelRows = [];
        const isFrozen = this.parent.isFrozenGrid() && !this.parent.getFrozenColumns();
        if (!isNullOrUndefined(exportProperties) && !isNullOrUndefined(exportProperties.theme)) {
            this.theme = exportProperties.theme;
        }
        if (gObj.childGrid && !isNullOrUndefined(exportProperties)) {
            gObj.hierarchyPrintMode = exportProperties.hierarchyExportMode || 'Expanded';
        }
        const helper = new ExportHelper(gObj, this.helper.getForeignKeyData());
        const gColumns = isExportColumns(exportProperties) ?
            prepareColumns(exportProperties.columns, gObj.enableColumnVirtualization) :
            helper.getGridExportColumns(isFrozen ? gObj.getColumns() : gObj.columns);
        const headerRow = helper.getHeaders(gColumns, this.includeHiddenColumn);
        const groupIndent = gObj.groupSettings.columns.length ? gObj.groupSettings.columns.length - 1 : 0;
        excelRows = this.processHeaderContent(gObj, headerRow, groupIndent, excelRows);
        // eslint-disable-next-line max-len
        if (!isNullOrUndefined(exportProperties) && !isNullOrUndefined(exportProperties.dataSource) && !(exportProperties.dataSource instanceof DataManager)) {
            // eslint-disable-next-line max-len
            excelRows = this.processRecordContent(gObj, r, headerRow, exportProperties, exportProperties.dataSource, excelRows, helper);
        }
        else if (!isNullOrUndefined(exportProperties) && exportProperties.exportType === 'CurrentPage') {
            excelRows = this.processRecordContent(gObj, r, headerRow, exportProperties, gObj.currentViewData, excelRows, helper);
        }
        else {
            excelRows = this.processRecordContent(gObj, r, headerRow, exportProperties, undefined, excelRows, helper);
        }
        gObj.notify(events.exportDataBound, { excelRows: excelRows, type: 'excel' });
        return excelRows;
    }
    processRecordContent(gObj, returnType, headerRow, exportProperties, currentViewRecords, excelRow, helper) {
        let record;
        if (!isNullOrUndefined(currentViewRecords) && currentViewRecords.length) {
            record = currentViewRecords;
        }
        else {
            record = returnType.result;
        }
        if (!isNullOrUndefined(record.level)) {
            this.processGroupedRows(gObj, record, headerRow, record.level, 0, exportProperties, excelRow, helper);
        }
        else {
            this.processRecordRows(gObj, record, headerRow, 0, 0, exportProperties, excelRow, helper);
        }
        if (!isNullOrUndefined(returnType.aggregates)) {
            if (!isNullOrUndefined(currentViewRecords)) {
                this.processAggregates(gObj, returnType.result, excelRow, currentViewRecords);
            }
            else {
                const result = returnType.result.GroupGuid ?
                    returnType.result.records : returnType.result;
                this.processAggregates(gObj, result, excelRow);
            }
        }
        return excelRow;
    }
    processGroupedRows(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gObj, dataSource, headerRow, level, startIndex, excelExportProperties, excelRows, helper) {
        for (const item of dataSource) {
            const cells = [];
            const index = 1;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const cell = {};
            cell.index = (index + level) - 1;
            const col = gObj.getColumnByField(item.field);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const args = {
                value: item.key,
                column: col,
                style: undefined,
                isForeignKey: col.isForeignColumn()
            };
            let value = gObj.getColumnByField(item.field).headerText +
                ': ' + (!col.enableGroupByFormat ? this.exportValueFormatter.formatCellValue(args) : item.key) + ' - ';
            if (item.count > 1) {
                value += item.count + ' items';
            }
            else {
                value += item.count + ' item';
            }
            const cArgs = { captionText: value, type: this.isCsvExport ? 'CSV' : 'Excel', data: item };
            this.parent.trigger(events.exportGroupCaption, cArgs);
            cell.value = cArgs.captionText;
            cell.style = this.getCaptionThemeStyle(this.theme);
            if (!isNullOrUndefined(cArgs.style)) {
                cell.style = this.mergeOptions(cell.style, cArgs.style);
            }
            const captionModelGen = new CaptionSummaryModelGenerator(gObj);
            const groupCaptionSummaryRows = captionModelGen.generateRows(item);
            this.fillAggregates(gObj, groupCaptionSummaryRows, (dataSource.level + dataSource.childLevels) - 1, excelRows, this.rowLength);
            cells.push(cell);
            if (excelRows[excelRows.length - 1].cells.length > 0) {
                let lIndex = dataSource.level + dataSource.childLevels + groupCaptionSummaryRows[0].cells.length;
                let hIndex = 0;
                for (const tCell of excelRows[excelRows.length - 1].cells) {
                    if (tCell.index < lIndex) {
                        lIndex = tCell.index;
                    }
                    if (tCell.index > hIndex) {
                        hIndex = tCell.index;
                    }
                    if (cells[cells.length - 1].index !== tCell.index) {
                        cells.push(tCell);
                    }
                }
                if ((lIndex - cell.index) > 1) {
                    cell.colSpan = lIndex - cell.index;
                }
                while (hIndex < (headerRow.columns.length + level + dataSource.childLevels)) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const sCell = {};
                    sCell.index = (hIndex + 1);
                    sCell.style = this.getCaptionThemeStyle(this.theme);
                    cells.push(sCell);
                    hIndex++;
                }
            }
            else {
                let span = 0;
                //Calculation for column span when group caption dont have aggregates
                for (const col of headerRow.columns) {
                    if (col.visible) {
                        span++;
                    }
                }
                cell.colSpan = (dataSource.childLevels + span);
            }
            excelRows[excelRows.length - 1].cells = cells;
            this.rowLength++;
            if (this.groupedColLength < 8 && level > 1) {
                const grouping = { outlineLevel: level - 1, isCollapsed: true };
                excelRows[excelRows.length - 1].grouping = grouping;
            }
            if (!isNullOrUndefined(dataSource.childLevels) && dataSource.childLevels > 0) {
                this.processGroupedRows(gObj, item.items, headerRow, item.items.level, startIndex, excelExportProperties, excelRows, helper);
                this.processAggregates(gObj, item, excelRows, undefined, (level - 1) + dataSource.childLevels, true);
            }
            else {
                startIndex = this.processRecordRows(gObj, item.items, headerRow, (level - 1), startIndex, excelExportProperties, excelRows, helper);
                this.processAggregates(gObj, item, excelRows, undefined, (level - 1), true);
            }
        }
    }
    processRecordRows(gObj, record, headerRow, level, startIndex, excelExportProperties, excelRows, helper) {
        let index = 1;
        let cells = [];
        const columns = headerRow.columns;
        const rows = helper.getGridRowModel(columns, record, gObj, startIndex);
        for (const row of rows) {
            cells = [];
            startIndex++;
            index = 1;
            let templateRowHeight;
            for (let c = 0, len = row.cells.length; c < len; c++) {
                const gCell = row.cells[c];
                if (gCell.cellType !== CellType.Data) {
                    continue;
                }
                const column = gCell.column;
                const field = column.field;
                const cellValue = !isNullOrUndefined(field) ? column.valueAccessor(field, row.data, column) : '';
                let value = !isNullOrUndefined(cellValue) ? cellValue : '';
                let fkData;
                if (column.isForeignColumn && column.isForeignColumn()) {
                    fkData = helper.getFData(value, column);
                    value = getValue(column.foreignKeyValue, fkData);
                }
                if (!isNullOrUndefined(value)) {
                    let cell = {};
                    const idx = index + level + gObj.childGridLevel;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let excelCellArgs = {
                        data: row.data, column: column, foreignKeyData: fkData,
                        value: value, style: undefined, colSpan: 1, cell: cell
                    };
                    gObj.trigger(events.excelQueryCellInfo, excelCellArgs);
                    if (!isNullOrUndefined(excelCellArgs.image) && !isNullOrUndefined(excelCellArgs.image.base64)) {
                        templateRowHeight = this.setImage(excelCellArgs, idx);
                        if (excelCellArgs.image.height && excelCellArgs.value !== '') {
                            templateRowHeight += 30;
                        }
                    }
                    if (!isNullOrUndefined(excelCellArgs.hyperLink)) {
                        excelCellArgs.cell.hyperlink = { target: excelCellArgs.hyperLink.target };
                        excelCellArgs.value = excelCellArgs.hyperLink.displayText || excelCellArgs.value;
                    }
                    cell = excelCellArgs.cell;
                    cell.index = idx;
                    cell.value = excelCellArgs.value;
                    if (excelCellArgs.data === '' && gObj.childGridLevel && index === 1) {
                        const style = {};
                        style.hAlign = 'left';
                        excelCellArgs = { style: style };
                        cell.colSpan = gObj.getVisibleColumns().length;
                        cell.value = this.l10n.getConstant('EmptyRecord');
                    }
                    if (excelCellArgs.colSpan > 1) {
                        cell.colSpan = excelCellArgs.colSpan;
                    }
                    if (!isNullOrUndefined(excelCellArgs.style)) {
                        const styleIndex = this.getColumnStyle(gObj, index + level);
                        cell.style = this.mergeOptions(this.styles[styleIndex], excelCellArgs.style);
                    }
                    else {
                        cell.style = { name: gObj.element.id + 'column' + (index + level) };
                    }
                    cells.push(cell);
                }
                index++;
            }
            const excelRow = { index: this.rowLength++, cells: cells };
            if (!isNullOrUndefined(templateRowHeight)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                excelRow.height = templateRowHeight;
            }
            if (this.groupedColLength && this.groupedColLength < 8 && (level + 1) > 0) {
                excelRow.grouping = { outlineLevel: (level + 1), isCollapsed: true };
                excelRows.push(excelRow);
            }
            else {
                excelRows.push(excelRow);
            }
            if (row.isExpand && !isNullOrUndefined(gObj.childGrid)) {
                gObj.isPrinting = true;
                const exportType = (!isNullOrUndefined(excelExportProperties) && excelExportProperties.exportType) ?
                    excelExportProperties.exportType : 'AllPages';
                const returnVal = this.helper.createChildGrid(gObj, row, exportType, this.gridPool);
                const childGridObj = returnVal.childGrid;
                const element = returnVal.element;
                childGridObj.actionFailure =
                    helper.failureHandler(this.gridPool, childGridObj, this.globalResolve);
                childGridObj.childGridLevel = gObj.childGridLevel + 1;
                const args = { childGrid: childGridObj, row, exportProperties: excelExportProperties };
                this.parent.trigger(events.exportDetailDataBound, args);
                childGridObj.beforeDataBound = this.childGridCell(excelRow, childGridObj, excelExportProperties, row);
                childGridObj.appendTo(element);
            }
            gObj.notify(events.exportRowDataBound, { rowObj: row, type: 'excel', excelRows: excelRows });
        }
        return startIndex;
    }
    setImage(args, idx) {
        if (isNullOrUndefined(this.sheet.images)) {
            this.sheet.images = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const excelImage = {
            image: args.image.base64, row: this.rowLength, column: idx,
            lastRow: this.rowLength, lastColumn: idx
        };
        if (args.image.width && args.image.height) {
            excelImage.width = args.image.width;
            excelImage.height = args.image.height;
        }
        this.sheet.images.push(excelImage);
        this.columns[idx - 1].width = args.image.width || this.columns[idx - 1].width;
        return args.image.height || 50;
    }
    childGridCell(excelRow, childGridObj, excelExportProps, gRow) {
        return (result) => {
            childGridObj.beforeDataBound = null;
            result.cancel = true;
            if (result.result.length === 0) {
                result.result = [''];
            }
            excelRow.childRows = this.processGridExport(childGridObj, excelExportProps, result);
            const intent = this.parent.groupSettings.columns.length;
            const rows = excelRow.childRows;
            for (let i = 0; i < rows.length; i++) {
                rows[i].grouping = { outlineLevel: intent + childGridObj.childGridLevel,
                    isCollapsed: !gRow.isExpand, isHidden: !gRow.isExpand };
            }
            childGridObj.destroy();
            detach(childGridObj.element);
            this.gridPool[childGridObj.id] = true;
            this.helper.checkAndExport(this.gridPool, this.globalResolve);
            return excelRow;
        };
    }
    processAggregates(gObj, rec, excelRows, currentViewRecords, indent, byGroup) {
        const summaryModel = new SummaryModelGenerator(gObj);
        let columns = summaryModel.getColumns();
        columns = columns.filter((col) => { return isNullOrUndefined(col.commands) && col.type !== 'checkbox'; });
        if (gObj.aggregates.length && this.parent !== gObj) {
            gObj.aggregateModule.prepareSummaryInfo();
        }
        let data = undefined;
        if (!isNullOrUndefined(currentViewRecords)) {
            data = currentViewRecords;
        }
        else {
            data = rec;
        }
        if (indent === undefined) {
            indent = 0;
        }
        if (gObj.groupSettings.columns.length > 0 && byGroup) {
            const groupSummaryModel = new GroupSummaryModelGenerator(gObj);
            const groupSummaryRows = groupSummaryModel.generateRows(data, { level: data.level });
            if (groupSummaryRows.length > 0) {
                excelRows = this.fillAggregates(gObj, groupSummaryRows, indent, excelRows);
            }
        }
        else {
            indent = gObj.groupSettings.columns.length > 0 && !byGroup ? gObj.groupSettings.columns.length : indent;
            const sRows = summaryModel.generateRows(data, rec.aggregates, null, null, columns);
            if (sRows.length > 0 && !byGroup) {
                indent = gObj.groupSettings.columns.length ? indent - 1 : indent;
                excelRows = this.fillAggregates(gObj, sRows, indent, excelRows);
            }
        }
        return excelRows;
    }
    fillAggregates(gObj, rows, indent, excelRows, customIndex) {
        for (const row of rows) {
            const cells = [];
            let isEmpty = true;
            let index = 0;
            for (const cell of row.cells) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const eCell = {};
                if (cell.cellType === CellType.DetailFooterIntent) {
                    continue;
                }
                if ((cell.visible || this.includeHiddenColumn)) {
                    index++;
                    if (cell.isDataCell) {
                        isEmpty = false;
                        const footerTemplate = !isNullOrUndefined(cell.column.footerTemplate);
                        const groupFooterTemplate = !isNullOrUndefined(cell.column.groupFooterTemplate);
                        const groupCaptionTemplate = !isNullOrUndefined(cell.column.groupCaptionTemplate);
                        eCell.index = index + indent + gObj.childGridLevel;
                        if (footerTemplate) {
                            eCell.value = this.getAggreateValue(CellType.Summary, cell.column.footerTemplate, cell, row);
                        }
                        else if (groupFooterTemplate) {
                            eCell.value = this.getAggreateValue(CellType.GroupSummary, cell.column.groupFooterTemplate, cell, row);
                        }
                        else if (groupCaptionTemplate) {
                            eCell.value = this.getAggreateValue(CellType.CaptionSummary, cell.column.groupCaptionTemplate, cell, row);
                        }
                        else {
                            for (const key of Object.keys(row.data[cell.column.field])) {
                                if (key === cell.column.type) {
                                    if (!isNullOrUndefined(row.data[cell.column.field].Sum)) {
                                        eCell.value = row.data[cell.column.field][`${cell.column.field} - sum`];
                                    }
                                    else if (!isNullOrUndefined(row.data[cell.column.field].Average)) {
                                        eCell.value = row.data[cell.column.field][`${cell.column.field} - average`];
                                    }
                                    else if (!isNullOrUndefined(row.data[cell.column.field].Max)) {
                                        eCell.value = row.data[cell.column.field][`${cell.column.field} - max`];
                                    }
                                    else if (!isNullOrUndefined(row.data[cell.column.field].Min)) {
                                        eCell.value = row.data[cell.column.field][`${cell.column.field} - min`];
                                    }
                                    else if (!isNullOrUndefined(row.data[cell.column.field].Count)) {
                                        eCell.value = row.data[cell.column.field][`${cell.column.field} - count`];
                                    }
                                    else if (!isNullOrUndefined(row.data[cell.column.field].TrueCount)) {
                                        eCell.value = row.data[cell.column.field][`${cell.column.field} - truecount`];
                                    }
                                    else if (!isNullOrUndefined(row.data[cell.column.field].FalseCount)) {
                                        eCell.value = row.data[cell.column.field][`${cell.column.field} - falsecount`];
                                    }
                                    else if (!isNullOrUndefined(row.data[cell.column.field].Custom)) {
                                        eCell.value = row.data[cell.column.field].Custom;
                                    }
                                }
                            }
                        }
                        eCell.style = this.getCaptionThemeStyle(this.theme); //{ name: gObj.element.id + 'column' + index };
                        this.aggregateStyle(cell.column, eCell.style, cell.column.field);
                        const gridCellStyle = cell.attributes.style;
                        if (gridCellStyle.textAlign) {
                            eCell.style.hAlign = gridCellStyle.textAlign.toLowerCase();
                        }
                        const args = {
                            row: row,
                            type: footerTemplate ? 'Footer' : groupFooterTemplate ? 'GroupFooter' : 'GroupCaption',
                            style: eCell
                        };
                        this.parent.trigger(events.excelAggregateQueryCellInfo, args);
                        cells.push(eCell);
                    }
                    else {
                        if (customIndex === undefined) {
                            eCell.index = index + indent + gObj.childGridLevel;
                            eCell.style = this.getCaptionThemeStyle(this.theme); //{ name: gObj.element.id + 'column' + index };
                            cells.push(eCell);
                        }
                    }
                }
            }
            if (!isNullOrUndefined(customIndex)) {
                excelRows.push({ index: customIndex, cells: cells });
            }
            else {
                let row = {};
                if (this.groupedColLength < 8 && this.groupedColLength > 0) {
                    const dummyOutlineLevel = 'outlineLevel';
                    const dummyGrouping = 'grouping';
                    const level = excelRows[excelRows.length - 1][dummyGrouping][dummyOutlineLevel];
                    const grouping = { outlineLevel: level, isCollapsed: true };
                    row = { index: this.rowLength++, cells: cells, grouping };
                }
                else {
                    row = { index: this.rowLength++, cells: cells };
                }
                if (!isEmpty) {
                    excelRows.push(row);
                }
            }
        }
        return excelRows;
    }
    aggregateStyle(col, style, field) {
        const column = this.parent.getColumnByField(field);
        if (typeof col.format === 'object') {
            const format = col.format;
            style.numberFormat = !isNullOrUndefined(format.format) ? format.format : format.skeleton;
            if (!isNullOrUndefined(format.type)) {
                style.type = format.type.toLowerCase();
            }
        }
        else {
            style.numberFormat = col.format;
        }
        if (!isNullOrUndefined(column) && isNullOrUndefined(style.type)) {
            style.type = column.type.toLowerCase();
        }
    }
    getAggreateValue(cellType, template, cell, row) {
        const templateFn = {};
        templateFn[getEnumValue(CellType, cell.cellType)] = compile(template);
        let txt;
        const data = row.data[cell.column.field ? cell.column.field : cell.column.columnName];
        if (this.parent.isReact || this.parent.isVue) {
            txt = (templateFn[getEnumValue(CellType, cell.cellType)](data, this.parent));
            if (this.parent.isReact) {
                this.parent.renderTemplates();
            }
        }
        else {
            txt = (templateFn[getEnumValue(CellType, cell.cellType)](data));
        }
        return txt[0].textContent;
    }
    mergeOptions(JSON1, JSON2) {
        const result = {};
        let attrname = Object.keys(JSON1);
        for (let index = 0; index < attrname.length; index++) {
            if (attrname[index] !== 'name') {
                result[attrname[index]] = JSON1[attrname[index]];
            }
        }
        attrname = Object.keys(JSON2);
        for (let index = 0; index < attrname.length; index++) {
            if (attrname[index] !== 'name') {
                result[attrname[index]] = JSON2[attrname[index]];
            }
        }
        return result;
    }
    getColumnStyle(gObj, columnIndex) {
        let index = 0;
        for (const style of this.styles) {
            if (style.name === gObj.element.id + 'column' + columnIndex) {
                return index;
            }
            index++;
        }
        return undefined;
    }
    headerRotation(args) {
        let degree = args.style.rotation;
        if (degree <= 90 && degree >= 0) {
            args.style.hAlign = 'Left';
        }
        else if (degree > 90 && degree <= 180) {
            args.style.hAlign = 'Right';
        }
        else {
            degree = 180;
            args.style.hAlign = 'Right';
        }
        args.style.rotation = degree;
    }
    processHeaderContent(gObj, headerRow, indent, excelRows) {
        const rowIndex = 1;
        const gridRows = headerRow.rows;
        // Column collection with respect to the records in the grid
        const gridColumns = headerRow.columns;
        const spannedCells = [];
        if (indent > 0) {
            let index = 0;
            while (index !== indent) {
                this.columns.push({ index: index + 1, width: 30 });
                index++;
            }
        }
        for (let col = 0; col < gridColumns.length; col++) {
            this.parseStyles(gObj, gridColumns[col], this.getRecordThemeStyle(this.theme), indent + col + 1);
        }
        let templateRowHeight;
        for (let row = 0; row < gridRows.length; row++) {
            let currentCellIndex = 1 + indent;
            const cells = [];
            for (let column = 0; column < gridRows[row].cells.length; column++) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let style = {};
                const cell = {};
                const gridCell = gridRows[row].cells[column];
                if (gridCell.cellType === CellType.HeaderIndent || gridCell.cellType === CellType.DetailHeader) {
                    continue;
                }
                let result = { contains: true, index: 1 };
                while (result.contains) {
                    result = this.getIndex(spannedCells, rowIndex, currentCellIndex);
                    currentCellIndex = result.index;
                    if (!result.contains) {
                        cell.index = result.index + gObj.childGridLevel;
                        break;
                    }
                }
                if (!isNullOrUndefined(gridCell.rowSpan) && gridCell.rowSpan !== 1) {
                    cell.rowSpan = gridCell.rowSpan;
                    for (let i = rowIndex; i < gridCell.rowSpan + rowIndex; i++) {
                        const spannedCell = { rowIndex: 0, columnIndex: 0 };
                        spannedCell.rowIndex = i;
                        spannedCell.columnIndex = currentCellIndex;
                        spannedCells.push(spannedCell);
                    }
                }
                if (!isNullOrUndefined(gridCell.colSpan) && gridCell.colSpan !== 1) {
                    cell.colSpan = gridCell.colSpan;
                    currentCellIndex = currentCellIndex + cell.colSpan - 1;
                }
                cell.value = gridCell.column.headerText;
                style = this.getHeaderThemeStyle(this.theme);
                if (!isNullOrUndefined(gridCell.column.textAlign)) {
                    style.hAlign = gridCell.column.textAlign.toLowerCase();
                }
                if (!isNullOrUndefined(gridCell.column.headerTextAlign)) {
                    style.hAlign = gridCell.column.headerTextAlign.toLowerCase();
                }
                const excelHeaderCellArgs = { cell: cell, gridCell: gridCell, style: style };
                gObj.trigger(events.excelHeaderQueryCellInfo, excelHeaderCellArgs);
                if (excelHeaderCellArgs.style.rotation) {
                    this.headerRotation(excelHeaderCellArgs);
                }
                if (!isNullOrUndefined(excelHeaderCellArgs.image) && !isNullOrUndefined(excelHeaderCellArgs.image.base64)) {
                    templateRowHeight = this.setImage(excelHeaderCellArgs, currentCellIndex);
                }
                if (!isNullOrUndefined(excelHeaderCellArgs.hyperLink)) {
                    excelHeaderCellArgs.cell.hyperlink = { target: excelHeaderCellArgs.hyperLink.target };
                    cell.value = excelHeaderCellArgs.hyperLink.displayText || cell.value;
                }
                cell.style = excelHeaderCellArgs.style;
                cells.push(cell);
                currentCellIndex++;
            }
            const excelRow = { index: this.rowLength++, cells: cells };
            if (!isNullOrUndefined(templateRowHeight)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                excelRow.height = templateRowHeight;
            }
            excelRows.push(excelRow);
        }
        return excelRows;
    }
    getHeaderThemeStyle(theme) {
        let style = {};
        style.fontSize = 12;
        style.borders = { color: '#E0E0E0' };
        style.bold = true;
        if (!isNullOrUndefined(theme) && !isNullOrUndefined(theme.header)) {
            style = this.updateThemeStyle(theme.header, style);
        }
        return style;
    }
    updateThemeStyle(themestyle, style) {
        return extend(style, themestyle);
    }
    getCaptionThemeStyle(theme) {
        let style = {};
        style.fontSize = 13;
        style.backColor = '#F6F6F6';
        if (!isNullOrUndefined(theme) && !isNullOrUndefined(theme.caption)) {
            style = this.updateThemeStyle(theme.caption, style);
        }
        return style;
    }
    getRecordThemeStyle(theme) {
        let style = {};
        style.fontSize = 13;
        style.borders = { color: '#E0E0E0' };
        if (!isNullOrUndefined(theme) && !isNullOrUndefined(theme.record)) {
            style = this.updateThemeStyle(theme.record, style);
        }
        return style;
    }
    processExcelHeader(header) {
        if (!isNullOrUndefined(header.rows) && (this.expType === 'NewSheet' || this.rowLength === 1)) {
            let noRows;
            if (header.headerRows === undefined) {
                this.rowLength = header.rows.length;
            }
            else {
                this.rowLength = header.headerRows;
            }
            if (this.rowLength < header.rows.length) {
                noRows = this.rowLength;
            }
            else {
                noRows = header.rows.length;
            }
            this.rowLength++;
            for (let row = 0; row < noRows; row++) {
                const json = header.rows[row];
                //Row index
                if (!(json.index !== null && !isNullOrUndefined(json.index))) {
                    json.index = (row + 1);
                }
                this.updatedCellIndex(json);
            }
        }
    }
    updatedCellIndex(json) {
        const cellsLength = json.cells.length;
        for (let cellId = 0; cellId < cellsLength; cellId++) {
            const jsonCell = json.cells[cellId];
            //cell index
            if (!(jsonCell.index !== null && !isNullOrUndefined(jsonCell.index))) {
                jsonCell.index = (cellId + 1);
            }
        }
        this.rows.push(json);
    }
    processExcelFooter(footer) {
        if (!isNullOrUndefined(footer.rows)) {
            let noRows;
            if (footer.footerRows === undefined) {
                this.rowLength += footer.rows.length;
            }
            else {
                if (footer.footerRows > footer.rows.length) {
                    this.rowLength += (footer.footerRows - footer.rows.length);
                    noRows = footer.rows.length;
                }
                else {
                    noRows = footer.footerRows;
                }
            }
            for (let row = 0; row < noRows; row++) {
                const json = footer.rows[row];
                //Row index
                if (json.index === null || json.index === undefined) {
                    json.index = this.rowLength++;
                }
                else {
                    json.index += this.rowLength;
                }
                this.updatedCellIndex(json);
            }
        }
    }
    getIndex(spannedCells, rowIndex, columnIndex) {
        for (const spannedCell of spannedCells) {
            if ((spannedCell.rowIndex === rowIndex) && (spannedCell.columnIndex === columnIndex)) {
                columnIndex = columnIndex + 1;
                return { contains: true, index: columnIndex };
            }
        }
        return { contains: false, index: columnIndex };
    }
    parseStyles(gObj, col, style, index) {
        if (!isNullOrUndefined(col.format)) {
            if (typeof col.format === 'object') {
                const format = col.format;
                style.numberFormat = !isNullOrUndefined(format.format) ? format.format : format.skeleton;
                if (!isNullOrUndefined(format.type)) {
                    style.type = format.type.toLowerCase();
                }
            }
            else {
                style.numberFormat = col.format;
                style.type = col.type;
            }
        }
        if (!isNullOrUndefined(col.textAlign)) {
            style.hAlign = col.textAlign.toLowerCase();
        }
        if (Object.keys(style).length > 0) {
            style.name = gObj.element.id + 'column' + index;
            this.styles.push(style);
        }
        if (!isNullOrUndefined(col.width) && col.width !== 'auto') {
            this.columns.push({ index: index + gObj.childGridLevel, width: typeof col.width === 'number' ?
                    col.width : this.helper.getConvertedWidth(col.width) });
        }
    }
    destroy() {
        //destroy for exporting
    }
}
