import { EventHandler, Browser } from '@syncfusion/ej2-base';
import { debounce } from '@syncfusion/ej2-base';
/**
 * InterSectionObserver - class watch whether it enters the viewport.
 *
 * @hidden
 */
export class InterSectionObserver {
    constructor(element, options, movableEle) {
        this.fromWheel = false;
        this.touchMove = false;
        this.options = {};
        this.sentinelInfo = {
            'up': {
                check: (rect, info) => {
                    const top = rect.top - this.containerRect.top;
                    info.entered = top >= 0;
                    return top + (this.options.pageHeight / 2) >= 0;
                },
                axis: 'Y'
            },
            'down': {
                check: (rect, info) => {
                    const bottom = rect.bottom;
                    info.entered = rect.bottom <= this.containerRect.bottom;
                    return ((bottom - this.containerRect.top) - (this.options.pageHeight / 2)) <= this.options.pageHeight / 2;
                }, axis: 'Y'
            },
            'right': {
                check: (rect, info) => {
                    const right = rect.right;
                    if (this.movableEle) {
                        info.entered = right < this.movableContainerRect.right;
                        return right - this.movableContainerRect.width <= this.movableContainerRect.right;
                    }
                    info.entered = right < this.containerRect.right;
                    return right - this.containerRect.width <= this.containerRect.right;
                }, axis: 'X'
            },
            'left': {
                check: (rect, info) => {
                    const left = rect.left;
                    info.entered = left > 0;
                    if (this.movableEle) {
                        return left + this.movableContainerRect.width >= this.movableContainerRect.left;
                    }
                    return left + this.containerRect.width >= this.containerRect.left;
                }, axis: 'X'
            }
        };
        this.element = element;
        this.options = options;
        this.movableEle = movableEle;
    }
    observe(callback, onEnterCallback) {
        this.containerRect = this.options.container.getBoundingClientRect();
        EventHandler.add(this.options.container, 'wheel', () => this.fromWheel = true, this);
        EventHandler.add(this.options.container, 'scroll', this.virtualScrollHandler(callback, onEnterCallback), this);
        if (this.options.movableContainer) {
            this.movableContainerRect = this.options.movableContainer.getBoundingClientRect();
            EventHandler.add(this.options.scrollbar, 'wheel', () => this.fromWheel = true, this);
            EventHandler.add(this.options.scrollbar, 'scroll', this.virtualScrollHandler(callback, onEnterCallback), this);
        }
    }
    check(direction) {
        const info = this.sentinelInfo[direction];
        if (this.movableContainerRect && (direction === 'left' || direction === 'right')) {
            return info.check(this.movableEle.getBoundingClientRect(), info);
        }
        return info.check(this.element.getBoundingClientRect(), info);
    }
    virtualScrollHandler(callback, onEnterCallback) {
        const delay = Browser.info.name === 'chrome' ? 200 : 100;
        const debounced100 = debounce(callback, delay);
        const debounced50 = debounce(callback, 50);
        this.options.prevTop = this.options.prevLeft = 0;
        return (e) => {
            const top = this.options.movableContainer ? this.options.container.scrollTop : e.target.scrollTop;
            const left = this.options.movableContainer ? this.options.scrollbar.scrollLeft : e.target.scrollLeft;
            let direction = this.options.prevTop < top ? 'down' : 'up';
            direction = this.options.prevLeft === left ? direction : this.options.prevLeft < left ? 'right' : 'left';
            this.options.prevTop = top;
            this.options.prevLeft = left;
            const current = this.sentinelInfo[direction];
            if (this.options.axes.indexOf(current.axis) === -1) {
                return;
            }
            const check = this.check(direction);
            if (current.entered) {
                if (this.movableEle && (direction === 'right' || direction === 'left')) {
                    onEnterCallback(this.movableEle, current, direction, { top: top, left: left }, this.fromWheel, check);
                }
                else {
                    onEnterCallback(this.element, current, direction, { top: top, left: left }, this.fromWheel, check);
                }
            }
            if (check) {
                let fn = debounced100;
                //this.fromWheel ? this.options.debounceEvent ? debounced100 : callback : debounced100;
                if (current.axis === 'X') {
                    fn = debounced50;
                }
                fn({ direction: direction, sentinel: current, offset: { top: top, left: left },
                    focusElement: document.activeElement });
            }
            this.fromWheel = false;
        };
    }
    setPageHeight(value) {
        this.options.pageHeight = value;
    }
}
