import { IndentCellRenderer } from './indent-cell-renderer';
/**
 * ExpandCellRenderer class which responsible for building group expand cell.
 *
 * @hidden
 */
export class ExpandCellRenderer extends IndentCellRenderer {
    /**
     * Function to render the expand cell
     *
     * @param {Cell} cell - specifies the cell
     * @param {Object} data - specifies the data
     * @param {string} data.field - Defines the field
     * @param {string} data.key - Defines the key
     * @param {Object} attr - specifies the attribute
     * @param {boolean} isExpand - specifies isexpand
     * @returns {Element} returns the element
     */
    render(cell, data, attr, isExpand) {
        const node = this.element.cloneNode();
        node.className = isExpand ? 'e-recordplusexpand' : 'e-recordpluscollapse';
        node.setAttribute('ej-mappingname', data.field);
        node.setAttribute('ej-mappingvalue', data.key);
        node.setAttribute('aria-expanded', isExpand ? 'true' : 'false');
        node.setAttribute('tabindex', '-1');
        node.appendChild(this.parent.createElement('a', {
            className: isExpand ? 'e-icons e-gdiagonaldown e-icon-gdownarrow' : 'e-icons e-gnextforward e-icon-grightarrow',
            attrs: { href: 'javascript:void(0);', 'title': isExpand ? 'expanded' : 'collapsed' }
        }));
        return node;
    }
}
