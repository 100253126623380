import { isNullOrUndefined, extend, addClass, removeClass } from '@syncfusion/ej2-base';
import { attributes as addAttributes } from '@syncfusion/ej2-base';
import { rowDataBound, queryCellInfo } from '../base/constant';
import { setStyleAndAttributes, getObject, extendObjWithFn } from '../base/util';
import { CellType } from '../base/enum';
import { CellMergeRender } from './cell-merge-renderer';
import * as literals from '../base/string-literals';
/**
 * RowRenderer class which responsible for building row content.
 *
 * @hidden
 */
export class RowRenderer {
    constructor(serviceLocator, cellType, parent) {
        this.isSpan = false;
        this.cellType = cellType;
        this.serviceLocator = serviceLocator;
        this.parent = parent;
        this.element = this.parent.createElement('tr', { attrs: { role: 'row' } });
    }
    /* eslint-disable */
    /**
     * Function to render the row content based on Column[] and data.
     *
     * @param {Row<T>} row - specifies the row
     * @param {Column[]} columns - specifies the columns
     * @param {Object} attributes - specifies the attributes
     * @param {string} rowTemplate - specifies the rowTemplate
     * @param {Element} cloneNode - specifies the cloneNode
     * @returns {Element} returns the element
     */
    /* eslint-enable */
    render(row, columns, attributes, rowTemplate, cloneNode) {
        return this.refreshRow(row, columns, attributes, rowTemplate, cloneNode);
    }
    /* eslint-disable */
    /**
     * Function to refresh the row content based on Column[] and data.
     *
     * @param {Row<T>} row - specifies the row
     * @param {Column[]} columns - specifies the column
     * @param {boolean} isChanged - specifies isChanged
     * @param {Object} attributes - specifies the attributes
     * @param {string} rowTemplate - specifies the rowTemplate
     * @returns {void}
     */
    /* eslint-enable */
    refresh(row, columns, isChanged, attributes, rowTemplate) {
        if (isChanged) {
            row.data = extendObjWithFn({}, row.changes);
            this.refreshMergeCells(row);
        }
        const node = this.parent.element.querySelector('[data-uid=' + row.uid + ']');
        const tr = this.refreshRow(row, columns, attributes, rowTemplate, null, isChanged);
        const cells = [].slice.call(tr.cells);
        const tempCells = [].slice.call(node.querySelectorAll('.e-templatecell'));
        if (this.parent.isReact && tempCells.length) {
            for (const col of columns) {
                if (col.template) {
                    setTimeout(() => {
                        this.parent.refreshReactColumnTemplateByUid(col.uid);
                    }, 0);
                    break;
                }
            }
        }
        node.innerHTML = '';
        for (const cell of cells) {
            node.appendChild(cell);
        }
    }
    // tslint:disable-next-line:max-func-body-length
    refreshRow(row, columns, attributes, rowTemplate, cloneNode, isEdit) {
        const tr = !isNullOrUndefined(cloneNode) ? cloneNode : this.element.cloneNode();
        const rowArgs = { data: row.data };
        const cellArgs = { data: row.data };
        const chekBoxEnable = this.parent.getColumns().filter((col) => col.type === 'checkbox' && col.field)[0];
        let value = false;
        if (chekBoxEnable) {
            value = getObject(chekBoxEnable.field, rowArgs.data);
        }
        const selIndex = this.parent.getSelectedRowIndexes();
        if (row.isDataRow) {
            row.isSelected = selIndex.indexOf(row.index) > -1 || value;
        }
        if (row.isDataRow && this.parent.isCheckBoxSelection
            && this.parent.checkAllRows === 'Check' && this.parent.enableVirtualization) {
            row.isSelected = true;
            if (selIndex.indexOf(row.index) === -1) {
                selIndex.push(row.index);
            }
        }
        this.buildAttributeFromRow(tr, row);
        addAttributes(tr, extend({}, attributes, {}));
        setStyleAndAttributes(tr, row.attributes);
        const cellRendererFact = this.serviceLocator.getService('cellRendererFactory');
        for (let i = 0, len = row.cells.length; i < len; i++) {
            const cell = row.cells[i];
            cell.isSelected = row.isSelected;
            cell.isColumnSelected = cell.column.isSelected;
            const cellRenderer = cellRendererFact.getCellRenderer(row.cells[i].cellType || CellType.Data);
            const attrs = { 'index': !isNullOrUndefined(row.index) ? row.index.toString() : '' };
            if (row.isExpand && row.cells[i].cellType === CellType.DetailExpand) {
                attrs['class'] = this.parent.isPrinting ? 'e-detailrowcollapse' : 'e-detailrowexpand';
            }
            let td = cellRenderer.render(row.cells[i], row.data, attrs, row.isExpand, isEdit);
            if (row.cells[i].cellType !== CellType.Filter) {
                if (row.cells[i].cellType === CellType.Data || row.cells[i].cellType === CellType.CommandColumn) {
                    this.parent.trigger(queryCellInfo, extend(cellArgs, {
                        cell: td, column: cell.column, colSpan: 1,
                        rowSpan: 1, foreignKeyData: row.cells[i].foreignKeyData,
                        requestType: this.parent.requestTypeAction
                    }));
                    let isRowSpanned = false;
                    if (row.index > 0 && this.isSpan) {
                        const prevRowCells = this.parent.groupSettings.columns.length > 0 &&
                            !this.parent.getRowsObject()[row.index - 1].isDataRow ?
                            this.parent.getRowsObject()[row.index].cells : this.parent.getRowsObject()[row.index - 1].cells;
                        const uid = 'uid';
                        const prevRowCell = prevRowCells.filter((cell) => cell.column.uid === row.cells[i].column[uid])[0];
                        isRowSpanned = prevRowCell.isRowSpanned ? prevRowCell.isRowSpanned : prevRowCell.rowSpanRange > 1;
                    }
                    if (cellArgs.colSpan > 1 || row.cells[i].cellSpan > 1 || cellArgs.rowSpan > 1 || isRowSpanned) {
                        this.isSpan = true;
                        const cellMerge = new CellMergeRender(this.serviceLocator, this.parent);
                        td = cellMerge.render(cellArgs, row, i, td);
                    }
                }
                if (!row.cells[i].isSpanned) {
                    tr.appendChild(td);
                }
            }
        }
        const args = { row: tr, rowHeight: this.parent.rowHeight };
        if (row.isDataRow) {
            const eventArg = extend(rowArgs, args);
            eventArg.isSelectable = true;
            this.parent.trigger(rowDataBound, eventArg);
            row.isSelectable = eventArg.isSelectable;
            const isDraggable = this.parent.isRowDragable();
            if (this.parent.allowPaging && this.parent.selectionSettings.persistSelection) {
                const primaryKey = this.parent.getPrimaryKeyFieldNames()[0];
                const pKey = row.data ? row.data[primaryKey] : null;
                const SelectedRecords = eventArg.isSelectable ? this.parent.partialSelectedRecords :
                    this.parent.disableSelectedRecords;
                if (!SelectedRecords.some((data) => data[primaryKey] === pKey)) {
                    SelectedRecords.push(row.data);
                }
            }
            if (!eventArg.isSelectable) {
                this.parent.selectionModule.isPartialSelection = true;
                row.isSelected = false;
                const chkBox = args.row.querySelectorAll('.e-rowcell.e-gridchkbox');
                const isDrag = eventArg.row.querySelector('.e-rowdragdrop');
                const cellIdx = this.parent.groupSettings.columns.length + (isDrag || this.parent.isDetail() ? 1 : 0);
                for (let i = 0; i < chkBox.length; i++) {
                    chkBox[i].firstElementChild.classList.add('e-checkbox-disabled');
                    chkBox[i].querySelector('.e-frame').classList.remove('e-check');
                }
                if (row.cells.length) {
                    for (let i = cellIdx; i < row.cells.length; i++) {
                        const cell = eventArg.row.querySelector('.e-rowcell[data-colindex="' + row.cells[i].index + '"]');
                        if (cell) {
                            removeClass([cell], ['e-selectionbackground', 'e-active']);
                        }
                    }
                }
                if (isDrag) {
                    removeClass([isDrag], ['e-selectionbackground', 'e-active']);
                }
            }
            if (this.parent.childGrid || isDraggable || this.parent.detailTemplate) {
                const td = tr.querySelectorAll('.e-rowcell:not(.e-hide)')[0];
                if (td) {
                    td.classList.add('e-detailrowvisible');
                }
            }
        }
        if (this.parent.enableVirtualization) {
            rowArgs.rowHeight = this.parent.rowHeight;
        }
        if (rowArgs.rowHeight) {
            tr.style.height = rowArgs.rowHeight + 'px';
        }
        else if (this.parent.rowHeight && (tr.querySelector('.e-headercell') || tr.querySelector('.e-groupcaption'))) {
            tr.style.height = this.parent.rowHeight + 'px';
        }
        if (row.cssClass) {
            tr.classList.add(row.cssClass);
        }
        if (row.lazyLoadCssClass) {
            tr.classList.add(row.lazyLoadCssClass);
        }
        const vFTable = this.parent.enableColumnVirtualization && this.parent.frozenColumns !== 0;
        if (!vFTable && this.parent.aggregates.length && this.parent.element.scrollHeight > this.parent.height) {
            for (let i = 0; i < this.parent.aggregates.length; i++) {
                const property = 'properties';
                const column = 'columns';
                if (this.parent.aggregates[i][property][column][0].footerTemplate) {
                    const summarycell = [].slice.call(tr.getElementsByClassName('e-summarycell'));
                    if (summarycell.length) {
                        const lastSummaryCell = (summarycell[summarycell.length - 1]);
                        addClass([lastSummaryCell], ['e-lastsummarycell']);
                        const firstSummaryCell = (summarycell[0]);
                        addClass([firstSummaryCell], ['e-firstsummarycell']);
                    }
                }
            }
        }
        return tr;
    }
    refreshMergeCells(row) {
        for (const cell of row.cells) {
            cell.isSpanned = false;
        }
        return row;
    }
    /* eslint-disable */
    /**
     * Function to check and add alternative row css class.
     *
     * @param {Element} tr - specifies the tr element
     * @param {Row<T>} row - specifies the row
     * @returns {void}
     */
    /* eslint-enable */
    buildAttributeFromRow(tr, row) {
        const attr = {};
        const prop = { 'rowindex': literals.dataRowIndex, 'dataUID': 'data-uid', 'ariaSelected': 'aria-selected' };
        const classes = [];
        if (row.isDataRow) {
            classes.push(literals.row);
        }
        if (row.isAltRow) {
            classes.push('e-altrow');
        }
        if (!isNullOrUndefined(row.index)) {
            attr[literals.ariaRowIndex] = row.index + 1;
            attr[prop.rowindex] = row.index;
        }
        if (row.rowSpan) {
            attr.rowSpan = row.rowSpan;
        }
        if (row.uid) {
            attr[prop.dataUID] = row.uid;
        }
        if (row.isSelected) {
            attr[prop.ariaSelected] = true;
        }
        if (row.visible === false) {
            classes.push('e-hide');
        }
        attr.class = classes;
        setStyleAndAttributes(tr, attr);
    }
}
