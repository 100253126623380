import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { CellRenderer } from './cell-renderer';
import { headerCellInfo } from '../base/constant';
import { setStyleAndAttributes, appendChildren } from '../base/util';
/**
 * StackedHeaderCellRenderer class which responsible for building stacked header cell content.
 *
 * @hidden
 */
export class StackedHeaderCellRenderer extends CellRenderer {
    constructor() {
        super(...arguments);
        this.element = this.parent.createElement('TH', {
            className: 'e-headercell e-stackedheadercell', attrs: {
                role: 'columnheader',
                tabindex: '-1'
            }
        });
    }
    /**
     * Function to render the cell content based on Column object.
     *
     * @param {Cell<Column>} cell - specifies the cell
     * @param {Object} data - specifies the data
     * @param {object} attributes - specifies the attributes
     * @returns {Element} returns the element
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render(cell, data, attributes) {
        const node = this.element.cloneNode();
        const div = this.parent.createElement('div', {
            className: 'e-stackedheadercelldiv',
            attrs: { 'e-mappinguid': cell.column.uid }
        });
        const column = cell.column;
        node.appendChild(div);
        if (!isNullOrUndefined(column.headerTemplate)) {
            appendChildren(div, column.getHeaderTemplate()(column, this.parent, 'headerTemplate'));
        }
        else {
            this.appendHtml(div, column.headerText, column.getDomSetter());
        }
        if (cell.column.toolTip) {
            node.setAttribute('title', cell.column.toolTip);
        }
        if (column.clipMode === 'Clip' || (!column.clipMode && this.parent.clipMode === 'Clip')) {
            node.classList.add('e-gridclip');
        }
        else if (column.clipMode === 'EllipsisWithTooltip' || (!column.clipMode && this.parent.clipMode === 'EllipsisWithTooltip')) {
            node.classList.add('e-ellipsistooltip');
        }
        if (!isNullOrUndefined(cell.column.textAlign)) {
            div.style.textAlign = cell.column.textAlign;
        }
        if (cell.column.customAttributes) {
            setStyleAndAttributes(node, cell.column.customAttributes);
        }
        node.setAttribute('colspan', cell.colSpan.toString());
        node.setAttribute('aria-colspan', cell.colSpan.toString());
        node.setAttribute('aria-rowspan', '1');
        if (this.parent.allowResizing) {
            const handler = this.parent.createElement('div');
            handler.className = cell.column.allowResizing ? 'e-rhandler e-rcursor' : 'e-rsuppress';
            node.appendChild(handler);
        }
        if (cell.className) {
            node.classList.add(cell.className);
        }
        this.parent.trigger(headerCellInfo, { cell, node });
        return node;
    }
}
