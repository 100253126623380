import { Row } from '../models/row';
import { isNullOrUndefined, extend, setValue } from '@syncfusion/ej2-base';
import { CellType } from '../base/enum';
import { RowModelGenerator } from '../services/row-model-generator';
import { GroupSummaryModelGenerator, CaptionSummaryModelGenerator } from '../services/summary-model-generator';
import { getForeignData, getUid } from '../../grid/base/util';
/**
 * GroupModelGenerator is used to generate group caption rows and data rows.
 *
 * @hidden
 */
export class GroupModelGenerator extends RowModelGenerator {
    constructor(parent) {
        super(parent);
        this.rows = [];
        /** @hidden */
        this.index = 0;
        this.infiniteChildCount = 0;
        this.renderInfiniteAgg = true;
        this.parent = parent;
        this.summaryModelGen = new GroupSummaryModelGenerator(parent);
        this.captionModelGen = new CaptionSummaryModelGenerator(parent);
    }
    generateRows(data, args) {
        if (this.parent.groupSettings.columns.length === 0) {
            return super.generateRows(data, args);
        }
        this.isInfiniteScroll = (args.requestType === 'infiniteScroll');
        this.rows = [];
        this.index = this.parent.enableVirtualization || this.isInfiniteScroll ? args.startIndex : 0;
        for (let i = 0, len = data.length; i < len; i++) {
            this.infiniteChildCount = 0;
            this.renderInfiniteAgg = true;
            this.getGroupedRecords(0, data[i], data.level, i, undefined, this.rows.length);
        }
        this.index = 0;
        if (this.parent.isCollapseStateEnabled()) {
            this.ensureRowVisibility();
        }
        return this.rows;
    }
    getGroupedRecords(index, data, raw, parentid, childId, tIndex, parentUid) {
        const level = raw;
        if (isNullOrUndefined(data.items)) {
            if (isNullOrUndefined(data.GroupGuid)) {
                this.rows = this.rows.concat(this.generateDataRows(data, index, parentid, this.rows.length, parentUid));
            }
            else {
                for (let j = 0, len = data.length; j < len; j++) {
                    this.getGroupedRecords(index, data[j], data.level, parentid, index, this.rows.length, parentUid);
                }
            }
        }
        else {
            let preCaption;
            const captionRow = this.generateCaptionRow(data, index, parentid, childId, tIndex, parentUid);
            if (this.isInfiniteScroll) {
                preCaption = this.getPreCaption(index, captionRow.data.key);
            }
            if (!preCaption) {
                this.rows = this.rows.concat(captionRow);
            }
            else {
                captionRow.uid = preCaption.uid;
            }
            if (data.items && data.items.length) {
                this.getGroupedRecords(index + 1, data.items, data.items.level, parentid, index + 1, this.rows.length, captionRow.uid);
            }
            if (this.parent.aggregates.length && this.isRenderAggregate(captionRow)) {
                const rowCnt = this.rows.length;
                this.rows.push(...this.summaryModelGen.generateRows(data, { level: level, parentUid: captionRow.uid }));
                for (let i = rowCnt - 1; i >= 0; i--) {
                    if (this.rows[i].isCaptionRow) {
                        this.rows[i].aggregatesCount = this.rows.length - rowCnt;
                    }
                    else if (!this.rows[i].isCaptionRow && !this.rows[i].isDataRow) {
                        break;
                    }
                }
            }
            if (preCaption) {
                this.setInfiniteRowVisibility(preCaption);
            }
        }
    }
    isRenderAggregate(data) {
        if (this.parent.enableInfiniteScrolling) {
            if (!this.renderInfiniteAgg) {
                return false;
            }
            this.getPreCaption(data.indent, data.data.key);
            this.renderInfiniteAgg = data.data.count === this.infiniteChildCount;
            return this.renderInfiniteAgg;
        }
        return !this.parent.enableInfiniteScrolling;
    }
    getPreCaption(indent, key) {
        const rowObj = [...this.parent.getRowsObject(), ...this.rows];
        let preCap;
        this.infiniteChildCount = 0;
        let i = rowObj.length;
        while (i--) {
            if (rowObj[i].isCaptionRow && rowObj[i].indent === indent
                && rowObj[i].data.key === key) {
                preCap = rowObj[i];
            }
            if (rowObj[i].indent === indent || rowObj[i].indent < indent) {
                break;
            }
            if (rowObj[i].indent === indent + 1) {
                this.infiniteChildCount++;
            }
        }
        return preCap;
    }
    getCaptionRowCells(field, indent, data) {
        const cells = [];
        let visibles = [];
        let column = this.parent.getColumnByField(field);
        const indexes = this.parent.getColumnIndexesInView();
        if (this.parent.enableColumnVirtualization) {
            column = this.parent.columns.filter((c) => c.field === field)[0];
        }
        const groupedLen = this.parent.groupSettings.columns.length;
        const gObj = this.parent;
        if (!this.parent.enableColumnVirtualization || indexes.indexOf(indent) !== -1) {
            for (let i = 0; i < indent; i++) {
                cells.push(this.generateIndentCell());
            }
            cells.push(this.generateCell({}, null, CellType.Expand));
        }
        indent = this.parent.enableColumnVirtualization ? 1 :
            (this.parent.getVisibleColumns().length + groupedLen + (gObj.detailTemplate || gObj.childGrid ? 1 : 0) -
                indent + (this.parent.getVisibleColumns().length ? -1 : 0));
        //Captionsummary cells will be added here.
        if (this.parent.aggregates.length && !this.captionModelGen.isEmpty()) {
            const captionCells = this.captionModelGen.generateRows(data)[0];
            extend(data, captionCells.data);
            let cIndex = 0;
            captionCells.cells.some((cell, index) => { cIndex = index; return cell.visible && cell.isDataCell; });
            visibles = captionCells.cells.slice(cIndex).filter((cell) => cell.visible);
            if (captionCells.visible && visibles[0].column.field === this.parent.getVisibleColumns()[0].field) {
                visibles = visibles.slice(1);
            }
            if (this.parent.getVisibleColumns().length === 1) {
                visibles = [];
            }
            indent = indent - visibles.length;
        }
        const cols = (!this.parent.enableColumnVirtualization ? [column] : this.parent.getColumns());
        let wFlag = true;
        for (let j = 0; j < cols.length; j++) {
            const tmpFlag = wFlag && indexes.indexOf(indent) !== -1;
            if (tmpFlag) {
                wFlag = false;
            }
            const cellType = !this.parent.enableColumnVirtualization || tmpFlag ?
                CellType.GroupCaption : CellType.GroupCaptionEmpty;
            indent = this.parent.enableColumnVirtualization && cellType === CellType.GroupCaption ? indent + groupedLen : indent;
            if (gObj.isRowDragable()) {
                indent++;
            }
            cells.push(this.generateCell(column, null, cellType, indent));
        }
        cells.push(...visibles);
        return cells;
    }
    /**
     * @param {GroupedData} data - specifies the data
     * @param {number} indent - specifies the indent
     * @param {number} parentID - specifies the parentID
     * @param {number} childID - specifies the childID
     * @param {number} tIndex - specifies the TIndex
     * @param {string} parentUid - specifies the ParentUid
     * @returns {Row<Column>} returns the Row object
     * @hidden
     */
    generateCaptionRow(data, indent, parentID, childID, tIndex, parentUid) {
        const options = {};
        const records = 'records';
        const col = this.parent.getColumnByField(data.field);
        options.data = extend({}, data);
        if (col) {
            options.data.field = data.field;
        }
        options.isDataRow = false;
        options.isExpand = !this.parent.groupSettings.enableLazyLoading && !this.parent.isCollapseStateEnabled();
        options.parentGid = parentID;
        options.childGid = childID;
        options.tIndex = tIndex;
        options.isCaptionRow = true;
        options.parentUid = parentUid;
        options.gSummary = !isNullOrUndefined(data.items[records]) ? data.items[records].length : data.items.length;
        options.uid = getUid('grid-row');
        const row = new Row(options);
        row.indent = indent;
        this.getForeignKeyData(row);
        row.cells = this.getCaptionRowCells(data.field, indent, row.data);
        return row;
    }
    getForeignKeyData(row) {
        const data = row.data;
        const col = this.parent.getColumnByField(data.field);
        if (col && col.isForeignColumn && col.isForeignColumn()) {
            const fkValue = (isNullOrUndefined(data.key) ? '' : col.valueAccessor(col.foreignKeyValue, getForeignData(col, {}, data.key)[0], col));
            setValue('foreignKey', fkValue, row.data);
        }
    }
    /**
     * @param {Object[]} data - specifies the data
     * @param {number} indent - specifies the indent
     * @param {number} childID - specifies the childID
     * @param {number} tIndex - specifies the tIndex
     * @param {string} parentUid - specifies the ParentUid
     * @returns {Row<Column>[]} returns the row object
     * @hidden
     */
    generateDataRows(data, indent, childID, tIndex, parentUid) {
        const rows = [];
        const indexes = this.parent.getColumnIndexesInView();
        for (let i = 0, len = data.length; i < len; i++, tIndex++) {
            rows[i] = this.generateRow(data[i], this.index, i ? undefined : 'e-firstchildrow', indent, childID, tIndex, parentUid);
            for (let j = 0; j < indent; j++) {
                if (this.parent.enableColumnVirtualization && indexes.indexOf(indent) === -1) {
                    continue;
                }
                rows[i].cells.unshift(this.generateIndentCell());
            }
            this.index++;
        }
        return rows;
    }
    generateIndentCell() {
        return this.generateCell({}, null, CellType.Indent);
    }
    refreshRows(input) {
        const indexes = this.parent.getColumnIndexesInView();
        for (let i = 0; i < input.length; i++) {
            if (input[i].isDataRow) {
                input[i].cells = this.generateCells(input[i]);
                for (let j = 0; j < input[i].indent; j++) {
                    if (this.parent.enableColumnVirtualization && indexes.indexOf(input[i].indent) === -1) {
                        continue;
                    }
                    input[i].cells.unshift(this.generateIndentCell());
                }
            }
            else {
                const cRow = this.generateCaptionRow(input[i].data, input[i].indent);
                input[i].cells = cRow.cells;
            }
        }
        return input;
    }
    setInfiniteRowVisibility(caption) {
        if (!caption.isExpand || caption.visible === false) {
            for (const row of this.rows) {
                if (row.parentUid === caption.uid) {
                    row.visible = false;
                    if (row.isCaptionRow) {
                        this.setInfiniteRowVisibility(row);
                    }
                }
            }
        }
    }
    ensureRowVisibility() {
        for (let i = 0; i < this.rows.length; i++) {
            const row = this.rows[i];
            if (!row.isCaptionRow) {
                continue;
            }
            for (let j = i + 1; j < this.rows.length; j++) {
                const childRow = this.rows[j];
                if (row.uid === childRow.parentUid) {
                    this.rows[j].visible = row.isExpand;
                }
            }
        }
    }
}
