import * as events from '../base/constant';
import { isActionPrevent } from '../base/util';
import { CheckBoxFilterBase } from '../common/checkbox-filter-base';
/**
 * @hidden
 * `CheckBoxFilter` module is used to handle filtering action.
 */
export class CheckBoxFilter {
    /**
     * Constructor for checkbox filtering module
     *
     * @param {IGrid} parent - specifies the IGrid
     * @param {FilterSettings} filterSettings - specifies the filtersettings
     * @param {ServiceLocator} serviceLocator - specifies the ServiceLocator
     * @hidden
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    constructor(parent, filterSettings, serviceLocator) {
        this.parent = parent;
        this.isresetFocus = true;
        this.checkBoxBase = new CheckBoxFilterBase(parent);
        this.addEventListener();
    }
    /**
     * To destroy the check box filter.
     *
     * @returns {void}
     * @hidden
     */
    destroy() {
        this.removeEventListener();
        this.checkBoxBase.closeDialog();
    }
    openDialog(options) {
        this.checkBoxBase.openDialog(options);
        this.parent.log('column_type_missing', { column: options.column });
    }
    closeDialog() {
        this.destroy();
        if (this.isresetFocus) {
            this.parent.notify(events.restoreFocus, {});
        }
    }
    closeResponsiveDialog() {
        this.checkBoxBase.closeDialog();
    }
    /**
     * For internal use only - Get the module name.
     *
     * @returns {string} - returns the module name
     * @private
     */
    getModuleName() {
        return 'checkboxFilter';
    }
    actionBegin(args) {
        this.parent.trigger(events.actionBegin, args);
    }
    actionComplete(args) {
        this.parent.trigger(events.actionComplete, args);
    }
    actionPrevent(args) {
        if (isActionPrevent(this.parent)) {
            this.parent.notify(events.preventBatch, args);
            args.cancel = true;
        }
    }
    clearCustomFilter(col) {
        this.checkBoxBase.clearFilter(col);
    }
    applyCustomFilter() {
        this.checkBoxBase.fltrBtnHandler();
        this.checkBoxBase.closeDialog();
    }
    addEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.on(events.cBoxFltrBegin, this.actionBegin, this);
        this.parent.on(events.cBoxFltrComplete, this.actionComplete, this);
        this.parent.on(events.fltrPrevent, this.actionPrevent, this);
    }
    removeEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.off(events.cBoxFltrBegin, this.actionBegin);
        this.parent.off(events.cBoxFltrComplete, this.actionComplete);
        this.parent.off(events.fltrPrevent, this.actionPrevent);
    }
}
