import { createEditElement } from '../base/util';
/**
 * `DropDownEditCell` is used to handle dropdown cell type editing.
 *
 * @hidden
 */
export class EditCellBase {
    constructor(parent) {
        this.parent = parent;
    }
    create(args) {
        return createEditElement(this.parent, args.column, 'e-field', { type: 'text' });
    }
    read(element) {
        return element.ej2_instances[0].value;
    }
    destroy() {
        if (this.obj && !this.obj.isDestroyed) {
            if (this.removeEventHandler) {
                this.removeEventHandler();
            }
            this.obj.destroy();
        }
    }
}
