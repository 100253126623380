import { remove, resetBlazorTemplate, blazorTemplates, getValue } from '@syncfusion/ej2-base';
import { isNullOrUndefined, extend } from '@syncfusion/ej2-base';
import { DataManager, Query, Deferred, Predicate, DataUtil } from '@syncfusion/ej2-data';
import { ValueFormatter } from '../services/value-formatter';
import { RenderType, CellType } from '../base/enum';
import { Data } from '../actions/data';
import { Column } from '../models/column';
import { Row } from '../models/row';
import { Cell } from '../models/cell';
import * as events from '../base/constant';
import { prepareColumns, setFormatter, isGroupAdaptive, getDatePredicate, getObject, clearReactVueTemplates } from '../base/util';
import { ContentRender } from '../renderer/content-renderer';
import { HeaderRender } from '../renderer/header-renderer';
import { CellRenderer } from '../renderer/cell-renderer';
import { HeaderCellRenderer } from '../renderer/header-cell-renderer';
import { StackedHeaderCellRenderer } from '../renderer/stacked-cell-renderer';
import { IndentCellRenderer } from '../renderer/indent-cell-renderer';
import { GroupCaptionCellRenderer, GroupCaptionEmptyCellRenderer } from '../renderer/caption-cell-renderer';
import { ExpandCellRenderer } from '../renderer/expand-cell-renderer';
import { HeaderIndentCellRenderer } from '../renderer/header-indent-renderer';
import { DetailHeaderIndentCellRenderer } from '../renderer/detail-header-indent-renderer';
import { DetailExpandCellRenderer } from '../renderer/detail-expand-cell-renderer';
import { RowDragDropRenderer } from './row-drag-drop-renderer';
import { RowDragDropHeaderRenderer } from '../renderer/row-drag-header-indent-render';
import * as literals from '../base/string-literals';
/**
 * Content module is used to render grid content
 *
 * @hidden
 */
export class Render {
    /**
     * Constructor for render module
     *
     * @param {IGrid} parent - specifies the IGrid
     * @param {ServiceLocator} locator - specifies the serviceLocator
     */
    constructor(parent, locator) {
        this.emptyGrid = false;
        this.counter = 0;
        this.parent = parent;
        this.locator = locator;
        this.data = new Data(parent, locator);
        this.l10n = locator.getService('localization');
        this.ariaService = this.locator.getService('ariaService');
        this.renderer = this.locator.getService('rendererFactory');
        this.addEventListener();
    }
    /**
     * To initialize grid header, content and footer rendering
     *
     * @returns {void}
     */
    render() {
        const gObj = this.parent;
        this.headerRenderer = this.renderer.getRenderer(RenderType.Header);
        this.contentRenderer = this.renderer.getRenderer(RenderType.Content);
        this.headerRenderer.renderPanel();
        this.contentRenderer.renderPanel();
        if (gObj.getColumns().length) {
            this.isLayoutRendered = true;
            this.headerRenderer.renderTable();
            this.contentRenderer.renderTable();
            this.emptyRow(false);
        }
        this.parent.scrollModule.setWidth();
        this.parent.scrollModule.setHeight();
        if (this.parent.height !== 'auto') {
            this.parent.scrollModule.setPadding();
        }
        this.refreshDataManager();
    }
    /**
     * Refresh the entire Grid.
     *
     * @param {NotifyArgs} e - specifies the NotifyArgs
     * @returns {void}
     */
    refresh(e = { requestType: 'refresh' }) {
        const gObj = this.parent;
        gObj.notify(`${e.requestType}-begin`, e);
        gObj.trigger(events.actionBegin, e, (args = { requestType: 'refresh' }) => {
            if (args.cancel) {
                gObj.notify(events.cancelBegin, args);
                return;
            }
            if (args.requestType === 'delete' && gObj.allowPaging) {
                const dataLength = args.data.length;
                const count = gObj.pageSettings.totalRecordsCount - dataLength;
                const currentViewData = gObj.getCurrentViewRecords().length;
                // eslint-disable-next-line max-len
                if (!(currentViewData - dataLength) && count && ((gObj.pageSettings.currentPage - 1) * gObj.pageSettings.pageSize) === count) {
                    gObj.prevPageMoving = true;
                    gObj.setProperties({
                        pageSettings: {
                            totalRecordsCount: count, currentPage: Math.ceil(count / gObj.pageSettings.pageSize)
                        }
                    }, true);
                    gObj.pagerModule.pagerObj.totalRecordsCount = count;
                }
            }
            if (args.requestType === 'reorder' && this.parent.dataSource && 'result' in this.parent.dataSource) {
                this.contentRenderer.refreshContentRows(args);
            }
            else if ((args.requestType === 'paging' || args.requestType === 'columnstate' || args.requestType === 'reorder')
                && this.parent.groupSettings.enableLazyLoading && this.parent.groupSettings.columns.length
                && this.parent.contentModule.getGroupCache()[this.parent.pageSettings.currentPage]) {
                this.contentRenderer.refreshContentRows(args);
            }
            else {
                this.refreshDataManager(args);
            }
        });
    }
    /**
     * @returns {void}
     * @hidden
     */
    resetTemplates() {
        const gObj = this.parent;
        const gridColumns = gObj.getColumns();
        if (gObj.detailTemplate) {
            const detailTemplateID = gObj.element.id + 'detailTemplate';
            blazorTemplates[detailTemplateID] = [];
            resetBlazorTemplate(detailTemplateID, 'DetailTemplate');
        }
        if (gObj.groupSettings.captionTemplate) {
            resetBlazorTemplate(gObj.element.id + 'captionTemplate', 'CaptionTemplate');
        }
        if (gObj.rowTemplate) {
            resetBlazorTemplate(gObj.element.id + 'rowTemplate', 'RowTemplate');
        }
        if (gObj.toolbarTemplate) {
            resetBlazorTemplate(gObj.element.id + 'toolbarTemplate', 'ToolbarTemplate');
        }
        if (gObj.pageSettings.template) {
            resetBlazorTemplate(gObj.element.id + '_template', 'pageSettings');
        }
        for (let i = 0; i < gridColumns.length; i++) {
            if (gridColumns[i].template) {
                blazorTemplates[gObj.element.id + gridColumns[i].uid] = [];
                resetBlazorTemplate(gObj.element.id + gridColumns[i].uid, 'Template');
            }
            if (gridColumns[i].headerTemplate) {
                resetBlazorTemplate(gObj.element.id + gridColumns[i].uid + 'headerTemplate', 'HeaderTemplate');
            }
            if (gridColumns[i].filterTemplate) {
                resetBlazorTemplate(gObj.element.id + gridColumns[i].uid + 'filterTemplate', 'FilterTemplate');
            }
        }
        const guid = 'guid';
        for (let k = 0; k < gObj.aggregates.length; k++) {
            for (let j = 0; j < gObj.aggregates[k].columns.length; j++) {
                if (gObj.aggregates[k].columns[j].footerTemplate) {
                    const tempID = gObj.element.id + gObj.aggregates[k].columns[j][guid] + 'footerTemplate';
                    resetBlazorTemplate(tempID, 'FooterTemplate');
                }
                if (gObj.aggregates[k].columns[j].groupFooterTemplate) {
                    const tempID = gObj.element.id + gObj.aggregates[k].columns[j][guid] + 'groupFooterTemplate';
                    resetBlazorTemplate(tempID, 'GroupFooterTemplate');
                }
                if (gObj.aggregates[k].columns[j].groupCaptionTemplate) {
                    const tempID = gObj.element.id + gObj.aggregates[k].columns[j][guid] + 'groupCaptionTemplate';
                    resetBlazorTemplate(tempID, 'GroupCaptionTemplate');
                }
            }
        }
    }
    refreshComplete(e) {
        this.parent.trigger(events.actionComplete, e);
    }
    /**
     * The function is used to refresh the dataManager
     *
     * @param {NotifyArgs} args - specifies the args
     * @returns {void}
     */
    refreshDataManager(args = {}) {
        if (args.requestType !== 'virtualscroll' && !args.isCaptionCollapse) {
            this.parent.showSpinner();
        }
        this.parent.notify(events.resetInfiniteBlocks, args);
        this.emptyGrid = false;
        let dataManager;
        const isFActon = this.isNeedForeignAction();
        this.ariaService.setBusy(this.parent.getContent().querySelector('.' + literals.content), true);
        if (isFActon) {
            const deffered = new Deferred();
            dataManager = this.getFData(deffered, args);
        }
        if (!dataManager) {
            dataManager = this.data.getData(args, this.data.generateQuery().requiresCount());
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            dataManager = dataManager.then((e) => {
                const query = this.data.generateQuery().requiresCount();
                if (this.emptyGrid) {
                    const def = new Deferred();
                    def.resolve({ result: [], count: 0 });
                    return def.promise;
                }
                return this.data.getData(args, query);
            });
        }
        if (this.parent.getForeignKeyColumns().length && (!isFActon || this.parent.searchSettings.key.length)) {
            const deffered = new Deferred();
            dataManager = dataManager.then((e) => {
                this.parent.notify(events.getForeignKeyData, { dataManager: dataManager, result: e, promise: deffered, action: args });
                return deffered.promise;
            });
        }
        if (this.parent.groupSettings.disablePageWiseAggregates && this.parent.groupSettings.columns.length) {
            dataManager = dataManager.then((e) => this.validateGroupRecords(e));
        }
        dataManager.then((e) => this.dataManagerSuccess(e, args))
            .catch((e) => this.dataManagerFailure(e, args));
    }
    getFData(deferred, args) {
        this.parent.notify(events.getForeignKeyData, { isComplex: true, promise: deferred, action: args });
        return deferred.promise;
    }
    isNeedForeignAction() {
        const gObj = this.parent;
        return !!((gObj.allowFiltering && gObj.filterSettings.columns.length) ||
            (gObj.searchSettings.key.length)) && this.foreignKey(this.parent.getForeignKeyColumns());
    }
    foreignKey(columns) {
        return columns.some((col) => {
            let fbool = false;
            fbool = this.parent.filterSettings.columns.some((value) => {
                return col.uid === value.uid;
            });
            return !!(fbool || this.parent.searchSettings.key.length);
        });
    }
    sendBulkRequest(args) {
        args.requestType = 'batchsave';
        const promise = this.data.saveChanges(args.changes, this.parent.getPrimaryKeyFieldNames()[0], args.original);
        const query = this.data.generateQuery().requiresCount();
        if (this.data.dataManager.dataSource.offline) {
            this.refreshDataManager({ requestType: 'batchsave' });
            return;
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            promise.then((e) => {
                this.data.getData(args, query)
                    .then((e) => this.dmSuccess(e, args))
                    .catch((e) => this.dmFailure(e, args));
            })
                .catch((e) => this.dmFailure(e, args));
        }
    }
    dmSuccess(e, args) {
        this.dataManagerSuccess(e, args);
    }
    dmFailure(e, args) {
        this.dataManagerFailure(e, args);
    }
    /**
     * Render empty row to Grid which is used at the time to represent to no records.
     *
     * @returns {void}
     * @hidden
     */
    renderEmptyRow() {
        this.emptyRow(true);
    }
    emptyRow(isTrigger) {
        const gObj = this.parent;
        let tbody = this.contentRenderer.getTable().querySelector(literals.tbody);
        if (!isNullOrUndefined(tbody)) {
            remove(tbody);
        }
        tbody = this.parent.createElement(literals.tbody, { attrs: { role: 'rowgroup' } });
        let spanCount = 0;
        if (gObj.detailTemplate || gObj.childGrid) {
            ++spanCount;
        }
        const tr = this.parent.createElement('tr', { className: 'e-emptyrow', attrs: { role: 'row' } });
        tr.appendChild(this.parent.createElement('td', {
            innerHTML: this.l10n.getConstant('EmptyRecord'),
            attrs: { colspan: (gObj.getVisibleColumns().length + spanCount + gObj.groupSettings.columns.length).toString() }
        }));
        tbody.appendChild(tr);
        this.contentRenderer.renderEmpty(tbody);
        if (isTrigger) {
            this.parent.trigger(events.dataBound, {});
            this.parent.notify(events.onEmpty, { rows: [new Row({ isDataRow: true, cells: [new Cell({ isDataCell: true, visible: true })] })] });
        }
    }
    dynamicColumnChange() {
        if (this.parent.getCurrentViewRecords().length) {
            this.updateColumnType(this.parent.getCurrentViewRecords()[0]);
        }
    }
    updateColumnType(record) {
        const columns = this.parent.getColumns();
        let value;
        const cFormat = 'customFormat';
        const equalTo = 'equalTo';
        const data = record && record.items ? record.items[0] : record;
        const fmtr = this.locator.getService('valueFormatter');
        for (let i = 0, len = columns.length; i < len; i++) {
            value = getObject(columns[i].field || '', data);
            if (!isNullOrUndefined(columns[i][cFormat])) {
                columns[i].format = columns[i][cFormat];
            }
            if (!isNullOrUndefined(columns[i].validationRules) && !isNullOrUndefined(columns[i].validationRules[equalTo])) {
                columns[i].validationRules[equalTo][0] = this.parent.element.id + columns[i].validationRules[equalTo][0];
            }
            if (columns[i].isForeignColumn() && columns[i].columnData) {
                value = getObject(columns[i].foreignKeyValue || '', columns[i].columnData[0]);
            }
            if (!isNullOrUndefined(value)) {
                this.isColTypeDef = true;
                if (!columns[i].type) {
                    columns[i].type = value.getDay ? (value.getHours() > 0 || value.getMinutes() > 0 ||
                        value.getSeconds() > 0 || value.getMilliseconds() > 0 ? 'datetime' : 'date') : typeof (value);
                }
            }
            else {
                columns[i].type = columns[i].type || null;
            }
            const valueFormatter = new ValueFormatter();
            if (columns[i].format && (columns[i].format.skeleton || (columns[i].format.format &&
                typeof columns[i].format.format === 'string'))) {
                columns[i].setFormatter(valueFormatter.getFormatFunction(extend({}, columns[i].format)));
                columns[i].setParser(valueFormatter.getParserFunction(columns[i].format));
            }
            if (typeof (columns[i].format) === 'string') {
                setFormatter(this.locator, columns[i]);
            }
            else if (!columns[i].format && columns[i].type === 'number') {
                columns[i].setParser(fmtr.getParserFunction({ format: 'n2' }));
            }
        }
    }
    /**
     * @param {ReturnType} e - specifies the return type
     * @param {NotifyArgs} args - specifies the Notifyargs
     * @returns {void}
     * @hidden
     */
    // tslint:disable-next-line:max-func-body-length
    dataManagerSuccess(e, args) {
        const gObj = this.parent;
        this.contentRenderer = this.renderer.getRenderer(RenderType.Content);
        this.headerRenderer = this.renderer.getRenderer(RenderType.Header);
        e.actionArgs = args;
        const isInfiniteDelete = this.parent.enableInfiniteScrolling && !this.parent.infiniteScrollSettings.enableCache
            && (args.requestType === 'delete' || (args.requestType === 'save' && this.parent.infiniteScrollModule.requestType === 'add'
                && !(gObj.sortSettings.columns.length || gObj.filterSettings.columns.length || this.parent.groupSettings.columns.length
                    || gObj.searchSettings.key)));
        // tslint:disable-next-line:max-func-body-length
        gObj.trigger(events.beforeDataBound, e, (dataArgs) => {
            if (dataArgs.cancel) {
                return;
            }
            dataArgs.result = isNullOrUndefined(dataArgs.result) ? [] : dataArgs.result;
            const len = Object.keys(dataArgs.result).length;
            if (this.parent.isDestroyed) {
                return;
            }
            if ((!gObj.getColumns().length && !len) && !(gObj.columns.length && gObj.columns[0] instanceof Column)) {
                gObj.hideSpinner();
                return;
            }
            if (this.isInfiniteEnd(args) && !len) {
                this.parent.notify(events.infiniteEditHandler, { e: args, result: e.result, count: e.count, agg: e.aggregates });
                return;
            }
            this.parent.isEdit = false;
            this.parent.notify(events.editReset, {});
            this.parent.notify(events.tooltipDestroy, {});
            this.contentRenderer.prevCurrentView = this.parent.currentViewData.slice();
            gObj.currentViewData = dataArgs.result;
            gObj.notify(events.refreshInfiniteCurrentViewData, { args: args, data: dataArgs.result });
            if (dataArgs.count && !gObj.allowPaging && (gObj.enableVirtualization || gObj.enableInfiniteScrolling)) {
                gObj.totalDataRecordsCount = dataArgs.count;
            }
            if (!len && dataArgs.count && gObj.allowPaging && args && args.requestType !== 'delete') {
                if (this.parent.groupSettings.enableLazyLoading
                    && (args.requestType === 'grouping' || args.requestType === 'ungrouping')) {
                    this.parent.notify(events.groupComplete, args);
                }
                gObj.prevPageMoving = true;
                gObj.pageSettings.totalRecordsCount = dataArgs.count;
                if (args.requestType !== 'paging') {
                    gObj.pageSettings.currentPage = Math.ceil(dataArgs.count / gObj.pageSettings.pageSize);
                }
                gObj.dataBind();
                return;
            }
            if ((!gObj.getColumns().length && len || !this.isLayoutRendered) && !isGroupAdaptive(gObj)) {
                this.updatesOnInitialRender(dataArgs);
            }
            if (!this.isColTypeDef && gObj.getCurrentViewRecords()) {
                if (this.data.dataManager.dataSource.offline && gObj.dataSource && gObj.dataSource.length) {
                    this.updateColumnType(gObj.dataSource[0]);
                }
                else {
                    this.updateColumnType(gObj.getCurrentViewRecords()[0]);
                }
            }
            if (!this.parent.isInitialLoad && this.parent.groupSettings.disablePageWiseAggregates &&
                !this.parent.groupSettings.columns.length) {
                dataArgs.result = this.parent.dataSource instanceof Array ? this.parent.dataSource : this.parent.currentViewData;
            }
            if ((this.parent.isReact || this.parent.isVue) && !isNullOrUndefined(args) && args.requestType !== 'infiniteScroll' && !args.isFrozen) {
                clearReactVueTemplates(this.parent, ['footerTemplate']);
            }
            if (this.parent.isAngular && this.parent.allowGrouping && this.parent.groupSettings.captionTemplate) {
                this.parent.destroyTemplate(['groupSettings_captionTemplate']);
            }
            this.parent.notify(events.dataReady, extend({ count: dataArgs.count, result: dataArgs.result, aggregates: dataArgs.aggregates }, args));
            if ((gObj.groupSettings.columns.length || (args && args.requestType === 'ungrouping'))
                && (args && args.requestType !== 'filtering')) {
                this.headerRenderer.refreshUI();
            }
            if (len) {
                if (isGroupAdaptive(gObj)) {
                    const content = 'content';
                    args.scrollTop = { top: this.contentRenderer[content].scrollTop };
                }
                if (!isInfiniteDelete) {
                    if (this.parent.enableImmutableMode) {
                        this.contentRenderer.immutableModeRendering(args);
                    }
                    else {
                        this.contentRenderer.refreshContentRows(args);
                    }
                }
                else {
                    this.parent.notify(events.infiniteEditHandler, { e: args, result: e.result, count: e.count, agg: e.aggregates });
                }
            }
            else {
                if (args && args.isCaptionCollapse) {
                    return;
                }
                if (!gObj.getColumns().length) {
                    gObj.element.innerHTML = '';
                    alert(this.l10n.getConstant('EmptyDataSourceError')); //ToDO: change this alert as dialog
                    return;
                }
                this.contentRenderer.setRowElements([]);
                this.contentRenderer.setRowObjects([]);
                this.ariaService.setBusy(this.parent.getContent().querySelector('.' + literals.content), false);
                this.renderEmptyRow();
                if (args) {
                    const action = (args.requestType || '').toLowerCase() + '-complete';
                    this.parent.notify(action, args);
                    if (args.requestType === 'batchsave') {
                        args.cancel = false;
                        args.rows = [];
                        args.isFrozen = this.parent.getFrozenColumns() !== 0 && !args.isFrozen;
                        this.parent.trigger(events.actionComplete, args);
                    }
                }
                this.parent.hideSpinner();
            }
            this.parent.notify(events.toolbarRefresh, {});
            this.setRowCount(this.parent.getCurrentViewRecords().length);
            if ('query' in e) {
                this.parent.getDataModule().isQueryInvokedFromData = false;
            }
        });
    }
    /**
     * @param {object} e - specifies the object
     * @param {Object[]} e.result - specifies the result
     * @param {NotifyArgs} args - specifies the args
     * @returns {void}
     * @hidden
     */
    dataManagerFailure(e, args) {
        this.ariaService.setOptions(this.parent.getContent().querySelector('.' + literals.content), { busy: false, invalid: true });
        this.setRowCount(1);
        this.parent.trigger(events.actionFailure, { error: e });
        this.parent.hideSpinner();
        if (args.requestType === 'save' || args.requestType === 'delete'
            || args.name === 'bulk-save') {
            return;
        }
        this.parent.currentViewData = [];
        this.renderEmptyRow();
        this.parent.log('actionfailure', { error: e });
    }
    setRowCount(dataRowCount) {
        this.ariaService.setOptions(this.parent.getHeaderTable(), {
            rowcount: dataRowCount ? dataRowCount.toString() : '1'
        });
    }
    isInfiniteEnd(args) {
        return this.parent.enableInfiniteScrolling && !this.parent.infiniteScrollSettings.enableCache && args.requestType === 'delete';
    }
    updatesOnInitialRender(e) {
        this.isLayoutRendered = true;
        if (this.parent.columns.length < 1) {
            this.buildColumns(e.result[0]);
        }
        prepareColumns(this.parent.columns, null, this.parent);
        this.headerRenderer.renderTable();
        this.contentRenderer.renderTable();
        this.parent.isAutoGen = true;
        this.parent.notify(events.autoCol, {});
    }
    iterateComplexColumns(obj, field, split) {
        const keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
            const childKeys = typeof obj[keys[i]] === 'object' && obj[keys[i]] && !(obj[keys[i]] instanceof Date) ?
                Object.keys(obj[keys[i]]) : [];
            if (childKeys.length) {
                this.iterateComplexColumns(obj[keys[i]], field + (keys[i] + '.'), split);
            }
            else {
                split[this.counter] = field + keys[i];
                this.counter++;
            }
        }
    }
    buildColumns(record) {
        const cols = [];
        const complexCols = {};
        this.iterateComplexColumns(record, '', complexCols);
        const columns = Object.keys(complexCols).filter((e) => complexCols[e] !== 'BlazId').
            map((field) => complexCols[field]);
        for (let i = 0, len = columns.length; i < len; i++) {
            cols[i] = { 'field': columns[i] };
            if (this.parent.enableColumnVirtualization) {
                cols[i].width = !isNullOrUndefined(cols[i].width) ? cols[i].width : 200;
            }
        }
        this.parent.setProperties({ 'columns': cols }, true);
    }
    instantiateRenderer() {
        this.renderer.addRenderer(RenderType.Header, new HeaderRender(this.parent, this.locator));
        this.renderer.addRenderer(RenderType.Content, new ContentRender(this.parent, this.locator));
        const cellrender = this.locator.getService('cellRendererFactory');
        cellrender.addCellRenderer(CellType.Header, new HeaderCellRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.Data, new CellRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.StackedHeader, new StackedHeaderCellRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.Indent, new IndentCellRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.GroupCaption, new GroupCaptionCellRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.GroupCaptionEmpty, new GroupCaptionEmptyCellRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.Expand, new ExpandCellRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.HeaderIndent, new HeaderIndentCellRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.StackedHeader, new StackedHeaderCellRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.DetailHeader, new DetailHeaderIndentCellRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.RowDragHIcon, new RowDragDropHeaderRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.DetailExpand, new DetailExpandCellRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.DetailFooterIntent, new IndentCellRenderer(this.parent, this.locator));
        cellrender.addCellRenderer(CellType.RowDragIcon, new RowDragDropRenderer(this.parent, this.locator));
    }
    addEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.on(events.initialLoad, this.instantiateRenderer, this);
        this.parent.on('refreshdataSource', this.dataManagerSuccess, this);
        this.parent.on(events.modelChanged, this.refresh, this);
        this.parent.on(events.refreshComplete, this.refreshComplete, this);
        this.parent.on(events.bulkSave, this.sendBulkRequest, this);
        this.parent.on(events.showEmptyGrid, () => { this.emptyGrid = true; }, this);
        this.parent.on(events.autoCol, this.dynamicColumnChange, this);
    }
    /**
     * @param {ReturnType} e - specifies the Return type
     * @returns {Promise<Object>} returns the object
     * @hidden
     */
    validateGroupRecords(e) {
        const index = e.result.length - 1;
        if (index < 0) {
            return Promise.resolve(e);
        }
        const group0 = e.result[0];
        const groupN = e.result[index];
        const predicate = [];
        const addWhere = (input) => {
            const groups = [group0, groupN];
            for (let i = 0; i < groups.length; i++) {
                predicate.push(new Predicate('field', '==', groups[i].field).and(this.getPredicate('key', 'equal', groups[i].key)));
            }
            input.where(Predicate.or(predicate));
        };
        const query = new Query();
        addWhere(query);
        const curDm = new DataManager(e.result);
        const curFilter = curDm.executeLocal(query);
        const newQuery = this.data.generateQuery(true);
        const rPredicate = [];
        if (this.data.isRemote()) {
            const groups = [group0, groupN];
            for (let i = 0; i < groups.length; i++) {
                rPredicate.push(this.getPredicate(groups[i].field, 'equal', groups[i].key));
            }
            newQuery.where(Predicate.or(rPredicate));
        }
        else {
            addWhere(newQuery);
        }
        const deferred = new Deferred();
        this.data.getData({}, newQuery).then((r) => {
            this.updateGroupInfo(curFilter, r.result);
            deferred.resolve(e);
        }).catch((e) => deferred.reject(e));
        return deferred.promise;
    }
    getPredicate(key, operator, value) {
        if (value instanceof Date) {
            return getDatePredicate({ field: key, operator: operator, value: value });
        }
        return new Predicate(key, operator, value);
    }
    updateGroupInfo(current, untouched) {
        const dm = new DataManager(untouched);
        const elements = current;
        for (let i = 0; i < elements.length; i++) {
            const uGroup = dm.executeLocal(new Query()
                .where(new Predicate('field', '==', elements[i].field).and(this.getPredicate('key', 'equal', elements[i].key))))[0];
            elements[i].count = uGroup.count;
            const itemGroup = elements[i].items;
            const uGroupItem = uGroup.items;
            if (itemGroup.GroupGuid) {
                elements[i].items = this.updateGroupInfo(elements[i].items, uGroup.items);
            }
            const rows = this.parent.aggregates;
            for (let j = 0; j < rows.length; j++) {
                const row = rows[j];
                for (let k = 0; k < row.columns.length; k++) {
                    const types = row.columns[k].type instanceof Array ? (row.columns[k].type) :
                        [(row.columns[k].type)];
                    for (let l = 0; l < types.length; l++) {
                        const key = row.columns[k].field + ' - ' + types[l].toLowerCase();
                        const data = itemGroup.level ? uGroupItem.records : uGroup.items;
                        const context = this.parent;
                        if (types[l] === 'Custom') {
                            const data = itemGroup.level ? uGroupItem : uGroup;
                            let temp = row.columns[k].customAggregate;
                            if (typeof temp === 'string') {
                                temp = getValue(temp, window);
                            }
                            elements[i].aggregates[key] = temp ? temp.call(context, data, row.columns[k]) : '';
                        }
                        else {
                            // eslint-disable-next-line max-len
                            elements[i].aggregates[key] = DataUtil.aggregates[types[l].toLowerCase()](data, row.columns[k].field);
                        }
                    }
                }
            }
        }
        return current;
    }
}
