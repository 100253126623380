import { getZIndexCalcualtion } from '../base/util';
import { Query, DataManager, DataUtil } from '@syncfusion/ej2-data';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { isNullOrUndefined, extend } from '@syncfusion/ej2-base';
import * as events from '../base/constant';
import * as literals from '../base/string-literals';
/**
 * `boolfilterui` render boolean column.
 *
 * @hidden
 */
export class BooleanFilterUI {
    constructor(parent, serviceLocator, filterSettings) {
        this.parent = parent;
        this.serviceLocator = serviceLocator;
        this.filterSettings = filterSettings;
        if (this.parent) {
            this.parent.on(events.filterMenuClose, this.destroy, this);
            this.parent.on(events.destroy, this.destroy, this);
        }
    }
    create(args) {
        const isForeignColumn = args.column.isForeignColumn();
        const dataSource = isForeignColumn ? args.column.dataSource : this.parent.dataSource;
        const fields = isForeignColumn ? args.column.foreignKeyValue : args.column.field;
        this.elem = this.parent.createElement('input', { className: 'e-flmenu-input', id: 'bool-ui-' + args.column.uid });
        args.target.appendChild(this.elem);
        this.dialogObj = args.dialogObj;
        this.dropInstance = new DropDownList(extend({
            dataSource: dataSource instanceof DataManager ?
                dataSource : new DataManager(dataSource),
            query: new Query().select(fields),
            fields: { text: fields, value: fields },
            placeholder: args.localizeText.getConstant('SelectValue'),
            cssClass: this.parent.cssClass ? 'e-popup-flmenu' + ' ' + this.parent.cssClass : 'e-popup-flmenu',
            locale: this.parent.locale,
            enableRtl: this.parent.enableRtl
        }, args.column.filter.params));
        this.ddOpen = this.openPopup.bind(this);
        this.ddComplete = this.actionComplete(fields);
        this.dropInstance.addEventListener(literals.open, this.ddOpen);
        this.dropInstance.addEventListener(events.actionComplete, this.ddComplete);
        this.dropInstance.appendTo(this.elem);
    }
    write(args) {
        const drpuiObj = document.querySelector('#bool-ui-' + args.column.uid).ej2_instances[0];
        if (!isNullOrUndefined(args.filteredValue)) {
            drpuiObj.text = args.filteredValue;
        }
    }
    read(element, column, filterOptr, filterObj) {
        const drpuiObj = document.querySelector('#bool-ui-' + column.uid).ej2_instances[0];
        const filterValue = drpuiObj.value;
        filterObj.filterByColumn(column.field, filterOptr, filterValue, 'and', false);
    }
    openPopup(args) {
        getZIndexCalcualtion(args, this.dialogObj);
    }
    actionComplete(fields) {
        return (e) => {
            e.result = DataUtil.distinct(e.result, fields, true);
        };
    }
    destroy() {
        if (!this.dropInstance || this.dropInstance.isDestroyed) {
            return;
        }
        this.dropInstance.removeEventListener(literals.open, this.ddOpen);
        this.dropInstance.removeEventListener(events.actionComplete, this.ddComplete);
        this.dropInstance.destroy();
        this.parent.off(events.filterMenuClose, this.destroy);
        this.parent.off(events.destroy, this.destroy);
    }
}
