/**
 * `TemplateEditCell` is used to handle template cell.
 *
 * @hidden
 */
export class TemplateEditCell {
    constructor(parent) {
        this.parent = parent;
    }
    read(element, value) {
        return value;
    }
    write() {
        //
    }
    destroy() {
        //
    }
}
