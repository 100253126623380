import { closest, isNullOrUndefined } from '@syncfusion/ej2-base';
import { click, keyPressed, commandClick, initialEnd, destroy } from '../base/constant';
import { CellType } from '../base/enum';
import { CommandColumnRenderer } from '../renderer/command-column-renderer';
import { getUid } from '../base/util';
import * as literals from '../base/string-literals';
/**
 * `CommandColumn` used to handle the command column actions.
 *
 * @hidden
 */
export class CommandColumn {
    constructor(parent, locator) {
        this.parent = parent;
        this.locator = locator;
        this.initiateRender();
        this.addEventListener();
    }
    initiateRender() {
        const cellFac = this.locator.getService('cellRendererFactory');
        cellFac.addCellRenderer(CellType.CommandColumn, new CommandColumnRenderer(this.parent, this.locator));
    }
    commandClickHandler(e) {
        const gObj = this.parent;
        const target = closest(e.target, 'button');
        if (!target || !closest(e.target, '.e-unboundcell')) {
            return;
        }
        const buttonObj = target.ej2_instances[0];
        const type = buttonObj.commandType;
        const uid = target.getAttribute('data-uid');
        let commandColumn;
        const row = gObj.getRowObjectFromUID(closest(target, '.' + literals.row).getAttribute('data-uid'));
        const cols = this.parent.columnModel;
        for (let i = 0; i < cols.length; i++) {
            if (cols[i].commands) {
                const commandCols = cols[i].commands;
                for (let j = 0; j < commandCols.length; j++) {
                    const idInString = 'uid';
                    const typeInString = 'type';
                    if (commandCols[j][idInString] === uid && commandCols[j][typeInString] === type) {
                        commandColumn = commandCols[j];
                    }
                    else {
                        const buttons = [].slice.call(closest(target, '.e-unboundcell').querySelectorAll('button'));
                        const index = buttons.findIndex((ele) => ele === target);
                        if (index < commandCols.length && commandCols[index][typeInString] === type) {
                            commandColumn = commandCols[index];
                        }
                    }
                }
            }
        }
        const args = {
            cancel: false,
            target: target,
            commandColumn: commandColumn,
            rowData: isNullOrUndefined(row) ? undefined : row.data
        };
        this.parent.trigger(commandClick, args, (commandclickargs) => {
            if (buttonObj.disabled || !gObj.editModule || commandclickargs.cancel) {
                return;
            }
            switch (type) {
                case 'Edit':
                    gObj.editModule.endEdit();
                    gObj.editModule.startEdit(closest(target, 'tr'));
                    break;
                case 'Cancel':
                    gObj.editModule.closeEdit();
                    break;
                case 'Save':
                    gObj.editModule.endEdit();
                    break;
                case 'Delete':
                    if (gObj.editSettings.mode !== 'Batch') {
                        gObj.editModule.endEdit();
                    }
                    gObj.commandDelIndex = parseInt(closest(target, 'tr').getAttribute(literals.dataRowIndex), 10);
                    gObj.clearSelection();
                    //for toogle issue when dbl click
                    gObj.selectRow(gObj.commandDelIndex, false);
                    gObj.editModule.deleteRecord();
                    gObj.commandDelIndex = undefined;
                    break;
            }
        });
    }
    /**
     * For internal use only - Get the module name.
     *
     * @returns {string} returns the module name
     */
    getModuleName() {
        return 'commandColumn';
    }
    /**
     * To destroy CommandColumn.
     *
     * @function destroy
     * @returns {void}
     */
    destroy() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.removeEventListener();
    }
    removeEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.off(click, this.commandClickHandler);
        this.parent.off(keyPressed, this.keyPressHandler);
        this.parent.off(initialEnd, this.load);
        this.parent.off(destroy, this.destroy);
    }
    addEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.on(click, this.commandClickHandler, this);
        this.parent.on(keyPressed, this.keyPressHandler, this);
        this.parent.on(initialEnd, this.load, this);
        this.parent.on(destroy, this.destroy, this);
    }
    keyPressHandler(e) {
        if (e.action === 'enter' && closest(e.target, '.e-unboundcelldiv')) {
            this.commandClickHandler(e);
            e.preventDefault();
        }
    }
    load() {
        const uid = 'uid';
        const col = this.parent.columnModel;
        for (let i = 0; i < col.length; i++) {
            if (col[i].commands) {
                const commandCol = col[i].commands;
                for (let j = 0; j < commandCol.length; j++) {
                    commandCol[j][uid] = getUid('gridcommand');
                }
            }
        }
    }
}
