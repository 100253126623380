import { extend, Internationalization } from '@syncfusion/ej2-base';
import { NumericTextBox } from '@syncfusion/ej2-inputs';
import { isEditable, getComplexFieldID, getObject, createEditElement } from '../base/util';
/**
 * `NumericEditCell` is used to handle numeric cell type editing.
 *
 * @hidden
 */
export class NumericEditCell {
    constructor(parent) {
        this.parent = parent;
    }
    keyEventHandler(args) {
        if (args.keyCode === 13 || args.keyCode === 9) {
            const evt = document.createEvent('HTMLEvents');
            evt.initEvent('change', false, true);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.dispatchEvent(evt);
        }
    }
    create(args) {
        this.instances = new Internationalization(this.parent.locale);
        return createEditElement(this.parent, args.column, 'e-field', {});
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    read(element) {
        return this.obj.value;
    }
    write(args) {
        const col = args.column;
        const isInline = this.parent.editSettings.mode !== 'Dialog';
        this.obj = new NumericTextBox(extend({
            value: parseFloat(getObject(args.column.field, args.rowData)),
            enableRtl: this.parent.enableRtl,
            placeholder: isInline ? '' : args.column.headerText,
            enabled: isEditable(args.column, args.requestType, args.element),
            floatLabelType: this.parent.editSettings.mode !== 'Dialog' ? 'Never' : 'Always',
            locale: this.parent.locale,
            cssClass: this.parent.cssClass ? this.parent.cssClass : null
        }, col.edit.params));
        args.element.setAttribute('name', getComplexFieldID(args.column.field));
        this.obj.appendTo(args.element);
        this.obj.element.addEventListener('keydown', this.keyEventHandler);
    }
    destroy() {
        if (this.obj && !this.obj.isDestroyed) {
            this.obj.element.removeEventListener('keydown', this.keyEventHandler);
            this.obj.destroy();
        }
    }
}
