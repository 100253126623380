import { CellRenderer } from './cell-renderer';
/**
 * DetailHeaderIndentCellRenderer class which responsible for building detail header indent cell.
 *
 * @hidden
 */
export class DetailHeaderIndentCellRenderer extends CellRenderer {
    constructor() {
        super(...arguments);
        this.element = this.parent.createElement('TH', { className: 'e-detailheadercell' });
    }
    /**
     * Function to render the detail indent cell
     *
     * @param  {Cell} cell - specifies the cell
     * @param  {Object} data - specifies the data
     * @returns {Element} returns the element
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render(cell, data) {
        const node = this.element.cloneNode();
        node.appendChild(this.parent.createElement('div', { className: 'e-emptycell' }));
        return node;
    }
}
