import { extend } from '@syncfusion/ej2-base';
import { MaskedTextBox } from '@syncfusion/ej2-inputs';
import { isEditable, getObject } from '../base/util';
import { EditCellBase } from './edit-cell-base';
/**
 * `MaskedTextBoxCellEdit` is used to handle masked input cell type editing.
 *
 * @hidden
 */
export class MaskedTextBoxCellEdit extends EditCellBase {
    write(args) {
        this.column = args.column;
        const isInlineEdit = this.parent.editSettings.mode !== 'Dialog';
        this.obj = new MaskedTextBox(extend({
            fields: { value: args.column.field },
            value: getObject(args.column.field, args.rowData),
            floatLabelType: isInlineEdit ? 'Never' : 'Always',
            mask: '000-000-0000',
            enabled: isEditable(args.column, args.requestType, args.element),
            cssClass: this.parent.cssClass ? this.parent.cssClass : null
        }, args.column.edit.params));
        this.obj.appendTo(args.element);
    }
}
