import { isNullOrUndefined, getEnumValue } from '@syncfusion/ej2-base';
import { RenderType } from '../base/enum';
/**
 * RendererFactory
 *
 * @hidden
 */
export class RendererFactory {
    constructor() {
        this.rendererMap = {};
    }
    addRenderer(name, type) {
        const rName = getEnumValue(RenderType, name);
        if (isNullOrUndefined(this.rendererMap[rName])) {
            this.rendererMap[rName] = type;
        }
    }
    getRenderer(name) {
        const rName = getEnumValue(RenderType, name);
        if (isNullOrUndefined(this.rendererMap[rName])) {
            // eslint-disable-next-line no-throw-literal
            throw `The renderer ${rName} is not found`;
        }
        else {
            return this.rendererMap[rName];
        }
    }
}
