import { isNullOrUndefined, extend } from '@syncfusion/ej2-base';
import { Query, DataManager, Predicate, Deferred, UrlAdaptor } from '@syncfusion/ej2-data';
import { setFormatter, isGroupAdaptive, getColumnByForeignKeyValue, refreshFilteredColsUid } from '../base/util';
import * as events from '../base/constant';
import { ValueFormatter } from '../services/value-formatter';
import { CheckBoxFilterBase } from '../common/checkbox-filter-base';
/**
 * Grid data module is used to generate query and data source.
 *
 * @hidden
 */
export class Data {
    /**
     * Constructor for data module.
     *
     * @param {IGrid} parent - specifies the IGrid
     * @param {ServiceLocator} serviceLocator - specifies the service locator
     * @hidden
     */
    constructor(parent, serviceLocator) {
        this.dataState = { isPending: false, resolver: null, group: [] };
        this.foreignKeyDataState = { isPending: false, resolver: null };
        this.parent = parent;
        this.serviceLocator = serviceLocator;
        this.initDataManager();
        if (this.parent.isDestroyed || this.getModuleName() === 'foreignKey') {
            return;
        }
        this.parent.on(events.rowsAdded, this.addRows, this);
        this.parent.on(events.rowPositionChanged, this.reorderRows, this);
        this.parent.on(events.rowsRemoved, this.removeRows, this);
        this.parent.on(events.dataSourceModified, this.initDataManager, this);
        this.parent.on(events.destroy, this.destroy, this);
        this.parent.on(events.updateData, this.crudActions, this);
        this.parent.on(events.addDeleteAction, this.getData, this);
        this.parent.on(events.autoCol, this.refreshFilteredCols, this);
        this.parent.on(events.columnsPrepared, this.refreshFilteredCols, this);
    }
    reorderRows(e) {
        this.dataManager.dataSource.json.splice(e.toIndex, 0, this.dataManager.dataSource.json.splice(e.fromIndex, 1)[0]);
    }
    getModuleName() {
        return 'data';
    }
    /**
     * The function used to initialize dataManager and external query
     *
     * @returns {void}
     */
    initDataManager() {
        const gObj = this.parent;
        this.dataManager = gObj.dataSource instanceof DataManager ? gObj.dataSource :
            (isNullOrUndefined(gObj.dataSource) ? new DataManager() : new DataManager(gObj.dataSource));
        if (gObj.isAngular && !(gObj.query instanceof Query)) {
            gObj.setProperties({ query: new Query() }, true);
        }
        else {
            this.isQueryInvokedFromData = true;
            gObj.query = gObj.query instanceof Query ? gObj.query : new Query();
        }
    }
    /**
     * The function is used to generate updated Query from Grid model.
     *
     * @param {boolean} skipPage - specifies the boolean to skip the page
     * @returns {Query} returns the Query
     * @hidden
     */
    generateQuery(skipPage) {
        const gObj = this.parent;
        const query = gObj.getQuery().clone();
        if (this.parent.columnQueryMode === 'ExcludeHidden') {
            query.select(this.parent.getColumns().filter((column) => !(column.isPrimaryKey !== true && column.visible === false || column.field === undefined)).map((column) => column.field));
        }
        else if (this.parent.columnQueryMode === 'Schema') {
            const selectQueryFields = [];
            const columns = this.parent.columns;
            for (let i = 0; i < columns.length; i++) {
                selectQueryFields.push(columns[i].field);
            }
            query.select(selectQueryFields);
        }
        this.filterQuery(query);
        this.searchQuery(query);
        this.aggregateQuery(query);
        this.sortQuery(query);
        if (isGroupAdaptive(this.parent)) {
            this.virtualGroupPageQuery(query);
        }
        else {
            this.pageQuery(query, skipPage);
        }
        this.groupQuery(query);
        return query;
    }
    /**
     * @param {Query} query - specifies the query
     * @returns {Query} - returns the query
     * @hidden
     */
    aggregateQuery(query) {
        const rows = this.parent.aggregates;
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            for (let j = 0; j < row.columns.length; j++) {
                const cols = row.columns[j];
                const types = cols.type instanceof Array ? cols.type : [cols.type];
                for (let k = 0; k < types.length; k++) {
                    query.aggregate(types[k].toLowerCase(), cols.field);
                }
            }
        }
        return query;
    }
    virtualGroupPageQuery(query) {
        const fName = 'fn';
        if (query.queries.length) {
            for (let i = 0; i < query.queries.length; i++) {
                if (query.queries[i][fName] === 'onPage') {
                    query.queries.splice(i, 1);
                }
            }
        }
        return query;
    }
    pageQuery(query, skipPage) {
        const gObj = this.parent;
        const fName = 'fn';
        const args = { query: query, skipPage: false };
        gObj.notify(events.setVirtualPageQuery, args);
        if (args.skipPage) {
            return query;
        }
        if ((gObj.allowPaging || gObj.enableVirtualization || gObj.enableInfiniteScrolling) && skipPage !== true) {
            gObj.pageSettings.currentPage = Math.max(1, gObj.pageSettings.currentPage);
            if (gObj.pageSettings.pageCount <= 0) {
                gObj.pageSettings.pageCount = 8;
            }
            if (gObj.pageSettings.pageSize <= 0) {
                gObj.pageSettings.pageSize = 12;
            }
            if (query.queries.length) {
                for (let i = 0; i < query.queries.length; i++) {
                    if (query.queries[i][fName] === 'onPage') {
                        query.queries.splice(i, 1);
                    }
                }
            }
            if (!isNullOrUndefined(gObj.infiniteScrollModule) && gObj.enableInfiniteScrolling) {
                this.parent.notify(events.infinitePageQuery, query);
            }
            else {
                query.page(gObj.pageSettings.currentPage, gObj.allowPaging && gObj.pagerModule &&
                    gObj.pagerModule.pagerObj.isAllPage && !this.dataManager.dataSource.offline ? null :
                    gObj.pageSettings.pageSize);
            }
        }
        return query;
    }
    groupQuery(query) {
        const gObj = this.parent;
        if (gObj.allowGrouping && gObj.groupSettings.columns.length) {
            if (this.parent.groupSettings.enableLazyLoading) {
                query.lazyLoad.push({ key: 'isLazyLoad', value: this.parent.groupSettings.enableLazyLoading });
            }
            const columns = gObj.groupSettings.columns;
            for (let i = 0, len = columns.length; i < len; i++) {
                const column = this.getColumnByField(columns[i]);
                if (!column) {
                    this.parent.log('initial_action', { moduleName: 'group', columnName: columns[i] });
                }
                const isGrpFmt = column.enableGroupByFormat;
                const format = column.format;
                if (isGrpFmt) {
                    query.group(columns[i], this.formatGroupColumn.bind(this), format);
                }
                else {
                    query.group(columns[i], null);
                }
            }
        }
        return query;
    }
    sortQuery(query) {
        const gObj = this.parent;
        if ((gObj.allowSorting || gObj.allowGrouping) && gObj.sortSettings.columns.length) {
            const columns = gObj.sortSettings.columns;
            const sortGrp = [];
            for (let i = columns.length - 1; i > -1; i--) {
                const col = this.getColumnByField(columns[i].field);
                if (col) {
                    col.setSortDirection(columns[i].direction);
                }
                else {
                    this.parent.log('initial_action', { moduleName: 'sort', columnName: columns[i].field });
                    return query;
                }
                let fn = columns[i].direction;
                if (col.sortComparer) {
                    this.parent.log('grid_sort_comparer');
                    fn = !this.isRemote() ? col.sortComparer.bind(col) : columns[i].direction;
                }
                if (gObj.groupSettings.columns.indexOf(columns[i].field) === -1) {
                    if (col.isForeignColumn() || col.sortComparer) {
                        query.sortByForeignKey(col.field, fn, undefined, columns[i].direction.toLowerCase());
                    }
                    else {
                        query.sortBy(col.field, fn);
                    }
                }
                else {
                    sortGrp.push({ direction: fn, field: col.field });
                }
            }
            for (let i = 0, len = sortGrp.length; i < len; i++) {
                if (typeof sortGrp[i].direction === 'string') {
                    query.sortBy(sortGrp[i].field, sortGrp[i].direction);
                }
                else {
                    const col = this.getColumnByField(sortGrp[i].field);
                    query.sortByForeignKey(sortGrp[i].field, sortGrp[i].direction, undefined, col.getSortDirection().toLowerCase());
                }
            }
        }
        return query;
    }
    searchQuery(query, fcolumn, isForeignKey) {
        const sSettings = this.parent.searchSettings;
        let fields = sSettings.fields.length ? sSettings.fields : this.getSearchColumnFieldNames();
        let predicateList = [];
        let needForeignKeySearch = false;
        if (this.parent.searchSettings.key.length) {
            needForeignKeySearch = this.parent.getForeignKeyColumns().some((col) => fields.indexOf(col.field) > -1);
            const adaptor = !isForeignKey ? this.dataManager.adaptor : fcolumn.dataSource.adaptor;
            if (needForeignKeySearch || (adaptor.getModuleName &&
                adaptor.getModuleName() === 'ODataV4Adaptor')) {
                fields = isForeignKey ? [fcolumn.foreignKeyValue] : fields;
                for (let i = 0; i < fields.length; i++) {
                    const column = isForeignKey ? fcolumn : this.getColumnByField(fields[i]);
                    if (column.isForeignColumn() && !isForeignKey) {
                        predicateList = this.fGeneratePredicate(column, predicateList);
                    }
                    else {
                        predicateList.push(new Predicate(fields[i], sSettings.operator, sSettings.key, sSettings.ignoreCase, sSettings.ignoreAccent));
                    }
                }
                const predList = Predicate.or(predicateList);
                predList.key = sSettings.key;
                query.where(predList);
            }
            else {
                query.search(sSettings.key, fields, sSettings.operator, sSettings.ignoreCase, sSettings.ignoreAccent);
            }
        }
        return query;
    }
    filterQuery(query, column, skipFoerign) {
        const gObj = this.parent;
        let predicateList = [];
        const actualFilter = [];
        const foreignColumn = this.parent.getForeignKeyColumns();
        let foreignColEmpty;
        if (gObj.allowFiltering && gObj.filterSettings.columns.length) {
            const columns = column ? column : gObj.filterSettings.columns;
            const colType = {};
            for (const col of gObj.getColumns()) {
                colType[col.field] = col.filter.type ? col.filter.type : gObj.filterSettings.type;
            }
            const foreignCols = [];
            const defaultFltrCols = [];
            for (const col of columns) {
                const gridColumn = gObj.getColumnByField(col.field);
                if (isNullOrUndefined(col.type) && gridColumn && (gridColumn.type === 'date' || gridColumn.type === 'datetime')) {
                    col.type = gObj.getColumnByField(col.field).type;
                }
                if (col.isForeignKey) {
                    foreignCols.push(col);
                }
                else {
                    defaultFltrCols.push(col);
                }
            }
            if (defaultFltrCols.length) {
                for (let i = 0, len = defaultFltrCols.length; i < len; i++) {
                    defaultFltrCols[i].uid = defaultFltrCols[i].uid ||
                        this.parent.grabColumnByFieldFromAllCols(defaultFltrCols[i].field).uid;
                }
                const excelPredicate = CheckBoxFilterBase.getPredicate(defaultFltrCols);
                for (const prop of Object.keys(excelPredicate)) {
                    predicateList.push(excelPredicate[prop]);
                }
            }
            if (foreignCols.length) {
                for (const col of foreignCols) {
                    col.uid = col.uid || this.parent.grabColumnByFieldFromAllCols(col.field).uid;
                    const column = this.parent.grabColumnByUidFromAllCols(col.uid);
                    if (!column) {
                        this.parent.log('initial_action', { moduleName: 'filter', columnName: col.field });
                    }
                    if (column.isForeignColumn() && getColumnByForeignKeyValue(col.field, foreignColumn) && !skipFoerign) {
                        actualFilter.push(col);
                        if (!column.columnData.length) {
                            foreignColEmpty = true;
                        }
                        predicateList = this.fGeneratePredicate(column, predicateList);
                    }
                    else {
                        const excelPredicate = CheckBoxFilterBase.getPredicate(columns);
                        for (const prop of Object.keys(excelPredicate)) {
                            predicateList.push(excelPredicate[prop]);
                        }
                    }
                }
            }
            if (predicateList.length && !foreignColEmpty) {
                query.where(Predicate.and(predicateList));
            }
            else {
                this.parent.notify(events.showEmptyGrid, {});
            }
        }
        return query;
    }
    fGeneratePredicate(col, predicateList) {
        const fPredicate = {};
        if (col) {
            this.parent.notify(events.generateQuery, { predicate: fPredicate, column: col });
            if (fPredicate.predicate.predicates.length) {
                predicateList.push(fPredicate.predicate);
            }
        }
        return predicateList;
    }
    /**
     * The function is used to get dataManager promise by executing given Query.
     *
     * @param {object} args - specifies the object
     * @param {string} args.requestType - Defines the request type
     * @param {string[]} args.foreignKeyData - Defines the foreignKeyData.string
     * @param {Object} args.data - Defines the data.
     * @param {number} args.index - Defines the index .
     * @param {Query} query - Defines the query which will execute along with data processing.
     * @returns {Promise<Object>} - returns the object
     * @hidden
     */
    getData(args = { requestType: '' }, query) {
        const key = this.getKey(args.foreignKeyData &&
            Object.keys(args.foreignKeyData).length ?
            args.foreignKeyData : this.parent.getPrimaryKeyFieldNames());
        this.parent.log('datasource_syntax_mismatch', { dataState: this.parent });
        if (this.parent.dataSource && 'result' in this.parent.dataSource) {
            const def = this.eventPromise(args, query, key);
            return def.promise;
        }
        else {
            let crud;
            switch (args.requestType) {
                case 'delete':
                    query = query ? query : this.generateQuery();
                    // eslint-disable-next-line no-case-declarations
                    const len = Object.keys(args.data).length;
                    if (len === 1) {
                        crud = this.dataManager.remove(key, args.data[0], query.fromTable, query);
                    }
                    else {
                        const changes = {
                            addedRecords: [],
                            deletedRecords: [],
                            changedRecords: []
                        };
                        changes.deletedRecords = args.data;
                        crud = this.dataManager.saveChanges(changes, key, query.fromTable, query.requiresCount());
                    }
                    break;
                case 'save':
                    query = query ? query : this.generateQuery();
                    args.index = isNullOrUndefined(args.index) ? 0 : args.index;
                    crud = this.dataManager.insert(args.data, query.fromTable, query, args.index);
                    break;
            }
            const promise = 'promise';
            args[promise] = crud;
            // eslint-disable-next-line no-prototype-builtins
            if (crud && !Array.isArray(crud) && !crud.hasOwnProperty('deletedRecords')) {
                return crud.then(() => {
                    return this.insert(query, args);
                });
            }
            else {
                return this.insert(query, args);
            }
        }
    }
    insert(query, args) {
        if (args.requestType === 'save') {
            this.parent.notify(events.recordAdded, args);
        }
        return this.executeQuery(query);
    }
    executeQuery(query) {
        if (this.dataManager.ready) {
            const deferred = new Deferred();
            const ready = this.dataManager.ready;
            ready.then(() => {
                this.dataManager.executeQuery(query).then((result) => {
                    deferred.resolve(result);
                });
            }).catch((e) => {
                deferred.reject(e);
            });
            return deferred.promise;
        }
        else {
            return this.dataManager.executeQuery(query);
        }
    }
    formatGroupColumn(value, field) {
        const serviceLocator = this.serviceLocator;
        const column = this.getColumnByField(field);
        const date = value;
        if (!column.type) {
            column.type = date.getDay ? (date.getHours() > 0 || date.getMinutes() > 0 ||
                date.getSeconds() > 0 || date.getMilliseconds() > 0 ? 'datetime' : 'date') : typeof (value);
        }
        if (isNullOrUndefined(column.getFormatter())) {
            setFormatter(serviceLocator, column);
        }
        const formatVal = ValueFormatter.prototype.toView(value, column.getFormatter());
        return formatVal;
    }
    crudActions(args) {
        const query = this.generateQuery();
        let promise = null;
        const pr = 'promise';
        const key = this.getKey(args.foreignKeyData &&
            Object.keys(args.foreignKeyData).length ? args.foreignKeyData :
            this.parent.getPrimaryKeyFieldNames());
        if (this.parent.dataSource && 'result' in this.parent.dataSource) {
            this.eventPromise(args, query, key);
        }
        switch (args.requestType) {
            case 'save':
                promise = this.dataManager.update(key, args.data, query.fromTable, query, args.previousData);
                break;
        }
        args[pr] = promise ? promise : args[pr];
        this.parent.notify(events.crudAction, args);
    }
    /**
     * @param {object} changes - specifies the changes
     * @param {string} key - specifies the key
     * @param {object} original - specifies the original data
     * @param {Query} query - specifies the query
     * @returns {Promise<Object>} returns the object
     * @hidden
     */
    saveChanges(changes, key, original, query = this.generateQuery()) {
        query.requiresCount();
        if ('result' in this.parent.dataSource) {
            const deff = new Deferred();
            const args = {
                requestType: 'batchsave', changes: changes, key: key, query: query,
                endEdit: deff.resolve
            };
            this.setState({ isPending: true, resolver: deff.resolve });
            this.parent.trigger(events.dataSourceChanged, args);
            return deff.promise;
        }
        else {
            const promise = this.dataManager.saveChanges(changes, key, query.fromTable, query, original);
            return promise;
        }
    }
    getKey(keys) {
        if (keys && keys.length) {
            return keys[0];
        }
        return undefined;
    }
    /**
     * @returns {boolean} returns whether its remote data
     * @hidden
     */
    isRemote() {
        return this.dataManager.dataSource.offline !== true && this.dataManager.dataSource.url !== undefined &&
            this.dataManager.dataSource.url !== '';
    }
    addRows(e) {
        for (let i = e.records.length; i > 0; i--) {
            this.dataManager.dataSource.json.splice(e.toIndex, 0, e.records[i - 1]);
        }
    }
    removeRows(e) {
        const json = this.dataManager.dataSource.json;
        this.dataManager.dataSource.json = json.filter((value) => e.records.indexOf(value) === -1);
    }
    getColumnByField(field) {
        let col;
        return (this.parent.columnModel).some((column) => {
            col = column;
            return column.field === field;
        }) && col;
    }
    destroy() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.off(events.rowsAdded, this.addRows);
        this.parent.off(events.rowsRemoved, this.removeRows);
        this.parent.off(events.dataSourceModified, this.initDataManager);
        this.parent.off(events.destroy, this.destroy);
        this.parent.off(events.updateData, this.crudActions);
        this.parent.off(events.addDeleteAction, this.getData);
        this.parent.off(events.autoCol, this.refreshFilteredCols);
        this.parent.off(events.columnsPrepared, this.refreshFilteredCols);
    }
    getState() {
        return this.dataState;
    }
    setState(state) {
        return this.dataState = state;
    }
    getForeignKeyDataState() {
        return this.foreignKeyDataState;
    }
    setForeignKeyDataState(state) {
        this.foreignKeyDataState = state;
    }
    getStateEventArgument(query) {
        const adaptr = new UrlAdaptor();
        const dm = new DataManager({ url: '', adaptor: new UrlAdaptor });
        const state = adaptr.processQuery(dm, query);
        const data = JSON.parse(state.data);
        return extend(data, state.pvtData);
    }
    eventPromise(args, query, key) {
        const dataArgs = args;
        const state = this.getStateEventArgument(query);
        const def = new Deferred();
        const deff = new Deferred();
        if (args.requestType !== undefined && this.dataState.isDataChanged !== false) {
            state.action = args;
            if (args.requestType === 'save' || args.requestType === 'delete') {
                const editArgs = args;
                editArgs.key = key;
                const promise = 'promise';
                editArgs[promise] = deff.promise;
                editArgs.state = state;
                this.setState({ isPending: true, resolver: deff.resolve });
                dataArgs.endEdit = deff.resolve;
                dataArgs.cancelEdit = deff.reject;
                this.parent.trigger(events.dataSourceChanged, editArgs);
                deff.promise.then(() => {
                    this.setState({ isPending: true, resolver: def.resolve, group: state.group, aggregates: state.aggregates });
                    if (editArgs.requestType === 'save') {
                        this.parent.notify(events.recordAdded, editArgs);
                    }
                    this.parent.trigger(events.dataStateChange, state);
                })
                    .catch(() => void 0);
            }
            else {
                this.setState({ isPending: true, resolver: def.resolve, group: state.group, aggregates: state.aggregates });
                this.parent.trigger(events.dataStateChange, state);
            }
        }
        else {
            this.setState({});
            def.resolve(this.parent.dataSource);
        }
        return def;
    }
    /**
     * Gets the columns where searching needs to be performed from the Grid.
     *
     * @returns {string[]} returns the searched column field names
     */
    getSearchColumnFieldNames() {
        const colFieldNames = [];
        const columns = this.parent.getColumns();
        for (const col of columns) {
            if (col.allowSearching && !isNullOrUndefined(col.field)) {
                colFieldNames.push(col.field);
            }
        }
        return colFieldNames;
    }
    refreshFilteredCols() {
        if (this.parent.allowFiltering && this.parent.filterSettings.columns.length) {
            refreshFilteredColsUid(this.parent, this.parent.filterSettings.columns);
        }
    }
}
