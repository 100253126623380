import { extend, remove, isNullOrUndefined, setStyleAttribute, removeClass, addClass } from '@syncfusion/ej2-base';
import { ContentRender } from './content-renderer';
import * as events from '../base/constant';
import { isRowEnteredInGrid, parentsUntil, setDisplayValue, generateExpandPredicates, getPredicates, getGroupKeysAndFields } from '../base/util';
import { RowRenderer } from '../renderer/row-renderer';
import { GroupModelGenerator } from '../services/group-model-generator';
import { GroupSummaryModelGenerator, CaptionSummaryModelGenerator } from '../services/summary-model-generator';
import * as literals from '../base/string-literals';
/**
 * GroupLazyLoadRenderer is used to perform lazy load grouping
 *
 * @hidden
 */
export class GroupLazyLoadRenderer extends ContentRender {
    constructor(parent, locator) {
        super(parent, locator);
        this.childCount = 0;
        this.scrollData = [];
        this.isFirstChildRow = false;
        this.isScrollDown = false;
        this.isScrollUp = false;
        this.groupCache = {};
        this.startIndexes = {};
        this.captionCounts = {};
        this.rowsByUid = {};
        this.objIdxByUid = {};
        this.initialGroupCaptions = {};
        this.requestType = ['paging', 'columnstate', 'reorder', 'cancel', 'save', 'beginEdit', 'add', 'delete',
            'filterbeforeopen', 'filterchoicerequest'];
        /** @hidden */
        this.refRowsObj = {};
        /** @hidden */
        this.cacheMode = false;
        /** @hidden */
        this.cacheBlockSize = 5;
        /** @hidden */
        this.ignoreAccent = this.parent.allowFiltering ? this.parent.filterSettings.ignoreAccent : false;
        /** @hidden */
        this.allowCaseSensitive = false;
        this.locator = locator;
        this.groupGenerator = new GroupModelGenerator(this.parent);
        this.summaryModelGen = new GroupSummaryModelGenerator(this.parent);
        this.captionModelGen = new CaptionSummaryModelGenerator(this.parent);
        this.rowRenderer = new RowRenderer(this.locator, null, this.parent);
        this.eventListener();
    }
    eventListener() {
        this.parent.addEventListener(events.actionBegin, this.actionBegin.bind(this));
        this.parent.addEventListener(events.actionComplete, this.actionComplete.bind(this));
        this.parent.on(events.initialEnd, this.setLazyLoadPageSize, this);
        this.parent.on(events.setGroupCache, this.setCache, this);
        this.parent.on(events.lazyLoadScrollHandler, this.scrollHandler, this);
        this.parent.on(events.columnVisibilityChanged, this.setVisible, this);
        this.parent.on(events.groupCollapse, this.collapseShortcut, this);
    }
    /**
     * @param {HTMLTableRowElement} tr - specifies the table row element
     * @returns {void}
     * @hidden
     */
    captionExpand(tr) {
        const page = this.parent.pageSettings.currentPage;
        const rowsObject = this.groupCache[page];
        const uid = tr.getAttribute('data-uid');
        const oriIndex = this.getRowObjectIndexByUid(uid);
        const isRowExist = rowsObject[oriIndex + 1] ? rowsObject[oriIndex].indent < rowsObject[oriIndex + 1].indent : false;
        const data = rowsObject[oriIndex];
        const key = getGroupKeysAndFields(oriIndex, rowsObject);
        const e = { captionRowElement: tr, groupInfo: data, enableCaching: true, cancel: false };
        this.parent.trigger(events.lazyLoadGroupExpand, e, (args) => {
            if (args.cancel) {
                return;
            }
            args.keys = key.keys;
            args.fields = key.fields;
            args.rowIndex = tr.rowIndex;
            args.makeRequest = !args.enableCaching || !isRowExist;
            if (!args.enableCaching && isRowExist) {
                this.clearCache([uid]);
            }
            args.skip = 0;
            args.take = this.pageSize;
            data.isExpand = this.rowsByUid[page][data.uid].isExpand = true;
            this.captionRowExpand(args);
        });
    }
    /**
     * @param {HTMLTableRowElement} tr - specifies the table row element
     * @returns {void}
     * @hidden
     */
    captionCollapse(tr) {
        const cache = this.groupCache[this.parent.pageSettings.currentPage];
        const rowIdx = tr.rowIndex;
        const uid = tr.getAttribute('data-uid');
        const captionIndex = this.getRowObjectIndexByUid(uid);
        const e = {
            captionRowElement: tr, groupInfo: cache[captionIndex], cancel: false
        };
        this.parent.trigger(events.lazyLoadGroupCollapse, e, (args) => {
            if (args.cancel) {
                return;
            }
            args.isExpand = false;
            this.removeRows(captionIndex, rowIdx);
        });
    }
    /**
     * @returns {void}
     * @hidden */
    setLazyLoadPageSize() {
        const scrollEle = this.parent.getContent().firstElementChild;
        const blockSize = Math.floor(scrollEle.offsetHeight / this.parent.getRowHeight()) - 1;
        this.pageSize = this.pageSize ? this.pageSize : blockSize * 3;
        this.blockSize = Math.ceil(this.pageSize / 2);
    }
    /**
     * @returns {void}
     * @hidden */
    clearLazyGroupCache() {
        this.clearCache();
    }
    clearCache(uids) {
        uids = uids ? uids : this.getInitialCaptionIndexes();
        const cache = this.groupCache[this.parent.pageSettings.currentPage];
        if (uids.length) {
            for (let i = 0; i < uids.length; i++) {
                const capIdx = this.getRowObjectIndexByUid(uids[i]);
                const capRow = cache[capIdx];
                if (!capRow) {
                    continue;
                }
                if (this.captionCounts[this.parent.pageSettings.currentPage][capRow.uid]) {
                    for (let i = capIdx + 1; i < cache.length; i++) {
                        if (cache[i].indent === capRow.indent || cache[i].indent < capRow.indent) {
                            delete this.captionCounts[this.parent.pageSettings.currentPage][capRow.uid];
                            break;
                        }
                        if (cache[i].isCaptionRow) {
                            delete this.captionCounts[this.parent.pageSettings.currentPage][cache[i].uid];
                        }
                    }
                }
                if (capRow.isExpand) {
                    const tr = this.parent.getRowElementByUID(capRow.uid);
                    if (!tr) {
                        return;
                    }
                    this.parent.groupModule.expandCollapseRows(tr.querySelector('.e-recordplusexpand'));
                }
                const child = this.getNextChilds(capIdx);
                if (!child.length) {
                    continue;
                }
                let subChild = [];
                if (child[child.length - 1].isCaptionRow) {
                    subChild = this.getChildRowsByParentIndex(cache.indexOf(child[child.length - 1]), false, false, null, true, true);
                }
                const start = cache.indexOf(child[0]);
                const end = subChild.length ? cache.indexOf(subChild[subChild.length - 1]) : cache.indexOf(child[child.length - 1]);
                cache.splice(start, end - (start - 1));
                this.refreshCaches();
            }
        }
    }
    refreshCaches() {
        const page = this.parent.pageSettings.currentPage;
        const cache = this.groupCache[page];
        this.rowsByUid = {};
        this.objIdxByUid = {};
        for (let i = 0; i < cache.length; i++) {
            this.maintainRows(cache[i], i);
        }
    }
    getInitialCaptionIndexes() {
        const page = this.parent.pageSettings.currentPage;
        const uids = [];
        for (let i = 0; i < this.initialGroupCaptions[page].length; i++) {
            uids.push(this.initialGroupCaptions[page][i].uid);
        }
        return uids;
    }
    /**
     * @param {string} uid - specifies the uid
     * @returns {number} returns the row object uid
     * @hidden
     */
    getRowObjectIndexByUid(uid) {
        return this.objIdxByUid[this.parent.pageSettings.currentPage][uid];
    }
    collapseShortcut(args) {
        if (this.parent.groupSettings.columns.length &&
            args.target && parentsUntil(args.target, literals.content) && args.target.parentElement.tagName === 'TR') {
            if (!args.collapse && parentsUntil(args.target, literals.row)) {
                return;
            }
            const row = args.target.parentElement;
            const uid = row.getAttribute('data-uid');
            if (args.collapse) {
                const rowObj = this.getRowByUid(uid);
                const capRow = this.getRowByUid(rowObj.parentUid);
                if (capRow.isCaptionRow && capRow.isExpand) {
                    const capEle = this.getRowElementByUid(rowObj.parentUid);
                    this.parent.groupModule.expandCollapseRows(capEle.cells[rowObj.indent - 1]);
                }
            }
            else {
                const capRow = this.getRowByUid(uid);
                if (capRow.isCaptionRow && !capRow.isExpand) {
                    const capEle = this.getRowElementByUid(uid);
                    this.parent.groupModule.expandCollapseRows(capEle.cells[capRow.indent]);
                }
            }
        }
    }
    getRowByUid(uid) {
        return this.rowsByUid[this.parent.pageSettings.currentPage][uid];
    }
    actionBegin(args) {
        if (!args.cancel) {
            if (!this.requestType.some((value) => value === args.requestType)) {
                this.groupCache = {};
                this.resetRowMaintenance();
            }
            if (args.requestType === 'reorder' && this.parent.groupSettings.columns.length) {
                const keys = Object.keys(this.groupCache);
                for (let j = 0; j < keys.length; j++) {
                    const cache = this.groupCache[keys[j]];
                    for (let i = 0; i < cache.length; i++) {
                        if (cache[i].isCaptionRow && !this.captionModelGen.isEmpty()) {
                            this.changeCaptionRow(cache[i], null, keys[j]);
                        }
                        if (cache[i].isDataRow) {
                            const from = args.fromIndex + cache[i].indent;
                            const to = args.toIndex + cache[i].indent;
                            this.moveCells(cache[i].cells, from, to);
                        }
                    }
                }
            }
            if (args.requestType === 'delete'
                || (args.action === 'add' && args.requestType === 'save')) {
                this.groupCache = {};
                this.resetRowMaintenance();
            }
        }
    }
    actionComplete(args) {
        if (!args.cancel && args.requestType !== 'columnstate' && args.requestType !== 'beginEdit'
            && args.requestType !== 'delete' && args.requestType !== 'save' && args.requestType !== 'reorder') {
            this.scrollReset();
        }
    }
    resetRowMaintenance() {
        this.startIndexes = {};
        this.captionCounts = {};
        this.rowsByUid = {};
        this.objIdxByUid = {};
        this.initialGroupCaptions = {};
    }
    moveCells(arr, from, to) {
        if (from >= arr.length) {
            let k = from - arr.length;
            while ((k--) + 1) {
                arr.push(undefined);
            }
        }
        arr.splice(from, 0, arr.splice(to, 1)[0]);
    }
    removeRows(idx, trIdx) {
        const page = this.parent.pageSettings.currentPage;
        const rows = this.groupCache[page];
        const trs = [].slice.call(this.parent.getContent().querySelectorAll('tr'));
        let aggUid;
        if (this.parent.aggregates.length) {
            const agg = this.getAggregateByCaptionIndex(idx);
            aggUid = agg.length ? agg[agg.length - 1].uid : undefined;
        }
        const indent = rows[idx].indent;
        this.addClass(this.getNextChilds(idx));
        rows[idx].isExpand = this.rowsByUid[page][rows[idx].uid].isExpand = false;
        let capUid;
        for (let i = idx + 1; i < rows.length; i++) {
            if (rows[i].indent === indent || rows[i].indent < indent) {
                capUid = rows[i].uid;
                break;
            }
            if (rows[i].isCaptionRow && rows[i].isExpand) {
                this.addClass(this.getNextChilds(i));
            }
        }
        for (let i = trIdx + 1; i < trs.length; i++) {
            if (trs[i].getAttribute('data-uid') === capUid) {
                break;
            }
            else if (trs[i].getAttribute('data-uid') === aggUid) {
                remove(trs[i]);
                break;
            }
            else {
                remove(trs[i]);
                this.refRowsObj[page].splice(trIdx + 1, 1);
            }
        }
        this.parent.notify(events.refreshExpandandCollapse, { rows: this.refRowsObj[page] });
    }
    addClass(rows) {
        const last = rows[this.blockSize];
        if (last) {
            last.lazyLoadCssClass = 'e-lazyload-middle-down';
        }
    }
    getNextChilds(index, rowObjects) {
        const group = this.groupCache[this.parent.pageSettings.currentPage];
        const rows = rowObjects ? rowObjects : group;
        const indent = group[index].indent + 1;
        const childRows = [];
        for (let i = rowObjects ? 0 : index + 1; i < rows.length; i++) {
            if (rows[i].indent < indent) {
                break;
            }
            if (rows[i].indent === indent) {
                childRows.push(rows[i]);
            }
        }
        return childRows;
    }
    lazyLoadHandler(args) {
        this.setStartIndexes();
        const tr = this.parent.getContent().querySelectorAll('tr')[args.index];
        const uid = tr.getAttribute('data-uid');
        const captionIndex = this.getRowObjectIndexByUid(uid);
        const captionRow = this.groupCache[this.parent.pageSettings.currentPage][captionIndex];
        let rows = args.isRowExist ? args.isScroll ? this.scrollData
            : this.getChildRowsByParentIndex(captionIndex, true, true, null, true) : [];
        this.scrollData = [];
        if (!args.isRowExist) {
            this.setRowIndexes(captionIndex, captionRow);
            this.refreshCaptionRowCount(this.groupCache[this.parent.pageSettings.currentPage][captionIndex], args.count);
            if (Object.keys(args.data).indexOf('GroupGuid') !== -1) {
                for (let i = 0; i < args.data.length; i++) {
                    const data = this.groupGenerator.generateCaptionRow(args.data[i], args.level, captionRow.parentGid, undefined, 0, captionRow.uid);
                    rows.push(data);
                    if (this.parent.aggregates.length) {
                        rows = rows.concat((this.summaryModelGen.generateRows(args.data[i], { level: args.level + 1, parentUid: data.uid })));
                    }
                }
            }
            else {
                this.groupGenerator.index = this.getStartIndex(captionIndex, args.isScroll);
                rows = this.groupGenerator.generateDataRows(args.data, args.level, captionRow.parentGid, 0, captionRow.uid);
            }
        }
        const trIdx = args.isScroll ? this.rowIndex : args.index;
        const nxtChild = this.getNextChilds(captionIndex, rows);
        const lastRow = !args.up ? this.hasLastChildRow(args.isScroll, args.count, nxtChild.length) : true;
        if (!args.isRowExist && !lastRow) {
            nxtChild[this.blockSize].lazyLoadCssClass = 'e-lazyload-middle-down';
        }
        if (!lastRow) {
            nxtChild[nxtChild.length - 1].lazyLoadCssClass = 'e-not-lazyload-end';
        }
        const aggregates = !args.isScroll && !args.isRowExist ? this.getAggregateByCaptionIndex(captionIndex) : [];
        if (!args.up) {
            if (!args.isRowExist) {
                this.refreshRowObjects(rows, args.isScroll ? this.rowObjectIndex : captionIndex);
            }
        }
        this.render(trIdx, rows, lastRow, aggregates);
        if (this.isFirstChildRow && !args.up) {
            this.parent.getContent().firstElementChild.scrollTop = rows.length * this.parent.getRowHeight();
        }
        this.isFirstChildRow = false;
        this.rowIndex = undefined;
        this.rowObjectIndex = undefined;
        this.childCount = 0;
        for (let i = 0; i < rows.length; i++) {
            this.refRowsObj[this.parent.pageSettings.currentPage].splice(captionIndex + i + 1, 0, rows[i]);
        }
        this.parent.notify(events.refreshExpandandCollapse, { rows: this.refRowsObj[this.parent.pageSettings.currentPage] });
    }
    setRowIndexes(capIdx, row) {
        if (!this.captionCounts[this.parent.pageSettings.currentPage]) {
            this.captionCounts[this.parent.pageSettings.currentPage] = {};
        }
        if (row.isCaptionRow) {
            this.captionCounts[this.parent.pageSettings.currentPage][row.uid] = row.data.count;
        }
    }
    getStartIndex(capIdx, isScroll) {
        const page = this.parent.pageSettings.currentPage;
        const cache = this.groupCache[page];
        if (isScroll) {
            return cache[this.rowObjectIndex].index + 1;
        }
        let count = 0;
        let idx = 0;
        const prevCapRow = this.getRowByUid(cache[capIdx].parentUid);
        if (prevCapRow) {
            idx = this.prevCaptionCount(prevCapRow);
        }
        if (cache[capIdx].indent > 0) {
            for (let i = capIdx - 1; i >= 0; i--) {
                if (cache[i].indent < cache[capIdx].indent) {
                    break;
                }
                if (cache[i].isCaptionRow && cache[i].indent === cache[capIdx].indent) {
                    count = count + cache[i].data.count;
                }
            }
        }
        const index = count + idx + this.startIndexes[page][cache[capIdx].parentGid];
        return index;
    }
    prevCaptionCount(prevCapRow) {
        const page = this.parent.pageSettings.currentPage;
        const cache = this.groupCache[page];
        let idx = 0;
        for (let i = cache.indexOf(prevCapRow) - 1; i >= 0; i--) {
            if (cache[i].indent === 0) {
                break;
            }
            if (cache[i].indent < prevCapRow.indent) {
                break;
            }
            if (cache[i].isCaptionRow && cache[i].indent === prevCapRow.indent) {
                const count = this.captionCounts[page][cache[i].uid];
                idx = idx + (count ? count : cache[i].data.count);
            }
        }
        const capRow = this.getRowByUid(prevCapRow.parentUid);
        if (capRow) {
            idx = idx + this.prevCaptionCount(capRow);
        }
        return idx;
    }
    setStartIndexes() {
        const cache = this.groupCache[this.parent.pageSettings.currentPage];
        if (!this.startIndexes[this.parent.pageSettings.currentPage]) {
            const indexes = [];
            let idx;
            for (let i = 0; i < cache.length; i++) {
                if (cache[i].isCaptionRow) {
                    if (!indexes.length) {
                        indexes.push(0);
                    }
                    else {
                        indexes.push(cache[idx].data.count + indexes[indexes.length - 1]);
                    }
                    idx = i;
                }
            }
            this.startIndexes[this.parent.pageSettings.currentPage] = indexes;
        }
    }
    hasLastChildRow(isScroll, captionCount, rowCount) {
        return isScroll ? captionCount === this.childCount + rowCount : captionCount === rowCount;
    }
    refreshCaptionRowCount(row, count) {
        row.data.count = count;
    }
    render(trIdx, rows, hasLastChildRow, aggregates) {
        const tr = this.parent.getContent().querySelectorAll('tr')[trIdx];
        const scrollEle = this.parent.getContent().firstElementChild;
        const rowHeight = this.parent.getRowHeight();
        if (tr && aggregates.length) {
            for (let i = aggregates.length - 1; i >= 0; i--) {
                tr.insertAdjacentElement('afterend', this.rowRenderer.render(aggregates[i], this.parent.getColumns()));
            }
        }
        if (tr && rows.length) {
            for (let i = rows.length - 1; i >= 0; i--) {
                if (this.confirmRowRendering(rows[i])) {
                    tr.insertAdjacentElement('afterend', this.rowRenderer.render(rows[i], this.parent.getColumns()));
                    if (this.isScrollDown) {
                        scrollEle.scrollTop = scrollEle.scrollTop - rowHeight;
                    }
                    if (this.isScrollUp) {
                        scrollEle.scrollTop = scrollEle.scrollTop + rowHeight;
                    }
                }
            }
        }
        this.isScrollDown = false;
        this.isScrollUp = false;
    }
    /**
     * @param {Row<Column>} row - specifies the row
     * @param {number} index - specifies the index
     * @returns {void}
     * @hidden
     */
    maintainRows(row, index) {
        const page = this.parent.pageSettings.currentPage;
        if (!this.rowsByUid[page]) {
            this.rowsByUid[page] = {};
            this.objIdxByUid[page] = {};
        }
        if (row.uid) {
            this.rowsByUid[page][row.uid] = row;
        }
        this.objIdxByUid[page][row.uid] = index;
    }
    confirmRowRendering(row) {
        let check = true;
        if (isNullOrUndefined(row.indent) && !row.isDataRow && !row.isCaptionRow) {
            const cap = this.getRowByUid(row.parentUid);
            if (cap.isCaptionRow && !cap.isExpand) {
                check = false;
            }
        }
        return check;
    }
    refreshRowObjects(newRows, index) {
        const page = this.parent.pageSettings.currentPage;
        const rowsObject = this.groupCache[page];
        this.rowsByUid[page] = {};
        this.objIdxByUid[page] = {};
        const newRowsObject = [];
        let k = 0;
        for (let i = 0; i < rowsObject.length; i++) {
            if (i === index) {
                this.maintainRows(rowsObject[i], k);
                newRowsObject.push(rowsObject[i]);
                k++;
                for (let j = 0; j < newRows.length; j++) {
                    this.maintainRows(newRows[j], k);
                    newRowsObject.push(newRows[j]);
                    k++;
                }
            }
            else {
                this.maintainRows(rowsObject[i], k);
                newRowsObject.push(rowsObject[i]);
                k++;
            }
        }
        this.groupCache[this.parent.pageSettings.currentPage] = extend([], newRowsObject);
        this.updateCurrentViewData();
    }
    getAggregateByCaptionIndex(index) {
        const cache = this.groupCache[this.parent.pageSettings.currentPage];
        const parent = cache[index];
        const indent = parent.indent;
        const uid = parent.uid;
        const agg = [];
        for (let i = index + 1; i < cache.length; i++) {
            if (cache[i].indent === indent) {
                break;
            }
            if (isNullOrUndefined(cache[i].indent) && cache[i].parentUid === uid) {
                agg.push(cache[i]);
            }
        }
        return agg;
    }
    getChildRowsByParentIndex(index, deep, block, data, includeAgg, includeCollapseAgg) {
        const cache = data ? data : this.groupCache[this.parent.pageSettings.currentPage];
        const parentRow = cache[index];
        let agg = [];
        if (!parentRow.isCaptionRow || (parentRow.isCaptionRow && !parentRow.isExpand && !includeCollapseAgg)) {
            return [];
        }
        if (includeAgg && this.parent.aggregates.length) {
            agg = this.getAggregateByCaptionIndex(index);
        }
        const indent = parentRow.indent;
        const uid = parentRow.uid;
        let rows = [];
        let count = 0;
        for (let i = index + 1; i < cache.length; i++) {
            if (cache[i].parentUid === uid) {
                if (isNullOrUndefined(cache[i].indent)) {
                    continue;
                }
                count++;
                rows.push(cache[i]);
                if (deep && cache[i].isCaptionRow) {
                    rows = rows.concat(this.getChildRowsByParentIndex(i, deep, block, data, includeAgg));
                }
                if (block && count === this.pageSize) {
                    break;
                }
            }
            if (cache[i].indent === indent) {
                break;
            }
        }
        return rows.concat(agg);
    }
    /**
     * @param {boolean} isReorder - specifies the isreorder
     * @returns {Row<Column>[]} returns the row
     * @hidden
     */
    initialGroupRows(isReorder) {
        let rows = [];
        const cache = this.groupCache[this.parent.pageSettings.currentPage];
        if (isReorder) {
            return this.getRenderedRowsObject();
        }
        for (let i = 0; i < cache.length; i++) {
            if (cache[i].indent === 0) {
                rows.push(cache[i]);
                rows = rows.concat(this.getChildRowsByParentIndex(i, true, true, cache, true));
            }
        }
        return rows;
    }
    /**
     * @returns {Row<Column>[]} retruns the row
     * @hidden */
    getRenderedRowsObject() {
        const rows = [];
        const trs = [].slice.call(this.parent.getContent().querySelectorAll('tr'));
        for (let i = 0; i < trs.length; i++) {
            rows.push(this.getRowByUid(trs[i].getAttribute('data-uid')));
        }
        return rows;
    }
    getCacheRowsOnDownScroll(index) {
        let rows = [];
        const rowsObject = this.groupCache[this.parent.pageSettings.currentPage];
        let k = index;
        for (let i = 0; i < this.pageSize; i++) {
            if (!rowsObject[k] || rowsObject[k].indent < rowsObject[index].indent) {
                break;
            }
            if (rowsObject[k].indent === rowsObject[index].indent) {
                rows.push(rowsObject[k]);
                if (rowsObject[k].isCaptionRow && rowsObject[k].isExpand) {
                    rows = rows.concat(this.getChildRowsByParentIndex(k, true, true, null, true));
                }
            }
            if (rowsObject[k].indent > rowsObject[index].indent || isNullOrUndefined(rowsObject[k].indent)) {
                i--;
            }
            k++;
        }
        return rows;
    }
    getCacheRowsOnUpScroll(start, end, index) {
        let rows = [];
        const rowsObject = this.groupCache[this.parent.pageSettings.currentPage];
        let str = false;
        for (let i = 0; i < rowsObject.length; i++) {
            if (str && (!rowsObject[i] || rowsObject[i].indent < rowsObject[index].indent || rowsObject[i].uid === end)) {
                break;
            }
            if (!str && rowsObject[i].uid === start) {
                str = true;
            }
            if (str && rowsObject[i].indent === rowsObject[index].indent) {
                rows.push(rowsObject[i]);
                if (rowsObject[i].isCaptionRow && rowsObject[i].isExpand) {
                    rows = rows.concat(this.getChildRowsByParentIndex(i, true, true, null, true));
                }
            }
        }
        return rows;
    }
    scrollHandler(e) {
        if (this.parent.isDestroyed || this.childCount) {
            return;
        }
        const downTrs = [].slice.call(this.parent.getContent().getElementsByClassName('e-lazyload-middle-down'));
        const upTrs = [].slice.call(this.parent.getContent().getElementsByClassName('e-lazyload-middle-up'));
        const endTrs = [].slice.call(this.parent.getContent().getElementsByClassName('e-not-lazyload-end'));
        let tr;
        let lazyLoadDown = false;
        let lazyLoadUp = false;
        let lazyLoadEnd = false;
        if (e.scrollDown && downTrs.length) {
            const result = this.findRowElements(downTrs);
            tr = result.tr;
            lazyLoadDown = result.entered;
        }
        if (!e.scrollDown && endTrs) {
            for (let i = 0; i < endTrs.length; i++) {
                const top = endTrs[i].getBoundingClientRect().top;
                const scrollHeight = this.parent.getContent().scrollHeight;
                if (top > 0 && top < scrollHeight) {
                    tr = endTrs[i];
                    lazyLoadEnd = true;
                    this.rowIndex = tr.rowIndex;
                    break;
                }
            }
        }
        if (!e.scrollDown && upTrs.length && !lazyLoadEnd) {
            const result = this.findRowElements(upTrs);
            tr = result.tr;
            lazyLoadUp = result.entered;
        }
        if (tr) {
            if (lazyLoadDown && e.scrollDown && lazyLoadDown && tr) {
                this.scrollDownHandler(tr);
            }
            if (!e.scrollDown && lazyLoadEnd && tr) {
                this.scrollUpEndRowHandler(tr);
            }
            if (this.cacheMode && !e.scrollDown && !lazyLoadEnd && lazyLoadUp && tr) {
                this.scrollUpHandler(tr);
            }
        }
    }
    scrollUpEndRowHandler(tr) {
        const page = this.parent.pageSettings.currentPage;
        const rows = this.groupCache[page];
        const uid = tr.getAttribute('data-uid');
        let index = this.rowObjectIndex = this.getRowObjectIndexByUid(uid);
        const idx = index;
        const childRow = rows[index];
        const parentCapRow = this.getRowByUid(childRow.parentUid);
        const capRowObjIdx = this.getRowObjectIndexByUid(parentCapRow.uid);
        const captionRowEle = this.parent.getContent().querySelector('tr[data-uid=' + parentCapRow.uid + ']');
        const capRowEleIndex = captionRowEle.rowIndex;
        const child = this.getChildRowsByParentIndex(capRowObjIdx);
        const childIdx = child.indexOf(childRow);
        const currentPage = Math.ceil(childIdx / this.pageSize);
        if (currentPage === 1) {
            return;
        }
        this.childCount = currentPage * this.pageSize;
        index = this.getCurrentBlockEndIndex(childRow, index);
        if (this.childCount < parentCapRow.data.count) {
            tr.classList.remove('e-not-lazyload-end');
            childRow.lazyLoadCssClass = '';
            const isRowExist = rows[index + 1] ? childRow.indent === rows[index + 1].indent : false;
            this.scrollData = isRowExist ? this.getCacheRowsOnDownScroll(index + 1) : [];
            const key = getGroupKeysAndFields(capRowObjIdx, rows);
            const args = {
                rowIndex: capRowEleIndex, makeRequest: !isRowExist, groupInfo: parentCapRow, fields: key.fields,
                keys: key.keys, skip: this.childCount, take: this.pageSize, isScroll: true
            };
            if (this.cacheMode && this.childCount >= (this.pageSize * this.cacheBlockSize)) {
                const child = this.getChildRowsByParentIndex(capRowObjIdx);
                const currenBlock = Math.ceil((child.indexOf(rows[idx]) / this.pageSize));
                const removeBlock = currenBlock - (this.cacheBlockSize - 1);
                this.removeBlock(uid, isRowExist, removeBlock, child);
                args.cachedRowIndex = (removeBlock * this.pageSize);
            }
            this.captionRowExpand(args);
        }
        else {
            this.childCount = 0;
        }
    }
    scrollDownHandler(tr) {
        const page = this.parent.pageSettings.currentPage;
        const rows = this.groupCache[page];
        const uid = tr.getAttribute('data-uid');
        let index = this.getRowObjectIndexByUid(uid);
        const idx = index;
        const childRow = rows[index];
        const parentCapRow = this.getRowByUid(childRow.parentUid);
        const capRowObjIdx = this.getRowObjectIndexByUid(parentCapRow.uid);
        const captionRowEle = this.getRowElementByUid(parentCapRow.uid);
        const capRowEleIndex = captionRowEle.rowIndex;
        const child = this.getChildRowsByParentIndex(capRowObjIdx);
        const childIdx = child.indexOf(childRow);
        const currentPage = Math.ceil(childIdx / this.pageSize);
        this.childCount = currentPage * this.pageSize;
        index = this.rowObjectIndex = this.getRowObjectIndexByUid(child[this.childCount - 1].uid);
        const lastchild = rows[index];
        const lastRow = this.getRowElementByUid(lastchild.uid);
        this.rowIndex = lastRow.rowIndex;
        index = this.getCurrentBlockEndIndex(lastchild, index);
        if (this.childCount < parentCapRow.data.count) {
            const isRowExist = rows[index + 1] ? childRow.indent === rows[index + 1].indent : false;
            if (isRowExist && !isNullOrUndefined(this.getRowElementByUid(rows[index + 1].uid))) {
                this.childCount = 0;
                return;
            }
            if (currentPage > 1 || !this.cacheMode) {
                tr.classList.remove('e-lazyload-middle-down');
                lastRow.classList.remove('e-not-lazyload-end');
                lastchild.lazyLoadCssClass = '';
            }
            this.scrollData = isRowExist ? this.getCacheRowsOnDownScroll(this.rowObjectIndex + 1) : [];
            const query = getGroupKeysAndFields(capRowObjIdx, rows);
            const args = {
                rowIndex: capRowEleIndex, makeRequest: !isRowExist, groupInfo: parentCapRow, fields: query.fields,
                keys: query.keys, skip: this.childCount, take: this.pageSize, isScroll: true
            };
            if (this.cacheMode && (this.childCount - this.pageSize) >= (this.pageSize * this.cacheBlockSize)) {
                this.isScrollDown = true;
                const child = this.getChildRowsByParentIndex(capRowObjIdx);
                const currenBlock = Math.ceil((child.indexOf(rows[idx]) / this.pageSize)) - 1;
                const removeBlock = (currenBlock - (this.cacheBlockSize - 1)) + 1;
                this.removeBlock(uid, isRowExist, removeBlock, child, lastchild);
                args.cachedRowIndex = (removeBlock * this.pageSize);
            }
            this.captionRowExpand(args);
        }
        else {
            this.childCount = 0;
        }
    }
    getCurrentBlockEndIndex(row, index) {
        const page = this.parent.pageSettings.currentPage;
        const rows = this.groupCache[page];
        if (row.isCaptionRow) {
            if (row.isExpand) {
                const childCount = this.getChildRowsByParentIndex(index, true).length;
                this.rowIndex = this.rowIndex + childCount;
            }
            const agg = this.getAggregateByCaptionIndex(index);
            this.rowObjectIndex = this.rowObjectIndex + agg.length;
            let idx = index;
            for (let i = idx + 1; i < rows.length; i++) {
                if (rows[i].indent === rows[index].indent || rows[i].indent < rows[index].indent) {
                    index = idx;
                    break;
                }
                else {
                    idx++;
                }
            }
        }
        return index;
    }
    removeBlock(uid, isRowExist, removeBlock, child, lastchild) {
        const page = this.parent.pageSettings.currentPage;
        const rows = this.groupCache[page];
        const uid1 = child[(((removeBlock + 1) * this.pageSize) - 1) - this.blockSize].uid;
        const uid2 = child[(removeBlock * this.pageSize) - this.pageSize].uid;
        const uid3 = child[(removeBlock * this.pageSize)].uid;
        const firstIdx = this.getRowObjectIndexByUid(uid1);
        rows[firstIdx].lazyLoadCssClass = 'e-lazyload-middle-up';
        this.getRowElementByUid(uid1).classList.add('e-lazyload-middle-up');
        if (lastchild) {
            this.getRowElementByUid(uid3).classList.add('e-not-lazyload-first');
            this.getRowByUid(uid3).lazyLoadCssClass = 'e-not-lazyload-first';
            this.getRowByUid(uid2).lazyLoadCssClass = '';
        }
        if (isRowExist) {
            this.removeTopRows(lastchild ? lastchild.uid : uid, uid2, uid3);
        }
        else {
            this.uid1 = uid2;
            this.uid2 = uid3;
            this.uid3 = lastchild ? lastchild.uid : uid;
        }
    }
    scrollUpHandler(tr) {
        const page = this.parent.pageSettings.currentPage;
        const rows = this.groupCache[page];
        const uid = tr.getAttribute('data-uid');
        const row = this.getRowByUid(uid);
        const index = this.rowObjectIndex = this.getRowObjectIndexByUid(uid);
        const parentCapRow = this.getRowByUid(row.parentUid);
        const capRowObjIdx = this.rowIndex = this.getRowObjectIndexByUid(parentCapRow.uid);
        const captionRowEle = this.parent.getRowElementByUID(parentCapRow.uid);
        const capRowEleIndex = captionRowEle.rowIndex;
        const child = this.getChildRowsByParentIndex(capRowObjIdx);
        const childIdx = child.indexOf(rows[index]);
        const currenBlock = Math.floor((childIdx / this.pageSize));
        let idx = this.blockSize;
        if ((this.blockSize * 2) > this.pageSize) {
            idx = (this.blockSize * 2) - this.pageSize;
            idx = this.blockSize - idx;
        }
        const start = child[(childIdx - (idx - 1)) - this.pageSize].uid;
        const end = child[childIdx - (idx - 1)].uid;
        this.scrollData = this.getCacheRowsOnUpScroll(start, end, index - (idx - 1));
        this.isFirstChildRow = currenBlock > 1;
        if (this.isFirstChildRow) {
            this.scrollData[0].lazyLoadCssClass = 'e-not-lazyload-first';
        }
        this.getRowByUid(end).lazyLoadCssClass = '';
        this.getRowElementByUid(end).classList.remove('e-not-lazyload-first');
        const removeBlock = currenBlock + this.cacheBlockSize;
        if (child.length !== parentCapRow.data.count && (removeBlock * this.pageSize > child.length)) {
            this.isFirstChildRow = false;
            this.scrollData[0].lazyLoadCssClass = '';
            this.getRowElementByUid(end).classList.add('e-not-lazyload-first');
            return;
        }
        const count = removeBlock * this.pageSize > parentCapRow.data.count
            ? parentCapRow.data.count : removeBlock * this.pageSize;
        const size = removeBlock * this.pageSize > parentCapRow.data.count
            ? (this.pageSize - ((this.pageSize * removeBlock) - parentCapRow.data.count)) : this.pageSize;
        const childRows = this.getChildRowsByParentIndex(rows.indexOf(child[count - 1]), true, false, null, true);
        const uid1 = childRows.length ? childRows[childRows.length - 1].uid : child[(count - 1)].uid;
        const uid2 = child[count - size].uid;
        const uid3 = child[(count - size) - 1].uid;
        const lastIdx = this.objIdxByUid[page][uid2] - idx;
        if (rows[lastIdx].lazyLoadCssClass === 'e-lazyload-middle-down') {
            const trEle = this.getRowElementByUid(rows[lastIdx].uid);
            if (trEle) {
                trEle.classList.add('e-lazyload-middle-down');
            }
        }
        this.getRowByUid(uid1).lazyLoadCssClass = '';
        this.getRowByUid(uid3).lazyLoadCssClass = 'e-not-lazyload-end';
        this.getRowElementByUid(uid3).classList.add('e-not-lazyload-end');
        this.removeBottomRows(uid1, uid2, uid3);
        this.rowIndex = tr.rowIndex - idx;
        if (tr.classList.length > 1) {
            tr.classList.remove('e-lazyload-middle-up');
        }
        else {
            tr.removeAttribute('class');
        }
        if (!isNullOrUndefined(this.getRowElementByUid(start))) {
            this.childCount = 0;
            this.scrollData = [];
            return;
        }
        const key = getGroupKeysAndFields(this.getRowObjectIndexByUid(parentCapRow.uid), rows);
        const args = {
            rowIndex: capRowEleIndex, makeRequest: false, groupInfo: parentCapRow, fields: key.fields,
            keys: key.keys, skip: this.childCount, take: this.pageSize, isScroll: true, scrollUp: true
        };
        this.isScrollUp = true;
        this.captionRowExpand(args);
    }
    findRowElements(rows) {
        let entered = false;
        let tr;
        for (let i = 0; i < rows.length; i++) {
            const rowIdx = rows[i].rowIndex;
            if (isRowEnteredInGrid(rowIdx, this.parent)) {
                entered = true;
                this.rowIndex = rowIdx;
                tr = rows[i];
                break;
            }
        }
        return { entered, tr };
    }
    getRowElementByUid(uid) {
        return this.parent.getContent().querySelector('tr[data-uid=' + uid + ']');
    }
    removeTopRows(uid1, uid2, uid3) {
        const trs = [].slice.call(this.parent.getContent().querySelectorAll('tr'));
        let start = false;
        for (let i = 0; i < trs.length; i++) {
            if (trs[i].getAttribute('data-uid') === uid3) {
                const tr = this.parent.getContent().querySelector('tr[data-uid=' + uid1 + ']');
                if (tr) {
                    this.rowIndex = tr.rowIndex;
                }
                break;
            }
            if (trs[i].getAttribute('data-uid') === uid2) {
                start = true;
            }
            if (start) {
                remove(trs[i]);
            }
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeBottomRows(uid1, uid2, uid3) {
        const trs = [].slice.call(this.parent.getContent().querySelectorAll('tr'));
        let trigger = false;
        for (let i = 0; i < trs.length; i++) {
            if (trs[i].getAttribute('data-uid') === uid2) {
                trigger = true;
            }
            if (trigger) {
                remove(trs[i]);
                if (trs[i].getAttribute('data-uid') === uid1) {
                    break;
                }
            }
        }
    }
    setCache(e) {
        const page = this.parent.pageSettings.currentPage;
        this.groupCache[page] = this.initialGroupCaptions[page] = extend([], e.data);
    }
    captionRowExpand(args) {
        const captionRow = args.groupInfo;
        const level = this.parent.groupSettings.columns.indexOf(captionRow.data.field) + 1;
        const pred = generateExpandPredicates(args.fields, args.keys, this);
        const predicateList = getPredicates(pred);
        const lazyLoad = { level: level, skip: args.skip, take: args.take, where: predicateList };
        if (args.makeRequest) {
            const query = this.parent.renderModule.data.generateQuery(true);
            if (!query.isCountRequired) {
                query.isCountRequired = true;
            }
            query.lazyLoad.push({ key: 'onDemandGroupInfo', value: lazyLoad });
            this.parent.showSpinner();
            this.parent.renderModule.data.getData({}, query).then((e) => {
                this.parent.hideSpinner();
                if (e.result.length === 0) {
                    return;
                }
                if (this.cacheMode && this.uid1 && this.uid2) {
                    this.removeTopRows(this.uid3, this.uid1, this.uid2);
                    this.uid1 = this.uid2 = this.uid3 = undefined;
                }
                this.lazyLoadHandler({
                    data: e.result, count: e.count, level: level, index: args.rowIndex,
                    isRowExist: false, isScroll: args.isScroll, up: false, rowIndex: args.cachedRowIndex
                });
            })
                .catch((e) => this.parent.renderModule.dataManagerFailure(e, { requestType: 'grouping' }));
        }
        else {
            this.lazyLoadHandler({
                data: null, count: args.groupInfo.data.count, level: level, index: args.rowIndex,
                isRowExist: true, isScroll: args.isScroll, up: args.scrollUp, rowIndex: args.cachedRowIndex
            });
        }
    }
    scrollReset(top) {
        this.parent.getContent().firstElementChild.scrollTop = top ? this.parent.getContent().firstElementChild.scrollTop + top : 0;
    }
    updateCurrentViewData() {
        const records = [];
        this.getRows().filter((row) => {
            if (row.isDataRow) {
                records[row.index] = row.data;
            }
        });
        this.parent.currentViewData = records.length ? records : this.parent.currentViewData;
    }
    /**
     * @returns {Row<Column>[]} returns the row
     * @hidden */
    getGroupCache() {
        return this.groupCache;
    }
    /**
     * @returns {Row<Column>[]} returns the row
     * @hidden */
    getRows() {
        return this.groupCache[this.parent.pageSettings.currentPage] || [];
    }
    /**
     * @returns {Element} returns the element
     * @hidden */
    getRowElements() {
        return [].slice.call(this.parent.getContent().getElementsByClassName(literals.row));
    }
    /**
     * @param {number} index - specifies the index
     * @returns {Element} returns the element
     * @hidden
     */
    getRowByIndex(index) {
        const tr = [].slice.call(this.parent.getContent().getElementsByClassName(literals.row));
        let row;
        for (let i = 0; !isNullOrUndefined(index) && i < tr.length; i++) {
            if (tr[i].getAttribute(literals.dataRowIndex) === index.toString()) {
                row = tr[i];
                break;
            }
        }
        return row;
    }
    /**
     * Tucntion to set the column visibility
     *
     * @param {Column[]} columns - specifies the column
     * @returns {void}
     * @hidden
     */
    setVisible(columns) {
        const gObj = this.parent;
        const rows = this.getRows();
        let testRow;
        rows.some((r) => { if (r.isDataRow) {
            testRow = r;
        } return r.isDataRow; });
        const contentrows = this.getRows().filter((row) => !row.isDetailRow);
        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            const idx = this.parent.getNormalizedColumnIndex(column.uid);
            const colIdx = this.parent.getColumnIndexByUid(column.uid);
            const displayVal = column.visible === true ? '' : 'none';
            if (idx !== -1 && testRow && idx < testRow.cells.length) {
                setStyleAttribute(this.getColGroup().childNodes[idx], { 'display': displayVal });
            }
            this.setDisplayNone(gObj.getDataRows(), colIdx, displayVal, contentrows, idx);
            if (!this.parent.invokedFromMedia && column.hideAtMedia) {
                this.parent.updateMediaColumns(column);
            }
            this.parent.invokedFromMedia = false;
        }
    }
    /**
     * Function to set display.
     *
     * @param {Object} tr - specifies the row object
     * @param {number} idx - specifies the index
     * @param {string} displayVal - specifies the display value
     * @param {Row<Column>[]} rows - specifies the array of rows
     * @param {number} oriIdx - specifies the index
     * @returns {void}
     * @hidden
     */
    setDisplayNone(tr, idx, displayVal, rows, oriIdx) {
        if (!this.parent.groupSettings.columns.length) {
            setDisplayValue(tr, idx, displayVal, rows);
        }
        else {
            const keys = Object.keys(this.groupCache);
            for (let j = 0; j < keys.length; j++) {
                const uids = this.rowsByUid[keys[j]];
                const idxs = Object.keys(uids);
                for (let i = 0; i < idxs.length; i++) {
                    const tr = this.parent.getContent().querySelector('tr[data-uid=' + idxs[i] + ']');
                    const row = uids[idxs[i]];
                    if (row.isCaptionRow) {
                        if (!this.captionModelGen.isEmpty()) {
                            this.changeCaptionRow(row, tr, keys[j]);
                        }
                        else {
                            row.cells[row.indent + 1].colSpan = displayVal === '' ? row.cells[row.indent + 1].colSpan + 1
                                : row.cells[row.indent + 1].colSpan - 1;
                            if (tr) {
                                tr.cells[row.indent + 1].colSpan = row.cells[row.indent + 1].colSpan;
                            }
                        }
                    }
                    if (row.isDataRow) {
                        this.showAndHideCells(tr, idx, displayVal, false);
                        row.cells[oriIdx].visible = displayVal === '' ? true : false;
                    }
                    if (!row.isCaptionRow && !row.isDataRow && isNullOrUndefined(row.indent)) {
                        row.cells[oriIdx].visible = displayVal === '' ? true : false;
                        row.visible = row.cells.some((cell) => cell.isDataCell && cell.visible);
                        this.showAndHideCells(tr, idx, displayVal, true, row);
                    }
                }
            }
        }
    }
    changeCaptionRow(row, tr, index) {
        const capRow = row;
        const captionData = row.data;
        const data = this.groupGenerator.generateCaptionRow(captionData, capRow.indent, capRow.parentGid, undefined, capRow.tIndex, capRow.parentUid);
        data.uid = row.uid;
        data.isExpand = row.isExpand;
        data.lazyLoadCssClass = row.lazyLoadCssClass;
        this.rowsByUid[index][row.uid] = data;
        this.groupCache[index][this.objIdxByUid[index][row.uid]] = data;
        if (tr) {
            const tbody = this.parent.getContentTable().querySelector(literals.tbody);
            tbody.replaceChild(this.rowRenderer.render(data, this.parent.getColumns()), tr);
        }
    }
    showAndHideCells(tr, idx, displayVal, isSummary, row) {
        if (tr) {
            const cls = isSummary ? 'td.e-summarycell' : 'td.e-rowcell';
            setStyleAttribute(tr.querySelectorAll(cls)[idx], { 'display': displayVal });
            if (tr.querySelectorAll(cls)[idx].classList.contains('e-hide')) {
                removeClass([tr.querySelectorAll(cls)[idx]], ['e-hide']);
            }
            if (isSummary) {
                if (row.visible && tr.classList.contains('e-hide')) {
                    removeClass([tr], ['e-hide']);
                }
                else if (!row.visible) {
                    addClass([tr], ['e-hide']);
                }
            }
        }
    }
}
