import { CellRenderer } from './cell-renderer';
/**
 * HeaderIndentCellRenderer class which responsible for building header indent cell.
 *
 * @hidden
 */
export class HeaderIndentCellRenderer extends CellRenderer {
    constructor() {
        super(...arguments);
        this.element = this.parent.createElement('TH', { className: 'e-grouptopleftcell' });
    }
    /**
     * Function to render the indent cell
     *
     * @param  {Cell} cell - specifies the cell
     * @param  {Object} data - specifies the data
     * @returns {Element} returns the element
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render(cell, data) {
        const node = this.element.cloneNode();
        node.appendChild(this.parent.createElement('div', { className: 'e-headercelldiv e-emptycell', innerHTML: '' }));
        return node;
    }
}
