var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Property, ChildProperty } from '@syncfusion/ej2-base';
/**
 * Configures the paging behavior of the Grid.
 */
export class PageSettings extends ChildProperty {
}
__decorate([
    Property(12)
], PageSettings.prototype, "pageSize", void 0);
__decorate([
    Property(8)
], PageSettings.prototype, "pageCount", void 0);
__decorate([
    Property(1)
], PageSettings.prototype, "currentPage", void 0);
__decorate([
    Property()
], PageSettings.prototype, "totalRecordsCount", void 0);
__decorate([
    Property(false)
], PageSettings.prototype, "enableQueryString", void 0);
__decorate([
    Property(false)
], PageSettings.prototype, "pageSizes", void 0);
__decorate([
    Property(null)
], PageSettings.prototype, "template", void 0);
