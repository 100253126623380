import { Draggable, isNullOrUndefined } from '@syncfusion/ej2-base';
import { removeClass } from '@syncfusion/ej2-base';
import { remove, closest as closestElement, classList, extend } from '@syncfusion/ej2-base';
import { parentsUntil, removeElement, getPosition, addRemoveActiveClasses, isActionPrevent } from '../base/util';
import { setRowsInTbody, resetRowIndex } from '../base/util';
import * as events from '../base/constant';
import { Scroll } from '../actions/scroll';
import * as literals from '../base/string-literals';
// eslint-disable-next-line valid-jsdoc
/**
 *
 * Reorder module is used to handle row reordering.
 *
 * @hidden
 */
export class RowDD {
    /**
     * Constructor for the Grid print module
     *
     * @param {IGrid} parent - specifies the IGrid
     * @hidden
     */
    constructor(parent) {
        this.selectedRows = [];
        this.isOverflowBorder = true;
        this.selectedRowColls = [];
        this.isRefresh = true;
        this.isReplaceDragEle = true;
        this.istargetGrid = false;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.helper = (e) => {
            const gObj = this.parent;
            const target = this.draggable.currentStateTarget;
            const visualElement = this.parent.createElement('div', {
                className: 'e-cloneproperties e-draganddrop e-grid e-dragclone',
                styles: 'height:"auto", z-index:2, width:' + gObj.element.offsetWidth
            });
            const table = this.parent.createElement('table', { styles: 'width:' + gObj.element.offsetWidth, attrs: { role: 'grid' } });
            const tbody = this.parent.createElement(literals.tbody, { attrs: { role: 'rowgroup' } });
            if (document.getElementsByClassName('e-griddragarea').length ||
                (gObj.rowDropSettings.targetID && ((!target.classList.contains('e-selectionbackground')
                    && gObj.selectionSettings.type !== 'Single') || !parentsUntil(target, 'e-rowcell'))) ||
                (!gObj.rowDropSettings.targetID && !parentsUntil(target, 'e-rowdragdrop'))) {
                return false;
            }
            if (gObj.rowDropSettings.targetID &&
                gObj.selectionSettings.mode === 'Row' && gObj.selectionSettings.type === 'Single') {
                gObj.selectRow(parseInt(this.draggable.currentStateTarget.parentElement.getAttribute(literals.dataRowIndex), 10));
            }
            this.startedRow = closestElement(target, 'tr').cloneNode(true);
            const frzCols = this.parent.isFrozenGrid();
            if (frzCols) {
                const rowIndex = parseInt(closestElement(target, 'tr').getAttribute(literals.dataRowIndex), 10);
                this.startedRow.innerHTML = '';
                this.startedRow.innerHTML += gObj.getRowByIndex(rowIndex).innerHTML;
                this.startedRow.innerHTML += gObj.getMovableRowByIndex(rowIndex).innerHTML;
                if (gObj.getFrozenMode() === literals.leftRight) {
                    this.startedRow.innerHTML += gObj.getFrozenRightRowByIndex(rowIndex).innerHTML;
                }
            }
            this.processArgs(target);
            const args = {
                selectedRow: this.rows, dragelement: target,
                cloneElement: visualElement, cancel: false, data: this.rowData
            };
            const selectedRows = gObj.getSelectedRows();
            gObj.trigger(events.rowDragStartHelper, args);
            const cancel = 'cancel';
            if (args[cancel]) {
                return false;
            }
            removeElement(this.startedRow, '.e-indentcell');
            removeElement(this.startedRow, '.e-detailrowcollapse');
            removeElement(this.startedRow, '.e-detailrowexpand');
            this.removeCell(this.startedRow, literals.gridChkBox);
            const exp = new RegExp('e-active', 'g'); //high contrast issue
            this.startedRow.innerHTML = this.startedRow.innerHTML.replace(exp, '');
            tbody.appendChild(this.startedRow);
            if (gObj.getSelectedRowIndexes().length > 1 && this.startedRow.hasAttribute('aria-selected')) {
                const index = gObj.getFrozenMode() === literals.leftRight ? 3 : 2;
                const dropCountEle = this.parent.createElement('span', {
                    className: 'e-dropitemscount', innerHTML: frzCols ? '' + selectedRows.length / index : '' + selectedRows.length
                });
                visualElement.appendChild(dropCountEle);
            }
            const ele = closestElement(target, 'tr').querySelector('.e-icon-rowdragicon');
            if (ele) {
                ele.classList.add('e-dragstartrow');
            }
            table.appendChild(tbody);
            visualElement.appendChild(table);
            gObj.element.appendChild(visualElement);
            return visualElement;
        };
        this.dragStart = (e) => {
            const gObj = this.parent;
            document.body.classList.add('e-prevent-select');
            if (document.getElementsByClassName('e-griddragarea').length) {
                return;
            }
            const target = e.target;
            const spanCssEle = this.parent.element.querySelector('.e-dropitemscount');
            if (this.parent.getSelectedRecords().length > 1 && spanCssEle) {
                spanCssEle.style.left = this.parent.element.querySelector('.e-cloneproperties table')
                    .offsetWidth - 5 + 'px';
            }
            this.processArgs(target);
            gObj.trigger(events.rowDragStart, {
                rows: this.rows, target: e.target,
                draggableType: 'rows', fromIndex: parseInt(this.rows[0].getAttribute(literals.dataRowIndex), 10),
                data: (Object.keys(this.rowData[0]).length > 0) ? this.rowData : this.currentViewData()
            });
            this.dragStartData = this.rowData;
            const dropElem = document.getElementById(gObj.rowDropSettings.targetID);
            if (gObj.rowDropSettings.targetID && dropElem && dropElem.ej2_instances &&
                dropElem.ej2_instances[0].getModuleName() === 'grid') {
                dropElem.ej2_instances[0].getContent().classList.add('e-allowRowDrop');
            }
        };
        this.drag = (e) => {
            const gObj = this.parent;
            this.isDropGrid = this.parent;
            this.istargetGrid = false;
            if (this.parent.rowDropSettings.targetID) {
                const dropElement = document.getElementById(gObj.rowDropSettings.targetID);
                this.isDropGrid = dropElement.ej2_instances[0];
                if (parentsUntil(e.target, 'e-grid')) {
                    this.istargetGrid = this.parent.rowDropSettings.targetID === parentsUntil(e.target, 'e-grid').id;
                }
            }
            const cloneElement = this.parent.element.querySelector('.e-cloneproperties');
            const target = this.getElementFromPosition(cloneElement, e.event);
            classList(cloneElement, ['e-defaultcur'], ['e-notallowedcur', 'e-movecur', 'e-grabcur']);
            this.isOverflowBorder = true;
            this.hoverState = gObj.enableHover;
            const trElement = parentsUntil(target, 'e-grid') ? closestElement(e.target, 'tr') : null;
            if (!e.target) {
                return;
            }
            this.processArgs(target);
            const args = {
                rows: this.rows, target: target, draggableType: 'rows',
                data: this.rowData, originalEvent: e, cancel: false
            };
            gObj.trigger(events.rowDrag, args);
            this.stopTimer();
            if (args.cancel) {
                return;
            }
            gObj.element.classList.add('e-rowdrag');
            this.dragTarget = trElement && (parentsUntil(target, 'e-grid').id === cloneElement.parentElement.id ||
                parentsUntil(target, 'e-grid').id) ? this.isDropGrid.element.querySelector('.e-emptyrow') ? 0 :
                parseInt(trElement.getAttribute(literals.ariaRowIndex), 10) : parseInt(this.startedRow.getAttribute(literals.ariaRowIndex), 10);
            if (gObj.rowDropSettings.targetID) {
                if (!parentsUntil(target, 'e-grid') ||
                    parentsUntil(cloneElement.parentElement, 'e-grid').id === parentsUntil(target, 'e-grid').id) {
                    classList(cloneElement, ['e-notallowedcur'], ['e-defaultcur']);
                }
                else {
                    classList(cloneElement, ['e-grabcur'], ['e-notallowedcur']);
                }
            }
            else {
                const elem = parentsUntil(target, 'e-grid');
                if (elem && elem.id === cloneElement.parentElement.id) {
                    classList(cloneElement, ['e-movecur'], ['e-defaultcur']);
                }
                else {
                    classList(cloneElement, ['e-notallowedcur'], ['e-movecur']);
                }
            }
            if (parentsUntil(this.isDropGrid.element, 'e-grid')) {
                if ((!this.isDropGrid.groupSettings.columns.length || e.target.classList.contains('e-selectionbackground')) &&
                    !this.isDropGrid.element.querySelector('.e-emptyrow')) {
                    if (parentsUntil(target, 'e-grid') && parentsUntil(target, 'e-grid').id === this.isDropGrid.element.id) {
                        this.updateScrollPostion(e.event);
                    }
                    if (((this.isOverflowBorder || this.parent.frozenRows > this.dragTarget) &&
                        (parseInt(this.startedRow.getAttribute(literals.dataRowIndex), 10) !== this.dragTarget || this.istargetGrid))
                        || (this.istargetGrid && trElement && this.isDropGrid.getRowByIndex(this.isDropGrid.getCurrentViewRecords().length - 1).
                            getAttribute('data-uid') === trElement.getAttribute('data-uid'))) {
                        this.moveDragRows(e, this.startedRow, trElement);
                    }
                    else {
                        let islastRowIndex;
                        if (this.parent.enableVirtualization) {
                            islastRowIndex = trElement && parseInt(trElement.getAttribute(literals.dataRowIndex), 10) ===
                                this.parent.renderModule.data.dataManager.dataSource.json.length - 1;
                        }
                        else {
                            const lastRowUid = this.parent.getRowByIndex(this.parent.getCurrentViewRecords().length - 1).
                                getAttribute('data-uid');
                            islastRowIndex = trElement && lastRowUid === trElement.getAttribute('data-uid') && lastRowUid !==
                                this.startedRow.getAttribute('data-uid');
                        }
                        if (islastRowIndex && !this.parent.rowDropSettings.targetID) {
                            const bottomborder = this.parent.createElement('div', { className: 'e-lastrow-dragborder' });
                            const gridcontentEle = this.parent.getContent();
                            bottomborder.style.width = this.parent.element.offsetWidth - this.getScrollWidth() + 'px';
                            if (this.parent.enableVirtualization) {
                                bottomborder.style.zIndex = '1';
                            }
                            if (!gridcontentEle.getElementsByClassName('e-lastrow-dragborder').length) {
                                gridcontentEle.classList.add('e-grid-relative');
                                gridcontentEle.appendChild(bottomborder);
                                bottomborder.style.bottom = this.getScrollWidth() + 'px';
                            }
                        }
                        this.removeBorder(trElement);
                    }
                }
                if (target && target.classList.contains(literals.content)
                    && !this.isDropGrid.element.querySelector('.e-emptyrow') && this.istargetGrid) {
                    this.removeBorder(trElement);
                    const rowIndex = this.isDropGrid.getCurrentViewRecords().length - 1;
                    const selector = '.e-rowcell,.e-rowdragdrop,.e-detailrowcollapse';
                    let rowElement = [];
                    rowElement = [].slice.call(this.isDropGrid.getRowByIndex(rowIndex).querySelectorAll(selector));
                    if (this.isDropGrid.isFrozenGrid()) {
                        rowElement = this.borderRowElement(rowIndex, selector);
                    }
                    if (rowElement.length > 0) {
                        addRemoveActiveClasses(rowElement, true, 'e-dragborder');
                    }
                }
            }
        };
        this.dragStop = (e) => {
            document.body.classList.remove('e-prevent-select');
            if (isActionPrevent(this.parent)) {
                this.parent.notify(events.preventBatch, {
                    instance: this, handler: this.processDragStop, arg1: e
                });
            }
            else {
                this.processDragStop(e);
            }
        };
        this.processDragStop = (e) => {
            const gObj = this.parent;
            if (this.parent.isDestroyed) {
                return;
            }
            const targetEle = this.getElementFromPosition(e.helper, e.event);
            const target = targetEle && !targetEle.classList.contains('e-dlg-overlay') ?
                targetEle : e.target;
            gObj.element.classList.remove('e-rowdrag');
            const dropElement = document.getElementById(gObj.rowDropSettings.targetID);
            if (gObj.rowDropSettings.targetID && dropElement && dropElement.ej2_instances &&
                dropElement.ej2_instances[0].getModuleName() === 'grid') {
                dropElement.ej2_instances[0].getContent().classList.remove('e-allowRowDrop');
            }
            if (parentsUntil(this.isDropGrid.element, 'e-grid')) {
                this.stopTimer();
                this.isDropGrid.enableHover = this.hoverState;
                this.isDropGrid.getContent().classList.remove('e-grid-relative');
                this.removeBorder(targetEle);
                const stRow = this.isDropGrid.element.querySelector('.e-dragstartrow');
                if (stRow) {
                    stRow.classList.remove('e-dragstartrow');
                }
            }
            this.processArgs(target);
            const args = {
                target: target, draggableType: 'rows',
                cancel: false,
                fromIndex: parseInt(this.rows[0].getAttribute(literals.dataRowIndex), 10),
                dropIndex: this.dragTarget, rows: this.rows,
                data: (Object.keys(this.dragStartData[0]).length > 0) ? this.dragStartData : this.currentViewData()
            };
            gObj.trigger(events.rowDrop, args, () => {
                if (!(parentsUntil(target, literals.row) || parentsUntil(target, 'e-emptyrow')
                    || parentsUntil(target, literals.gridContent)) || args.cancel) {
                    this.dragTarget = null;
                    remove(e.helper);
                    return;
                }
                this.isRefresh = false;
                const selectedIndexes = this.parent.getSelectedRowIndexes();
                if (gObj.isRowDragable()) {
                    if (!this.parent.rowDropSettings.targetID &&
                        this.startedRow.querySelector('td.e-selectionbackground') && selectedIndexes.length > 1 &&
                        selectedIndexes.length !== this.parent.getCurrentViewRecords().length) {
                        this.reorderRows(selectedIndexes, args.dropIndex);
                    }
                    else {
                        this.reorderRows([parseInt(this.startedRow.getAttribute(literals.dataRowIndex), 10)], this.dragTarget);
                    }
                    this.dragTarget = null;
                    if (!gObj.rowDropSettings.targetID) {
                        if (e.helper.classList.contains('e-cloneproperties') && document.querySelector('.' + e.helper.classList[0])) {
                            remove(e.helper);
                        }
                        if (gObj.enableVirtualization) {
                            gObj.refresh();
                        }
                        else {
                            this.rowOrder(args);
                        }
                    }
                }
                this.isRefresh = true;
            });
        };
        this.removeCell = (targetRow, className) => {
            return [].slice.call(targetRow.querySelectorAll('td')).filter((cell) => {
                if (cell.classList.contains(className)) {
                    targetRow.deleteCell(cell.cellIndex);
                }
            });
        };
        this.parent = parent;
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.on(events.initialEnd, this.initializeDrag, this);
        this.parent.on(events.columnDrop, this.columnDrop, this);
        this.parent.on(events.rowDragAndDropComplete, this.onActionComplete, this);
        this.onDataBoundFn = this.onDataBound.bind(this);
        this.parent.addEventListener(events.dataBound, this.onDataBoundFn);
        this.parent.on(events.uiUpdate, this.enableAfterRender, this);
        this.parent.on(events.destroy, this.destroy, this);
    }
    refreshRow(args, tbody, mtbody, frTbody, target, mTarget, frTarget) {
        const gObj = this.parent;
        const frzCols = gObj.isFrozenGrid();
        const isLeftRight = gObj.getFrozenMode() === literals.leftRight;
        let tbodyMovableHeader;
        let tbodyMovableContent;
        let frHdr;
        let frCnt;
        const tbodyContent = gObj.getContentTable().querySelector(literals.tbody);
        const tbodyHeader = gObj.getHeaderTable().querySelector(literals.tbody);
        if (frzCols) {
            tbodyMovableHeader = gObj.getMovableHeaderTbody();
            tbodyMovableContent = gObj.getMovableContentTbody();
            if (isLeftRight) {
                frHdr = gObj.getFrozenRightHeaderTbody();
                frCnt = gObj.getFrozenRightContentTbody();
            }
        }
        const index = gObj.getFrozenMode() === literals.leftRight ? 3 : 2;
        for (let i = 0, len = args.rows.length; i < len; i++) {
            if (frzCols) {
                if (i % index === 0) {
                    tbody.insertBefore(args.rows[i], target);
                }
                else if (i % index === 1) {
                    mtbody.insertBefore(args.rows[i], mTarget);
                }
                else {
                    frTbody.insertBefore(args.rows[i], frTarget);
                }
            }
            else {
                tbody.insertBefore(args.rows[i], target);
            }
        }
        const tr = [].slice.call(tbody.getElementsByClassName(literals.row));
        let mtr;
        let frTr;
        if (frzCols) {
            mtr = [].slice.call(mtbody.getElementsByClassName(literals.row));
            if (isLeftRight) {
                frTr = [].slice.call(frTbody.getElementsByClassName(literals.row));
            }
        }
        this.refreshData(tr, mtr, frTr);
        if (this.parent.frozenRows) {
            for (let i = 0, len = tr.length; i < len; i++) {
                if (i < this.parent.frozenRows) {
                    setRowsInTbody(tbodyHeader, tbodyMovableHeader, frHdr, tr, mtr, frTr, (tbody, rows) => {
                        tbody.appendChild(rows[i]);
                    });
                }
                else {
                    setRowsInTbody(tbodyContent, tbodyMovableContent, frCnt, tr, mtr, frTr, (tbody, rows) => {
                        tbody.appendChild(rows[i]);
                    });
                }
            }
        }
    }
    updateFrozenRowreOrder(args) {
        const gObj = this.parent;
        let tbodyMovH;
        let tbodyMovC;
        let tbodyFrH;
        let tbodyFrC;
        const frzCols = this.parent.isFrozenGrid();
        const isLeftRight = gObj.getFrozenMode() === literals.leftRight;
        const tbodyC = gObj.getContentTable().querySelector(literals.tbody);
        const tbodyH = gObj.getHeaderTable().querySelector(literals.tbody);
        if (frzCols) {
            tbodyMovH = gObj.getMovableHeaderTbody();
            tbodyMovC = gObj.getMovableContentTbody();
            if (isLeftRight) {
                tbodyFrH = gObj.getFrozenRightHeaderTbody();
                tbodyFrC = gObj.getFrozenRightContentTbody();
            }
        }
        const tr = [].slice.call(tbodyH.getElementsByClassName(literals.row)).concat([].slice.call(tbodyC.getElementsByClassName(literals.row)));
        let mtr;
        let frTr;
        if (frzCols) {
            mtr = [].slice.call(tbodyMovH.getElementsByClassName(literals.row))
                .concat([].slice.call(tbodyMovC.getElementsByClassName(literals.row)));
            if (isLeftRight) {
                frTr = [].slice.call(tbodyFrH.getElementsByClassName(literals.row))
                    .concat([].slice.call(tbodyFrC.getElementsByClassName(literals.row)));
            }
        }
        const tbody = gObj.createElement(literals.tbody, { attrs: { role: 'rowgroup' } });
        const mtbody = gObj.createElement(literals.tbody, { attrs: { role: 'rowgroup' } });
        const frTbody = gObj.createElement(literals.tbody, { attrs: { role: 'rowgroup' } });
        this.parent.clearSelection();
        const targetRows = this.refreshRowTarget(args);
        for (let i = 0, len = tr.length; i < len; i++) {
            tbody.appendChild(tr[i]);
            if (frzCols) {
                mtbody.appendChild(mtr[i]);
                if (isLeftRight) {
                    frTbody.appendChild(frTr[i]);
                }
            }
        }
        this.refreshRow(args, tbody, mtbody, frTbody, targetRows.target, targetRows.mTarget, targetRows.frTarget);
    }
    refreshRowTarget(args) {
        const gObj = this.parent;
        let mTr;
        let frTr;
        let targetIdx = parseInt(args.target.parentElement.getAttribute(literals.dataRowIndex), 10);
        if (args.fromIndex < args.dropIndex || args.fromIndex === args.dropIndex) {
            targetIdx = targetIdx + 1;
        }
        const tr = gObj.getRowByIndex(targetIdx);
        if (gObj.isFrozenGrid()) {
            mTr = gObj.getMovableRowByIndex(targetIdx);
            if (gObj.getFrozenMode() === literals.leftRight) {
                frTr = gObj.getFrozenRightRowByIndex(targetIdx);
            }
        }
        const rows = {
            target: tr, mTarget: mTr, frTarget: frTr
        };
        return rows;
    }
    updateFrozenColumnreOrder(args) {
        const gObj = this.parent;
        let mtbody;
        let frTbody;
        const frzCols = this.parent.isFrozenGrid();
        const tbody = gObj.getContentTable().querySelector(literals.tbody);
        if (frzCols) {
            mtbody = gObj.getMovableContentTbody();
            if (gObj.getFrozenMode() === literals.leftRight) {
                frTbody = gObj.getFrozenRightContentTbody();
            }
        }
        this.parent.clearSelection();
        const targetRows = this.refreshRowTarget(args);
        this.refreshRow(args, tbody, mtbody, frTbody, targetRows.target, targetRows.mTarget, targetRows.frTarget);
    }
    refreshData(tr, mtr, frTr) {
        const rowObj = {};
        const movobj = {};
        const frObj = {};
        const recordobj = {};
        const rowObjects = this.parent.getRowsObject();
        const movbObject = this.parent.getMovableRowsObject();
        const frRightObject = this.parent.getFrozenRightRowsObject();
        const currentViewData = this.parent.getCurrentViewRecords();
        for (let i = 0, len = tr.length; i < len; i++) {
            const index = parseInt(tr[i].getAttribute(literals.dataRowIndex), 10);
            rowObj[i] = rowObjects[index];
            recordobj[i] = currentViewData[index];
            if (this.parent.isFrozenGrid()) {
                movobj[i] = movbObject[index];
                if (frTr) {
                    frObj[i] = frRightObject[index];
                }
            }
        }
        const rows = this.parent.getRows();
        let movbRows;
        let frRightRows;
        if (this.parent.isFrozenGrid()) {
            movbRows = this.parent.getMovableRows();
            if (frTr) {
                frRightRows = this.parent.getFrozenRightRows();
            }
        }
        for (let i = 0, len = tr.length; i < len; i++) {
            rows[i] = tr[i];
            rowObjects[i] = rowObj[i];
            currentViewData[i] = recordobj[i];
            if (this.parent.isFrozenGrid()) {
                movbRows[i] = mtr[i];
                movbObject[i] = movobj[i];
                if (frTr) {
                    frRightRows[i] = frTr[i];
                    frRightObject[i] = frObj[i];
                }
            }
        }
        resetRowIndex(this.parent, rowObjects, tr);
        if (this.parent.isFrozenGrid()) {
            resetRowIndex(this.parent, movbObject, mtr);
            if (frTr) {
                resetRowIndex(this.parent, frRightObject, frTr);
            }
        }
    }
    rowOrder(args) {
        if (args.dropIndex === args.fromIndex || isNaN(args.dropIndex)) {
            return;
        }
        if (this.parent.isDetail()) {
            this.parent.detailCollapseAll();
            const rows = [].slice.call(this.parent.getContentTable().querySelector(literals.tbody).children);
            const rowObjects = this.parent.getRowsObject();
            rows.filter((row) => {
                if (row.classList.contains('e-detailrow')) {
                    row.remove();
                }
            });
            for (let i = 0, len = rowObjects.length; i < len; i++) {
                if (!rowObjects[i]) {
                    break;
                }
                if (rowObjects[i].isDetailRow) {
                    this.parent.getRowsObject().splice(i, 1);
                    i--;
                }
            }
        }
        if (args.target.classList.contains('e-rowcelldrag') || args.target.classList.contains('e-dtdiagonalright')
            || args.target.classList.contains('e-dtdiagonaldown')) {
            args.target = args.target.parentElement;
        }
        if (!args.target.classList.contains('e-rowcell') && parentsUntil(args.target, 'e-rowcell')) {
            args.target = parentsUntil(args.target, 'e-rowcell');
        }
        if (this.parent.frozenRows) {
            this.updateFrozenRowreOrder(args);
        }
        else {
            this.updateFrozenColumnreOrder(args);
        }
        if (this.selectedRowColls.length > 0) {
            this.parent.selectRows(this.selectedRowColls);
            const indexes = [];
            if (this.parent.filterSettings.columns.length || this.parent.sortSettings.columns.length) {
                for (let i = 0, len = args.rows.length; i < len; i++) {
                    indexes.push(parseInt(args.rows[i].getAttribute(literals.dataRowIndex), 10));
                }
                this.selectedRowColls = indexes;
            }
            this.selectedRowColls = [];
        }
    }
    currentViewData() {
        const selectedIndexes = this.parent.getSelectedRowIndexes();
        const currentVdata = [];
        const fromIdx = parseInt(this.startedRow.getAttribute(literals.dataRowIndex), 10);
        for (let i = 0, n = selectedIndexes.length; i < n; i++) {
            const currentV = 'currentViewData';
            currentVdata[i] = this.parent[currentV][selectedIndexes[i]];
        }
        if (!this.parent.rowDropSettings.targetID && selectedIndexes.length === 0) {
            currentVdata[0] = this.parent.currentViewData[fromIdx];
        }
        return currentVdata;
    }
    saveChange(changeRecords, query) {
        this.parent.getDataModule().saveChanges(changeRecords, this.parent.getPrimaryKeyFieldNames()[0], {}, query)
            .then(() => {
            this.parent.notify(events.modelChanged, {
                type: events.actionBegin, requestType: 'rowdraganddrop'
            });
        }).catch((e) => {
            const error = 'error';
            const message = 'message';
            if (!isNullOrUndefined(e[error]) && !isNullOrUndefined(e[error][message])) {
                e[error] = e[error][message];
            }
            this.parent.trigger(events.actionFailure, e);
        });
    }
    reorderRows(fromIndexes, toIndex) {
        const selectedIndexes = this.parent.getSelectedRowIndexes();
        const selectedRecords = [];
        const draggedRecords = [];
        const currentViewData = this.parent.renderModule.data.dataManager.dataSource.json;
        const skip = this.parent.allowPaging ?
            (this.parent.pageSettings.currentPage * this.parent.pageSettings.pageSize) - this.parent.pageSettings.pageSize : 0;
        let dropIdx = toIndex + skip;
        let actualIdx = fromIndexes[0] + skip;
        for (let i = 0, len = fromIndexes.length; i < len; i++) {
            draggedRecords[i] = currentViewData[fromIndexes[i] + skip];
        }
        for (let i = 0, len = selectedIndexes.length; i < len; i++) {
            selectedRecords[i] = currentViewData[selectedIndexes[i] + skip];
        }
        for (let i = 0, len = draggedRecords.length; i < len; i++) {
            if (i !== 0) {
                for (let j = 0, len1 = currentViewData.length; j < len1; j++) {
                    if (JSON.stringify(this.parent.renderModule.data.dataManager.dataSource.json[j]) ===
                        JSON.stringify(draggedRecords[i])) {
                        actualIdx = j;
                        break;
                    }
                }
                for (let j = 0, len1 = currentViewData.length; j < len1; j++) {
                    if (JSON.stringify(this.parent.renderModule.data.dataManager.dataSource.json[j]) === JSON.stringify(draggedRecords[i - 1])) {
                        if (actualIdx > j) {
                            dropIdx = j + 1;
                        }
                        break;
                    }
                }
            }
            this.reorderRow(actualIdx - skip, dropIdx - skip);
        }
        if (this.isRefresh) {
            this.parent.notify(events.modelChanged, {
                type: events.actionBegin, requestType: 'rowdraganddrop'
            });
        }
        for (let i = 0, len = selectedRecords.length; i < len; i++) {
            for (let j = 0, len1 = currentViewData.length; j < len1; j++) {
                if (JSON.stringify(this.parent.renderModule.data.dataManager.dataSource.json[j]) === JSON.stringify(selectedRecords[i])) {
                    selectedIndexes[i] = j - skip;
                    break;
                }
            }
        }
        this.selectedRowColls = selectedIndexes;
    }
    stopTimer() {
        window.clearInterval(this.timer);
    }
    /**
     * To trigger action complete event.
     *
     * @param {NotifyArgs} e - specifies the NotifyArgs
     * @returns {void}
     * @hidden
     */
    onActionComplete(e) {
        this.parent.trigger(events.actionComplete, extend(e, { type: events.actionComplete, requestType: 'rowdraganddrop' }));
    }
    initializeDrag() {
        const gObj = this.parent;
        this.draggable = new Draggable(gObj.element, {
            dragTarget: '.e-rowcelldrag, .e-rowdragdrop, .e-rowcell',
            distance: 5,
            helper: this.helper,
            dragStart: this.dragStart,
            drag: this.drag,
            dragStop: this.dragStop,
            isReplaceDragEle: this.isReplaceDragEle,
            isPreventSelect: false
        });
    }
    updateScrollPostion(e) {
        const y = getPosition(e).y;
        const cliRect = this.isDropGrid.getContent().getBoundingClientRect();
        const rowHeight = this.isDropGrid.getRowHeight() - 15;
        const scrollElem = this.isDropGrid.getContent().firstElementChild;
        const virtualScrollbtm = this.parent.enableVirtualization ? 20 : 0;
        if (cliRect.top >= y) {
            const scrollPixel = -(this.isDropGrid.getRowHeight());
            this.isOverflowBorder = false;
            this.timer = window.setInterval(() => { this.setScrollDown(scrollElem, scrollPixel); }, 200);
        }
        else if (cliRect.top + this.isDropGrid.getContent().clientHeight - rowHeight - 33 - virtualScrollbtm <= y) {
            const scrollPixel = (this.isDropGrid.getRowHeight());
            this.isOverflowBorder = false;
            this.timer = window.setInterval(() => { this.setScrollDown(scrollElem, scrollPixel); }, 200);
        }
    }
    setScrollDown(scrollElem, scrollPixel) {
        scrollElem.scrollTop = scrollElem.scrollTop + scrollPixel;
    }
    moveDragRows(e, startedRow, targetRow) {
        const cloneElement = this.parent.element.querySelector('.e-cloneproperties');
        const element = closestElement(e.target, 'tr');
        if (parentsUntil(element, 'e-grid') &&
            (parentsUntil(cloneElement.parentElement, 'e-grid').id === parentsUntil(element, 'e-grid').id || this.istargetGrid)) {
            const targetElement = element ?
                element : this.startedRow;
            this.setBorder(targetElement, e.event, startedRow, targetRow);
        }
    }
    setBorder(element, event, startedRow, targetRow) {
        let node = this.parent.element;
        if (this.istargetGrid) {
            node = this.isDropGrid.element;
        }
        const cloneElement = this.parent.element.querySelector('.e-cloneproperties');
        this.removeFirstRowBorder(element);
        this.removeLastRowBorder(element);
        if (parentsUntil(element, 'e-grid') && element.classList.contains(literals.row) && ((!this.parent.rowDropSettings.targetID &&
            parentsUntil(cloneElement.parentElement, 'e-grid').id === parentsUntil(element, 'e-grid').id) || this.istargetGrid)) {
            removeClass(node.querySelectorAll('.e-rowcell,.e-rowdragdrop,.e-detailrowcollapse'), ['e-dragborder']);
            let rowElement = [];
            const targetRowIndex = parseInt(targetRow.getAttribute(literals.dataRowIndex), 10);
            if (targetRow && targetRowIndex === 0) {
                const div = this.parent.createElement('div', { className: 'e-firstrow-dragborder' });
                const gridheaderEle = this.isDropGrid.getHeaderContent();
                gridheaderEle.classList.add('e-grid-relative');
                div.style.width = node.offsetWidth - this.getScrollWidth() + 'px';
                if (!gridheaderEle.getElementsByClassName('e-firstrow-dragborder').length) {
                    gridheaderEle.appendChild(div);
                }
            }
            else if (this.parent.rowDropSettings.targetID && targetRow) {
                element = this.isDropGrid.getRowByIndex(targetRowIndex - 1);
                rowElement = [].slice.call(element.querySelectorAll('.e-rowcell,.e-rowdragdrop,.e-detailrowcollapse'));
            }
            else if (targetRow && parseInt(startedRow.getAttribute(literals.dataRowIndex), 10) > targetRowIndex) {
                element = this.parent.getRowByIndex(targetRowIndex - 1);
                rowElement = [].slice.call(element.querySelectorAll('.e-rowcell,.e-rowdragdrop,.e-detailrowcollapse'));
            }
            else {
                rowElement = [].slice.call(element.querySelectorAll('.e-rowcell,.e-rowdragdrop,.e-detailrowcollapse'));
            }
            const frzCols = this.parent.isFrozenGrid();
            if (targetRow && targetRowIndex !== 0 && frzCols) {
                const rowIndex = parseInt(element.getAttribute(literals.dataRowIndex), 10);
                const selector = '.e-rowcell,.e-rowdragdrop,.e-detailrowcollapse';
                rowElement = this.borderRowElement(rowIndex, selector);
            }
            if (rowElement.length > 0) {
                addRemoveActiveClasses(rowElement, true, 'e-dragborder');
            }
        }
    }
    borderRowElement(rowIndex, selector) {
        let lastRow = [];
        lastRow = [].slice.call(this.isDropGrid.getRowByIndex(rowIndex).querySelectorAll(selector)).
            concat([].slice.call(this.isDropGrid.getMovableRowByIndex(rowIndex).querySelectorAll(selector)));
        if (this.isDropGrid.getFrozenMode() === literals.leftRight) {
            lastRow = lastRow.concat([].slice.call(this.isDropGrid.getFrozenRightRowByIndex(rowIndex).querySelectorAll(selector)));
        }
        return lastRow;
    }
    getScrollWidth() {
        const scrollElem = this.parent.getContent().firstElementChild;
        return scrollElem.scrollWidth > scrollElem.offsetWidth ? Scroll.getScrollBarWidth() : 0;
    }
    removeFirstRowBorder(element) {
        if (this.isDropGrid.element.getElementsByClassName('e-firstrow-dragborder').length > 0 && element &&
            element.rowIndex !== 0) {
            remove(this.isDropGrid.element.getElementsByClassName('e-firstrow-dragborder')[0]);
        }
    }
    removeLastRowBorder(element) {
        let islastRowIndex;
        if (this.parent.enableVirtualization) {
            islastRowIndex = element && parseInt(element.getAttribute(literals.dataRowIndex), 10) !==
                this.parent.renderModule.data.dataManager.dataSource.json.length - 1;
        }
        else {
            islastRowIndex = element &&
                this.parent.getRowByIndex(this.parent.getCurrentViewRecords().length - 1).getAttribute('data-uid') !==
                    element.getAttribute('data-uid');
        }
        if (this.parent.element.getElementsByClassName('e-lastrow-dragborder').length > 0 && element && islastRowIndex) {
            remove(this.parent.element.getElementsByClassName('e-lastrow-dragborder')[0]);
        }
    }
    removeBorder(element) {
        this.removeFirstRowBorder(element);
        if (!this.parent.rowDropSettings.targetID) {
            this.removeLastRowBorder(element);
        }
        element = (this.isDropGrid.isFrozenGrid() ? this.isDropGrid.getMovableRows() : this.isDropGrid.getRows()).filter((row) => row.querySelector('td.e-dragborder'))[0];
        if (element) {
            let rowElement = [].slice.call(element.getElementsByClassName('e-dragborder'));
            if (this.parent.isFrozenGrid()) {
                const rowIndex = parseInt(element.getAttribute(literals.dataRowIndex), 10);
                const selector = '.e-dragborder';
                rowElement = this.borderRowElement(rowIndex, selector);
            }
            addRemoveActiveClasses(rowElement, false, 'e-dragborder');
        }
    }
    getElementFromPosition(element, event) {
        const position = getPosition(event);
        element.style.display = 'none';
        const target = document.elementFromPoint(position.x, position.y);
        element.style.display = '';
        return target;
    }
    onDataBound() {
        if (this.selectedRowColls.length > 0 && this.parent.enableVirtualization) {
            this.parent.selectRows(this.selectedRowColls);
            this.selectedRowColls = [];
        }
    }
    getTargetIdx(targetRow) {
        return targetRow ? parseInt(targetRow.getAttribute(literals.dataRowIndex), 10) : 0;
    }
    singleRowDrop(e) {
        const targetRow = closestElement(e.target, 'tr');
        const srcControl = e.droppedElement.parentElement.ej2_instances[0];
        const currentIndex = targetRow ? targetRow.rowIndex : srcControl.currentViewData.length - 1;
        this.reorderRow(this.startedRowIndex, currentIndex);
    }
    columnDrop(e) {
        const gObj = this.parent;
        if (e.droppedElement.getAttribute('action') !== 'grouping' &&
            (parentsUntil(e.target, literals.row) || parentsUntil(e.target, 'e-emptyrow') || parentsUntil(e.target, literals.gridContent))) {
            const targetRow = closestElement(e.target, 'tr');
            let srcControl;
            let currentIndex;
            if ((e.droppedElement.querySelector('tr').getAttribute('single-dragrow') !== 'true' &&
                e.droppedElement.parentElement.id === gObj.element.id)
                || (e.droppedElement.querySelector('tr').getAttribute('single-dragrow') === 'true' &&
                    e.droppedElement.parentElement.id !== gObj.element.id)) {
                return;
            }
            if (e.droppedElement.parentElement.id !== gObj.element.id) {
                srcControl = e.droppedElement.parentElement.ej2_instances[0];
            }
            else if (this.isSingleRowDragDrop || e.droppedElement.querySelector('tr').getAttribute('single-dragrow') === 'true') {
                this.singleRowDrop(e);
                return;
            }
            if (srcControl.element.id !== gObj.element.id && srcControl.rowDropSettings.targetID !== gObj.element.id) {
                return;
            }
            const records = srcControl.getSelectedRecords();
            let targetIndex = currentIndex = this.getTargetIdx(targetRow);
            if (isNaN(targetIndex)) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                targetIndex = currentIndex = 0;
            }
            if (gObj.allowPaging) {
                targetIndex = targetIndex + (gObj.pageSettings.currentPage * gObj.pageSettings.pageSize) - gObj.pageSettings.pageSize;
            }
            //Todo: drag and drop mapper & BatchChanges
            gObj.notify(events.rowsAdded, { toIndex: targetIndex, records: records });
            gObj.notify(events.modelChanged, {
                type: events.actionBegin, requestType: 'rowdraganddrop'
            });
            const selectedRows = srcControl.getSelectedRowIndexes();
            const skip = srcControl.allowPaging ?
                (srcControl.pageSettings.currentPage * srcControl.pageSettings.pageSize) - srcControl.pageSettings.pageSize : 0;
            this.selectedRows = [];
            for (let i = 0, len = records.length; i < len; i++) {
                this.selectedRows.push(skip + selectedRows[i]);
            }
            srcControl.notify(events.rowsRemoved, { indexes: this.selectedRows, records: records });
            srcControl.notify(events.modelChanged, {
                type: events.actionBegin, requestType: 'rowdraganddrop'
            });
        }
    }
    reorderRow(fromIndexes, toIndex) {
        const gObj = this.parent;
        if (!gObj.sortSettings.columns.length && !gObj.groupSettings.columns.length && !gObj.filterSettings.columns.length) {
            //Todo: drag and drop mapper & BatchChanges
            const skip = gObj.allowPaging ?
                (gObj.pageSettings.currentPage * gObj.pageSettings.pageSize) - gObj.pageSettings.pageSize : 0;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const fromIndex = fromIndexes;
            toIndex = toIndex + skip;
            this.selectedRows = gObj.getSelectedRowIndexes();
            gObj.notify(events.rowPositionChanged, {
                fromIndex: fromIndexes + skip,
                toIndex: toIndex
            });
        }
    }
    enableAfterRender(e) {
        if (e.module === this.getModuleName() && e.enable) {
            this.initializeDrag();
        }
    }
    /**
     * To destroy the print
     *
     * @returns {void}
     * @hidden
     */
    destroy() {
        const gridElement = this.parent.element;
        if (this.parent.isDestroyed || !gridElement || (!gridElement.querySelector('.' + literals.gridHeader) &&
            !gridElement.querySelector('.' + literals.gridContent))) {
            return;
        }
        this.draggable.destroy();
        this.parent.off(events.initialEnd, this.initializeDrag);
        this.parent.off(events.columnDrop, this.columnDrop);
        this.parent.off(events.rowDragAndDropComplete, this.onActionComplete);
        this.parent.removeEventListener(events.dataBound, this.onDataBoundFn);
        this.parent.off(events.uiUpdate, this.enableAfterRender);
        this.parent.off(events.destroy, this.destroy);
        //destory ejdrag and drop
    }
    /**
     * For internal use only - Get the module name.
     *
     * @returns {string} returns the module name
     * @private
     */
    getModuleName() {
        return 'rowDragAndDrop';
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    processArgs(target) {
        const gObj = this.parent;
        const dragIdx = parseInt(this.startedRow.getAttribute(literals.dataRowIndex), 10);
        if ((gObj.getSelectedRecords().length > 0 && this.startedRow.cells[0].classList.contains('e-selectionbackground') === false)
            || gObj.getSelectedRecords().length === 0) {
            if (this.parent.enableVirtualization) {
                this.rows = [this.startedRow];
            }
            else {
                this.rows = [this.parent.getRowByIndex(dragIdx)];
                if (gObj.isFrozenGrid()) {
                    this.rows = [gObj.getRowByIndex(dragIdx), gObj.getMovableRowByIndex(dragIdx)];
                    if (gObj.getFrozenMode() === literals.leftRight) {
                        this.rows = [
                            gObj.getRowByIndex(dragIdx), gObj.getMovableRowByIndex(dragIdx), gObj.getFrozenRightRowByIndex(dragIdx)
                        ];
                    }
                }
            }
            this.rowData = [this.parent.getRowInfo((this.startedRow).querySelector('.' + literals.rowCell)).rowData];
        }
        else {
            this.rows = gObj.getSelectedRows();
            this.rowData = gObj.getSelectedRecords();
        }
    }
}
