import { isNullOrUndefined, remove, extend } from '@syncfusion/ej2-base';
import { RowModelGenerator } from '../services/row-model-generator';
import { FreezeRowModelGenerator } from '../services/freeze-row-model-generator';
import { RowRenderer } from '../renderer/row-renderer';
import * as events from '../base/constant';
import { getScrollBarWidth, getEditedDataIndex, resetRowIndex, setRowElements, getRowIndexFromElement, getGroupKeysAndFields, getPredicates, generateExpandPredicates } from '../base/util';
import * as literals from '../base/string-literals';
/**
 * Infinite Scrolling class
 *
 * @hidden
 */
export class InfiniteScroll {
    /**
     * Constructor for the Grid infinite scrolling.
     *
     * @param {IGrid} parent - specifies the IGrid
     * @param {ServiceLocator} serviceLocator - specifies the ServiceLocator
     * @hidden
     */
    constructor(parent, serviceLocator) {
        this.infiniteCache = {};
        this.infiniteCurrentViewData = {};
        this.infiniteFrozenCache = {};
        this.isDownScroll = false;
        this.isUpScroll = false;
        this.isScroll = true;
        this.enableContinuousScroll = false;
        this.initialRender = true;
        this.isRemove = false;
        this.isInitialCollapse = false;
        this.prevScrollTop = 0;
        this.actions = ['filtering', 'searching', 'grouping', 'ungrouping', 'reorder', 'sorting', 'refresh'];
        this.keys = [literals.downArrow, literals.upArrow, literals.enter, literals.shiftEnter];
        this.rowTop = 0;
        this.isInitialMovableRender = true;
        this.virtualInfiniteData = {};
        this.isCancel = false;
        this.emptyRowData = {};
        this.isNormaledit = false;
        this.isInfiniteScroll = false;
        this.isLastPage = false;
        this.isInitialRender = true;
        this.isFocusScroll = false;
        this.isGroupCollapse = false;
        this.parent = parent;
        this.serviceLocator = serviceLocator;
        this.isNormaledit = this.parent.editSettings.mode === 'Normal';
        this.addEventListener();
        this.widthService = serviceLocator.getService('widthService');
        this.rowModelGenerator = this.parent.isFrozenGrid() ? new FreezeRowModelGenerator(this.parent)
            : new RowModelGenerator(this.parent);
    }
    getModuleName() {
        return 'infiniteScroll';
    }
    /**
     * @returns {void}
     * @hidden
     */
    addEventListener() {
        this.parent.on(events.dataReady, this.onDataReady, this);
        this.parent.on(events.dataSourceModified, this.dataSourceModified, this);
        this.parent.on(events.infinitePageQuery, this.infinitePageQuery, this);
        this.parent.on(events.infiniteScrollHandler, this.infiniteScrollHandler, this);
        this.parent.on(events.beforeCellFocused, this.infiniteCellFocus, this);
        this.parent.on(events.appendInfiniteContent, this.appendInfiniteRows, this);
        this.parent.on(events.removeInfiniteRows, this.removeInfiniteCacheRows, this);
        this.parent.on(events.resetInfiniteBlocks, this.resetInfiniteBlocks, this);
        this.parent.on(events.setInfiniteCache, this.setCache, this);
        this.parent.on(events.initialCollapse, this.ensureIntialCollapse, this);
        this.parent.on(events.keyPressed, this.infiniteCellFocus, this);
        this.parent.on(events.infiniteShowHide, this.setDisplayNone, this);
        this.parent.on(events.virtualScrollEditActionBegin, this.editActionBegin, this);
        this.parent.on(events.getVirtualData, this.getVirtualInfiniteData, this);
        this.parent.on(events.editReset, this.resetInfiniteEdit, this);
        this.parent.on(events.virtualScrollEditSuccess, this.infiniteEditSuccess, this);
        this.parent.on(events.refreshVirtualCache, this.refreshInfiniteCache, this);
        this.parent.on(events.refreshInfiniteEditrowindex, this.refreshInfiniteEditrowindex, this);
        this.parent.on(events.infiniteEditHandler, this.infiniteEditHandler, this);
        this.parent.on(events.virtualScrollAddActionBegin, this.infiniteAddActionBegin, this);
        this.parent.on(events.modelChanged, this.modelChanged, this);
        this.parent.on(events.refreshInfiniteCurrentViewData, this.refreshInfiniteCurrentViewData, this);
        this.parent.on(events.destroy, this.destroy, this);
        this.parent.on(events.contentReady, this.selectNewRow, this);
        this.parent.on(events.captionActionComplete, this.captionActionComplete, this);
        this.parent.on(events.setVirtualPageQuery, this.setGroupCollapsePageQuery, this);
        this.actionBeginFunction = this.actionBegin.bind(this);
        this.actionCompleteFunction = this.actionComplete.bind(this);
        this.dataBoundFunction = this.dataBound.bind(this);
        this.parent.on(events.deleteComplete, this.deleteComplate, this);
        this.parent.addEventListener(events.actionBegin, this.actionBeginFunction);
        this.parent.addEventListener(events.actionComplete, this.actionCompleteFunction);
        this.parent.addEventListener(events.dataBound, this.dataBoundFunction);
    }
    /**
     * @returns {void}
     * @hidden
     */
    removeEventListener() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.off(events.dataReady, this.onDataReady);
        this.parent.off(events.dataSourceModified, this.dataSourceModified);
        this.parent.off(events.infinitePageQuery, this.infinitePageQuery);
        this.parent.off(events.infiniteScrollHandler, this.infiniteScrollHandler);
        this.parent.off(events.beforeCellFocused, this.infiniteCellFocus);
        this.parent.off(events.appendInfiniteContent, this.appendInfiniteRows);
        this.parent.off(events.removeInfiniteRows, this.removeInfiniteCacheRows);
        this.parent.off(events.resetInfiniteBlocks, this.resetInfiniteBlocks);
        this.parent.off(events.setInfiniteCache, this.setCache);
        this.parent.off(events.initialCollapse, this.ensureIntialCollapse);
        this.parent.off(events.keyPressed, this.infiniteCellFocus);
        this.parent.off(events.infiniteShowHide, this.setDisplayNone);
        this.parent.off(events.virtualScrollEditActionBegin, this.editActionBegin);
        this.parent.off(events.getVirtualData, this.getVirtualInfiniteData);
        this.parent.off(events.editReset, this.resetInfiniteEdit);
        this.parent.off(events.virtualScrollEditSuccess, this.infiniteEditSuccess);
        this.parent.off(events.refreshVirtualCache, this.refreshInfiniteCache);
        this.parent.on(events.refreshInfiniteEditrowindex, this.refreshInfiniteEditrowindex);
        this.parent.off(events.infiniteEditHandler, this.infiniteEditHandler);
        this.parent.off(events.virtualScrollAddActionBegin, this.infiniteAddActionBegin);
        this.parent.off(events.modelChanged, this.modelChanged);
        this.parent.off(events.refreshInfiniteCurrentViewData, this.refreshInfiniteCurrentViewData);
        this.parent.off(events.destroy, this.destroy);
        this.parent.off(events.contentReady, this.selectNewRow);
        this.parent.off(events.captionActionComplete, this.captionActionComplete);
        this.parent.off(events.setVirtualPageQuery, this.setGroupCollapsePageQuery);
        this.parent.removeEventListener(events.actionBegin, this.actionBeginFunction);
        this.parent.removeEventListener(events.actionComplete, this.actionCompleteFunction);
        this.parent.removeEventListener(events.dataBound, this.dataBoundFunction);
    }
    dataBound() {
        if (this.groupCaptionAction === 'collapse') {
            this.groupCaptionAction = 'refresh';
            this.makeGroupCollapseRequest();
        }
        else if (this.groupCaptionAction === 'refresh') {
            this.parent.hideSpinner();
            this.groupCaptionAction = this.empty;
        }
    }
    setGroupCollapsePageQuery(args) {
        const gObj = this.parent;
        if (!gObj.infiniteScrollSettings.enableCache && this.isGroupCollapse) {
            args.skipPage = true;
            this.isGroupCollapse = false;
            if (this.groupCaptionAction === 'collapse') {
                const captionRow = gObj.getRowObjectFromUID(this.parentCapUid);
                const rowObjs = gObj.getRowsObject();
                let childCount = 0;
                for (let i = rowObjs.length - 1; i >= 0; i--) {
                    if (rowObjs[i].indent === captionRow.indent) {
                        break;
                    }
                    if (rowObjs[i].isDataRow) {
                        childCount++;
                    }
                }
                const key = getGroupKeysAndFields(rowObjs.indexOf(captionRow), rowObjs);
                let pred = generateExpandPredicates(key.fields, key.keys, this);
                const predicateList = getPredicates(pred);
                pred = predicateList[predicateList.length - 1];
                for (let i = predicateList.length - 2; i >= 0; i--) {
                    pred = pred.and(predicateList[i]);
                }
                args.query.where(pred);
                args.query.skip(childCount);
                this.parentCapUid = this.empty;
            }
            else {
                const rows = gObj.getRows();
                const size = gObj.pageSettings.pageSize;
                const skip = getRowIndexFromElement(rows[rows.length - 1]) + 1;
                let additionalCnt = ((skip - (skip % size)) + size) - skip;
                if ((skip % size) === 0) {
                    additionalCnt = 0;
                }
                args.query.skip(skip);
                args.query.take((gObj.infiniteScrollSettings.initialBlocks * gObj.pageSettings.pageSize) + additionalCnt);
            }
        }
    }
    captionActionComplete(args) {
        const gObj = this.parent;
        if (!gObj.infiniteScrollSettings.enableCache && args.isCollapse) {
            const contetRect = gObj.getContent().firstElementChild.getBoundingClientRect();
            const tableReact = gObj.contentModule.getTable().getBoundingClientRect();
            if (Math.round(tableReact.bottom - gObj.getRowHeight()) <= Math.round(contetRect.bottom)) {
                this.parentCapUid = args.parentUid;
                this.groupCaptionAction = 'collapse';
                gObj.showSpinner();
                const caption = gObj.getRowObjectFromUID(args.parentUid);
                const childCount = this.getCaptionChildCount(caption);
                if (!childCount) {
                    this.groupCaptionAction = 'refresh';
                    this.makeGroupCollapseRequest();
                }
                else {
                    this.makeGroupCollapseRequest(args.parentUid);
                }
            }
        }
    }
    makeGroupCollapseRequest(parentUid) {
        const gObj = this.parent;
        const rows = gObj.getRows();
        const index = getRowIndexFromElement(rows[rows.length - 1]);
        const prevPage = this.parent.pageSettings.currentPage;
        this.parent.pageSettings.currentPage = Math.ceil(index / this.parent.pageSettings.pageSize) + 1;
        if (this.parent.pageSettings.currentPage > this.maxPage) {
            gObj.hideSpinner();
            return;
        }
        const scrollArg = {
            requestType: 'infiniteScroll',
            currentPage: this.parent.pageSettings.currentPage,
            prevPage: prevPage,
            startIndex: index + 1,
            direction: 'down',
            isCaptionCollapse: true,
            parentUid: parentUid
        };
        this.isGroupCollapse = true;
        this.parent.notify('model-changed', scrollArg);
    }
    getCaptionChildCount(caption) {
        const rowObj = this.parent.getRowsObject();
        const index = rowObj.indexOf(caption);
        let make = false;
        for (let i = index; i < rowObj.length; i++) {
            if ((rowObj[i].indent === caption.indent || rowObj[i].indent < caption.indent)
                && rowObj[i].data.key !== caption.data.key) {
                break;
            }
            if (rowObj[i].isCaptionRow && !this.childCheck(rowObj, rowObj[i], i)) {
                make = true;
                break;
            }
        }
        return make;
    }
    childCheck(rowObj, row, index) {
        let childCount = 0;
        for (let i = index + 1; i < rowObj.length; i++) {
            if (rowObj[i].indent === row.indent) {
                break;
            }
            if (rowObj[i].indent === (row.indent + 1) && rowObj[i].parentUid === row.uid) {
                childCount++;
            }
        }
        return row.data.count === childCount;
    }
    updateCurrentViewData() {
        const gObj = this.parent;
        if (gObj.groupSettings.columns.length) {
            return;
        }
        const keys = Object.keys(this.infiniteCurrentViewData);
        gObj.currentViewData = [];
        const page = gObj.pageSettings.currentPage;
        const isCache = gObj.infiniteScrollSettings.enableCache;
        const blocks = gObj.infiniteScrollSettings.maxBlocks;
        const isMiddlePage = isCache && (page > blocks || (this.isUpScroll && page > 1));
        const start = isMiddlePage ? this.isUpScroll ? page : (page - blocks) + 1 : 1;
        const end = isMiddlePage ? (start + blocks) - 1 : isCache ? blocks : keys.length;
        for (let i = start; i <= end; i++) {
            if (this.infiniteCurrentViewData[i]) {
                gObj.currentViewData = gObj.currentViewData.concat(this.infiniteCurrentViewData[i]);
            }
        }
    }
    refreshInfiniteCurrentViewData(e) {
        const page = this.parent.pageSettings.currentPage;
        const size = this.parent.pageSettings.pageSize;
        const blocks = this.parent.infiniteScrollSettings.initialBlocks;
        const keys = Object.keys(this.infiniteCurrentViewData);
        const cache = this.parent.infiniteScrollSettings.enableCache;
        if (!this.parent.groupSettings.columns.length) {
            const isAdd = e.args.requestType === 'save' && !(this.parent.sortSettings.columns.length
                || this.parent.filterSettings.columns.length || this.parent.groupSettings.columns.length
                || this.parent.searchSettings.key);
            const isDelete = e.args.requestType === 'delete';
            if (!cache && (isAdd || isDelete)) {
                if (isAdd) {
                    let indexCount = 0;
                    for (let i = 1; i <= keys.length; i++) {
                        indexCount += this.infiniteCurrentViewData[i].length - 1;
                        if (e.args.index <= indexCount) {
                            this.resetCurrentViewData(i);
                            this.infiniteCurrentViewData[i].splice(e.args.index, 0, e.args.data);
                            break;
                        }
                    }
                }
                else {
                    this.infiniteCurrentViewData[keys[keys.length - 1]].push(e.data[0]);
                }
            }
            else {
                if (blocks > 1 && e.data.length === (blocks * size)) {
                    this.setInitialCache(e.data.slice(), {}, cache && e.args.requestType === 'delete', true);
                }
                else {
                    this.infiniteCurrentViewData[page] = e.data.slice();
                }
            }
        }
    }
    resetCurrentViewData(startIndex) {
        const keys = Object.keys(this.infiniteCurrentViewData);
        for (let i = startIndex; i <= keys.length; i++) {
            const lastViewData = this.infiniteCurrentViewData[i][this.infiniteCurrentViewData[i].length - 1];
            if (this.infiniteCurrentViewData[i + 1]) {
                this.infiniteCurrentViewData[i + 1].splice(0, 0, lastViewData);
            }
            this.infiniteCurrentViewData[i].pop();
        }
    }
    deleteComplate() {
        if (this.parent.isFrozenGrid() && !this.parent.infiniteScrollSettings.enableCache) {
            this.parent.contentModule.refreshScrollOffset();
        }
    }
    modelChanged(args) {
        const rows = this.parent.getRows();
        if (args.requestType === 'save' && args.index && args.data) {
            this.addRowIndex = args.index;
        }
        if (rows && rows.length && args.requestType !== 'infiniteScroll' && (args.requestType === 'delete' || this.requestType === 'add')) {
            this.firstIndex = getRowIndexFromElement(rows[0]);
            this.firstBlock = Math.ceil((this.firstIndex + 1) / this.parent.pageSettings.pageSize);
            this.lastIndex = getRowIndexFromElement(rows[rows.length - 1]);
            if (args.requestType === 'delete') {
                const rowObj = this.parent.getRowsObject();
                args.startIndex = this.parent.infiniteScrollSettings.enableCache
                    ? (this.firstBlock - 1) * this.parent.pageSettings.pageSize : rowObj[rowObj.length - 1].index;
            }
            else {
                args.startIndex = this.firstIndex;
            }
            if (!this.parent.infiniteScrollSettings.enableCache
                && this.parent.pageSettings.currentPage === this.maxPage && args.requestType === 'delete') {
                this.isLastPage = true;
                this.lastIndex = this.lastIndex - 1;
            }
        }
    }
    infiniteAddActionBegin(args) {
        if (this.isNormaledit) {
            this.isAdd = true;
            if (this.parent.infiniteScrollSettings.enableCache) {
                if (!Object.keys(this.emptyRowData).length) {
                    this.createEmptyRowdata();
                }
                if (this.parent.pageSettings.currentPage > 1) {
                    args.startEdit = false;
                    this.resetInfiniteBlocks({}, true);
                    this.makeRequest({ currentPage: 1 });
                }
            }
        }
    }
    infiniteEditHandler(args) {
        if (!this.parent.infiniteScrollSettings.enableCache && (args.e.requestType === 'delete'
            || (args.e.requestType === 'save' && this.requestType === 'add'))) {
            const frozenCols = this.parent.isFrozenGrid();
            const rowElms = this.parent.getRows();
            const rows = this.parent.getRowsObject();
            if (this.ensureRowAvailability(rows, args.result[0])) {
                if (rowElms.length && !(this.addRowIndex && this.addRowIndex >= rowElms.length)) {
                    this.resetRowIndex(rows, args.e, rowElms, this.requestType === 'add', true);
                    if (frozenCols) {
                        const rows = this.parent.getMovableRowsObject();
                        this.resetRowIndex(rows, args.e, this.parent.getMovableDataRows(), this.requestType === 'add');
                        if (this.parent.getFrozenMode() === literals.leftRight) {
                            const frRows = this.parent.getFrozenRightRowsObject();
                            this.resetRowIndex(frRows, args.e, this.parent.getFrozenRightRows(), this.requestType === 'add');
                        }
                    }
                }
                if (!this.isLastPage) {
                    this.createRow(rows, args);
                }
                else {
                    this.isLastPage = false;
                    this.parent.pageSettings.currentPage = this.maxPage;
                    if (this.parent.selectionModule.index < this.parent.frozenRows) {
                        remove(rowElms[this.parent.frozenRows - 1]);
                        this.createRow([rows[this.parent.frozenRows - 1]], args, false, true);
                        if (frozenCols) {
                            const movableRows = this.parent.getMovableDataRows();
                            remove(movableRows[this.parent.frozenRows]);
                            this.createRow([this.parent.getMovableRowsObject()[this.parent.frozenRows - 1]], args, true, true);
                            if (this.parent.getFrozenMode() === literals.leftRight) {
                                const rightRows = this.parent.getFrozenRightDataRows();
                                remove(rightRows[this.parent.frozenRows]);
                                this.createRow([this.parent.getFrozenRightRowsObject()[this.parent.frozenRows - 1]], args, false, true, true);
                            }
                        }
                        setRowElements(this.parent);
                    }
                }
            }
            this.parent.hideSpinner();
            this.parent.notify(events.refreshInfinitePersistSelection, {});
            if (this.requestType === 'delete') {
                this.parent.notify(events.deleteComplete, args.e);
            }
            else {
                this.parent.notify(events.saveComplete, args.e);
            }
        }
        this.parent.notify(events.freezeRender, { case: 'refreshHeight' });
    }
    createRow(rows, args, isMovable, isFrozenRows, isFrozenRight) {
        const row = !isFrozenRows ? this.generateRows(args.result, args.e) : rows;
        const rowRenderer = new RowRenderer(this.serviceLocator, null, this.parent);
        if (args.e.requestType === 'save' && args.e.index && args.e.data) {
            row[0].index = this.addRowIndex;
            this.addRowIndex = null;
            if (row[0].index >= rows.length) {
                return;
            }
        }
        let tbody;
        if (isFrozenRight) {
            tbody = this.parent.element.querySelector('.e-frozen-right-content').querySelector(literals.tbody);
        }
        else {
            tbody = !this.parent.isFrozenGrid() ? this.parent.getContent().querySelector(literals.tbody) : isMovable
                ? this.parent.getMovableVirtualContent().querySelector(literals.tbody)
                : this.parent.getFrozenVirtualContent().querySelector(literals.tbody);
        }
        if (this.parent.frozenRows) {
            tbody = isFrozenRows && this.requestType !== 'add' || !isFrozenRows && this.requestType === 'add'
                ? !this.parent.isFrozenGrid() ? this.parent.getHeaderContent().querySelector(literals.tbody)
                    : isMovable ? this.parent.getMovableVirtualHeader().querySelector(literals.tbody)
                        : isFrozenRight ? this.parent.element.querySelector('.e-frozen-right-header').querySelector(literals.tbody)
                            : this.parent.getFrozenVirtualHeader().querySelector(literals.tbody) : tbody;
        }
        const notifyArgs = {
            rows: rows, cancel: false, args: args, isMovable: isMovable,
            isFrozenRows: isFrozenRows, isFrozenRight: isFrozenRows, row: row
        };
        this.parent.notify(events.infiniteCrudCancel, notifyArgs);
        if (!notifyArgs.cancel) {
            for (let i = row.length - 1; i >= 0; i--) {
                if (this.requestType === 'delete') {
                    tbody.appendChild(rowRenderer.render(row[i], this.parent.getColumns()));
                }
                else {
                    tbody.insertBefore(rowRenderer.render(row[i], this.parent.getColumns()), tbody.rows[(args.e.index)]);
                }
            }
        }
        if (!isFrozenRows && this.parent.frozenRows
            && (this.parent.selectionModule.index < this.parent.frozenRows || this.requestType === 'add')) {
            const rowElems = isMovable ? this.parent.getMovableDataRows() : isFrozenRight ? this.parent.getFrozenRightDataRows()
                : this.parent.getRows();
            const index = (isMovable || isFrozenRight) && this.requestType === 'add'
                ? this.parent.frozenRows : this.parent.frozenRows - 1;
            remove(rowElems[index]);
            this.createRow([rows[this.parent.frozenRows - 1]], args, isMovable, true, isFrozenRight);
        }
        if (!this.parent.infiniteScrollSettings.enableCache && !isFrozenRows) {
            if (isFrozenRight) {
                setRowElements(this.parent);
                this.parent.contentModule.rightFreezeRows = this.requestType === 'add'
                    ? row.concat(rows) : rows.concat(row);
            }
            else if (!this.parent.isFrozenGrid() || isMovable) {
                setRowElements(this.parent);
                this.parent.contentModule.visibleRows = this.requestType === 'add'
                    ? row.concat(rows) : rows.concat(row);
                if (this.parent.getFrozenMode() === literals.leftRight) {
                    args.e.renderMovableContent = true;
                    this.createRow(this.parent.getFrozenRightRowsObject(), args, false, false, true);
                }
            }
            else {
                this.parent.contentModule.visibleFrozenRows = this.requestType === 'add'
                    ? row.concat(rows) : rows.concat(row);
                args.e.isFrozen = true;
                this.createRow(this.parent.getMovableRowsObject(), args, true);
            }
        }
    }
    ensureRowAvailability(rows, data) {
        let resume = true;
        if (this.parent.frozenRows && !this.parent.infiniteScrollSettings.enableCache
            && this.parent.sortSettings.columns && this.requestType === 'add') {
            const key = this.parent.getPrimaryKeyFieldNames()[0];
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].data[key] === data[key]) {
                    resume = false;
                    break;
                }
            }
        }
        return resume;
    }
    generateRows(data, args) {
        return this.rowModelGenerator.generateRows(data, args);
    }
    resetRowIndex(rows, args, rowElms, isAdd, isFrozen) {
        const keyField = this.parent.getPrimaryKeyFieldNames()[0];
        const isRemove = !(rowElms.length % this.parent.pageSettings.pageSize);
        if (isAdd) {
            if (isRemove) {
                if (isFrozen && !this.parent.groupSettings.columns.length) {
                    this.swapCurrentViewData(1, true);
                }
                remove(rowElms[rows.length - 1]);
                rowElms.splice(rows.length - 1, 1);
                rows.splice(rows.length - 1, 1);
            }
        }
        else {
            rows.filter((e, index) => {
                if (e.data[keyField] === args.data[0][keyField]) {
                    if (isFrozen && !this.parent.groupSettings.columns.length) {
                        const page = Math.ceil((index + 1) / this.parent.pageSettings.pageSize);
                        this.resetInfiniteCurrentViewData(page, index);
                    }
                    rows.splice(index, 1);
                    remove(rowElms[index]);
                    rowElms.splice(index, 1);
                }
            });
        }
        const startIndex = isAdd ? this.addRowIndex ? this.addRowIndex + 1 : 1 : 0;
        resetRowIndex(this.parent, rows, rowElms, startIndex, this.addRowIndex ? this.addRowIndex : 0);
    }
    resetInfiniteCurrentViewData(page, index) {
        index = index - ((page - 1) * this.parent.pageSettings.pageSize);
        this.infiniteCurrentViewData[page].splice(index, 1);
        this.swapCurrentViewData(page, false);
    }
    swapCurrentViewData(page, isAdd) {
        const keys = Object.keys(this.infiniteCurrentViewData);
        const end = isAdd ? keys.length + 1 : keys.length;
        for (let i = page; i < end; i++) {
            if (this.infiniteCurrentViewData[i + 1]) {
                const pageIndex = isAdd ? i : i + 1;
                const index = isAdd ? this.infiniteCurrentViewData[i].length - 1 : 0;
                const data = this.infiniteCurrentViewData[pageIndex].splice(index, 1);
                if (isAdd) {
                    this.infiniteCurrentViewData[i + 1] = data.concat(this.infiniteCurrentViewData[i + 1]);
                    if ((i + 1) === end - 1) {
                        this.infiniteCurrentViewData[i + 1].splice(this.infiniteCurrentViewData[i + 1].length - 1, 1);
                    }
                }
                else {
                    this.infiniteCurrentViewData[i].push(data[0]);
                }
            }
        }
        this.updateCurrentViewData();
    }
    setDisplayNone(args) {
        if (this.parent.infiniteScrollSettings.enableCache) {
            const frozenCols = this.parent.isFrozenGrid();
            const keys = frozenCols ? Object.keys(this.infiniteFrozenCache) : Object.keys(this.infiniteCache);
            for (let i = 1; i <= keys.length; i++) {
                const cache = frozenCols ? args.isFreeze ? this.infiniteFrozenCache[i][0]
                    : this.infiniteFrozenCache[i][1] : this.infiniteCache[i];
                cache.filter((e) => {
                    e.cells[args.index].visible = args.visible === '';
                });
            }
            this.resetContentModuleCache(frozenCols ? this.infiniteFrozenCache : this.infiniteCache);
        }
    }
    refreshInfiniteCache(args) {
        this.getEditedRowObject().data = args.data;
    }
    refreshInfiniteCacheRowVisibleLength(args, currentPage) {
        const cPageRowArray = args[currentPage];
        if (this.parent.enableInfiniteScrolling && this.parent.infiniteScrollSettings.enableCache) {
            let length = 0;
            let vRowLen = 0;
            let hRowLen = 0;
            for (let i = 0; i < cPageRowArray.length; i++) {
                if (cPageRowArray[i].visible || isNullOrUndefined(cPageRowArray[i].visible)) {
                    vRowLen++;
                }
                else {
                    hRowLen++;
                }
            }
            if (hRowLen > vRowLen) {
                length = hRowLen - vRowLen;
                if (length > vRowLen) {
                    length = vRowLen;
                }
            }
            else {
                length = vRowLen - hRowLen;
                if (length > hRowLen) {
                    length = hRowLen;
                }
            }
            if (length === 0) {
                length = 1;
            }
            return length;
        }
        else {
            return cPageRowArray.length;
        }
    }
    refreshInfiniteEditrowindex(args) {
        this.editRowIndex = args.index;
    }
    getEditedRowObject() {
        const rowObjects = this.parent.getRowsObject();
        let editedrow;
        for (let i = 0; i < rowObjects.length; i++) {
            if (rowObjects[i].index === this.editRowIndex) {
                editedrow = rowObjects[i];
            }
        }
        return editedrow;
    }
    infiniteEditSuccess(args) {
        if (this.isNormaledit) {
            if (!this.isAdd && args.data) {
                this.updateCurrentViewRecords(args.data);
            }
            this.isAdd = false;
        }
    }
    updateCurrentViewRecords(data) {
        const index = getEditedDataIndex(this.parent, data);
        if (!isNullOrUndefined(index)) {
            this.parent.getCurrentViewRecords()[index] = data;
        }
    }
    actionBegin(args) {
        if (args.requestType === 'add' || args.requestType === 'delete') {
            this.requestType = args.requestType;
        }
        else if (args.action === 'add' && args.requestType === 'save') {
            this.requestType = args.action;
        }
        if (this.parent.isFrozenGrid() && !args.cancel && args.requestType === 'searching'
            || args.requestType === 'sorting' || args.requestType === 'filtering') {
            this.isInitialRender = true;
        }
    }
    actionComplete(args) {
        if (args.requestType === 'delete' || args.requestType === 'save' || args.requestType === 'cancel') {
            this.requestType = this.empty;
            this.isCancel = args.requestType === 'cancel' || args.requestType === 'save';
            this.isAdd = this.isEdit = false;
            if (this.isNormaledit) {
                this.editRowIndex = this.empty;
                this.virtualInfiniteData = {};
                this.parent.editModule.previousVirtualData = {};
            }
        }
    }
    resetInfiniteEdit() {
        if (this.parent.enableInfiniteScrolling && this.isNormaledit) {
            if ((this.parent.editSettings.allowEditing && this.isEdit) || (this.parent.editSettings.allowAdding && this.isAdd)) {
                this.parent.isEdit = true;
            }
        }
    }
    getVirtualInfiniteData(data) {
        this.getVirtualInfiniteEditedData();
        data.virtualData = this.virtualInfiniteData;
        data.isAdd = this.isAdd;
        data.isCancel = this.isCancel;
    }
    editActionBegin(e) {
        this.isEdit = true;
        this.editRowIndex = e.index;
        const rowObject = extend({}, this.getEditedRowObject().data);
        e.data = Object.keys(this.virtualInfiniteData).length ? this.virtualInfiniteData : rowObject;
    }
    dataSourceModified() {
        this.resetInfiniteBlocks({ requestType: this.empty }, true);
    }
    onDataReady(e) {
        if (!isNullOrUndefined(e.count) && e.requestType !== 'infiniteScroll') {
            this.maxPage = Math.ceil(e.count / this.parent.pageSettings.pageSize);
        }
    }
    ensureIntialCollapse(isExpand) {
        this.isInitialCollapse = !isExpand;
    }
    infiniteScrollHandler(e) {
        this.restoreInfiniteEdit();
        this.restoreInfiniteAdd();
        const targetEle = e.target;
        const isInfinite = targetEle.classList.contains(literals.content);
        if (isInfinite && this.parent.enableInfiniteScrolling && !e.isLeft) {
            const scrollEle = this.parent.getContent().firstElementChild;
            this.prevScrollTop = scrollEle.scrollTop;
            const rows = this.parent.getRows();
            if (!rows.length) {
                return;
            }
            const index = getRowIndexFromElement(rows[rows.length - 1]) + 1;
            const prevPage = this.parent.pageSettings.currentPage;
            let args;
            const offset = targetEle.scrollHeight - targetEle.scrollTop;
            const round = Math.round(targetEle.scrollHeight - targetEle.scrollTop);
            let floor = offset < targetEle.clientHeight ? Math.ceil(offset) : Math.floor(offset);
            if (floor > targetEle.clientHeight) {
                floor = floor - 1;
            }
            const isBottom = (floor === targetEle.clientHeight || round === targetEle.clientHeight);
            if (!isNullOrUndefined(this.groupCaptionAction)) {
                return;
            }
            if (this.isScroll && isBottom && (this.parent.pageSettings.currentPage <= this.maxPage - 1 || this.enableContinuousScroll)) {
                if (this.parent.infiniteScrollSettings.enableCache) {
                    this.isUpScroll = false;
                    this.isDownScroll = true;
                }
                const rows = [].slice.call(scrollEle.querySelectorAll('.e-row:not(.e-addedrow)'));
                const row = rows[rows.length - 1];
                const rowIndex = getRowIndexFromElement(row);
                this.parent.pageSettings.currentPage = Math.ceil(rowIndex / this.parent.pageSettings.pageSize) + 1;
                args = {
                    requestType: 'infiniteScroll',
                    currentPage: this.parent.pageSettings.currentPage,
                    prevPage: prevPage,
                    startIndex: index,
                    direction: 'down'
                };
                this.makeRequest(args);
            }
            if (this.isScroll && this.parent.infiniteScrollSettings.enableCache && targetEle.scrollTop === 0
                && this.parent.pageSettings.currentPage !== 1) {
                if (this.parent.infiniteScrollSettings.enableCache) {
                    this.isDownScroll = false;
                    this.isUpScroll = true;
                }
                const row = [].slice.call(scrollEle.getElementsByClassName(literals.row));
                const rowIndex = getRowIndexFromElement(row[this.parent.pageSettings.pageSize - 1]);
                const startIndex = getRowIndexFromElement(row[0]) - this.parent.pageSettings.pageSize;
                this.parent.pageSettings.currentPage = Math.ceil(rowIndex / this.parent.pageSettings.pageSize) - 1;
                if (this.parent.pageSettings.currentPage) {
                    args = {
                        requestType: 'infiniteScroll',
                        currentPage: this.parent.pageSettings.currentPage,
                        prevPage: prevPage,
                        startIndex: startIndex,
                        direction: 'up'
                    };
                    this.makeRequest(args);
                }
            }
            if (this.parent.infiniteScrollSettings.enableCache && !this.isScroll && isNullOrUndefined(args)) {
                if (this.isDownScroll || this.isUpScroll) {
                    scrollEle.scrollTop = this.top;
                }
            }
        }
    }
    makeRequest(args) {
        if (this.parent.pageSettings.currentPage !== args.prevPage) {
            const initBlocks = this.parent.infiniteScrollSettings.initialBlocks;
            if (initBlocks < this.maxPage && this.parent.pageSettings.currentPage <= this.maxPage) {
                this.isInfiniteScroll = true;
                if (isNullOrUndefined(this.infiniteCache[args.currentPage])) {
                    setTimeout(() => {
                        this.getVirtualInfiniteEditedData();
                        this.parent.notify('model-changed', args);
                    }, 100);
                }
                else {
                    setTimeout(() => {
                        this.getVirtualInfiniteEditedData();
                        this.parent.notify(events.refreshInfiniteModeBlocks, args);
                    }, 100);
                }
            }
            else {
                this.parent.pageSettings.currentPage = this.maxPage;
            }
        }
    }
    infinitePageQuery(query) {
        if (this.initialRender) {
            this.initialRender = false;
            this.intialPageQuery(query);
        }
        else {
            if (!this.isInfiniteScroll && (this.requestType === 'delete' || this.requestType === 'add')) {
                this.editPageQuery(query);
            }
            else {
                query.page(this.parent.pageSettings.currentPage, this.parent.pageSettings.pageSize);
            }
        }
    }
    editPageQuery(query) {
        const initialBlocks = this.parent.infiniteScrollSettings.initialBlocks;
        const isCache = this.parent.infiniteScrollSettings.enableCache;
        if (isCache) {
            this.infiniteCache = {};
            this.infiniteFrozenCache = {};
            this.infiniteCurrentViewData = {};
            query.skip(this.firstIndex);
            query.take(initialBlocks * this.parent.pageSettings.pageSize);
        }
        else {
            if (this.parent.editSettings.mode === 'Dialog') {
                this.parent.clearSelection();
            }
            const index = this.requestType === 'delete' ? this.lastIndex : this.addRowIndex ? this.addRowIndex : this.firstIndex;
            query.skip(index);
            query.take(1);
        }
    }
    intialPageQuery(query) {
        if (this.parent.infiniteScrollSettings.enableCache
            && this.parent.infiniteScrollSettings.initialBlocks > this.parent.infiniteScrollSettings.maxBlocks) {
            this.parent.infiniteScrollSettings.initialBlocks = this.parent.infiniteScrollSettings.maxBlocks;
        }
        const pageSize = this.parent.pageSettings.pageSize * this.parent.infiniteScrollSettings.initialBlocks;
        query.page(1, pageSize);
    }
    scrollToLastFocusedCell(e) {
        const gObj = this.parent;
        const rowIdx = this.lastFocusInfo.rowIdx + (e.keyArgs.action === literals.upArrow ? -1 : 1);
        const cellIdx = this.lastFocusInfo.cellIdx;
        let row = gObj.getRowByIndex(rowIdx);
        if (!row) {
            const rowRenderer = new RowRenderer(this.serviceLocator, null, this.parent);
            const page = Math.floor(rowIdx / this.parent.pageSettings.pageSize) + 1;
            gObj.pageSettings.currentPage = page;
            const cols = gObj.getColumns();
            remove(gObj.getContent().querySelector('tbody'));
            gObj.getContent().querySelector('table').appendChild(gObj.createElement('tbody', { attrs: { 'role': 'rowgroup' } }));
            let focusRows = [];
            // eslint-disable-next-line @typescript-eslint/tslint/config
            for (let i = (page === 1 || this.maxPage === page) ? 0 : -1, k = 0; k < gObj.infiniteScrollSettings.maxBlocks; this.maxPage === page ? i-- : i++, k++) {
                const rows = this.infiniteCache[page + i];
                if (rows) {
                    focusRows = focusRows.concat(rows);
                    for (let j = 0; j < rows.length; j++) {
                        gObj.getContent().querySelector('tbody').appendChild(rowRenderer.render(rows[j], cols));
                    }
                }
            }
            gObj.notify(events.contentReady, { rows: focusRows, args: {} });
            setRowElements(gObj);
        }
        row = gObj.getRowByIndex(rowIdx);
        const target = row.cells[cellIdx];
        gObj.focusModule.isInfiniteScroll = true;
        gObj.focusModule.onClick({ target }, true);
        gObj.selectRow(rowIdx);
        target.focus();
        this.isFocusScroll = false;
        e.cancel = true;
    }
    setLastCellFocusInfo(e) {
        const cell = ((e.byClick && e.clickArgs.target) || (e.byKey && e.keyArgs.target)
            || (!this.isFocusScroll && e).target);
        if (cell && cell.classList.contains('e-rowcell')) {
            const cellIdx = parseInt(cell.getAttribute('data-colindex'), 10);
            const rowIdx = parseInt(cell.parentElement.getAttribute('data-rowindex'), 10);
            this.lastFocusInfo = { rowIdx: rowIdx, cellIdx: cellIdx };
        }
    }
    infiniteCellFocus(e) {
        const gObj = this.parent;
        const cache = gObj.infiniteScrollSettings.enableCache;
        if (e.byKey) {
            if (cache && this.isFocusScroll) {
                this.scrollToLastFocusedCell(e);
                return;
            }
            const cell = document.activeElement;
            let rowIndex = getRowIndexFromElement(cell.parentElement);
            this.cellIndex = parseInt(cell.getAttribute(literals.dataColIndex), 10);
            const content = gObj.getContent().firstElementChild;
            const totalRowsCount = (this.maxPage * gObj.pageSettings.pageSize) - 1;
            const visibleRowCount = Math.floor(content.offsetHeight / this.parent.getRowHeight());
            const contentRect = content.getBoundingClientRect();
            if (!isNaN(rowIndex)) {
                if (e.keyArgs.action === literals.downArrow || e.keyArgs.action === literals.enter) {
                    this.rowIndex = rowIndex += 1;
                    const row = gObj.getRowByIndex(rowIndex);
                    const rowRect = row && row.getBoundingClientRect();
                    if (cache) {
                        rowIndex = cell.parentElement.rowIndex + 1;
                    }
                    if (this.isFocusScroll || (!row && rowIndex < totalRowsCount)
                        || (rowRect && rowRect.bottom >= contentRect.bottom)) {
                        if (!this.isFocusScroll) {
                            this.pressedKey = e.keyArgs.action;
                        }
                        this.isFocusScroll = false;
                        content.scrollTop = ((rowIndex - visibleRowCount) + 1) * this.parent.getRowHeight();
                    }
                    else if (!cache && row) {
                        if (rowRect && (rowRect.bottom >= contentRect.bottom || rowRect.top < contentRect.top)) {
                            row.cells[this.cellIndex].scrollIntoView();
                        }
                    }
                }
                else if (e.keyArgs.action === literals.upArrow || e.keyArgs.action === literals.shiftEnter) {
                    this.rowIndex = rowIndex -= 1;
                    const row = gObj.getRowByIndex(rowIndex);
                    const rowRect = row && row.getBoundingClientRect();
                    if (cache) {
                        rowIndex = cell.parentElement.rowIndex - 1;
                    }
                    if (!row || rowRect.top <= contentRect.top) {
                        this.pressedKey = e.keyArgs.action;
                        content.scrollTop = rowIndex * this.parent.getRowHeight();
                    }
                }
            }
        }
        else if (e.key === 'PageDown' || e.key === 'PageUp') {
            this.pressedKey = e.key;
        }
        this.setLastCellFocusInfo(e);
    }
    createEmptyRowdata() {
        this.parent.getColumns().filter((e) => {
            this.emptyRowData[e.field] = this.empty;
        });
    }
    getVirtualInfiniteEditedData() {
        const editForm = this.parent.element.querySelector('.' + literals.editedRow);
        const addForm = this.parent.element.querySelector('.' + literals.addedRow);
        const gridForm = this.parent.element.querySelector('.e-gridform');
        if (this.parent.infiniteScrollSettings.enableCache && (editForm || addForm)) {
            const rowData = editForm ? extend({}, this.getEditedRowObject().data)
                : extend({}, this.emptyRowData);
            this.virtualInfiniteData = this.parent.editModule.getCurrentEditedData(gridForm, rowData);
            if (this.parent.isFrozenGrid()) {
                this.virtualInfiniteData = this.parent.editModule
                    .getCurrentEditedData(this.parent.getMovableVirtualContent().querySelector('.e-gridform'), rowData);
            }
        }
    }
    restoreInfiniteEdit() {
        const content = this.parent.getContent().firstElementChild;
        const frozenEdit = this.parent.frozenRows ? this.editRowIndex >= this.parent.frozenRows : true;
        if (this.isNormaledit && this.parent.infiniteScrollSettings.enableCache && frozenEdit) {
            if (this.parent.editSettings.allowEditing && !isNullOrUndefined(this.editRowIndex)) {
                const row = this.parent.getRowByIndex(this.editRowIndex);
                if (Object.keys(this.virtualInfiniteData).length && row && !this.parent.getContent().querySelector('.' + literals.editedRow)) {
                    const top = row.getBoundingClientRect().top;
                    if (top < content.offsetHeight && top > this.parent.getRowHeight()) {
                        this.parent.isEdit = false;
                        this.parent.editModule.startEdit(row);
                    }
                }
            }
        }
    }
    restoreInfiniteAdd() {
        const content = this.parent.getContent().firstElementChild;
        if (this.parent.getCurrentViewRecords().length && this.parent.getRowByIndex(0) && this.isNormaledit &&
            this.parent.infiniteScrollSettings.enableCache && this.isAdd && !content.querySelector('.' + literals.addedRow)) {
            const isTop = content.scrollTop < this.parent.getRowHeight();
            if (isTop) {
                this.parent.isEdit = false;
                this.parent.addRecord();
            }
        }
    }
    appendInfiniteRows(e) {
        const frozenCols = this.parent.isFrozenGrid();
        const scrollEle = this.parent.getContent().firstElementChild;
        const isInfiniteScroll = this.parent.enableInfiniteScrolling && e.args.requestType === 'infiniteScroll';
        const isMovable = this.parent.getFrozenMode() === literals.leftRight && e.tableName === 'movable';
        if ((isInfiniteScroll && !e.args.isFrozen && !isMovable) || !isInfiniteScroll) {
            if (isInfiniteScroll && e.args.direction === 'up') {
                e.tbody.insertBefore(e.frag, e.tbody.firstElementChild);
            }
            else {
                e.tbody.appendChild(e.frag);
            }
        }
        if (!frozenCols) {
            this.parent.contentModule.getTable().appendChild(e.tbody);
            this.updateCurrentViewData();
        }
        else {
            if (isInfiniteScroll) {
                if (e.tableName === literals.frozenLeft || (this.parent.getFrozenMode() === 'Right' && e.tableName === literals.frozenRight)) {
                    this.frozenFrag = e.frag;
                }
                else if (this.parent.getFrozenMode() === literals.leftRight && e.tableName === 'movable') {
                    this.movableFrag = e.frag;
                }
                else {
                    const tbody = this.parent.getFrozenVirtualContent().querySelector(literals.tbody);
                    if (e.args.direction === 'up') {
                        tbody.insertBefore(this.frozenFrag, tbody.firstElementChild);
                    }
                    else {
                        tbody.appendChild(this.frozenFrag);
                    }
                    if (e.tableName === literals.frozenRight) {
                        this.parent.getMovableVirtualContent().querySelector(literals.tbody).appendChild(this.movableFrag);
                        this.parent.element.querySelector('.e-frozen-right-content').querySelector(literals.tbody).appendChild(e.frag);
                    }
                    else {
                        this.parent.getMovableVirtualContent().querySelector('.' + literals.table).appendChild(e.tbody);
                    }
                    this.parent.contentModule.refreshScrollOffset();
                    this.updateCurrentViewData();
                }
            }
            else {
                let table;
                if (e.tableName === literals.frozenLeft) {
                    table = this.parent.getFrozenVirtualContent().querySelector('.' + literals.table);
                }
                else if (e.tableName === 'movable') {
                    table = this.parent.getMovableVirtualContent().querySelector('.' + literals.table);
                    if (this.parent.getFrozenMode() !== literals.leftRight) {
                        this.parent.contentModule.refreshScrollOffset();
                        this.updateCurrentViewData();
                    }
                }
                else {
                    table = this.parent.element.querySelector('.e-frozen-right-content').querySelector('.' + literals.table);
                    if (this.parent.getFrozenMode() === literals.leftRight) {
                        this.parent.contentModule.refreshScrollOffset();
                        this.updateCurrentViewData();
                    }
                }
                table.appendChild(e.tbody);
                this.widthService.refreshFrozenScrollbar();
            }
        }
        if (this.isInitialRender && !e.args.isFrozen) {
            this.isInitialRender = false;
            this.parent.scrollModule.setHeight();
        }
        if (!e.args.isFrozen) {
            this.rowTop = !this.rowTop ? this.parent.getRows()[0].getBoundingClientRect().top : this.rowTop;
            if (isInfiniteScroll) {
                if (this.parent.infiniteScrollSettings.enableCache && this.isRemove) {
                    scrollEle.scrollTop = this.top;
                }
                setRowElements(this.parent);
            }
            this.restoreInfiniteAdd();
            this.isScroll = true;
        }
        this.isInfiniteScroll = false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectNewRow(args) {
        const gObj = this.parent;
        const row = gObj.getRowByIndex(this.rowIndex);
        const cache = gObj.infiniteScrollSettings.enableCache;
        if (row && this.keys.some((value) => value === this.pressedKey)) {
            const content = gObj.getContent().firstElementChild;
            const rowHeight = gObj.getRowHeight();
            const target = row.cells[this.cellIndex];
            if ((this.pressedKey === literals.downArrow || this.pressedKey === literals.enter)
                || (cache && (this.pressedKey === literals.upArrow || this.pressedKey === literals.shiftEnter))) {
                if (!cache && this.pressedKey !== literals.upArrow && this.pressedKey !== literals.shiftEnter) {
                    content.scrollTop = content.scrollTop + rowHeight;
                }
                gObj.focusModule.isInfiniteScroll = true;
                gObj.focusModule.onClick({ target }, true);
                gObj.selectRow(this.rowIndex);
            }
        }
        else if (this.lastFocusInfo && (this.pressedKey === literals.pageDown || this.pressedKey === literals.pageUp)) {
            const idx = cache ? 0 : this.lastFocusInfo.rowIdx;
            if (gObj.getRowByIndex(idx)) {
                const target = gObj.getCellFromIndex(idx, this.lastFocusInfo.cellIdx);
                if (target) {
                    this.isFocusScroll = true;
                    if (!cache) {
                        gObj.focusModule.isInfiniteScroll = true;
                        gObj.focusModule.onClick({ target }, true);
                    }
                    else {
                        target.focus({ preventScroll: true });
                    }
                }
            }
        }
        this.pressedKey = undefined;
    }
    removeInfiniteCacheRows(e) {
        const isInfiniteScroll = this.parent.enableInfiniteScrolling && e.args.requestType === 'infiniteScroll';
        if (!e.args.isFrozen && isInfiniteScroll && this.parent.infiniteScrollSettings.enableCache && this.isRemove) {
            const rows = [].slice.call(this.parent.getContentTable().getElementsByClassName(literals.row));
            if (e.args.direction === 'down') {
                if (this.parent.allowGrouping && this.parent.groupSettings.columns.length) {
                    const captionRows = [].slice.call(this.parent.getContentTable().querySelectorAll('tr'));
                    this.removeCaptionRows(captionRows, e.args);
                }
                const addRowCount = this.parent.element.querySelector('.' + literals.addedRow) ? 0 : 1;
                this.removeTopRows(rows, this.parent.pageSettings.pageSize - addRowCount);
            }
            if (e.args.direction === 'up') {
                if (this.parent.allowGrouping && this.parent.groupSettings.columns.length) {
                    const captionRows = [].slice.call(this.parent.getContentTable().querySelectorAll('tr'));
                    this.removeCaptionRows(captionRows, e.args);
                }
                else {
                    this.removeBottomRows(rows, rows.length - 1, e.args);
                }
            }
            this.isScroll = false;
            this.top = this.calculateScrollTop(e.args);
        }
    }
    calculateScrollTop(args) {
        let top = 0;
        const scrollCnt = this.parent.getContent().firstElementChild;
        if (args.direction === 'down') {
            if (this.parent.allowGrouping && this.parent.groupSettings.columns.length && !this.isInitialCollapse) {
                top = this.captionRowHeight();
            }
            const captionRows = [].slice.call(this.parent.getContent().firstElementChild.querySelectorAll('tr:not(.e-row)'));
            let captionCount = 0;
            if (this.isInitialCollapse && !isNullOrUndefined(captionRows)) {
                captionCount = Math.round((captionRows.length - 1) / this.parent.groupSettings.columns.length);
            }
            const value = captionCount ? captionCount
                : this.parent.pageSettings.pageSize * (this.parent.infiniteScrollSettings.maxBlocks - 1);
            let currentViewRowCount = 0;
            let i = 0;
            while (currentViewRowCount < scrollCnt.clientHeight) {
                i++;
                currentViewRowCount = i * this.parent.getRowHeight();
            }
            i = i - 1;
            top += (value - i) * this.parent.getRowHeight();
        }
        if (args.direction === 'up') {
            if (this.parent.allowGrouping && this.parent.groupSettings.columns.length && !this.isInitialCollapse) {
                const len = this.refreshInfiniteCacheRowVisibleLength(this.infiniteCache, this.parent.pageSettings.currentPage);
                top = len * this.parent.getRowHeight();
            }
            else if (this.isInitialCollapse) {
                const groupedData = this.infiniteCache[this.parent.pageSettings.currentPage];
                let count = 0;
                for (let i = 0; i < groupedData.length; i++) {
                    if (groupedData[i].isCaptionRow) {
                        count++;
                    }
                }
                top += Math.round(count / this.parent.groupSettings.columns.length) * this.parent.getRowHeight();
            }
            else {
                top += (this.parent.pageSettings.pageSize * this.parent.getRowHeight() + getScrollBarWidth());
            }
        }
        return top;
    }
    captionRowHeight() {
        const rows = [].slice.call(this.parent.getContent().querySelectorAll('tr:not(.e-row)'));
        return rows.length * this.parent.getRowHeight();
    }
    removeTopRows(rows, maxIndx) {
        const frozeCols = this.parent.isFrozenGrid();
        const frRows = this.parent.getFrozenMode() === literals.leftRight
            ? [].slice.call(this.parent.element.querySelector('.e-frozen-right-content').getElementsByClassName(literals.row)) : null;
        const movableRows = frozeCols ?
            [].slice.call(this.parent.getMovableVirtualContent().getElementsByClassName(literals.row)) : null;
        for (let i = 0; i <= maxIndx; i++) {
            if (this.parent.frozenRows && this.parent.pageSettings.currentPage === this.parent.infiniteScrollSettings.maxBlocks + 1
                && i > (maxIndx - this.parent.frozenRows)) {
                continue;
            }
            remove(rows[i]);
            if (movableRows) {
                remove(movableRows[i]);
            }
            if (frRows) {
                remove(frRows[i]);
            }
        }
    }
    removeBottomRows(rows, maxIndx, args) {
        let cnt = 0;
        const frozeCols = this.parent.isFrozenGrid();
        const movableRows = frozeCols ?
            [].slice.call(this.parent.getMovableVirtualContent().getElementsByClassName(literals.row)) : null;
        const frRows = this.parent.getFrozenMode() === literals.leftRight ?
            [].slice.call(this.parent.element.querySelector('.e-frozen-right-content').getElementsByClassName(literals.row)) : null;
        const pageSize = this.parent.pageSettings.pageSize;
        if (!frozeCols && this.infiniteCache[args.prevPage].length < pageSize) {
            cnt = this.parent.pageSettings.pageSize - this.infiniteCache[args.prevPage].length;
        }
        if (frozeCols && this.infiniteFrozenCache[args.prevPage][1].length < pageSize) {
            cnt = this.parent.pageSettings.pageSize - this.infiniteFrozenCache[args.prevPage][1].length;
        }
        for (let i = maxIndx; cnt < pageSize; i--) {
            cnt++;
            remove(rows[i]);
            if (movableRows) {
                remove(movableRows[i]);
            }
            if (frRows) {
                remove(frRows[i]);
            }
        }
    }
    removeCaptionRows(rows, args) {
        const rowElements = [].slice.call(this.parent.getContent().getElementsByClassName(literals.row));
        if (args.direction === 'down') {
            const lastRow = rowElements[this.parent.pageSettings.pageSize - 1];
            const lastRowIndex = getRowIndexFromElement(lastRow) - 1;
            let k = 0;
            for (let i = 0; k < lastRowIndex; i++) {
                if (!rows[i].classList.contains(literals.row)) {
                    remove(rows[i]);
                }
                else {
                    k = getRowIndexFromElement(rows[i]);
                }
            }
        }
        if (args.direction === 'up') {
            const lastIndex = getRowIndexFromElement(rowElements[rowElements.length - 1]);
            const page = Math.ceil(lastIndex / this.parent.pageSettings.pageSize);
            let startIndex = 0;
            for (let i = this.parent.pageSettings.currentPage + 1; i < page; i++) {
                startIndex += this.infiniteCache[i].length;
            }
            for (let i = startIndex; i < rows.length; i++) {
                remove(rows[i]);
            }
        }
    }
    resetInfiniteBlocks(args, isDataModified) {
        const isInfiniteScroll = this.parent.enableInfiniteScrolling && args.requestType !== 'infiniteScroll';
        if (!this.initialRender && !isNullOrUndefined(this.parent.infiniteScrollModule) && isInfiniteScroll) {
            if (this.actions.some((value) => value === args.requestType) || isDataModified || (args.requestType === 'save'
                && (this.parent.sortSettings.columns.length || this.parent.filterSettings.columns.length
                    || this.parent.groupSettings.columns.length || this.parent.searchSettings.key))) {
                const scrollEle = this.parent.getContent().firstElementChild;
                this.initialRender = true;
                scrollEle.scrollTop = 0;
                this.parent.pageSettings.currentPage = 1;
                this.infiniteCache = this.infiniteFrozenCache = {};
                this.infiniteCurrentViewData = {};
                this.resetContentModuleCache({});
                this.isRemove = false;
                this.top = 0;
                this.isInitialMovableRender = true;
                this.isInitialCollapse = false;
                this.parent.contentModule.isRemove = this.isRemove;
                this.parent.contentModule.isAddRows = this.isRemove;
                this.parent.contentModule.visibleRows = [];
                this.parent.contentModule.visibleFrozenRows = [];
            }
        }
    }
    setCache(e) {
        if (this.parent.enableInfiniteScrolling && this.parent.infiniteScrollSettings.enableCache) {
            const frozeCols = this.parent.isFrozenGrid();
            const idx = e.args.isFrozen ? 1 : 0;
            const isEdit = e.args.requestType !== 'infiniteScroll'
                && (this.requestType === 'delete' || this.requestType === 'add');
            const currentPage = this.parent.pageSettings.currentPage;
            if ((frozeCols && this.isInitialMovableRender) || (!frozeCols && !Object.keys(this.infiniteCache).length) || isEdit) {
                this.isInitialMovableRender = !e.args.isFrozen;
                this.setInitialCache(e.modelData, e.args, isEdit);
            }
            if (!frozeCols && isNullOrUndefined(this.infiniteCache[this.parent.pageSettings.currentPage])) {
                this.infiniteCache[this.parent.pageSettings.currentPage] = e.modelData;
                this.resetContentModuleCache(this.infiniteCache);
            }
            if (frozeCols) {
                if ((idx === 0 && isNullOrUndefined(this.infiniteFrozenCache[currentPage]))
                    || !this.infiniteFrozenCache[currentPage][idx].length) {
                    this.createFrozenCache(currentPage);
                    this.infiniteFrozenCache[currentPage][idx] = e.modelData;
                    if (idx === 1) {
                        this.resetContentModuleCache(this.infiniteFrozenCache);
                    }
                }
            }
            if (e.isInfiniteScroll && !this.isRemove) {
                this.isRemove = (currentPage - 1) % this.parent.infiniteScrollSettings.maxBlocks === 0;
                this.parent.contentModule.isRemove = this.isRemove;
            }
        }
    }
    setInitialCache(data, args, isEdit, isCurrentViewData) {
        const frozenCols = this.parent.isFrozenGrid();
        const idx = args.isFrozen ? 1 : 0;
        let k = !isEdit ? 1 : this.firstBlock;
        for (let i = 1; i <= this.parent.infiniteScrollSettings.initialBlocks; i++) {
            const startIndex = (i - 1) * this.parent.pageSettings.pageSize;
            const endIndex = i * this.parent.pageSettings.pageSize;
            if (this.parent.allowGrouping && this.parent.groupSettings.columns.length && !isCurrentViewData) {
                this.setInitialGroupCache(data, k, startIndex, endIndex);
            }
            else {
                if (isCurrentViewData) {
                    this.infiniteCurrentViewData[k] = data.slice(startIndex, endIndex);
                }
                else {
                    if (frozenCols) {
                        this.createFrozenCache(k);
                        this.infiniteFrozenCache[k][idx] = data.slice(startIndex, endIndex);
                        this.resetContentModuleCache(this.infiniteFrozenCache);
                    }
                    else {
                        this.infiniteCache[k] = data.slice(startIndex, endIndex);
                        this.resetContentModuleCache(this.infiniteCache);
                    }
                }
            }
            k++;
        }
    }
    createFrozenCache(index) {
        if (!this.infiniteFrozenCache[index]) {
            this.infiniteFrozenCache[index] = [[], []];
        }
    }
    setInitialGroupCache(data, index, sIndex, eIndex) {
        const pageData = [];
        let startIndex = 0;
        for (let i = 1; i <= Object.keys(this.infiniteCache).length; i++) {
            startIndex += this.infiniteCache[i].length;
        }
        let k = sIndex;
        for (let i = startIndex; i < data.length && k < eIndex; i++) {
            if (data[i].index < eIndex || data[i].isCaptionRow) {
                k = data[i].isCaptionRow ? k : data[i].index;
                pageData.push(data[i]);
            }
            if (data[i].index >= eIndex || data[i].index === eIndex - 1) {
                break;
            }
        }
        this.infiniteCache[index] = pageData;
        this.resetContentModuleCache(this.infiniteCache);
    }
    resetContentModuleCache(data) {
        this.parent.contentModule
            .infiniteCache = data;
    }
    /**
     * @returns {void}
     * @hidden
     */
    destroy() {
        this.removeEventListener();
    }
}
