import { extend } from '@syncfusion/ej2-base';
import { DatePicker, DateTimePicker } from '@syncfusion/ej2-calendars';
import { isEditable, getObject, getCustomDateFormat } from '../base/util';
import { EditCellBase } from './edit-cell-base';
/**
 * `DatePickerEditCell` is used to handle datepicker cell type editing.
 *
 * @hidden
 */
export class DatePickerEditCell extends EditCellBase {
    write(args) {
        this.edit = this.parent.editModule;
        if (args.column.editType === 'datepickeredit') {
            this.obj = new DatePicker(extend(dateanddatetimerender(args, this.parent.editSettings.mode, this.parent.enableRtl, this.parent.cssClass, this), args.column.edit.params));
        }
        else if (args.column.editType === 'datetimepickeredit') {
            this.obj = new DateTimePicker(extend(dateanddatetimerender(args, this.parent.editSettings.mode, this.parent.enableRtl, this.parent.cssClass, this), args.column.edit.params));
        }
        this.obj.appendTo(args.element);
    }
}
// eslint-disable-next-line
function dateanddatetimerender(args, mode, rtl, css, datePickerEditCell) {
    const isInline = mode !== 'Dialog';
    const format = getCustomDateFormat(args.column.format, args.column.type);
    let value = getObject(args.column.field, args.rowData);
    value = value ? new Date(value) : null;
    return {
        floatLabelType: isInline ? 'Never' : 'Always',
        value: value,
        format: format,
        placeholder: isInline ?
            '' : args.column.headerText, enableRtl: rtl,
        enabled: isEditable(args.column, args.requestType, args.element),
        cssClass: css ? css : null,
        close: datePickerClose.bind(datePickerEditCell)
    };
}
// eslint-disable-next-line
function datePickerClose(args) {
    if (args.event.action === 'escape') {
        this.edit.editCellDialogClose = true;
    }
}
