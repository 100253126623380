import { addClass, removeClass, attributes, isNullOrUndefined } from '@syncfusion/ej2-base';
import { Button } from '@syncfusion/ej2-buttons';
import { CellRenderer } from './cell-renderer';
import { appendChildren } from '../base/util';
import { destroy, commandColumnDestroy } from '../base/constant';
/**
 * `CommandColumn` used to render command column in grid
 *
 * @hidden
 */
export class CommandColumnRenderer extends CellRenderer {
    constructor(parent, locator) {
        super(parent, locator);
        this.buttonElement = this.parent.createElement('button', {});
        this.unbounDiv = this.parent.createElement('div', { className: 'e-unboundcelldiv', styles: 'display: inline-block' });
        this.childRefs = [];
        this.element = this.parent.createElement('TD', {
            className: 'e-rowcell e-unboundcell', attrs: {
                role: 'gridcell', tabindex: '-1'
            }
        });
        this.parent.on(destroy, this.destroyButtons, this);
        this.parent.on(commandColumnDestroy, this.destroyButtons, this);
    }
    destroyButtons(args) {
        for (let i = 0; i < this.childRefs.length; i++) {
            if (this.childRefs[i] && !this.childRefs[i].isDestroyed) {
                this.childRefs[i].destroy();
                if (this.childRefs[i].element) {
                    this.childRefs[i].element.innerHTML = '';
                }
            }
        }
        if (args.type === 'paging') {
            let elem = this.parent.element.querySelectorAll('.e-unboundcell');
            if (elem.length) {
                for (let i = 0; i < elem.length; i++) {
                    if (elem[i]) {
                        if (elem[i].querySelector('.e-unboundcelldiv')) {
                            elem[i].querySelector('.e-unboundcelldiv').innerHTML = '';
                        }
                        elem[i].innerHTML = '';
                    }
                }
                elem = null;
            }
        }
        else {
            this.parent.off(destroy, this.destroyButtons);
            this.parent.off(commandColumnDestroy, this.destroyButtons);
        }
    }
    /**
     * Function to render the cell content based on Column object.
     *
     * @param {cell<Column>} cell - specifies the cell
     * @param {Object} data - specifies the data
     * @param {Object} attributes - specifies the attributes
     * @param {boolean} isVirtualEdit - specifies virtual scroll editing
     * @returns {Element} returns the element
     */
    render(cell, data, attributes, isVirtualEdit) {
        let node = this.element.cloneNode();
        const uid = 'uid';
        node.appendChild(this.unbounDiv.cloneNode());
        node.setAttribute('aria-label', 'is Command column column header ' + cell.column.headerText);
        if (cell.column.commandsTemplate) {
            if (this.parent.isReact && typeof (cell.column.commandsTemplate) !== 'string') {
                const tempID = this.parent + 'commandsTemplate';
                cell.column.getColumnTemplate()(data, this.parent, 'commandsTemplate', tempID, null, null, node.firstElementChild);
                this.parent.renderTemplates();
            }
            else {
                appendChildren(node.firstElementChild, cell.column.getColumnTemplate()(data));
            }
        }
        else {
            for (const command of cell.commands) {
                node = this.renderButton(node, command, attributes.index, command[uid]);
            }
        }
        this.setAttributes(node, cell, attributes);
        if ((!this.parent.enableVirtualization && this.parent.isEdit) || isVirtualEdit) {
            addClass([].slice.call(node.getElementsByClassName('e-edit-delete')), 'e-hide');
            removeClass([].slice.call(node.getElementsByClassName('e-save-cancel')), 'e-hide');
        }
        else {
            addClass([].slice.call(node.getElementsByClassName('e-save-cancel')), 'e-hide');
            removeClass([].slice.call(node.getElementsByClassName('e-edit-delete')), 'e-hide');
        }
        return node;
    }
    renderButton(node, buttonOption, index, uid) {
        const button = this.buttonElement.cloneNode();
        attributes(button, {
            'id': this.parent.element.id + (buttonOption.type || '') + '_' + index + '_' + uid, 'type': 'button',
            title: !isNullOrUndefined(buttonOption.title) ? buttonOption.title :
                buttonOption.buttonOption.content || this.localizer.getConstant(buttonOption.type) || buttonOption.type,
            'data-uid': uid
        });
        button.onclick = buttonOption.buttonOption.click;
        buttonOption.buttonOption.cssClass = this.parent.cssClass ?
            buttonOption.buttonOption.cssClass + ' ' + this.parent.cssClass : buttonOption.buttonOption.cssClass;
        const buttonObj = new Button(buttonOption.buttonOption, button);
        this.childRefs.push(buttonObj);
        buttonObj.commandType = buttonOption.type;
        node.firstElementChild.appendChild(buttonObj.element);
        switch (buttonOption.type) {
            case 'Edit':
            case 'Delete':
                addClass([button], ['e-edit-delete', 'e-' + buttonOption.type.toLowerCase() + 'button']);
                break;
            case 'Cancel':
            case 'Save':
                addClass([button], ['e-save-cancel', 'e-' + buttonOption.type.toLowerCase() + 'button']);
                break;
        }
        return node;
    }
}
